import {Settings} from '../../common/models'
import {RESTHelper} from '@targomo/client';
import {Injectable} from '@angular/core';
import { Place } from './place';
import { StatisticsGeometryResult, LatLngId, StatisticsGeometryRequestOptions } from '@targomo/core';

export class GeoRegion {
  id?: number
  name: string
}

export class GeoRegionVersion {
  id?: number
  created: string
}

@Injectable()
export class GeoRegionsEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/georegions')
  }

  getGeometries() {
    return this.http.get<any>(`/geometries`)
  }

  delete(set: {id: number}) {
    return this.http.delete<any>(`/${set.id}`)
  }

  get(set: {id: number}) {
    return this.http.get<any>(`/${set.id}`)
  }

  me() {
    return this.http.get<GeoRegion[]>(`/me`)
  }
  
  findAll() {
    return this.http.get<GeoRegion[]>(`/`)
  }

  getVersions(set: {id: number}) {
    return this.http.get<GeoRegionVersion[]>(`/${set.id}/versions`)
  }

  saveActive(version: {id: number}, value: boolean = true) {
    return this.http.put(`/version/${version.id}/active/${value}`, {})
  }

  savePreview(version: {id: number}, value: boolean = true) {
    return this.http.put(`/version/${version.id}/preview/${value}`, {})
  }

  deleteVersion(version: {id: number}) {
    return this.http.delete(`/version/${version.id}`)
  }
  
  save(layer: GeoRegion) {
    return this.http.put<GeoRegion>(`/${layer.id}`, layer)
  }  

  uploadMultipart(files: any, set?: {id?: number, name?: string}) {
    const data = new FormData();
    data.append('files', files[0])  
      
    if (set && isFinite(set.id)) {
      return this.http.put<number>(`/upload/${set.id}`, data)
    } else {
      return this.http.post<number>(`/upload/new/${encodeURIComponent(set.name)}`, data)
    }
  }  
}