import { Place } from '../api/place'
import { DataSet } from '../api/dataSet'
import { Category } from '../api/index'
import { Observable } from 'rxjs/Observable'
import { CategoryMap } from './placesModel'
import { RANDOM_COLORS } from '@targomo/client'
import { MARKER_PRINT_FACTOR } from './displayConstants'
import { MarkerSizeProperty } from './settingsModel'
const Color = require('color')

export function markersLayer(colors: string[], forPrint: boolean, property: MarkerSizeProperty) {
  const STOPS: string[][] = []

  colors.forEach((color) => {
    STOPS.push([color, color])
  })
  // var i = 0
  // for (var key in categoriesMap) {
  //   i++
  //   const category = categoriesMap[key]

  //   STOPS.push([key, category.color || RANDOM_COLORS[i % RANDOM_COLORS.length]])
  // }

  const STOPS_LIGHTER = STOPS.map((stop) => [stop[0], Color(stop[1]).lighten(0.1).hex()])
  const STOPS_DARK = STOPS.map((stop) => [stop[0], Color(stop[1]).darken(0.5).hex()])

  // const SIZE_STOPS_SMALL = []
  // const SIZE_STOPS_BIG = []
  const factor = forPrint ? MARKER_PRINT_FACTOR / (window.devicePixelRatio || 1) : 1
  const SIZE_STOPS_NEW_SMALL = []
  const SIZE_STOPS_NEW_BIG = []

  // for (let i = 0; i <= 10; i++) {
  //   SIZE_STOPS_SMALL.push([{ zoom: 1, value: i }, 1 * factor])
  //   let radius = 8 + Math.ceil(Math.sqrt(i / 3.14) * 12)
  //   SIZE_STOPS_BIG.push([{ zoom: 25, value: i }, radius * factor])
  // }

  for (let i = 0; i <= 10; i++) {
    SIZE_STOPS_NEW_SMALL.push(i)
    SIZE_STOPS_NEW_SMALL.push(1 * factor)

    SIZE_STOPS_NEW_BIG.push(i)
    let radius = 8 + Math.ceil(Math.sqrt(i / 3.14) * 12)
    SIZE_STOPS_NEW_BIG.push(radius * factor)

    // SIZE_STOPS_NEW.push([{ zoom: 1, value: i }, 1 * factor])
    // let radius = 8 + Math.ceil(Math.sqrt(i / 3.14) * 12)
    // SIZE_STOPS_BIG.push([{ zoom: 25, value: i }, radius * factor])
  }

  const newRadiusStyle = [
    'interpolate',
    ['linear'],
    ['zoom'],
    1,
    [
      '*',
      ['match', ['get', 'marker-size:' + property], ...SIZE_STOPS_NEW_SMALL, 1],
      ['case', ['has', 'marker-icon-size'], ['get', 'marker-icon-size'], 1],
    ],
    25,
    [
      '*',
      ['match', ['get', 'marker-size:' + property], ...SIZE_STOPS_NEW_BIG, 1],
      ['case', ['has', 'marker-icon-size'], ['get', 'marker-icon-size'], 1],
    ],
  ]

  // Marker styling, per category
  const markerStyle = {
    'circle-radius': newRadiusStyle,
    // {
    //   property: 'marker-size:' + property,
    //   type: 'exponential',
    //   stops: SIZE_STOPS_SMALL.concat(SIZE_STOPS_BIG),
    // },
    'circle-color': {
      property: 'marker-type',
      type: 'categorical',
      stops: STOPS,
      default: '#3887BE',
    }, //'#ff0000',//"#3887be",
    'circle-stroke-width': 1,
    'circle-stroke-color': {
      property: 'marker-type',
      type: 'categorical',
      stops: STOPS_DARK,
    },
  }

  const sourceMarkerStyle = {
    ...markerStyle,
    'circle-color': {
      property: 'marker-type',
      type: 'categorical',
      stops: STOPS_LIGHTER,
    }, //'#ff0000',//"#3887be",
    'circle-stroke-width': 2,
    'circle-stroke-color': 'white',
  }

  return { markerStyle, sourceMarkerStyle }
}
