import { Component } from '@angular/core';
import { DataSet } from "../../../api";
import {Auth, TgmQuickDialogs} from '@targomo/client';
import {DataSetEndpoint} from '../../../api/dataSet';
import {Router, ActivatedRoute} from '@angular/router';
import { GeoRegionsEndpoint } from '../../../api/georegions';
import { ReportTemplate } from '../../../../common/models';
import { ReportTemplateEndpoint } from '../../../api/reportTemplate';

@Component({
  selector: 'admin-report-templates-list',
  templateUrl: './adminReportTemplatesList.component.html'
})
export class AdminReportTemplatesListComponent {
  templates: ReportTemplate[]
  loading: Promise<any[]>

  constructor(
    private templateEndpoint: ReportTemplateEndpoint, 
    private route: ActivatedRoute, 
    private router: Router,
    private quickDialogs: TgmQuickDialogs) {
  }

  ngOnInit() {
    this.updateList()
  }

  async updateList() {
    this.templates = await this.templateEndpoint.findAll()
  }

  async deleteTemplate(template: ReportTemplate) {
    if (await this.quickDialogs.confirm({
      title: 'Delete Report Template',
      message: `Would you like to delete template ${template.name})?`,
      confirm: 'Delete',
      cancel: 'Cancel'
    })) {
      await this.templateEndpoint.delete(template)
      this.updateList()
    }
  }

  showMenu(event: any) {
    event.stopPropagation()
  }
}
