import { Router } from '@angular/router'
import { angularInjector } from '../app.module'
import { APP_BASE_PATH } from './basePath'

let staticRedirectUrl: string = null

export function loginRedirectGetAndClear() {
  const result = staticRedirectUrl
  staticRedirectUrl = null
  return result
}

export function loginRedirect(): void {
  if (!staticRedirectUrl) {
    staticRedirectUrl = window.location.href
  } else {
    return
  }

  if ((window.location.hash || '').indexOf('#/login') === 0) {
    return
  }

  if (angularInjector) {
    const router = angularInjector.get(Router)

    if (router) {
      let hash = window.location.hash ? String(window.location.hash).slice(1) : '/login'

      if (hash === '/') {
        hash = '/login'
      }

      router.navigate([hash])
      return
    }
  }

  // NOTE: lines below are probably never going to be reached because the angular thing above
  // should always work....but leaving in it just in case...
  window.location.href = APP_BASE_PATH + '#/login' // ?returnUrl=' + encodeURIComponent(staticRedirectUrl)
  if (!staticRedirectUrl) {
    window.location.reload()
  }
}
