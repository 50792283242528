<div *ngFor="let item of template">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-select [(ngModel)]="item.left" (ngModelChange)="fire()">
      <mat-option [title]="option.label" *ngFor="let option of available" [value]="option.key">
        {{option.label}}
      </mat-option>
    </mat-select>      
  
    <div class="divide">/</div>
    
    <mat-select [(ngModel)]="item.right" (ngModelChange)="fire()">
      <mat-option [title]="1" [value]="'1'">
          1
      </mat-option>      
      <mat-option [title]="option.label" *ngFor="let option of available" [value]="option.key">
        {{option.label}}
      </mat-option>
    </mat-select>      

    <mat-select style="text-align: right" fxFlex="80px" [(ngModel)]="item.format" (ngModelChange)="fire()">
      <mat-option [title]="'Format:' + option.label" *ngFor="let option of availableFormats" [value]="option.key">
          {{option.label}}
      </mat-option>
    </mat-select>      
    
    <button type="button"  mat-icon-button (click)="delete(item)" ><mat-icon class="mat-20">delete</mat-icon></button>
  </div>
</div>


<div fxLayout="row" fxLayoutAlign="center center" style="margin: 16px; width: 100%">
  <button type="button" mat-button (click)="add()" >Add Ratio or Value</button>
</div>