import { Component, Input, OnInit } from '@angular/core'
import { SearchResult, SubscriberComponent } from '@targomo/client'
import { AppModel } from '../../model/appModel.service'
import { HomeComponent } from '../home.component'

@Component({
  selector: 'search-temporary-location',
  templateUrl: './searchTemporaryLocation.component.html',
  styleUrls: ['./searchTemporaryLocation.component.less'],
})
export class SearchTemporaryLocationComponent extends SubscriberComponent {
  location: SearchResult

  constructor(readonly home: HomeComponent, readonly appModel: AppModel) {
    super()

    this.watch(this.appModel.places.temporaryLocation, (location) => {
      this.location = location
    })
  }

  close() {
    this.appModel.places.temporaryLocation.next(null)
  }

  center() {
    if (this.location) {
      this.home.mapView.setZoom(13)
      this.home.mapView.setView(this.location, true)
    }
  }
}
