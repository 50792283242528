import {AppModel} from '../../model/appModel.service';
import {PersonicxStatisticsSummary} from '../../../common/models/statistics/statistics';
import {Subscription} from 'rxjs/Subscription';
import { Component, Input } from '@angular/core';
import { SubscriberComponent } from '@targomo/client';
import { Observable } from 'rxjs';
import { ReportPerCapitaHousehold } from '../../model/settingsModel';

export const PERSONICS_GROUPS = [
    {id: "a", name: "PreFamily"},
    {id: "b", name: "Families"},
    {id: "c", name: "Empty Nesters"},
    {id: "d", name: "Retired, Seniors"},
  ]

export const AFFLUENCE = [1, 2, 3, 4, 5]

@Component({
  selector: 'lifestyle-personicx-mini-report',
  templateUrl: './lifestylePersonicxMiniReport.html',
  styleUrls: ['./lifestylePersonicxMiniReport.less']
})
export class LifestylePersonicxMiniReportComponent extends SubscriberComponent {
  @Input() allColumns: boolean

  loading: Promise<{}>
  statistics: PersonicxStatisticsSummary
  statisticsIndex: PersonicxStatisticsSummary

  groups = PERSONICS_GROUPS
  affluence = AFFLUENCE

  reports: any[] = []

  constructor(readonly appModel: AppModel) {
    super()
  }

  ngOnInit() {
    this.watch(this.appModel.statistics.census.promise, promise => this.loading = promise)
    this.watch(Observable.combineLatest(
      this.appModel.statistics.census.value,
      this.appModel.settings.reportPerCapitaHouseholdsUpdates
    ), ([statistics, reportPerCapitaHouseholds]) => {
      if (statistics) {
        this.statistics = statistics.lifestyle.personicxSummary
        this.statisticsIndex = statistics.lifestyle.personicxSummary
      } else {
        this.statistics = null
        this.statisticsIndex = null
      }


      this.reports = []
      this.reports.push({statistics: this.statistics, index: false, title: 'Household Count'})

      if (this.allColumns && this.statistics) {
        this.reports.push({statistics: this.statisticsIndex, index: true,  title: `Index / Bias vs ${statistics.regionLabel()} Avg`})
      }
    })
  }
}