import { Place } from '../api/index'

import { csv } from '../util/csv'

const EXPORT_COLUMNS: { [key: string]: boolean } = {
  storeId: true,
  lat: true,
  lng: true,
  primaryCategory: true,
  secondaryCategory: true,
  holdingCompany: true,
  fascia: true,
  sitename: true,
  paon: true,
  saon: true,
  taon: true,
  street: true,
  suburb: true,
  town: true,
  postcode: true,
  district: true,
  county: true,
  region: true,
  netSalesArea: true,
  netSalesAreaSource: true,
  grossInternalArea: true,
  grossInternalAreaSource: true,
  travelTime: true,
}

export default function (places: Place[], exportType: string, fileName = 'storepointgeo_exported.csv') {
  if (exportType.toLocaleLowerCase() === 'csv') {
    saveFile(toCSV(places), 'text/csv', fileName)
  } else {
    saveFile(JSON.stringify(toGeoJSON(places)), 'application/vnd.geo+json', fileName)
  }
}

/**
 * Save contest as file
 */
function saveFile(data: string, dataType: string, fileName: string) {
  const anchor: any = document.createElement('a')
  const blob = new Blob([data], { type: dataType })
  anchor.href = URL.createObjectURL(blob)
  anchor.download = fileName

  document.body.appendChild(anchor)
  try {
    anchor.click()
  } catch (e) {
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, anchor.download)
    }
  }
  document.body.removeChild(anchor)
}

/**
 * Convert list of places to geojson
 */
function toGeoJSON(places: Place[]) {
  var placesGeo = places.map((place) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [place.lng, place.lat],
      },

      properties: exportProperties(place, true),
    }
  })

  return {
    type: 'FeatureCollection',
    features: placesGeo,
  }
}

/**
 * Convert list of places to csv
 */
export function toCSV(places: Place[]) {
  return csv(places.map(exportPropertiesCSV))
}

// FIXME: be more selective
function exportProperties(place: Place, excludePosition: boolean) {
  const result = {}

  for (let key in place) {
    if ((key != 'lat' && key != 'lng') || !excludePosition) {
      if (EXPORT_COLUMNS[key] && place.hasOwnProperty(key)) {
        ;(<any>result)[key] = (<any>place)[key]
      }
    }
  }

  if (place.other) {
    return { ...result, ...place.other }
  } else {
    return result
  }
}

function exportPropertiesCSV(place: Place) {
  return exportProperties(place, false)
}
