import { Component, OnInit } from '@angular/core';
import { SubscriberComponent } from '@targomo/client';
import { AppModel } from '../../../model/appModel.service';

@Component({
  selector: 'per-capita-households-switch',
  templateUrl: './perCapitaHouseholdsSwitch.component.html',
  styleUrls: ['./perCapitaHouseholdsSwitch.component.less']
})
export class PerCapitaHouseholdsSwitchComponent extends SubscriberComponent {
  region: string
  country: string
  value: boolean = true

  constructor(readonly appModel: AppModel) {
    super()
  }

  ngOnChanges() {
  }

  changeValue(value: any) {
    this.appModel.displaySettings.nextProperty('perCapitaHousehold', value)
  }
}
