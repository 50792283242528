import { Component, Input, NgZone, OnChanges, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Auth, SubscriberComponent } from '@targomo/client'
import { Category } from '../../../api/category'
import { DataSet } from '../../../api/dataSet'
import { Place } from '../../../api/place'
import { AppModel } from '../../../model/appModel.service'
import { Filter } from '../../../util/types'
import { CategoryColorModel } from '../../../model/categoryColorModel'
import { DataLoadingModel } from '../../../model/dataLoadingModel'
import { ColorSelectDialog } from '../../dialogs/colorSelectDialog/colorSelectDialog.component'
import { FasciasSelection } from '../../../model/fasciasSelectionModel'
import { UserPermissions } from '../../../../common/models'
import { take } from 'rxjs/operators'

class TreeNode {
  public isBranch: boolean
  public visible: boolean
  public selected: boolean = false
  public placesCount: number = null
  public filtered: boolean = true

  constructor(public name: string, public children: Category[], public value: Category) {
    this.isBranch == !!children
    this.visible = false

    if (children) {
      children.forEach((category) => {
        if (category.placesCount != null) {
          this.placesCount = (this.placesCount || 0) + category.placesCount
        }
      })
    }
  }

  toggle() {
    this.visible = !this.visible
  }
}

@Component({
  selector: 'layer-select-panel',
  templateUrl: './layerSelectPanel.html',
  styleUrls: ['./layerSelectPanel.less'],
})
export class LayerSelectPanelComponent extends SubscriberComponent implements OnChanges, OnInit {
  @Input() type: 'default' | 'alsoAvailable'
  @Input() dataSet: DataSet
  @Input() values: Category[]
  // public visible: {[index: string]: any} = {}
  nodes: TreeNode[] = []

  filter: Filter<Place>
  selectedAll: boolean = false
  fasciasSelection: FasciasSelection = {}
  permissions: UserPermissions

  constructor(
    readonly appModel: AppModel,
    private auth: Auth,
    private zone: NgZone,
    private placesLoadingModel: DataLoadingModel,
    private dialog: MatDialog,
    private categoryColorModel: CategoryColorModel
  ) {
    super()

    this.watch(this.appModel.places.fasciasSelectionModel.selected$, (fasciasSelection) => {
      this.fasciasSelection = fasciasSelection
    })

    this.filter = (place: Place): boolean => {
      if (!place.category) return false

      return place.category.visible // != false // we want undefined to also count as true in this case
    }

    this.appModel.places.filters.add(this.filter)

    this.watch(this.appModel.sessionLoaded, () => {
      // avoid a cascade of change detection (from all other other instances of this widget)
      this.updateSelectState()
      // this.zone.runOutsideAngular(() => {
      //   if (this.nodes) {
      //     this.nodes.forEach(node => {
      //       this.updateNodeSelected(node, false)
      //     })

      //     this.updateAllSelected()
      //   }
      // })
    })

    this.watch(this.appModel.places.categorySelectionUpdated, (category) => {
      if (category && category.dataSet === this.dataSet) {
        this.updateSelectState()
      }
    })

    this.watch(this.placesLoadingModel.dataSetLoadedOrUpdated, (dataSet) => {
      if (dataSet === this.dataSet && this.nodes) {
        this.nodes.forEach((node) => {
          node.placesCount = 0
          if (node.children)
            node.children.forEach((category) => {
              node.placesCount += category.placesCount || 0
            })
        })
      }
    })

    this.watch(this.appModel.places.categorySearchUpdated, ([search, onlyMapped]) => {
      if (this.nodes) {
        this.nodes.forEach((node) => {
          function contains(value: string, segment: string) {
            return (value || '').toLowerCase().indexOf(segment) > -1
          }

          let nodeFiltered = !onlyMapped && (!search || contains(node.name, search))

          if (node.children && !nodeFiltered) {
            node.children.forEach((category) => {
              nodeFiltered = nodeFiltered || category.filtered
            })
          }

          node.filtered = onlyMapped ? !!nodeFiltered : nodeFiltered
        })
      }
    })
  }

  private categorySelected(category: Category) {
    return !!category.visible // != false
  }

  private updateNodeSelected(node: TreeNode, updateAll = true) {
    var result = true

    node.placesCount = 0
    if (node.children) {
      for (var child of node.children) {
        result = result && this.categorySelected(child)
        node.placesCount += child.placesCount || 0
      }
    }

    node.selected = result

    if (updateAll) {
      this.updateAllSelected()
    }
  }

  private updateAllSelected() {
    var result = true

    this.values.forEach((category) => {
      result = result && !!category.visible //!== false
    })

    this.selectedAll = result
    this.appModel.places.categorySelectUpdated.next(0)
    this.appModel.places.filters.touch()
  }

  toggle(node: TreeNode, category: Category, event?: any) {
    event && event.stopPropagation()

    if (this.type === 'alsoAvailable') {
      return
    }

    this.placesLoadingModel.loadCategory(this.dataSet, category)

    if (!category.visible) {
      this.appModel.places.logLayersCreated([category])
    }

    category.visible = !category.visible // || category.visible === undefined)
    this.updateNodeSelected(node)
  }

  toggleNode(node: TreeNode, event?: any) {
    event && event.stopPropagation()

    if (this.type === 'alsoAvailable') {
      return
    }

    this.placesLoadingModel.loadDataSet(this.dataSet)

    node.visible = true
    node.selected = !node.selected

    const listAffected: Category[] = []
    if (node.children) {
      for (var child of node.children) {
        if (!child.visible && node.selected) {
          listAffected.push(child)
        }

        child.visible = node.selected
      }
    }

    this.appModel.places.logLayersCreated(listAffected)
    this.updateAllSelected()
  }

  toggleAll(event?: any) {
    event && event.stopPropagation()

    if (this.type === 'alsoAvailable') {
      return
    }

    this.placesLoadingModel.loadDataSet(this.dataSet)

    const listAffected: Category[] = []

    this.selectedAll = !this.selectedAll
    for (let child of this.values) {
      if (!child.visible && this.selectedAll) {
        listAffected.push(child)
      }
      child.visible = this.selectedAll
    }

    for (let child of this.nodes) {
      child.selected = this.selectedAll
    }

    this.appModel.places.logLayersCreated(listAffected)
    this.appModel.places.categorySelectUpdated.next(0)
    this.appModel.places.filters.touch()
  }

  toggleFascia(node: TreeNode, category: Category, event: any) {
    event.stopPropagation()

    if (!category.visible) {
      this.toggle(node, category, event)
    }

    if (this.fasciasSelection[category && category.id] && this.fasciasSelection[category && category.id].all) {
      this.appModel.places.toggleCategory(category, false)
    } else {
      this.appModel.places.toggleCategory(category, true)
    }
  }

  togglePin(category: Category, event: any) {
    event.stopPropagation()

    this.appModel.places.toggleCategoryPinned(category)
  }

  ngOnDestroy() {
    this.appModel.places.filters.remove(this.filter)
  }

  async ngOnInit() {
    this.ngOnChanges()

    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
    }
  }

  ngOnChanges() {
    if (!this.values) return

    // if (this.values) {
    //   this.values.forEach(category => category.visible = false)
    // }

    const groups: any = {}

    // Splint into groups
    for (let item of this.values) {
      let group = groups[item.grouping]
      if (!group) {
        group = groups[item.grouping] = []
      }

      group.push(item)
    }

    const nodes: TreeNode[] = []
    for (let group in groups) {
      const node = new TreeNode(group, groups[group], null)
      nodes.push(node)
    }

    this.nodes = nodes
    this.updateSelectState()
  }

  private updateSelectState() {
    this.zone.runOutsideAngular(() => {
      if (this.nodes) {
        this.nodes.forEach((node) => {
          this.updateNodeSelected(node, false)
        })

        this.updateAllSelected()
      }
    })
  }

  async editColor(category: Category, event$: any) {
    event.stopPropagation()
    event.preventDefault()

    // this.categoryColorModel.setColorMode(true)
    const currentModel = await this.appModel.settings.locationIconModelUpdates.pipe(take(1)).toPromise()
    const iconConfig = currentModel.getCategoryConfig(category)

    const dialogRef = this.dialog.open(ColorSelectDialog, {
      disableClose: false,
      data: {
        withIcons: true,
        value: {
          color: category.customColor || category.originalColor,
          icon: iconConfig.icon,
          iconSize: iconConfig.size,
        },
      },
    })

    const result = await dialogRef.afterClosed().toPromise()
    if (result) {
      this.categoryColorModel.updateCustomColor(category, result.color)

      const currentModel = await this.appModel.settings.locationIconModelUpdates.pipe(take(1)).toPromise()

      this.appModel.settings.displaySettings.nextProperty(
        'locationIconModel',
        currentModel.updateCategoryIcon(category, result.icon).updateCategoryIconSize(category, result.iconSize)
      )
    }
  }
}
