<section style="width: 80vw; max-width: 350px">
  <h3 mat-dialog-title translate="menu.selectLayerColor">Select icon</h3>

  <form (ngSubmit)="save()">
    <div>
      <button type="button" (click)="select(icon)" mat-icon-button *ngFor="let icon of ICONS">
        <img [class.selected]="selected == icon.id" class="marker-icon" src="assets/images/{{ icon.file }}" />
      </button>
    </div>

    <div class="dialog-bottom">
      <button mat-button (click)="dialogRef.close()" mat-dialog-close>
        <span translate="dialog.cancel">Cancel</span>
      </button>
      <button mat-raised-button color="primary" mat-button><span translate="dialog.save">Save</span></button>
    </div>
  </form>
</section>
