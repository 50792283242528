<div fxLayout="column">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Report Templates</h2>

    <div class="secondary">
      <button mat-button class="primary" routerLink="/admin/reportTemplate/new"><mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> New Template</button>
    </div>
  </div>
</div>

<tgm-loadable [model]="loading">
  <mat-list>
    <mat-list-item *ngFor="let template of templates" routerLink="/admin/reportTemplate/{{template.id}}">
      <div class="name-section" fxFlex fxLayout="column" >
        <div class="name">{{template.name}}</div>
      </div>

      <button (click)="showMenu($event)" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="mat-24">more_vert</mat-icon></button>

      <mat-menu #menu="matMenu">
        <button routerLink="/admin/reportTemplate/{{template.id}}" mat-menu-item>Edit Template</button>
        <button (click)="deleteTemplate(template)" mat-menu-item>Delete Template</button>
      </mat-menu>
    </mat-list-item>
  </mat-list>
</tgm-loadable>