import { Component } from '@angular/core';
import { Indicators, SubscriberComponent } from '@targomo/client';
import { array as arrays } from '@targomo/common';
import { StatisticsEndpoint } from '../../../api/statistics';
import { AppModel } from '../../../model/appModel.service';

const COMPETITORS: {[index: string]: boolean} = {
  // 'ITSU' : true,
  // 'SUBWAY' : true,
  // 'ABOKADO' : true,
  // 'LEON' : true,
  // 'VITAL INGREDIENT' : true,
  // 'TOSSED' : true,
  // 'WRAP IT UP' : true,
  // 'BIRLEY SANDWICHES' : true,
  // 'PURE' : true,
  // 'LE PAIN QUOTIDIEN' : true,
  // 'CHOPD' : true,
  // 'TORTILLA' : true,
  // 'PONCHO8' : true,

  "ABOKADO" : true,
  "CAFFE NERO" : true,
  "CRUSSH" : true,
  "EAT." : true,
  "ITSU" : true,
  "JOE AND THE JUICE" : true,
  "LEON" : true,
  "PAUL" : true,
  "POD" : true,
  "PONCHO8" : true,
  "PRET A MANGER" : true,
  "STARBUCKS" : true,
  "TOSSED" : true,
  "VITAL INGREDIENT" : true
}


@Component({
  selector: 'cocodimama-benchmark-report',
  templateUrl: './cocodimamaBenchmark.html'
})
export class CodcodimamaBenchmarkComponent extends SubscriberComponent {
  currentSorting: string = 'fascia'
  inverseSorting = false
  locations: any[]
  loading: Promise<any>
  
  columns = [
    {key: "fascia", label: "Name"},
    {key: "street", label: "Street"},
    {key: "town", label: "Town"},
    {key: "postcode", label: "Postcode"},
    {key: "value1", label: "Ped Flow"},
    // {key: "value2", label: "All"},
    // {key: "value3", label: "16-34"},
    // {key: "value4", label: "AB"},
    {key: "value5", label: "All"},
    {key: "value6", label: "16-34"},
    {key: "value7", label: "AB"},
    // {key: "value8", label: "Complementary"},
    {key: "value9", label: "Competitor"},
  ]

  constructor(
    readonly appModel: AppModel,
    private indicators: Indicators,
    private statisticsEndpoint: StatisticsEndpoint) {
    super()

    this.watch(this.appModel.maxiReportModel.benchmarkCocodiMama.loading$, loading => {
      this.loading = this.indicators.add(loading)
    })

    this.watch(this.appModel.maxiReportModel.benchmarkCocodiMama.values$, locations => {
      if (locations) {
        this.locations = locations

        setTimeout(async () => {
          this.updateSort()
        })
      }
    })
  }

  async export() {
    await this.loading

    return {locations: this.locations, columns: this.columns, fileName: 'benchmark_coco_di_mama'}
  }


  sortByColumn(column: string) {
    if (this.currentSorting == column) {
      this.inverseSorting = !this.inverseSorting
    }

    this.currentSorting = column
    this.updateSort()
  }

  updateSort() {
    arrays.sortBy(this.locations, this.currentSorting, this.inverseSorting)
  }
}