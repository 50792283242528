import {AppModel} from '../../model/appModel.service';
import {Component, Input} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {PlaceEndpoint} from '../../api/place';
import { Indicators, SubscriberComponent } from '@targomo/client';
import { Place, PlanningApplicationDataSetEndpoint } from "../../api/index";
import { PlanningApplication } from '../../../common/models';
// const urlRegex = require('url-regex')

const ipRegex = require('ip-regex')
const tlds = require('tlds')

// Copied due to ie11
const urlRegex = (opts: any) => {
	opts = Object.assign({strict: true}, opts);

	const protocol = `(?:(?:[a-z]+:)?//)${opts.strict ? '' : '?'}`;
	const auth = '(?:\\S+(?::\\S*)?@)?';
	const ip = ipRegex.v4().source;
	const host = '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)';
	const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
	const tld = `(?:\\.${opts.strict ? '(?:[a-z\\u00a1-\\uffff]{2,})' : `(?:${tlds.sort((a: any, b: any) => b.length - a.length).join('|')})`})\\.?`;
	const port = '(?::\\d{2,5})?';
	const path = '(?:[/?#][^\\s"]*)?';
	const regex = `(?:${protocol}|www\\.)${auth}(?:localhost|${ip}|${host}${domain}${tld})${port}${path}`;

	return opts.exact ? new RegExp(`(?:^${regex}$)`, 'i') : new RegExp(regex, 'ig');

} // end copy

const BLACKLISTED: {[id: string]: boolean} = {
  'Filter_Date': true
}


const LABELS = {
  storeId:                 'ID',
  lat:                     'Lat',
  lng:                     'Lng',
  primaryCategory:         'Primary Category',
  holdingCompany:          'Holding Company',
  fascia:                  'Fascia',
  sitename:                'Site Name',
  paon:                    'Primary Addressable Object Name',
  saon:                    'Secondary Addressable Object Name',
  taon:                    'Tertiary Addressable Object Name',
  street:                  'Street',
  suburb:                  'Suburb',
  town:                    'Town',
  postcode:                'Post Code',
  district:                'District',
  county:                  'County',
  region:                  'Region',
  netSalesArea:            'Net Sales Area',
  netSalesAreaSource:      'Net Sales Area Source',
  grossInternalArea:       'Gross Internal Area',
  grossInternalAreaSource: 'Gross Internal Area Source',

  planningApp:              'Planning Application',
  linkToPlanning:           'Link To Planning',
  appDate:                  'Application Date',
  decisionDate:             'Decision Date',
  openDate:                 'Open Date',
  latestComment:            'Latest Comment',
  dateChecked:              'Date Checked',
}

const LABELS_MAIN = [
  'storeId',
  // 'lat',
  // 'lng',
  'sitename',
  'fascia',
  'primaryCategory',
  'holdingCompany',
]

const LABELS_ADDRESS = [
  'paon',
  'saon',
  'taon',
  'street',
  'suburb',
  'town',
  'postcode',
  'district',
  'county',
  'region'
]

const LABELS_VALUES = [
  'netSalesArea',
  'netSalesAreaSource',
  'grossInternalArea',
  'grossInternalAreaSource',

  'planningApp',
  'linkToPlanning',
  'appDate',
  'decisionDate',
  'openDate',
  'latestComment',
  'dateChecked',
]

const NUMBERS = {
  netSalesArea: true,
  grossInternalArea: true
}

const DATES = {
  appDate: true,
  decisionDate: true,
  openDate: true,
  dateChecked: true,
}

@Component({
  selector: 'place-details',
  templateUrl: './placeDetails.html',
  styleUrls: ['./sideTabs.less']
})
export class PlaceDetailsComponent extends SubscriberComponent {
  @Input() location: Place | PlanningApplication
  @Input() back: boolean = true
  @Input() fetch: boolean = true

  detailsMain: any = []
  detailsAddress: any = []
  detailsValues: any = []
  detailsOther: any = []
  numbers = NUMBERS
  dates = DATES

  constructor (
    readonly appModel: AppModel, 
    private placeEndpoint: PlaceEndpoint, 
    private indicators: Indicators,
    private planningEndpoint: PlanningApplicationDataSetEndpoint,
  ) {
    super()
  }

  ngOnInit() {
    if (!this.location) {
      this.watch(this.appModel.places.selectedPlace, place => {
        this.show(place)
      })

      this.watch(this.appModel.places.selectedPlanningPlace, async item => {
        if (item) {
          if (item.type === 'location') {
            const place = await this.indicators.add(this.placeEndpoint.get(item.location))
            this.show(<Place>place, true)
          } else {
            const place = await this.indicators.add(this.planningEndpoint.getApplication(item.location))
            this.show(place, true)
          }
        }
      })      
    } else {
      this.show(this.location)
    }
  }

  ngOnChanges() {
    if (this.location) {
      this.show(this.location)
    }
  }

  ngOnDestroy() {
    this.appModel.places.selectedPlace.next(null)
    super.ngOnDestroy()
  }

  async show(place: {id?: number}, alreadyFetched = false) {

    if (!place) {
      this.detailsMain = []
      this.detailsAddress = []
      this.detailsValues = []
      return
    }

    const processOther = (properties: any) => {
      if (!properties) {
        return []
      }

      const result: any = []
      for (let key in properties) {
        if (!BLACKLISTED[key]) {
          let value = properties[key]
          let isUrl = urlRegex({exact: true}).test(value)
          let url = value

          // No protocol at beginning so add http:// by default
          if (!(/[a-z]+:/i.test(url))) {
            url = 'http://' + url
          }

          result.push({key, name: key, value, isUrl, url})
        }
      }

      return result
    }

    const isGeoJSON = place && (<any>place).properties && (<any>place).properties.isGeoJSON

    if (Number(place.id) < 0 && !isGeoJSON) {
      const process = (key: string) => {
        return {key: key, name: (<any>LABELS)[key], value: (<any>place)[key]}
      }
      // this.detailsMain = [{key: 'fascia', name: LABELS['fascia'], value: (<any>place)['fascia']}]
      this.detailsMain    = LABELS_MAIN.map(process)
      this.detailsAddress = LABELS_ADDRESS.map(process)
      this.detailsValues  = LABELS_VALUES.map(process)

    } else if (Number(place.id) < 0 && isGeoJSON) {
      const process = (key: string) => {
        return {key: key, name: (<any>LABELS)[key], value: (<any>place)[key]}
      }

      this.detailsMain    = LABELS_MAIN.map(process)
      this.detailsAddress = LABELS_ADDRESS.map(process)
      this.detailsValues  = LABELS_VALUES.map(process)
      this.detailsOther   = processOther((<any>place).other)
    } else {
      const result = (this.fetch && !alreadyFetched) ? await this.indicators.add(this.placeEndpoint.get({id: place.id})) : place

      const process = (key: string) => {
        return {key: key, name: (<any>LABELS)[key], value: (<any>result)[key]}
      }

      this.detailsMain    = LABELS_MAIN.map(process)
      this.detailsAddress = LABELS_ADDRESS.map(process)
      this.detailsValues  = LABELS_VALUES.map(process)
      this.detailsOther   = processOther((<any>result).other)      
    }
  }
}
