<div>
  <mat-slider
      color="primary"
      [max]="max"
      [min]="min"
      [step]="step"
      [value]="maxValue"
      (change)="changeMax($event)"
      >
  </mat-slider>

  <mat-slider
    color="primary"
    class="slider-above"
      [max]="max"
      [min]="min"
      [step]="step"
      [value]="minValue"
      (change)="changeMin($event)"
      >
  </mat-slider>
</div>