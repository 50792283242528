<section style="width: 80vw; max-width: 350px">
  <div class="dialog-logo">
    <img *ngIf="logo" [src]="logo" />
  </div>

  <form [formGroup]="form" (ngSubmit)="save()">
    <div style="margin: 16px 0px">
      <mat-form-field style="width: 100%">
        <input matInput placeholder="Label" type="text" name="label" formControlName="label" />
      </mat-form-field>
    </div>

    <div class="dialog-bottom">
      <button mat-button (click)="dialogRef.close()" mat-dialog-close>
        <span translate="dialog.cancel">Cancel</span>
      </button>
      <button mat-raised-button color="primary" mat-button><span translate="dialog.save">Save</span></button>
    </div>
  </form>
</section>
