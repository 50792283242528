<p>Select your default travel-time colours or define your own:</p>

<div style="text-align: left; margin-bottom: 16px">
  <button color="primary" (click)="editCustomTravelColors()" mat-button>Edit custom travel colours</button>
</div>

<select-image-list
  (selectionChange)="changeColor($event)"
  [selected]="settings.travelColorsRangeNameUpdates | async"
  [options]="colorRanges"
></select-image-list>
