import { AppModel } from './appModel.service'
import { combineLatest, Observable, Subject } from 'rxjs'
import { shareReplay, map, startWith } from 'rxjs/operators'
import { object as objects } from '@targomo/common'
import { Place } from '../api/place'
import { Category } from '../api'
import { AbstractLocation } from './entities/place'

const COMBINE_THRESHOLD = 10

export interface SourcesTitleFascia {
  name: string
  count: number
  category: Category
}

export interface SourcesTitle {
  zoneSelectionExists: boolean
  zoneSelectionTitle?: string
  sources?: AbstractLocation[]
  fascias?: SourcesTitleFascia[]
  combined?: boolean
}

export class SourcesTitleModel {
  value$: Observable<SourcesTitle>

  constructor(private appModel: AppModel) {
    this.value$ = this.init()
  }

  private init() {
    return combineLatest(
      this.appModel.places.sources.observable,
      this.appModel.zoneLayersModel.selectionExistsUpdates,
      this.appModel.zoneLayersModel.selectedZones,
      this.appModel.places.generalPropertyUpdate$.pipe(startWith(null))
    ).pipe(
      map(([sources, zoneSelectionExists, selectedZones]) => {
        if (selectedZones && selectedZones.length) {
          const zoneSelectionTitle = selectedZones[0].name || 'Selected Zone'

          return {
            zoneSelectionExists,
            zoneSelectionTitle,
          }
        } else {
          let fascias = null

          const combined = sources.length > COMBINE_THRESHOLD
          if (sources && combined) {
            fascias = objects.values(
              sources.reduce((acc, cur) => {
                if (acc[cur.category.name]) {
                  acc[cur.category.name].count++
                } else {
                  acc[cur.category.name] = { name: cur.category.name, count: 1, category: cur.category }
                }
                return acc
              }, {} as { [key: string]: SourcesTitleFascia })
            )

            sources = null
          } else {
            fascias = null
          }

          return {
            zoneSelectionExists: false,
            sources,
            fascias,
            combined,
          }
        }
      }),
      shareReplay(1)
    )
  }
}
