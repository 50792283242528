<section style="width: 80vw; max-width: 350px">
  <h3 mat-dialog-title>Share Session</h3>

  <div class="hint"></div>

  <form>
    <mat-form-field style="width: 100%">
      <input style="width: 100%" matInput [(ngModel)]="email" name="email" placeholder="Email" required/>
    </mat-form-field>

    <div class="dialog-bottom">
      <button mat-button mat-dialog-close><span >Cancel</span></button>
      <button mat-raised-button (click)="dialog.close(email)" color="primary" mat-button><span >Share</span></button>
    </div>
  </form>
</section>