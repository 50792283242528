import { Indicators } from '@targomo/client'
import { DataSetLocation } from './index'
import {
  TravelTypeEdgeWeightOptions,
  ReachableTile,
  StatisticsSet,
  StatisticsKey,
  LatLngProperties,
} from '@targomo/core'
import { STATISTICS, STATISTICS_MAP, GREAT_BRITAIN_HEX_500M, GREAT_BRITAIN_HEX_100M } from './constants'
import { ObservableList } from '@targomo/client'
import { Place } from '../api/place'
import { array as arrays } from '@targomo/common'
import { DisplaySettings, TravelDisplayMode } from './settingsModel'
import { ExtendedBehaviorSubject } from '../util/extendedBehaviorSubject'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'

// TODO: get rid of this class, but for now lets get eveytihng to compile
export class LocalMapModel {
  // public readonly hoverMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public readonly hoverWorkforce: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public readonly hoverPedestrian: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public readonly hoverRoadsVolume: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public readonly clickRoadsVolume: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public readonly contextRoadsVolume: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  public readonly travelOptions: ExtendedBehaviorSubject<TravelTypeEdgeWeightOptions> =
    new ExtendedBehaviorSubject<any>(this.initTravelTypeEdgeWeightOptions())
  public readonly options: ExtendedBehaviorSubject<DisplaySettings> = new ExtendedBehaviorSubject<any>(
    new DisplaySettings()
  )
  public readonly locations: ExtendedBehaviorSubject<LatLngProperties[]> = new ExtendedBehaviorSubject<any>([])

  public readonly sources: ExtendedBehaviorSubject<LatLngProperties[]> = new ExtendedBehaviorSubject<any>([])
  public readonly selected: ExtendedBehaviorSubject<LatLngProperties[]> = new ExtendedBehaviorSubject<any>([])
  public readonly polygons: ExtendedBehaviorSubject<any> = new ExtendedBehaviorSubject<any>(null)

  public attributonExtra = '<span class="mi-attribution">Geocoding by Esri</span>, '

  constructor(private indicators: Indicators, private sourcesList: ObservableList<Place>) {
    // this.travelOptions.next(new TravelTypeEdgeWeightOptions('https://api.targomo.com/germany/', 'TOAZN1AACARCKE81TH3YJYL', 600, 'car'))
    // this.travelOptions.next(this.initTravelTypeEdgeWeightOptions())

    const options = new DisplaySettings()
    options.statistic = STATISTICS[0]

    this.options.next(options)
  }

  initTravelTypeEdgeWeightOptions() {
    const options: TravelTypeEdgeWeightOptions = { maxEdgeWeight: 600, travelType: 'car' }

    //// BEGIN DEV
    // options.statisticsUrl = 'https://dev.route360.net/statistics' //dev
    // options.serverUrl = 'https://dev.route360.net/statistics' // dev
    // options.serviceUrl = 'https://localhost:8097/' // dev
    // options.serviceKey = 'T6TGCZJHAGE9DJWC4GE7J6N' // dev key
    // END DEV

    return options
  }

  // getStatisticsLayer(zoom: number): string {
  //   return null//`germany_zensus_2000m_2011`
  // }

  getStatisticsEndpoint(zoom: number): StatisticsSet {
    if (zoom >= 12) {
      return GREAT_BRITAIN_HEX_100M
    } else {
      return GREAT_BRITAIN_HEX_500M
    }
  }

  getAllStatisticsKeys(): StatisticsKey[] {
    return STATISTICS_MAP
    // return [STATISTICS[0]]
  }

  // getReachable(zoom: number): Promise<ReachableTile> {
  //   const options = <DisplaySettings>this.options.getValue()
  //   if (options.isochrones)
  //     return Promise.resolve({})
  //   else
  //     return this.indicators.add(this.getReachableForSources(zoom, this.sourcesList.getValues()))
  // }

  // getStatisticsLayer(zoom: number) {
  //   const options = <DisplaySettings>this.options.getValue()
  //   if (options.travelDisplayMode == TravelDisplayMode.NoThematicPolygons)
  //     return null

  //   return super.getStatisticsLayer(zoom)
  // }
}
