import * as models from '../../common/models'
import { Injectable } from '@angular/core'
import { RESTHelper } from '@targomo/client'
import { DisplaySettings, PdfReportSettings, PrintReportSettings } from '../model/settingsModel'

@Injectable()
export class UserSettingsEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/userSettings')
  }

  load() {
    return this.http.get<DisplaySettings | false>(`/`)
  }

  save(savedSession: DisplaySettings) {
    return this.http.put(`/`, savedSession)
  }

  loadDefaultProject() {
    return this.http.get<number>(`/defaultProject`)
  }

  saveDefaultProject(id: number) {
    return this.http.put(`/defaultProject`, { id })
  }

  loadReport() {
    return this.http.get<
      models.UserSettings & { reportTemplate: PdfReportSettings } & { customReport: PrintReportSettings }
    >(`/customReport`)
  }
}
