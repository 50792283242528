<div class="admin-panel">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Zone Layers</h2>
    <div class="secondary">
      <button mat-button class="mat-primary" routerLink="/admin/zone/upload"><mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> Upload Zone Layer Set</button>
    </div>
  </div>    


  <mat-list>
    <!-- <mat-divider></mat-divider> -->
    <mat-list-item *ngFor="let set of dataSets" routerLink="/admin/zone/{{set.id}}">
      <div class="name-section" fxFlex fxLayout="column" >
        <span>{{set.name}}</span>
      </div>

      <button (click)="showMenu($event)" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="mat-24">more_vert</mat-icon></button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/admin/zone/{{set.id}}" aria-label="Edit">
          <mat-icon mat-font-set="material-icons">mode_edit</mat-icon>
          Edit Zone Layer
        </button>
      </mat-menu>
    </mat-list-item>
  </mat-list>
</div>