import { Component, OnInit, Input } from '@angular/core'
import { Place } from '../../../api/place'
import { SubscriberComponent, files } from '@targomo/client'
import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { map, withLatestFrom, shareReplay, take } from 'rxjs/operators'
import { MatchpointModel } from '../../../model/matchpoint/matchpointModel'
import { MatTableDataSource, Sort } from '@angular/material'
import { array as arrays } from '@targomo/common'
import { AppModel } from '../../../model/appModel.service'
import { MatchpointEndpoint } from '../../../api/matchpoint'
import { csv } from '../../../util/csv'

@Component({
  selector: 'matchpoint-similar-centres',
  templateUrl: './matchpointSimilarCentres.component.html',
  styleUrls: ['./matchpointSimilarCentres.component.less'],
})
export class MatchpointSimilarCentresComponent extends SubscriberComponent implements OnInit {
  @Input() readonly model: MatchpointModel
  categories$: Observable<string[]>
  filter: string

  similarFilterSubject = new BehaviorSubject<string>('')
  similarFiltered$: Observable<Place[]> = null
  places: Place[] = []
  placesTruncated = false
  placesTotal = 0

  dataSource: MatTableDataSource<Place>
  sort: Sort
  displayedColumns = ['name', 'secondaryCategory', 'street', 'town', 'postcode', 'select', 'blacklist']

  constructor(
    private appModel: AppModel, // TODO:
    private matchpointEndpoint: MatchpointEndpoint
  ) {
    super()
  }

  async ngOnInit() {
    this.categories$ = this.model.similarCategories$
    // this.similarFiltered$ = this.initSimilarFiltered()

    this.watch(this.initSimilarFiltered(), (placesState) => {
      this.places = placesState.places
      this.placesTruncated = placesState.truncated
      this.placesTotal = placesState.total

      this.updatePage()
    })

    this.watch(this.model.similarActiveAnalysis$, () => {
      this.updatePage()
    })
  }

  sortData(sort: Sort) {
    this.sort = sort
    this.updatePage()
  }

  private updatePage() {
    if (this.places) {
      this.places.forEach((place: any) => {
        place.matchpointSitenameOrFascia = place.sitename || place.fascia
      })

      if (this.sort) {
        this.places = arrays.sortBy(this.places, this.sort.active, this.sort.direction === 'desc')
      } else {
        this.places = arrays.sortBy(this.places, 'name')
      }

      const selectedPlaces = this.places.filter((row) => this.model.isSimilarSelectedForAnalysis(row))
      const unselectedPlaces = this.places.filter((row) => !this.model.isSimilarSelectedForAnalysis(row))

      this.dataSource = new MatTableDataSource([].concat(selectedPlaces, unselectedPlaces))
    }
  }

  click(item: any) {}

  filterChanged(event: any) {
    this.similarFilterSubject.next(event)
  }

  private initSimilarFiltered() {
    const contains = (item: any, text: string) => {
      return (item || '').toLowerCase().indexOf(text) > -1
    }

    return combineLatest(
      this.similarFilterSubject,
      this.model.allCentrePoints,
      // this.model.similar$,
      this.model.similarAndOther$,
      this.appModel.places.sources.observable
    ).pipe(
      map(([filter, allCentres, similar, sources]) => {
        filter = (filter || '').trim().toLowerCase()
        if (!filter) {
          return { places: similar, total: similar.length, truncated: false }
        } else {
          const sourcesMap = (sources || []).reduce((acc, cur) => {
            acc[cur.id] = true
            return acc
          }, {} as any)

          const result = (allCentres || []).filter((item) => {
            return (
              !sourcesMap[item.id] &&
              (contains(item.name, filter) ||
                contains(item.defaultName, filter) ||
                contains((item as any).matchpointSitenameOrFascia, filter))
            )
          })

          if (result.length > 100) {
            return { places: result.slice(0, 100), total: result.length, truncated: true }
          } else {
            return { places: result, total: result.length, truncated: false }
          }
        }
      }),
      shareReplay(1)
    )
  }

  async downloadCsv() {
    // const travelOptions = await this.appModel.settings.travelOptionsUpdates.pipe(take(1)).toPromise()
    // let config: any[] = await this.appModel.settings.matchpointUserSettingsUpdates.pipe(take(1)).toPromise()
    // if (!config) {
    //   // TODO
    //   config = await this.matchpointEndpoint.getSettingsPublic()
    // }

    // const results = await this.matchpointEndpoint.getSimilarExport(
    //   this.places,
    //   config,
    //   travelOptions.maxEdgeWeight,
    //   travelOptions.travelType
    // )

    const exported = csv(
      this.places.map((item: any) => {
        // const { other, properties, statistics, ...self } = item

        return {
          name: item.name,
          category: item.category.name,
        }
      })
    )

    files.saveFile(exported, 'text/csv', 'storepointgeo_matchpoint_similar.csv')
  }
}
