<form>
  <mat-form-field floatPlaceholder="never">
    <input matInput [ngModel]="searchString" [placeholder]="placeholder" [matAutocomplete]="auto" [formControl]="geosearchControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-progress-bar *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>

      <mat-option *ngFor="let suggestion of suggestions | async" [value]="suggestion">
        <span>{{ suggestion.toString() }}</span>
      </mat-option>

    </mat-autocomplete>
  </mat-form-field>
</form>