<tgm-loadable [model]="model?.loading$ | async">
  <div fxLayout="row" class="options-header" style="margin: 8px" fxLayoutGap="16px" fxLayoutAlign="end center">
    <div fxFlex>
      <matchpoint-category-filter [model]="model"></matchpoint-category-filter>
    </div>
    <button (click)="downloadCsv()" mat-button>Download List as CSV</button>

    <mat-checkbox
      [disabled]="!(model.isTownCentre$ | async)"
      (change)="model.setIncludeTownCenters($event.checked)"
      [checked]="model.includeTownCenters$ | async"
      >Include Town Centre Shopping Centre Locations in Town Centres</mat-checkbox
    >
  </div>

  <table
    style="width: 100%"
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
    matSortActive="secondaryCategory"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="fascia">Fascia</th>
      <td mat-cell *matCellDef="let row">
        <span class="checkbox" [style.background]="row.category.color || '#ddd'"></span>
        {{ row.fascia }}
      </td>
    </ng-container>

    <ng-container matColumnDef="secondaryCategory">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="secondaryCategory">Category</th>
      <td mat-cell *matCellDef="let row">{{ row.secondaryCategory }}</td>
    </ng-container>

    <ng-container matColumnDef="centerPointPercent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="centerPointPercent">Present in Centres</th>
      <td mat-cell *matCellDef="let row">{{ row.centerPointPercent | percent }}</td>
    </ng-container>

    <ng-container matColumnDef="centerPointAtToMap">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="centerPointAtToMap">Add to Map</th>
      <td (click)="toggleCategoryVisible(row.category)" mat-cell *matCellDef="let row">
        <mat-checkbox style="pointer-events: none" [checked]="row.category.visible"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="centerPointTravelTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="centerPointTravelTime">In Travel Time (mins)</th>
      <td mat-cell *matCellDef="let row">{{ row.travelTime | minutesHoursSimple }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</tgm-loadable>
