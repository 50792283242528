import { ReportModel } from "./exportPdfReport";
import { PDFHelper } from "./helper";
import { CensusStatisticsGroup } from "../../../common/models/statistics/statistics";
import { largeWorkforceTableWithIndex, largeWorkforceTableTimes } from "./largeFormatTableHelpers";
import { graphWorkforce, graphValueIndex } from "./graphs";


const LABELS_AGES = {
  "aged16to24": "16 to 24",
  "aged25to34": "25 to 34",
  "aged35to44": "35 to 44",
  "aged45to54": "45 to 54",
  "aged55to64": "55 to 64",
  "aged65to74": "65 to 74",
}

const LABELS_SOCIAL_GRADE = {
  "ab": "AB",
  "c1": "C1",
  "c2": "C2",
  "de": "DE",
}

const LABELS_OCCUPATIONS = {
  "managersDirectorsSeniorOfficials": "Managers, directors and senior officials",
  "professional": "Professional occupations",
  "associateProfessional": "Associate professional and technical occupations",
  "adminSecretarial": "Administrative and secretarial occupations",
  "skilledTrades": "Skilled trades occupations",
  "caringLeisureOtherService": "Caring, leisure and other service occupations",
  "salesCustomerService": "Sales and customer service occupations",
  "processPlantMachineOperatives": "Process, plant and machine operatives",
  "elementaryOccupations": "Elementary occupations",
}

const LABELS_INDUSTRIES = {
  "agricultureForestryFishing": "Agriculture, forestry and fishing",
  "miningQuarrying": "Mining and quarrying",
  "manufacturing": "Manufacturing",
  "electricityGasSteam": "Electricity, gas, steam and air conditioning supply",
  "waterSewerageWasteManagement": "Water supply, sewerage, waste management and remediation activities",
  "construction": "Construction",
  "wholesaleRetailTradeRepairMotorVehicles": "Wholesale and retail trade; repair of motor vehicles and motor cycles",
  "transportStorage": "Transport and storage",
  "accomodationFoodServices": "Accommodation and food service activities",
  "informationCommunication": "Information and communication",
  "financialInsuranceActivities": "Financial and insurance activities",
  "realEstateActivities": "Real estate activities",
  "professionalScientificTechnicalActivities": "Professional, scientific and technical activities",
  "administrativeSupportServiceActivities": "Administrative and support service activities",
  "publicAdministrationDefenceCompulsorySocialSecurity": "Public administration and defence; compulsory social security",
  "education": "Education",
  "humanHealthSocialWorkActivities": "Human health and social work activities",
  "artsEntertainmentRecreationOtherServiceActivities": "Arts, entertainment and recreation; other service activities",
  "other1": "Activities of households as employers; undifferentiated goods - and services - producing activities of households for own use",
  "other2": "Activities of extraterritorial organisations and bodies",
}

const LABELS_GENDER = {
  "male": "Male",
  "female": "Female",
}


export function workforceReport(reportModel: ReportModel, helper: PDFHelper) {
  const result: any[] = [
    [{pageBreak: 'before', pageOrientation: 'portrait', text: ''}],
    [helper.fullWidthHeader('Workforce demographics by age')],
    largeWorkforceTableWithIndex(reportModel, helper, LABELS_AGES, reportSegment => reportSegment.statistics.census.workerAges),    
    {
      image: graphWorkforce(helper, reportModel[reportModel.length - 1].statistics.census.workerAges, LABELS_AGES),
      width: 540,
      // width: 770,
    },

    [{pageBreak: 'before', pageOrientation: 'portrait', text: ''}],
    [helper.fullWidthHeader('Workforce demographics by age')],
    largeWorkforceTableTimes(reportModel, helper, LABELS_AGES, reportSegment => reportSegment.statistics.census.workerAges),

    [{pageBreak: 'before', pageOrientation: 'portrait', text: ''}],
    [helper.fullWidthHeader('Workforce demographics by social grade')],
    largeWorkforceTableWithIndex(reportModel, helper, LABELS_SOCIAL_GRADE, reportSegment => reportSegment.statistics.census.socialClassWorkforce),
    {
      image: graphWorkforce(helper, reportModel[reportModel.length - 1].statistics.census.socialClassWorkforce, LABELS_SOCIAL_GRADE),
      width: 540,
      // width: 770,
    },

    [{pageBreak: 'before', pageOrientation: 'portrait', text: ''}],
    [helper.fullWidthHeader('Workforce demographics by social grade')],
    largeWorkforceTableTimes(reportModel, helper, LABELS_SOCIAL_GRADE, reportSegment => reportSegment.statistics.census.socialClassWorkforce),

    [{pageBreak: 'before', pageOrientation: 'portrait', text: ''}],
    [helper.fullWidthHeader('Workforce demographics by occupation')],
    largeWorkforceTableWithIndex(reportModel, helper, LABELS_OCCUPATIONS, reportSegment => reportSegment.statistics.census.occupations),

    [{pageBreak: 'before', pageOrientation: 'portrait', text: ''}],
    [helper.fullWidthHeader('Workforce demographics by industry')],
    largeWorkforceTableWithIndex(reportModel, helper, LABELS_INDUSTRIES, reportSegment => reportSegment.statistics.census.industries),

    // [{pageBreak: 'before', pageOrientation: 'portrait', text: ''}],
    // [helper.fullWidthHeader('Workforce demographics by occupation')],
    // largeWorkforceTableTimes(reportModel, helper, LABELS_OCCUPATIONS, reportSegment => reportSegment.statistics.census.occupations),
    
  ]

  return result
}
