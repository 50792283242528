<div class="no-print" style="position: relative; min-height: 50px">
  <div style="margin-bottom: 16px">
    <div class="dialog-bottom" style="text-align: left">
      <button mat-button (click)="dialogRef.close()">
        <mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon>
        <span translate="dialog.back">Back</span>
      </button>
    </div>
  </div>
</div>

<section style="margin: 20px">
  <source-title-panel></source-title-panel>

  <mat-tab-group (selectedTabChange)="changeTab($event)">
    <mat-tab label="Similar Centres">
      <matchpoint-similar-centres [model]="model"></matchpoint-similar-centres>
    </mat-tab>

    <mat-tab label="Subject Centre Operators">
      <matchpoint-subject-centre-operators [model]="model"></matchpoint-subject-centre-operators>
    </mat-tab>

    <mat-tab label="Gap Opportunities">
      <matchpoint-gap-opportunities [model]="model"></matchpoint-gap-opportunities>
    </mat-tab>

    <mat-tab label="Selection Criteria">
      <matchpoint-config-panel [model]="model"></matchpoint-config-panel>
    </mat-tab>
  </mat-tab-group>
</section>
