<p>Choose what you want displayed on labels, selecting multiple boxes will expand the label content</p>

<div class="title">Example (based on selection): {{ example }}</div>
<div class="content">
  <div class="option-line" *ngFor="let option of options" (click)="toggle(option.key)">
    <mat-checkbox [checked]="value[option.key]">
      {{ option.label }}
    </mat-checkbox>
  </div>
</div>
