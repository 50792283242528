import { Injectable } from '@angular/core'
import { AppModel } from './appModel.service'
import { UserSettingsEndpoint } from '../api/userSettings'
import { DisplaySettings } from './settingsModel'
import { STATISTICS } from './constants'
import { LocationIconModel } from './locationIconModel'

function comparisonFunction(v1: DisplaySettings, v2: DisplaySettings) {
  return JSON.stringify(v1) !== JSON.stringify(v2)
}

@Injectable()
export class UserSettingsPersistenceService {
  private loading: Promise<{}> = null

  constructor(private appModel: AppModel, private userSettingsEndpoint: UserSettingsEndpoint) {}

  public async ready() {
    return this.loading
  }

  public init() {
    this.loading = this.initRun()
  }

  private async initRun() {
    const loaded = await this.userSettingsEndpoint.load()
    if (loaded) {
      const current = await this.appModel.settings.displaySettings.take(1).toPromise()
      // Ensure old saved sessions work when a new key is added
      for (let key in loaded) {
        if ((<any>loaded)[key] != null) {
          ;(<any>current)[key] = (<any>loaded)[key]
        }
      }

      if (loaded.statistic) {
        current.statistic = STATISTICS.filter((statistic) => statistic.id === loaded.statistic.id)[0]
        if (!current.statistic) {
          current.statistic = STATISTICS[0] // don;'t allow undefined
        }
      }

      current.cellHover = false

      current.intersectionMode = 'union'
      current.exclusiveTravel = false

      current.showOnlyMappedCategories = false // don't save this
      current.markerStyleReport = null
      current.markerStylePrint = false

      current.markerSizeMinMax = null
      current.dateAddedQuarterMinMax = null
      current.populationForecastRange = null

      current.travelTimeDistanceSorting = 'travelTime'
      current.showStoresWithinTravelTime = false
      current.dataSetFilters = {}
      current.secondaryLocationTypeFilters = {}
      current.showStoresWithinNoAreaData = true
      // current.matchpointUserSettings = null

      current.pointAndClickMode = false
      current.pointAndClickSource = null

      current.customLocationsColorsPalette = {}
      current.locationIconModel = new LocationIconModel({})

      this.appModel.settings.displaySettings.next(current)
    }

    this.appModel.settings.defaultLoadSessionId.next(await this.userSettingsEndpoint.loadDefaultProject())

    this.appModel.settings.defaultLoadSessionId
      .distinctUntilChanged()
      .debounceTime(500)
      .subscribe((id) => {
        this.userSettingsEndpoint.saveDefaultProject(id)
      })

    return this.appModel.settings.displaySettings
      .distinctUntilChanged(comparisonFunction)
      .debounceTime(500)
      .subscribe((settings) => {
        this.userSettingsEndpoint.save(settings)
      })
  }
}
