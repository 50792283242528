import {Settings} from '../../common/models'
import {RESTHelper} from '@targomo/client';
import {Injectable} from '@angular/core';
import { Place } from './place';
import { StatisticsGeometryResult, LatLngId, StatisticsGeometryRequestOptions } from '@targomo/core';
import { VersionsEndpoint } from './types';

export class ZoneSelection {
  name: string
  features: string[]
  layer: number
  selected: boolean
  geometry?: any
  color?: string
  children?: ZoneSelection[]
  freehand?: boolean
}

export class ZoneLayer {
  id?: number
  name: string
}

export class ZoneLayerVersion {
  id?: number
  created: string
}

@Injectable()
export class ZoneLayersEndpoint implements VersionsEndpoint<ZoneLayer, ZoneLayerVersion> {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/zoneLayers')
  }

  getGeometries(layer: number, features: string[]) {
    return this.http.post<any>(`/geometries`, {layer, features})
  }

  getGeometriesGeojson(geometry: any) {
    return this.http.post<any>(`/geometries/geojson`, geometry)
  }

  getGeometriesMultiple(shapes: {layer: number, features: string[]}[]) {
    return this.http.post<any[]>(`/geometries/multiple`, shapes)
  }

  // getGeometries(layer: number, features: string[]) {
  //   return this.http.post<any>(`/geometries`, {layer, features})
  // }

  statistics(geometry: any, options: StatisticsGeometryRequestOptions): Promise<StatisticsGeometryResult> {
    return this.http.post<any>(`/statistics`, {geometry, options})
  }

  delete(set: {id: number}) {
    return this.http.delete<any>(`/${set.id}`)
  }

  getGeometriesFeatures(layer: number, shapes: {key: string, features: string[]}[]) {
    return this.http.post<any>(`/${layer}/geometries/features`, shapes)
  }

  get(set: {id: number}) {
    return this.http.get<any>(`/${set.id}`)
  }

  me() {
    return this.http.get<ZoneLayer[]>(`/me`)
  }
  
  findAll() {
    return this.http.get<ZoneLayer[]>(`/`)
  }

  getVersions(set: {id: number}) {
    return this.http.get<ZoneLayerVersion[]>(`/${set.id}/versions`)
  }

  saveActive(version: {id: number}, value: boolean = true) {
    return this.http.put(`/version/${version.id}/active/${value}`, {})
  }

  savePreview(version: {id: number}, value: boolean = true) {
    return this.http.put(`/version/${version.id}/preview/${value}`, {})
  }

  deleteVersion(version: {id: number}) {
    return this.http.delete<boolean>(`/version/${version.id}`)
  }
  
  save(layer: ZoneLayer) {
    return this.http.put<ZoneLayer>(`/${layer.id}`, layer)
  }  

  uploadMultipart(files: any, set?: {id?: number, name?: string}) {
    const data = new FormData();
    data.append('files', files[0])  
      
    if (set && isFinite(set.id)) {
      return this.http.put<number>(`/upload/${set.id}`, data)
    } else {
      return this.http.post<number>(`/upload/new/${encodeURIComponent(set.name)}`, data)
    }
  }  
}