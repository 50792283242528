import { Injectable } from '@angular/core'
import { Auth, MaxEdgeWeightOption, TRAVEL_COLORS } from '@targomo/client'
import { array as arrays } from '@targomo/common'
import {
  ExtendedStatisticsKey,
  LatLngId,
  LatLngIdTravelTime,
  ReachabilityClient,
  ReachableTile,
  requests,
  StatisticsClient,
  StatisticsRequestOptions,
  StatisticsResult,
  StatisticsSet,
  StatisticsSetMeta,
  StatisticsTravelRequestOptions,
  TargomoClient,
  TimeRequestOptions,
  TravelTypeEdgeWeightOptions,
} from '@targomo/core'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import 'rxjs/add/observable/fromPromise'
import 'rxjs/add/operator/pairwise'
import 'rxjs/add/operator/pluck'
import 'rxjs/add/operator/shareReplay'
import { Observable } from 'rxjs/Observable'
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators'
import { Place } from '../../common/models'
import {
  POPULATION_FORECAST_FROM_YEAR,
  POPULATION_FORECAST_TO_YEAR,
  ReportPerCapitaHousehold,
} from '../../common/models/statistics/statistics'
import { defaultDateTime } from '../../common/util/defaultTravelDateTime'
import { ZoneLayersEndpoint } from '../api/sectors'
import { ExtendedBehaviorSubject } from '../util/extendedBehaviorSubject'
import { STATISTICS, STATISTICS_WITH_NONE } from './constants'
import { CustomLocation, AbstractLocation, TemporaryCustomLocation } from './entities'
import { customCategory } from '../api'
import { LocationIconModel } from './locationIconModel'
export {
  POPULATION_FORECAST_FROM_YEAR,
  POPULATION_FORECAST_TO_YEAR,
  ReportPerCapitaHousehold,
} from '../../common/models/statistics/statistics'

export const BASE_MAP_TILER_URL = 'https://api.maptiler.com'
export const DEFAULT_MAP_TILER_KEY = 'SSJuBDWncR4eKUhD01yE'

export const NEW_MAP_STYLES = [
  { name: 'Light', key: 'positron', referenceLayer: 'tunnel_motorway_casing', image: 'options_map_light.png' },
  { name: 'Dark', key: 'darkmatter', referenceLayer: 'highway_path', image: 'options_map_dark.png' },
  { name: 'Basic', key: 'basic', referenceLayer: 'road_path', image: 'options_map_basic.png' },
  { name: 'Streets', key: 'streets', referenceLayer: 'tunnel_motorway_casing', image: 'options_map_streets.png' },
  { name: 'Topo', key: 'topo', referenceLayer: 'tunnel_motorway_casing', image: 'options_map_topo.png' },
  { name: 'Satellite', key: 'hybrid', referenceLayer: 'water', image: 'options_map_satellite.png' },
]

export const INTERSECTION_MODES = [
  { value: 'union', name: 'Combined' },
  { value: 'intersection', name: 'Overlap' },
  { value: 'exclusive', name: 'Exclusive' },
]

export function intersectionModeString(value: string) {
  const result = INTERSECTION_MODES.find((item) => item.value === value)
  return result && result.name
}

export const NEW_MAP_STYLES_MAP = NEW_MAP_STYLES.reduce((acc, cur) => {
  acc[cur.key] = cur

  return acc
}, {} as { [key: string]: { name: string; key: string; referenceLayer: string } })

export const OLD_MAP_STYLES_MAP: { [key: string]: { to: string; name: string; key: string; referenceLayer: string } } =
  {
    'osm-bright-gl-style': {
      to: 'basic',
      name: 'Bright',
      key: 'osm-bright-gl-style',
      referenceLayer: 'tunnel-service-track-casing',
    },
    'positron-gl-style': {
      to: 'positron',
      name: 'Light',
      key: 'positron-gl-style',
      referenceLayer: 'tunnel_motorway_casing',
    },
    'blueberry-gl-style': {
      to: 'positron',
      name: 'Blueberry',
      key: 'blueberry-gl-style',
      referenceLayer: 'tunnel_motorway_casing',
    },
    'dark-matter-gl-style': {
      to: 'darkmatter',
      name: 'Dark',
      key: 'dark-matter-gl-style',
      referenceLayer: 'highway_path',
    },
    'fiord-color-gl-style': {
      to: 'basic',
      name: 'Blues',
      key: 'fiord-color-gl-style',
      referenceLayer: 'tunnel_motorway_casing',
    },
    'klokantech-basic-gl-style': {
      to: 'basic',
      name: 'Basic',
      key: 'klokantech-basic-gl-style',
      referenceLayer: 'road_path',
    },
    'toner-gl-style': { to: 'positron', name: 'Toner', key: 'toner-gl-style', referenceLayer: 'water' },
  }

export function createStyleUrl(style: string) {
  return `${BASE_MAP_TILER_URL}/maps/${style}/style.json?key=${DEFAULT_MAP_TILER_KEY}`
}

export const INITIAL_MAP_STYLE = createStyleUrl('positron')

export class LocalreachabilityClient extends ReachabilityClient {
  async locations<T extends LatLngIdTravelTime>(
    sources: LatLngId[],
    targets: T[],
    options: TimeRequestOptions
  ): Promise<T[]> {
    const locations = await super.locations(sources, targets, options)
    const filteredLocations = locations.filter(
      (location) => location.travelTime > -1 && location.travelTime <= options.maxEdgeWeight
    )
    return filteredLocations
  }

  async combinedTimeDistance(
    sources: LatLngId[],
    targets: LatLngId[],
    options: TimeRequestOptions,
    includeShortestDistance = true
  ) {
    const shortestResult: Record<
      string,
      { sourceId: string; travelTime: number; fastestTravelDistance: number; shortestTravelDistance?: number }
    > = {}

    if (sources.length === 0 || targets.length === 0) {
      return shortestResult
    }

    const resultIndividual = await super.individual(sources, targets, options)

    let longestLength = 0

    resultIndividual.forEach((sourceResults) => {
      sourceResults.targets.forEach((target: any) => {
        const existingTarget = shortestResult[target.id]
        if (target.travelTime >= 0 && (!existingTarget || target.travelTime < existingTarget.travelTime)) {
          shortestResult[target.id] = {
            sourceId: sourceResults.id,
            travelTime: target.travelTime,
            fastestTravelDistance: target.length,
          }
        }

        longestLength = Math.max(longestLength, target.length)
      })
    })

    if (includeShortestDistance && longestLength > 0) {
      const result = await super.combined(sources, targets, {
        ...options,
        edgeWeight: 'distance',
        maxEdgeWeight: longestLength,
      })

      result.forEach((item) => {
        if (item.travelTime > -1) {
          shortestResult[item.id] =
            shortestResult[item.id] ||
            ({
              sourceId: item.source,
              travelTime: null,
              fastestTravelDistance: null,
            } as any)

          shortestResult[item.id].shortestTravelDistance = Math.min(
            item.travelTime,
            shortestResult[item.id] && shortestResult[item.id].fastestTravelDistance
          )
        }
      })
    }

    return shortestResult
  }
}

// FIXME: workaround because lib version here not latest
class LocalStatisticsClient extends StatisticsClient {
  async metadata(group: StatisticsSetMeta | StatisticsSet): Promise<StatisticsSetMeta> {
    const server = (this as any).client.config.tilesUrl
    const serviceKey = (this as any).client.serviceKey
    const key = typeof group == 'number' ? group : group.id
    const cacheKey = server + '-' + key

    return await (this as any).statisticsMetadataCache.get(cacheKey, async () => {
      const result = await requests((this as any).client).fetch(
        `${server}/statistics/meta/v1/${key}?key=${encodeURIComponent(serviceKey)}`
      )
      if (!result.name && result.names && result.names.en) {
        result.name = result.names.en
      }

      if (result.stats && result.stats.length) {
        result.stats.forEach((stat: any) => {
          if (!stat.name && stat.names && stat.names.en) {
            stat.name = stat.names.en
          }
        })
      }

      return result
    })
  }

  private prepareOptions<T extends StatisticsTravelRequestOptions>(input: T) {
    const options: any = input
    if (options.travelType === 'walk' || options.travelType === 'bike') {
      options.trafficJunctionPenalty = 4
      options.trafficSignalPenalty = 7
    }

    return options as T
  }

  async dependent(sources: LatLngId[], options: StatisticsRequestOptions): Promise<StatisticsResult> {
    return super.dependent(sources, this.prepareOptions(options))
  }

  async travelTimes(sources: LatLngId[], options: StatisticsTravelRequestOptions): Promise<ReachableTile> {
    const result = await super.travelTimes(sources, this.prepareOptions(options))
    const resultFiltered: ReachableTile = {}

    for (let key in result) {
      if (result[key] <= options.maxEdgeWeight) {
        resultFiltered[key] = result[key]
      }
    }

    return resultFiltered
  }
}

function initStatisticsDisabled() {
  try {
    const result =
      localStorage['STOREPOINTGEO_DISABLE_STATISTICS'] === 'true' ||
      localStorage['STOREPOINTGEO_DISABLE_STATISTICS'] === true
    return result
  } catch (e) {
    return false
  }
}

export interface TravelTimeRangeOption {
  name: string
  id: number
  options: MaxEdgeWeightOption[]
}

// return arrays.empty(6).map((value, i) => ({value: (i + 1) * 300, outlineColor: null, color: TRAVEL_COLORS[i], label: `${(i + 1) * 5}`}))

function makeTravelRange(increment: number) {
  return arrays.empty(6).map((value, i) => Math.round((i + 1) * increment))
}

function makeTravelRangeOptions(values: number[], colors = TRAVEL_COLORS) {
  return values.map((value, i) => ({
    value: value,
    outlineColor: null,
    color: TRAVEL_COLORS[i],
    label: `${Math.round(value / 60)}`,
  }))
}

export const TRAVEL_TIME_RANGE_OPTIONS: TravelTimeRangeOption[] = [
  {
    id: 0,
    name: '5 Min - 30 Min',
    options: makeTravelRangeOptions(makeTravelRange(5 * 60)),
  },
  {
    id: 1,
    name: '10 Min - 60 Min',
    options: makeTravelRangeOptions(makeTravelRange(10 * 60)),
  },
  {
    id: 2,
    name: '20 Min - 120 Min',
    options: makeTravelRangeOptions(makeTravelRange(20 * 60)),
  },
  {
    id: 3,
    name: '40 Min - 240 Min',
    options: makeTravelRangeOptions(makeTravelRange(40 * 60)),
  },
]

export interface MinMaxNumber {
  min: number
  max: number
}

export type MarkerSizeProperty = 'netSalesArea' | 'grossInternalArea'

export enum TravelDisplayMode {
  ThematicPolygonsInverted,
  ThematicNoPolygons,
  NoThematicPolygons,
}

export interface PrintReportSettings {
  printShowSizeLegend: boolean
  printShowFasciaLegend: boolean
}

export interface PdfReportSettings {
  competitionColumns: string[]
  sections: string[]
  borderColor: string
  customLogo: boolean
  shortFooter: boolean
  shortMapCompetition: boolean
}

export enum GapReportLocations {
  WITHIN_MAP_WINDOW = 'map',
  WITHIN_REACHABLE_AREA = 'travel',
}

export class DisplaySettings {
  markerSizeProperty: MarkerSizeProperty = 'netSalesArea'
  markerSizeMinMax: { min: number; max: number } = null
  dateAddedQuarterMinMax: { min: number; max: number; showNull: boolean } = null
  travelRange: number = 0
  edgeWeights = arrays
    .empty(6)
    .map((value, i) => ({ value: (i + 1) * 300, outlineColor: null, color: TRAVEL_COLORS[i], label: `${(i + 1) * 5}` }))
  regonalIndices: boolean = true
  pedestrianLayer: boolean = false
  workforceLayer: boolean = false
  roadsVolumeLayer: boolean = false
  roadsVolumeLayerViewportBreaks: boolean = true
  isochrones: boolean = true
  performanceMapSingleStatistic: boolean = true
  showLabels: boolean = false
  travelDisplayMode: TravelDisplayMode = TravelDisplayMode.NoThematicPolygons
  travelDisplayModeForced: TravelDisplayMode = null
  statistic: ExtendedStatisticsKey = STATISTICS[0]
  travelColorsRangeName: string
  travelColors: string[]
  customTravelColors: string[] = [].concat(TRAVEL_COLORS)
  inverseTravel: boolean
  sourceMarkerStyle: any
  markerStyle: any
  markerStylePrint: boolean = false
  markerStyleReport: 'hidden' | 'competition' = null
  performanceMouseMoveOutsideAngular: boolean
  mapStyle: string = 'positron'
  travelOpacity: number = 0.8
  statisticsOpacity: number = 0.5
  cellHover: boolean = false
  interpolator: string
  intersectionMode = 'union'
  exclusiveTravel: boolean = false
  showSectors: boolean = false
  showOnlyUserZones: boolean = false
  zoneLayer: number = null
  showOnlyMappedCategories: boolean = false

  travelOptions: TravelTypeEdgeWeightOptions = {
    travelType: 'car',
    edgeWeight: 'time',
    maxEdgeWeight: 900,
  }

  populationForecastRange: MinMaxNumber = null
  careHomeForecastYear = 0
  labelsConfig: string[] = []

  trafficLightMonths = 1
  comparisonReportTemplateId: string = null
  perCapitaHousehold: ReportPerCapitaHousehold = 0

  projectNumber: string = null
  showCustomCategoryColors = false
  customCategoryColorsPalette: { [category: string]: string } = {}
  customLocationsColorsPalette: { [location: string]: string } = {}

  gapReportLocations: GapReportLocations = null

  travelTimeDistanceSorting: 'travelTime' | 'shortestTravelDistance' | 'fastestTravelDistance' = 'travelTime'
  showStoresWithinTravelTime: boolean = false
  dataSetFilters: Record<number, any> = {}
  secondaryLocationTypeFilters: Record<string, boolean> = {}
  matchpointUserSettings: any[] = null
  showStoresWithinNoAreaData: boolean = true
  travelRangeCustomMaxEdgeWeight = 180 * 60
  pointAndClickMode = false
  pointAndClickSource: Place = null
  locationIconModel: LocationIconModel = null
  useCustomReportTimes = false
  customReportTimes: number[] = []
  postcodesAnalysisLayer = false
}

@Injectable()
export class SettingsModel {
  public readonly initialized = new BehaviorSubject(false)
  public readonly client = new TargomoClient(
    'https://api.targomo.com/britishisles_storepointgeo/',
    '5CGUUDE5M5V21GVPDZPC'
  )

  private permissionsSubject$ = new BehaviorSubject<any>(null)

  readonly permissions$ = this.permissionsSubject$.pipe(shareReplay(1))
  // = from(this.initAuth()).pipe(shareReplay(1))

  public readonly clientStatistics = this.client
  public readonly clientGeometry = this.client

  // public readonly clientStatistics = new TargomoClient('https://spgCoreService:443/', '5CGUUDE5M5V21GVPDZPC', {
  //   statisticsUrl: 'https://dev.route360.net/spgeostatistics'
  // })

  // // public readonly clientGeometry = new TargomoClient('https://api.targomo.com/britishisles_storepointgeo/', '5CGUUDE5M5V21GVPDZPC', {
  // public readonly clientGeometry = new TargomoClient('https://spgCoreService:443/', '5CGUUDE5M5V21GVPDZPC', {
  //   statisticsUrl: 'https://dev.route360.net/spgeostatistics'
  // })

  public readonly displaySettings = new ExtendedBehaviorSubject<DisplaySettings>(new DisplaySettings())
  public readonly defaultLoadSessionId = new ExtendedBehaviorSubject<number>(null)

  public readonly secondaryLocationTypeFiltersUpdates = this.pluck<Record<string, boolean>>(
    'secondaryLocationTypeFilters'
  )
    .map((values) => {
      values = values || {}
      const count = Object.keys(values).filter((key) => values[key]).length

      if (count === 0) {
        return { ALL: true }
      } else if (count > 1) {
        delete values.ALL
        return values
      } else {
        return values
      }
    })
    .distinctUntilChanged()
    .shareReplay(1)

  readonly customReportTimesUpdates = this.pluck<number[]>('customReportTimes').pipe(
    map((values) => {
      return (values || []).filter((item) => !!item).sort((a, b) => +a - +b)
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly travelRangeIdUpdates = this.pluck<number>('travelRange').distinctUntilChanged().shareReplay(1)

  public readonly useCustomReportTimesUpdates = combineLatest(this.travelRangeIdUpdates).pipe(
    map(([a]) => a === -2),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly customReportMaxEdgeWeightUpdates = combineLatest(
    this.useCustomReportTimesUpdates,
    this.customReportTimesUpdates
  ).pipe(
    map(([use, va]) => (use ? Math.max(...va) : null)),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly useCustomMaxTimeUpdates = combineLatest(this.travelRangeIdUpdates).pipe(
    map(([a]) => a === -1),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly locationIconModelUpdates = this.pluck<any>('locationIconModel').pipe(
    distinctUntilChanged(),
    map((data) => {
      return new LocationIconModel(data)
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly matchpointUserSettingsUpdates = this.pluck<any[]>('matchpointUserSettings')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly pointAndClickModeUpdates = this.pluck<boolean>('pointAndClickMode')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly pointAndClickSourceUpdates = combineLatest(this.pluck<AbstractLocation>('pointAndClickSource')).pipe(
    map(([source]) => {
      if (source) {
        const custom = new TemporaryCustomLocation(source)
        custom.properties = custom.properties || {}
        custom.category = customCategory('Temp Location')

        return custom
      } else {
        return null
      }
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly showStoresWithinNoAreaDataUpdates = this.pluck<boolean>('showStoresWithinNoAreaData')
    .map((value) => value !== false)
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly regonalIndicesUpdates = this.pluck<boolean>('regonalIndices').distinctUntilChanged().shareReplay(1)
  public readonly pedestrianLayerUpdates = this.pluck<boolean>('pedestrianLayer').distinctUntilChanged().shareReplay(1)
  public readonly workforceLayerUpdates = this.pluck<boolean>('workforceLayer').distinctUntilChanged().shareReplay(1)
  public readonly showLabelsUpdates = this.pluck<boolean>('showLabels').distinctUntilChanged().shareReplay(1)
  public readonly roadsVolumeLayerUpdates = this.pluck<boolean>('roadsVolumeLayer')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly postcodesAnalysisLayerUpdates = combineLatest(
    this.pluck<boolean>('postcodesAnalysisLayer'),
    this.permissions$
  ).pipe(
    map(([visible, permissions]) => {
      return visible && permissions && permissions.postcodesAnalysis
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly roadsVolumeLayerViewportBreaksUpdates = this.pluck<boolean>('roadsVolumeLayerViewportBreaks')
    .map((value) => !!value || value == null)
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly showCustomCategoryColorsUpdates = this.pluck<boolean>('showCustomCategoryColors')
    .distinctUntilChanged()
    .shareReplay(1)

  // public readonly dataSetFiltersUpdates = this.pluck<any>('dataSetFilters').distinctUntilChanged().shareReplay(1)

  public readonly customCategoryColorsPaletteUpdates = this.pluck<{ [category: string]: string }>(
    'customCategoryColorsPalette'
  )
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly customLocationsColorsPaletteUpdates = this.pluck<{ [location: string]: string }>(
    'customLocationsColorsPalette'
  )
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly travelRangeCustomMaxEdgeWeightUpdates = this.pluck<number>('travelRangeCustomMaxEdgeWeight')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly defaultLoadSessionIdUpdates = this.defaultLoadSessionId.distinctUntilChanged().shareReplay(1)

  public readonly gapReportLocationsUpdates = this.pluck<GapReportLocations>('gapReportLocations').pipe(
    map((value) => value || GapReportLocations.WITHIN_MAP_WINDOW),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly travelOpacityUpdates = this.pluck<number>('travelOpacity').distinctUntilChanged().shareReplay(1)
  public readonly statisticsOpacityUpdates = this.pluck<number>('statisticsOpacity')
    .distinctUntilChanged()
    .shareReplay(1)
  public readonly inverseTravelUpdates = this.pluck<boolean>('inverseTravel').distinctUntilChanged().shareReplay(1)
  public readonly cellHoverUpdates = this.pluck<boolean>('cellHover').distinctUntilChanged().shareReplay(1)
  public readonly isochronesUpdates = this.pluck<boolean>('isochrones').distinctUntilChanged().shareReplay(1)
  public readonly interpolatorUpdates = this.pluck<string>('interpolator').distinctUntilChanged().shareReplay(1)
  public readonly mapStyleUpdates = this.pluck<string>('mapStyle')
    .map((style) => {
      let styleItem: { key: string; referenceLayer: string } = null
      if (NEW_MAP_STYLES_MAP[style]) {
        styleItem = NEW_MAP_STYLES_MAP[style]
      } else if (OLD_MAP_STYLES_MAP[style]) {
        styleItem = NEW_MAP_STYLES_MAP[OLD_MAP_STYLES_MAP[style].to]
      } else {
        styleItem = NEW_MAP_STYLES_MAP['positron']
      }

      return styleItem.key
    })
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly mapStyleUrlUpdates = this.mapStyleUpdates.pipe(
    map((style) => {
      let styleItem: { key: string; referenceLayer: string } = null
      if (NEW_MAP_STYLES_MAP[style]) {
        styleItem = NEW_MAP_STYLES_MAP[style]
      } else if (OLD_MAP_STYLES_MAP[style]) {
        styleItem = NEW_MAP_STYLES_MAP[OLD_MAP_STYLES_MAP[style].to]
      } else {
        styleItem = NEW_MAP_STYLES_MAP['positron']
      }

      let url: string = null

      url = createStyleUrl(styleItem.key)
      return { url, referenceLayer: styleItem.referenceLayer }
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly intersectionExclusiveModeUpdates = this.pluck<string>('intersectionMode')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly showStoresWithinTravelTimeUpdates = this.pluck<boolean>('showStoresWithinTravelTime')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly travelColorsRangeNameUpdates = this.pluck<string>('travelColorsRangeName')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly customTravelColorsUpdates = this.pluck<string[]>('customTravelColors')
    .distinctUntilChanged()
    .shareReplay(1)
  public readonly travelColorsUpdates = this.pluck<string[]>('travelColors').distinctUntilChanged().shareReplay(1)

  public readonly statisticUpdates = this.pluck<ExtendedStatisticsKey>('statistic')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly projectNumberUpdates = this.pluck<string>('projectNumber').distinctUntilChanged().shareReplay(1)

  public readonly travelDisplayModeForcedUpdates = this.pluck<TravelDisplayMode>('travelDisplayModeForced')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly travelDisplayModeUpdates = combineLatest(
    this.pluck<TravelDisplayMode>('travelDisplayMode').distinctUntilChanged().shareReplay(1),
    this.travelDisplayModeForcedUpdates
  ).pipe(
    map(([userMode, forcedMode]) => {
      return forcedMode != null ? forcedMode : userMode
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )

  public readonly travelOptionsUpdates = combineLatest(
    this.pluck<TravelTypeEdgeWeightOptions>('travelOptions'),
    this.useCustomReportTimesUpdates,
    this.customReportTimesUpdates
  )
    .map(([options, useCustomTime, customTimes]) => {
      options = (options && { ...options }) || ({} as any)
      ;(<any>options).bikeSpeed = (<any>options).walkSpeed = {
        trafficJunctionPenalty: 4,
        trafficSignalPenalty: 7,
      }

      if (useCustomTime) {
        options.maxEdgeWeight = Math.max(...customTimes) || options.maxEdgeWeight
      }

      return { ...options, ...defaultDateTime(options.travelType) } as TravelTypeEdgeWeightOptions
    })

    .distinctUntilChanged()
    .shareReplay(1)

  public readonly labelsConfigUpdates = this.pluck<string[]>('labelsConfig')

  public readonly markerSizePropertyUpdates = this.pluck<MarkerSizeProperty>('markerSizeProperty')
    .distinctUntilChanged()
    .shareReplay(1)
  public readonly markerSizeMinMaxUpdates = this.pluck<{ min: number; max: number }>('markerSizeMinMax')
    .distinctUntilChanged()
    .shareReplay(1)
  public readonly markerStylePrintUpdates = this.pluck<boolean>('markerStylePrint')
    .distinctUntilChanged()
    .shareReplay(1)
  public readonly markerStyleReportUpdates = this.pluck<'hidden' | 'competition'>('markerStyleReport')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly dateAddedQuarterMinMaxUpdates = this.pluck<{ min: number; max: number; showNull: boolean }>(
    'dateAddedQuarterMinMax'
  )
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly disableStatisticsUpdates = Observable.from([initStatisticsDisabled()])
    .map((v) => {
      return v
    })
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly travelTimeDistanceSortingUpdates = this.pluck<
    'travelTime' | 'shortestTravelDistance' | 'fastestTravelDistance'
  >('travelTimeDistanceSorting')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly zoneLayerUpdates = this.pluck<number>('zoneLayer').distinctUntilChanged().shareReplay(1)

  public readonly showOnlyUserZonesUpdates = this.pluck<boolean>('showOnlyUserZones')
    .distinctUntilChanged()
    .shareReplay(1)
  public readonly showOnlyMappedCategoriesUpdates = this.pluck<boolean>('showOnlyMappedCategories')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly comparisonReportTemplateIdUpdates = this.pluck<number>('comparisonReportTemplateId')
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly populationForecastRangeUpdates = this.pluck<MinMaxNumber>('populationForecastRange')
    .map((current) => {
      if (!current) {
        return { min: POPULATION_FORECAST_FROM_YEAR, max: POPULATION_FORECAST_TO_YEAR }
      } else {
        return { min: current.min || POPULATION_FORECAST_FROM_YEAR, max: current.max || POPULATION_FORECAST_TO_YEAR }
      }
    })
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly careHomeForecastYearUpdates = this.pluck<number>('careHomeForecastYear')
    .map((current) => {
      return Math.max(Math.min(current, POPULATION_FORECAST_TO_YEAR), POPULATION_FORECAST_FROM_YEAR)
    })
    .distinctUntilChanged()
    .shareReplay(1)

  // public readonly exclusiveTravelUpdates = this.pluck<boolean>('exclusiveTravel').distinctUntilChanged().shareReplay(1)
  // public readonly intersectionModeUpdates = this.pluck<string>('intersectionMode').distinctUntilChanged().shareReplay(1)

  public readonly exclusiveTravelUpdates = this.intersectionExclusiveModeUpdates
    .map((value) => {
      return value === 'exclusive'
    })
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly intersectionModeUpdates = this.intersectionExclusiveModeUpdates
    .map((value) => {
      if (value === 'exclusive') {
        return 'union'
      } else {
        return value
      }
    })
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly showSectorsUpdates: Observable<boolean>
  public readonly showSectorsTilesLayersUpdates: Observable<boolean>

  public readonly edgeWeightsUpdates = Observable.combineLatest(
    this.travelColorsUpdates,
    this.travelRangeIdUpdates,
    this.travelRangeCustomMaxEdgeWeightUpdates,
    this.initialized.distinctUntilChanged(),
    this.customReportTimesUpdates
  )
    .filter(([, , , initialized, customReportTimes]) => {
      return initialized
    })
    .switchMap(async ([colors, rangeId, travelRangeCustomMaxEdgeWeight, , customReportTimes]) => {
      travelRangeCustomMaxEdgeWeight = Math.max(60, Math.min(+travelRangeCustomMaxEdgeWeight, 4 * 60 * 60))

      const permissions = ((await this.auth.me()) as any).permissions
      if (!permissions.extendedTravel) {
        rangeId = 0
      }

      let travelRange = TRAVEL_TIME_RANGE_OPTIONS[0].options
      if (rangeId != null) {
        if (rangeId < 0) {
          if (rangeId === -2) {
            travelRange = makeTravelRangeOptions(
              customReportTimes,
              customReportTimes.map((item, i) => {
                return TRAVEL_COLORS[((i + 1) * 6) / customReportTimes.length - 1]
              })
            )
          } else {
            travelRange = makeTravelRangeOptions(makeTravelRange(travelRangeCustomMaxEdgeWeight / 6))
          }
        } else {
          TRAVEL_TIME_RANGE_OPTIONS.forEach((option) => {
            if (option.id === rangeId) {
              travelRange = option.options
            }
          })
        }
      }

      colors = colors || TRAVEL_COLORS
      if (rangeId === -2) {
        colors.forEach((color, i) => {
          travelRange[Math.floor((i / TRAVEL_COLORS.length) * travelRange.length)].color = color
        })
      } else {
        colors.forEach((color, i) => {
          travelRange[i].color = color
        })
      }

      return travelRange.concat([])
    })
    .shareReplay(1)

  public async initAuth() {
    this.permissionsSubject$.next(((await this.auth.me()) as any).permissions)
    // return ((await this.auth.me()) as any).permissions
  }

  public readonly travelMinutesGroupingUpdates = this.edgeWeightsUpdates.map((edgeWeights) => {
    const minutesGroup = edgeWeights[0].value / 5
    const minuteLabelFactor = minutesGroup / 60

    return { minutesGroup, minuteLabelFactor }
  })

  public readonly travelEdgeWeightsUpdates = this.edgeWeightsUpdates
    .map((items) => items.map((item) => item.value))
    .shareReplay(1)
  public readonly edgeWeightsColorsUpdates = this.edgeWeightsUpdates.distinctUntilChanged().shareReplay(1)

  public readonly reportPerCapitaHouseholdsUpdates = this.pluck<ReportPerCapitaHousehold>('perCapitaHousehold')
    .map((option) => {
      return option == null ? ReportPerCapitaHousehold.PER_HOUSEHOLD : option
    })
    .distinctUntilChanged()
    .shareReplay(1)

  public readonly trafficLightMonthsUpdates = this.pluck<number>('trafficLightMonths')
    .map((v) => v || 1)
    .distinctUntilChanged()
    .shareReplay(1)

  constructor(private auth: Auth, private zoneLayersEndpoint: ZoneLayersEndpoint) {
    this.initAuth()

    // FIXME: workaround because lib version here not latest
    ;(this.client as any).statistics = new LocalStatisticsClient(this.client)
    ;(this.client as any).reachability = new LocalreachabilityClient(this.client)

    // this.showSectorsUpdates = this.zoneLayerUpdates.map(layer => !!layer).distinctUntilChanged().shareReplay(1)
    this.showSectorsUpdates = Observable.from([false])
      .merge(
        Observable.combineLatest(this.zoneLayerUpdates)
          .debounceTime(10)
          .switchMap(async ([layer]) => {
            const user = await this.auth.me()
            const permission = user && (<any>user).permissions && (<any>user).permissions.zones

            return permission
          })
      )
      .distinctUntilChanged()
      .shareReplay(1)
    // this.zoneLayerUpdates.map(layer => !!layer).distinctUntilChanged().shareReplay(1)

    this.showSectorsTilesLayersUpdates = Observable.from([false])
      .merge(
        Observable.combineLatest(this.zoneLayerUpdates, this.showSectorsUpdates)
          .debounceTime(10)
          .switchMap(async ([layer, permission]) => {
            const availableLayers = await this.zoneLayersEndpoint.me()
            const allow = !!layer && permission

            if (allow) {
              return availableLayers.filter((available) => available.id === layer).length > 0
            } else {
              return allow
            }
          })
      )
      .distinctUntilChanged()
      .shareReplay(1)

    this.initRememberStatisticForExclusive()

    this.edgeWeightsUpdates
      .withLatestFrom(this.travelOptionsUpdates)
      .debounceTime(100)
      .subscribe(([travelRangeOtions, travelOptions]) => {
        const currentMaxEdgeWeight = travelOptions ? travelOptions.maxEdgeWeight : 0
        let newMaxEdgeWeight = travelRangeOtions[0].value

        travelRangeOtions.forEach((option: MaxEdgeWeightOption) => {
          if (option.value <= currentMaxEdgeWeight) {
            newMaxEdgeWeight = option.value
          }
        })

        if (currentMaxEdgeWeight !== newMaxEdgeWeight) {
          this.displaySettings.nextPropertyWithCurrent<TravelTypeEdgeWeightOptions>('travelOptions', (current) => {
            current.maxEdgeWeight = newMaxEdgeWeight
            return { ...current }
          })
        }
      })

    Observable.combineLatest(this.customTravelColorsUpdates, this.travelColorsRangeNameUpdates).subscribe(
      ([customColors, travelColorsRangeName]) => {
        if (travelColorsRangeName === 'custom' && customColors instanceof Array) {
          this.displaySettings.nextProperty<TravelTypeEdgeWeightOptions>('travelColors', <any>[].concat(customColors))
        }
      }
    )
  }

  private initRememberStatisticForExclusive() {
    let previousStatistic: ExtendedStatisticsKey
    this.exclusiveTravelUpdates.withLatestFrom(this.statisticUpdates).subscribe(([exclusive, statistic]) => {
      if (exclusive) {
        previousStatistic = statistic
        this.displaySettings.nextProperty('statistic', STATISTICS_WITH_NONE[0])
      } else {
        if (!statistic || statistic.id < 0) {
          this.displaySettings.nextProperty('statistic', previousStatistic)
        }
      }
    })
  }

  private pluck<T>(key: string) {
    return this.displaySettings.pluck<any, T>(key).distinctUntilChanged().shareReplay(1)
  }

  getTravelDisplayModeDetails(travelDisplayMode: TravelDisplayMode) {
    switch (travelDisplayMode) {
      case TravelDisplayMode.ThematicPolygonsInverted:
        return { statistics: true, polygons: true, inverted: true }

      case TravelDisplayMode.ThematicNoPolygons:
        return { statistics: true, polygons: false, inverted: false }

      case TravelDisplayMode.NoThematicPolygons:
        return { statistics: false, polygons: true, inverted: false }
    }
  }
}
