import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { CompactLayersState } from '../model/placesModel'

export interface UrlInitialState {
  lat: number
  lng: number
  zoom: number
  layers: CompactLayersState
}

@Injectable()
export class UrlInitialStateResolver implements Resolve<UrlInitialState> {
  constructor() {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      if (route.queryParams.state) {
        const state: UrlInitialState = JSON.parse(route.queryParams.state)
        return state
      }
    } catch (e) {
      // ignore whatever happens here and continue loading the app
      console.error(e)
    }

    return null as UrlInitialState
  }
}
