import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { AllStatistics, LabelledStatisticsValue } from '../../../../../../common/models/statistics/statistics'
import { CareHomeYearReport } from '../../../../../model/careHome/careHomeModel'

@Component({
  selector: 'care-home-segment-table',
  templateUrl: './careHomeSegmentTable.component.html',
  styleUrls: ['./careHomeSegmentTable.component.less']
})
export class CareHomeSegmentTableComponent implements OnInit, OnChanges {
  @Input() model: CareHomeYearReport
  @Input() statistics: AllStatistics

  values: LabelledStatisticsValue[]

  constructor() { }

  ngOnInit() {
    this.ngOnChanges()
  }

  ngOnChanges() {
    if (this.model) {
      this.values = [
        this.model.aged65to74,
        this.model.aged75to84,
        this.model.aged85AndOver,
        this.model.aged65AndOver,
      ]
    }
  }
}
