import { Component, ViewChild } from '@angular/core'
import { MatDialog, MatMenuTrigger } from '@angular/material'
import { TgmQuickDialogs } from '@targomo/client'
import { LatLng } from '@targomo/core'
import { CategoryEndpoint } from '../../api/category'
import { AppModel } from '../../model/appModel.service'
import { CategoryColorModel } from '../../model/categoryColorModel'
import { CustomLocation, DataSetLocation, PlanningLocation } from '../../model/index'
import { TravelDisplayMode } from '../../model/settingsModel'
import { ColorSelectDialog } from './../dialogs/colorSelectDialog/colorSelectDialog.component'
import { NewLocationDialog } from './../dialogs/newLocationDialog.component'
import { HomeComponent } from './../home.component'
import { IconSelectDialog } from '../dialogs/iconSelectDialog/iconSelectDialog.component'
import { take } from 'rxjs/operators'

@Component({
  selector: 'map-menu',
  templateUrl: './mapMenu.component.html',
})
export class MapMenuComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  current: DataSetLocation = null
  currentLatLng: LatLng = null
  isSelected: boolean = false
  isDeleted: boolean = false
  isDeletable: boolean = false
  isLabelSelected: boolean = false
  isPolygonExportAllowed: boolean = false
  isColorSelectEnabled = false
  isIconSelectEnabled = false
  currentRoadSection: any = null
  isTemporaryCustom = false
  isPointAndClick = false

  constructor(
    readonly appModel: AppModel,
    private dialog: MatDialog,
    private categoryEndpoint: CategoryEndpoint,
    private quickDialogs: TgmQuickDialogs,
    readonly homeComponent: HomeComponent,
    private categoryColorModel: CategoryColorModel
  ) {}

  public show(location: DataSetLocation, latLng: LatLng) {
    this.currentRoadSection = null
    this.current = location
    this.currentLatLng = latLng

    this.isColorSelectEnabled = this.current && this.current.id < 0 && this.current.dataSetId == null
    this.isIconSelectEnabled = !!this.current && !this.isColorSelectEnabled

    this.isSelected = location && this.appModel.places.sources.contains(location)
    this.isLabelSelected = location && this.appModel.places.isLabelEnabled(location)
    this.isDeleted = location && location.deleted
    this.isDeletable =
      location &&
      !(location instanceof PlanningLocation) &&
      location != this.appModel.places.selectedPlacePlanningRisk.getValue()

    if (this.appModel.settings.displaySettings.getValue().showLabels) {
      this.isLabelSelected = !this.isLabelSelected
    }

    this.isPolygonExportAllowed =
      this.homeComponent &&
      this.homeComponent.permissions &&
      this.homeComponent.permissions.downloadPolygonGeojson &&
      !!this.appModel.polygons.getValue() &&
      this.appModel.settings.displaySettings.getValue().travelDisplayMode === TravelDisplayMode.NoThematicPolygons

    this.isTemporaryCustom = location && location.isTemporaryCustom()
    this.isPointAndClick = this.appModel.settings.displaySettings.getValue().pointAndClickMode

    this.trigger.openMenu()
  }

  public showRoad(latLng: LatLng, currentRoadSection: any) {
    this.current = null
    this.currentLatLng = latLng
    this.currentRoadSection = currentRoadSection
  }

  toggleRoadLabel() {
    this.appModel.places.labelRoadLink.toggle(this.currentRoadSection.id)
  }

  async viewStoreData() {
    if (this.current && !this.current.isTemporaryCustom()) {
      this.appModel.places.selectedPlace.next(this.current)
    }
  }

  async storeData() {
    const dialogRef = this.dialog.open(NewLocationDialog, {
      minWidth: 450,
      disableClose: false,
    })

    dialogRef.componentInstance.show(
      {
        ...this.current,
        properties: { ...this.current, gia: this.current.grossInternalArea, nsa: this.current.netSalesArea },
      } as any,
      'Edit'
    )

    const result: any = await dialogRef.afterClosed().toPromise()
    if (result) {
      this.current.fascia = result.properties.name || result.name
      this.current.name = this.current.fascia
      this.current.defaultName = this.current.fascia
      this.current.street = result.properties.street
      this.current.town = result.properties.town
      this.current.postcode = result.properties.postcode
      this.current.grossInternalArea = isNaN(+result.properties.gia) ? null : +result.properties.gia
      this.current.netSalesArea = isNaN(+result.properties.nsa) ? null : +result.properties.nsa

      await this.appModel.places.updateLabelsWithCurrentConfig([this.current])
      this.appModel.places.generalPropertyUpdate$.next()
    }

    return result
  }

  async saveLocation() {
    const dialogRef = this.dialog.open(NewLocationDialog, {
      minWidth: 450,
      disableClose: false,
    })

    dialogRef.componentInstance.show({ ...this.current, properties: { ...this.current.properties } } as any, 'Save')

    const result: any = await dialogRef.afterClosed().toPromise()
    if (result) {
      this.current = new CustomLocation(result)
      this.current.primaryCategory = this.current.fascia //'CUSTOM'
      this.current.category = this.categoryEndpoint.custom(this.current.fascia)
      this.current.properties['marker-type'] = this.current.category.markerStyleCategory
      this.appModel.places.addCustomPlace(this.current)

      this.appModel.settings.displaySettings.nextWithCurrent((current) => {
        current.pointAndClickSource = null
        return current
      })

      this.appModel.places.sources.add(this.current)
    }
  }

  // private customePlaceId = -10

  async addLocation() {
    if (!(await this.appModel.places.isLatLngAllowed(this.currentLatLng))) {
      this.quickDialogs.snack('Location is outside the currently allowed regions')
      return
    }

    const dialogRef = this.dialog.open(NewLocationDialog, {
      minWidth: 450,
      disableClose: false,
    })

    const location = new CustomLocation({ lat: this.currentLatLng.lat, lng: this.currentLatLng.lng, properties: {} })
    dialogRef.componentInstance.show(location)

    // var id = this.customePlaceId--
    // const event = this.currentEvent

    const result: any = await dialogRef.afterClosed().toPromise()
    if (result) {
      // result.id = id
      result.fascia = result.name || result.properties.name
      result.name = result.fascia

      result.street = result.properties.street
      result.town = result.properties.town
      result.postcode = result.properties.postcode
      result.grossInternalArea = result.properties.gia
      result.netSalesArea = result.properties.nsa
      result.primaryCategory = result.fascia //'CUSTOM'
      result.category = this.categoryEndpoint.custom(result.fascia)
      result.properties['marker-type'] = result.category.markerStyleCategory

      this.appModel.places.addCustomPlace(result)
    }
  }

  deleteLocation() {
    this.appModel.places.deleteLocation(this.current)
  }

  undeleteLocation() {
    this.appModel.places.undeleteLocation(this.current)
  }

  toggleDragging(value: boolean) {
    this.current.properties['marker-draggable'] = value
    // this.appModel.updatedLocations()
  }

  togglePolygons() {
    this.appModel.zoneLayersModel.clearSelected()
    this.appModel.places.sources.toggle(this.current)
  }

  downloadPolygons() {
    this.homeComponent.downloadGeoJSON()
  }

  toggleLabels() {
    this.appModel.places.toggleLabel(this.current)
    this.isLabelSelected = this.appModel.places.isLabelEnabled(this.current)
    if (this.appModel.settings.displaySettings.getValue().showLabels) {
      this.isLabelSelected = !this.isLabelSelected
    }
  }

  async colorLocation() {
    const currentModel = await this.appModel.settings.locationIconModelUpdates.pipe(take(1)).toPromise()
    const iconConfig = currentModel.getLocationConfig(this.current)

    const dialogRef = this.dialog.open(ColorSelectDialog, {
      disableClose: false,
      data: {
        withIcons: true,
        value: {
          color: this.current && this.current.properties && this.current.properties['marker-type'], //this.current.category && this.current.category.color,
          icon: iconConfig.icon,
          iconSize: iconConfig.size,
        },
      },
    })

    const result = await dialogRef.afterClosed().toPromise()
    if (result) {
      // this.current.category.markerStyleCategory = result.color
      this.categoryColorModel.updateCustomColorLocation(this.current, result.color)
      // this.categoryColorModel.updateCustomColor(this.current.category, result.color)

      this.appModel.settings.displaySettings.nextProperty(
        'locationIconModel',
        currentModel.updateLocationIcon(this.current, result.icon).updateLocationIconSize(this.current, result.iconSize)
      )
      this.appModel.places.customPlaces.touch()
    }
  }

  async iconLocation() {
    const currentModel = await this.appModel.settings.locationIconModelUpdates.pipe(take(1)).toPromise()
    const iconConfig = currentModel.getLocationConfig(this.current)

    const dialogRef = this.dialog.open(ColorSelectDialog, {
      disableClose: false,
      data: {
        // withColors: false,
        withIcons: true,
        value: {
          color: this.current.category && this.current.category.color,
          icon: iconConfig.icon,
          iconSize: iconConfig.size,
        },
      },
    })

    const result = await dialogRef.afterClosed().toPromise()
    if (result) {
      const currentModel = await this.appModel.settings.locationIconModelUpdates.pipe(take(1)).toPromise()

      this.appModel.settings.displaySettings.nextProperty(
        'locationIconModel',
        currentModel.updateLocationIcon(this.current, result.icon).updateLocationIconSize(this.current, result.iconSize)
      )
      this.appModel.places.customPlaces.touch()
    }
  }
}
