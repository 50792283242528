<!-- <h4>Report General Options</h4>
<mat-list>
  <mat-list-item (click)="toggleGeneral('customLogo')">
    <mat-checkbox style="pointer-events: none" [checked]="model?.customLogo" aria-label=""></mat-checkbox>
    Use custom logo
  </mat-list-item>

  <mat-list-item (click)="toggleGeneral('shortFooter')">
    <mat-checkbox style="pointer-events: none" [checked]="model?.shortFooter" aria-label=""></mat-checkbox>
    Use short footer
  </mat-list-item>  
</mat-list> -->

<h4>Location Details Columns</h4>
<mat-list>
  <mat-list-item *ngFor="let profile of availableLocationColumns" (click)="toggleCompetitionColumn(profile)">
    <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
    {{profile.label}}
  </mat-list-item>
</mat-list>

<h4>Ratios and Other Values under Location Details</h4>
<admin-comparison-ratio (valueChange)="updateRatios($event, 'locationRatios')" [value]="model?.locationRatios" [available]="availableForRatios"></admin-comparison-ratio>

<h4>Demographics</h4>
<mat-list>
  <mat-list-item *ngFor="let profile of availableDemographics" (click)="toggleReportSection(profile)">
    <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
    {{profile.label}}
  </mat-list-item>
</mat-list>

<h4>Spend</h4>
<mat-list>
  <mat-list-item *ngFor="let profile of availableLifestyle" (click)="toggleReportSection(profile)">
    <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
    {{profile.label}}
  </mat-list-item>
</mat-list>

<h4>Workforce</h4>
<mat-list>
  <mat-list-item *ngFor="let profile of availableWorkforce" (click)="toggleReportSection(profile)">
    <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
    {{profile.label}}
  </mat-list-item>
</mat-list>


<h4>Competition Columns</h4>
<mat-list>
  <mat-list-item *ngFor="let profile of availableCompetitionColumns" (click)="toggleCompetitionColumn(profile)">
    <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
    {{profile.label}}
  </mat-list-item>
</mat-list>

<h4>Ratios and Other Value under Competition</h4>
<admin-comparison-ratio (valueChange)="updateRatios($event, 'competitionRatios')" [value]="model?.competitionRatios" [available]="availableForRatios"></admin-comparison-ratio>
