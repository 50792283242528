<div #mapElement></div>
<div class="zoom-controls">
  <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start end">

    <ng-content select="[mapbox-extra-mini-fabs]" class="extra-mini-fabs" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start end"></ng-content>

    <button mat-mini-fab (click)="map.zoomIn()">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button mat-mini-fab (click)="map.zoomOut()">
      <mat-icon>zoom_out</mat-icon>
    </button>
    <button mat-mini-fab (click)="map.resetNorth()">
      <mat-icon svgIcon="compass" [style.transform]="'rotate(' + -_bearing + 'deg)'"></mat-icon>
    </button>

    <div fxLayout="row" fxLayoutAlign="end center">
      <div 
        [style.visibility]="_showAttribution ? 'visible' : 'hidden'" 
        class="attribution" 
        fxLayout="row"
        fxLayoutAlign="end"
        fxLayoutGap="0.3rem"
        fxLayout.xs="column"
        (mouseenter)="showAttribution(true)" 
        (mouseleave)="showAttribution(false)">

          <ng-content></ng-content>

      </div>

      <button (mouseenter)="showAttribution(true)" (mouseleave)="showAttribution(false)" mat-mini-fab>
        <mat-icon svgIcon="targomo"></mat-icon>
      </button>
    </div>
  </div>
</div>