<tgm-speeddial icon="mode_edit" (change)="change($event)">
  <button color="primary" title="Export selected places" mat-mini-fab [disableRipple]="true" (click)="save()">
    <mat-icon  class="mat-24">save</mat-icon>
  </button>

  <button color="primary" title="Clear all" mat-mini-fab [disableRipple]="true" (click)="deleteAll()">
    <mat-icon  class="mat-24">delete_forever</mat-icon>
  </button>

  <button color="primary" title="Remove current shape" mat-mini-fab [disableRipple]="true" (click)="delete()">
    <mat-icon  class="mat-24">delete</mat-icon>
  </button>

  <button color="primary" title="Select" mat-mini-fab [disableRipple]="true" (click)="select()">
    <mat-icon  class="mat-24">tab_unselected</mat-icon>
  </button>

  <!--<button color="primary" title="Select nodes" mat-mini-fab [disableRipple]="true" (click)="selectNode()">
    <mat-icon  class="mat-24">select_all</mat-icon>
  </button>-->

  <button color="primary" title="Draw shape" mat-mini-fab [disableRipple]="true" (click)="draw()">
    <mat-icon  class="mat-24">add_box</mat-icon>
  </button>

</tgm-speeddial>