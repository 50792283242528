<h2 class="mat-title">{{company && company.id && 'Edit Company' || 'New Company'}}</h2>

<tgm-loadable [model]="loading">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="ready">
    <section fxLayout="row">
      <section fxLayout="row">
        <div fxLayout="column">  
          <span style="margin-top: 20px; " class="small-title">Company Logo</span>
          <tgm-file-uploader filter=".jpeg,.jpg,.png,.gif" style="width: 150px; height: 150px; margin-right: 50px" (selected)="onSelectCompanyPicture($event)" [background]="urlCompany"></tgm-file-uploader>
  
          <span style="margin-top: 20px; " class="small-title">Custom App Logo</span>
          <tgm-file-uploader filter=".jpeg,.jpg,.png,.gif" style="width: 150px; height: 150px; margin-right: 50px" (selected)="onSelectLogoPicture($event)" [background]="urlLogo"></tgm-file-uploader>
        </div>
      </section>
  
      <section fxLayout="column" fxFlex>
        <mat-form-field style="width: 100%; margin-bottom: 10px">
          <input matInput placeholder="Name" type="text" formControlName="name"/>

            <mat-hint *ngIf="form.submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
              Name is required
            </mat-hint>
        </mat-form-field>

      </section>
    </section>

    <section>
      <admin-edit-permissions formControlName="company"></admin-edit-permissions>
    </section>

    <section class="message-section">
      <h3 style="text-transform: uppercase" class="mat-title">Messages</h3>
      <div class="hint">Note: Changes to message assignment for companies are saved immediately</div>
      <section>
        <mat-list>
          <mat-list-item *ngFor="let profile of availableMessages">
            <mat-icon mat-font-set="material-icons">email</mat-icon>
            <div fxFlex style="margin-left: 8px">{{profile.title}}</div>
            <button (click)="messageAssignUsers(profile)" title="Assign all company users" mat-icon-button type="button" ><mat-icon class="arrow-icon" mat-font-set="material-icons">group_add</mat-icon></button>
            <button (click)="messageUnAssignUsers(profile)" title="Unassign all company users" mat-icon-button type="button" ><mat-icon class="arrow-icon" mat-font-set="material-icons">clear</mat-icon></button>

          </mat-list-item>
        </mat-list>
      </section>
    </section>


    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-button type="button" routerLink="/admin/companies"><mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span translate="dialog.back">Back</span></button>
      <button mat-flat-button color="primary" type="submit"><span translate="dialog.save">Save</span></button>
    </div>
  </form>
</tgm-loadable>