import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Auth, Indicators, SubscriberComponent } from '@targomo/client';
import { Observable } from 'rxjs';
import { Place } from '../../../api';
import { AppModel } from '../../../model/appModel.service';
import { DataLoadingModel } from '../../../model/dataLoadingModel';
import { MarkerSizeProperty, SettingsModel } from '../../../model/settingsModel';
import { Filter } from '../../../util/types';
import { HomeComponent } from '../../home.component';

export interface MarkerSizeKeyValue {
  key: MarkerSizeProperty
  name: string
  selected?: boolean
}

@Component({
  selector: 'map-filters-options-panel',
  templateUrl: './mapFiltersOptionsPanel.component.html',
  styleUrls: ['./mapFiltersOptionsPanel.component.less']
})
export class MapFiltersOptionsPanelComponent extends SubscriberComponent {

  markerSizeValues: MarkerSizeKeyValue[] = [
    {key: 'netSalesArea', name: 'Net Sales Area', selected: true},
    {key: 'grossInternalArea', name: 'Gross Internal Area'},
  ]

  lowerActualValue: number = 0
  upperActualValue: number = 10000000

  lowerValue: number = 0
  upperValue: number = Math.sqrt(this.upperActualValue)
  maxValue: number = Math.sqrt(this.upperActualValue)


  hideMarkersBelowSize: number
  markerSizeLegend: any[] = []
  sizes: number[] = [1, 1, 1]
  permissions: any

  form: FormGroup

  constructor(
      private auth: Auth,
      private indicators: Indicators,
      readonly appModel: AppModel,
      readonly dataLoadingModel: DataLoadingModel,
      private zone: NgZone) {
    super()
  }

  async ngOnInit() {
    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
    }

    this.watch(this.appModel.displaySettings, settings => {
      const name = this.markerSizeValues.filter(item => item.key == settings.markerSizeProperty)[0].name

      this.markerSizeValues.forEach(property => property.selected = property.key === settings.markerSizeProperty)

      this.markerSizeLegend = [
      //   5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000].map(size => {
      //   return {key: '' + size, name: '' + size}
      // })
        {key: 'small',  name: `${name} < 20,000 sq ft`},
        {key: 'medium', name: `${name} 20,000 to 30,000 sq ft`},
        {key: 'large',  name: `${name} > 30,000 sq ft`},
      ]

      // this.appModel.places.places.value.subscribe(places => {
      //   let maxValue = 0
      //   places.forEach(place => maxValue = Math.max(maxValue, (<any>place)[settings.markerSizeProperty]))

      //   this.maxValue = Math.sqrt(maxValue)
      // })

    })

    this.watch(Observable.combineLatest(
      this.dataLoadingModel.maximums,
      this.appModel.displaySettings.pluck<any, MarkerSizeProperty>('markerSizeProperty').distinctUntilChanged()
    ), ([maximums, markerSizeProperty]) => {
      let current = maximums && maximums[markerSizeProperty]
      this.maxValue = Math.sqrt(current.max || 10000000)
    })


    // this.watch(this.home.mapLayout.mapView.events.move.debounceTime(100), event => {
    //   this.sizes = [0, 3 * 5000, 8 * 5000].map(area => 2 * Math.round(this.appModel.places.getRadiusForZoomLevel(event.zoom, area)))

    //   this.zone.run(() => {
    //     this.markerSizeLegend = [].concat(this.markerSizeLegend)
    //   })      
    // })

    this.watch(this.appModel.settings.markerSizeMinMaxUpdates, minMax => {
      if (minMax) {
        this.lowerActualValue = minMax.min
        this.upperActualValue = minMax.max
        this.lowerValue = Math.sqrt(minMax.min)
        this.upperValue = Math.sqrt(minMax.max)
      }
    })
  }

  private currentZoomFilter: Filter<Place> = null
  private filterTimeout: any = null

  onInputChange(which: 'upper' | 'lower') {
    if (which === 'lower')
      this.lowerValue = Math.sqrt(this.lowerActualValue)

    if (which === 'upper')
      this.upperValue = Math.sqrt(this.upperActualValue)

    const action = () => {
      this.updateFilter()
    }

    clearTimeout(this.filterTimeout)
    this.filterTimeout = setTimeout(action, 250)
  }

  onSliderChange(which: 'upper' | 'lower') {
    if (which === 'upper')
      this.upperActualValue = Math.round(Math.pow(this.upperValue, 2))

    if (which === 'lower')
      this.lowerActualValue = Math.round(Math.pow(this.lowerValue, 2))

    const action = () => {
      this.updateFilter()
    }

    clearTimeout(this.filterTimeout)
    this.filterTimeout = setTimeout(action, 250)
  }

  updateFilter() {
    const settings = this.appModel.displaySettings.getValue()
    const zoomFilter = (place: Place): boolean => {
      return place[settings.markerSizeProperty] >= this.lowerActualValue && place[settings.markerSizeProperty] <= this.upperActualValue
    }

    // this.appModel.places.filters.remove(this.currentZoomFilter)
    // this.appModel.places.filters.add(this.currentZoomFilter = zoomFilter)

    this.appModel.displaySettings.nextProperty('markerSizeMinMax', {min: this.lowerActualValue, max: this.upperActualValue})
  }

  toggle(value: MarkerSizeKeyValue) {
    this.markerSizeValues.forEach(item => item.selected = item == value)
    this.appModel.displaySettings.nextWithCurrent(settings => {
      settings.markerSizeProperty = value.key
      return settings
    })
  }
}
