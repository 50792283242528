import { Component } from '@angular/core';
import { DataSet } from "../../../api";
import {Auth, SubscriberComponent} from '@targomo/client';
import {DataSetEndpoint} from '../../../api/dataSet';
import {Router, ActivatedRoute} from '@angular/router';
import { GeoRegionsEndpoint } from '../../../api/georegions';
import { ComparisonReportTemplate } from '../../../../common/models';
import { PdfReportSettings } from '../../../model/settingsModel';
import { ComparisonReportTemplateEndpoint } from '../../../api/comparisonReportTemplate';

@Component({
  selector: 'admin-report-template',
  templateUrl: './adminComparisonReportTemplate.component.html'
})
export class AdminComparisonReportTemplateComponent extends SubscriberComponent {
  model: ComparisonReportTemplate = <any>{}
  loading: Promise<ComparisonReportTemplate>
  saving: Promise<ComparisonReportTemplate>

  constructor(private templateEndpoint: ComparisonReportTemplateEndpoint, private route: ActivatedRoute, private router: Router) {
    super()

    this.watch(route.params, async params => {
      if (params.id != null && params.id !== 'new') {
        this.model = await this.templateEndpoint.get({id: params.id})
      } else {
        this.model = <any>{name: '', data: {}}
      }
    })
  }

  showMenu(event: any) {
    event.stopPropagation()
  }

  async save(event: any) {
    if (!this.model.id) {
      await this.templateEndpoint.create(this.model)
    } else {
      await this.templateEndpoint.save(this.model)
    }
    this.router.navigate(['/admin/comparisonReportTemplates'])
  }

  updateReport(value: PdfReportSettings) {
    if (this.model) {
      this.model.data = value
    }
  }
}
