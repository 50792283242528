import { AllStatistics, CensusStatisticsGroup, PersonicxStatisticsSummary, StatisticsValue } from '../../../../common/models/statistics/statistics'
import { CareHomeReport } from '../../../model/careHome/careHomeModel'
import { CENSUS_SEGMENTS_RAW, ReportSegment, sumSegments } from '../../mini/model/reportSegments'
import { PDFHelper } from '../helper'
import { BORDER_COLOR, TABLE_OPTIONS_DEMOGRAPHICS, TABLE_OPTIONS_DEMOGRAPHICS_PERSONICS } from './tableStyle'
import { underlineCell } from './underlineCell'

const AGES_COMPACT: ReportSegment =  {id: "ages", name: "Age", children: [
  {id: "aged0to15", name: "0-15", sumOf: ['aged0to4', 'aged5to7', 'aged8to9', 'aged10to14', 'aged15']},
  {id: "aged16to64", name: "16-64", sumOf: ['aged16to17', 'aged18to19', 'aged20to24', 'aged25to29', 'aged30to44', 'aged45to59', 'aged60to64']},
  {id: "aged65to74", name: "65-74", },
  {id: "aged75to84", name: "75-84", },
  {id: "aged85to89", name: "85-89", },
  {id: "aged90AndOver", name: "90+", },  
]}

const TENURE: ReportSegment =  {id: "tenure", name: "Tenure", children: [
  // {id: "employerHouseholdMember", name: "Employer of a household member"},
  // {id: "relativeFriendHouseholdMember", name: "Relative or friend of a household member"},
  // {id: "livingRentFree", name: "Living rent free"},
  // {id: "privateLandlordLettingAgency", name: "Private landlord or letting agency"},
  // {id: "ownsMortgageLoan", name: "Owns with a mortgage or loan"},
  // {id: "sharedOwnership", name: "Shared ownership"},
  {id: "owned", name: "Owned", children: [
    {id: "ownsMortgageLoan", name: "Owns with a mortgage"},
    {id: "ownsOutright", name: "Owns outright"},
  ]
  },
  // {id: "ownsOutright", name: "Owns outright"},
  // {id: "otherPrivateRented", name: "Other private rented"},
  {id: "privateRented", name: "Private rented"},
  // {id: "rentedCouncilLocalAuthority", name: "Rented from Council (Local Authority)"},
  // {id: "otherSocialRented", name: "Other social rented"},
  {id: "socialRented", name: "Social rented"},
]}


function demographicsValueGraph(value: number, background: string): any {
  if (!isFinite(value))
    return ''

  const first = value < 1

  if (first) {
    value = Math.min(1, Math.abs(1 - value))
  } else {
    value = Math.min(1, value - 1)
  }

  if (value == 0) {
    return ''
  }

  const TOTAL = 20
  const HEIGHT = 10
  const width = Math.round(TOTAL * value)
  const offset = TOTAL - width

  return {
    // style: 'defaultCellMicro',
    margin: [0, 0, 0, 0],
    canvas: [
      {
        type: 'rect',
        x: 0,
        y: 0,
        w: TOTAL * 2,
        h: HEIGHT,
        lineColor: '#ffffff',
        lineOpacity: 0,
        opacity: 0,
        lineWidth: 0,
        fillOpacity: 0,
      },
      
      {
        type: 'rect',
        x: first ? offset : TOTAL,
        y: (HEIGHT - 2.5) / 2,
        w: width,
        h: 2.5,
        color: first ? '#a5a5a5' : '#ed7d32',
      },

      {
        type: 'rect',
        x: TOTAL - 0.5,
        y: 0,
        w: 1,
        h: HEIGHT,
        color: '#222222',
      },
    ]
  }
}


function demographicsPersonicsTable(helper: PDFHelper, statistics: PersonicxStatisticsSummary, allStatistics: AllStatistics, indices: boolean) {
  const LABELS = [
    'Pre Family',
    'Families',
    'Empty Nesters',
    'Retired',
  ]

  const title = indices ? `LifePro - Index vs ${allStatistics.regionLabel()} Average` : 'LifePro - Household Count'

  const preHeaders = [
    [{colSpan: 7, alignment: 'left', bold: true, text: title}, '', '', '', '', '', ''],
    [{colSpan: 7, alignment: 'center', bold: true, text: 'Household Income Group'}, '', '', '', '', '', ''],
    ['', 'High', '', 'Avg', '', 'Low', ''],
  ]
  const headers = [['Lifestage', '1', '2', '3', '4', '5', 'Total']]

  let rows = ['a', 'b', 'c', 'd'].map((key, index) => {
    const rowGroup: StatisticsValue[] = statistics[key]
    return ([LABELS[index]] as any[])
      .concat(rowGroup.map(cell =>
        indices ? helper.formatNumber(100 * (cell.total / statistics.total) / cell.index) : helper.formatNumber(cell.total)
      ))
      .concat([
        indices
          ? helper.formatNumber(100 * (statistics.vertical[index] / statistics.total) / statistics.verticalIndices[index])
          : helper.formatNumber(statistics.vertical[index])
      ])
  })

  const footers = [(['Total'] as any[])
    .concat(statistics.horizontal.map((total, i) =>
      indices
        ? helper.formatNumber(100 * (total / statistics.total) / statistics.horizontalIndices[i])
        : helper.formatNumber(total)
    ))
    .concat([
      helper.formatNumber(
        indices ? (100) : statistics.total
      )])
    ]


  rows = [].concat(preHeaders, headers, rows, footers)

  rows = rows.map((row, i) => row.map((cell, j) => {
    if (typeof cell === 'string') {
      cell = {text: cell, style: 'defaultCellMicro'}
    } else if (!cell.style) {
      cell = {...cell, style: 'defaultCellMicro'}
    }

    if (j === 0) {
      cell.alignment = 'left'
    } else if (j === 6) {
      cell.alignment = 'right'
    } else {
      cell.alignment = 'center'
    }

    if (i <= 2 || i > 7) {
      cell.bold = true
    }

    if (i === 1) {
      cell.alignment = 'center'
    }

    return cell
  })
)  
    
  return helper.table(null, rows, 'defaultTable', TABLE_OPTIONS_DEMOGRAPHICS_PERSONICS)
}

function demographicsTable(
  helper: PDFHelper, 
  segmentParent: ReportSegment, 
  statistics: CensusStatisticsGroup, 
  allStatistics: AllStatistics,
  showTotal = false
) {
  let rows: any[][] =[]
  
  segmentParent.children.forEach(segment => {
    const item = statistics[segment.id] as StatisticsValue

    rows.push([
      {text: segment.name, border: [false, false, false, false], margin: [15, 1, 1, 1]},
      helper.formatNumber(item.percent || 0, 1),
      helper.formatNumber(item.total),
      helper.formatNumber(100 * (item.percent / item.index) || 0),
      demographicsValueGraph(item.percent / item.index, '#999999')
    ])

    if (segment.children) {
      segment.children.forEach(segment => {
        const item = statistics[segment.id] as StatisticsValue
    
        rows.push([
          {text: segment.name, border: [false, false, false, false], margin: [25, 1, 1, 1]},
          helper.formatNumber(item.percent || 0, 1),
          helper.formatNumber(item.total),
          helper.formatNumber(100 * (item.percent / item.index) || 0),
          demographicsValueGraph(item.percent / item.index, '#999999')
        ])
      })
    }
  })

  rows = [
    [{text: segmentParent.name, colSPan: 5 }, '', '', '', ''],
    [
      '', 
      underlineCell('%', BORDER_COLOR), 
      underlineCell('Total', BORDER_COLOR), 
      {stack: [underlineCell({text: `Ind vs ${allStatistics.regionLabel()} Avg`}, BORDER_COLOR)], colSpan: 2},
      ''],
  ].concat(rows)

  if (showTotal) {
    rows.push([
      {text: 'Total', bold: true, margin: [15, 1, 1, 1]},
      '',
      {text: helper.formatNumber(statistics.total), bold: true},
      '',
      '',
    ])
  }


  rows = rows.map((row, i) => row.map((cell, j) => {
      if (typeof cell === 'string') {
        cell = {text: cell, style: 'defaultCellMicro'}
      } else if (!cell.style) {
        cell = {...cell, style: 'defaultCellMicro'}
      }

      if (j === 0) {
        cell.alignment = 'left'
      } else if (j === 1 || j === 3) {
        cell.alignment = 'center'
      }
      
      if (i === 1) {
        cell.alignment = 'center'
      }

      if (i <= 1) {
        cell.bold = true
      }

      if (j === 0 && i > 0) {
        cell.border = [false, false, false, false]
      }

      return cell
    })
  )


  return helper.table(null, rows, 'defaultTable', TABLE_OPTIONS_DEMOGRAPHICS)
}

export function careDemographics(helper: PDFHelper, careHomeReport: CareHomeReport) {
  const statistics = careHomeReport.statistics

  const dwellingSegment: ReportSegment = JSON.parse(JSON.stringify(CENSUS_SEGMENTS_RAW.find(item => item.id === 'accomodation')))
  dwellingSegment.children = dwellingSegment.children.filter(item => item.id !== 'temporaryStructure')



  return [{
    columnGap: 30,
    columns: [
      [
        {bold: true, text: 'Households & Population', style: 'defaultCell', alignment: 'left'},
          helper.table(null, [
            ['Households', helper.formatNumber(statistics.census.population2011.households.total)],
            ['Population', helper.formatNumber(statistics.census.population2011.population.total)],
          ], 'defaultTable'),
      ],
      ''
    ]},

    {text: ' ', fontSize: 7},

    {
      columnGap: 30,
      columns: [
        [
          demographicsTable(helper, AGES_COMPACT, sumSegments(AGES_COMPACT, statistics.census.ages), statistics, true),
          {text: ' ', fontSize: 7},
          demographicsTable(helper, dwellingSegment, statistics.census.accomodation, statistics),
          {text: ' ', fontSize: 7},
          demographicsTable(helper, TENURE, statistics.census.tenure, statistics),
        ],
        [
          demographicsTable(helper, CENSUS_SEGMENTS_RAW.find(item => item.id === 'socialClass'), statistics.census.socialClass, statistics, true),
          {text: ' ', fontSize: 7},
          demographicsTable(helper, CENSUS_SEGMENTS_RAW.find(item => item.id === 'ethnicity'), statistics.census.ethnicity, statistics),
        ]
      ]
    },
    {text: ' ', fontSize: 7},
    {
      columnGap: 30,
      columns: [
        demographicsPersonicsTable(helper, statistics.lifestyle.social.households, statistics, false),
        demographicsPersonicsTable(helper, statistics.lifestyle.social.households, statistics, true),
      ]
    }
  ]
}
