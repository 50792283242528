<form [formGroup]="form"  class="form" (submit)="save(form)" novalidate *ngIf="!!ready">
  <h3 class="mat-title">Edit Category</h3>

  <section fxLayout="row">
    <div (click)="editColor($event)" [style.background]="model.color || '#ddd'" class="color">
    </div>
    <!--<div style="display: none" mat-color-picker="options" [ngModel]="model.color"></div>-->
    <div style="display: none" mat-color-picker="options"></div>

    <div fxLayout="column" fxFlex>
      <mat-form-field>
        <input matInput placeholder="Name" type="text" name="name" formControlName="name"/>

        <mat-hint *ngIf="form.submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
          Name is required
        </mat-hint>
      </mat-form-field>
    </div>
  </section>

  <div class="align-right" style="margin-top: 16px">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit">Save</button>
  </div>
</form>
