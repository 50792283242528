import { Component } from "@angular/core"
import { SubscriberComponent } from "@targomo/client";
import { SettingsModel } from "../../../model/settingsModel";
import { OnInit } from "@angular/core/src/metadata/lifecycle_hooks";
import { ZoneSelection } from "../../../api/sectors";
import { ZoneLayersModel } from "../../../model/zoneLayersModel";
import { AppModel } from "../../../model/appModel.service";

@Component({
  selector: 'zone-layers-panel',
  styleUrls: ['./zoneLayerPanel.component.less'],
  templateUrl: `./zoneLayerPanel.component.html`
})
export class ZoneLayerPanelComponent extends SubscriberComponent implements OnInit {
  formActive: 'edit' | 'upload' | 'draw' = null
  formInput: ZoneSelection = <any>{}
  currentLayer: number = null

  constructor(
    readonly appModel: AppModel,
    readonly settings: SettingsModel,
    readonly sectorsModel: ZoneLayersModel,
  ) {
    super()
  }

  ngOnInit(): void {
    this.watch(this.settings.zoneLayerUpdates, layer => {
      this.currentLayer = layer
    })
  }

  addSector() {
    this.formActive = 'edit'
    this.formInput = <any>{}
    this.sectorsModel.editSector({})
  }

  drawSector() {
    this.formActive = 'draw'
    this.formInput = <any>{}
    this.sectorsModel.editDrawnSector(<any>{})
  }

  uploadSector() {
    this.formActive = 'upload'
    this.formInput = <any>{}
    // this.sectorsModel.editSector({})
  }

  async saveSectorPanel(event: ZoneSelection) {
    this.formActive = null
    const features = await this.sectorsModel.visibleLayerFeatures.take(1).toPromise()
    const layerId = await this.settings.zoneLayerUpdates.take(1).toPromise()


    if (event) {
      event.features = features
      event.layer = layerId
      this.sectorsModel.addSector(event)
    }

    this.sectorsModel.editSector(null)
  }

  saveDrawnSectorPanel(event: any) {
    this.formActive = null

    if (event) {
      this.sectorsModel.addSector(event)
    }

    this.sectorsModel.editDrawnSector(null)
  }

  async uploadSectorPanel(event: ZoneSelection[]) {
    this.formActive = null
    const features = await this.sectorsModel.visibleLayerFeatures.take(1).toPromise()
    const layerId = await this.settings.zoneLayerUpdates.take(1).toPromise()

    if (event) {
      this.sectorsModel.addSectors(event)
    }

    this.sectorsModel.editSector(null)
  }

  deleteSector(zone: ZoneSelection, event: any) {
    event.stopPropagation()
    this.sectorsModel.removeSector(zone)
  }

  editSector(zone: ZoneSelection, event: any) {
    event.stopPropagation()

    this.formInput = zone
    if (zone.freehand) {
      this.formActive = 'draw'
      this.sectorsModel.editDrawnSector(zone)
    } else {
      const features: any = {}
      this.formActive = 'edit'
      if (zone.features) {
        zone.features.forEach(key => features[key] = true)
      }
      this.sectorsModel.editSector(features)
    }
  }

  toggleSector(zone: ZoneSelection) {
    if (!zone.selected) {
      this.appModel.places.sources.clear()
    }

    if (!zone.selected && zone.layer != null) {
      this.settings.displaySettings.nextProperty('zoneLayer', zone.layer)
    }
    this.sectorsModel.toggle(zone)
  }
}