<div class="title">Selected Layer Filters</div>

<table style="width: 100%">
  <thead>
    <tr>
      <th></th>
      <th colspan="2">Active/Passive</th>
      <th colspan="2">NSA/GIA</th>
      <th colspan="2">Size Filter</th>
      <th colspan="2">Size (sq ft)</th>
    </tr>

    <tr class="subheader">
      <th></th>
      <th>Active</th>
      <th>Passive</th>
      <th>NSA</th>
      <th>GIA</th>
      <th>Unit</th>
      <th>Average</th>
      <th>Size</th>
      <th>Min</th>
      <!-- <th></th> -->
      <th>Max</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let data of layers">
      <td>{{ data.name }}</td>

      <td (click)="data.setActive()">
        <mat-checkbox style="pointer-events: none" [checked]="data.isActive()"></mat-checkbox>
      </td>
      <td (click)="data.setPassive()">
        <mat-checkbox style="pointer-events: none" [checked]="data.isPassive()"></mat-checkbox>
      </td>
      <td (click)="data.setNSA()">
        <mat-checkbox style="pointer-events: none" [checked]="data.isNSA()"></mat-checkbox>
      </td>
      <td (click)="data.setGIA()">
        <mat-checkbox style="pointer-events: none" [checked]="data.isGIA()"></mat-checkbox>
      </td>
      <td (click)="data.setUnit()">
        <mat-checkbox style="pointer-events: none" [checked]="data.isUnit()"></mat-checkbox>
      </td>

      <td (click)="data.setAverage()">
        <mat-checkbox style="pointer-events: none" [checked]="data.isAverage()"></mat-checkbox>
      </td>

      <td>
        <button mat-icon-button [matMenuTriggerFor]="menu">{{ data.getSizeByLabel() }}</button>

        <mat-menu #menu="matMenu">
          <div *ngFor="let option of SIZE_OPTIONS">
            <mat-checkbox
              (click)="clickMenu($event)"
              (change)="data.toggleSizeBy(option.key)"
              [checked]="data.isSizeBy(option.key)"
              >{{ option.name }}</mat-checkbox
            >
          </div>
        </mat-menu>
      </td>

      <td class="min-max">
        <!-- {{ data.getSliderLowerValue() | number: '1.0-0' }} -->

        <mat-form-field>
          <input
            matInput
            [ngModel]="data.getSliderLowerValue()"
            (ngModelChange)="data.setSliderLowerValue($event)"
            name="min"
            placeholder="Min"
          />
        </mat-form-field>
      </td>
      <!-- <td>
        <tgm-rangeslider
          [margin]="0.001"
          [step]="0.01"
          [max]="data.getSliderMaxValue(maximums)"
          [minValue]="data.getSliderLowerValue()"
          [maxValue]="data.getSliderUpperValue()"
          (minValueChange)="data.setSliderLowerValue($event)"
          (maxValueChange)="data.setSliderUpperValue($event)"
        ></tgm-rangeslider>
      </td> -->
      <td class="min-max">
        <!-- {{ data.getSliderUpperValue() | number: '1.0-0' }} -->
        <mat-form-field>
          <input
            matInput
            [ngModel]="data.getSliderUpperValue()"
            (ngModelChange)="data.setSliderUpperValue($event)"
            name="max"
            placeholder="Max"
          />
        </mat-form-field>
      </td>
    </tr>
  </tbody>
</table>

<div>
  <mat-checkbox
    (change)="toggleShowStoresWithinNoAreaDataUpdates($event)"
    [checked]="showStoresWithinNoAreaDataUpdates$ | async"
    >Show locations with no area data</mat-checkbox
  >
</div>

<div class="help">
  <div class="title">Notes & Meanings</div>

  <p>An <b>active</b> layer is visible and will impact Exclusive Travel Times and Competition Ratio Calculations.</p>

  <p>
    A <b>passive</b> layer is visible but is not reported as competition and therefore has no impact on Exclusive Travel
    Times or Competition Ratio Calculations.
  </p>

  <p><b>NSA</b> - Net Sales Area. <b>GIA</b> - Gross Internal Area.</p>

  <p>
    <b>Size Filter</b>: Unit will apply the size filter to individual store sizes. Small format foodstores, for example,
    may be excluded and larger units left visible.
  </p>

  <p>
    <b>Size Filter</b>: Average will apply the size filter based on the company average size. This means entire fascias
    can be included or excluded based on company average size.
  </p>
</div>

<!-- <mat-list>
  <mat-list-item *ngFor="let option of markerSizeValues" (click)="toggle(option)">
    <mat-checkbox style="pointer-events: none" [checked]="option.selected" aria-label="{{option.name}}"></mat-checkbox>
    <span>{{option.name}}</span>
  </mat-list-item>
</mat-list>

<h3 class="mat-title">Filter Symbols:</h3>

<tgm-rangeslider [margin]="0.001" [step]="0.01" [max]="maxValue" [(minValue)]="lowerValue" [(maxValue)]="upperValue" (minValueChange)="onSliderChange('lower')" (maxValueChange)="onSliderChange('upper')"></tgm-rangeslider>

<div fxLayout="row" style="width: 100%; padding: 0px 16px" fxLayoutGap="32px">
  <mat-form-field fxFlex="50%">
    <input matInput type="number" (ngModelChange)="onInputChange('lower')" [(ngModel)]="lowerActualValue" required placeholder="Min sq ft">
  </mat-form-field>

  <mat-form-field fxFlex="50%">
    <input matInput type="number" (ngModelChange)="onInputChange('upper')" [(ngModel)]="upperActualValue" required placeholder="Max sq ft">
  </mat-form-field>
</div>      


<ng-container *ngIf="permissions && permissions.dateAddedFilter">
  <h3 class="mat-title">Filter by Date Added:</h3>
  <filter-dates></filter-dates>
</ng-container>

<h3 class="mat-title">Symbol Sizes:</h3>

<table class="marker-legend">
  <tr *ngFor="let option of markerSizeLegend; let i=index">
    <td><span class="legend-panel-marker" [style.width]="'' + sizes[i] + 'px'" [style.height]="'' + sizes[i] + 'px'"></span></td>
    <td style="width: 100%"><span>{{option.name}}</span></td>
  </tr>
</table> -->
