<mat-list-item>
  <mat-select
    style="min-width: 200px"
    fxFlex
    [ngModel]="appModel.settings.travelTimeDistanceSortingUpdates | async"
    (ngModelChange)="changeValue($event)"
  >
    <mat-option value="travelTime">Travel Time</mat-option>
    <mat-option value="fastestTravelDistance">Fastest Route Distance</mat-option>
    <mat-option value="shortestTravelDistance">Shortest Distance</mat-option>
  </mat-select>
</mat-list-item>
