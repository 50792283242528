import { DecimalPipe } from '@angular/common'
import { CustomBarGraphOptions } from '../../components/tgm-custom-bargraph/bargraph.component'
import { GraphValue } from '../../components/tgm-custom-bargraph/bargraph.component.type'
import { TextMeasurer } from './textMeasurer'

const DECIMAL_PIPE = new DecimalPipe('en')

export function graphLabelMeasure(measurer: TextMeasurer, graphModel: GraphValue[], graphOptions: CustomBarGraphOptions) {
  try {
    const formatValue = (item: string | number) => {
      if (typeof item === 'number') {
        return graphOptions && graphOptions.formatTick && (item) || DECIMAL_PIPE.transform(item)
      } else {
        return item
      }
    }
    graphOptions && graphOptions.formatTick  || ((item: any) => '' + item)

    let maxValue = (graphModel || []).reduce((acc, cur) => {
      return Math.max(acc, Math.max.apply(null, (cur.values || []).map(item => measurer.width('' + formatValue(item.value)))))
    }, 0)

    if (graphOptions && graphOptions.yAxisTickValues) {
      maxValue = Math.max(maxValue, 
        Math.max.apply(null, graphOptions.yAxisTickValues.map(item => measurer.width('' + formatValue(item))))
      )
    }

    return Math.max(80, maxValue + 30)
  } catch (ex) {
    // Just in case....
    console.error(ex)
    return 80
  }
}