import { Injectable } from '@angular/core'
import { SettingsModel } from './settingsModel'
import { Category } from '../api'
import { DataLoadingModel } from './dataLoadingModel'
import { AbstractLocation } from './entities/place'

@Injectable()
export class CategoryColorModel {
  constructor(private settings: SettingsModel, private dataLoadingModel: DataLoadingModel) {}

  updateCustomColor(category: Category, color: string) {
    category.customColor = color
    category.color = color

    this.settings.displaySettings.nextPropertyWithCurrent(
      'customCategoryColorsPalette',
      (customCategoryColorsPalette: any) => {
        customCategoryColorsPalette = customCategoryColorsPalette || {}

        const key = this.dataLoadingModel.categoryKey(category)
        customCategoryColorsPalette[key] = color

        return { ...customCategoryColorsPalette }
      }
    )

    this.settings.displaySettings.nextProperty('showCustomCategoryColors', true)
  }

  updateCustomColorLocation(location: AbstractLocation, color: string) {
    this.settings.displaySettings.nextPropertyWithCurrent(
      'customLocationsColorsPalette',
      (customCategoryColorsPalette: any) => {
        customCategoryColorsPalette = customCategoryColorsPalette || {}

        customCategoryColorsPalette[location.id] = color

        return { ...customCategoryColorsPalette }
      }
    )

    this.settings.displaySettings.nextProperty('showCustomCategoryColors', true)
  }
}
