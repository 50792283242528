export const BORDER_COLOR = '#fc7bea'

export const TABLE_OPTIONS_DEMOGRAPHICS = {
  widths: () => [116, 56, 66, 56, 40],
  layout: {
    hLineColor: function (i: number, node: any) {
      return BORDER_COLOR
    },

    hLineWidth: function (i: number, node: any) {
      if (i === 1) {
        return 2
      // } else if (i === 2) {
      //   return 1
      } else {
        return 0
      }
    },

    vLineColor: function (i: number, node: any) {
      return BORDER_COLOR
    },

    vLineWidth: function (i: number, node: any) {
      return 0
    },

    fillColor(i: number, node: any) {
      if (i < 2) {
        return '#ffffff'
      }

      return (i % 2 === 0) ? '#fafafa' : '#ffffff'
    }    
  }
}


export const TABLE_OPTIONS_DEMOGRAPHICS_PERSONICS = {
  widths: () => [70, 41, 41, 41, 41, 41, 41],
  layout: {
    hLineColor: function (i: number, node: any) {
      return BORDER_COLOR
    },

    hLineWidth: function (i: number, node: any) {
      return 0
    },

    vLineColor: function (i: number, node: any) {
      return BORDER_COLOR
    },

    vLineWidth: function (i: number, node: any) {
      return 0
    },

    fillColor(i: number, node: any) {
      if (i <= 2) {
        return '#ffffff'
      }

      return (i % 2 === 0) ? '#fafafa' : '#ffffff'
    }    
  }
}


export const TABLE_OPTIONS_OVER_UNDER_SUPPLY = {
  widths: () => ['*', 50, 50, 60, 50, 50, 55, 60, 50, 50, 60, 55],

  layout: {
    hLineColor: function (i: number, node: any) {
      return '#000000' // BORDER_COLOR
    },

    hLineWidth: function (i: number, node: any, col: number) {
      if (col === 0) {
        return 0
      }

      // if (node.table && node.table.body && node.table.body[i - 1] && node.table.body[i - 1][0].titleRowUnderline) {
      //   return 1
      // }
      return 0
    },

    vLineColor: function (i: number, node: any) {
      return BORDER_COLOR
    },

    vLineWidth: function (i: number, node: any) {
      return 0
    },

    fillColor(i: number, node: any, col: number) {
      if (i < 2) {
        return '#ffffff'
      }


      if (node.table && node.table.body && node.table.body[i] 
          && (
            node.table.body[i][0].titleRow || node.table.body[i][0].separator || node.table.body[i][col].labelColumn
          )) {

        return '#ffffff'
      }

      return (i % 2 === 0) ? '#fafafa' : '#ffffff'
    }    
  }  
}

export const TABLE_OPTIONS_OTHER = {
  layout: {
    hLineColor: function (i: number, node: any) {
      return '#000000' // BORDER_COLOR
    },

    hLineWidth: function (i: number, node: any, col: number) {
      if (col === 0) {
        return 0
      }
      
      // if (i === 1) {
      //   return 1
      // }

      return 0
    },

    vLineColor: function (i: number, node: any) {
      return BORDER_COLOR
    },

    vLineWidth: function (i: number, node: any) {
      return 0
    },

    fillColor(i: number, node: any, col: number) {
      if (i <= 1 || col === 0) {
        return '#ffffff'
      }

      return (i % 2 === 0) ? '#fafafa' : '#ffffff'
    }    
  }  
}