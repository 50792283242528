import * as models from '../../common/models'
import { Injectable } from '@angular/core'
import { RESTHelper, RANDOM_COLORS } from '@targomo/client'
import { object } from '@targomo/common'
import { AppModel } from '../model/appModel.service'
import { CategoryLike } from '../../common/models'
import { DataSet } from './dataSet'
import { getCategoryKey } from '../model/dataLoadingModel'

const DEFAULT_COLORS = [
  '#9E9D24',
  '#558B2F',
  '#0091EA',
  '#00B8D4',
  '#00BFA5',
  '#00C853',
  '#AEEA00',
  '#FF6F00',
  '#4A148C',
  '#C51162',
  '#D50000',
  '#FFD600',
]

export class CategoryAverageSizes {
  private counts: Record<'netSalesArea' | 'grossInternalArea', Record<string, { count: number; value: number }>> =
    {} as never

  add(property: 'netSalesArea' | 'grossInternalArea', type: string, value: number) {
    this.counts[property] = this.counts[property] || {}
    this.counts[property][type] = this.counts[property][type] || { count: 0, value: 0 }

    if (value != null) {
      this.counts[property][type].count++
      this.counts[property][type].value += value
    }
  }

  getSize(property: 'netSalesArea' | 'grossInternalArea', types: string[]) {
    const propertyCounts = this.counts[property]
    let total = 0
    let count = 0

    types.forEach((type) => {
      total += propertyCounts[type] ? propertyCounts[type].value : 0
      count += propertyCounts[type] ? propertyCounts[type].count : 0
    })

    return count === 0 ? null : total / count || 0
  }
}

export class Category implements models.Category, CategoryLike {
  id: number
  code: string
  name: string
  grouping: string
  dataSetName?: string
  dataSet?: DataSet
  color?: string
  originalColor?: string
  customColor?: string
  markerStyleCategory?: string
  visible?: boolean
  pinned?: boolean = false
  placesCount?: number
  placesCountOriginal?: number
  filtered?: boolean
  selected?: boolean
  averageSizes?: CategoryAverageSizes

  customIcon?: number
  customIconSize?: string

  init(): this {
    if (!this.color) {
      const name = String(this.name)
      this.color =
        DEFAULT_COLORS[
          (name.charCodeAt(name.length - 1) * 2 + name.charCodeAt(0) * 10 + name.length) % DEFAULT_COLORS.length
        ]
    }

    this.originalColor = this.color
    this.placesCountOriginal = this.placesCount

    return this
  }

  getPersistentCode() {
    return getCategoryKey(this)
  }

  // isCustom() {
  //   return this == CUSTOM_CATEGORY
  // }
}

export const CUSTOM_CATEGORIES: { [fascia: string]: Category } = {}

export function nextCategoryId() {
  let id = -1
  for (let key in CUSTOM_CATEGORIES) {
    id = Math.min(CUSTOM_CATEGORIES[key].id, id)
  }

  return id - 1
}

export function customCategory(fascia: string) {
  fascia = (fascia || '').toUpperCase()

  if (!CUSTOM_CATEGORIES[fascia]) {
    const color = RANDOM_COLORS[(fascia.charCodeAt(0) + fascia.charCodeAt(1)) % RANDOM_COLORS.length] || '#5496c4'

    const category = new Category()
    category.id = nextCategoryId()
    category.name = fascia
    category.color = color
    category.originalColor = color
    category.markerStyleCategory = color
    category.visible = true

    CUSTOM_CATEGORIES[fascia] = category
  }

  return CUSTOM_CATEGORIES[fascia]
}

// CUSTOM_CATEGORY.name  = 'Custom'
// CUSTOM_CATEGORY.color = '#0079c9'
// CUSTOM_CATEGORY.markerStyleCategory = '#0079c9'
// CUSTOM_CATEGORY.visible = true

@Injectable()
export class CategoryEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper, private appModel: AppModel) {
    this.http = RestHelper.prefix('/categories')
  }

  get(category: { id: number }) {
    return this.http.get<Category>(`/${category.id}`)
  }

  save(category: Category) {
    return this.http.put<Category>(`/${category.id}`, category)
  }

  async findAll() {
    const categories = await this.http.get<Category[]>(`/`)
    return categories.map((category) => {
      return object.assign(new Category(), category).init()
    })
  }

  custom(fascia: string) {
    return customCategory(fascia)

    // fascia = (fascia || '').toUpperCase()
    // if (!CUSTOM_CATEGORIES[fascia]) {
    //   const color = RANDOM_COLORS[(fascia.charCodeAt(0) + fascia.charCodeAt(1)) % RANDOM_COLORS.length]
    //   const category = new Category()
    //   category.id = this.appModel.places.nextCategoryId()
    //   category.name = fascia
    //   category.color = color
    //   category.originalColor = color
    //   category.markerStyleCategory = color
    //   category.visible = true

    //   CUSTOM_CATEGORIES[fascia] = category
    // }

    // return CUSTOM_CATEGORIES[fascia]
  }
}

// @Injectable()
// export class ProjectsEndpoint {
//   constructor(private http: RESTHelper) {
//     this.http = http.prefix('/projects')
//   }

//   async create(project: Project) {
//     const newProject = {
//       name: project.name,
//       layers: project.layers && project.layers.map(serializeLayer)
//     }

//     return this.http.post('/', newProject)
//   }

//   async update(project: Project) {
//     const newProject = {
//       id: project.id,
//       name: project.name,
//       layers: project.layers && project.layers.map(serializeLayer)
//     }

//     return this.http.put(`/${project.id}`, newProject)
//   }

//   async me() {
//     return this.http.get<Project[]>('/me')
//   }

//   async find(id: number) {
//     const result = await this.http.get<Project>(`/${id}`)
//     result.layers = result.layers && result.layers.map(deserializeLayer)
//     return result
//   }

//   async remove(id: number) {
//     return this.http.delete(`/${id}`)
//   }
// }
