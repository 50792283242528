import { Component, OnInit, Input } from '@angular/core'
import { Indicators, SubscriberComponent } from '@targomo/client'
import { array as arrays } from '@targomo/common'
import { MatchpointSettingType } from '../../../../common/models/matchpoint'
import { MatchpointEndpoint } from '../../../api/matchpoint'
import { MATCHPOINT_COLUMNS } from '../../../pages/admin/adminComparisonReportOptions/adminComparisonReportOptions.component'
import { MatchpointModel } from '../../../model/matchpoint/matchpointModel'
import { MatchpointDialogComponent } from '../matchpointDialog/matchpointDialog.component'
import { AppModel } from '../../../model/appModel.service'
import { object as objects } from '@targomo/common'
import { WHITELISTED_DOMINANT_STATISTICS_GROUPS } from '../../../model/matchpoint/helpers/findDominantVariable'
import { take } from 'rxjs/operators'

const CONDITION_TYPES = [
  { label: 'Regional Index', value: MatchpointSettingType.PERCENT_INDEX },
  { label: 'Absolute Value', value: MatchpointSettingType.ABSOLUTE_VALUE },
  { label: 'User Selected Index', value: MatchpointSettingType.USER_INDEX },
  { label: 'User Selected Absolute Value', value: MatchpointSettingType.USER_ABSOLUTE_VALUE },
]

function prepareCategories() {
  const CATEGORIES: any[] = JSON.parse(
    JSON.stringify(
      MATCHPOINT_COLUMNS.concat([
        { isCatchment: true, key: '$$$CatchmentSpend', label: '* Retail GIA (including F&B and Leisure)' },
      ])
    )
  )

  function mapChildren(item: any) {
    item.children = item.children || []
    item.childrenMap = ((item.children || []) as any[]).reduce((acc2, cur2) => {
      acc2[cur2.key] = cur2
      return acc2
    }, {} as any)
  }

  // note, don't delete ... because childrenMap update on original
  const CATEGORIES_MAP = CATEGORIES.reduce((acc, cur) => {
    acc[cur.key] = cur
    mapChildren(cur)
    return acc
  }, {} as any)

  // special case 1
  CATEGORIES_MAP['demographics@tenure#ownedrented'].children = CATEGORIES_MAP['demographics@tenure'].children.filter(
    (item: any) =>
      WHITELISTED_DOMINANT_STATISTICS_GROUPS['demographics@tenure#ownedrented']['demographics@tenure@' + item.key]
  )
  mapChildren(CATEGORIES_MAP['demographics@tenure#ownedrented'])

  // special case 2
  ;['lifestyle#A1D5', 'lifestyle#A1C5'].forEach((lifestyle) => {
    CATEGORIES_MAP[lifestyle].children = Object.keys(WHITELISTED_DOMINANT_STATISTICS_GROUPS[lifestyle]).map((item) => {
      const key = item.slice('lifestyle@'.length)
      const keyParts = key.split('@')
      return { key, label: [keyParts[0], +keyParts[1] + 1].join('') }
    })
    mapChildren(CATEGORIES_MAP[lifestyle])
  })

  return CATEGORIES
}

const CATEGORIES: any[] = prepareCategories()

@Component({
  selector: 'matchpoint-config-panel',
  templateUrl: './matchpointConfigPanel.component.html',
  styleUrls: ['./matchpointConfigPanel.component.less'],
})
export class MatchpointConfigPanelComponent extends SubscriberComponent implements OnInit {
  @Input() readonly model: MatchpointModel

  readonly PERCENT_INDEX = MatchpointSettingType.PERCENT_INDEX
  readonly ABSOLUTE_VALUE = MatchpointSettingType.ABSOLUTE_VALUE
  readonly USER_INDEX = MatchpointSettingType.USER_INDEX
  readonly USER_ABSOLUTE_VALUE = MatchpointSettingType.USER_ABSOLUTE_VALUE

  saving: Promise<{}>
  availableCategories: {
    selected?: boolean
    key: string
    label: string
    minimum?: number
    maximum?: number
    type?: number
    subKey?: string
  }[] = arrays.sortBy(JSON.parse(JSON.stringify(CATEGORIES)), 'label')

  visibleCategories = this.availableCategories

  showOnlySelected = false
  avalableConditionTypes = CONDITION_TYPES
  currentPanel: 'edit' | 'load' | 'save' = 'edit'
  dominant: any = {}

  constructor(
    private matchpointEndpoint: MatchpointEndpoint,
    private indicators: Indicators,
    private parent: MatchpointDialogComponent,
    private appModel: AppModel
  ) {
    super()
  }

  async ngOnInit() {
    let current = await this.appModel.settings.matchpointUserSettingsUpdates.pipe(take(1)).toPromise()
    if (!current) {
      current = await this.indicators.add(this.matchpointEndpoint.getSettingsPublic())
    }

    this.loadSettings(current)

    this.modelChanged()
    // const currentMap = current.reduce((acc: any, cur: any) => {
    //   acc[cur.statistic] = cur
    //   return acc
    // }, {})

    // this.availableCategories.forEach((profile) => {
    //   profile.selected = !!currentMap[profile.key]

    //   profile.minimum = null
    //   profile.maximum = null
    //   profile.type = 0

    //   if (profile.selected) {
    //     profile.minimum = currentMap[profile.key].minimum
    //     profile.maximum = currentMap[profile.key].maximum
    //     profile.type = currentMap[profile.key].type || 0
    //   }
    // })
  }

  // async save(event: any) {
  //   const selectedCategories = this.availableCategories
  //     .filter((profile) => profile.selected)
  //     .map((value: any) => {
  //       return {
  //         statistic: value.key,
  //         minimum: value.minimum,
  //         maximum: value.maximum,
  //         type: value.type,
  //       }
  //     })

  //   this.saving = this.indicators.add(this.matchpointEndpoint.saveSettings(selectedCategories))
  // }

  async getUpdatedConfig() {
    const selectedCategories = this.availableCategories
      .filter((profile) => profile.selected)
      .map((value: any) => {
        return {
          statistic: value.key,
          minimum: value.minimum,
          maximum: value.maximum,
          type: value.type,
          subKey: value.subKey,
        }
      })

    return selectedCategories
  }

  toggleCategory(category: { selected: boolean }) {
    category.selected = !category.selected

    this.modelChanged()
  }

  toggleOnlySelected(value: any) {
    if (value) {
      this.visibleCategories = this.availableCategories.filter((item) => !!item.selected)
    } else {
      this.visibleCategories = this.availableCategories
    }
  }

  updateModel(current: any[]) {
    this.loadSettings(current)
    this.appModel.settings.displaySettings.nextProperty('matchpointUserSettings', current)
  }

  loadSettings(current: any[]) {
    const currentMap = current.reduce((acc: any, cur: any) => {
      acc[cur.statistic] = cur
      return acc
    }, {})

    this.availableCategories.forEach((profile) => {
      profile.selected = !!currentMap[profile.key]

      profile.minimum = null
      profile.maximum = null
      profile.type = 0

      if (profile.selected) {
        profile.minimum = currentMap[profile.key].minimum
        profile.maximum = currentMap[profile.key].maximum
        profile.type = currentMap[profile.key].type || 0
        profile.subKey = currentMap[profile.key].subKey
      }
    })

    this.modelChanged()
  }

  save(event?: any) {
    this.currentPanel = 'save'
  }

  load() {
    this.currentPanel = 'load'
  }

  back() {
    this.currentPanel = 'edit'
  }

  use() {}

  async modelChanged(event?: any) {
    setTimeout(async () => {
      const settings = await this.getUpdatedConfig()
      const materialized = await this.model.materializeConfigFromSettings(settings)
      this.dominant = objects.values(materialized.dominant).reduce((acc, cur) => {
        acc[cur.originalKey] = cur
        return acc
      }, {})
    })
  }
}
