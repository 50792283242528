<form>
  <div fxLayoutAlign="start center">
    <mat-form-field fxFlex floatPlaceholder="never">
      <input placeholder="Filter categories" fxFlex matInput [ngModel]="searchString" [formControl]="formControl" />
    </mat-form-field>

    <button
      title="Clear search"
      [disabled]="!formControl.value"
      (click)="clearSelection()"
      type="button"
      mat-icon-button
    >
      <mat-icon class="mat-20">clear</mat-icon>
    </button>
    <button title="Show only mapped categories" (click)="showMapped()" type="button" mat-icon-button>
      <mat-icon [color]="(settings.showOnlyMappedCategoriesUpdates | async) ? 'primary' : 'black'" class="mat-20"
        >location_on</mat-icon
      >
    </button>
    <button title="De-select all layers" (click)="clearLayers()" type="button" mat-icon-button>
      <mat-icon class="mat-20">layers_clear</mat-icon>
    </button>
    <button title="Map Filters" (click)="mapFilters()" type="button" mat-icon-button>
      <mat-icon class="mat-20">tune</mat-icon>
    </button>
  </div>
</form>
