import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js'
import * as turf from '@turf/turf'
import { LatLng } from '@targomo/core'
import { Observable, BehaviorSubject } from 'rxjs'
import distance from '@turf/distance'
import * as turfHelpers from '@turf/helpers'
import circle from '@turf/circle'

// TODO:
// 1. correct number of points for polygon
// 2. show center point
// 3. drag from toolbar like niek did in wall
// 4. fix edit

import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode } from '../../../other/mapbox-gl-draw-circle'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

/**
 *
 */
export class MapboxDrawLayer {
  private draw: any
  private empty = true

  private circleRadius: number = 2

  private currentModeSubject$ = new BehaviorSubject<string>(null)
  readonly currentMode$: Observable<string> = this.currentModeSubject$
    .asObservable()
    .distinctUntilChanged()
    .shareReplay(1)
  private editUpdateSubject$ = new BehaviorSubject<any>(null)
  readonly editUpdate$ = this.editUpdateSubject$.asObservable().distinctUntilChanged().shareReplay(1)

  constructor(private map: CustomMapboxComponent, options?: any) {
    this.draw = new MapboxDraw({
      ...options,
      defaultMode: 'draw_circle',
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
      },
    })
    this.map.addControl(this.draw)

    this.init()
  }

  private async init() {
    const map = await this.map.getMap()
    map.on('draw.modechange', this.onModeChange)
    map.on('draw.create', this.onCreate)

    map.on('draw.update', this.onEdit)
    map.on('draw.render', this.onEdit)
  }

  private readonly onModeChange = (event: any) => {
    this.currentModeSubject$.next(event.mode)
  }

  private readonly onCreate = (event: any) => {
    this.setFeatures(this.getCombinedGeometry())
  }

  private readonly onEdit = (event: any) => {
    this.editUpdateSubject$.next(this.getFeatures())
  }

  async removeControl() {
    const map = await this.map.getMap()
    map.removeControl(this.draw)
    map.off('draw.modechange', this.onModeChange)
    map.off('draw.create', this.onCreate)
    map.off('draw.update', this.onEdit)
    map.off('draw.render', this.onEdit)
  }

  modeDraw() {
    this.draw.changeMode(this.draw.modes.DRAW_POLYGON)
    this.currentModeSubject$.next(this.draw.modes.DRAW_POLYGON)
  }

  modeDrawCircle() {
    const mode = this.draw.changeMode('draw_circle', {
      maximumRadiusInKm: 10,
      initialRadiusInKm: () => {
        return this.circleRadius
      },
    })

    this.currentModeSubject$.next('draw_circle')
  }

  modeSelect() {
    this.draw.changeMode(this.draw.modes.DIRECT_SELECT, { featureId: 'MAIN' })
    this.currentModeSubject$.next(this.draw.modes.DIRECT_SELECT)
  }

  modeNodeSelect() {
    const featureId = this.draw.getSelectedId()
    if (featureId) {
      this.draw.changeMode(this.draw.modes.DIRECT_SELECT, { featureId: featureId })
      this.currentModeSubject$.next(this.draw.modes.DIRECT_SELECT)
    }
  }

  modeInactive() {
    this.draw.changeMode(this.draw.modes.STATIC)
    this.currentModeSubject$.next(this.draw.modes.STATIC)
  }

  isEmpty() {
    return this.empty
  }

  delete() {
    this.empty = true
    this.draw.trash()
    this.draw.deleteAll()
    this.modeDraw()
  }

  deleteAll() {
    this.empty = true
    this.draw.deleteAll()
    this.modeDraw()
  }

  getFeatures() {
    return this.draw.getAll()
  }

  setFeatures(geometry: any) {
    this.empty = false
    this.draw.deleteAll()
    geometry.id = 'MAIN'
    this.draw.add(geometry)
    setTimeout(() => {
      this.modeSelect()
    })
  }

  asFilter() {
    const features = this.getFeatures()
    if (!features) {
      return function () {
        return false
      }
    }

    const polygons = turf.multiPolygon(features.features.map((feature: any) => feature.geometry.coordinates))
    return function (point: LatLng) {
      return turf.inside([point.lng, point.lat], polygons)
    }
  }

  getCombinedGeometry() {
    const featureCollection = this.getFeatures()
    const features = (featureCollection && featureCollection.features) || []
    let polygons: any[] = []

    if (features.length === 1) {
      if (features[0].properties.isCircle) {
        features[0].geometry = this.makeCircle(features[0]).geometry
      }

      return features[0]
    }

    features.forEach((feature: any) => {
      if (feature.geometry.type.toLowerCase() === 'polygon') {
        polygons.push(feature.geometry.coordinates)
      } else {
        polygons = polygons.concat(feature.geometry.coordinates)
      }
    })

    const result = turf.multiPolygon(polygons)
    result.id = 'MAIN'
    return result
  }

  setCircleRadius(circleRadius: number) {
    if (circleRadius !== undefined) {
      this.circleRadius = circleRadius

      const featureCollection = this.getFeatures()
      const feature = featureCollection && featureCollection.features && featureCollection.features[0]

      if (feature && feature.properties.isCircle) {
        feature.properties.radiusInKm = circleRadius
        feature.geometry = this.makeCircle(feature).geometry
        this.draw.add(feature)
      }
    }
  }

  private makeCircle(feature: any): any {
    const center = feature.properties.center
    const circleFeature = circle(center, feature.properties.radiusInKm, { steps: 260 })
    return circleFeature
  }
}
