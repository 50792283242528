import { SubscriberComponent, GraphValue, BarGraphOptions } from '@targomo/client'
import { Component, OnInit } from '@angular/core';
import { AppModel } from '../../model/appModel.service';
import { AllStatistics } from '../../../common/models/statistics/statistics';
import { POPULATION_FORECAST_FROM_YEAR, POPULATION_FORECAST_TO_YEAR, SettingsModel } from '../../model/settingsModel';
import { Observable } from 'rxjs';
import * as indices from '../../../common/models/statistics/statisticsIndices'
import { CustomBarGraphOptions } from '../../components/tgm-custom-bargraph/bargraph.component';
import { DecimalPipe } from '@angular/common';

const FROM_YEAR = POPULATION_FORECAST_FROM_YEAR
const TO_YEAR = POPULATION_FORECAST_TO_YEAR

const DECIMAL_PIPE = new DecimalPipe('en')

@Component({
  selector: 'population-forecast',
  templateUrl: './populationForecast.component.html',
  styleUrls: ['./populationForecast.component.less']
})
export class PopulationForecastComponent extends SubscriberComponent implements OnInit {
  // range slider in out library has a bug, but I can't update at this point, because I have to migrate
  // statistics names changes too
  minValue = 0
  maxValue = TO_YEAR - FROM_YEAR

  lowerValue = 0
  upperValue = this.maxValue

  yearValues: {name: string, value: number, change: number, indexVsAverage: number}[] = null

  labelYears: string[] = []

  loading: Promise<{}>

  graphPopulationModel: GraphValue[]
  graphPopulationOptions: BarGraphOptions

  // statistics: CensusStatistics
  allStatistics: AllStatistics

  constructor(
    readonly appModel: AppModel,
    readonly settings: SettingsModel
  ) {
    super()

    this.labelYears = []
    for (let i = FROM_YEAR; i <= TO_YEAR; i++) {
      this.labelYears.push('' + i)
    }

    this.graphPopulationOptions = new CustomBarGraphOptions({
      width: 420,
      showValues: true,
      showControls: false,
      axisLabelDistance: 5,
      xAxisLabelRotate: -45,
      xAxisLabel: '',
      yAxisLabel: '', margin: {left: 60},
      tooltip: (item: any) => {

        return `
        <table>
          <tr>
            <td class="legend-color-guide"><div style="background-color: ${item.color}"></div></td>
            <td class="key">${item.data ? item.data.label : ''}</td>
          </tr>
          <tr>
            <td></td>
            <td class="value">${item.data ? DECIMAL_PIPE.transform(item.data.value, '1.0-0') : ''}</td>
          </tr>
        </table>
        `
      },
      color: (item: any, index: number) => {
        // return item.series === 0 ? '#5360bb' : '#e17f4c'
        return item.series === 0 ? '#00f' : '#f00'
      }
    })
  }

  ngOnInit() {
    this.watch(this.appModel.statistics.census.promise, promise => this.loading = promise)

    // this.watch(this.appModel.statistics.census.value, statistics => {
    //   if (statistics) {
    //     // this.statistics = statistics.census
    //     this.allStatistics = statistics
    //   } else {
    //     // this.statistics = null
    //     this.allStatistics = null
    //   }
    // })

    this.watch(Observable.combineLatest(
      this.settings.populationForecastRangeUpdates,
      this.appModel.statistics.populationForecast,
      this.appModel.statistics.census.value
    ), ([value, forecast, statistics]) => {
      if (statistics) {
        this.allStatistics = statistics
      } else {
        this.allStatistics = null
        this.yearValues = null
        this.graphPopulationModel = null
        return
      }

      if (!forecast) {
        this.yearValues = null
        this.graphPopulationModel = null
        return
      }

      this.lowerValue = value.min - FROM_YEAR
      this.upperValue = value.max - FROM_YEAR

      this.yearValues = [
        {name: '' + value.min, value: 0, change: null, indexVsAverage: null, },
        {name: '' + value.max, value: 0, change: 0, indexVsAverage: 0, }
      ]

      this.graphPopulationModel = forecast
      if (forecast) {
        forecast.forEach((graphValue, i) => {
          const total = graphValue.values.reduce((total, current) => total + current.value, 0)
          this.yearValues[i].value = total

          if (i !== 0) {
            this.yearValues[i].change = (this.yearValues[i].value / this.yearValues[i - 1].value) - 1
            this.yearValues[i].indexVsAverage = (this.yearValues[i].change / indices.getForecastGrowth(statistics.region, value.min, value.max)) * 100
          }
        })
      }
    })
  }

  onSliderChange(which: string) {
    this.settings.displaySettings.nextPropertyWithCurrent('populationForecastRange', value => {
      return {min: FROM_YEAR + this.lowerValue, max: FROM_YEAR + this.upperValue}
    })
  }
}
