
    <div>
      <tgm-loadable [model]="loading">
        <table class="versions" *ngIf="versions && versions.length > 0">
          <tbody>
            <tr>
              <th></th>
              <th>Version</th>
              <th></th>
              <th>Preview</th>
              <th>Active</th>
            </tr>

            <tr *ngFor="let version of versions">
              <td>
                <mat-icon
                  [hidden]="version.preview || version.active"
                  class="delete"
                  (click)="deleteVersion(version)"
                  mat-font-set="material-icons"
                  >delete</mat-icon
                >
              </td>
              <td>
                <span fxFlex>{{ version.createdAt | date: 'y-MM-dd HH:mm' }}</span>
              </td>
              <td>
                <i
                  class="material-icons general-hourclass-icon-animation"
                  *ngIf="version.jobStatusText && (version.preview || version.active)"
                  [title]="version.jobStatusText"
                  >hourglass_empty</i
                >
              </td>
              <td>
                <mat-checkbox [checked]="version.preview" (change)="togglePreview(version, $event)"></mat-checkbox>
              </td>
              <td><mat-checkbox [checked]="version.active" (change)="toggleActive(version, $event)"></mat-checkbox></td>
            </tr>
          </tbody>
        </table>

        <div class="no-versions" *ngIf="versions && versions.length == 0">No locations in this dataset</div>
      </tgm-loadable>

      <div *ngIf="versions && versions.length == 0">
        <div style="text-align: center; margin-top: 24px">
          <button mat-button (click)="deleteSet()">
            <i class="material-icons">delete_forever</i> <span>Delete this data set</span>
          </button>
        </div>
      </div>
    </div>
  