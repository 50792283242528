import { Component, OnInit } from '@angular/core';
import { MessagesEndpoint, Message } from '../../api/message';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-message-popup',
  templateUrl: './messagePopup.component.html',
  styleUrls: ['./messagePopup.component.less']
})
export class MessagePopupComponent implements OnInit {
  visible = false
  nextButton = false
  index = -1
  messages: Message[]
  message: Message = null

  constructor(
    private messagesEndpoint: MessagesEndpoint,
    private matSnackBar: MatSnackBar
  ) { }

  async ngOnInit() {
    this.messages = await this.messagesEndpoint.me()

    if (this.messages && this.messages.length) {
      this.visible = true
      this.nextButton = this.messages.length > 1
      this.nextMessage()
    }
  }

  private nextMessage() {
    this.index++
    if (this.index >= this.messages.length) {
      this.visible = false
      return
    }

    this.message = this.messages[this.index]

    if (this.index >= this.messages.length - 1) {
      this.nextButton = false
    }
  }

  close() {
    this.messagesEndpoint.dismiss(this.message)
    this.nextMessage()
  }

  async closeRequest() {
    await this.messagesEndpoint.requestInfo(this.message)
    this.matSnackBar.open('Your message has been received', null, {duration: 5000})

    this.nextMessage()
  }  
}
