
import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CategoryLike } from '../../../../../common/models';
import { Category } from '../../../../api';
import { DataSetLike } from '../../../../../common/models/dataSet';

interface CategoryGrouping {
  name: string
  visible: boolean
  filtered: boolean
  selected: boolean
  children: CategoryLike[]
  childrenOriginal: CategoryLike[]
}

@Component({
  selector: 'admin-select-fascias-category',
  templateUrl: './adminSelectFasciasCategory.component.html',
  styleUrls: ['./adminSelectFasciasCategory.component.less']
})
export class AdminSelectFasciasCategoryComponent implements OnInit, OnChanges {
  @Input() categories: CategoryLike[]
  @Input() filter: string
  @Input() allowGroupings: boolean = true
  @Input() dataSet: DataSetLike
  @Input() showOnlySelected: boolean = false

  @Output() selectionChange = new EventEmitter<CategoryLike>()
  groupings: CategoryGrouping[]
  selectAll: boolean = false

  constructor(
  ) {
  }

  ngOnInit() {
    this.ngOnChanges(null)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes || changes.categories) {
      const groupingsMap: {[index: string]: CategoryLike[]} = {}
      if (this.categories) {
        this.categories.forEach(category => {
          if (groupingsMap[category.grouping]) {
            groupingsMap[category.grouping].push(category)
          } else {
            groupingsMap[category.grouping] = [category]
          }
        })

        this.groupings = Object.keys(groupingsMap).sort().map(name => {
          return {
            name,
            visible: false,
            filtered: true,
            selected: false,
            children: groupingsMap[name],
            childrenOriginal: groupingsMap[name]
          }
        })

      }

      this.updateAllSelected()
    }

    function contains(value: string, segment: string) {
      if (!segment) {
        return false
      }

      return (value || '').toLowerCase().indexOf(segment) > -1
    }

    if (!changes || changes.filter || changes.showOnlySelected) {
      const filterText = (this.filter || '').trim().toLowerCase()

      let filteredChildren = false
      this.groupings.forEach(grouping => {
        let found = false
        grouping.children.forEach(category => {
          category.filtered = !filterText || contains(category.name, filterText)

          if (this.showOnlySelected && !category.selected) {
            category.filtered = false
          }

          found = found || category.filtered
          filteredChildren = filteredChildren || category.filtered
        })

        if (!this.showOnlySelected) {
          grouping.filtered = !filterText || found || contains(grouping.name, filterText)
        } else {
          grouping.filtered = found
        }

        filteredChildren = filteredChildren || grouping.filtered
      })

      if (this.dataSet) {
        this.dataSet.filteredChildren = filteredChildren
      }
    }
  }

  toggle(grouping: CategoryGrouping) {
    grouping.visible = !grouping.visible
  }

  toggleSelect(category: CategoryLike) {
    category.selected = !category.selected

    this.selectionChange.next(category)
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll
    this.categories.forEach(category => category.selected = this.selectAll)
    this.updateAllSelected()
    this.selectionChange.next(null)
  }

  toggleSelectGrouping(grouping: CategoryGrouping, event: any) {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    grouping.selected = !grouping.selected
    grouping.children.forEach(category => category.selected = grouping.selected)
    this.updateAllSelected()
    this.selectionChange.next(null)
  }

  private updateAllSelected() {
    let result = true

    this.categories.forEach(category => {
      result = result && category.selected
    })

    this.selectAll = result

    if (this.groupings) {
      this.groupings.forEach(grouping => {
        let caregoryResult = true
        grouping.children.forEach(category => {
          caregoryResult = caregoryResult && category.selected
        })

        grouping.selected = caregoryResult
      })
    }
  }
}
