<!-- <div *ngIf="selectedDetailsPlaceSubject | async as place">
  <button matTooltip="Back" matTooltipPosition="below" mat-button type="button" (click)="backDetails()"><mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span>Back</span></button>
  <place-details [location]="place" [fetch]="false" [back]="false"></place-details>
</div> -->

<div>
  <section style="padding: 0px 16px" *ngIf="!selectedPlace">
    <div fxLayout="row" fxLayoutAlign="start center">
      <h3 fxFlex>Risk Assessement Report</h3>
      <div *ngIf="!!locations?.length">
        <button mat-button type="button" (click)="export()"><mat-icon class="arrow-icon" mat-font-set="material-icons">get_app</mat-icon> <span>Export as CSV</span></button>
      </div>
    </div>

    <div style="margin: 16px 0px" fxLayout="column">
      <div class="select-label">Opening  within</div>
      <mat-select placeholder="Opening within X " [value]="timeSubject| async" style="min-width: 200px" fxFlex (selectionChange)="changeTime($event)">
        <mat-option *ngFor="let option of timeOptions" [value]="option.value">{{option.name}}</mat-option>
      </mat-select>  
    </div>

    <tgm-loadable class="auto-scroll" [model]="loading">
      <span class="no-data" *ngIf="!locations?.length">No Data</span>

      <div *ngIf="!!locations?.length">
        <table >
          <tr>
            <!-- <th></th> -->
            <th>Name</th>
            <th>Town</th>
            <th>Postcode</th>
            <th>Opening Nearby</th>
          </tr>

          <tr (click)="click(item)" *ngFor="let item of locations" (mouseleave)="hover(null)" (mouseenter)="hover(item)" [class.hover]="hoverPlace && (hoverPlace.id == item.id)">
            <!-- <td><span class="colorbox"></span></td> -->
            <td  style="width: 100%">{{item.name}}</td>
            <td  style="width: 100%">{{item.town}}</td>
            <td  style="width: 100%">{{item.postcode}}</td>
            <td class="value-cell">{{item.value | number}}</td>
          </tr>
        </table>
      </div>
    </tgm-loadable>
  </section>


  <section style="padding: 0px 16px" *ngIf="!!selectedPlace">
    <div style="margin: 16px 0px">
      <button matTooltip="Exit Risk Report Analysis" matTooltipPosition="below" mat-flat-button type="button" (click)="back()"><mat-icon class="arrow-icon" mat-font-set="material-icons">close</mat-icon> <span>Exit Risk Report Analysis</span></button>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex class="pre-title">Risk Assessement Report</div>
      <div *ngIf="!!planningLocations?.length">
        <button mat-button type="button" (click)="exportPlanning()"><mat-icon class="arrow-icon" mat-font-set="material-icons">get_app</mat-icon> <span>Export as CSV</span></button>
      </div>
    </div>

    <h3>{{selectedPlace.fascia}}</h3>

    <tgm-loadable class="auto-scroll" [model]="loading">
      <span class="no-data" *ngIf="!planningLocations?.length">No Data</span>


      <div *ngIf="!!planningLocations?.length">

        <table >
          <tr>
            <th>Name</th>
            <th>Town</th>
            <th>Postcode</th>
            <th>Travel Time (mins)</th>
            <th>GIA</th>
            <th>NSA</th>
            <th>Opening</th>
          </tr>

          <tr *ngFor="let item of planningLocations" 
            [class.hover]="(appModel.places.hoverPlanningPlace | async)?.id == item.id"
            (click)="clickPlanning(item)" (mouseleave)="hoverPlanning(null)" (mouseenter)="hoverPlanning(item)">
            <td style="width: 100%">{{item.fascia}}</td>
            <td style="width: 100%">{{item.town}}</td>
            <td style="width: 100%">{{item.postcode}}</td>
            <td class="value-cell">{{item.travelTime | minutesHoursSimple}}</td>
            <td class="value-cell">{{item.grossInternalArea | number}}</td>
            <td class="value-cell">{{item.netSalesArea | number}}</td>
            <td class="value-cell">{{item.openDate | date}}</td>
          </tr>
        </table>
      </div>
    </tgm-loadable>
  </section>
</div>