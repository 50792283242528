import { Place, DataSet, Category, canonicalPositions } from '../../api'
import { MatchpointEndpoint } from '../../api/matchpoint'
import { Indicators, ObservableList } from '@targomo/client'
import { AppModel } from '../appModel.service'
import { Observable, BehaviorSubject, combineLatest, concat, from } from 'rxjs'
import { DataLoadingModel } from '../dataLoadingModel'
import {
  map,
  shareReplay,
  tap,
  filter,
  take,
  skipUntil,
  distinctUntilChanged,
  concatMap,
  debounceTime,
  startWith,
} from 'rxjs/operators'
import { uniqueOperators } from './helpers/uniqueOperators'
import { loadMatchpointReport } from './transformations/loadReport'
import { allCentrePoints } from './transformations/allCentrePoints'
import { switchMap } from 'rxjs/operators'
import { processLocationOnLoad } from './helpers/processLocationsOnLoad'
import { AllStatistics, ReportPerCapitaHousehold } from '../../../common/models/statistics/statistics'
import { findDominantVariables } from './helpers/findDominantVariable'
import { DataSetFilter } from '../dataSetFilter'
import { DataSetLike } from '../../../common/models/dataSet'
import { CategoryLike } from '../../../common/models'
import { geometry } from '@targomo/core'

function compareValue(a: any, b: any, key: string, reverse: boolean = false): number {
  let va = reverse ? b[key] : a[key]
  let vb = reverse ? a[key] : b[key]

  if (va < vb) {
    return -1
  } else if (va > vb) {
    return 1
  } else {
    return 0
  }
}

function compareSubject(a: any, b: any): number {
  return compareValue(a, b, 'secondaryCategory') || compareValue(a, b, 'fascia') || 0
}

function compareSimilar(a: any, b: any): number {
  return (
    compareValue(a, b, 'secondaryCategory') ||
    compareValue(a, b, 'centerPointPercent', true) ||
    compareValue(a, b, 'fascia') ||
    0
  )
}

export interface MatchpointPlace extends Place {
  centerPointCount?: number
  centerPointPercent?: number
  secondaryCategory?: string
  centerPointMap?: any
}

export interface MatchpointResult {
  similar: MatchpointPlace[]
  subjectOperatorLocations: Place[]
  similarOperatorLocations: Place[]
}

export class MatchpointModel {
  readonly similarAndOther$: Observable<MatchpointPlace[]>
  readonly similar$: Observable<MatchpointPlace[]>
  readonly subjectOperatorLocations$: Observable<Place[]>
  readonly similarOperatorLocations$: Observable<Place[]>

  readonly subjectOperators$: Observable<MatchpointPlace[]>
  readonly similarGapOperators$: Observable<MatchpointPlace[]>
  readonly similarGapOperatorsWithTravelTime$: Observable<MatchpointPlace[]>
  readonly similarActiveAnalysis$: Observable<MatchpointPlace[]>
  readonly similarCategories$: Observable<string[]>

  private similarSelectionUpdates = new BehaviorSubject<boolean>(false)

  private selectedCategories: { [key: string]: boolean } = {}
  private selectedSimilar: { [key: number]: boolean } = {}
  private blacklistedSimilar: { [key: number]: boolean } = {}

  private additionalSimilar = new ObservableList<Place>()

  private similarMap: { [id: number]: MatchpointPlace } = {}
  private similarAndAdditionalMap: { [id: number]: MatchpointPlace } = {}

  private selectedCategoriesAll = true

  readonly loading$: Observable<Promise<MatchpointResult>>
  readonly report$: Observable<MatchpointResult>

  readonly allCentrePoints: Observable<Place[]>

  private includeCatchmentSubject = new BehaviorSubject(true)
  readonly includeCatchment$ = this.includeCatchmentSubject.asObservable().distinctUntilChanged().shareReplay(1)

  private includeTownCentersSubject = new BehaviorSubject(false)

  private operatorsAdditional$: Observable<Place[]>

  readonly gapOpportunityFilterUpdates$ = new BehaviorSubject<DataSetFilter>(null)
  readonly gapOpportunityFilter: DataSetFilter = new DataSetFilter({ id: 0 } as any, {}, () => {
    this.gapOpportunityFilterUpdates$.next(this.gapOpportunityFilter)
  })

  readonly gapOpportunityDataSets$: Observable<DataSet[]>
  readonly gapOpportunityDataSetsForFilter$: Observable<DataSetLike[]>

  readonly gapOpportunityFilterSelectedCategories$ = new BehaviorSubject<Set<Category>>(null)

  readonly catchmentPossible$: Observable<boolean>
  readonly isTownCentre$: Observable<boolean> = this.initSourceTownCenter()

  readonly includeTownCenters$ = combineLatest(
    this.includeTownCentersSubject.asObservable().distinctUntilChanged(),
    this.isTownCentre$
  ).pipe(
    map(([includeTownCentre, isTownCentre]) => {
      return includeTownCentre && isTownCentre
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )

  readonly centerPointId$ = this.includeTownCenters$.pipe(
    map((value) => (value ? 'CentrePoint Town ID' : 'CentrePoint ID')),
    distinctUntilChanged(),
    shareReplay(1)
  )

  constructor(
    private appModel: AppModel,
    private matchpointEndpoint: MatchpointEndpoint,
    private dataLoadingModel: DataLoadingModel,
    private indicators: Indicators
  ) {
    this.gapOpportunityFilter.setAverage()

    this.catchmentPossible$ = this.initCatchmentPossible()
    // this.isTownCentre$ = this.initSourceTownCenter()

    this.allCentrePoints = allCentrePoints(this.dataLoadingModel)

    this.loading$ = loadMatchpointReport(
      this.matchpointEndpoint,
      this.dataLoadingModel,
      this.appModel.places.sources.observable,
      this.appModel.statistics,
      this.appModel.settings.travelOptionsUpdates,
      // this.includeCatchment$,
      this.catchmentPossible$,
      this.appModel.settings.matchpointUserSettingsUpdates,
      this.centerPointId$
    )

    this.operatorsAdditional$ = this.initLoadAdditionalOperators().pipe(
      switchMap((promise) => {
        return promise && this.indicators.add(promise)
      }),
      filter((report) => !!report),
      shareReplay(1)
    )

    this.report$ = this.loading$.pipe(
      switchMap((promise) => {
        return promise && this.indicators.add(promise)
      }),
      filter((report) => !!report),
      tap((report) => {
        this.initSimilarMap(report.similar)
      }),
      shareReplay(1)
    )

    this.similar$ = this.report$.pipe(
      map((report) => report.similar),
      shareReplay(1)
    )

    this.subjectOperatorLocations$ = this.report$.pipe(
      map((report) => report.subjectOperatorLocations),
      shareReplay(1)
    )

    this.similarOperatorLocations$ = this.report$.pipe(
      map((report) => report.similarOperatorLocations),
      shareReplay(1)
    )

    this.similarCategories$ = this.initSimilarOperatorCategories()
    this.similarActiveAnalysis$ = this.initActiveAnalysis()
    this.subjectOperators$ = this.initSubjectOperators()

    this.similarGapOperators$ = this.initSimilarOperatorsGapOpportunity()

    this.similarGapOperatorsWithTravelTime$ = this.initSimilarOperatorsGapOpportunityWithTravel()

    this.similarAndOther$ = combineLatest(this.similar$, this.additionalSimilar.observable).pipe(
      map(([similar, other]) => {
        return [].concat(similar, other)
      }),
      shareReplay(1)
    )

    this.gapOpportunityDataSets$ = this.similarGapOperators$.pipe(
      map((operators) => {
        return Array.from(new Set<DataSet>((operators || []).map((place) => place.dataSet)))
      }),
      shareReplay(1)
    )

    this.loadGapOpportunityDataSets()

    this.gapOpportunityDataSetsForFilter$ = this.initGapOpportunityDataSetsForFilter()
  }

  private initCatchmentPossible() {
    return this.appModel.places.sources.observable.pipe(
      map((sources) => {
        return sources.length === 1 && (sources[0].dataSet.name || '').toUpperCase().indexOf('CENTREPOINT') > -1
      }),
      shareReplay(1)
    )
  }

  private initSourceTownCenter() {
    return this.appModel.places.sources.observable.pipe(
      map((sources) => {
        return (
          sources.length === 1 &&
          (sources[0].dataSet.name || '').toUpperCase().indexOf('CENTREPOINT') > -1 &&
          sources[0].category.name.toUpperCase() === 'TOWN CENTRE'
        )
      }),
      shareReplay(1)
    )
  }

  private initGapOpportunityDataSetsForFilter() {
    return this.similarGapOperators$.pipe(
      map((operators) => {
        const categories = new Set(operators.map((operator) => operator.category))
        const dataSets = Array.from(new Set<DataSet>((operators || []).map((place) => place.dataSet)))

        return dataSets.map((dataSet) => {
          return {
            id: dataSet.id,
            name: dataSet.name,
            original: dataSet,
            categories: dataSet.categories
              .filter((category) => categories.has(category))
              .map((category) => {
                return {
                  id: category.id,
                  name: category.name,
                  grouping: category.grouping,
                  color: category.color,
                  selected: true,
                  original: category,
                } as CategoryLike
              }),
          } as DataSetLike
        })
      }),
      shareReplay(1)
    )
  }

  loadGapOpportunityDataSets() {
    return this.gapOpportunityDataSets$
      .pipe(
        take(1),
        switchMap((dataSet) => from(dataSet)),
        concatMap(async (dataSet) => this.dataLoadingModel.loadDataSet(dataSet))
      )
      .toPromise()
  }

  private initSimilarMap(similar: Place[]) {
    this.similarMap = {}
    this.similarAndAdditionalMap = {}

    if (similar) {
      similar.forEach((place) => (this.similarMap[place.id] = place))
      similar.forEach((place) => (this.similarAndAdditionalMap[place.id] = place))
    }

    this.additionalSimilar.getValues().forEach((place) => (this.similarAndAdditionalMap[place.id] = place))

    return similar
  }

  private initLoadAdditionalOperators() {
    return combineLatest(this.additionalSimilar.observable, this.centerPointId$).pipe(
      map(async ([sources, centerPointId]) => {
        if (!sources || sources.length === 0) {
          return []
        } else {
          const places = await this.matchpointEndpoint.getOperators(sources, centerPointId)

          return processLocationOnLoad(places, this.dataLoadingModel)
        }
      }),
      shareReplay(1)
    )
  }

  public isCategorySelectedAll() {
    return !!this.selectedCategoriesAll
  }

  public isCategorySelected(key: string) {
    return this.selectedCategories == null || !!this.selectedCategories[key]
  }

  public isSimilarSelectedForAnalysis(item: MatchpointPlace) {
    return (
      this.similarAndAdditionalMap[item.id] &&
      (this.isSimilarSelected(item) || this.isCategorySelected(item.secondaryCategory)) &&
      !this.isSimilarBlacklisted(item)
    )
  }

  public isSimilarSelected(item: MatchpointPlace) {
    return !!this.selectedSimilar[item.id]
  }

  public isSimilarBlacklisted(item: MatchpointPlace) {
    return !!this.blacklistedSimilar[item.id]
  }

  public async toggleCategorySelected(key: string) {
    this.selectedCategories[key] = !this.selectedCategories[key]

    let selectedAllValue = true
    const values = (await this.similar$.take(1).toPromise()) || []
    values.forEach((cur) => {
      selectedAllValue = selectedAllValue && this.selectedCategories[cur.secondaryCategory]
    })
    this.selectedCategoriesAll = selectedAllValue

    this.similarSelectionUpdates.next(true)
  }

  public async toggleCategorySelectedAll() {
    this.selectedCategoriesAll = !this.selectedCategoriesAll

    if (!this.selectedCategoriesAll) {
      this.selectedCategories = {}
    } else {
      const values = (await this.similar$.take(1).toPromise()) || []
      this.selectedCategories = values.reduce((acc, cur) => {
        acc[cur.secondaryCategory] = true
        return acc
      }, {} as { [key: string]: boolean })
    }
    this.similarSelectionUpdates.next(true)
  }

  public async toggleSimilarSelected(item: MatchpointPlace) {
    const newState = (this.selectedSimilar[item.id] = !this.selectedSimilar[item.id])

    if (newState) {
      this.blacklistedSimilar[item.id] = false
    }

    if (!this.similarMap[item.id]) {
      if (newState) {
        this.additionalSimilar.add(item)
      } else {
        this.additionalSimilar.remove(item)
      }

      this.initSimilarMap((await this.similar$.take(1).toPromise()) || [])
    }

    this.similarSelectionUpdates.next(true)
  }

  public async toggleSimilarBlacklisted(item: MatchpointPlace) {
    const newState = (this.blacklistedSimilar[item.id] = !this.blacklistedSimilar[item.id])

    if (newState) {
      this.selectedSimilar[item.id] = false
    }

    if (!this.similarMap[item.id]) {
      if (newState) {
        this.additionalSimilar.remove(item)
      }

      this.initSimilarMap((await this.similar$.take(1).toPromise()) || [])
    }

    this.similarSelectionUpdates.next(true)
  }

  private initSubjectOperators() {
    return combineLatest(this.report$, this.centerPointId$).pipe(
      map(([report, centerpointId]) => {
        return this.sortPlaces(
          uniqueOperators(report.subjectOperatorLocations, [], report.similar.length, centerpointId),
          compareSubject
        )
      }),
      shareReplay(1)
    )
  }

  private initSimilarOperatorsGapOpportunity() {
    return combineLatest(
      this.subjectOperatorLocations$,
      this.similarActiveAnalysis$,
      this.similarOperatorLocations$,
      this.additionalSimilar.observable,
      this.operatorsAdditional$,
      this.centerPointId$
    ).pipe(
      map(([subjectOperators, similar, similarOperatorLocations, additional, operatorsAdditional, centerpointId]) => {
        let similarMap = similar.reduce((acc, cur) => {
          acc[cur.storeId] = true
          return acc
        }, {} as any)

        similarMap = additional.reduce((acc, cur) => {
          acc[cur.storeId] = true
          return acc
        }, similarMap)

        const filteredSimilarOperators = similarOperatorLocations.filter((place) => {
          // return similarMap[place.other && place.other['CentrePoint ID']]
          return similarMap[place.other && place.other[centerpointId]]
        })

        const allOperators = [].concat(filteredSimilarOperators, operatorsAdditional)
        const totalCount = similar.length + additional.length

        const result = this.sortPlaces(
          uniqueOperators(allOperators, subjectOperators, totalCount, centerpointId),
          compareSimilar
        )
        return result
      }),
      shareReplay(1)
    )
  }

  // private initSimilarOperatorsGapOpportunityWithTravel() {
  //   return combineLatest(this.similarGapOperators$, this.appModel.places.reachableFilteredPlaces.value).pipe(
  //     map(([operators, targets]) => {

  //       const categoryTravelTimes: Record<number, number> = {}
  //       const visibleCategories: Set<number> = new Set()

  //       operators.forEach((location) => {
  //         if (location.category.visible) {
  //           visibleCategories.add(location.category.id)
  //         }
  //       })

  //       targets.forEach((target) => {
  //         if (visibleCategories.has(target.category.id)) {
  //           if (target.travelTime > -1) {
  //             categoryTravelTimes[target.category.id] =
  //               categoryTravelTimes[target.category.id] == null
  //                 ? target.travelTime
  //                 : Math.min(target.travelTime, categoryTravelTimes[target.category.id])
  //           }
  //         }
  //       })

  //       operators.forEach((operator) => {
  //         operator.travelTime = categoryTravelTimes[operator.category.id]
  //       })

  //       return operators
  //     })
  //   )
  // }

  private initSimilarOperatorsGapOpportunityWithTravel() {
    // return combineLatest(this.similarGapOperators$, this.appModel.places.reachableFilteredPlaces.value).pipe(
    const operatorsWithTime$ = combineLatest(
      this.similarGapOperators$,
      this.appModel.places.sources.observable,
      this.appModel.settings.travelOptionsUpdates
    ).pipe(
      debounceTime(1),
      switchMap(async ([operators, sources, travelOptions]) => {
        const categoryTravelTimes: Record<number, number> = {}
        const visibleCategories: Set<number> = new Set()
        const dataSets = new Set(operators.map((operator) => operator.dataSet))
        const categories = new Set(operators.map((operator) => operator.category))

        for (const dataSet of Array.from(dataSets)) {
          await this.appModel.placesLoadingModel.loadDataSet(dataSet)
        }

        await new Promise((resolve) => setTimeout(resolve, 1))
        const targets = await this.appModel.places.places.getValue().filter((place) => categories.has(place.category))

        const canonicalSources = canonicalPositions(sources)
        const boundingBox = geometry.boundingBoxListWithinTravelOptions(canonicalSources, travelOptions)
        const sourcesIds = new Set(sources.map((source) => source.id))

        const duplicates = new Set<number>() /// TODO: not sure why
        const reachable = await this.indicators.add(
          this.appModel.places.rechabilityLocations(
            canonicalSources,
            targets.filter((target) => {
              if (duplicates.has(target.id)) {
                return false
              }

              duplicates.add(target.id)
              return !sourcesIds.has(target.id) && geometry.contains(boundingBox, target)
            }),
            travelOptions
          )
        )

        reachable.forEach((target) => {
          if (target.travelTime > -1) {
            categoryTravelTimes[target.category.id] =
              categoryTravelTimes[target.category.id] == null
                ? target.travelTime
                : Math.min(target.travelTime, categoryTravelTimes[target.category.id])
          }
        })

        operators.forEach((operator) => {
          operator.travelTime = categoryTravelTimes[operator.category.id]
        })

        return operators
      })
    )

    return combineLatest(this.similarGapOperators$, operatorsWithTime$.pipe(startWith(null))).pipe(
      map(([operators, operatorsWithTime]) => {
        return operatorsWithTime || operators
      }),
      shareReplay(1)
    )
  }

  private initActiveAnalysis() {
    return combineLatest(this.similarSelectionUpdates, this.similar$).pipe(
      skipUntil(this.similarCategories$),
      map(([filter, similar]) => {
        return (similar || []).filter((item) => {
          return this.isSimilarSelectedForAnalysis(item)
        })
      }),
      shareReplay(1)
    )
  }

  private initSimilarOperatorCategories() {
    return this.similar$.pipe(
      map((similar) => {
        const values = similar || []
        const result = values.reduce((acc, cur) => {
          acc[cur.secondaryCategory] = true
          this.selectedCategories[cur.secondaryCategory] = true
          return acc
        }, {} as { [key: string]: boolean })

        return Object.keys(result).sort()
      }),
      // take(1),
      shareReplay(1)
    )
  }

  private sortPlaces(
    places: MatchpointPlace[],
    compare: (a: MatchpointPlace, b: MatchpointPlace) => number
  ): MatchpointPlace[] {
    if (!places) {
      return []
    }

    return places.sort(compare)
  }

  setIncludeCatchment(value: boolean) {
    this.includeCatchmentSubject.next(value)
  }

  setIncludeTownCenters(value: boolean) {
    this.includeTownCentersSubject.next(value)
  }

  async materializeConfigFromSettings(settings: any[]) {
    const sources = await this.appModel.places.sources.observable.pipe(take(1)).toPromise()
    const census = await this.appModel.statistics.census.value
      .pipe(
        filter((available) => !!available),
        take(1)
      )
      .toPromise()
    const includeCatchment = await this.includeCatchment$.pipe(take(1)).toPromise()

    return this.materializeConfig(sources, settings, census, includeCatchment)
  }

  materializeConfig(sources: Place[], settings: any[], census: AllStatistics, includeCatchment: boolean) {
    const statistics = new AllStatistics(
      census.inputOriginal,
      census.gbpToEuroRate,
      sources[0].region,
      sources[0].region,
      sources[0].country,
      ReportPerCapitaHousehold.PER_HOUSEHOLD
    )

    const dominant = findDominantVariables(sources, statistics, settings, !includeCatchment).dominant

    return { dominant, statistics }
  }
}
