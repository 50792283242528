<h4>Report General Options</h4>
<mat-list>
  <mat-list-item (click)="toggleGeneral('customLogo')">
    <mat-checkbox style="pointer-events: none" [checked]="model?.customLogo" aria-label=""></mat-checkbox>
    Use custom logo
  </mat-list-item>

  <mat-list-item (click)="toggleGeneral('shortFooter')">
    <mat-checkbox style="pointer-events: none" [checked]="model?.shortFooter" aria-label=""></mat-checkbox>
    Use short footer
  </mat-list-item>

  <mat-list-item (click)="toggleGeneral('shortMapCompetition')">
    <mat-checkbox style="pointer-events: none" [checked]="model?.shortMapCompetition" aria-label=""></mat-checkbox>
    Show competition on map
  </mat-list-item>
</mat-list>

<h4>Report Sections</h4>
<mat-list>
  <mat-list-item *ngFor="let profile of availableSections" (click)="toggleReportSection(profile)">
    <mat-checkbox
      style="pointer-events: none"
      [checked]="profile.inverse ? !profile.selected : profile.selected"
      aria-label=""
    ></mat-checkbox>
    {{ profile.label }}
  </mat-list-item>
</mat-list>

<h4>Competition Columns</h4>
<mat-list>
  <mat-list-item *ngFor="let profile of availableCompetitionColumns" (click)="toggleCompetitionColumn(profile)">
    <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
    {{ profile.label }}
  </mat-list-item>
</mat-list>

<h4>Report Color</h4>

<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
  <div (click)="editColor($event)" [style.background]="model?.borderColor || '#ddd'" class="color"></div>
  <button mat-button (click)="clearColor()" type="button">Clear</button>
</div>
