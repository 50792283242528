import { Entity } from './entity'
import { DataSet } from './dataSet'

export const EXPORTABLE_PROPERTIES = [
  'storeId',
  'lat',
  'lng',
  'primaryCategory',
  'secondaryCategory',
  'holdingCompany',
  'fascia',
  'sitename',
  'paon',
  'saon',
  'taon',
  'street',
  'suburb',
  'town',
  'postcode',
  'district',
  'county',
  'region',
  'netSalesArea',
  'netSalesAreaSource',
  'grossInternalArea',
  'grossInternalAreaSource',
]

// TODO: this is all just thrown here for now, while I am still deciding...will be cleaned up
export interface Position {
  lat: number
  lng: number
}

export interface Place extends Position {
  id: number

  latAlt?: number
  lngAlt?: number

  storeId?: string
  fascia?: string
  sitename?: string
  primaryCategory?: string
  netSalesArea?: number
  grossInternalArea?: number

  street?: string
  town?: string
  suburb?: string
  postcode?: string
  county?: string
  region?: string

  travelTime?: number
  dataSetId?: number

  paon?: string
  saon?: string
  taon?: string

  secondaryCategory?: string
  netSalesAreaSource?: string
  grossInternalAreaSource?: string
  holdingCompany?: string
  district?: string

  other?: any
}

export interface CategoryLike {
  id?: number
  name: string
  grouping?: string
  filtered?: boolean
  selected?: boolean
}

export interface Category extends CategoryLike {
  id: number
  code: string
  name: string
  grouping: string
  color?: string
  originalColor?: string
  customColor?: string
  customIcon?: number
  visible?: boolean
  filtered?: boolean
  dataSet?: DataSet
}
