import { Component, OnInit } from '@angular/core';
import { MessagesEndpoint } from '../../../api/message';
import { Indicators, TgmQuickDialogs } from '@targomo/client';

@Component({
  selector: 'app-admin-message-center',
  templateUrl: './adminMessageCenter.component.html',
  styleUrls: ['./adminMessageCenter.component.less']
})
export class AdminMessageCenterComponent implements OnInit {
  messages: any[] = []

  constructor(
    private messagesEndpoint: MessagesEndpoint,
    private indicators: Indicators,
    private quickDialogs: TgmQuickDialogs,
  ) { }

  async ngOnInit() {
    this.updateList()
  }
  
  private async updateList() {
    this.messages = await this.indicators.add(this.messagesEndpoint.findAll())
  }

  showMenu(event: any) {
    event.stopPropagation()
  }  

  async removeMessage(message: any) {
    if (await this.quickDialogs.confirm({
      title: 'Delete User',
      message: `Would you like to delete message (${message.title})?`,
      confirm: 'Delete',
      cancel: 'Cancel'
    })) {
      const result = await this.messagesEndpoint.delete(message)
      this.updateList()
    }
  }

}
