import {Component, Input, OnInit, OnChanges, SimpleChange} from '@angular/core'
import {MAP_STYLES} from '@targomo/client'
import {StatisticsKey} from '@targomo/core'
import {STATISTICS_WITH_NONE, STATISTICS, STATISTICS_MAP} from '../../model/constants';
import {AppModel} from '../../model/appModel.service';
import { AllStatistics } from '../../../common/models/statistics/statistics';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'indices-switch',
  template: `
    <mat-list-item>
      <mat-select style="min-width: 200px" fxFlex [ngModel]="value" (ngModelChange)="changeStatistic($event)">
        <mat-option [value]="false">Show {{country || 'GB'}} Average</mat-option>
        <mat-option *ngIf="region != 'GB' && region != 'ROI'" [value]="true">Show {{region || 'Region'}} Average</mat-option>
      </mat-select>
    </mat-list-item>
  `,
  styles: [`
    :host {
      display: block;
    }

    .mat-select-underline {
      background: none !important;
    }

    .mat-list-item-content {
      padding: 0px !important;
    }
  `]
})
export class IndicesSwitchComponent {
  @Input()  statistics: AllStatistics
  region: string
  country: string
  value: boolean = true

  private subscriptions: Subscription[] = []

  constructor(readonly appModel: AppModel) {
    this.subscriptions.push(this.appModel.displaySettings.subscribe(settings => {
      this.value = settings.regonalIndices
      if (this.statistics && (this.statistics.calculatedRegion == 'GB' || this.statistics.calculatedRegion == 'ROI')) {
        this.value = false
      }
    }))
  }

  ngOnChanges() {
    if (this.statistics) {
      this.region = this.statistics.calculatedRegion
      this.country = this.statistics.country
    } else {
      this.region = null
      this.country = null
    }

    if (this.region === 'GB' || this.region === 'ROI') {
      this.value = false
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  changeStatistic(value: any) {
    this.appModel.displaySettings.nextProperty('regonalIndices', value)
  }
}