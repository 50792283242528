<section fxLayout="column" layout-align="center center">
  <div>
    <div>
      <h1 translate="account.login" class="mat-title">Login</h1>
    </div>

    <div>
      <form [formGroup]="form" (ngSubmit)="login($event)" fxLayout="column" novalidate>
        <mat-form-field>
          <input matInput type="text" formControlName="username" required placeholder="Username" />

          <mat-hint
            *ngIf="submitted && form.get('username').hasError('required')"
            [ngStyle]="{'color': 'red'}"
            translate="account.usernameRequired"
          >
            Please enter your username
          </mat-hint>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="password" formControlName="password" required placeholder="Password" />

          <mat-hint
            *ngIf="submitted && form.get('password').hasError('required')"
            [ngStyle]="{'color': 'red'}"
            translate="account.passwordRequired"
          >
            Please enter your password
          </mat-hint>
        </mat-form-field>

        <div *ngIf="failedLogin" style="color: red; font-size: 12px" translate="account.usernameOrPasswordNotFound">
          Password or username not valid
        </div>

        <div class="align-right main-button">
          <button mat-flat-button color="primary" type="submit">
            <span translate="account.buttonLogin">Sign in</span>
          </button>
        </div>

        <div class="bottom-action">
          <a routerLinkActive="active" routerLink="/request-reset">Forgot password?</a>
        </div>
      </form>
    </div>
  </div>

  <footer
    class="login-footer"
    style="color: white; text-align: center; font-size: 14px; margin-top: 30px; line-height: 1.5em"
  >
    <div style="font-size: 20px; margin: 8px; font-weight: bold">StorePointGeo</div>
    <div style="font-weight: bold">from Aspinall & Aspinall</div>
    <div>
      <a href="#" (click)="bookmark($event)">Bookmark this Page</a> |
      <a href="mailto: storepointgeo@oxretail.com">Contact Us</a>
    </div>
    <div><a target="_blank" href="http://oxretail.com">http://oxretail.com</a></div>
    <div style="margin-top: 18px">Powered by <a target="_blank" href="https://www.targomo.com/">Targomo</a></div>
  </footer>
</section>
