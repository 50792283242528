
import {Component, Input, OnInit, OnChanges, SimpleChange} from '@angular/core'
import {MatDialogRef, MatDialog} from '@angular/material'
import {DataSetLocation} from '../../model/index';
import {AppModel} from '../../model/appModel.service';
import {Place} from '../../api/place';


@Component({
  selector: 'export-dialog',
  templateUrl: './exportDialog.html',
  styleUrls: ['./exportDialog.less']
})
export class ExportDialog {
  empty: boolean = false

  constructor(public dialogRef: MatDialogRef<ExportDialog>, appModel: AppModel) {
  }

  public show(places: Place[]) {
    this.empty = !(places && places.length)
  }

  private save() {
    this.dialogRef.close({})
  }

  close(type: string) {
    this.dialogRef.close(type)
  }
}