import { LabelledStatisticsValue } from "../../../../common/models/statistics/statistics"
import { CareHomeReport, CareHomeYearReport } from "../../../model/careHome/careHomeModel"
import { PDFHelper } from "../helper"
import { TABLE_OPTIONS_OVER_UNDER_SUPPLY } from "./tableStyle"
import { underlineCell } from "./underlineCell"

export function careOverUnderSupply(helper: PDFHelper, careHomeReport: CareHomeReport) {
  const statistics = careHomeReport.statistics

  const years = [
    {key: '', label: 'Current Year', data: careHomeReport.current},
    {key: '', label: '' + careHomeReport.additional[0].year, data: careHomeReport.additional[0].horizon},
    {key: '', label: '' + careHomeReport.additional[1].year, data: careHomeReport.additional[1].horizon},
  ]

  let rows: any[][] = []
  // const headers: any[] = []

  let row: any[] = []

  function formatNumber(value: any, precision: number = 0, showPlusMinus = false) {
    if (value === null || value === undefined || isNaN(value)) {
      return ''
    }

    let formated = helper.numberFilter.transform(Math.abs(value), `1.${precision}-${precision}`)

    if (showPlusMinus) {
      if (value > 0) {
        formated = `+${formated}`
      } else if (value < 0) {
        formated = `-${formated}`
      }
    }


    if (value < 0) {
      return {text: `(${formated})`, color: '#ff0000'}
    } else {
      return {text: '' + formated}
    }
  }

  function yearsRow(title: string, showYears = true) {
    const row: any[] = []
    row.push(title)

    years.forEach((year, i) => {
      if (showYears) {
        row.push({stack: [underlineCell({text: year.label, alignment: 'center'})], colSpan: (i !== 0 ? 4 : 3)})

      } else {
        row.push('')
      }

      row.push('')
      row.push('')

      if (i !== 0) {
        row.push('')
      }
    })
    rows.push(row.map(cell => {
      if (typeof cell === 'string') {
        cell = {text: cell}
      }

      // return underlineCell({...cell, bold: true, titleRow: true, titleRowUnderline: showYears, margin: [0, 12, 0, 4]})
      return {...cell, bold: true, titleRow: true, titleRowUnderline: showYears, margin: [0, 12, 0, 4]}
       // underlineCell({...cell, bold: true})
    }))

  }

  yearsRow('')

  row = []
  row.push('Population')

  years.forEach((year, i) => {
    row.push('%')
    row.push(`Ind vs ${statistics.regionLabel()} Avg`)
    row.push('Total')
    if (i !== 0) {
      row.push('Change vs Base (%)')
    }
  })
  rows.push(row.map(text => {
    return {
      text, bold: true
    }
  }))

  const populationLabels: string[] = ['65-74', '75-84', '85+', '65+']
  const populationKeys: (keyof CareHomeYearReport)[] = [
    'aged65to74',
    'aged75to84',
    'aged85AndOver',
    'aged65AndOver',
  ]

  populationKeys.forEach((key, i) => {
    row = []
    row.push({text: populationLabels[i], alignment: 'right'})

    years.forEach((year, i) => {
      const current: LabelledStatisticsValue = year.data[key] as any
      row.push(formatNumber(current.percent, 1))
      row.push(formatNumber(current.percentOverIndex))
      row.push(formatNumber(current.total))
      if (i !== 0) {
        row.push(formatNumber(current.changeOverBase * 100, 1, true))
      }
    })
    rows.push(row)
  })

  // row = []
  // row.push({text: ''})

  // years.forEach((year, i) => {
  //   const total = populationKeys.reduce((acc, key) => {
  //     return acc + (year.data[key] as any).total
  //   }, 0)
  //   row.push('')
  //   row.push('')
  //   row.push({text: formatNumber(total), bold: true})
  //   if (i !== 0) {
  //     row.push('')
  //   }
  // })
  // rows.push(row)

  yearsRow('Care Supply')

  const bedKeys: (keyof CareHomeYearReport)[] = ['provisionHomes', 'provisionBeds', 'provisionEnSuite']
  const bedKeysLabels: string[] = ['Care Homes', 'Care Beds', 'of which ensuite']
  bedKeys.forEach((key, i) => {
    row = []
    row.push({text: bedKeysLabels[i], alignment: 'right'})

    years.forEach((year, i) => {
      const current: number = year.data[key] as any
      row.push({text: formatNumber(current), colSpan: (i !== 0 ? 4 : 3)})
      row.push('')
      row.push('')

      if (i !== 0) {
        row.push('')
      }
    })
    rows.push(row)
  })

  yearsRow('Over/Under Supply', false)

  const bedKeysSupplyRequirement: (keyof CareHomeYearReport)[] = ['bedRequirement']
  const bedKeysSupplyRequirementLabels: string[] = ['Requirement']
  bedKeysSupplyRequirement.forEach((key, i) => {
    row = []
    row.push({text: bedKeysSupplyRequirementLabels[i], alignment: 'right'})

    years.forEach((year, i) => {
      const current: LabelledStatisticsValue = year.data[key] as any
      row.push({text: formatNumber(current.total), colSpan: (i !== 0 ? 4 : 3)})
      row.push('')
      row.push('')

      if (i !== 0) {
        row.push('')
      }
    })
    rows.push(row)
  })

  rows.push([{text: '', separator: true}, '', '', '', '', '', '', '', '', '', '', ''])
  rows.push([{text: '', separator: true}, '', '', '', '', '', '', '', '', '', '', ''])

  const bedKeysSupply: (keyof CareHomeYearReport)[] = ['provisionBeds', 'overUnderSupply']
  const bedKeysSupplyLabels: any[] = [
    {text: 'Supply (all beds)'},
    {bold: true, text: ['over / ', {text: '(under)', color: '#ff0000'}, ' supply']},
  ]
  bedKeysSupply.forEach((key, i) => {
    row = []
    row.push({...bedKeysSupplyLabels[i], alignment: 'right'})

    years.forEach((year, i) => {
      const current: number = year.data[key] as any
      row.push({text: formatNumber(current), colSpan: (i !== 0 ? 4 : 3)})
      row.push('')
      row.push('')

      if (i !== 0) {
        row.push('')
      }
    })
    rows.push(row)
  })

  rows.push([{text: '', separator: true}, '', '', '', '', '', '', '', '', '', '', ''])
  rows.push([{text: '', separator: true}, '', '', '', '', '', '', '', '', '', '', ''])

  const enSuiteKeysSupply: (keyof CareHomeYearReport)[] = ['provisionEnSuite', 'overUnderSupplyEnSuite']
  const enSuiteKeysSupplyLabels: any[] = [
    {text: 'Supply (ensuite only)'},
    {bold: true, text: ['over / ', {text: '(under)', color: '#ff0000'}, ' supply']},
  ]

  enSuiteKeysSupply.forEach((key, i) => {
    row = []
    row.push({...enSuiteKeysSupplyLabels[i], alignment: 'right'})

    years.forEach((year, i) => {
      const current: number = year.data[key] as any
      row.push({text: formatNumber(current), colSpan: (i !== 0 ? 4 : 3)})
      row.push('')
      row.push('')

      if (i !== 0) {
        row.push('')
      }
    })
    rows.push(row)
  })

  rows = rows.map((row, i) => row.map((cell, j) => {
    if (typeof cell === 'string') {
      cell = {text: cell, style: 'defaultCellBiggerFont'}
    } else if (!cell.style) {
      cell = {...cell, style: 'defaultCellBiggerFont'}
    }

    if (!cell.alignment) {
      if (j === 0) {
        cell.alignment = 'left'
      } else {
        cell.alignment = 'center'
      }
    }

    if (j === 0) {
      cell.labelColumn = true
      cell.border = [false, false, false, false]
    }

    return cell
  }))

  return helper.table(null, rows, 'defaultTable', TABLE_OPTIONS_OVER_UNDER_SUPPLY)
}
