
import {Component, Input, EventEmitter, Output, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource, PageEvent} from '@angular/material';
import { SubscriberComponent } from '@targomo/client';
import { UserEventLogEndpoint } from '../../../api/userEventLog';
import { UserEventLogType, UserEventLog } from '../../../../common/models/userEventLog';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/merge'
import 'rxjs/add/operator/mergeMap'

const AVAILABLE_EVENTS = [
  {label: "User changed password", value: 0},
  {label: "Login", value: 1},
  {label: "Data layer activated", value: 2},
  {label: "Travel time run", value: 3},
  {label: "Map created", value: 4},
  {label: "Maxi reports viewed", value: 5},
  {label: "Pdf report downloaded", value: 6},
  {label: "Session", value: 8},
  {label: "Map image created", value: 9},
  {label: "Message dismissed", value: 10},
  {label: "Message info requested", value: 11},
]

@Component({
  selector: 'admin-activity-log',
  templateUrl: './activityLog.component.html',
  styleUrls: ['./activityLog.component.less']
})
export class AdminActivityLogComponent extends SubscriberComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
    
  availableTypes = AVAILABLE_EVENTS
  loading: Promise<void>
  dataSource: MatTableDataSource<UserEventLog>
  dataLength: number = 0
  displayedColumns = ['name', 'username', 'created', 'data']
  activityType = 0

  constructor(private userEventLog: UserEventLogEndpoint) {
    super()
  }

  ngOnInit() {
    this.watch(this.paginator.page, (event: PageEvent) => {
      return this.loading = this.loadPage(event.pageIndex, event.pageSize)
    })

    this.loading = this.loadPage(this.paginator.pageIndex, this.paginator.pageSize || 5)
  }

  async loadPage(page: number, size: number) {
    const result = await this.userEventLog.events(this.activityType, {page, size})
    this.dataSource = new MatTableDataSource(result.rows)
    this.dataLength = result.count
  }

  selectActivityType(event: any) {
    this.activityType = event
    this.loading = this.loadPage(this.paginator.pageIndex, this.paginator.pageSize || 5)
  }

  formatData(value: any) {
    if (value) {
      if (this.activityType === UserEventLogType.MESSAGE_INFO_REQUESTED 
        || this.activityType == UserEventLogType.MESSAGE_DISMISSED) {
          return [value.messageId, value.title].filter(item => !!item).join(': ')
      }
    }
    return ''
  }
}