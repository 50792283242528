import { SWITCHABLE_INDICES_COLUMNS } from './swithableIndicesColumns'
import { ReportPerCapitaHousehold } from './statistics'

const INDICES = require('./indices.json')
const INDICES_HOUSEHOLDS = require('./indicesHouseholds.json')
const INDICES_UPDATES = require('./indicesUpdates.json')
const INDICES_IRELAND = require('./indicesIreland.json')

const INDICES2019_GB_NATIONAL = require('./indices2019GBNational.json')
const INDICES2019_IRELAND_DEMOGRAPHICS = require('./indices2019IrelandDemographics.json')
const INDICES2019_IRELAND_SPEND = require('./indices2019IrelandSpend.json')
const INDICES2019_IRELAND_WORKFORCE = require('./indices2019IrelandWorkforce.json')

const INDICES_OCT2019_IRELAND_DEMOGRAPHICS = require('./indicesOct2019IrelandDemographics.json')

const INDICES_20200217_DEMOGRAPHICS = require('./indices20200217Demographics.json')
const INDICES_20200217_SPEND = require('./indices20200217Spend.json')

const INDICES_FORECAST_2021 = require('./indicesForecast2021.json')

const INDICES_EXTENDED_2021 = require('./indicesExtended2021.json')
const INDICES_CLASSIC_2021 = require('./indices2021.json')
const INDICES_SPEND_2021 = require('./indices2021Spend.json')

// const INDICES_WORKFORCE_2021 = require('./indices_workforce_20211110.json')

const INDICES_BANDS_2023 = require('./indices_2023_bands.json')

const INDICES_CORRECTED_2024 = require('./indices2024_demographics.json')
const INDICES_WORKFORCE_2024 = require('./workforce2024.json')

function debugCommon(area: string, values1: any, values2: any) {
  const result: any = {}

  for (const key in values1[area]) {
    if (values2[area][key]) {
      result[key] = true
    }
  }

  for (const key in values2[area]) {
    if (values1[area][key]) {
      result[key] = true
    }
  }

  console.log('**** Common Keys ****', Object.keys(result).join('\n'))
}

// debugCommon('ROI', INDICES2019_IRELAND_SPEND, INDICES2019_IRELAND_DEMOGRAPHICS)

function multiply(indices: any, factor: number, exclude?: (key: string) => boolean) {
  const result: any = {}

  Object.keys(indices).forEach((region) => {
    const regionData = indices[region]
    const copyRegion: any = {}

    Object.keys(regionData).forEach((key) => {
      copyRegion[key] = regionData[key]
      if (isFinite(copyRegion[key])) {
        const lowerKey = key.toLowerCase().trim()
        if (lowerKey !== 'households' && lowerKey !== 'population') {
          if (!(exclude && exclude(key))) {
            copyRegion[key] = copyRegion[key] * factor
          }
        }
      }
    })

    result[region] = copyRegion
  })

  return result
}

function liftMaleFemale(indices: { [region: string]: { [key: string]: number } }) {
  const MALE_STATISTICS = ['M_16_24', 'M_25_34', 'M_35_44', 'M_45_54', 'M_55_64', 'M_65_74']

  const FEMALE_STATISTICS = ['F_16_24', 'F_25_34', 'F_35_44', 'F_45_54', 'F_55_64', 'F_65_74']

  function sumIndices(keys: string[], regionIndices: { [key: string]: number }) {
    return keys.reduce((acc, cur) => {
      return acc + (regionIndices[cur] || 0)
    }, 0)
  }

  for (let region in indices) {
    const regionIndices = indices[region]
    if (regionIndices['M_16_24'] != null || regionIndices['F_16_24'] != null) {
      const maleTotal = sumIndices(MALE_STATISTICS, regionIndices)
      const femaleTotal = sumIndices(FEMALE_STATISTICS, regionIndices)
      const humanTotal = maleTotal + femaleTotal
      if (!(Math.floor(humanTotal) === 100 || Math.ceil(humanTotal) === 100)) {
        console.log('**Warning**: Indices Lifting: Male and Female workforce totals =', humanTotal)
      }

      MALE_STATISTICS.forEach((key) => {
        const newValue = (regionIndices[key] / maleTotal) * humanTotal
        regionIndices[key] = newValue
      })

      FEMALE_STATISTICS.forEach((key) => {
        const newValue = (regionIndices[key] / femaleTotal) * humanTotal
        regionIndices[key] = newValue
      })

      const maleTotalLifted = sumIndices(MALE_STATISTICS, regionIndices)
      const femaleTotalLifted = sumIndices(FEMALE_STATISTICS, regionIndices)
      if (!(Math.floor(maleTotalLifted) === 100 || Math.ceil(maleTotalLifted) === 100)) {
        console.log('**Warning**: Indices Lifting: Lifted Male workforce totals =', maleTotalLifted)
      }

      if (!(Math.floor(femaleTotalLifted) === 100 || Math.ceil(femaleTotalLifted) === 100)) {
        console.log('**Warning**: Indices Lifting: Lifted Female workforce totals =', femaleTotalLifted)
      }
    }
  }

  return indices
}

function excludeHouseHolds(key: string) {
  key = key.toLowerCase()

  return key.indexOf('_households') !== -1
}

const LISTS = [
  multiply(INDICES_WORKFORCE_2024, 100),
  multiply(INDICES_CORRECTED_2024, 100),
  INDICES_BANDS_2023,
  // INDICES_WORKFORCE_2021,

  multiply(INDICES_CLASSIC_2021, 100, excludeHouseHolds),
  INDICES_SPEND_2021,
  INDICES_EXTENDED_2021,
  multiply(INDICES_FORECAST_2021, 100),
  // NOTE: fix fow now so that users won't panik...but better fix would be to look into details what is what
  // and ensure everything is consistent instead
  //
  multiply(INDICES_20200217_DEMOGRAPHICS, 100, excludeHouseHolds),
  INDICES_20200217_SPEND,

  INDICES2019_GB_NATIONAL,
  multiply(INDICES_OCT2019_IRELAND_DEMOGRAPHICS, 100, excludeHouseHolds),
  multiply(INDICES2019_IRELAND_DEMOGRAPHICS, 100, excludeHouseHolds),
  INDICES2019_IRELAND_SPEND,
  multiply(INDICES2019_IRELAND_WORKFORCE, 100),

  INDICES_UPDATES,
  INDICES_HOUSEHOLDS,
  INDICES,
  INDICES_IRELAND,
]
  .map((indices) => {
    const result: any = {}

    Object.keys(indices).forEach((region) => {
      const regionData = indices[region]
      const copyRegion: any = {}

      Object.keys(regionData).forEach((key) => {
        copyRegion[key.toUpperCase()] = +regionData[key]
      })

      result[region.toUpperCase()] = copyRegion
    })

    return result
  })
  .map(liftMaleFemale)

function processPopulationIndex(indicesList: any[]) {
  function findAllRegions() {
    const result: any = {}
    indicesList.forEach((indices) => {
      Object.keys(indices).forEach((region) => {
        result[region] = true
      })
    })

    return Object.keys(result)
  }

  const regions = findAllRegions()

  function findPopulationsHouseholds() {
    const result: any = {}
    ;['POPULATION', 'HOUSEHOLDS'].forEach((keyUpper) => {
      regions.forEach((region) => {
        result[region] = result[region] || {}
        for (let indices of indicesList) {
          if (indices[region] && keyUpper in indices[region]) {
            result[region][keyUpper] = +indices[region][keyUpper]
            return
          }
        }
      })
    })

    return result
  }

  const totals = findPopulationsHouseholds()

  return indicesList.map((indices) => {
    const result: typeof indices = {}

    Object.keys(indices).forEach((region) => {
      const regionIndices = indices[region]
      const regionResult = { ...regionIndices }
      result[region] = regionResult
      Object.keys(regionIndices).forEach((key) => {
        if (SWITCHABLE_INDICES_COLUMNS[key]) {
          // if (key !== 'POPULATION' && key !== 'REGION' && key !== 'HOUSEHOLDS') {
          regionResult[key] = (regionIndices[key] * totals[region]['HOUSEHOLDS']) / totals[region]['POPULATION']
        }
      })
    })

    return result
  })
}

const LISTS_HOUSEHOLDS = LISTS
const LISTS_POPULATION = processPopulationIndex(LISTS)

function updateSingleIndex(data: any, key: string, region: string, reportPerCapitaHousehold: ReportPerCapitaHousehold) {
  const keyUpper = key.toUpperCase()

  for (let indices of reportPerCapitaHousehold === ReportPerCapitaHousehold.PER_CAPITA
    ? LISTS_POPULATION
    : LISTS_HOUSEHOLDS) {
    if (indices[region] && keyUpper in indices[region]) {
      data[key]['index'] = +indices[region][keyUpper]
      return
    }
  }
}

export function decorate(
  data: any,
  region: string = 'GB',
  reportPerCapitaHousehold = ReportPerCapitaHousehold.PER_HOUSEHOLD
) {
  const regionLower = region.toLowerCase()

  region = region || 'GB'

  if (region === 'YORKS & HUMBER') region = 'YORKS & the HUMBER'

  for (let key in data) {
    updateSingleIndex(data, key, region.toUpperCase(), reportPerCapitaHousehold)
  }
}

const INDICES_FORECAST = require('./indicesForecast.json')
export function getForecastGrowth(region: string, from: number, to: number) {
  region = region.toUpperCase()
  return INDICES_FORECAST[region][to] / INDICES_FORECAST[region][from] - 1
}
