<section style="min-width: 400px">
  <h3 mat-dialog-title>Export Selected Locations</h3>

    <div fxLayout="row" *ngIf="empty">
      You must first select some locations to export
    </div>

    <div *ngIf="!empty">
      Please choose an export format:

      <mat-list>
        <mat-list-item (click)="close('geojson')">
          <mat-icon mat-font-set="material-icons">file_download</mat-icon>
          <p>Export as GeoJSON</p>
        </mat-list-item>

        <mat-list-item (click)="close('csv')">
          <mat-icon mat-font-set="material-icons">file_download</mat-icon>
          <p>Export as CSV</p>
        </mat-list-item>
      </mat-list>
    </div>

    <div style="margin: 12px 0px; color: #aaa; font-size: 14px">
      Note: Maximum 500 places can be exported at a time
    </div>

    <div class="dialog-bottom">
      <button mat-button (click)="dialogRef.close()" mat-dialog-close><span translate="dialog.cancel">Close</span></button>
      <!--<button mat-flat-button color="primary" mat-button><span translate="dialog.save">Save</span></button>-->
    </div>
</section>
