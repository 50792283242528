import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { APP_BASE_PATH } from '../../../util/basePath';
import { UserEndpoint } from '../../../api';

@Component({
  selector: 'project-number-dialog',
  templateUrl: './projectNumberDialog.component.html',
  styleUrls: ['./projectNumberDialog.component.less']
})
export class ProjectNumberDialogComponent implements OnInit {
  form: FormGroup
  logo: string

  constructor(
    public dialogRef: MatDialogRef<ProjectNumberDialogComponent>,
    private formBuilder: FormBuilder,
    private userEndpoint: UserEndpoint,
    @Optional() @Inject(MAT_DIALOG_DATA) data: string
  ) {
    this.form = this.formBuilder.group({
      'projectNumber':  [data || '', []],
    })
  }

  async ngOnInit() {
    const user = await this.userEndpoint.me()
    this.logo = APP_BASE_PATH + `api/users/${user.id}/companypicture/public`
  }

  save() {
    this.dialogRef.close(this.form.value.projectNumber)
  }
}
