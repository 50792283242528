import { Component, OnInit } from '@angular/core'
import { Auth, Indicators, SubscriberComponent } from '@targomo/client'
import { MatCheckboxChange } from '@angular/material'
import { AppModel } from '../../../model/appModel.service'
import { Observable } from 'rxjs'

export const OPTIONS = [
  { name: 'All', id: 'ALL' },
  { name: 'Town Centre', id: 'TC' },
  { name: 'Suburban Centre', id: 'Suburban' },
  { name: 'Shopping Centre', id: 'SC' },
  { name: 'Out of town', id: 'OOT' },
  { name: 'Outlet Centre', id: 'OC' },
]

// 'town centre': 'TC',
// 'town centre shopping centre': 'TC-SC',
// 'shopping centre': 'SC',
// 'out of town': 'OOT',
// 'suburban area': 'Suburban',
// 'suburban centre': 'Suburban',
// 'retail / shopping park': 'OOT',

@Component({
  selector: 'secondary-filters-panel',
  templateUrl: './secondaryFiltersPanel.component.html',
  styleUrls: ['./secondaryFiltersPanel.component.less'],
})
export class SecondaryFiltersPanelComponent extends SubscriberComponent implements OnInit {
  options = OPTIONS
  permissions: any
  values: any = {}
  readonly showStoresWithinTravelTime$: Observable<boolean>

  constructor(
    private auth: Auth,
    private indicators: Indicators,
    readonly appModel: AppModel // readonly dataLoadingModel: DataLoadingModel
  ) {
    super()

    this.showStoresWithinTravelTime$ = this.appModel.settings.showStoresWithinTravelTimeUpdates.pipe()

    this.watch(this.appModel.settings.secondaryLocationTypeFiltersUpdates, (values) => {
      this.values = values
    })
  }

  async ngOnInit() {
    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
    }
  }

  toggleShowStoresWithinTravelTime(event: MatCheckboxChange) {
    this.appModel.settings.displaySettings.nextPropertyWithCurrent('showStoresWithinTravelTime', (current: any) => {
      return event.checked
    })
  }

  toggle(id: string) {
    this.values[id] = !this.values[id]
    if (id === 'ALL' && this.values[id]) {
      this.appModel.settings.displaySettings.nextProperty('secondaryLocationTypeFilters', { ALL: true })
    } else {
      this.appModel.settings.displaySettings.nextProperty('secondaryLocationTypeFilters', { ...this.values })
    }
  }
}
