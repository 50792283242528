<mat-tab-group [(selectedIndex)]="currentTab">
  <mat-tab label="Data Layers">
    <category-search-panel></category-search-panel>
    <layers-panel></layers-panel>

    <div style="text-align: center; margin-top: 24px" [hidden]="visibleAlsoAvailable">
      <button mat-button (click)="alsoAvailable(true)">
        <i class="material-icons">layers</i> <span>Also Available Layers</span>
      </button>
    </div>

    <div style="margin: 0px 8px" *ngIf="visibleAlsoAvailable" fxLayout="row" fxLayoutAlign="end center">
      <button mat-button (click)="alsoAvailable(false)"><i class="material-icons">keyboard_arrow_down</i>Hide also available layers</button>
    </div>

    <layers-panel type="alsoAvailable" *ngIf="visibleAlsoAvailable"></layers-panel>

    <div style="text-align: center; margin-top: 24px" *ngIf="permissions && permissions.uploadGeoJSON">
      <button mat-button (click)="uploadCustomLayer()">
        <i class="material-icons">file_upload</i> <span>Upload custom layer</span>
      </button>
    </div>
  </mat-tab>

  <mat-tab label="Mini Reports">
    <mini-reports-panel></mini-reports-panel>

    <div style="text-align: center">
      <button mat-button (click)="showMaxiReport()">
        <span>See full report &rarr;</span>
      </button>
    </div>

    <div *ngIf="hasSources" style="text-align: center">
      <button mat-button (click)="clearSources()">
        <mat-icon mat-font-set="material-icons" class="material-icons">delete</mat-icon>
        <span>Clear selected locations</span>
      </button>
    </div>
  </mat-tab>

  <mat-tab label="Legend">
    <legend-panel></legend-panel>
  </mat-tab>
</mat-tab-group>
