import {Component} from '@angular/core'
import {FormBuilder, AbstractControl, Validators, FormGroup} from "@angular/forms"
import {UserEndpoint} from '../../api/user';

@Component({
  selector: 'request-password-reset-component',
  templateUrl: './requestPasswordReset.html',
  styleUrls: ['./account.less']  
})
export class RequestPasswordResetComponent {
  form: FormGroup
  requestSent = false
  saving: Promise<{}>

  constructor(private formBuilder: FormBuilder, private userEndpoint: UserEndpoint) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      'email': [null, [Validators.required]],
    })
  }

  async submit() {
    (<any>this.form).submitted = true

    if (this.form.valid) {
      this.saving = this.userEndpoint.requestPasswordReset(this.form.value.email).catch(error => {
        if (error.status == 404) {
          throw new Error("Email not found")
        } else {
          // this.error = "Request failed"
          throw error
        }
      })
      await this.saving
      this.requestSent = true
    }
  }
}