<tgm-loadable [model]="loading" style="padding: 24px 0px">
  <!--<-container>-->
    <table >
      <thead mat-head mat-order="order">
        <tr class="head-description">
          <th colspan="5"></th>
          <th colspan="3">Residents</th>
          <th colspan="2">Workforce</th>
          <th colspan="2">Spend</th>
          <th colspan="2">F&B</th>
        </tr>

        <tr >
          <th (click)="sortByColumn(statistic.key)" *ngFor="let statistic of columns">
            {{statistic.label}}
            <mat-icon class="direction-icon" mat-font-set="material-icons" *ngIf="currentSorting == statistic.key && inverseSorting">arrow_upward</mat-icon>
            <mat-icon class="direction-icon" mat-font-set="material-icons" *ngIf="currentSorting == statistic.key && !inverseSorting">arrow_downward</mat-icon>
          </th>

          <!--<th mat-column mat-order-by="fascia">Name</th>
          <th mat-column mat-order-by="street">Street</th>
          <th mat-column mat-order-by="town">Town</th>
          <th mat-column mat-order-by="postcode">Postcode</th>
          <th mat-column mat-order-by="value1">All</th>
          <th mat-column mat-order-by="value2">Target Customers</th>
          <th mat-column mat-order-by="value3">Students</th>
          <th mat-column mat-order-by="value4">All</th>
          <th mat-column mat-order-by="value5">Target</th>
          <th mat-column mat-order-by="value6">All</th>
          <th mat-column mat-order-by="value7">Target</th>
          <th mat-column mat-order-by="value8">Complementary</th>
          <th mat-column mat-order-by="value9">Competitor</th>-->
        </tr>
      </thead>

      <tbody mat-body>
        <tr  *ngFor="let item of locations">
          <td >
            <span class="checkbox" [style.background]="item.category.color || '#ddd'"></span>
            {{item.defaultName}}</td>
          <td >{{item.street}}</td>
          <td >{{item.town}}</td>
          <td >{{item.postcode}}</td>
          <td >{{item.value0 | number:'1.0-0'}}</td>
          <td >{{item.value1 | number:'1.0-0'}}</td>
          <td >{{item.value2 | number:'1.0-0'}}</td>
          <td >{{item.value3 | number:'1.0-0'}}</td>
          <td >{{item.value4 | number:'1.0-0'}}</td>
          <td >{{item.value5 | number:'1.0-0'}}</td>
          <td >{{item.value6 | number:'1.0-0'}}</td>
          <td >{{item.value7 | number:'1.0-0'}}</td>
          <td >{{item.value8 | number:'1.0-0'}}</td>
          <td >{{item.value9 | number:'1.0-0'}}</td>
        </tr>
      </tbody>
    </table>
  <!--</-container>-->

</tgm-loadable>