declare let d3: any

import { Component, Input, OnChanges, AfterViewInit, ViewChild, Inject, ChangeDetectionStrategy } from '@angular/core'
import { GraphValue } from './bargraph.component.type'
import { NvD3Component } from 'ng2-nvd3'
import { DOCUMENT } from '@angular/common'
import { MaxEdgeWeightOption, TRAVEL_COLORS } from '@targomo/client'

export class CustomBarGraphOptions {
  formatValue?: (value: number) => number | string
  formatTick?: (value: number) => number | string
  valueTooltop?: (value: number) => string
  height?: number = 300
  width?: number
  unitLabel?: string
  showValues?: boolean
  minuteSpan?: number = 5
  colorSet?: MaxEdgeWeightOption[]
  tooltip?: any
  /**
   * if multi-series data, controls can be enabled to toggle between stacked and grouped
   */
  showControls?: boolean = false
  /**
   * if multi-series data, selectable legend will show.
   * https://nvd3-community.github.io/nvd3/examples/documentation.html#legend
   */
  legendOptions?: any

  /**
   * Makes the X labels stagger at different distances from the axis so they're less likely to overlap.
   */
  staggerLabels?: boolean = false

  /**
   * if multi-series data, can choose to reduce ticks/labels on x-axis to avoid overlap/conflict
   */
  reduceXTicks?: boolean = false

  axisLabelDistance?: number = 100
  xAxisLabel?: string = ''
  yAxisLabel?: string = ''
  xAxisLabelRotate?: number = null
  yAxisTickValues?: number[]
  valuesFormat?: (input: any) => any

  margin?: {
    left?: number
    right?: number
    top?: number
    bottom?: number
  }

  /**
   * function to assign bar color based on colors defined on colorSet (if defined) or default travel colors
   */
  color?: (item: any, index: number) => string = (item, index) => {
    let returnColor: string

    if (this.colorSet) {
      let sortedColorSet: MaxEdgeWeightOption[] = this.colorSet.slice().sort((a, b) => 0 - (a.value > b.value ? 1 : -1))

      sortedColorSet.forEach((set) => {
        if (item.xValue <= set.value) {
          returnColor = set.color
        }
      })
    } else {
      const minuteSpan = Math.max(0, Math.floor((+item.label - 1) / this.minuteSpan))
      returnColor = TRAVEL_COLORS[minuteSpan]
    }
    return returnColor
  }

  constructor(values: CustomBarGraphOptions) {
    for (let key in values) {
      ;(<any>this)[key] = (<any>values)[key]
    }
  }
}

/**
 * Bargraph component extended from `ng2-nvd3`.
 *
 * Important:
 *  - Add in global styles: `@import "~nvd3/build/nv.d3.css";`
 *  - In Component: `import 'd3';` and `import 'nvd3';`
 */
@Component({
  selector: 'tgm-custom-bargraph',
  styleUrls: ['./bargraph.component.less'],
  templateUrl: './bargraph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TgmCustomBargraphComponent implements AfterViewInit, OnChanges {
  /**
   * The actual chart component
   */
  @ViewChild(NvD3Component) chartComponent: NvD3Component

  /**
   * The values to be rendered in the graph
   */
  @Input() model: GraphValue[]

  /**
   * Graph options
   */
  @Input() options: CustomBarGraphOptions

  /** @hidden */
  graphOptions: any

  constructor(
    /**
     * @hidden
     */
    @Inject(DOCUMENT) private document: any
  ) {}

  /** @hidden */
  ngAfterViewInit() {
    this.options = this.options || {}

    const formatNumber = (d: any) => (this.options.formatValue ? this.options.formatValue(d) : d3.format(',d')(d))

    // Decide if x-axis labels should be rotated to save space
    let maxLabelLength = 0
    if (this.model) {
      let maxBars = 0
      this.model.forEach((item) => {
        maxBars = Math.max(maxBars, item.values.length)
        item.values.forEach((value) => {
          maxLabelLength = Math.max(maxLabelLength, (value.label || '').length)
        })
      })

      maxLabelLength = maxLabelLength * maxBars
    }

    this.graphOptions = {
      chart: {
        tooltip: this.options.tooltip
          ? {
              contentGenerator: this.options.tooltip,
            }
          : null,
        type: this.model && this.model.length > 1 ? 'multiBarChart' : 'discreteBarChart',
        height: this.options.height || 350,
        width: this.options.width || undefined,
        margin: {
          top: this.options.margin && this.options.margin.top != null ? this.options.margin.top : 20,
          right: this.options.margin && this.options.margin.right != null ? this.options.margin.right : 20,
          bottom: this.options.margin && this.options.margin.bottom != null ? this.options.margin.bottom : 50,
          left: this.options.margin && this.options.margin.left != null ? this.options.margin.left : 30,
        },
        x: function (d: any) {
          return d.label
        },
        y: function (d: any) {
          return d.value
        },
        showValues: this.options.showValues || false,
        showControls: this.options.showControls,
        staggerLabels: this.options.staggerLabels,
        reduceXTicks: this.options.reduceXTicks,
        valueFormat: this.options.valuesFormat || formatNumber,
        duration: 500,
        color: this.model && this.model.length > 1 ? null : this.options.color,
        barColor: this.model && this.model.length > 1 ? this.options.color : null,
        legend: this.options.legendOptions,
        xAxis: {
          axisLabel: this.options.xAxisLabel || '',
          rotateLabels:
            this.options.xAxisLabelRotate != null ? this.options.xAxisLabelRotate : maxLabelLength > 30 ? -7 : 0,
        },
        yAxis: {
          tickSize: 0,
          axisLabel: this.options.yAxisLabel || '',
          axisLabelDistance: this.options.axisLabelDistance === undefined ? 100 : this.options.axisLabelDistance,
          tickFormat: this.options.valuesFormat || formatNumber,
          tickValues: this.options.yAxisTickValues,
        },
      },
    }
  }

  ngOnChanges() {
    this.ngAfterViewInit()
  }

  getImageData(): Promise<any> {
    return new Promise((resolve) => {
      const svg = this.chartComponent ? this.chartComponent.svg[0][0].cloneNode(true) : null

      if (svg) {
        const chartOptions = this.chartComponent.options.chart

        let style = this.document.createElement('style')
        style.setAttribute('type', 'text/css')
        style.innerHTML = `
        .nvd3-svg {background: #fafafa;}
        .nvd3 .nv-axis .nv-axisMaxMin text {font-weight: bold;}
        .nvd3 text {font: normal 11px "Open Sans", Arial, sans-serif;}
        .nvd3 .nv-axis line {fill: none; stroke: #e5e5e5; shape-rendering: crispEdges;}
        .nvd3 .nv-axis path {fill: none; stroke: #000; stroke-opacity: .75; shape-rendering: crispEdges;}
        `
        let defs = this.document.createElement('defs')
        defs.appendChild(style)
        svg.insertBefore(defs, svg.firstChild)
        const svgString = new XMLSerializer().serializeToString(svg)
        const DOMURL = self.URL || (self as any).webkitURL || self
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
        const url = DOMURL.createObjectURL(svgBlob)

        let theCanvas = document.createElement('canvas')
        const ctx = theCanvas.getContext('2d')
        ctx.canvas.width = chartOptions.width
        ctx.canvas.height = chartOptions.height

        let img = new Image()
        img.onload = function () {
          ctx.drawImage(img, 0, 0)
          const png = theCanvas.toDataURL('image/png')
          DOMURL.revokeObjectURL(png)
          theCanvas = null
          resolve(png)
        }
        img.src = url
      } else {
        resolve(null)
      }
    })
  }
}
