import { Observable, of, BehaviorSubject } from 'rxjs'
import { map, shareReplay, tap } from 'rxjs/operators'
import { Category } from '../api/category'
import { PlacesModel } from './placesModel'
import { object as objects } from '@targomo/common'
import { combineLatest } from 'rxjs'
import exportPlaces from '../util/exportPlaces'
import { AbstractLocation } from './entities'
import { BoundingBox, geometry } from '@targomo/core'
import { GapReportLocations } from './settingsModel'
import { array as arrays } from '@targomo/common'

function calculatePresent(all: AbstractLocation[], bounds: BoundingBox) {
  if (bounds) {
    return all.filter((place) => geometry.contains(bounds, place))
  } else {
    return []
  }
}

function calculateNotPresent(all: AbstractLocation[], reachable: AbstractLocation[]) {
  if (!all || !reachable) {
    return all
  }

  const withinMap = objects.mapFromArray(reachable, 'id')
  return all.filter((item) => !withinMap[item.id])
}

function calculateAllOperators(locations: AbstractLocation[]) {
  if (!locations) {
    return {}
  } else {
    return locations.reduce(
      (acc, place) => {
        if (acc[place.category.id]) {
          acc[place.category.id].count++
        } else {
          acc[place.category.id] = { category: place.category, count: 1 }
        }
        return acc
      },
      {} as {
        [id: number]: {
          category: Category
          count: number
        }
      }
    )
  }
}

function calculateOperatorsPresent(locations: AbstractLocation[]) {
  if (!locations) {
    return null
  } else {
    locations = locations.map((location) => {
      const result: any = location.copy()
      result.travelTimeBand = result.travelTime != null ? Math.floor(result.travelTime / 300) * 5 : null
      return result
    })

    locations = arrays.sortBy(locations, ['travelTimeBand', 'town', 'netSalesArea', 'grossInternalArea'])

    return objects.values(
      locations.reduce(
        (acc, place) => {
          if (acc[place.category.id]) {
            acc[place.category.id].count++
            acc[place.category.id].locations.push(place)
          } else {
            acc[place.category.id] = { category: place.category, count: 1, locations: [place] }
          }
          return acc
        },
        {} as {
          [id: number]: {
            category: Category
            count: number
            locations: AbstractLocation[]
          }
        }
      )
    )
  }
}

function calculateOperatorsNotPresent(
  all: { [id: number]: { category: Category; count: number } },
  within: OperatorPresent[]
) {
  const allClone: any = { ...all }
  if (within) {
    within.forEach((item) => {
      delete allClone[item.category.id]
    })
  }

  return objects.values(allClone)
}

export interface OperatorPresent {
  category: Category
  count: number
  locations: AbstractLocation[]
}

export interface OperatorNotPresent {
  category: Category
  count: number
}

export class LocationsPresentReport {
  readonly locationsPresent: AbstractLocation[]
  readonly locationsNotPresent: AbstractLocation[]

  readonly operatorsPresent: OperatorPresent[]
  readonly operatorsNotPresent: OperatorNotPresent[]

  readonly windowMode: boolean

  constructor(
    readonly locationsMode: GapReportLocations,
    readonly travelSourcesCount: number,
    readonly bounds: BoundingBox,
    readonly all: AbstractLocation[],
    readonly present: AbstractLocation[]
  ) {
    this.windowMode = travelSourcesCount === 0 || locationsMode === GapReportLocations.WITHIN_MAP_WINDOW

    this.locationsPresent = this.windowMode ? calculatePresent(all, bounds) : present
    this.locationsNotPresent = calculateNotPresent(all, this.locationsPresent)
    const operatorsMap = calculateAllOperators(all)

    this.operatorsPresent = calculateOperatorsPresent(this.locationsPresent)
    this.operatorsNotPresent = calculateOperatorsNotPresent(operatorsMap, this.operatorsPresent)
  }
}

export class LocationsPresentModel {
  readonly bounds$ = new BehaviorSubject<BoundingBox>(null)

  readonly locationsPresent$: Observable<AbstractLocation[]>
  readonly locationsNotPresent$: Observable<AbstractLocation[]>

  readonly operatorsPresent$: Observable<OperatorPresent[]>
  readonly operatorsNotPresent$: Observable<OperatorNotPresent[]>
  readonly loading$: Observable<Promise<any>>

  constructor(
    private places: PlacesModel,
    locationMode$: Observable<GapReportLocations>,
    travelSourcesCount$: Observable<number>
  ) {
    const allLocations$ = this.places.filteredPlaces.value

    this.locationsPresent$ = combineLatest(
      locationMode$,
      allLocations$,
      places.reachableFilteredPlaces.value,
      places.sources.observable,
      this.bounds$,
      travelSourcesCount$
    ).pipe(
      map(([locationMode, all, reachable, sources, bounds, travelSources]) =>
        travelSources > 0 && locationMode === GapReportLocations.WITHIN_REACHABLE_AREA
          ? [].concat(sources, reachable)
          : calculatePresent(all, bounds)
      ),
      shareReplay(1)
    )

    this.locationsNotPresent$ = combineLatest(allLocations$, this.locationsPresent$).pipe(
      map(([all, reachable]) => calculateNotPresent(all, reachable)),
      shareReplay(1)
    )

    const allOperatorsMap$ = allLocations$.pipe(
      map((locations) => calculateAllOperators(locations)),
      shareReplay(1)
    )

    this.operatorsPresent$ = this.locationsPresent$.pipe(
      map((locations) => calculateOperatorsPresent(locations)),
      shareReplay(1)
    )

    this.operatorsNotPresent$ = combineLatest(allOperatorsMap$, this.operatorsPresent$).pipe(
      map(([all, within]) => calculateOperatorsNotPresent(all, within)),
      shareReplay(1)
    )
  }

  async exportCsvNotPresent() {
    const locations = await this.locationsNotPresent$.take(1).toPromise()
    exportPlaces(locations, 'csv', 'storepointgeo_not_present.csv')
  }

  async exportCsvPresent() {
    const locations = await this.locationsPresent$.take(1).toPromise()
    exportPlaces(locations, 'csv', 'storepointgeo_present.csv')
  }
}
