<div *ngIf="sources">
<div style="margin: 32px 0px">
  <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="2rem">
    <div *ngIf="report">
      <button color="primary" mat-flat-button (click)="export()">
        <mat-icon mat-font-set="material-icons">file_download</mat-icon>
        Download Comparison Report Data
      </button>
    </div>
    
    <div *ngIf="availableTemplates?.length > 1">
      <mat-form-field>
        <mat-label>Template</mat-label>
        <mat-select [ngModel]="appModel.settings.comparisonReportTemplateIdUpdates | async" (ngModelChange)="selectTemplate($event)">
          <mat-option *ngFor="let option of availableTemplates" [value]="option.id">
            {{option.name}}
          </mat-option>
        </mat-select>      
      </mat-form-field>      
    </div>

    <div>
      <mat-form-field>
        <mat-label>Highlight</mat-label>
        <mat-select [ngModel]="comparisonHighlight$ | async" (ngModelChange)="selectHighlight($event)">
          <mat-option *ngFor="let option of avalableComparisonHighlight" [value]="option.key">
            {{option.label}}
          </mat-option>
        </mat-select>      
      </mat-form-field>      
    </div>

    <div>
      <mat-form-field>
        <mat-label>Index</mat-label>
        <mat-select [ngModel]="comparisonIndex$ | async" (ngModelChange)="selectIndex($event)">
          <mat-option *ngFor="let option of avalableComparisonIndex" [value]="option.key">
            {{option.label}}
          </mat-option>
        </mat-select>      
      </mat-form-field>      
    </div>
    
    <!-- <div fxFlex></div> -->
  </div>
</div>

<div [style.min-width.px]="tableWidth">
<table>
  <tbody>
    <ng-container *ngFor="let segment of report?.segments">
        <tr class="section">
            <th>{{segment.title}}</th>

          <ng-container *ngFor="let item of sources">
            <th class="separator" colspan="4"></th>
          </ng-container>
        </tr>


        <ng-container *ngIf="segment.type == 'index'">
            <ng-container *ngFor="let group of segment.children">
                <tr>
                    <th>{{group.title}}</th>
  
                    <ng-container *ngFor="let item of sources">
                      <th colspan="2">{{perHouseholdCapitaTitle}}</th>
                      <th class="separator" colspan="2">Index vs {{indexTitle}}</th>
                    </ng-container>
                </tr>
  
                <ng-container *ngFor="let child of group.children">
                  <tr>
                    <td>{{child.title}}</td>
  
                    <ng-container *ngFor="let item of child.values">
                      <td colspan="2">{{item.value | number: '1.0-0'}}</td>
                      <td class="separator" colspan="2">{{item.valueIndex | number: '1.0-0'}}</td>
                    </ng-container>
                  </tr>
                </ng-container>
            </ng-container>  
        </ng-container>


        <ng-container *ngIf="segment.type == 'indexDominant'">
          <ng-container *ngFor="let group of segment.children">
              <tr>
                  <th>{{group.title}}</th>

                  <ng-container *ngFor="let item of sources">
                    <th colspan="2">%</th>
                    <th colspan="2" class="separator">Index vs {{indexTitle}}</th>
                  </ng-container>
              </tr>

              <ng-container *ngFor="let child of group.children">
                <tr>
                  <td>{{child.title}}</td>

                  <ng-container *ngFor="let item of child.values">
                    <td colspan="2" [class.highlighted]="item.dominant" >{{item.value | number: '1.0-1'}}</td>
                    <td colspan="2" class="separator" [class.highlighted]="item.dominant" >{{item.valueIndex | number: '1.0-0'}}</td>
                  </ng-container>
                </tr>
              </ng-container>
          </ng-container>
        </ng-container>


        <ng-container *ngIf="segment.type == 'property'">
          <ng-container *ngFor="let group of segment.children">
              <ng-container *ngFor="let child of group.children">
                <tr>
                  <td>{{child.title}}</td>

                  <ng-container *ngFor="let item of child.values">
                    <td *ngIf="item.type == 'integer'" class="separator property-cell" colspan="4">{{item.value | number: '1.0-0'}}</td>
                    <td *ngIf="item.type == 'number'" class="separator property-cell" colspan="4">{{item.value | number: '1.0-1'}}</td>
                    <td *ngIf="item.type == 'ratio'" class="separator property-cell" colspan="4">{{item.value | number: item.format}}</td>
                    <td *ngIf="item.type == 'percent'" class="separator property-cell" colspan="4">{{item.value | percent: '1.0-1'}}</td>
                    <td *ngIf="item.type != 'integer' && item.type != 'percent' && item.type != 'ratio' && item.type != 'number'" class="separator property-cell" colspan="4">{{item.value}}</td>
                  </ng-container>
                </tr>
              </ng-container>
          </ng-container>
        </ng-container>

    </ng-container>
  </tbody>
</table>
</div>
</div>