<div class="map-layout-container" [class.sidenavOpenState]="isSideBarOpened">
  <mat-sidenav-container>
    <mat-sidenav mode="over" #sidenav #start [opened]="isSideBarOpened" (openedChange)="sidenavStateChange($event)">
      <div #logoRef><ng-content select="[map-layout-sidenav-logo]"></ng-content></div>

      <section
        *ngIf="logoRef.childNodes.length === 0"
        class="logo-section"
        [ngStyle]="{ background: (styleOptions && styleOptions.logoBgColor) || '#00838f' }"
      >
        <div class="close-button-container">
          <button mat-icon-button #closeButton (click)="start.close()">
            <mat-icon mat-font-set="material-icons">close</mat-icon>
          </button>
        </div>

        <div class="logo">
          <img
            src="{{
              (styleOptions && styleOptions.logoUrl) || (options && options.logoUrl) || 'assets/images/logo.png'
            }}"
          />
        </div>
      </section>

      <!-- @deprecated -->
      <ng-content select="[map-layout-sidenav-header]"></ng-content>

      <div style="position: relative">
        <ng-content select="[map-layout-sidenav-main]"></ng-content>
      </div>
    </mat-sidenav>

    <div class="map-layout-top-left">
      <ng-content select="[map-layout-top-left-container]"></ng-content>
    </div>

    <div class="fab-container" fxLayout="column" fxLayoutAlign="start end">
      <tgm-travelfab
        [hidden]="customMaxEdgeWeight"
        class="time-distance-fab"
        [colors]="colors"
        [unitLabel]="options.unitLabel || 'min'"
        [(model)]="travelOptions.maxEdgeWeight"
        (modelChange)="travelOptionsChanged()"
        [options]="options.maxEdgeWeightOptions"
      >
      </tgm-travelfab>

      <button *ngIf="customMaxEdgeWeight" mat-fab class="readonlytravel" [disableRipple]="true">
        {{ customMaxEdgeWeight / 60 }} min
      </button>

      <tgm-travelfab
        class="travel-type-fab"
        [(model)]="travelOptions.travelType"
        (modelChange)="travelOptionsChanged()"
        [options]="options.travelTypeOptions"
      >
      </tgm-travelfab>

      <div style="pointer-events: all">
        <ng-content select="[map-layout-extra-fabs]" fxLayout="column"></ng-content>
      </div>
    </div>

    <div class="legend-container">
      <ng-content select="[map-layout-legend-container]"></ng-content>
      <tgm-slide-opacity-animator
        [animationOptions]="{ trigger: styleOptions && !styleOptions.hideRainbow, direction: 'Y' }"
      >
        <tgm-rainbow
          [maxValue]="travelOptions.maxEdgeWeight"
          [unitLabel]="options.unitLabel || 'min'"
          [options]="options.maxEdgeWeightOptions"
        >
        </tgm-rainbow>
      </tgm-slide-opacity-animator>
      <ng-content select="[map-layout-below-legend-container]"></ng-content>
    </div>

    <div class="map-container">
      <custom-mapbox #map [options]="options">
        <ng-content select="[map-layout-extra-mini-fabs]"></ng-content>
      </custom-mapbox>
      <ng-content select="[map-bottom-container]"></ng-content>
    </div>

    <tgm-map-popup #mapMenuPopup>
      <ng-content select="[map-layout-context-menu]"></ng-content>
    </tgm-map-popup>
  </mat-sidenav-container>
</div>
