<h2 class="mat-title">{{user.id && 'Edit Profile' || 'New Profile'}}</h2>

<tgm-loadable [model]="loading">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="ready">
    <section fxLayout="row">
      <div fxLayout="column">
        <div>
          <span class="small-title">Profile Picture</span>
          <tgm-file-uploader
            filter=".jpeg,.jpg,.png,.gif"
            style="width: 150px; height: 150px; margin-right: 50px"
            (selected)="onSelectProfilePicture($event)"
            [background]="url"
          ></tgm-file-uploader>
        </div>

        <div *ngIf="form.value.companyId == null">
          <span style="margin-top: 20px" class="small-title">Company Logo</span>
          <tgm-file-uploader
            filter=".jpeg,.jpg,.png,.gif"
            style="width: 150px; height: 150px; margin-right: 50px"
            (selected)="onSelectCompanyPicture($event)"
            [background]="urlCompany"
          ></tgm-file-uploader>
        </div>

        <div *ngIf="form.value.companyId == null">
          <span style="margin-top: 20px" class="small-title">Custom App Logo</span>
          <tgm-file-uploader
            filter=".jpeg,.jpg,.png,.gif"
            style="width: 150px; height: 150px; margin-right: 50px"
            (selected)="onSelectLogoPicture($event)"
            [background]="urlLogo"
          ></tgm-file-uploader>
        </div>
      </div>

      <section fxLayout="column" fxFlex>
        <mat-form-field style="width: 100%; margin-bottom: 10px">
          <input matInput placeholder="Name" type="text" formControlName="name" />

          <mat-hint *ngIf="form.submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
            Name is required
          </mat-hint>
        </mat-form-field>

        <mat-form-field style="width: 100%; margin-bottom: 10px">
          <input matInput placeholder="Username" type="text" formControlName="username" />

          <mat-hint *ngIf="form.submitted && form.get('username').hasError('required')" [ngStyle]="{'color': 'red'}">
            Username is required
          </mat-hint>
        </mat-form-field>

        <mat-form-field style="width: 100%; margin-bottom: 10px">
          <input matInput placeholder="Email" type="text" formControlName="email" />

          <mat-hint *ngIf="form.submitted && form.get('email').hasError('required')" [ngStyle]="{'color': 'red'}">
            Email is required
          </mat-hint>
        </mat-form-field>

        <mat-form-field style="width: 100%; margin-bottom: 10px">
          <input matInput placeholder="Password" type="password" formControlName="password" />

          <mat-hint *ngIf="form.submitted && form.get('password').hasError('required')" [ngStyle]="{'color': 'red'}">
            Password is required
          </mat-hint>

          <mat-hint *ngIf="form.submitted && form.get('password').hasError('minlength')" [ngStyle]="{'color': 'red'}">
            Password must be at least 3 characters long
          </mat-hint>
        </mat-form-field>

        <mat-form-field style="width: 100%; margin-bottom: 10px">
          <input matInput placeholder="Address" type="text" formControlName="address" />

          <mat-hint *ngIf="form.submitted && form.get('address').hasError('required')" [ngStyle]="{'color': 'red'}">
            Address is required
          </mat-hint>
        </mat-form-field>

        <section>
          <label class="form-label">Assign to Company</label>
          <mat-select placeholder="None" formControlName="companyId">
            <mat-option *ngFor="let template of availableCompanies" [value]="template.id">
              {{template.name}}
            </mat-option>
          </mat-select>
        </section>
      </section>
    </section>

    <section *ngIf="form.value.companyId == null">
      <admin-edit-permissions formControlName="userPermissions"></admin-edit-permissions>
    </section>

    <!-- <section>
      <h3 style="text-transform: uppercase" class="mat-title">Enabled features</h3>
      <section>
        <mat-list>
          <mat-list-item *ngFor="let profile of availablePermissions" (click)="togglePermission(profile.key)">
            <mat-checkbox style="pointer-events: none" [checked]="user.permissions && user.permissions[profile.key]" aria-label=""></mat-checkbox>
            {{profile.label}}
          </mat-list-item>
        </mat-list>
      </section>
    </section>

    <section>
      <h3 style="text-transform: uppercase" class="mat-title">Enabled regions</h3>
      <section>
        <mat-list>
          <mat-list-item *ngFor="let profile of availableRegions" (click)="toggleRegion(profile)">
            <mat-checkbox style="pointer-events: none"  [checked]="profile.selected" aria-label=""></mat-checkbox>
            {{profile.name}}
          </mat-list-item>
        </mat-list>
      </section>
    </section>

    <section>
      <h3 style="text-transform: uppercase" class="mat-title">Data profiles</h3>
      <section>
        <mat-list>
          <mat-list-item *ngFor="let profile of dataProfiles" (click)="toggleProfile(profile)">
            <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
            {{profile.name}}
          </mat-list-item>
        </mat-list>
      </section>
    </section>

    <section>
      <h3 style="text-transform: uppercase" class="mat-title">Map Print</h3>
      <mat-list>
        <mat-list-item (click)="toggleGeneral('printShowSizeLegend')">
          <mat-checkbox style="pointer-events: none" [checked]="reportSettings?.customReport?.printShowSizeLegend" aria-label=""></mat-checkbox>
          Show Marker Size Legend
        </mat-list-item>

        <mat-list-item (click)="toggleGeneral('printShowFasciaLegend')">
          <mat-checkbox style="pointer-events: none" [checked]="reportSettings?.customReport?.printShowFasciaLegend" aria-label=""></mat-checkbox>
          Show Fascia Legend
        </mat-list-item>
      </mat-list>

    </section>

    <section>
      <h3 style="text-transform: uppercase" class="mat-title">Pdf Report Template</h3>
      <mat-select placeholder="Default" [value]="reportSettings?.reportTemplateId" (selectionChange)="changeDefaultTemplate($event)">
        <mat-option *ngFor="let template of availableTemplates" [value]="template.id">
          {{template.name}}
        </mat-option>
      </mat-select>
    </section>

    <section>
      <h3 style="text-transform: uppercase" class="mat-title">Comparison Report Template</h3>
      <mat-list>
        <mat-list-item *ngFor="let profile of availableComparisonTemplates" (click)="changeComparisonTemplate(profile)">
          <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
          {{profile.name}}
        </mat-list-item>
      </mat-list>
    </section>

    <section>
      <h3 style="text-transform: uppercase" class="mat-title">Planning Applications</h3>
      <tgm-loadable [model]="loadingDataSets">
        <admin-select-fascias [allowGroupings]="false" [dataSets]="dataSets" ></admin-select-fascias>
      </tgm-loadable>
    </section> -->

    <section>
      <h3 style="text-transform: uppercase" class="mat-title">Messages</h3>
      <div class="hint">Note: Messages already seen by the user are no longer shown here</div>
      <section>
        <mat-list>
          <mat-list-item *ngFor="let profile of availableMessages" (click)="toggleMessage(profile)">
            <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
            {{profile.title}}
          </mat-list-item>
        </mat-list>
      </section>
    </section>

    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-button type="button" routerLink="/admin/users">
        <mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon>
        <span translate="dialog.back">Back</span>
      </button>
      <button mat-flat-button color="primary" type="submit"><span translate="dialog.save">Save</span></button>
    </div>
  </form>
</tgm-loadable>
