import { Injectable } from '@angular/core'
import { files, Indicators } from '@targomo/client'
import { StatisticsGroup, TravelTypeEdgeWeightOptions } from '@targomo/core'
import { from, Subject, Observable, of } from 'rxjs'
import {
  mergeMap,
  take,
  tap,
  toArray,
  map,
  shareReplay,
  distinctUntilChanged,
  switchMap,
  startWith,
  delay,
  filter,
} from 'rxjs/operators'
import { csv } from '../util/csv'
import { AppModel } from './appModel.service'
import { AbstractLocation, EXPORTABLE_PROPERTIES } from './entities/place'
import { ZoneLayersModel } from './zoneLayersModel'
import { AllStatistics } from '../../common/models/statistics/statistics'
import { ZoneSelection } from '../api/sectors'
import {
  ALL_STATISTICS_NAME_MAP,
  ALL_STATISTICS_WORKDOFCE_NAME_MAP,
  STATISTICS_MAP,
  STATISTICS_WORKFORCE,
} from '../../common/constants/statisticsConstants'
import { STATISTICS_FORECAST } from '../../common/constants/constantsForecast'
import { STATISTICS_EXTENDED } from '../../common/constants/statisticsExtended'
import { DataSetEndpoint, canonicalPositions } from '../api'
import { STATISTICS_EXPORT_NAMES } from '../../common/constants/statisticsExportColumnNames'
import { ExportAllEndpoint } from '../api/exportAll'
import { ExportDataRequestStatus, ExportDataRequest } from '../../common/models/exportDataRequest'

export const EXPORTABLE_PROPERTIES_GEOMETRY = ['name']

interface BatchConfig {
  sources: AbstractLocation[]
  zones: ZoneSelection[]
  travelOptions: TravelTypeEdgeWeightOptions
  intersectionMode: string
  filteredPlaces: AbstractLocation[]
  exclusiveMode: boolean
}

// TODO: smart progress check based on number of sources

@Injectable()
export class BatchStatisticsModel {
  readonly exports$: Observable<Partial<ExportDataRequest>[]>
  readonly exportsCount$: Observable<number>
  readonly hasExports$: Observable<boolean>
  readonly exportsAutoRefresh$: Observable<any>

  private reload: Subject<any> = new Subject()

  // private queue: Subject<BatchConfig> = new Subject()

  constructor(
    readonly appModel: AppModel,
    private indicators: Indicators,
    private zonesModel: ZoneLayersModel,
    private dataSetEndpoint: DataSetEndpoint,
    private exportAllEndpoint: ExportAllEndpoint
  ) {
    // this.initQueue()
    this.exports$ = this.initExports().pipe(shareReplay(1))
    this.exportsCount$ = this.exports$.pipe(
      map((list) => list.length),
      shareReplay(1),
      distinctUntilChanged()
    )
    this.hasExports$ = this.exportsCount$.pipe(
      map((num) => num > 0),
      shareReplay(1),
      distinctUntilChanged()
    )

    this.exportsAutoRefresh$ = this.exports$.pipe(
      delay(3000),
      filter((list) => {
        return (
          (list || []).filter(
            (item) => item.status !== ExportDataRequestStatus.READY && item.status !== ExportDataRequestStatus.ERROR
          ).length > 0
        )
      }),
      tap(() => {
        this.reloadExports()
      }, shareReplay(1))
    )
  }

  reloadExports() {
    this.reload.next(true)
  }

  private initExports() {
    return this.reload.pipe(
      startWith(null),
      switchMap(async () => {
        return await this.exportAllEndpoint.list()
      })
    )
  }

  // private initQueue() {
  //   this.queue
  //     .pipe(
  //       mergeMap((value) => {
  //         return from(this.indicators.add(this.processBatch(value)))
  //       }, 1),
  //       tap((result) => {
  //         const data = result.map((item) => item.flattened)
  //         files.saveFile(csv(data), 'text/csv', 'storepointgeo_all_data_combined.csv')
  //       })
  //     )
  //     .subscribe()
  // }

  // private processBatch(config: BatchConfig) {
  //   const statisticsModel = this.appModel.statistics

  //   function flattenStatistics(statistics: StatisticsGroup) {
  //     const allKeys = []
  //       .concat(STATISTICS_MAP, STATISTICS_FORECAST, STATISTICS_EXTENDED, STATISTICS_WORKFORCE)
  //       .map((item) => item.name)

  //     return allKeys.reduce((acc, cur) => {
  //       let key = STATISTICS_EXPORT_NAMES[cur]
  //       // if (ALL_STATISTICS_NAME_MAP[cur]) {
  //       //   const item = ALL_STATISTICS_NAME_MAP[cur]
  //       //   key = (item.label || item.name) + ` [C${item.id}]`
  //       // } else if (ALL_STATISTICS_WORKDOFCE_NAME_MAP[cur]) {
  //       //   const item = ALL_STATISTICS_WORKDOFCE_NAME_MAP[cur]
  //       //   key = (item.label || item.name) + ` [W${item.id}]`
  //       // }

  //       acc[key] = statistics[cur].total
  //       return acc
  //     }, {} as any)
  //   }

  //   function flattenReport(source: AbstractLocation, statistics: StatisticsGroup) {
  //     const placeProperties = EXPORTABLE_PROPERTIES.reduce((acc, cur) => {
  //       acc[cur] = (source as any)[cur]

  //       return acc
  //     }, {} as any)

  //     const statFlattened = flattenStatistics(statistics)

  //     return { ...placeProperties, ...statFlattened }
  //   }

  //   function flattenGeometryReport(geometry: any, statistics: StatisticsGroup) {
  //     const placeProperties = EXPORTABLE_PROPERTIES_GEOMETRY.reduce((acc, cur) => {
  //       acc[cur] = geometry[cur]

  //       return acc
  //     }, {} as any)

  //     const statFlattened = flattenStatistics(statistics)

  //     return { ...placeProperties, ...statFlattened }
  //   }

  //   if (config.zones && config.zones.length > 0) {
  //     return from(config.zones).pipe(
  //       mergeMap(async (zone) => {
  //         const statistics = await statisticsModel.calculateForGeometry(zone.geometry)
  //         const flattened = flattenGeometryReport(zone, statistics.inputOriginal)

  //         const result = {
  //           statistics,
  //           flattened,
  //           zone,
  //           source: null as any,
  //         }

  //         return result
  //       }, 1),
  //       toArray()
  //     )
  //   } else {
  //     return from(config.sources).pipe(
  //       mergeMap(async (source) => {
  //         const inactiveSources = this.appModel.places.getInactiveSourcesWithPlaces(
  //           config.exclusiveMode,
  //           [source],
  //           config.travelOptions,
  //           config.filteredPlaces
  //         )

  //         const statistics = await statisticsModel.calculateForSources(
  //           [source],
  //           config.travelOptions,
  //           inactiveSources,
  //           config.intersectionMode
  //         )

  //         const flattened = flattenReport(source, statistics.inputOriginal)

  //         const result = {
  //           source,
  //           statistics,
  //           flattened,
  //           zone: null as any,
  //         }

  //         return result
  //       }, 1),
  //       toArray()
  //     )
  //   }
  // }

  async addCurrentSources(exportType: 'report' | 'competitors' = 'report') {
    const zones = await this.zonesModel.selectedZones.pipe(take(1)).toPromise()
    const sources = this.appModel.places.sources.getValues()
    const travelOptions = await this.appModel.settings.travelOptionsUpdates.pipe(take(1)).toPromise()
    const intersectionMode = await this.appModel.settings.intersectionModeUpdates.pipe(take(1)).toPromise()
    const filteredPlaces = await this.appModel.places.filteredPlaces.value.pipe(take(1)).toPromise()
    const exclusiveMode = await this.appModel.settings.exclusiveTravelUpdates.pipe(take(1)).toPromise()
    const inactiveSources = await this.appModel.places.inactiveSources.pipe(take(1)).toPromise()

    function serializePositions(locations: AbstractLocation[]) {
      return locations.map((item) => {
        const { dataSet, category, ...rest } = item

        return rest
      })
    }

    const name = [sources.length && `${sources.length} Location(s)`, zones.length && `${zones.length} Zones(s)`]
      .filter((part) => !!part)
      .join(' and ')

    // const visiblePlaceIds = filteredPlaces.map((item) => item.id)
    // const customPlaces = serializePositions(filteredPlaces.filter((place) => place.isStar()))
    // const visibleDataSets = this.appModel.places.getVisibleDataSets()

    const { visiblePlaceIds, customPlaces, visibleDataSets } = await this.appModel.places.serializeVisible()

    const payload = {
      name,
      sources: serializePositions(canonicalPositions(sources) as AbstractLocation[]),
      zones,
      travelOptions,
      intersectionMode,
      // filteredPlaces: serializePositions(canonicalPositions(inactiveSources) as AbstractLocation[]),
      exclusiveMode,
      exportType,
      customPlaces,
      visibleDataSets,
      visiblePlaceIds,
    }

    await this.exportAllEndpoint.create(payload)
    this.reloadExports()
  }
}
