import { Component } from '@angular/core'
import { SubscriberComponent } from '@targomo/client'
import { Observable } from 'rxjs'
import { AppModel } from '../../../model/appModel.service'
import { DataLoadingModel } from '../../../model/dataLoadingModel'
import { DataSetFilter } from '../../../model/dataSetFilter'

@Component({
  selector: 'primary-filters-panel',
  templateUrl: './primaryFiltersPanel.component.html',
  styleUrls: ['./primaryFiltersPanel.component.less'],
})
export class PrimaryFiltersPanelComponent extends SubscriberComponent {
  layers: DataSetFilter[] = []
  maximums: any = {}

  showStoresWithinNoAreaDataUpdates$: Observable<boolean>

  SIZE_OPTIONS = [
    { name: 'All', key: 'ALL' },
    { name: 'High Street', key: 'HS' },
    { name: 'Shopping Centre', key: 'SC' },
    { name: 'Out of Town', key: 'OOT' },
  ]

  constructor(readonly appModel: AppModel, readonly dataLoadingModel: DataLoadingModel) {
    super()

    this.showStoresWithinNoAreaDataUpdates$ = this.appModel.settings.showStoresWithinNoAreaDataUpdates

    this.watch(this.appModel.places.visibleDatasetsFilters, (dataSets) => {
      this.layers = dataSets
    })

    this.watch(Observable.combineLatest(this.dataLoadingModel.maximums), ([maximums]) => {
      this.maximums = maximums
    })
  }

  toggleShowStoresWithinNoAreaDataUpdates(event: any) {
    this.appModel.settings.displaySettings.nextPropertyWithCurrent('showStoresWithinNoAreaData', (current: any) => {
      return event.checked
    })
  }

  clickMenu(event: any) {
    event.stopPropagation()
  }
}
