import { AutoUpdateLog, DataSet } from '../../common/models'
import { Category } from './category'
import { Injectable } from '@angular/core'
import { RESTHelper } from '@targomo/client'
import { object } from '@targomo/common'
import { DataSetVersion } from '../../common/models/dataSet'
import { VersionsEndpoint } from './types'
import { LatLngId } from '@targomo/core'

export type DataSet = DataSet

@Injectable()
export class DataSetEndpoint implements VersionsEndpoint<DataSet, DataSetVersion> {
  private http: RESTHelper
  private cachedAllProperties: Promise<string[]>

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/datasets')
  }

  get(Set: { id: number }) {
    return this.http.get<DataSet>(`/${Set.id}`).then((Set) => {
      // Set.categories = Set.categories.map(category => angular.extend(new Category(), category).init())

      return Set
    })
  }

  delete(set: { id: number }) {
    return this.http.delete<any>(`/${set.id}`)
  }

  autoUpdateNow(set: { id: number }) {
    return this.http.post<any>(`/${set.id}/autoupdate/now`, {})
  }

  getCategories(set: { id: number }) {
    return this.http
      .get<Category[]>(`/${set.id}/categories`)
      .then((categories) => categories.map((category) => object.assign(new Category(), category).init()))
  }

  getAvailableProperties(set: { id: number }) {
    return this.http.get<string[]>(`/${set.id}/availableProperties`)
  }

  getAllAvailableProperties() {
    const promise = this.cachedAllProperties
    this.cachedAllProperties = this.http.get<string[]>(`/availablePropertiesAll`)

    if (promise) {
      return promise
    } else {
      return this.cachedAllProperties
    }
  }

  getVersions(set: { id: number }) {
    return this.http.get<DataSetVersion[]>(`/${set.id}/versions`)
  }

  getAutoUpdateLog(set: { id: number }) {
    return this.http.get<AutoUpdateLog>(`/${set.id}/autoupdate`)
  }

  saveActive(version: { id: number }, value: boolean = true) {
    return this.http.put(`/version/${version.id}/active/${value}`, {})
  }

  savePreview(version: { id: number }, value: boolean = true) {
    return this.http.put(`/version/${version.id}/preview/${value}`, {})
  }

  deleteVersion(version: { id: number }) {
    return this.http.delete<boolean>(`/version/${version.id}`)
  }

  save(Set: DataSet) {
    return this.http.put<DataSet>(`/${Set.id}`, Set)
  }

  create(Set: DataSet) {
    return this.http.post<DataSet>(`/`, Set)
  }

  upload(data: string, set?: { id?: number; name?: string }, categorize?: string) {
    if (set && set.id) {
      return this.http.put<number>(`/upload/${set.id}/${categorize}`, data)
    } else {
      return this.http.post<number>(`/upload/new/${encodeURIComponent(set.name)}/${categorize}`, data)
    }
  }

  uploadMultipart(files: any, set?: { id?: number; name?: string }, categorize?: string) {
    const data = new FormData()
    data.append('files', files[0])

    if (set && isFinite(set.id)) {
      return this.http.put<number>(`/upload/${set.id}/${categorize}`, data)
    } else {
      return this.http.post<number>(`/upload/new/${encodeURIComponent(set.name)}/${categorize}`, data)
    }
  }

  findAll() {
    return this.http.get<DataSet[]>('')
  }

  findAllFull() {
    return this.http.get<DataSet[]>('/full')
  }

  // findAvailableOptions() {
  //   return this.http.get<DataSet[]>('/available')
  // }

  async me() {
    const sets = await this.http.get<DataSet[]>('/me')
    sets.forEach((set) => {
      set.placesCountOriginal = set.placesCount
      set.categories = set.categories.map((category) => {
        const result = object.assign(new Category(), category).init()
        result.dataSetName = set.name
        result.dataSet = set
        return result
      })
    })

    return sets.filter((set) => !!set.versionId)
  }

  async meUnavailable() {
    const sets = await this.http.get<DataSet[]>('/meUnavailable')
    sets.forEach((set) => {
      set.categories = set.categories.map((category) => {
        const result = object.assign(new Category(), category).init()
        result.dataSetName = set.name
        return result
      })
    })

    return sets.filter((set) => !!set.versionId)
  }
}
