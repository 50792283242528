import { Component } from '@angular/core';
import { Category, DataSet } from "../../../api";
import {Auth} from '@targomo/client';
import {DataSetEndpoint} from '../../../api/dataSet';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { GeoRegionsEndpoint } from '../../../api/georegions';

@Component({
  selector: 'admin-georegion-categories',
  templateUrl: './adminGeoRegions.html'
})
export class AdminGeoRegionComponent {
  form: FormGroup
  // categories: Category[] = []
  currentEdit: number
  loading: Promise<Category[]>
  saving: Promise<{}>
  set: DataSet = <any>{}
  id: number

  constructor(private formBuilder: FormBuilder, private Auth: Auth, private dataSetEndpoint: GeoRegionsEndpoint, private route: ActivatedRoute, private router: Router) {
    this.form = this.formBuilder.group({
      'name': [this.set.name, [Validators.required]],
    })
    this.refresh()
  }

  async refresh() {
    const params = this.route.snapshot.params
    const id = params.id

    this.id = id
    // this.categories = await (this.loading = this.dataSetEndpoint.getCategories({id: setId}))
    this.set = await this.dataSetEndpoint.get({id: id})

    this.form = this.formBuilder.group({
      'name': [this.set.name, [Validators.required]],
    })
  }

  back() {
    this.router.navigate(['/admin/georegions'])
  }

  showMenu(event: any) {
    event.stopPropagation()
  }

  async save(event?: any) {
    if (this.form.valid) {
      this.set.name = this.form.value.name
      this.set.copyright = this.form.value.copyright
      this.saving = this.dataSetEndpoint.save(this.set)
      await this.saving
      this.back()
    }
  }

  async updatedVersion() {
    // this.categories = await (this.loading = this.dataSetEndpoint.getCategories({id: this.setId}))
  }
}
