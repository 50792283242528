<div class="admin-panel">
  <h2 class="mat-title">Settings</h2>

  <form class="form" name="form" (submit)="save($event)" novalidate>
    <section fxLayout="row">
      <div fxLayout="column">
        <span class="small-title">Default App Logo</span>
        <tgm-file-uploader filter=".jpeg,.jpg,.png,.gif" style="width: 150px; height: 150px; margin-right: 50px" (selected)="onSelectLogo($event)" [background]="url"></tgm-file-uploader>
      </div>

      <div fxLayout="column" fxFlex>
        <section fxLayout="column" fxFlex>
          <mat-form-field>
            <input placeholder="Copyright Notice" matInput type="text" name="copyright" [(ngModel)]="settings.copyright"/>
          </mat-form-field>
        </section>

        <section fxLayout="column" fxFlex>
          <mat-form-field>
            <input placeholder="Help url" matInput type="text" name="helpUrl" [(ngModel)]="settings.helpUrl"/>
          </mat-form-field>
        </section>

        <section fxLayout="column" fxFlex>
          <mat-form-field>
            <input placeholder="GBP to EURO exchange rate" matInput type="text" name="gbpToEuroRate" [(ngModel)]="settings.gbpToEuroRate"/>
          </mat-form-field>
        </section>
      </div>
    </section>

    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-flat-button color="primary" type="submit">Save</button>
    </div>
  </form>
</div>