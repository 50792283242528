import { Indicators } from '@targomo/client'
import { object as objects } from '@targomo/common'
import { StatisticsGeometryResult, StatisticsGroup } from '@targomo/core'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/take'
import { Observable } from 'rxjs/Observable'
import { take } from 'rxjs/operators'
import { AllStatistics } from '../../../common/models/statistics/statistics'
import { canonicalPositions } from '../../api/place'
import { ZoneLayersEndpoint, ZoneSelection } from '../../api/sectors'
import { AppModel } from '../../model/appModel.service'
import { STATISTICS, STATISTICS_GROUP, STATISTICS_GROUP_WORKFORCE, STATISTICS_WORKFORCE } from '../../model/constants'
import { INTERSECTION_THRESHOLD } from '../../model/placesModel'
import { STATISTICS_EXTENDED } from '../../../common/constants/statisticsExtended'
import { STATISTICS_FORECAST } from '../../../common/constants/constantsForecast'

export const PDF_TABLE_TRAVEL_INTERVALS: { [index: number]: number[] } = {
  5: [5],
  10: [5, 10],
  15: [5, 10, 15],
  20: [10, 15, 20],
  25: [10, 20, 25],
  30: [10, 20, 30],
}

export async function getReportTimes(appModel: AppModel) {
  const options = await appModel.settings.travelOptionsUpdates.pipe(take(1)).toPromise()
  const edgeWeights = await appModel.settings.edgeWeightsUpdates.take(1).toPromise()
  const useCustomReportTimes = await appModel.settings.useCustomReportTimesUpdates.take(1).toPromise()

  const settings = appModel.settings.displaySettings.getValue()

  if (useCustomReportTimes) {
    const customTimes = await appModel.settings.customReportTimesUpdates.pipe(take(1)).toPromise()
    return customTimes.map((time) => {
      return {
        minutes: time / 60,
        time: time,
      }
    })
  }

  if (settings.travelRangeCustomMaxEdgeWeight) {
    const minutes = options.maxEdgeWeight / 60
    const count = minutes <= 5 ? 1 : minutes <= 10 ? 2 : 3
    const step = minutes / count
    return Array.from({ length: count }).map((ite, i) => {
      const time = Math.round((i + 1) * step)
      return {
        minutes: time,
        time: time * 60,
      }
    })
  } else {
    const minutesGroup = edgeWeights[0].value / 5 //
    const times = PDF_TABLE_TRAVEL_INTERVALS[options.maxEdgeWeight / minutesGroup]

    return times.map((time) => {
      return {
        minutes: time,
        time: time * 60,
      }
    })
  }
}

export async function calculate(appModel: AppModel, indicators: Indicators) {
  const client = appModel.settings.clientStatistics
  let sources = appModel.places.sources.getValues()

  const times = await getReportTimes(appModel)

  const [options, exclusiveMode, zonesVisible, selectedZones, reportPerCapitaHouseholds, intersectionMode] =
    await Observable.combineLatest(
      appModel.settings.travelOptionsUpdates,
      appModel.settings.exclusiveTravelUpdates,
      appModel.zoneLayersModel.selectionExistsUpdates,
      appModel.zoneLayersModel.selectedZones,
      appModel.settings.reportPerCapitaHouseholdsUpdates,
      appModel.settings.intersectionModeUpdates
    )
      .take(1)
      .toPromise()

  /// TEMPORARY
  if (zonesVisible) {
    sources = [appModel.places.places.getValue()[0]]
    const times = [5]

    const nameFull = (selectedZones && selectedZones[0] && selectedZones[0].name) || 'Within Selected Zone'
    const statistics = await appModel.statistics.census.promise.pipe(take(1)).toPromise()
    return [
      {
        name: '5',
        nameFull,
        nameFullFromTo: nameFull,
        nameFullFromToExclusive: nameFull,
        statistics: statistics,
        minutes: 5,
      },
    ]
  } else {
    if (sources.length > INTERSECTION_THRESHOLD && intersectionMode !== 'union') {
      return []
    }

    // const STATISTICS_COMBINED = STATISTICS.concat(STATISTICS_EXTENDED as any)
    const STATISTICS_COMBINED = STATISTICS.concat(STATISTICS_FORECAST as any, STATISTICS_EXTENDED as any)

    return indicators.add(
      Promise.all(
        times.map(async (timeBand, i) => {
          const requestOptions = { ...options }
          requestOptions.maxEdgeWeight = timeBand.time // time * minutesGroup

          const results: StatisticsGroup[] = await Promise.all([
            await indicators.add(
              client.statistics.combined(canonicalPositions(sources), {
                statistics: STATISTICS_COMBINED,
                statisticsGroup: STATISTICS_GROUP,
                ...requestOptions,
                intersectionMode,
                inactiveSources: canonicalPositions(
                  await appModel.places.getInactiveSources(exclusiveMode, sources, options)
                ),
              })
            ),

            await indicators.add(
              client.statistics.combined(canonicalPositions(sources), {
                statistics: STATISTICS_WORKFORCE,
                statisticsGroup: STATISTICS_GROUP_WORKFORCE,
                ...requestOptions,
                intersectionMode,
                inactiveSources: canonicalPositions(
                  await appModel.places.getInactiveSources(exclusiveMode, sources, options)
                ),
              })
            ),
          ])

          const result = objects.assign({}, results[0], results[1])
          // const results = await Promise.all([
          //   this.indicators.add(this.appModel.settings.client.statistics.combined(canonicalPositions(sources), {statistics: STATISTICS_BENCHMARKS_CHILANGO, statisticsGroup: STATISTICS_GROUP, ...options})),
          //   this.indicators.add(this.appModel.settings.client.statistics.combined(canonicalPositions(sources), {statistics: STATISTICS_BENCHMARKS_CHILANGO_WORKFORCE, statisticsGroup: STATISTICS_GROUP_WORKFORCE, ...options}))
          // ])

          // const data = new AllStatistics(objects.assign({}, results[0], results[1]), (await this.appModel.publicSettings).gbpToEuroRate)

          const statistics = new AllStatistics(result, (await appModel.publicSettings).gbpToEuroRate)
          const { region, calculatedRegion, country } = appModel.statistics.singleRegion(sources)
          statistics.updateIndices(region, calculatedRegion, country, reportPerCapitaHouseholds)

          // const requestOptions = travelOptions.forTimes({sources: sources, travelSpeed: options.travelSpeed, travelType: options.travelType, travelTime: time * 60})
          // requestOptions.setMaxRoutingTime(time * 60)
          // requestOptions.travelTime = time * 60

          // return api.census(requestOptions, sources).then(result => {

          const nameFull = zonesVisible
            ? (selectedZones && selectedZones[0] && selectedZones[0].name) || 'Within Selected Zone'
            : '' + timeBand.minutes + ' minutes'
          return {
            name: '' + timeBand.minutes,
            nameFull,
            nameFullFromTo: '0-' + nameFull,
            nameFullFromToExclusive: (i == 0 ? 0 : timeBand.minutes + 1) + '-' + nameFull,
            statistics: statistics,
            minutes: timeBand.minutes,
          }
          // })
        })
      )
    )
  }
}

export async function calculateZone(
  zone: ZoneSelection,
  appModel: AppModel,
  indicators: Indicators,
  zoneLayersEndpoint: ZoneLayersEndpoint
) {
  const client = appModel.settings.client
  // let sources = appModel.places.sources.getValues()
  const options = await appModel.settings.travelOptionsUpdates.take(1).toPromise()
  const exclusiveMode = await appModel.settings.exclusiveTravelUpdates.take(1).toPromise()
  const reportPerCapitaHouseholds = await appModel.settings.reportPerCapitaHouseholdsUpdates.take(1).toPromise()
  // const zonesVisible = await appModel.settings.showSectorsUpdates.take(1).toPromise()

  let times = PDF_TABLE_TRAVEL_INTERVALS[options.maxEdgeWeight / 60]
  let sources: any = []

  /// TEMPORARY
  if (true) {
    sources = [appModel.places.places.getValue()[0]]
    times = [5]
  }

  return indicators.add(
    Promise.all(
      times.map(async (time) => {
        const geometry = await await indicators.add(zoneLayersEndpoint.getGeometries(zone.layer, zone.features))

        // this.settingsModel.clientStatistics.statistics.geometry(JSON.stringify(geometry.properties.alternate), {crs: 25833, statistics: STATISTICS, statisticsGroup: STATISTICS_GROUP}),

        // const result = await indicators.add(zoneLayersEndpoint.statistics(geometry.properties.alternate, {
        // const result =
        const results: StatisticsGeometryResult[] = await Promise.all([
          await indicators.add(
            appModel.settings.clientStatistics.statistics.geometry(JSON.stringify(geometry.properties.alternate), {
              crs: 25833,
              statistics: STATISTICS,
              statisticsGroup: STATISTICS_GROUP,
            })
          ),

          await indicators.add(
            appModel.settings.clientStatistics.statistics.geometry(JSON.stringify(geometry.properties.alternate), {
              crs: 25833,
              statistics: STATISTICS_WORKFORCE,
              statisticsGroup: STATISTICS_GROUP_WORKFORCE,
            })
          ),
        ])

        const result = objects.assign({}, results[0].values.sum, results[1].values.sum)

        const merged: any = result // .values.sum
        for (let key in merged) {
          ;(merged as any)[key].total = (merged as any)[key].value
          ;(merged as any)[key].values = { 0: (merged as any)[key].value }
        }

        const statistics = new AllStatistics(merged, (await appModel.publicSettings).gbpToEuroRate)
        const { region, calculatedRegion, country } = appModel.statistics.singleRegion(sources)
        statistics.updateIndices(region, calculatedRegion, country, reportPerCapitaHouseholds)

        const nameFull = zone.name || 'Within Selected Zone'
        return {
          name: '' + time,
          nameFull,
          nameFullFromTo: nameFull,
          nameFullFromToExclusive: nameFull,
          statistics: statistics,
          minutes: time,
        }
      })
    )
  )
}
