import { TgmToolbarButtonComponent } from './../tgm-toolbar-button/tgm-toolbar-button.component';
import { Component, OnInit, ContentChildren, QueryList } from '@angular/core';
import { TgmToolbarPanelComponent } from '../tgm-toolbar-panel/tgm-toolbar-panel.component';

@Component({
  selector: 'tgm-toolbar',
  templateUrl: './tgm-toolbar.component.html',
  styleUrls: ['./tgm-toolbar.component.less']
})
export class TgmToolbarComponent implements OnInit {

  @ContentChildren(TgmToolbarPanelComponent) _panels: QueryList<TgmToolbarPanelComponent>;
  @ContentChildren(TgmToolbarButtonComponent) _buttons: QueryList<TgmToolbarButtonComponent>;

  panelExpanded = false;

  constructor() { }


  ngOnInit() {
  }

  togglePanel() {
    this.panelExpanded = !this.panelExpanded;
  }

}
