import {Component, Input, OnInit, OnChanges, SimpleChange, ViewContainerRef, AfterViewInit, ViewChild} from '@angular/core'
import { HomeComponent } from '../home.component';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { SettingsModel } from '../../model/settingsModel';

@Component({
  selector: 'view-layers',
  template: '',
})
export class ViewLayersComponent implements OnDestroy, OnInit {
  constructor(private home: HomeComponent, private settingsModel: SettingsModel) {
  }

  ngOnInit() {
    this.home.setMainLayerVisible(true)
  }

  ngOnDestroy() {
    this.home.setMainLayerVisible(false)    
  }
}