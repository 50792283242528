import { Component, OnInit, Input, NgZone } from '@angular/core'
import { Place } from '../../../api/place'
import { SubscriberComponent, files, Indicators } from '@targomo/client'
import { MatTableDataSource, Sort } from '@angular/material'
import { array as arrays } from '@targomo/common'
import { MatchpointModel } from '../../../model/matchpoint/matchpointModel'
import { csv } from '../../../util/csv'
import { Category } from '../../../../common/models'
import { DataLoadingModel } from '../../../model/dataLoadingModel'
import { AppModel } from '../../../model/appModel.service'
import { DataSetFilter } from '../../../model/dataSetFilter'

@Component({
  selector: 'matchpoint-gap-opportunities',
  templateUrl: './matchpointGapOpportunities.component.html',
  styleUrls: ['./matchpointGapOpportunities.component.less'],
})
export class MatchpointGapOpportunitiesComponent extends SubscriberComponent implements OnInit {
  @Input() readonly model: MatchpointModel

  placesAll: Place[]
  places: Place[]
  dataSource: MatTableDataSource<any>
  sort: Sort
  filter: DataSetFilter
  categoriesFilter: Set<Category>
  displayedColumns = ['name', 'secondaryCategory', 'centerPointPercent', 'centerPointAtToMap', 'centerPointTravelTime']

  constructor(
    private placesLoadingModel: DataLoadingModel,
    private indicators: Indicators,
    private appModel: AppModel,
    private zone: NgZone
  ) {
    super()
  }

  async ngOnInit() {
    this.watch(this.model.similarGapOperatorsWithTravelTime$, (places) => {
      this.zone.run(() => {
        this.placesAll = places
        this.updatePlaces()
        this.dataSource = new MatTableDataSource(this.places)
        this.updatePage()
      })
    })

    this.watch(this.model.gapOpportunityFilterSelectedCategories$, (categoriesFilter) => {
      this.categoriesFilter = categoriesFilter

      this.zone.run(() => {
        this.updatePlaces()
        this.updatePage()
      })
    })

    this.watch(this.model.gapOpportunityFilterUpdates$, async (filter) => {
      this.filter = filter

      if (filter && filter.hasSliderValues()) {
        await this.indicators.add(this.model.loadGapOpportunityDataSets())
      }

      this.zone.run(() => {
        this.updatePlaces()
        this.updatePage()
      })
    })
  }

  private updatePlaces() {
    if (this.filter && this.placesAll) {
      this.places = this.placesAll.filter((place) =>
        this.filter.matchLocation(place as any, !this.filter.hasSliderValues())
      )
    } else {
      this.places = this.placesAll || []
    }

    if (this.categoriesFilter) {
      this.places = this.places.filter((place) => this.categoriesFilter.has(place.category))
    }
  }

  sortData(sort: Sort) {
    this.sort = sort
    this.updatePage()
  }

  private updatePage() {
    if (this.places) {
      let direction = false
      let active: string | string[] = 'secondaryCategory'

      if (this.sort) {
        active = this.sort.active
        direction = this.sort.direction === 'desc'
      }

      if (active === 'secondaryCategory') {
        active = ['secondaryCategory', 'centerPointPercent']
      } else if (active === 'centerPointPercent') {
        active = ['centerPointPercent', 'secondaryCategory']
      }

      this.places = arrays.sortBy(this.places, active, direction)
      this.dataSource = new MatTableDataSource(this.places)
    }
  }

  async downloadCsv() {
    // const travelOptions = await this.appModel.settings.travelOptionsUpdates.pipe(take(1)).toPromise()
    // let config: any[] = await this.appModel.settings.matchpointUserSettingsUpdates.pipe(take(1)).toPromise()
    // if (!config) {
    //   // TODO
    //   config = await this.matchpointEndpoint.getSettingsPublic()
    // }

    const results = this.places
    // await this.matchpointEndpoint.getSimilarExport(
    //   this.places,
    //   config,
    //   travelOptions.maxEdgeWeight,
    //   travelOptions.travelType
    // )

    const exported = csv(
      results.map((item: any) => {
        return {
          fascia: item.fascia,
          category: item.category.name,
          presentInCentres: Math.round(item.centerPointPercent * 100),
          travelTime: item.travelTime >= 0 ? Math.round(item.travelTime / 60) : null,
        }
      })
    )

    files.saveFile(exported, 'text/csv', 'storepointgeo_matchpoint_gap_opportunities.csv')
  }

  async toggleCategoryVisible(category: Category) {
    category.visible = !category.visible
    if (category.visible) {
      await this.indicators.add(this.placesLoadingModel.loadDataSet(category.dataSet))
    }

    this.appModel.places.categorySelectionUpdated.next(category as never)
  }
}
