import { Injectable } from "@angular/core";
import { AppModel } from "./appModel.service";
import { DataSet } from "../api/dataSet";
import { Category } from "../api/category";
import { AbstractLocation, CustomLocation } from "./entities";

interface LayerState {
  visibleCategories: {[id: number]: {[id: number]: Partial<Category>}}
  customPlaces: AbstractLocation[]
}

@Injectable()
export class LayerStateStack {
  private stack: LayerState[] = []

  constructor(
    private appModel: AppModel
  ) {
  }

  private serialiseSelectionDate(dataSets: DataSet[]) {
    const result: {[id: number]: {[id: number]: Category}} = {}

    dataSets.forEach(dataSet => {
      const categorySelection: any = {}

      result[dataSet.id] = categorySelection
      if (dataSet.categories) {
        dataSet.categories.forEach(category => {
          if (category.visible) {
            categorySelection[category.id] = {
              name: category.name,
              grouping: category.grouping
            }
          }

          category.visible = false
        })
      }
    })

    return result
  }

  private derialiseSelectionDate(dataSets: DataSet[], state: LayerState) {
    dataSets.forEach(dataSet => {
      // In case datasets have changed
      const categoryMap = Object.keys(state.visibleCategories[dataSet.id]).reduce((acc, cur) => {
        const item = state.visibleCategories[dataSet.id][+cur]
        acc[`${item.grouping}@${item.name}`] = true
        return acc
      }, {} as any)

      if (dataSet.categories) {
        dataSet.categories.forEach(category => {
          const key = `${category.grouping}@${category.name}`
          if (category.grouping && category.name) {
            category.visible = !!categoryMap[key] || !!(state.visibleCategories[dataSet.id] && state.visibleCategories[dataSet.id][category.id])
          }
        })
      }
    })
  }

  async push() {
    const dataSets = await this.appModel.places.dataSets.take(1).toPromise()

    const state: LayerState = {
      visibleCategories: this.serialiseSelectionDate(dataSets),
      customPlaces: this.appModel.places.customPlaces.getValues()
    }

    this.stack.push(state)

    this.appModel.places.customPlaces.clear()
    this.appModel.places.filters.touch()
    this.appModel.sessionLoaded.next(true)

  }

  async pop() {
    const dataSets = await this.appModel.places.dataSets.take(1).toPromise()
    const state = this.stack.pop()

    this.derialiseSelectionDate(dataSets, state)

    this.appModel.places.customPlaces.update(state.customPlaces)
    this.appModel.places.filters.touch()
    this.appModel.sessionLoaded.next(true)
  }

  async snapshot() {
    return this.stack
  }

  async restore(state: LayerState[]) {
    if (state) {
      this.stack = state.map(item => {
        return {
          visibleCategories: item.visibleCategories,
          customPlaces: (item.customPlaces || []).map(data => new CustomLocation(data))
        }
      })
    } else {
      this.stack = []
    }
  }
}