import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Indicators, Auth, SubscriberComponent } from '@targomo/client';
import { MessagesEndpoint } from '../../../api/message';
import { switchMap, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-admin-message-center-edit',
  templateUrl: './adminMessageCenterEdit.component.html',
  styleUrls: ['./adminMessageCenterEdit.component.less']
})
export class AdminMessageCenterEditComponent extends SubscriberComponent implements OnInit {
  form: FormGroup
  message: any
  loading: Promise<any>
  ready = false
  image: string

  constructor(
    private auth: Auth,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private indicators: Indicators,
    private messagesEndpoint: MessagesEndpoint,
  ) {
    super()
  }

  async ngOnInit() {
    this.watch(this.route.params, async params => {
      let controls: {[name: string]: FormControl} = {}

      if (params.id !== 'new') {
        this.message = await this.indicators.add(this.messagesEndpoint.get({id: +params.id}))

        this.image = this.message.image

        this.form = new FormGroup(controls = {
          title: new FormControl(this.message.title, [Validators.required]),
          body: new FormControl(this.message.body, [Validators.required]),
          sendToAll: new FormControl(this.message.sendToAll, []),
        })

        this.ready = true
      } else {
        this.form = new FormGroup(controls = {
          title: new FormControl('', [Validators.required]),
          body: new FormControl('', [Validators.required]),
          sendToAll: new FormControl(false, []),
        })

        this.message = {}
        this.ready = true
      }

      this.watch(controls.title.valueChanges, title => {
        this.message.title = title
      })

      this.watch(controls.body.valueChanges.pipe(
        debounceTime(100),
        switchMap(async body => {
          try {
            const result = await this.messagesEndpoint.preview(body)
            return result && result.html || body
          } catch (e) {
            return body
          }
        })
      ), html => {
        this.message.html = html
      })      
    })
  }


  async submit() {
    (<any>this.form).submitted = true

    if (this.form.valid) {
      this.message.title = this.form.value.title
      this.message.body = this.form.value.body
      this.message.sendToAll = this.form.value.sendToAll

      const result = await this.messagesEndpoint.save(this.message)

      if (result) {
        this.router.navigate(['/admin/messages'])
      }
    }
  }

  onSelectImage(files: FileList) {
    if (!files || !files[0]) {
      this.image = null
      this.message.image = null
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      const result = <any>reader.result

      this.message.image = result
      this.image = result
    }

    reader.readAsDataURL(files[0])
  }  
}
