import { ObservableList } from '@targomo/client'
import { AbstractLocation } from '..'

export class CombinedSources extends ObservableList<AbstractLocation> {
  updatePointAndClick(source: AbstractLocation) {
    this.remove(this.find((item) => item.isTemporaryCustom()))
    if (source) {
      this.add(source)
    }
  }
}
