import { DataSet } from '../../common/models/dataSet'
import { AbstractLocation } from './entities/place'

export class DataSetFilter {
  constructor(
    readonly dataSet: DataSet,
    private values: Record<number, Record<string, any>>,
    private update: () => void
  ) {
    this.values = this.values || {}
  }

  isActive() {
    return !this.getValue('passive')
  }

  isPassive() {
    return !this.isActive()
  }

  isNSA() {
    return !this.isGIA()
  }

  isGIA() {
    return !this.getValue('nsa')
  }

  isUnit() {
    return !this.getValue('average')
  }

  isAverage() {
    return !this.isUnit()
  }

  setActive() {
    this.setValue('passive', false)
  }

  setPassive() {
    this.setValue('passive', true)
  }

  setNSA() {
    this.setValue('nsa', true)
  }

  setGIA() {
    this.setValue('nsa', false)
  }

  setUnit() {
    this.setValue('average', false)
  }

  setAverage() {
    this.setValue('average', true)
  }

  private sanitizeSizeBy(value: any) {
    value = value || { ALL: true }

    if (value.ALL) {
      return { ALL: true }
    } else {
      delete value.ALL
    }

    return value
  }

  getSizeBy() {
    return this.sanitizeSizeBy(this.getValue('sizeBy'))
  }

  getSizeByLabel() {
    return Object.keys(this.getSizeBy()).join(',')
  }

  toggleSizeBy(item: string) {
    const value = this.getSizeBy()
    value[item] = !value[item]

    if (item !== 'ALL') {
      delete value.ALL
    }

    this.setValue('sizeBy', this.sanitizeSizeBy({ ...value }))
  }

  isSizeBy(item: string) {
    return !!this.getSizeBy()[item]
  }

  getSliderMaxValue(maximums?: {
    netSalesArea: {
      min: any
      max: any
    }
    grossInternalArea: {
      min: any
      max: any
    }
  }) {
    const limits = maximums[this.isNSA() ? 'netSalesArea' : 'grossInternalArea']
    return (limits && limits.max) || 1000000
  }

  getSliderLowerValue() {
    return this.getValue('sliderLower')
  }

  getSliderUpperValue() {
    return this.getValue('sliderUpper')
  }

  setSliderLowerValue(value: number) {
    this.setValue('sliderLower', Number.isFinite(+value) && !!String(value).trim() ? value : null)
  }

  setSliderUpperValue(value: number) {
    this.setValue('sliderUpper', Number.isFinite(+value) && !!String(value).trim() ? value : null)
  }

  hasSliderValues() {
    return this.getSliderLowerValue() != null || this.getSliderUpperValue() != null
  }

  getFilterProperty() {
    return this.isNSA() ? 'netSalesArea' : 'grossInternalArea'
  }

  matchLocation(place: AbstractLocation, showStoresWithinNoAreaData: boolean) {
    const property = this.getFilterProperty()
    const types = this.getSizeBy()
    const lowerLimit = this.getSliderLowerValue()
    const upperLimit = this.getSliderUpperValue()

    if (this.isAverage()) {
      const category = place.category

      if (category) {
        const averageSize = category.averageSizes.getSize(property, types.ALL ? ['ALL'] : Object.keys(types))

        if (averageSize == null) {
          return showStoresWithinNoAreaData
        }

        return (
          (lowerLimit == undefined || averageSize >= lowerLimit) &&
          (upperLimit == undefined || averageSize <= upperLimit)
        )
      }
    } else {
      if (place[property] == null) {
        return showStoresWithinNoAreaData
      }

      if (place.id >= 0 || (place.id < 0 && place[property] != null)) {
        return (
          (lowerLimit == undefined || place[property] >= lowerLimit) &&
          (upperLimit == undefined || place[property] <= upperLimit)
        )
      } else {
        return true
      }
    }

    return true
  }

  get name() {
    return this.dataSet.name
  }

  private setValue(key: string, value: any) {
    this.values[this.dataSet.id] = this.values[this.dataSet.id] || {}
    this.values[this.dataSet.id][key] = value

    this.update()
  }

  private getValue(key: string) {
    return this.values && this.values[this.dataSet.id] && this.values[this.dataSet.id][key]
  }
}
