const COLUMNS_LOCATIONS: {[index: string]: string} = {
  'y' : 'lat',
  'x' : 'lng',
  'lat' : 'lat',
  'lng' : 'lng',
  'county' : 'county',
  'district' : 'district',
  'fascia' : 'fascia',

  'y_alt' : 'latAlt',
  'x_alt' : 'lngAlt',
  'lat_alt' : 'latAlt',
  'lng_alt' : 'lngAlt',
  'gross_internal_area' : 'grossInternalArea',
  'gross_internal_area_source' : 'grossInternalAreaSource',
  'holding_company' : 'holdingCompany',
  'net_sales_area' : 'netSalesArea',
  'net_sales_area_source' : 'netSalesAreaSource',
  'primary_category' : 'primaryCategory',
  'secondary_category' : 'secondaryCategory',
  'store_id' : 'storeId',

  'y-alt' : 'latAlt',
  'x-alt' : 'lngAlt',
  'lat-alt' : 'latAlt',
  'lng-alt' : 'lngAlt',
  'gross-internal-area' : 'grossInternalArea',
  'gross-internal-area-source' : 'grossInternalAreaSource',
  'holding-company' : 'holdingCompany',
  'net-sales-area' : 'netSalesArea',
  'net-sales-area-source' : 'netSalesAreaSource',
  'primary-category' : 'primaryCategory',
  'secondary-category' : 'secondaryCategory',
  'store-id' : 'storeId',

  'yAlt' : 'latAlt',
  'xAlt' : 'lngAlt',
  'latAlt' : 'latAlt',
  'lngAlt' : 'lngAlt',
  'grossinternalarea' : 'grossInternalArea',
  'grossinternalareasource' : 'grossInternalAreaSource',
  'holdingcompany' : 'holdingCompany',
  'netsalesarea' : 'netSalesArea',
  'netsalesareasource' : 'netSalesAreaSource',
  'primarycategory' : 'primaryCategory',
  'secondarycategory' : 'secondaryCategory',
  'storeid' : 'storeId',

  'paon' : 'paon',
  'postcode' : 'postcode',
  'region' : 'region',
  'saon' : 'saon',
  'sitename' : 'sitename',
  'street' : 'street',
  'suburb' : 'suburb',
  'taon' : 'taon',
  'town' : 'town',
}


const COLUMN_TYPES_LOCATIONS: {[index: string]: ('string' | 'number' | 'date')} = {
  'lat' : 'number',
  'lng' : 'number',
  'county' : 'string',
  'district' : 'string',
  'fascia' : 'string',

  'latAlt' : 'number',
  'lngAlt' : 'number',

  'grossInternalArea' : 'number',
  'grossInternalAreaSource' : 'string',
  'holdingCompany' : 'string',
  'netSalesArea' : 'number',
  'netSalesAreaSource' : 'string',
  'primaryCategory' : 'string',
  'secondaryCategory' : 'string',
  'storeId' : 'string',

  'paon' : 'string',
  'postcode' : 'string',
  'region' : 'string',
  'saon' : 'string',
  'sitename' : 'string',
  'street' : 'string',
  'suburb' : 'string',
  'taon' : 'string',
  'town' : 'string',
}

const COLUMNS_PLANNING_APPLICATIONS = {
  ...COLUMNS_LOCATIONS,

  'developer': 'developer',
  'stage': 'stage',
  'planning_app': 'planningApp',
  'link_to_planning': 'linkToPlanning',
  'app_date': 'appDate',
  'decision_date': 'decisionDate',
  'open_date': 'openDate',
  'latest_comment': 'latestComment',
  'date_checked': 'dateChecked',

  // 'developer': 'developer',
  // 'stage': 'stage',
  'planning app': 'planningApp',
  'link to planning': 'linkToPlanning',
  'app date': 'appDate',
  'decision date': 'decisionDate',
  'open date': 'openDate',
  'latest comment': 'latestComment',
  'date checked': 'dateChecked',

  'planningapp': 'planningApp',
  'linktoplanning': 'linkToPlanning',
  'appdate': 'appDate',
  'decisiondate': 'decisionDate',
  'opendate': 'openDate',
  'latestcomment': 'latestComment',
  'datechecked': 'dateChecked',
}

const COLUMN_TYPES_PLANNING_APPLICATIONS: {[index: string]: ('string' | 'number' | 'date')} = {
  ...COLUMN_TYPES_LOCATIONS,

  'developer': 'string',
  'stage': 'string',
  'planningApp': 'string',
  'linkToPlanning': 'string',
  'appDate': 'date',
  'decisionDate': 'date',
  'openDate': 'date',
  'latestComment': 'string',
  'dateChecked': 'date',  
}

export interface ImportColumnsDefinitions {
  columnsSynonyms: {[index: string]: string}
  columnsTypes: {[index: string]: ('string' | 'number' | 'date')}
}

export const LOCATION_DEFINITIONS: ImportColumnsDefinitions = {
  columnsSynonyms: COLUMNS_LOCATIONS,
  columnsTypes: COLUMN_TYPES_LOCATIONS
}

export const PLANNING_APPLICATION_DEFINITIONS: ImportColumnsDefinitions = {
  columnsSynonyms: COLUMNS_PLANNING_APPLICATIONS,
  columnsTypes: COLUMN_TYPES_PLANNING_APPLICATIONS
}



// export const COLUMNS: {[index: string]: string} = {
//   'y' : 'lat',
//   'x' : 'lng',
//   'lat' : 'lat',
//   'lng' : 'lng',
//   'county' : 'county',
//   'district' : 'district',
//   'fascia' : 'fascia',

//   'y_alt' : 'latAlt',
//   'x_alt' : 'lngAlt',
//   'lat_alt' : 'latAlt',
//   'lng_alt' : 'lngAlt',
//   'gross_internal_area' : 'grossInternalArea',
//   'gross_internal_area_source' : 'grossInternalAreaSource',
//   'holding_company' : 'holdingCompany',
//   'net_sales_area' : 'netSalesArea',
//   'net_sales_area_source' : 'netSalesAreaSource',
//   'primary_category' : 'primaryCategory',
//   'secondary_category' : 'secondaryCategory',
//   'store_id' : 'storeId',

//   'y-alt' : 'latAlt',
//   'x-alt' : 'lngAlt',
//   'lat-alt' : 'latAlt',
//   'lng-alt' : 'lngAlt',
//   'gross-internal-area' : 'grossInternalArea',
//   'gross-internal-area-source' : 'grossInternalAreaSource',
//   'holding-company' : 'holdingCompany',
//   'net-sales-area' : 'netSalesArea',
//   'net-sales-area-source' : 'netSalesAreaSource',
//   'primary-category' : 'primaryCategory',
//   'secondary-category' : 'secondaryCategory',
//   'store-id' : 'storeId',

//   'yAlt' : 'latAlt',
//   'xAlt' : 'lngAlt',
//   'latAlt' : 'latAlt',
//   'lngAlt' : 'lngAlt',
//   'grossinternalarea' : 'grossInternalArea',
//   'grossinternalareasource' : 'grossInternalAreaSource',
//   'holdingcompany' : 'holdingCompany',
//   'netsalesarea' : 'netSalesArea',
//   'netsalesareasource' : 'netSalesAreaSource',
//   'primarycategory' : 'primaryCategory',
//   'secondarycategory' : 'secondaryCategory',
//   'storeid' : 'storeId',

//   'paon' : 'paon',
//   'postcode' : 'postcode',
//   'region' : 'region',
//   'saon' : 'saon',
//   'sitename' : 'sitename',
//   'street' : 'street',
//   'suburb' : 'suburb',
//   'taon' : 'taon',
//   'town' : 'town',
// }


// export const COLUMN_TYPES: {[index: string]: string} = {
//   'lat' : 'number',
//   'lng' : 'number',
//   'county' : 'string',
//   'district' : 'string',
//   'fascia' : 'string',

//   'latAlt' : 'number',
//   'lngAlt' : 'number',

//   'grossInternalArea' : 'number',
//   'grossInternalAreaSource' : 'string',
//   'holdingCompany' : 'string',
//   'netSalesArea' : 'number',
//   'netSalesAreaSource' : 'string',
//   'primaryCategory' : 'string',
//   'secondaryCategory' : 'string',
//   'storeId' : 'string',

//   'paon' : 'string',
//   'postcode' : 'string',
//   'region' : 'string',
//   'saon' : 'string',
//   'sitename' : 'string',
//   'street' : 'string',
//   'suburb' : 'string',
//   'taon' : 'string',
//   'town' : 'string',
// }


// export function translateColumns(row: any, headers: string[], translatedHeaders: string[], line?: number) {
//   let object: any = {}
//   let other: any = null

//   const error = (text: string) => {
//     throw new Error('Line ' + line + ':' + text)
//   }


//   headers.forEach((key, j) => {
//     if (key != 'id' && key != 'data_set_id') {
//       let value = row[key]
//       let translatedKey = translatedHeaders[j]// COLUMNS[key.toLowerCase()]

//       if (!translatedKey) {
//         // error('Unknown column `' + key + '` found')
//         if (!other) {
//           other = {}
//         }

//         if (key in other) {
//           error('Duplicate column `' + key + '` found')
//         }

//         other[key] = value
//       } else {
//         if (translatedKey in object) {
//           error('Duplicate column `' + key + '` found')
//         }

//         if (COLUMN_TYPES[translatedKey] === 'number') {
//           value = (value || '').trim()

//           if (value === '') {
//             value = null
//           } else {
//             value = +value

//             if (!!value && !isFinite(value)) {
//               error('Invalid numerical value `' + value +'` for column `' + key + '`')
//             }
//           }
//         }

//         if (translatedKey == 'lng') {
//           value = +value

//           if (value < -14 || value > 3) {
//             error("Invalid lng value `" + value + '` (Coordinates must be lng/lat in 4326)')
//           }
//         }

//         if (translatedKey == 'lat') {
//           value = +value

//           if (value < 49 || value > 64) {
//             error("Invalid lat value `" + value + '` (Coordinates must be lng/lat in 4326)')
//           }
//         }

//         if (translatedKey == 'lngAlt' && (value !== '' && value != null)) {
//           value = +value

//           if (value < -14 || value > 3) {
//             error("Invalid lngAlt value `" + value + '` (Coordinates must be lng/lat in 4326)')
//           }
//         }

//         if (translatedKey == 'latAlt' && (value !== '' && value != null)) {
//           value = +value

//           if (value < 49 || value > 64) {
//             error("Invalid latAlt value `" + value + '` (Coordinates must be lng/lat in 4326)')
//           }
//         }

//         object[translatedKey] = value
//       }
//     }
//   })

//   if (!object.lat || !object.lng) {
//     error("Missing lng/lat values")
//   }

//   object.other = other

//   return object
// }

