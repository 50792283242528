<p>Select to use default colours by StorePoint locations or custom colours</p>

<mat-radio-group [ngModel]="(settings.displaySettings | async).showCustomCategoryColors">
  <div class="option-box" (click)="changeSelection(false)">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
      <div>
        <option-image></option-image>
        <mat-radio-button [value]="false"><b>Default</b></mat-radio-button>
      </div>
    </div>
  </div>

  <div class="option-box" (click)="changeSelection(true)">
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
      <div>
        <option-image></option-image>
        <mat-radio-button [value]="true"><b>Custom</b></mat-radio-button>
      </div>

      <div>
        To edit your custom colours at anytime simply click on the colour square by the fascia in the StorePoint data
        layers and select an alternative colour from the palette.
      </div>
    </div>
  </div>
</mat-radio-group>
