<ng-container *ngIf="statistics && allStatistics && node">
  <ng-container *ngIf="enableGraph">
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="view" (change)="viewChangeClick($event)">
      <mat-button-toggle value="graph" title="View as Chart">
        <mat-icon>insert_chart</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="table" title="View as Table">
        <mat-icon>grid_on</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <tgm-custom-bargraph
      *ngIf="!!graphOptions && view == 'graph'"
      [model]="graphModel"
      [options]="graphOptions"
    ></tgm-custom-bargraph>
  </ng-container>

  <table *ngIf="view == 'table'">
    <thead>
      <tr>
        <th>Segment</th>
        <th *ngIf="!node.skipPercent">%</th>
        <th *ngIf="!!node.showPerHousehold">{{ perHouseholdCapitaTitle }} ({{ currencySymbol }})</th>
        <th *ngIf="!node.skipPercent || !!node.showPerHousehold || !!node.percentOverIndex">
          Ind vs {{ allStatistics && allStatistics.regionLabel() }} Avg
        </th>
        <th *ngIf="!node.indexMoney && allColumns && (!node.skipPercent || !!node.showPerHousehold)">
          {{ allStatistics && allStatistics.regionLabel() }} Avg %
        </th>
        <th *ngIf="node.indexMoney && allColumns && (!node.skipPercent || !!node.showPerHousehold)">
          {{ allStatistics && allStatistics.regionLabel() }} Avg ({{ currencySymbol }})
        </th>
        <!--<th *ngIf="allColumns && (!!node.showPerHousehold)">Number of Households</th>-->
        <th *ngIf="!!node.showPerHousehold">{{ node.totalLabel || 'Total' }} ({{ currencySymbol }}000)</th>
        <th *ngIf="!node.showPerHousehold">{{ node.totalLabel || 'Total' }}</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let child of children">
        <tr
          [class.intensity]="child.intensity"
          (click)="click(child, statisticsValues[child.id])"
          *ngIf="!child.parent && !(!statisticsValues[child.id].index && !statisticsValues[child.id].total)"
        >
          <td>
            {{ child.name }}
          </td>

          <td *ngIf="!node.skipPercent">{{ statisticsValues[child.id].percent | number: '1.1-1' }}</td>
          <td *ngIf="!!node.showPerHousehold">
            {{ statisticsValues[child.id].total / allStatistics.populationOrHouseholds | number: '1.0-0' }}
          </td>
          <td *ngIf="!node.skipPercent">
            {{ (statisticsValues[child.id].percent * 100) / statisticsValues[child.id].index | number: '1.0-0' }}
          </td>
          <td *ngIf="!!node.percentOverIndex">
            {{ statisticsValues[child.id].percentOverIndex | number: '1.0-0' }}
          </td>
          <td *ngIf="!!node.showPerHousehold">
            {{
              (statisticsValues[child.id].total /
                allStatistics.populationOrHouseholds /
                statisticsValues[child.id].index) *
                100 | simpleNumber: '1.0-0'
            }}
          </td>
          <td *ngIf="!node.indexMoney && allColumns && (!node.skipPercent || !!node.showPerHousehold)">
            {{ statisticsValues[child.id].index | number: '1.1-1' }}
          </td>
          <td *ngIf="node.indexMoney && allColumns && (!node.skipPercent || !!node.showPerHousehold)">
            {{ statisticsValues[child.id].index | number: '1.0-0' }}
          </td>
          <td *ngIf="!!node.showPerHousehold">{{ statisticsValues[child.id].total / 1000 | number: '1.0-0' }}</td>
          <td *ngIf="!node.showPerHousehold">{{ statisticsValues[child.id].total | number: '1.0-0' }}</td>
        </tr>

        <tr (click)="click(child, statisticsValues[child.id])" *ngIf="child.parent">
          <td class="expandable" [attr.colspan]="allColumns ? 5 : 4">
            <div fxLayout="row">
              <span class="inner-content">{{ child.name }}</span>
              <div class="secondary-container">
                <mat-icon
                  *ngIf="child.parent"
                  [class.toggled]="child.visible"
                  class="mat-secondary animation-rotate"
                  mat-font-set="material-icons"
                  >keyboard_arrow_right</mat-icon
                >
              </div>
            </div>
          </td>
        </tr>
      </ng-container>

      <tr class="totals" (click)="click(statisticsValues[child.id])" *ngIf="!node.skipTotal">
        <td>Total</td>
        <td *ngIf="!node.skipPercent"></td>
        <td *ngIf="!!node.showPerHousehold"></td>
        <td *ngIf="!node.skipPercent || !!node.showPerHousehold"></td>
        <td *ngIf="allColumns && (!node.skipPercent || !!node.showPerHousehold)"></td>
        <!--<td *ngIf="allColumns && (!!node.showPerHousehold)"></td>-->
        <td *ngIf="!!node.showPerHousehold">{{ statisticsValues.total / 1000 | number: '1.0-0' }}</td>
        <td *ngIf="!node.showPerHousehold">{{ statisticsValues.total | number: '1.0-0' }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
