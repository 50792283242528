import { Component, OnInit } from '@angular/core'
import { SettingsModel } from '../../../model/settingsModel'

@Component({
  selector: 'travel-method-options-panel',
  templateUrl: './travelMethodOptionsPanel.component.html',
  styleUrls: ['./travelMethodOptionsPanel.component.less'],
})
export class TravelMethodOptionsPanelComponent {
  options = [
    { value: 'union', name: 'Combined' },
    { value: 'intersection', name: 'Overlapping' },
    { value: 'exclusive', name: 'Exclusive' },
  ]

  constructor(readonly settings: SettingsModel) {}

  changeSelection(event: any) {
    this.settings.displaySettings.nextProperty('intersectionMode', event.value)
  }
}
