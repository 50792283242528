<ng-container *ngIf="report$ | async; let report">
  <span class="no-data" *ngIf="!report?.groups?.length">No Data</span>
  <ng-container *ngIf="!!report?.groups?.length">
    <div class="hint1">Home Postcodes from Existing Stores in {{ travelTitle$ | async }}</div>

    <div>
      <div *ngFor="let group of report?.groups">
        <div class="group-title">{{ group.title }}</div>
        <table>
          <tr>
            <th>Location</th>
            <th>% of Store Customer Postcodes</th>
          </tr>
          <tr *ngFor="let category of group.categories">
            <td>{{ category.name }}</td>
            <td>{{ category.percent | percent }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="hint1">Others are locations with a postode count &lt; 1%</div>
  </ng-container>
</ng-container>

<div>
  <tgm-loadable [model]="postcodeAnalysisModel.promise$ | async" fxLayout="column" fxLayoutAlign="center center">
    <tgm-custom-bargraph
      [model]="(report$ | async)?.graphModel"
      [options]="graphPopulationOptions"
    ></tgm-custom-bargraph>

    <div class="hint2">
      Excludes stores where &lt; 5% of postcodes fall within a {{ travelTitleHint$ | async }} time
    </div>
  </tgm-loadable>
</div>
