<div [hidden]="!!saveAs">
  <back-button></back-button>
  <section style="padding: 0px 16px">
    <div *ngIf="currentSession">
      <h3>Save session</h3>

      <div style="text-align: center; margin: 32px">
        <div>Currenty loaded session:</div>
        <div class="session-name">{{currentSession?.name}}</div>
      </div>

      <div class="bottom-buttons" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
        <button (click)="saveUpdate()" mat-flat-button color="primary" type="button"><span>Update current session</span></button>
        <button (click)="saveNew()" mat-flat-button color="primary" type="button"><span>Save as new session</span></button>
      </div>

    </div>
  </section>
</div>

<div [hidden]="!saveAs">
  <back-button></back-button>
  <section style="padding: 0px 16px">
    <h3>Save session</h3>

    <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column" novalidate>
      <mat-form-field>
        <input matInput type="text" formControlName="name" required placeholder="Session name">

        <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
          <span>Session name is required</span>
        </mat-hint>
      </mat-form-field>


      <div class="bottom-buttons">
        <button mat-flat-button color="primary" type="submit"><span>Save</span></button>
      </div>
    </form>
  </section>
</div>