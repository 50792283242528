import { AbstractLocation } from './entities'
import { Category } from '../api/category'
import { getCategoryKey } from './dataLoadingModel'

export class LocationIconModel {
  private locationsIconType: Record<string, number> = {}
  private categoriesIconType: Record<string, number> = {}
  private locationsIconSize: Record<string, number> = {}
  private categoriesIconSize: Record<string, number> = {}

  constructor(data: Partial<LocationIconModel>) {
    if (data) {
      Object.assign(this, data)
    }
  }

  toJSON(): Partial<LocationIconModel> {
    return {
      locationsIconType: this.locationsIconType,
      categoriesIconType: this.categoriesIconType,
      locationsIconSize: this.locationsIconSize,
      categoriesIconSize: this.categoriesIconSize,
    } as any
  }

  getLocationIcon(location: AbstractLocation) {
    if (this.locationsIconType[location.id]) {
      return this.locationsIconType[location.id]
    }

    const code = location.category && getCategoryKey(location.category)
    return this.categoriesIconType[code]
  }

  getLocationIconSize(location: AbstractLocation) {
    if (this.locationsIconSize[location.id]) {
      return this.locationsIconSize[location.id] || 1
    }

    const code = location.category && getCategoryKey(location.category)
    return this.categoriesIconSize[code] || 1
  }

  getLocationConfig(location: AbstractLocation) {
    return {
      icon: this.locationsIconType[location.id],
      size: this.locationsIconSize[location.id] || 1,
    }
  }

  getCategoryConfig(category: Category) {
    const code = category && getCategoryKey(category)

    return {
      icon: this.categoriesIconType[code],
      size: this.categoriesIconSize[code] || 1,
    }
  }

  private updateLocationKey(key: string, id: string, value: any) {
    return new LocationIconModel({
      ...this.toJSON(),
      [key]: { ...(this as any)[key], [id]: value },
    })
  }

  updateLocationIcon(location: AbstractLocation, iconType: number) {
    return this.updateLocationKey('locationsIconType', location.id, iconType)
  }

  updateCategoryIcon(category: Category, iconType: number) {
    if (category) {
      return this.updateLocationKey('categoriesIconType', getCategoryKey(category), iconType)
    } else {
      return this
    }
  }

  updateLocationIconSize(location: AbstractLocation, iconSize: number) {
    return this.updateLocationKey('locationsIconSize', location.id, iconSize)
  }

  updateCategoryIconSize(category: Category, iconSize: number) {
    if (category) {
      return this.updateLocationKey('categoriesIconSize', getCategoryKey(category), iconSize)
    } else {
      return this
    }
  }

  // getLocationConfig(location: AbstractLocation) {
  // }

  // updateLocationConfig(location: AbstractLocation, config: any) {
  // }

  decorateWithIcon(location: AbstractLocation) {
    const icon = this.getLocationIcon(location) || 0
    const iconSize = this.getLocationIconSize(location)
    if (icon || iconSize !== 1) {
      location.properties['marker-icon-type'] = `marker_icon_${icon}`
    } else {
      delete location.properties['marker-icon-type']
    }

    location.properties['marker-icon-size'] = iconSize
  }
}
