
import {ActivatedRoute, Router} from '@angular/router'
import {Component, OnInit} from '@angular/core'
import {UserEndpoint} from '../../api/user'
import {FormGroup, FormBuilder, Validators} from '@angular/forms'
import {Auth} from '@targomo/client'
import { CookieService } from 'ngx-cookie-service';
import { ProjectNumberModel } from '../../model/projectNumberModel'
import { loginRedirectGetAndClear } from '../../util/loginRedirect';

@Component({
  selector: 'login-component',
  templateUrl: './login.html',
  styleUrls: ['./account.less']
})
export class LoginComponent {
  form: FormGroup
  failedLogin: boolean = false
  submitted: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private auth: Auth,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private userEndpoint: UserEndpoint,
    private projectNumberModel: ProjectNumberModel
  ) {
    this.projectNumberModel.clearProjectNumber()

    this.form = this.formBuilder.group({
      'username': [null, [Validators.required]],
      'password': [null, [Validators.required]],
    })
  }

  async login(event?: any) {
    this.submitted = true
    this.failedLogin = false

    if (this.form.valid) {
      try {
        this.cookieService.delete('token')
        try {
          // await this.userEndpoint.preflight()
        } catch (ex2) {
          console.error(ex2)
          // nevemind what happens here
        }
        const result = await this.auth.login(this.form.value.username, this.form.value.password)
        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || ''
        if (returnUrl.indexOf('admin') === -1) {
          returnUrl = ''
        }

        returnUrl = loginRedirectGetAndClear() || returnUrl
        // this.router.navigateByUrl(returnUrl)
        window.location = returnUrl
      } catch (e) {
        this.failedLogin = true
      }
    }
  }

  bookmark(event: any) {
    event.preventDefault()

    const pageTitle = document.title
    const pageURL = document.location

    const windowAny = <any>window
    if (windowAny.sidebar) { // For Mozilla Firefox Bookmark
      windowAny.sidebar.addPanel(pageTitle, pageURL, '')
    } else if ((windowAny.external || document.all) && windowAny.external.AddFavorite) { // For IE Favorite
      windowAny.external.AddFavorite( pageURL, pageTitle)
    } else { // for other browsers which does not support
      alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Cmd' : 'Ctrl') + '+D to bookmark this page.')
    }
  }
}