import * as models from '../../common/models'
import {Injectable} from '@angular/core';
import {RESTHelper} from '@targomo/client';

export interface SavedSession extends models.SavedSession {
}

@Injectable()
export class SavedSessionEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/savedSessions')
  }

  me() {
    return this.http.get<SavedSession[]>(`/me`)
  }

  meShared() {
    return this.http.get<SavedSession[]>(`/me/shared`)
  }

  meSharing() {
    return this.http.get<SavedSession[]>(`/me/sharing`)
  }

  get(savedSession: {id: number}) {
    return this.find(savedSession)
  }

  find(savedSession: {id: number}) {
    return this.http.get<SavedSession>(`/${savedSession.id}`)
  }

  create(savedSession: SavedSession) {
    return this.http.post<SavedSession>(`/`, savedSession)
  }

  update(id: number, savedSession: SavedSession) {
    return this.http.put<SavedSession>(`/${id}`, savedSession)
  }

  share(id: number, email: string) {
    return this.http.post<any>(`/${id}/share`, {email})
  }

  unshare(id: number) {
    return this.http.delete<any>(`/share/${id}`)
  }  

  accept(key: string) {
    return this.http.post<any>(`/accept`, {key})
  }

  delete(savedSession: SavedSession) {
    return this.http.delete(`/${savedSession.id}`)
  }
}