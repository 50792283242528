
import {Component, Input, EventEmitter, Output, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource, PageEvent, Sort} from '@angular/material';
import { files, SubscriberComponent } from '@targomo/client';
import { UserEventLogEndpoint } from '../../../api/userEventLog';
import { UserEventLogType, UserEventLog } from '../../../../common/models/userEventLog';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/merge'
import 'rxjs/add/operator/mergeMap'
import {array as arrays} from '@targomo/common'
import {csv} from '../../../util/csv'

const COLUMNS = [
  'name',
  'email',
  'countLogin',
  'timeSession',
  'countDataLayer',
  'countTravelTime',
  'countMapCreated',
  'countMaxiReports',
  'countPdfDownloads',
]

const COLUMNS_WITH_PROJECT = COLUMNS.concat(['projectNumber'])

@Component({
  selector: 'admin-activity-log-report',
  templateUrl: './activityLogReport.component.html',
  styleUrls: ['./activityLogReport.component.less']
})
export class AdminActivityLogReportComponent extends SubscriberComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  loading: Promise<UserEventLog[]>
  dataSource: MatTableDataSource<any>
  dataLength: number = 0
  displayedColumns = COLUMNS

  fromDate: Date
  toDate: Date
  projectNumber: boolean = false
  allowDownload = false
  sort: Sort


  fromDateLoaded: Date
  toDateLoaded: Date

  data: any[]

  constructor(private userEventLog: UserEventLogEndpoint) {
    super()

    this.fromDate = new Date()
    this.toDate = new Date()
    this.fromDate.setMonth(this.fromDate.getMonth() - 1)
  }

  ngOnInit() {
    this.watch(this.paginator.page, (event: PageEvent) => {
      return this.loadPage(event.pageIndex, event.pageSize)
    })
  }

  async loadPage(page: number, size: number) {
    if (this.data) {
      this.dataSource = new MatTableDataSource(this.data.slice(page * size, page * size + size))
    }
  }

  async load() {
    if (!this.fromDate || !this.toDate) {
      return
    }

    this.displayedColumns = this.projectNumber ? COLUMNS_WITH_PROJECT : COLUMNS

    const result = await (this.loading = this.userEventLog.userReport(this.fromDate, this.toDate, this.projectNumber))
    this.data = result
    this.updatePage()

    this.fromDateLoaded = this.fromDate
    this.toDateLoaded = this.toDate

    this.dataLength = result.length
    this.allowDownload = result.length > 0
  }

  download() {
    files.saveFile(csv(this.data), 'text/csv', 'storepointgeo_user_activity.csv')
  }

  sortData(sort: Sort) {
    this.sort = sort
    this.updatePage()
  }

  private updatePage() {
    if (this.data) {
      if (this.sort) {
        this.data = arrays.sortBy(this.data, this.sort.active, this.sort.direction === 'desc')
      }
      this.loadPage(this.paginator.pageIndex, this.paginator.pageSize || 20)
    }
  }
}