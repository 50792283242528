<tgm-loadable [model]="appModel.statistics.census.promise | async">
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 16px">
    <tgm-custom-bargraph [model]="graphPopulationModel" [options]="graphPopulationOptions"></tgm-custom-bargraph>

    <div class="graph-legend" fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="row" *ngIf="yearValues">
        <div style="background: #7d40fc" class="colorbox"></div>
        <div>{{yearValues[0].name}}</div>
        <div style="display: inline-block; width: 6px"></div>
        <div style="background: #c44042" class="colorbox"></div>
        <div>{{yearValues[1].name}}</div>
      </div>
    </div>
  </div>

  <table *ngIf="yearValues">
    <tr>
      <th></th>
      <th></th>
      <th>Change</th>
      <th>Index vs {{allStatistics && allStatistics.regionLabel()}} Avg</th>
    </tr>

    <tr *ngFor="let year of yearValues">
      <td>{{year.name}}</td>
      <td>{{year.value | number: '1.0-0'}}</td>
      <td>{{year.change != null ? (year.change | percent) : ''}}</td>
      <td style="width: 25%">{{year.indexVsAverage != null ? (year.indexVsAverage | number: '1.0-0') : ''}}</td>
    </tr>
  </table>
</tgm-loadable>


<div style="margin: 24px; margin-right: 12px">
  <div class="year-scale" fxLayout="row">
    <div fxFlex="8.333%" *ngFor="let yearLabel of labelYears">{{yearLabel}}</div>
  </div>

  <div style="margin-right: 12px">
    <tgm-rangeslider [margin]="1" [step]="1" [min]="minValue" [max]="maxValue" [(minValue)]="lowerValue" [(maxValue)]="upperValue" (minValueChange)="onSliderChange('lower')" (maxValueChange)="onSliderChange('upper')"></tgm-rangeslider>
  </div>
</div>