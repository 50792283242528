<h2>Activity Log</h2>

<mat-form-field style="width: 100%">
  <mat-select fxFlex [ngModel]="activityType" 
    (ngModelChange)="selectActivityType($event)"
    placeholder="Activity Type" style="width: 100%">
    <mat-option *ngFor="let option of availableTypes" title="{{option.label}}" [value]="option.value">{{option.label}}</mat-option>
  </mat-select>
</mat-form-field>

<tgm-loadable [model]="loading">
  <mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Username </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.username}} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef mat-sort-header> When </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.created | date: 'yyyy-MM-dd HH:mm:ss'}} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="data">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Data </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{formatData(row.data)}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>
</tgm-loadable>

<mat-paginator [length]="dataLength" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>