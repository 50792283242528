import {DataProfile, DataSet}  from '../../common/models'
import {Category} from './category'
import {Injectable} from '@angular/core';
import {RESTHelper} from '@targomo/client';

export type DataProfile = DataProfile

@Injectable()
export class DataProfileEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/dataprofiles')
  }

  get(profile: {id: number}) {
    return this.http.get<DataProfile>(`/${profile.id}`).then(profile => {
      // profile.categories = profile.categories.map(category => angular.extend(new Category(), category).init())

      return profile
    })
  }

  save(profile: DataProfile) {
    return this.http.put<DataProfile>(`/${profile.id}`, profile)
  }

  delete(profile: DataProfile) {
    return this.http.delete<DataProfile>(`/${profile.id}`)
  }

  create(profile: DataProfile) {
    return this.http.post<DataProfile>(`/`, profile)
  }

  findAll() {
    return this.http.get<DataProfile[]>('')
  }
}
