import * as moment from 'moment'

export namespace dateQuarters {
  export function fromDate(date: string) {
    const parts = (date || '').trim().split('-')
    return (+parts[0] * 4) + Math.floor((+parts[1] - 1) / 3)
  }

  export function toBoundsDate(value: number, bound: 'min' | 'max') {
    if (bound === 'max') {
      value = value + 1
    }
    const year = Math.floor(value / 4)
    const quarter = (value) % 4

    const date = new Date()
    date.setFullYear(year)
    date.setMonth(quarter * 3)

    return moment(date).format('YYYY-MM') + '-01'
  }  

  export function toQuarterString(value: number) {
    const year = Math.floor(value / 4)
    const quarter = (value % 4) + 1

    return `${year} Q${quarter}`
  }    
}
