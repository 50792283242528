<tgm-loadable [model]="loading">
  <span class="no-data" *ngIf="!places.length">No Data</span>

  <table *ngIf="sources.length || places.length">
    <tbody>
      <ng-container *ngIf="sources.length && !zonesVisible">
        <ng-container *ngIf="!sourcesFascias">
          <tr *ngIf="sources.length && !zonesVisible" class="subheading">
            <td colspan="tableColSpan + 2" class="mat-title">Origins</td>
          </tr>

          <tr class="head">
            <td (click)="sortByColumn(statistic.key)" *ngFor="let statistic of columns">
              {{statistic.label}}
              <mat-icon
                class="direction-icon"
                mat-font-set="material-icons"
                *ngIf="currentSorting == statistic.key && inverseSorting"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="direction-icon"
                mat-font-set="material-icons"
                *ngIf="currentSorting == statistic.key && !inverseSorting"
                >arrow_downward</mat-icon
              >
            </td>
          </tr>

          <tr (click)="click(item)" *ngFor="let item of sources ">
            <td>
              <span class="checkbox" [style.background]="getColor(item)"></span>
              {{item.defaultName}}
            </td>
            <td>{{item.street}}</td>
            <td>{{item.town}}</td>
            <td>{{item.postcode}}</td>
            <td *ngIf="type == 'planningApplication'">{{item.planningValue | maybeNumber}}</td>
            <td *ngIf="!zonesVisible">{{item.travelTime | minutesHoursSimple}}</td>
            <td>{{item.grossInternalArea | number}}</td>
            <td>{{item.netSalesArea | number}}</td>
          </tr>
        </ng-container>

        <ng-container *ngIf="!!sourcesFascias">
          <tr *ngIf="sources.length && !zonesVisible" class="subheading">
            <td colspan="2" class="mat-title">Origins Fascias</td>
          </tr>

          <tr class="head">
            <td
              [attr.colspan]="statistic.colspan || 1"
              (click)="sortByColumn(statistic.key)"
              *ngFor="let statistic of columnsFascias"
            >
              {{statistic.label}}
              <mat-icon
                class="direction-icon"
                mat-font-set="material-icons"
                *ngIf="currentSorting == statistic.key && inverseSorting"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="direction-icon"
                mat-font-set="material-icons"
                *ngIf="currentSorting == statistic.key && !inverseSorting"
                >arrow_downward</mat-icon
              >
            </td>
          </tr>

          <tr (click)="click(item)" *ngFor="let item of sourcesFascias">
            <td>
              <span
                [attr.colspan]="columnsFascias[0].colspan"
                class="checkbox"
                [style.background]="getColor(item)"
              ></span>
              {{item.name}}
            </td>
            <td>{{item.count}}</td>
            <td>{{item.grossInternalArea | number}}</td>
            <td>{{item.netSalesArea | number}}</td>
          </tr>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let group of groups">
        <ng-container *ngIf="!!places.length && !!group">
          <tr *ngIf="!zonesVisible" class="subheading">
            <td colspan="tableColSpan + 2" class="mat-title">{{group.name}}</td>
          </tr>

          <ng-container *ngIf="!useLocationFascias">
            <tr class="head">
              <td (click)="sortByColumn(statistic.key)" *ngFor="let statistic of columns">
                {{statistic.label}}
                <mat-icon
                  class="direction-icon"
                  mat-font-set="material-icons"
                  *ngIf="currentSorting == statistic.key && inverseSorting"
                  >arrow_upward</mat-icon
                >
                <mat-icon
                  class="direction-icon"
                  mat-font-set="material-icons"
                  *ngIf="currentSorting == statistic.key && !inverseSorting"
                  >arrow_downward</mat-icon
                >
              </td>
            </tr>

            <tr (click)="click(item)" *ngFor="let item of group.values " [style.background]="group.background">
              <td>
                <span class="checkbox" [style.background]="getColor(item)"></span>
                {{item.defaultName}}
              </td>
              <td>{{item.street}}</td>
              <td>{{item.town}}</td>
              <td>{{item.postcode}}</td>
              <td *ngIf="type == 'planningApplication'">{{item.planningValue | maybeNumber}}</td>
              <td *ngIf="!zonesVisible">{{item.travelTime | minutesHoursSimple}}</td>
              <td>{{item.grossInternalArea | number}}</td>
              <td>{{item.netSalesArea | number}}</td>
            </tr>
          </ng-container>

          <ng-container *ngIf="!!useLocationFascias">
            <tr class="head">
              <td
                [attr.colspan]="statistic.colspan || 1"
                (click)="sortByColumn(statistic.key)"
                *ngFor="let statistic of columnsFascias"
              >
                {{statistic.label}}
                <mat-icon
                  class="direction-icon"
                  mat-font-set="material-icons"
                  *ngIf="currentSorting == statistic.key && inverseSorting"
                  >arrow_upward</mat-icon
                >
                <mat-icon
                  class="direction-icon"
                  mat-font-set="material-icons"
                  *ngIf="currentSorting == statistic.key && !inverseSorting"
                  >arrow_downward</mat-icon
                >
              </td>
            </tr>

            <tr (click)="click(item)" *ngFor="let item of group.fascias " [style.background]="group.background">
              <td [attr.colspan]="columnsFascias[0].colspan">
                <span class="checkbox" [style.background]="getColor(item)"></span>
                {{item.category.name}}
              </td>
              <td>{{item.count}}</td>
              <td>{{item.grossInternalArea | number}}</td>
              <td>{{item.netSalesArea | number}}</td>
            </tr>
          </ng-container>

          <tr class="totals">
            <td [colSpan]="tableColSpan" style="font-weight: bold">Total</td>
            <td>{{group?.totals?.grossInternalArea | number}}</td>
            <td>{{group?.totals?.netSalesArea | number}}</td>
          </tr>

          <tr class="totals" *ngIf="group.isLast">
            <td [colSpan]="tableColSpan" style="font-weight: bold">Grand Total</td>
            <td>{{totals?.totals?.grossInternalArea | number}}</td>
            <td>{{totals?.totals?.netSalesArea | number}}</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</tgm-loadable>
