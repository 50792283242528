import { DefaultMapLayer, FeatureInteractionLayer } from '@targomo/client'
import { Subscription } from 'rxjs/Subscription'
import { TravelTypeEdgeWeightOptions, TargomoClient } from '@targomo/core'
import { TileMapSource } from '@targomo/client'
import { DefaultMapLayerOptions } from '@targomo/client'
import { MapLayerPosition } from '@targomo/client'
import { Observable } from 'rxjs/Observable'
import { LocalMapModel } from '../../model/localMapModel'
import { FilterMapLayer, FilterMapLayerOptions } from '@targomo/client'
import { CompositeLayer } from '@targomo/client'
import { ExtendedBehaviorSubject } from '../../util/extendedBehaviorSubject'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

export class StreetsComposite extends CompositeLayer {
  private streetsLayer: DefaultMapLayer
  private hoverLayer: FilterMapLayer

  constructor(
    map: CustomMapboxComponent,
    private pedestrianLayerUpdates: Observable<boolean>,
    private hoverMode: Observable<boolean>,
    private mapModel: LocalMapModel,
    private client: TargomoClient
  ) {
    super(map as any)

    this.init()
    this.initEvents()
  }

  private init() {
    const sourceMapUrl = new BehaviorSubject<string>(
      this.client.config.tilesUrl +
        '/statistics/tiles/v1/22/{z}/{x}/{y}.mvt?columns=%220%22&key=' +
        encodeURIComponent(this.client.serviceKey)
    )
    const source = new TileMapSource(<any>sourceMapUrl.asObservable())

    const options: DefaultMapLayerOptions = {
      type: 'line',
      sourceLayer: '22',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': {
          property: '0',
          stops: [
            [0, '#5e4fa2'],
            [814633, '#3288bd'],
            [2019096, '#66c2a5'],
            [3713948, '#abdda4'],
            [6167480, '#e6f598'],
            [8942941, '#fee08b'],
            [11808720, '#fdae61'],
            [14224571, '#f46d43'],
            [16564714, '#d53e4f'],
            [21213442, '#9e0142'],
          ],
        },
        'line-width': 3,
      },
    }

    const layerOptions = new BehaviorSubject<DefaultMapLayerOptions>(options)
    const layer = (this.streetsLayer = new DefaultMapLayer(
      this.map,
      source,
      <any>layerOptions.asObservable()
    ).setPosition(MapLayerPosition.BELOW_MARKERS))
    layer.setVisible(false)

    const filterOptions: FilterMapLayerOptions = {
      sourceLayer: '22',
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'rgba(255, 255, 0, 0.7)', //"#3288bd",
        'line-width': 3,
      },
    }

    this.hoverLayer = new FilterMapLayer(this.map, source, filterOptions).setPosition(MapLayerPosition.BELOW_MARKERS)
    this.hoverLayer.setVisible(false)
  }

  async initEvents() {
    let visible: boolean = false

    this.watch(this.mapModel.hoverPedestrian, (feature) => {
      if (!visible) return

      const list: (string | number)[] = ['in', 'id']

      if (feature) {
        list.push(feature.properties.id)
      }

      this.hoverLayer.setVisible(visible && list.length > 2)
      this.hoverLayer.setFilter(list)
    })

    this.watch(Observable.combineLatest(this.pedestrianLayerUpdates, this.hoverMode), ([state, hover]) => {
      visible = state && hover

      if (!visible) {
        this.hoverLayer.setVisible(false)
      }

      this.streetsLayer.setVisible(state)
    })

    const interaction = new FeatureInteractionLayer(this.streetsLayer)
    this.watch(interaction.events.hover, (event) => {
      this.mapModel.hoverPedestrian.next(event.feature)
    })
  }

  setVisible(value: boolean): this {
    throw new Error('Method not implemented.')
  }
  isVisible(): boolean {
    throw new Error('Method not implemented.')
  }
}
