import { Component } from '@angular/core'
import { Category, DataSet } from '../../api/index'
import { Auth, Indicators } from '@targomo/client'
import { DataSetEndpoint } from '../../api/dataSet'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { startWith } from 'rxjs/operators'
import { AutoUpdateLog } from '../../../common/models'

export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const DEFAULT_PROPERTIES = [
  'storeId',
  'fascia',
  'sitename',
  'primaryCategory',
  'netSalesArea',
  'grossInternalArea',
  'street',
  'town',
  'suburb',
  'postcode',
  'county',
  'region',
]

@Component({
  selector: 'admin-dataprofile-categories',
  templateUrl: './adminDataProfileCategories.html',
  styleUrls: ['./adminDataProfileCategories.less'],
})
export class AdminDataProfileCategoriesComponent {
  form: FormGroup
  categories: Category[] = []
  currentEdit: number
  loading: Promise<Category[]>
  saving: Promise<{}>
  set: DataSet = <any>{}
  setId: number

  properties: string[] = []
  autoUpdateLog: AutoUpdateLog
  updateNowScheduled = false

  constructor(
    private formBuilder: FormBuilder,
    private Auth: Auth,
    private dataSetEndpoint: DataSetEndpoint,
    private route: ActivatedRoute,
    private router: Router,
    private indicators: Indicators
  ) {
    this.form = this.formBuilder.group({
      name: [this.set.name, [Validators.required]],
      labelColumn: [this.set.labelColumn, [Validators.required]],
      copyright: [this.set.copyright],
      publicView: [this.set.publicView],

      planningValueColumn: [this.set.planningValueColumn],
      planningDataSet: [this.set.planningDataSet],
      postcodesDataSet: [this.set.postcodesDataSet],

      autoUpdate: [this.set.autoUpdate],
      autoUpdateURL: [this.set.autoUpdateURL, [Validators.pattern(URL_REGEX)]],
      categorizeBy: [this.set.categorizeBy],
    })
    this.refresh()
  }

  async refresh() {
    const params = this.route.snapshot.params
    const id = params.id
    const setId = (this.setId = params.setId)

    this.categories = setId === 'new' ? [] : await (this.loading = this.dataSetEndpoint.getCategories({ id: setId }))
    this.set = setId === 'new' ? ({} as any) : await this.dataSetEndpoint.get({ id: setId })

    this.properties = DEFAULT_PROPERTIES.concat(
      setId === 'new' ? [] : await this.indicators.add(this.dataSetEndpoint.getAvailableProperties({ id: +setId }))
    )

    this.autoUpdateLog = setId === 'new' ? ({} as any) : await this.dataSetEndpoint.getAutoUpdateLog({ id: setId })

    this.properties.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    })

    this.form = this.formBuilder.group({
      name: [this.set.name, [Validators.required]],
      labelColumn: [this.set.labelColumn || 'fascia', [Validators.required]],
      copyright: [this.set.copyright],
      publicView: [this.set.publicView],
      planningValueColumn: [this.set.planningValueColumn],
      planningDataSet: [this.set.planningDataSet],
      postcodesDataSet: [this.set.postcodesDataSet],

      autoUpdate: [this.set.autoUpdate],
      autoUpdateURL: [this.set.autoUpdateURL, [Validators.pattern(URL_REGEX)]],
      categorizeBy: [this.set.categorizeBy],
    })

    this.form
      .get('autoUpdate')
      .valueChanges.pipe(startWith(this.set.autoUpdate))
      .subscribe((value) => {
        if (value) {
          this.form.get('autoUpdateURL').setValidators([Validators.required, Validators.pattern(URL_REGEX)])
        } else {
          this.form.get('autoUpdateURL').setValidators([Validators.pattern(URL_REGEX)])
        }

        this.form.get('autoUpdateURL').updateValueAndValidity()

        if (value) {
          this.form.get('categorizeBy').setValidators([Validators.required])
        } else {
          this.form.get('categorizeBy').clearValidators()
        }

        this.form.get('categorizeBy').updateValueAndValidity()
      })
  }

  back() {
    this.router.navigate(['/admin/datasets'])
  }

  editCategory(category: Category) {
    this.currentEdit = category.id
  }

  onCloseEdit(refresh: boolean) {
    this.currentEdit = null
    if (refresh) {
      this.refresh()
    }
  }

  showMenu(event: any) {
    event.stopPropagation()
  }

  async save(event?: any) {
    if (this.form.valid) {
      this.set.name = this.form.value.name
      this.set.copyright = this.form.value.copyright
      this.set.labelColumn = this.form.value.labelColumn
      this.set.publicView = this.form.value.publicView
      this.set.planningValueColumn = this.form.value.planningValueColumn
      this.set.planningDataSet = this.form.value.planningDataSet
      this.set.postcodesDataSet = this.form.value.postcodesDataSet
      this.set.autoUpdate = this.form.value.autoUpdate
      this.set.autoUpdateURL = this.form.value.autoUpdateURL
      this.set.categorizeBy = this.form.value.categorizeBy

      this.saving = !this.set.id ? this.dataSetEndpoint.create(this.set) : this.dataSetEndpoint.save(this.set)
      await this.saving
      this.back()
    }
  }

  async updateNow() {
    if (await this.dataSetEndpoint.autoUpdateNow({ id: this.setId })) {
      this.updateNowScheduled = true
    }
  }

  async updatedVersion() {
    this.categories = await (this.loading = this.dataSetEndpoint.getCategories({ id: this.setId }))
  }
}
