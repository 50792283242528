<div class="no-print" style="position: relative; min-height: 50px">
  <div style="margin-bottom: 16px">
    <div class="dialog-bottom" style="text-align: left">
      <button mat-button (click)="dialogRef.close()"><mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span translate="dialog.back">Back</span></button>
    </div>
  </div>

  <div class="secondary-button" *ngIf="available">
    <div fxLayout="row" fxLayoutAlign="stretch center">
      <div *ngIf="showWait" style="margin-right: 12px; margin-top: 14px; color: #999">Please wait, report is being generated...</div>

      <per-capita-households-switch 
        *ngIf="permissions && permissions.householdsCapitaSwitch"
        style="margin-right: 24px; min-width: 200px; max-width: 200px" ></per-capita-households-switch>

      <indices-switch style="margin-right: 24px; min-width: 200px; max-width: 200px" [statistics]="statistics"></indices-switch>

      <button mat-button (click)="exportPdf()">
        <mat-icon mat-font-set="material-icons">picture_as_pdf</mat-icon>
        Download as PDF
      </button>

      <button mat-button (click)="exportBatchPdf()" *ngIf="zoneLayersModel.selectionExistsWithIndividualParts | async">
        <mat-icon mat-font-set="material-icons">picture_as_pdf</mat-icon>
        Download as separate PDFs (one for each zone)
      </button>

      <button mat-button (click)="export(false)">
        <mat-icon mat-font-set="material-icons">file_download</mat-icon>
        Download Report Data
      </button>

      <button mat-button (click)="export(true)" *ngIf="showBenchmark || showCocodimamaBenchmark || showPlanetOrganicBenchmark">
        <mat-icon mat-font-set="material-icons">file_download</mat-icon>
        Download All Report Data
      </button>

    </div>
  </div>
</div>


<section style="margin: 20px;">
  <source-title-panel ></source-title-panel>

  <mat-tab-group (selectedTabChange)="changeTab($event)">
    <mat-tab label="{{(zoneLayersModel.selectionExistsUpdates | async) ? 'Competition by Zone' : 'Competition by Traveltime'}}">
      <competition-maxi-report ></competition-maxi-report>
    </mat-tab>

    <mat-tab *ngIf="showPlanning" label="{{(zoneLayersModel.selectionExistsUpdates | async) ? 'Planning Applications by Zone' : 'Planning Applications by Traveltime'}}">
      <competition-maxi-report type="planningApplication"></competition-maxi-report>
    </mat-tab>

    <mat-tab label="Residents' Demographics">
      <census-mini-report [allColumns]="true" ></census-mini-report>
    </mat-tab>

    <mat-tab label="Residents' Lifestyle Data &amp; Spend">
      <lifestyle-mini-report [allColumns]="true" ></lifestyle-mini-report>
    </mat-tab>

    <mat-tab label="Workforce Demographics" *ngIf="!statistics || statistics.hasWorkforce != false">
      <workforce-mini-report [allColumns]="true" ></workforce-mini-report>
    </mat-tab>

    <mat-tab [disabled]="comparisonDisabled" label="Comparison" *ngIf="permissions && permissions.comparisonReport">
      <maxi-comparison-report></maxi-comparison-report>
    </mat-tab>

    <ng-container *ngIf="!(zoneLayersModel.selectionExistsUpdates | async)">
      <mat-tab [disabled]="benchmarkDisabled" label="Benchmark Report: Chilango" *ngIf="showBenchmark">
        <benchmark-report #benchmarkChilango></benchmark-report>
      </mat-tab>

      <mat-tab [disabled]="benchmarkDisabled" label="Benchmark Report: Coco di Mama" *ngIf="showCocodimamaBenchmark">
        <cocodimama-benchmark-report #benchmarkCocoDiMama></cocodimama-benchmark-report>
      </mat-tab>

      <mat-tab [disabled]="benchmarkDisabled" label="Benchmark Report: Planet Organic" *ngIf="showPlanetOrganicBenchmark">
        <planet-organic-benchmark-report #benchmarkPlanetOrganic></planet-organic-benchmark-report>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</section>

