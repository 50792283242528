
import {map, mergeMap, shareReplay, switchMap, catchError} from 'rxjs/operators';
import { SearchResult, SuggestionResult } from './geosearch.component.type';
import { Observable, of } from 'rxjs';
import { Component, Input, Output, ViewChild, OnInit, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'
import { LatLng, TargomoClient } from '@targomo/core'
import { MatAutocomplete } from '@angular/material'
import { SubscriberComponent } from '@targomo/client'
import { ready } from '../../util/ready.operator'
import { awaitAll } from '../../util/awaitAll.operator'

export * from './geosearch.component.type'
/**
 * Geocoder Search field
 */
@Component({
  selector: 'tgm-custom-geosearch',
  styleUrls: ['./geosearch.component.less'],
  templateUrl: './geosearch.component.html'
})
export class TgmGeosearchComponent extends SubscriberComponent implements OnInit {
  /**
   * An optional function that returns custom search results, otherwise only geocoder is used
   */
  @Input() source: (query: string) => Promise<SearchResult[] | LatLng[]>

  /**
   * Placeholder string for search box
   */
  @Input() placeholder: string = 'Search location...'

  /**
   * Placeholder string for search box
   */
  @Input() searchString: string = ''

  /**
   * Limit results to given country
   */
  @Input() country: string

  /**
   * When given then this is used
   */
  @Input() targomoClient: TargomoClient = new TargomoClient('germany', null) // without region throws error

  /**
   * Reference position for geocoding
   */
  @Input() center: LatLng

  /**
   * photon or esri
   */
  @Input() engine: 'photon' | 'esri' = 'esri'

  /**
   * Language code
   */
  @Input() language: string = 'en'

  /**
   * The selected item from the list
   */
  @Output() selected = new EventEmitter<SearchResult>(null)


  @ViewChild('auto') autocomplete: MatAutocomplete;

  suggestions: Observable<any[]>
  geosearchControl: FormControl;
  loading$: Observable<boolean>

  constructor() {
    super()
    this.geosearchControl = new FormControl()

    const queryCustom = async (query: string): Promise<SearchResult[] | LatLng[]> => {
      if (typeof this.source == 'function') {
        return await this.source(query)
      } else {
        return []
      }
    }

    const queryByEngine = async (value: string) => {
      if (this.engine === 'esri') {
        return await this._getEsriObservable(value)
      } else if (this.engine === 'photon') {
        return await this._getPhotonObservable(value)
      } else {
        return []
      }
    }

    const promises$ = this.geosearchControl.valueChanges.pipe(
      map((value: any) => {
        if (value.length < 3) {
          return [[]] // Only start geocoding when more than 2 chars are in input
        }
        // Combine geoding engine results with custom search results (ex searching through app locations)
        return [
          queryByEngine(value),
          queryCustom(value),
        ]
      }),
      shareReplay(1)
    )

    this.loading$ = promises$.pipe(
      map(async values => await Promise.all(<any[]>values)),
      catchError(() => of([])),
      ready(),
      map(item => !item),
      shareReplay(1)
    )

    this.suggestions = promises$.pipe(
      awaitAll(),
      switchMap(async values => {
        return [].concat.apply([], await Promise.all(values)).filter((item: any) => !!item)
      }))
  }

  private async _getEsriObservable(value: string): Promise<SuggestionResult[]> {
    return (await this.targomoClient.geocoding.esri.suggest(value, this.center, this.language, this.country, 5))
      .filter((location: any) => location.text)
      .map((location: any) =>
        new SuggestionResult(location.text, location.magicKey, value, this.center, this.country)
      )
  }

  private async _getPhotonObservable(value: string): Promise<SearchResult[]> {
    return (await this.targomoClient.geocoding.photon.geocode(value, this.center, this.language))
      .filter((location: any) => // TODO create geojson type in core and add here
        !this.country || (location.properties.country && location.properties.country.toLowerCase() == this.country))
      .map((location: any) => // TODO create geojson type in core and add here
        new SearchResult(
          location.geometry.coordinates[1],
          location.geometry.coordinates[0],
          location.description.title,
          location.description.full))
  }

  ngOnInit() {
    this.watch(this.autocomplete.optionSelected.pipe(mergeMap(async selectedEvent => {
      // If esri, we need to do the magicKey request
      if (this.engine === 'esri') {
        // esri engine, requesting subsequent result
        // only if this is esri suggesion, if custom object retunn directly
        if (selectedEvent.option.value instanceof SuggestionResult) {
          const suggestionResult: SuggestionResult = selectedEvent.option.value
          const result = await this.targomoClient.geocoding.esri.geocode(
            suggestionResult.query,
            suggestionResult.center,
            this.language,
            this.country,
            suggestionResult.magicKey)
          const location = result[0]
          return location && new SearchResult(
            location.lat,
            location.lng,
            location.description,
            location.description)
        } else {
          return <SearchResult>selectedEvent.option.value
        }
      } else {
        // photon engine already has details.
        return <SearchResult>selectedEvent.option.value
      }
    })), value => {
      // I dont like this approach but currently its the only way of supprting event emitter completely... (Jan)
      this.selected.emit(value)
    })
  }

  displayFn = (value: SuggestionResult) => value && value.toString()
}
