import { SelectFasciasDefinition } from '../../../../../common/models/planningApplication'
import { DataSetLike } from '../../../../../common/models/dataSet'
import { CategoryLike } from '../../../../../common/models'

export class SelectFasciasModel {
  constructor(private dataSets: DataSetLike[], private groupings = true) {
    dataSets = dataSets || []
  }

  updateSelected(selectedFascias: SelectFasciasDefinition[]) {
    const selectedMap: any = {}

    if (this.groupings) {
      selectedFascias.forEach((item) => {
        if (!selectedMap[item.dataSetId]) {
          selectedMap[item.dataSetId] = {}
        }

        if (!selectedMap[item.dataSetId][item.grouping]) {
          selectedMap[item.dataSetId][item.grouping] = {}
        }

        selectedMap[item.dataSetId][item.grouping][item.fascia] = true
      })

      this.dataSets.forEach((dataSet) => {
        dataSet.categories.forEach((category: CategoryLike) => {
          category.selected = !!(
            selectedMap[dataSet.id] &&
            selectedMap[dataSet.id][category.grouping] &&
            selectedMap[dataSet.id][category.grouping][category.name]
          )
        })
      })
    } else {
      selectedFascias.forEach((item) => {
        if (!selectedMap[item.dataSetId]) {
          selectedMap[item.dataSetId] = {}
        }

        selectedMap[item.dataSetId][item.fascia] = true
      })

      this.dataSets.forEach((dataSet) => {
        dataSet.categories.forEach((category: CategoryLike) => {
          category.selected = !!(selectedMap[dataSet.id] && selectedMap[dataSet.id][category.name])
        })
      })
    }
  }

  getSelectedFascias(): SelectFasciasDefinition[] {
    const result: SelectFasciasDefinition[] = []

    this.dataSets.forEach((dataSet) => {
      dataSet.categories.forEach((category: CategoryLike) => {
        if (category.selected) {
          result.push({ dataSetId: dataSet.id, fascia: category.name, grouping: category.grouping })
        }
      })
    })

    return result
  }

  getSelectedDataSets(): DataSetLike[] {
    return this.dataSets.filter((dataSet) => {
      return dataSet.categories.find((category: CategoryLike) => {
        return category.selected
      })
    })
  }
}
