import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material';
import { POPULATION_FORECAST_FROM_YEAR, POPULATION_FORECAST_TO_YEAR } from '../../../../../common/models/statistics/statistics'

const FROM_YEAR = POPULATION_FORECAST_FROM_YEAR
const TO_YEAR = POPULATION_FORECAST_TO_YEAR

@Component({
  selector: 'forecast-year-slider',
  templateUrl: './forecastYearSlider.component.html',
  styleUrls: ['./forecastYearSlider.component.less']
})
export class ForecastYearSliderComponent {
  @Input() year = FROM_YEAR
  @Output() onYearChange = new EventEmitter()

  minValue = FROM_YEAR
  maxValue = TO_YEAR

  labelYears: string[] = []

  constructor() {
    this.labelYears = []
    for (let i = FROM_YEAR; i <= TO_YEAR; i++) {
      this.labelYears.push('' + i)
    }
  }

  onSliderChange(event: MatSliderChange) {
    this.onYearChange.emit(event.value)
  }
}
