// const parseCsv = require('csv-parse')
import * as parseCsv from 'csv-parse'
import { ImportColumnsDefinitions, LOCATION_DEFINITIONS } from './columns';
let transform = require('stream-transform/lib/es5/index.js')
import * as moment from 'moment'

const DATE_FORMATS = [
  'DD/MM/YYYY',
  'DD.MM.YYYY',
  'YYYY/MM/DD',
]

function parseDate(value: string): Date {
  for (let i = 0; i < DATE_FORMATS.length; i++) {
    const parsed = moment(value, DATE_FORMATS[i])
    if (parsed.isValid()) {
      return parsed.toDate()
    }
  }

  return moment(value).toDate()
}

function translateHeaders(columns: string[], columnDefinitions: ImportColumnsDefinitions) {
  const foundColumns: {[key: string]: boolean} = {}
  const result = columns.map(item => undefined)
  const COLUMNS = columnDefinitions.columnsSynonyms

  const iterate = (processCallback: (key: string) => string) => {
    columns.forEach((column, i) => {
      const processedColumn = processCallback(column)
      if (COLUMNS[processedColumn] && !foundColumns[COLUMNS[processedColumn]]) {
        result[i] = COLUMNS[processedColumn]
        foundColumns[result[i]] = true
      }
    })
  }

  const processedColumnSimple = (column: string) => column.toLowerCase()
  const processedColumn = (column: string) => (column || '').replace(/[\-_]+/g, '').toLowerCase()
  const processedColumnSpace = (column: string) => (column || '').replace(/[\s\-_]+/g, '').toLowerCase()

  iterate(processedColumnSimple)
  iterate(processedColumn)
  iterate(processedColumnSpace)

  return result
}

export function parse(input: string, callback: (items: any[], columns: string[], translatedColumns: string[]) => Promise<void>, splitByCount = 1000, columnDefinitions = LOCATION_DEFINITIONS) {
  const now = new Date().getTime()
  const COLUMN_TYPES = columnDefinitions.columnsTypes
  // const throttle = new Throttle()

  return new Promise<boolean>(async (resolve, reject) => {
    let output: any[] = []
    let headers: string[]
    let translatedHeaders: string[]
    let total = 0

    const parser = parseCsv({})
    let first: boolean = true

    async function processItems(output: any[]) {
      for (let i = 0; i < output.length; i++) {
        total++

        const error = (text: string) => {
          throw new Error('Line ' + total + ':' + text)
        }

        let row = output[i]
        let object: any = {}
        let other: any = null

        headers.forEach((key, j) => {
          if (key != 'id' && key != 'data_set_id') {
            let value = row[j]
            let translatedKey = translatedHeaders[j]// COLUMNS[key.toLowerCase()]

            if (!translatedKey) {
              // error('Unknown column `' + key + '` found')
              if (!other) {
                other = {}
              }

              if (key in other) {
                error('Duplicate column `' + key + '` found')
              }
                
              other[key] = value
            } else {              
              if (translatedKey in object) {
                error('Duplicate column `' + key + '` found')
              }

              if (COLUMN_TYPES[translatedKey] === 'number') {
                const originalValue = value
                value = (value || '').trim().replace(/,/g, '')

                if (value === '') {
                  value = null
                } else {
                  value = +value

                  if (isNaN(value)) {
                    error('Invalid numerical value `' + originalValue +'` for column `' + key + '`')
                  }

                  if (!!value && !isFinite(value)) {
                    error('Invalid numerical value `' + originalValue +'` for column `' + key + '`')
                  }
                }
              } else if (COLUMN_TYPES[translatedKey] === 'date') {
                const originalValue = value
                value = (value || '').trim()
                const dateValue = value ? parseDate(value) : null
                if (dateValue && isNaN(dateValue.getTime())) {
                  error('Invalid date value `' + originalValue +'` for column `' + key + '`')
                }

                value = dateValue
              }

              if (translatedKey == 'lng') {
                value = +value

                if (value < -14 || value > 3) {
                  error("Invalid lng value `" + value + '` (Coordinates must be lng/lat in 4326)')
                }
              }

              if (translatedKey == 'lat') {
                value = +value

                if (value < 49 || value > 64) {
                  error("Invalid lat value `" + value + '` (Coordinates must be lng/lat in 4326)')
                }
              }

              if (translatedKey == 'lngAlt' && (value !== '' && value != null)) {
                value = +value

                if (value < -14 || value > 3) {
                  error("Invalid lngAlt value `" + value + '` (Coordinates must be lng/lat in 4326)')
                }
              }

              if (translatedKey == 'latAlt' && (value !== '' && value != null)) {
                value = +value

                if (value < 49 || value > 64) {
                  error("Invalid latAlt value `" + value + '` (Coordinates must be lng/lat in 4326)')
                }
              }              

              object[translatedKey] = value
            }
          }
        })

        if (!object.lat || !object.lng) {
          error("Missing lng/lat values")
        }

        object.other = other

        output[i] = object
      }

      await callback(output, headers, translatedHeaders)
    }

    parser.on('error', function(err: any) {
      console.log(err.message)
      reject(err)
    })


    const purgeOutput = async () => {
      // parser.pause()
      const currentOutput = output
      output = []
      await processItems(currentOutput)
      // parser.resume()
      // throttle.next()
    }

    parser.on('end', async function() {
      try {
        await processItems(output)
        // throttle.next()
        resolve(true)
      } catch (e) {
        reject(e)
      }
    })

    parser.on('data', async (record: any) => {
      if (first) {
        headers = record
        translatedHeaders = translateHeaders(headers, columnDefinitions)
        
        // headers.map((key: any) => {
        //   const result = translateColumn(key, foundColumns)
        //   if (result) {
        //     foundColumns[result] = true
        //   }
        //   return result
        // })
        first = false
        return
      } else {
        output.push(record)
      }

      if (output.length >= splitByCount) {
        try {
          await purgeOutput()
        } catch (e) {
          reject(e)
        }
      }
    })

    try {
      const STEP = 500000
      for (let i = 0; i < input.length; i += STEP) {
        parser.write(input.substring(i, i + STEP))
        await new Promise(resolve => setTimeout(resolve, 50))
        await purgeOutput()
      }
    } catch (e) {
      reject(e)
    } finally {
      parser.end()
    }
    
  })
}