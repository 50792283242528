<back-button *ngIf="back"></back-button>
<div style="padding: 0px 16px;" class="details-table">

  <h2 class="mat-title">Location Details</h2>

  <table class="key-value-table">
    <tbody>
      <ng-container *ngFor="let row of detailsMain">
        <tr  *ngIf="!!row.value">
          <td>{{row.name}}</td><td>{{row.value}}</td>
        </tr>
      </ng-container>        
    </tbody>
  </table>

  <h2 [hidden]="!detailsAddress" class="mat-title">Address</h2>

  <table class="key-value-table">
    <tbody>
      <ng-container *ngFor="let row of detailsAddress">
        <tr *ngIf="!!row.value">
          <td>{{row.name}}</td><td>{{row.value}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <h2 [hidden]="!detailsValues && !detailsOther" class="mat-title">Data</h2>

  <table class="key-value-table">
    <tbody>
      <ng-container *ngFor="let row of detailsValues">
        <tr  *ngIf="!!row.value">
          <td>{{row.name}}</td>
          <td *ngIf="!numbers[row.key] && !dates[row.key]">{{row.value}}</td>
          <td *ngIf="numbers[row.key]">{{row.value | number}}</td>
          <td *ngIf="dates[row.key]">{{row.value | date}}</td>
        </tr>
      </ng-container>

      <ng-container *ngFor="let row of detailsOther">
          <tr  *ngIf="!!row.value">
            <td>{{row.name}}</td>
            <td *ngIf="!row.isUrl">{{row.value}}</td>
            <td class="table-link" *ngIf="row.isUrl"><a target="_blank" [href]="row.url">{{row.value}}</a></td>
            <!-- <td *ngIf="numbers[row.key]">{{row.value | number}}</td> -->
          </tr>
        </ng-container>        
    </tbody>
  </table>
</div>

<div style="padding: 32px 16px;" class="details-table">
  <center-point-report-panel></center-point-report-panel>
</div>