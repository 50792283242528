<section style="padding: 0px 16px">
  <div *ngIf="formActive == 'draw'">
    <draw-zone-layer-panel [input]="formInput" (output)="saveDrawnSectorPanel($event)"></draw-zone-layer-panel>
  </div>
    
  <div *ngIf="formActive == 'edit'">
    <save-zone-layer-panel [input]="formInput" (output)="saveSectorPanel($event)"></save-zone-layer-panel>
  </div>

  <div *ngIf="formActive == 'upload'">
    <upload-zone-layer-panel (output)="uploadSectorPanel($event)"></upload-zone-layer-panel>
  </div>
  
  <div *ngIf="!formActive">
    <h3>Zones</h3>
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 16px">
      <!-- <div fxFlex></div> -->
      <div *ngIf="!formActive" fxLayout="row" fxLayoutAlign="end center">
        <button (click)="uploadSector()" class="primary" title="" mat-button><mat-icon class="mat-24">file_upload</mat-icon> Upload Zone</button>
        <button (click)="addSector()" class="primary" title="" mat-button><mat-icon class="mat-24">add</mat-icon> Create Zone</button>
        <button (click)="drawSector()" class="primary" title="" mat-button><mat-icon class="mat-24">create</mat-icon> Freehand Zone</button>
      </div>
    </div>


    <mat-list class="select-layer">
      <div class="select-label">Boundaries layer</div>
      <zone-layer-switch></zone-layer-switch>    
    </mat-list> 

    <!-- <mat-select  [ngModel]="(appModel.settings.displaySettings | async).travelDisplayMode" (ngModelChange)="changeTravelDisplayMode($event)">
      <mat-option *ngFor="let option of travelDisplayModeOptions" [value]="option.value">{{option.name}}</mat-option>
    </mat-select> -->
    
    <mat-list class="layers-list" *ngIf="sectorsModel.sectors | async as sectorsList">
      <mat-list-item [class.disabled]="zone.layer != currentLayer && !zone.freehand" class="region" [class.selected]="zone.selected" *ngFor="let zone of sectorsList" (click)="toggleSector(zone)">
        <div class="list-item-full-center" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon [style.color]="zone.color || 'black'"  class="front-icon mat-24">layers</mat-icon>

          <div fxFlex>
            <div>{{zone.name}}</div>
          </div>

          <button class="delete-button" style="pointer-events: all !important" (click)="deleteSector(zone, $event)" title="Delete zone" mat-icon-button
            ><mat-icon class="mat-24">delete</mat-icon></button>
            <button *ngIf="!zone.children"
              (click)="editSector(zone, $event)" title="Edit zone" mat-icon-button
              ><mat-icon class="mat-24">edit</mat-icon></button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</section>