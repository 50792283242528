
import {AppModel} from '../../model/appModel.service'
import {Indicators, SubscriberComponent} from '@targomo/client'
import {Component} from '@angular/core'
import { GraphValue } from '@targomo/client';
import { BarGraphOptions } from '@targomo/client';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { AllStatistics } from '../../../common/models/statistics/statistics';
import { Auth } from '@targomo/client';
import { map, switchMap, shareReplay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { graphLabelMeasure } from '../util/graphLabelMeasure';
import { TextMeasurer } from '../util/textMeasurer';

const MEASURER = new TextMeasurer('12px normal "Open Sans"')

@Component({
  selector: 'mini-reports-panel',
  templateUrl: './miniReportsPanel.html',
  styleUrls: ['./miniReportsPanel.less']
})
export class MiniReportsPanelComponent extends SubscriberComponent {
  visible: any = {}
  graphPopulationModel: GraphValue[]
  graphWorkforceModel: GraphValue[]
  graphWorkforceOptions: BarGraphOptions
  graphPopulationOptions: BarGraphOptions
  statistics: AllStatistics
  permissions: any

  constructor(private indicators: Indicators, readonly appModel: AppModel, private auth: Auth) {
    super()
    this.initGraphModel()

    this.watch(this.appModel.statistics.census.promise, async promise => {
      this.statistics = await promise
    })
    this.initPermisions()
  }

  private async initPermisions() {
    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
    }
  }

  toggle(sectionName: string) {
    this.visible[sectionName] = !this.visible[sectionName]
  }

  initGraphModel() {
    const graphModels$ = this.appModel.statistics.census.value.pipe(
      switchMap(async statistics => {
        if (statistics) {
          return {
            graphPopulationModel: await this.appModel.statistics.createGraph(statistics.census.population2011.population),
            graphWorkforceModel: await this.appModel.statistics.createGraph(statistics.census.allWorkforce),
          }
        } else {
          return {
            graphPopulationModel: null,
            graphWorkforceModel: null,

          }
        }
      }),
      shareReplay(1)
    )

    this.watch(combineLatest(
      this.appModel.settings.edgeWeightsUpdates,
      graphModels$,
    ), ([colorSet, models]) => {
      this.graphPopulationOptions = new BarGraphOptions({width: 420, axisLabelDistance: 5, xAxisLabel: 'Time in min', yAxisLabel: 'Reachable people', margin: {left: 80}, colorSet})
      this.graphWorkforceOptions = new BarGraphOptions({width: 420, axisLabelDistance: 5, xAxisLabel: 'Time in min', yAxisLabel: 'Reachable workforce', margin: {left: 80}, colorSet})

      this.graphPopulationOptions.margin.left = graphLabelMeasure(MEASURER, models.graphPopulationModel, this.graphPopulationOptions)
      this.graphWorkforceOptions.margin.left = graphLabelMeasure(MEASURER, models.graphWorkforceModel, this.graphWorkforceOptions)
      this.graphPopulationOptions.axisLabelDistance = 20
      this.graphWorkforceOptions.axisLabelDistance = 20
    })

    this.watch(graphModels$, models => {
      this.graphPopulationModel = models.graphPopulationModel
      this.graphWorkforceModel  = models.graphWorkforceModel
    })
  }

  ngOnInit() {
  }
}