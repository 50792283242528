import {Component, EventEmitter, Output, Input, NgZone} from '@angular/core'
import {TgmQuickDialogs, getBasePath, Indicators} from '@targomo/client'
import {Validators, FormGroup, FormBuilder} from '@angular/forms'
import { SavedSessionEndpoint } from '../../../api/savedSession';
import { SavedSessionModel } from '../../../model/savedSessionModel';
import { Router } from '@angular/router';
import { HomeComponent } from '../../../main/home.component';
import { ZoneSelection, ZoneLayersEndpoint } from '../../../api/sectors';
import { OnInit, OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import {files as filesUtil} from '@targomo/client'
import { parseZoneCsv } from './parseZoneCsv';
import { ZoneLayersModel } from '../../../model/zoneLayersModel';
import { AppModel } from '../../../model/appModel.service';

@Component({
  selector: 'upload-zone-layer-panel',
  templateUrl: './uploadZoneLayer.component.html',
  styleUrls: ['./zoneLayerPanel.component.less', './uploadZoneLayer.component.less'],
})
export class UploadZoneLayerComponent implements OnInit, OnDestroy {
  form: FormGroup
  submitted = false
  needsName = false
  sectorsList: any[]
  resultList: ZoneSelection[]

  basePath = getBasePath()
  importSingle = false

  @Output() output = new EventEmitter<ZoneSelection[]>()

  constructor(
    private formBuilder: FormBuilder,
    private home: HomeComponent,
    private router: Router,
    private savedSessionsEndpoint: SavedSessionEndpoint,
    private savedSessionsModel: SavedSessionModel,
    private quickDialogs: TgmQuickDialogs,
    private zone: NgZone,
    readonly sectorsModel: ZoneLayersModel,
    readonly zoneLayersEndpoint: ZoneLayersEndpoint,
    private appModel: AppModel,
    private indicators: Indicators,
  ) {
  }

  ngOnDestroy(): void {
    this.sectorsModel.previewSectors.next(null)
  }

  ngOnInit(): void {
    this.sectorsModel.previewSectors.next([])

    this.form = this.formBuilder.group({
      'name': ['', [Validators.required]],
    })
  }

  async cancel() {
    this.output.next(null)
  }

  async submit() {
    this.submitted = true

    if (this.form.valid || !this.needsName) {
      let zoneResultList = this.resultList

      if (this.resultList.length > 1 && this.importSingle) {
        const allFeaturesMap: {[id: string]: boolean} = {}
        this.resultList.forEach(resultItem => {
          resultItem.features.forEach(id => allFeaturesMap[id] = true)
        })

        zoneResultList = [{
          name: '',
          features: Object.keys(allFeaturesMap),
          layer: this.resultList[0].layer,
          selected: false,
          children: this.resultList
        }]
      }

      if (this.needsName && zoneResultList.length > 0) {
        zoneResultList[0].name = this.form.value.name
      }
      this.output.next(zoneResultList)
    }
  }


  async selectFile(files: File[]) {
    this.needsName = false
    this.sectorsList = null
    this.resultList = null

    const zoneLayer = await this.appModel.settings.zoneLayerUpdates.take(1).toPromise()

    if (!files || !files.length) {
      return
    }

    // this.locationError = false
    try {
      const result = <string>await filesUtil.readFile(files[0])
      // Note....just in case IE, otherwise String.endsWith would do just fine
      const endsWith = (value: string, suffix: string) => {
        return value.lastIndexOf(suffix) === (value.length - suffix.length)
      }

      // let locationsResult: any[] = []
      if (files[0].type === 'text/csv' || endsWith(files[0].name, '.csv')) {
        await new Promise(async (resolve, reject) => {
            const data = await parseZoneCsv(result)

            const columnsOriginal = data[0]
            const columns = columnsOriginal.map(column => (column || '').trim().toLowerCase())
            const rows = data.slice(1)

            if (columns.length === 1) {
              const features = rows.map(row => row[0])
              const result = await this.indicators.add(this.zoneLayersEndpoint.getGeometriesFeatures(zoneLayer, [{key: columns[0], features: features}]))
              const idsMap = result && result.children && result.children[0] && result.children[0] 
                              && result.children[0].features.reduce((acc: any , cur: any) => {
                acc[cur] = true
                return acc
              }, {} as any)

              // this.sectorsModel.editSector(idsMap)

              const previewZones: ZoneSelection[] = result.children.map((child: any, i: number) => {
                return {
                  name: '',
                  features: child.features,
                  layer: zoneLayer,
                  selected: false,
                }
              })

              this.resultList = previewZones
              this.sectorsModel.previewSectors.next(previewZones)

              this.needsName = true
            } else {
              const nameColumn = columns.indexOf('zonegroupname')
              const gidColumn = nameColumn === 1 ? 0 : 1

              const groups = rows.reduce((acc, cur) => {
                if (cur[nameColumn]) {
                  acc[cur[nameColumn]] = acc[cur[nameColumn]] || []
                  acc[cur[nameColumn]].push(cur[gidColumn])
                }
                return acc
              }, {} as any)
              this.needsName = false

              this.sectorsList = Object.keys(groups).sort().filter(name => !!name).map(name => {
                return {
                  name
                }
              })

              const shapes = this.sectorsList.map(sector => {
                return {
                  key: columns[gidColumn],
                  features: (groups[sector.name] || []).filter((item: any) => !!item)
                }
              }).filter(sector => sector.features.length > 0)

              const result = await this.indicators.add(this.zoneLayersEndpoint.getGeometriesFeatures(zoneLayer, shapes))

              const previewZones: ZoneSelection[] = result.children.map((child: any, i: number) => {
                return {
                  name: this.sectorsList[i].name,
                  features: child.features,
                  layer: zoneLayer,
                  selected: false,
                }
              }).filter((sector: ZoneSelection) => sector.name)

              this.resultList = previewZones
              this.sectorsModel.previewSectors.next(previewZones)
            }
          })
      }
    } catch (e) {
      console.error(e)
    }
  }

  updateImportSingle(event: any) {
    this.needsName = event
  }
}
