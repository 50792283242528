import {
  CompositeLayer,
  DefaultMapLayer,
  FeatureInteractionLayer,
  FilterMapLayer,
  FilterMapLayerOptions,
  MapLayerPosition,
  TileMapSource,
} from '@targomo/client'
import { StatisticsKey, TargomoClient } from '@targomo/core'
import { of } from 'rxjs'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Observable } from 'rxjs/Observable'
import { startWith, switchMap, map } from 'rxjs/operators'
import { LocalMapModel } from '../../model/localMapModel'
import { GREAT_BRITAIN_WORKFORCE } from '../../model/constants'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

export const GREAT_BRITAIN_WORKFORCE_VISUAL = GREAT_BRITAIN_WORKFORCE + 3

export class WorkforceComposite extends CompositeLayer {
  private streetsLayer: DefaultMapLayer
  private hoverLayer: FilterMapLayer
  private sourceMapUrl: BehaviorSubject<string>
  private layerOptions: FilterMapLayerOptions
  private filterOptions: FilterMapLayerOptions

  constructor(
    map: CustomMapboxComponent,
    private workforceLayerUpdates: Observable<boolean>,
    private hoverMode: Observable<boolean>,
    private mapModel: LocalMapModel,
    private client: TargomoClient
  ) {
    super(map as any)

    this.init()
    this.initEvents()
  }

  private init() {
    const sourceMapUrl = (this.sourceMapUrl = new BehaviorSubject<string>(
      this.client.config.tilesUrl +
        `/statistics/tiles/v1/${GREAT_BRITAIN_WORKFORCE_VISUAL}/{z}/{x}/{y}.mvt?columns=0&key=` +
        encodeURIComponent(this.client.serviceKey)
    ))
    const source = new TileMapSource(<any>sourceMapUrl.asObservable())

    // Note to self: Don't be fooled by hardcoded values here dummy, they are dummies
    // updateZoom() updates the breakpoints based on the real metadata for each statistic group
    const options = of(null).pipe(
      map((meta) => {
        const breakpoints: number[] = [0, 174991]
        const stops = breakpoints.map((point, i) => {
          const SIZE_RANGE = 28
          const SIZE_START = 2
          const step = SIZE_RANGE / breakpoints.length

          return [point, SIZE_START + i * step]
        })

        return (this.layerOptions = {
          type: 'circle',
          sourceLayer: String(GREAT_BRITAIN_WORKFORCE_VISUAL),
          paint: {
            'circle-color': '#3288bd',
            'circle-opacity': 0.5,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#3288bd',
            'circle-radius': {
              property: '0',
              stops,
            },
          },
        })
      })
    )

    const layer = (this.streetsLayer = new DefaultMapLayer(this.map, source, options).setPosition(
      MapLayerPosition.BELOW_MARKERS
    ))
    layer.setVisible(false)

    const filterOptions: FilterMapLayerOptions = (this.filterOptions = {
      sourceLayer: '21',
      type: 'circle',
      paint: {
        'circle-color': 'rgba(255, 255, 0, 0.7)', //"#3288bd",
        'circle-opacity': 0.5,
        'circle-stroke-width': 1,
        'circle-stroke-color': 'rgba(255, 255, 0, 1)',
        'circle-radius': {
          property: '0',
          stops: [
            [0, 2],
            [54068, 30],
          ],
        },
      },
    })

    this.hoverLayer = new FilterMapLayer(this.map, source, filterOptions).setPosition(MapLayerPosition.BELOW_MARKERS)
    this.hoverLayer.setVisible(false)

    this.updateZoom()
    return layer
  }

  async initEvents() {
    let visible: boolean = false

    this.remember(
      <any>this.mapModel.hoverWorkforce.subscribe((feature) => {
        if (!visible) {
          return
        }

        const list: (string | number)[] = ['in', 'id']

        if (feature) {
          list.push(feature.properties.id)
        }

        this.hoverLayer.setVisible(visible && list.length > 2)
        this.hoverLayer.setFilter(list)
      })
    )

    this.remember(
      <any>Observable.combineLatest(this.workforceLayerUpdates, this.hoverMode).subscribe(([state, hover]) => {
        visible = state && hover

        if (!visible) {
          this.hoverLayer.setVisible(false)
        }

        this.streetsLayer.setVisible(state)
      })
    )

    const interaction = new FeatureInteractionLayer(this.streetsLayer)
    this.watch(interaction.events.hover, (event) => {
      this.mapModel.hoverWorkforce.next(event.feature)
    })

    this.initZoom()
  }

  async initZoom() {
    const map = await this.map.getMap()
    let lastZoom: number = null

    map.on('zoom', async () => {
      const zoomLevel = this.getStatisticsZoom(map.getZoom())
      if (zoomLevel != lastZoom) {
        lastZoom = zoomLevel
        this.updateZoom()
      }
    })
  }

  // question: not correct?
  private getStatisticsZoom(zoom: number): number {
    if (zoom < 8) {
      return GREAT_BRITAIN_WORKFORCE_VISUAL
    } else if (zoom < 11) {
      return GREAT_BRITAIN_WORKFORCE_VISUAL - 1
    } else {
      return GREAT_BRITAIN_WORKFORCE_VISUAL - 2
    }
  }

  private async updateZoom() {
    const map = await this.map.getMap()
    const zoomLevel = this.getStatisticsZoom(map.getZoom())
    const key: StatisticsKey = { id: 0, name: 'all' }
    const metadata = await this.client.statistics.metadataKey(zoomLevel, key)

    const initBreakpoints = () => {
      let breakpoints = metadata.breakpoints.kmeans.c9 || metadata.breakpoints.equal_interval.c9
      return [[0, 2]].concat(breakpoints.map((breakpoint: any, i: number) => [breakpoint, (i + 1) * 1.6]))
    }

    this.layerOptions.sourceLayer = '' + zoomLevel
    this.filterOptions.sourceLayer = '' + zoomLevel

    this.filterOptions.paint['circle-radius'].stops = this.layerOptions.paint['circle-radius'].stops = initBreakpoints()

    this.sourceMapUrl.next(
      this.client.config.tilesUrl +
        '/statistics/tiles/v1/' +
        zoomLevel +
        '/{z}/{x}/{y}.mvt?columns=0&key=' +
        encodeURIComponent(this.client.serviceKey)
    )
    this.streetsLayer.update()
    this.hoverLayer.update()
  }

  setVisible(value: boolean): this {
    throw new Error('Method not implemented.')
  }
  isVisible(): boolean {
    throw new Error('Method not implemented.')
  }
}
