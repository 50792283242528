export const STATISTICS_ALTERNATE_LABELS: {[id: string]: string} = {
'Higher managerial and professional occupations' : 'Higher managerial & professional',
'Lower managerial and professional occupations' : 'Lower managerial & professional',
'Intermediate occupations' : 'Intermediate occupations',
'Small employers and own account workers' : 'Small employers & own account workers',
'Lower supervisory and technical occupations' : 'Lower supervisory & technical occupations',
'Semi-routine occupations' : 'Semi-routine occupations',
'Routine occupations' : 'Routine occupations',
'Never worked and long-term unemployed' : 'Never worked & long-term unemployed',
'NS-SeC not classified' : 'NS-SeC not classified',
'Managers, directors and senior officials' : 'Managers, directors & senior officials',
'Professional occupations' : 'Professional occupations',
'Associate professional and technical occupations' : 'Associate professional & technical',
'Administrative and secretarial occupations' : 'Administrative & secretarial',
'Skilled trades occupations' : 'Skilled trades',
'Caring, leisure and other service occupations' : 'Caring, leisure & other service',
'Sales and customer service occupations' : 'Sales & customer service',
'Process, plant and machine operatives' : 'Process, plant & machine operatives',
'Elementary occupations' : 'Elementary occupations',
'Students' : 'Students',


////


'Agriculture, forestry and fishing' : 'Agriculture, forestry & fishing',
'Mining and quarrying' : 'Mining & quarrying',
'Manufacturing	' : 'anufacturing',
'Electricity, gas, steam and air conditioning supply' : 'Electricity, gas, steam & air conditioning supply',
'Water supply, sewerage, waste management and remediation activities' : 'Water supply, sewerage, waste management…',
'Construction' : 'Construction',
'Wholesale and retail trade; repair of motor vehicles and motor cycles' : 'Wholesale & retail trade; repair of motor vehicles & motor cycles',
'Transport and storage' : 'Transport & storage',
'Accommodation and food service activities' : 'Accommodation & food service activities',
'Information and communication' : 'Information & communication',
'Financial and insurance activities' : 'Financial & insurance activities',
'Real estate activities' : 'Real estate activities',
'Professional, scientific and technical activities' : 'Professional, scientific & technical activities',
'Administrative and support service activities' : 'Administrative & support service activities',
'Public administration and defence; compulsory social security' : 'Public administration & defence; compulsory social security',
'Education' : 'Education',
'Human health and social work activities' : 'Human health & social work activities',
'Arts, entertainment and recreation; other service activities' : 'Arts, entertainment & recreation; other service activities',
'Activities of households as employers; undifferentiated goods - and services - producing activities of households for own use' : 'Activities of households as employer',
'Activities of extraterritorial organisations and bodies' : 'Activities of extraterritorial organisations & bodies',

}