<mat-list>

<mat-list-item class="top-level"  (click)="toggle('visible')">
  <div class="location-item" fxFlex fxLayout="row">
    <mat-icon [class.toggled]="state['visible']" class="animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
    <div fxFlex>Locations Present</div>
  </div>
</mat-list-item>

<div  class="second-level" *ngIf="state['visible']">
  <div style="padding: 8px;" *ngIf="visibleList.length == 0">No locations</div>

  <mat-list-item *ngFor="let category of visibleList">
    <div class="location-item" fxFlex fxLayout="row">
      <span class="checkbox" [style.background]="category.color || '#ddd'"></span>
      <div fxFlex>{{category.name}}</div>
    </div>
  </mat-list-item>
</div>


<mat-list-item class="top-level"  (click)="toggle('invisible')">
    <div class="location-item" fxFlex fxLayout="row">
      <mat-icon [class.toggled]="state['invisible']" class="animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
      <div fxFlex>Locations Not Present</div>
    </div>
  </mat-list-item>
      
  <div  class="second-level" *ngIf="state['invisible']">
    <div style="padding: 8px;" *ngIf="invisibleList.length == 0">No locations</div>

    <mat-list-item *ngFor="let category of invisibleList">
      <div class="location-item" fxFlex fxLayout="row">
        <span class="checkbox" [style.background]="category.color || '#ddd'"></span>
        <div fxFlex>{{category.name}}</div>
      </div>
    </mat-list-item>
  </div>  

</mat-list>
