import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyEndpoint } from '../../../api/company';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriberComponent, Indicators, TgmQuickDialogs } from '@targomo/client';
import { switchMap } from 'rxjs/operators';
import { PlanningApplicationDataSetEndpoint } from '../../../api/planningApplicationDataSet';
import { SelectFasciasModel } from '../components/adminSelectFascias/selectFasciasModel';
import { SelectFasciasDefinition } from '../../../../common/models/planningApplication';
import { APP_BASE_PATH } from '../../../util/basePath';
import { Message, MessagesEndpoint } from '../../../api/message';

@Component({
  selector: 'app-admin-company-edit',
  templateUrl: './adminCompanyEdit.component.html',
  styleUrls: ['./adminCompanyEdit.component.less']
})
export class AdminCompanyEditComponent extends SubscriberComponent implements OnInit {
  company: any = {}
  form: FormGroup
  loading: Promise<any>
  ready = true

  urlLogo: string
  urlCompany: string

  private selectedFascias: SelectFasciasDefinition[]
  availableMessages: Message[]

  constructor(
    private companyEndpoint: CompanyEndpoint,
    private planningEndpoint: PlanningApplicationDataSetEndpoint,
    private route: ActivatedRoute,
    private router: Router,
    private indicators: Indicators,
    private messagesEndpoint: MessagesEndpoint,
    private quickDialogs: TgmQuickDialogs
  ) {
    super()

    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      company: new FormControl({userOrCompany: {}}, []),
    })
  }

  ngOnInit() {    
    const observable$ = this.route.params.pipe(
      switchMap(async params => {
        this.availableMessages = await this.messagesEndpoint.findAll()

        if (params.id === 'new') {
          return {company: null, selectedFascias: null} as any
        } else {
          const [selectedFascias, company] = await this.indicators.add(this.loading = Promise.all([
            this.planningEndpoint.getCompanyFascias({id: params.id}),
            this.companyEndpoint.find({id: +params.id}),
          ]))

          return {selectedFascias, company}
          // new SelectFasciasModel(this.dataSets, false).updateSelected(selectedFascias)

          // return await (this.loading = this.indicators.add(this.companyEndpoint.find({id: +params.id})))
        }
      })
    )

    this.watch(observable$, ({company, selectedFascias}) => {
      company = company || {}
      this.company = company
      this.selectedFascias = selectedFascias || []

      if (this.company.hasCompanyPicture) {
        this.urlCompany  = `${APP_BASE_PATH}api/companies/${this.company.id}/companypicture`
      }

      if (this.company.hasLogo) {
        this.urlLogo  = `${APP_BASE_PATH}api/companies/${this.company.id}/logo`
      }

      this.form = new FormGroup({
        name: new FormControl(company.name || '', [Validators.required]),
        company: new FormControl({userOrCompany: company, selectedFascias}, []),
      })
    })
  }

  async submit() {
    (<any>this.form).submitted = true

    if (this.form.valid) {
      // this.company.dataProfiles = this.dataProfiles.filter(profile => profile.selected).map(profile => profile.id)
      // this.company.geoRegions = this.availableRegions.filter(profile => profile.selected).map(profile => profile.id)
      this.company.name = this.form.value.name

      // const selectedPlanningFascias = new SelectFasciasModel(this.dataSets).getSelectedFascias()

      this.company.permissions = this.company.permissions || {}
      // this.company.permissions.comparisonReportTemplates = this.availableComparisonTemplates.filter(template => template.selected).map(template => template.id)

      if (this.company.id) {
        await this.companyEndpoint.update(this.company)
        await this.planningEndpoint.saveCompanyFascias(<any>this.company, this.form.value.company.selectedFascias || [])

        this.router.navigate(['/admin/companies'])
      } else {
        const result = await this.companyEndpoint.create(this.company)
        if (result) {
          await this.planningEndpoint.saveCompanyFascias(result, this.form.value.company.selectedFascias || [])
        }

        if (result) {
          this.router.navigate(['/admin/companies'])
        }
      }
    }
  }

  onSelectPicture(which: 'companyPicture' | 'logo', property: 'urlCompany' | 'urlLogo', files: FileList) {
    if (!files || !files[0]) {
      this[property] = null
      this.company[which] = null
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      const result: string = <any>reader.result

      this.company[which] = result.substring(result.indexOf(',') + 1)
      this[property] = result
    }

    reader.readAsDataURL(files[0])
  }

  onSelectCompanyPicture(files: FileList) {
    this.onSelectPicture('companyPicture', 'urlCompany', files)
  }

  onSelectLogoPicture(files: FileList) {
    this.onSelectPicture('logo', 'urlLogo', files)
  }

  async messageAssignUsers(message: any) {
    await this.messagesEndpoint.assignCompany(this.company, message)
    this.quickDialogs.snack('Message assigned')
  }

  async messageUnAssignUsers(message: any) {
    await this.messagesEndpoint.unAssignCompany(this.company, message)
    this.quickDialogs.snack('Message un-assigned')
  }  
}
