<section style="width: 80vw; max-width: 350px">
  <h3 mat-dialog-title translate="menu.selectLayerColor">Select travel colours</h3>

  <form (ngSubmit)="save()">
    <div>

    <span [style.background]="colors[selectedIndex]"
      cpWidth="350px"
      [cpToggle]="true"
      [cpDialogDisplay]="'inline'"
      [cpCancelButton]="false"
      [(colorPicker)]="colors[selectedIndex]"></span>
    </div>

    <div>
      <div class="rainbow-container">
        <div class="rainbow-item" 
          (click)="selectColorIndex(i)"
          *ngFor="let item of colors; let i = index"
          [style.background]="item"
          [class.selected]="i == selectedIndex"
        >
        </div>
      </div>          
    </div>

    <div class="dialog-bottom">
      <button mat-button (click)="dialogRef.close()" mat-dialog-close><span translate="dialog.cancel">Cancel</span></button>
      <button mat-raised-button color="primary" mat-button><span translate="dialog.save">Save</span></button>
    </div>
  </form>
</section>
