import { Component, Input, EventEmitter, Output } from '@angular/core'
import { Category } from '../../api/index'
import { CategoryEndpoint } from '../../api/category'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ColorSelectDialog } from '../../main/dialogs/colorSelectDialog/colorSelectDialog.component'
import { MatDialog } from '@angular/material'

@Component({
  selector: 'admin-category-edit',
  templateUrl: './adminCategoryEdit.html',
  styleUrls: ['./adminCategoryEdit.less'],
})
export class AdminCategoryEditComponent {
  @Input() model: Category
  @Output() onClose = new EventEmitter<boolean>()

  user: any = {}
  form: FormGroup
  ready = false

  private options = {
    icon: 'brush',
    default: '#f00',
    openOnInput: false,
    preview: true,
    hasBackdrop: false,
    mdColorAlphaChannel: false,
  }

  constructor(
    private formBuilder: FormBuilder,
    private categoryEndpoint: CategoryEndpoint,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.model = await this.categoryEndpoint.get(this.model)

    this.form = this.formBuilder.group({
      name: [this.model.name, [Validators.required]],
    })

    this.ready = true
  }

  cancel() {
    this.onClose.emit(false)
  }

  async save() {
    if (this.form.valid) {
      this.model.name = this.form.value.name

      await this.categoryEndpoint.save(this.model)
      this.onClose.emit(true)
    }
  }

  async editColor($event: any) {
    $event.preventDefault()

    const dialogRef = this.dialog.open(ColorSelectDialog, {
      disableClose: false,
      data: { value: { color: this.model.color } },
    })

    const result = await dialogRef.afterClosed().toPromise()
    this.model.color = result ? result.color : this.model.color
  }
}
