import { Component, OnInit } from '@angular/core'
import { SettingsModel } from '../../../model/settingsModel'

@Component({
  selector: 'point-colors-options-panel',
  templateUrl: './pointColorsOptionsPanel.component.html',
  styleUrls: ['./pointColorsOptionsPanel.component.less'],
})
export class PointColorsOptionsPanelComponent {
  constructor(readonly settings: SettingsModel) {}

  ngOnInit() {}

  changeSelection(value: any) {
    this.settings.displaySettings.nextProperty('showCustomCategoryColors', value)
  }
}
