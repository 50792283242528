import {Settings, UserEventLog} from '../../common/models'
import {RESTHelper} from '@targomo/client';
import {Injectable} from '@angular/core';
import { Place } from './place';
import { LatLngId } from '@targomo/core';
import { Category } from './category';
import { UserEventLogType } from '../../common/models/userEventLog';

export type Settings = Settings

interface Paginated<T> {
  rows: T[]
  count: number
}

@Injectable()
export class UserEventLogEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/userEventLog')
  }

  async events(type: number, pagination: {page: number, size: number}) {
    const result = await this.http.get<Paginated<UserEventLog>>(`/${type}/pagination/${pagination.page}/${pagination.size}`)
    if (result) {
      result.rows.forEach(item => {
        if (item.created) {
          item.created = new Date(item.created)
        }
      })
    }
    return result
  }

  async userReport(from: Date, to: Date, forProjectNumbers: boolean) {
    from.setMinutes(0)
    from.setHours(0)
    from.setSeconds(0)

    to.setMinutes(59)
    to.setHours(23)
    to.setSeconds(59)

    return await this.http.get<UserEventLog[]>(`/userReport/${forProjectNumbers ?  'project/' : ''}${from && from.getTime()}/${to && to.getTime()}`)
  }

  async log(type: number, data?: any) {
    await this.http.post<{}>(`/`, {type, data})
  }

  async logDataLayerActivated(category: Category) {
    return this.log(UserEventLogType.DATA_LAYER_ACTIVATED)
  }

  async logMapCreated() {
    return this.log(UserEventLogType.MAP_CREATED)
  }

  async logGapReportCreated() {
    return this.log(UserEventLogType.MAP_CREATED)
  }  

  async logMapImageCreated() {
    return this.log(UserEventLogType.MAP_IMAGE_CREATED)
  }

  async maxiReportViewed() {
    return this.log(UserEventLogType.MAXI_REPORTS_VIEWED)
  }

  async pdfDownloaded() {
    return this.log(UserEventLogType.PDF_REPORT_DOWNLOADED)
  }

  async travelTimesRun() {
    return this.log(UserEventLogType.TRAVEL_TIME_RUN)
  }

  async logHeartbeat(session: number) {
    return await this.http.post<number>(`/`, {z: session})
  }

  async logLayersCreated(count: number) {
    return this.log(UserEventLogType.DATA_LAYER_ACTIVATED, {count})
  }

  async matchpointViewed() {
    return this.log(UserEventLogType.MATCHPOINT_DIALOG_OPENED)
  }

  async careReportPdfDownloaded() {
    return this.log(UserEventLogType.CARE_REPORT_PDF_DOWNLOADED)
  }  
}