import {
  CompositeLayer,
  DefaultMapLayer,
  DefaultMapLayerOptions,
  FilterMapLayer,
  FilterMapLayerOptions,
  getBasePath,
  MapCellEvent,
  MapLayerPosition,
  TileMapSource,
} from '@targomo/client'
import { BoundingBox } from '@targomo/core'
import * as scales from 'd3-scale-chromatic'
import { combineLatest, of, Subject } from 'rxjs'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Observable } from 'rxjs/Observable'
import { catchError, debounceTime, filter, map, shareReplay, switchMap } from 'rxjs/operators'
import { RoadsVolumeEndpoint } from '../../api/roadsVolume'
import { LocalMapModel } from '../../model/localMapModel'
import { PlacesModel } from '../../model/placesModel'
import { ExtenedInteractionLayer } from './extendedInteractionLayer'
import { LabelsLayer } from './labels-layer'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

const DEFAULT_BREAKS = [0, 2908, 5626, 8342, 10671, 13236, 15825, 19036, 24036, 34572, 216108]
const MIN_ZOOM = 7

class RoadsTileMapSource extends TileMapSource {
  getMapBoxObject() {
    const source: any = super.getMapBoxObject()
    source.promoteId = 'id'
    return source
  }
}

export class RoadsVolumeComposite extends CompositeLayer {
  readonly events: {
    readonly context: Observable<MapCellEvent>
  } = {
    context: new Subject<MapCellEvent>(),
  }

  private streetsLayer: DefaultMapLayer
  private hoverLayer: FilterMapLayer
  private labelLayer: any

  constructor(
    map: CustomMapboxComponent,
    private visibilityUpdates: Observable<boolean>,
    private hoverMode: Observable<boolean>,
    private mapModel: LocalMapModel,
    private bounds$: Observable<BoundingBox>,
    private roadsVolumeEndpoint: RoadsVolumeEndpoint,
    private roadsVolumeLayerViewportBreaksUpdates$: Observable<boolean>,
    private places: PlacesModel
  ) {
    super(map as any)

    this.init()
    this.initEvents()
  }

  private initScaleFetch() {
    return combineLatest(this.visibilityUpdates, this.bounds$, this.roadsVolumeLayerViewportBreaksUpdates$).pipe(
      debounceTime(300),
      switchMap(async ([visible, bounds, viewPortBreaks]) => {
        const zoom = this.map.getZoom()
        if (!visible || zoom < MIN_ZOOM || !viewPortBreaks) {
          return DEFAULT_BREAKS
        }

        try {
          return await this.roadsVolumeEndpoint.breakpoints(bounds, Math.floor(zoom))
        } catch (e) {
          return DEFAULT_BREAKS
        }
      }),
      catchError(() => of(DEFAULT_BREAKS)),
      filter((breaks) => breaks && breaks.length > 0),
      shareReplay(1)
    )
  }

  private createColorScale(breaks: number[]) {
    return breaks.reduce((acc, cur, i) => {
      acc.push(cur)
      acc.push(scales.interpolateSpectral(1 - i / (breaks.length - 1)))
      return acc
    }, [])
  }

  private init() {
    const sourceMapUrl = new BehaviorSubject<string>(getBasePath() + 'api/thematic/roads/tiles/{z}/{x}/{y}.mvt')
    const source = new RoadsTileMapSource(<any>sourceMapUrl.asObservable())
    const options$: Observable<DefaultMapLayerOptions> = this.initScaleFetch().pipe(
      map((breaks: number[]) => {
        breaks = Array.from(new Set(breaks.map((v) => +v))).sort((a, b) => a - b)

        return {
          type: 'line',
          sourceLayer: '0',
          minzoom: MIN_ZOOM,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': [
              'to-color',
              [
                'interpolate-hcl',
                ['linear'],
                ['to-number', ['get', 'all_motor_vehicles']],
                ...this.createColorScale(breaks),
              ],
            ],
            'line-width': 4,
          },
        }
      })
    )

    const layer = (this.streetsLayer = new DefaultMapLayer(this.map, source, options$).setPosition(
      MapLayerPosition.BELOW_MARKERS
    ))

    layer.setVisible(false)

    const filterOptions: FilterMapLayerOptions = {
      sourceLayer: '0',
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'rgba(255, 255, 0, 0.9)',
        'line-width': 4,
      },
    }

    this.hoverLayer = new FilterMapLayer(this.map, source, filterOptions).setPosition(MapLayerPosition.BELOW_MARKERS)
    this.hoverLayer.setVisible(false)

    const style = of({
      sourceLayer: '0',
      minzoom: 5,
      layout: {
        'icon-image': 'car-side-solid-border',
        'symbol-placement': 'point',
        'text-field': ['number-format', ['to-number', ['get', 'all_motor_vehicles']], { locale: 'en' }],
        // "text-max-width": 100,
        'text-allow-overlap': true, // !value,
        // "symbol-spacing": 150,
        // "text-line-height": 12,
        'symbol-avoid-edges': false,
        'text-size': 14,
        'icon-size': 0.12,
        'text-anchor': 'left',
        'text-offset': [1, 0],
        'text-font': ['Open Sans Bold'],
        'text-justify': 'auto',
        'text-variable-anchor': [
          'left',
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right',
          'right',
          'top',
          'bottom',
          'center',
        ],
      },
      paint: {
        'text-color': '#444444',
        'text-halo-color': '#cccccc',
        'text-halo-width': 0.8,
      },
    })

    const filter = this.places.labelRoadLink.observable.pipe(
      map((item: any[]) => {
        return ['in', 'id'].concat(item)
      })
    )

    this.labelLayer = new LabelsLayer(this.map as any, source as any, style, filter).setPosition(
      MapLayerPosition.SOURCES
    )
    this.hoverLayer.setVisible(false)
  }

  async initEvents() {
    let visible: boolean = false

    this.watch(this.mapModel.hoverRoadsVolume, (feature) => {
      if (!visible) return

      const list: (string | number)[] = ['in', 'id']

      if (feature) {
        list.push(feature.properties.id)
      }

      this.hoverLayer.setVisible(visible && list.length > 2)
      this.hoverLayer.setFilter(list)
    })

    this.watch(Observable.combineLatest(this.visibilityUpdates, this.hoverMode), ([state, hover]) => {
      visible = state && hover

      if (!visible) {
        this.hoverLayer.setVisible(false)
      }

      this.streetsLayer.setVisible(state)
      this.labelLayer.setVisible(state)
    })

    const interaction = new ExtenedInteractionLayer(this.streetsLayer)
    this.watch(interaction.events.hover, (event) => {
      this.mapModel.hoverRoadsVolume.next(event.feature)
    })

    const interactionBoth = new ExtenedInteractionLayer([this.streetsLayer, this.labelLayer])

    this.watch(interactionBoth.events.click, (event) => {
      this.mapModel.clickRoadsVolume.next(event.feature)
    })

    this.watch(interactionBoth.events.context, (event) => {
      ;(this.events.context as Subject<MapCellEvent>).next(event)

      this.mapModel.contextRoadsVolume.next(event.feature)
    })
  }

  setVisible(value: boolean): this {
    throw new Error('Method not implemented.')
  }
  isVisible(): boolean {
    throw new Error('Method not implemented.')
  }
}
