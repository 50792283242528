import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'share-session-dialog',
  templateUrl: './shareSessionDialog.component.html',
  styleUrls: ['./shareSessionDialog.component.less']
})
export class ShareSessionDialogComponent implements OnInit {
  email = ''

  constructor(readonly dialog: MatDialogRef<ShareSessionDialogComponent>,) { }

  ngOnInit() {
  }

}
