import {Component, EventEmitter, Output, Input, NgZone} from '@angular/core'
import {TgmQuickDialogs, SubscriberComponent} from '@targomo/client'
import {Validators, FormGroup, FormBuilder} from '@angular/forms'
import { SavedSessionEndpoint } from '../../../api/savedSession';
import { SavedSessionModel } from '../../../model/savedSessionModel';
import { Router } from '@angular/router';
import { HomeComponent } from '../../../main/home.component';
import { ZoneSelection, ZoneLayersEndpoint } from '../../../api/sectors';
import { OnInit, OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { MapboxDrawLayer } from '../../../main/map/mapboxDrawLayer';
import * as turf from '@turf/helpers'
import { LabelType } from 'ng5-slider';

import distance from '@turf/distance'
import * as turfHelpers from '@turf/helpers'
import circle from '@turf/circle'

@Component({
  selector: 'draw-zone-layer-panel',
  templateUrl: './drawZoneLayer.component.html',
  styleUrls: ['./drawZoneLayer.component.less'],
})
export class DrawZoneLayerComponent extends SubscriberComponent implements OnInit, OnDestroy {
  form: FormGroup
  submitted = false

  mapDrawLayer: MapboxDrawLayer
  currentMode: string

  @Input() input: ZoneSelection = <any>{}
  @Output() output = new EventEmitter<ZoneSelection>()

  isCircle = false
  circleRadius = 2
  currentFeatureCollection: any

  sliderOptions = {
    floor: 0.1,
    ceil: 10,
    step: 0.1,
    showSelectionBar: true,
    animate: false,
    translate: (value: number, label: LabelType): string => {
        return value + ' km';
    }
  }  

  constructor(
    private formBuilder: FormBuilder,
    private home: HomeComponent,
    private router: Router,
    private savedSessionsEndpoint: SavedSessionEndpoint,
    private savedSessionsModel: SavedSessionModel,
    private quickDialogs: TgmQuickDialogs,
    private zoneLayersEndpoint: ZoneLayersEndpoint,
  ) {
    super()
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      'name': [this.input && this.input.name || '', [Validators.required]],
    })

    this.mapDrawLayer = new MapboxDrawLayer(this.home.mapView, {
      displayControlsDefault: false
    })

    this.watch(this.mapDrawLayer.currentMode$, mode => {
      this.currentMode = mode
    })

    this.watch(this.mapDrawLayer.editUpdate$, collection => {
      this.currentFeatureCollection = collection

      if (collection && collection.features && collection.features.length === 1) {
        this.isCircle = collection.features[0].properties.isCircle
        if (isFinite(collection.features[0].properties.radiusInKm)) {
          this.circleRadius = collection.features[0].properties.radiusInKm
        }
      } else {
        this.isCircle = false
      }
    })

    this.mapDrawLayer.modeDraw()

    if (this.input.geometry) {
      this.mapDrawLayer.setFeatures(this.input.geometry)
    }
  }

  ngOnDestroy() {
    if (this.mapDrawLayer) {
      this.mapDrawLayer.removeControl()
    }
  }

  async cancel() {
    this.output.next(null)
  }

  async submit() {
    this.submitted = true

    if (this.form.valid) {
      const featureCollection = this.mapDrawLayer.getCombinedGeometry()
      const polygonsFeature = featureCollection 
      // const featureCollection = this.mapDrawLayer.getFeatures()
      // const features = featureCollection && featureCollection.features || []
      // let polygons: any[] = []

      // features.forEach((feature: any) => {
      //   if (feature.geometry.type.toLowerCase() === 'polygon') {
      //     polygons.push(feature.geometry.coordinates)
      //   } else {
      //     polygons = polygons.concat(feature.geometry.coordinates)
      //   }
      // })

      // const polygonsFeature = turf.multiPolygon(polygons)

      this.input.geometry = await this.zoneLayersEndpoint.getGeometriesGeojson(polygonsFeature)
      this.input.name = this.form.value.name
      this.input.freehand = true

      this.output.next(this.input)
    }
  }

  delete() {
    this.mapDrawLayer.delete()
  }

  deleteAll() {
    this.mapDrawLayer.deleteAll()
  }

  select() {
    this.mapDrawLayer.modeSelect()
  }

  selectNode() {
    this.mapDrawLayer.modeNodeSelect()
  }

  toggle() {
    this.mapDrawLayer.modeDraw()
  }

  drawCircle() {
    this.mapDrawLayer.modeDrawCircle()
  }  

  circleRadiusChange(event: any) {
    this.mapDrawLayer.setCircleRadius(event)
  }
}
