<h2 class="mat-title">Care Home REport</h2>

<tgm-loadable [model]="loading">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="ready">
    <h3 class="mat-title subtitle">Care homes per population ratios:</h3>

    <ng-container *ngFor="let formItem of ageColumns">
      <div fxLayout="column">
        <div>
          <mat-form-field style="width: 100%; display: block">
            <input [placeholder]="formItem.label" matInput type="number" [formControlName]="formItem.key">
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-flat-button color="primary" type="submit"><span>Save</span></button>
    </div>
  </form>
</tgm-loadable>