

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Indicators } from '@targomo/client';
import { TgmQuickDialogs } from '@targomo/client';
import { MatCheckboxChange } from "@angular/material";
import { Router } from '@angular/router';
import { VersionsEndpoint } from '../../../../api/types';

@Component({
  selector: 'admin-versions',
  templateUrl: './adminVersions.component.html', 
  styleUrls: ['./adminVersions.component.less']
})
export class AdminVersionsComponent {
  @Input() private id: number
  @Input() endpoint: VersionsEndpoint<any, any>
  @Input() listUrl: string
  @Output() private updated = new EventEmitter()

  versions: any[] = []
  loading: Promise<any[]>

  constructor(
    private indicators: Indicators, 
    private quickDialogs: TgmQuickDialogs, 
    private router: Router) {
  }

  async ngOnInit() {
    this.versions = await this.indicators.add((this.loading = this.endpoint.getVersions({id: +this.id})))
  }

  async togglePreview(version: any, value: MatCheckboxChange) {
    await this.endpoint.savePreview(version, value.checked)
    this.updated.emit(true)
    this.ngOnInit()
  }

  async toggleActive(version: any, value: MatCheckboxChange) {
    await this.endpoint.saveActive(version, value.checked)
    this.ngOnInit()
  }

  async deleteVersion(version: any) {
    if (version.active || version.preview) {
      this.quickDialogs.snack('Active and Preview versions can\'t be deleted')
      return
    }

    const result = await this.quickDialogs.confirm({
      message: 'Permanently delete this version?',
      confirm: 'Delete',
      cancel: 'Cancel'
    })

    if (result) {
      const deleteResult = await this.endpoint.deleteVersion(version)
      if (deleteResult) {
        this.quickDialogs.snack('Version deleted')
        this.ngOnInit()
      } else {
        this.quickDialogs.snack('Delete failed')
      }
    }
  }

  async deleteSet() {
    const set = await this.endpoint.get({id: this.id})

    if (await this.quickDialogs.confirm({
      title: 'Delete Zone',
      message: `Would you like to delete ${set.name}?`,
      confirm: 'Delete',
      cancel: 'Cancel'
    })) {
      await this.endpoint.delete({id: this.id})
      this.quickDialogs.snack(`${set.name} deleted`)
      this.router.navigate([this.listUrl])
    }
  }
}