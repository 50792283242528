import { Component, OnInit, Input } from '@angular/core';
import { MatchpointModel } from '../../../model/matchpoint/matchpointModel';
import { Place } from '../../../api/place';
import { SubscriberComponent } from '@targomo/client';
import { MatTableDataSource, Sort } from '@angular/material';
import {array as arrays} from '@targomo/common'

@Component({
  selector: 'matchpoint-subject-centre-operators',
  templateUrl: './matchpointSubjectCentreOperators.component.html',
  styleUrls: ['./matchpointSubjectCentreOperators.component.less']
})
export class MatchpointSubjectCentreOperatorsComponent extends SubscriberComponent implements OnInit {
  @Input() readonly model: MatchpointModel

  places: Place[]
  dataSource: MatTableDataSource<Place>
  sort: Sort
  displayedColumns = ['name', 'secondaryCategory']

  constructor() {
    super()
  }

  async ngOnInit() {
    this.watch(this.model.subjectOperators$, places => {
      this.dataSource = new MatTableDataSource(places)
      this.places = places
      this.updatePage()
    })
  }

  sortData(sort: Sort) {
    this.sort = sort
    this.updatePage()
  }

  private updatePage() {
    if (this.places) {
      if (this.sort) {        
        this.places = arrays.sortBy(this.places, this.sort.active, this.sort.direction === 'desc')
      } else {
        this.places = arrays.sortBy(this.places, 'secondaryCategory')
      }

      this.dataSource = new MatTableDataSource(this.places)
    }
  }    
}
