import { TravelType } from '@targomo/core'

export function travelLabel(travelType: TravelType) {
  switch(travelType) {
    case 'walk': return 'Walk-'
    case 'car': return 'Drive-'
    case 'transit': return 'Public Transport-'
    case 'bike': return 'Cycle-'
    default: return ''
  }
}