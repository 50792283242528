<tgm-toolbar class="vertical full-height-vh">
  <tgm-toolbar-button
    [class.active]="isTabActive(0)"
    title="Data Layers"
    (click)="showTabPanel(0)"
    routerLinkActive="active"
  >
    <mat-icon>layers</mat-icon>
  </tgm-toolbar-button>

  <tgm-toolbar-button
    [class.active]="isTabActive(1)"
    style="position: relative"
    title="Mini Reports"
    (click)="showTabPanel(1)"
    routerLinkActive="active"
  >
    <mat-icon>assessment</mat-icon>
    <span *ngIf="sources && sources.length" class="counter">{{sources && sources.length}}</span>
  </tgm-toolbar-button>

  <tgm-toolbar-button
    *ngIf="permissions && permissions.zones"
    (click)="sidenavOpen()"
    title="Zones"
    routerLink="/zones"
    routerLinkActive="active"
  >
    <mat-icon>edit_location</mat-icon>
    <span *ngIf="zoneLayersModel.selectionExistsUpdates | async" class="counter">✔</span>
  </tgm-toolbar-button>

  <tgm-toolbar-button
    [hidden]="(!sources || sources.length == 0) && !(zoneLayersModel.selectionExistsUpdates | async)"
    title="Clear Selected Travel-Times"
    (click)="clearSelected()"
  >
    <mat-icon>delete</mat-icon>
  </tgm-toolbar-button>

  <tgm-toolbar-button
    *ngIf="permissions && permissions.riskAssessment"
    (click)="sidenavOpen()"
    title="Risk Assessment Report"
    routerLink="/riskAssessment"
    routerLinkActive="active"
  >
    <mat-icon>traffic</mat-icon>
    <span *ngIf="appModel.places.selectedPlacePlanningRisk | async" class="counter">✔</span>
  </tgm-toolbar-button>

  <tgm-toolbar-button
    title="{{(appModel.settings.showLabelsUpdates | async)  ? 'Hide' : 'Show'}} Location Labels"
    (click)="toggleLabels()"
  >
    <mat-icon><i class="material-icons">font_download</i></mat-icon>
    <span *ngIf="appModel.settings.showLabelsUpdates | async" class="counter">✔</span>
  </tgm-toolbar-button>

  <tgm-toolbar-button (click)="sidenavOpen()" title="Save Session" routerLink="/saveSession" routerLinkActive="active">
    <mat-icon>save</mat-icon>
  </tgm-toolbar-button>

  <tgm-toolbar-button
    (click)="sidenavOpen()"
    title="Load Saved Session"
    routerLink="/loadSession"
    routerLinkActive="active"
  >
    <mat-icon>folder</mat-icon>
  </tgm-toolbar-button>

  <tgm-toolbar-button hasAuthority="admin" title="Admin Settings" routerLink="/admin/users" routerLinkActive="active">
    <mat-icon>supervisor_account</mat-icon>
  </tgm-toolbar-button>

  <tgm-toolbar-button [mat-menu-trigger-for]="menuPrint" title="Export">
    <mat-icon>get_app</mat-icon>
  </tgm-toolbar-button>

  <tgm-toolbar-button
    (click)="sidenavOpen()"
    title="Data Exports in Progress"
    routerLink="/exportData"
    routerLinkActive="active"
    *ngIf="batchStatisticsModel.hasExports$ | async"
  >
    <mat-icon>hourglass_full</mat-icon>
    <span class="counter">{{batchStatisticsModel.exportsCount$ | async}}</span>
  </tgm-toolbar-button>

  <tgm-toolbar-button *ngIf="settings && settings.helpUrl" title="Help">
    <a class="help-link" [href]="settings.helpUrl" target="_blank">
      <mat-icon>help</mat-icon>
    </a>
  </tgm-toolbar-button>

  <tgm-toolbar-button
    [class.active]="isTabActive(2)"
    title="Map Options"
    (click)="showOptions()"
    routerLinkActive="active"
  >
    <mat-icon>settings</mat-icon>
  </tgm-toolbar-button>

  <div style="opacity: 0" fxFlex></div>

  <tgm-toolbar-button (click)="logout()" title="Logout">
    <mat-icon>exit_to_app</mat-icon>
  </tgm-toolbar-button>
</tgm-toolbar>

<mat-menu #menuPrint="matMenu">
  <button
    [disabled]="(!sources || sources.length == 0) && !(zoneLayersModel.selectionExistsUpdates | async)"
    mat-menu-item
    (click)="exportMainPdfReport()"
  >
    Full PDF Report
  </button>
  <button
    [disabled]="(!sources || sources.length == 0) && !(zoneLayersModel.selectionExistsUpdates | async)"
    mat-menu-item
    (click)="exportReportData()"
  >
    Report Data
  </button>

  <button
    [disabled]="(!sources || sources.length == 0) && !(zoneLayersModel.selectionExistsUpdates | async)"
    mat-menu-item
    (click)="exportIndividualData()"
  >
    All Data Download
  </button>

  <button
    [disabled]="(!sources || sources.length == 0) && !(zoneLayersModel.selectionExistsUpdates | async)"
    mat-menu-item
    (click)="exportIndividualCompetitors()"
  >
    All Competitors Download
  </button>

  <button
    [disabled]="(!sources || sources.length == 0) && !(zoneLayersModel.selectionExistsUpdates | async)"
    *ngIf="permissions?.careHome"
    mat-menu-item
    (click)="exportCareHomeReport()"
  >
    Care Home Report
  </button>
  <button *ngIf="permissions?.locationsInView" mat-menu-item (click)="exportGapReport()">Gap Analysis Report</button>
  <button mat-menu-item (click)="printMap()">PDF Map with Legend</button>
  <button mat-menu-item (click)="exportMapImage()">PNG Map Image</button>
  <button mat-menu-item (click)="shareableLink()">Shareable Link to Current Map</button>
</mat-menu>

<div class="maplayout-wrapper">
  <simple-map-layout
    #mapLayout
    [options]="options"
    [customMaxEdgeWeight]="appModel.settings.customReportMaxEdgeWeightUpdates | async"
    [styleOptions]="layoutOptions | async"
    [travelOptions]="appModel.settings.travelOptionsUpdates | async"
    [class.hiddenIdentify]="!showHoverButton"
    (travelOptionsChange)="travelChange($event)"
  >
    <!-- <div map-layout-top-left-container>
      <button *ngIf="!mapLayout.isSideBarOpened" (click)="sidenavOpen()" color="primary" mat-fab><mat-icon md-font-set="material-icons">menu</mat-icon></button>
  </div> -->

    <!-- class for transclusion -->
    <div map-layout-sidenav-main>
      <router-outlet></router-outlet>

      <div [hidden]="!mainLayersVisible">
        <div class="geosearch-container">
          <tgm-custom-geosearch
            #geosearch
            placeholder="Search Location..."
            [source]="searchSource"
            country="GB,IE"
            (selected)="selectSearch($event)"
          ></tgm-custom-geosearch>

          <ng-container *ngIf="appModel.places.temporaryLocation | async">
            <search-temporary-location></search-temporary-location>
          </ng-container>
        </div>

        <div class="disabled-statistics" *ngIf="appModel.settings.disableStatisticsUpdates | async">
          Warning: Statistics are disabled
        </div>

        <section class="sidenav-content">
          <sidetabs [currentTab]="currentTab" (uploadLayer)="showPanel('/uploadLayer')"></sidetabs>
        </section>
      </div>
    </div>

    <!-- class for transclusion -->
    <!-- <div map-layout-sidenav-details>
      <div style="padding: 0px 20px">
        <button mat-button (click)="showMainView()"><mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span translate="dialog.back">Back</span></button>
        <place-details></place-details>
      </div>
  </div>

  <div map-layout-sidenav-options>
    <div style="margin: 8px; text-align: left">
      <button mat-button (click)="showMainView()"><mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span translate="dialog.back">Back</span></button>

      <div *ngIf="panelView == 'uploadLayer'">
        <upload-layer (close)="showMainView()"></upload-layer>
      </div>
    </div>
  </div> -->

    <!-- class for transclusion -->
    <div map-layout-extra-fabs>
      <div class="extra-fabs-container" fxFlex fxLayout="column" fxLayoutAlign="start end">
        <button
          title="Map Cell Information"
          (click)="toggleCellHover()"
          mat-fab
          [disableRipple]="true"
          [color]="(appModel.settings.cellHoverUpdates | async) ? 'accent' : 'primary'"
          *ngIf="showHoverButton"
        >
          <mat-icon class="mat-24">info_outline</mat-icon>
        </button>

        <!-- <button title="Settings" hasAuthority="admin" mat-fab [disableRipple]="true" routerLink="/admin/users" color="primary">
        <mat-icon class="mat-24">supervisor_account</mat-icon>
      </button> -->

        <select-tool [mapView]="mapView"></select-tool>

        <button
          title="Point and Click Mode"
          (click)="togglePointAndClick()"
          mat-fab
          [disableRipple]="true"
          [color]="(appModel.settings?.pointAndClickModeUpdates | async) ? 'accent' : 'primary'"
        >
          <mat-icon class="mat-24">ads_click</mat-icon>
        </button>

        <button
          [disabled]="!(appModel.places?.matchpointAvailable$ | async)"
          title="Matchpoint"
          (click)="showMatchpointDialog()"
          mat-fab
          [disableRipple]="true"
          color="primary"
          *ngIf="permissions?.matchpoint"
        >
          <mat-icon class="mat-24">compare_arrows</mat-icon>
        </button>

        <!-- <button title="Print" mat-fab [disableRipple]="true" (click)="printMap()" color="primary">
        <mat-icon class="mat-24">print</mat-icon>
      </button>

      <button title="Logout" mat-fab [disableRipple]="true" (click)="logout()" color="primary">
        <mat-icon class="mat-24">exit_to_app</mat-icon>
      </button> -->
      </div>
    </div>

    <div map-bottom-container>
      <div class="legend-container">
        <tgm-map-scale #mapScale [menuOptions]="mapScaleOptions" [mapView]="mapView"></tgm-map-scale>
      </div>
    </div>

    <!-- class for transclusion -->
    <div map-layout-context-menu>
      <map-menu #mapMenu></map-menu>
    </div>

    <div map-layout-extra-mini-fabs>
      <div class="attribution" fxLayout="row" fxLayoutAlign="center center">
        <span>{{copyright}}, </span>
        <span class="mi-attribution">developed by <a href="http://www.targomo.com/" target="_blank">Targomo</a>, </span>
        <a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a>
        <a href="http://www.openstreetmap.org/about/" target="_blank">, © OpenStreetMap contributors</a>
      </div>
    </div>
  </simple-map-layout>

  <app-message-popup></app-message-popup>
  <chat-panel></chat-panel>
</div>
