export const DEFAULT_COPYRIGHT = 'www.storepointgeo.com © Aspinall & Aspinall 2021'

export const DEFAULT_MAP_ATTRIBUTION = '© MapTiler © OpenStreetMap contributors'

export interface CareHomeBedFactorsValues {
  agesUnder65?: number
  ages65to69?: number
  ages70to74?: number
  ages75to79?: number
  ages80to84?: number
  ages85to89?: number
  ages90plus?: number
}

export interface SettingsCareHome {
  ratios: CareHomeBedFactorsValues
}
export interface Settings {
  hasLogo?: boolean

  copyright: string
  logo: string
  helpUrl: string
  gbpToEuroRate: number
  careHomeReport: SettingsCareHome
}
