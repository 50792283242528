import { Component, EventEmitter, Output } from '@angular/core'
import { Indicators, TgmQuickDialogs, SubscriberComponent } from '@targomo/client'
import { SavedSession, SavedSessionEndpoint } from '../../../api/savedSession'
import { SavedSessionModel } from '../../../model/savedSessionModel'
import { Router } from '@angular/router'
import { HomeComponent } from '../../../main/home.component'
import { MatDialog } from '@angular/material'
import { ShareSessionDialogComponent } from '../shareSessionDialog/shareSessionDialog.component'
import { SettingsModel } from '../../../model/settingsModel'

@Component({
  selector: 'load-session-panel',
  styleUrls: ['./loadSession.component.less'],
  templateUrl: './loadSession.component.html',
})
export class LoadSessionComponent extends SubscriberComponent {
  loadingProjects: Promise<SavedSession[]>
  projects: any[]

  view: 'mine' | 'shared' | 'sharing' = 'mine'
  defaultLoadSessionId: number

  constructor(
    private indicators: Indicators,
    private home: HomeComponent,
    private router: Router,
    private savedSessionEndpoint: SavedSessionEndpoint,
    private savedSessionsModel: SavedSessionModel,
    private quickDialogs: TgmQuickDialogs,
    private dialog: MatDialog,
    private settings: SettingsModel
  ) {
    super()
    this.init()

    this.watch(this.settings.defaultLoadSessionIdUpdates, (defaultLoadSessionId) => {
      this.defaultLoadSessionId = defaultLoadSessionId
    })
  }

  private async init() {
    const load = () => {
      switch (this.view) {
        case 'shared':
          return this.savedSessionEndpoint.meShared()
        case 'sharing':
          return this.savedSessionEndpoint.meSharing()
        default:
          return this.savedSessionEndpoint.me()
      }
    }

    this.projects = await (this.loadingProjects = load())
  }

  async loadProject(layer: any) {
    const result = await this.indicators.add(this.savedSessionEndpoint.find(layer))
    this.savedSessionsModel.restore(result.data, this.home.mapView)
    if (this.view !== 'shared') {
      this.savedSessionsModel.currentSession.next(result)
    }
    this.quickDialogs.snack('Project loaded')
    this.router.navigate(['/'])
  }

  async deleteProject(layer: any) {
    if (
      await this.quickDialogs.confirm({
        title: 'Delete Saved Session',
        message: `Would you like to delete saved session ${layer.name}?`,
        confirm: 'Delete',
        cancel: 'Cancel',
      })
    ) {
      await this.savedSessionEndpoint.delete(layer)
      this.init()
    }
  }

  async shareProject(layer: any) {
    const dialogRef = this.dialog.open(ShareSessionDialogComponent, {
      disableClose: false,
    })

    const result: any = await dialogRef.afterClosed().toPromise()
    if (result) {
      await this.savedSessionEndpoint.share(layer.id, result)
      this.init()
    }
  }

  setDefaultProject(layer: SavedSession) {
    this.settings.defaultLoadSessionId.nextWithCurrent((id) => {
      return layer.id === id ? null : layer.id
    })
  }

  async unshareProject(layer: SavedSession) {
    if (
      await this.quickDialogs.confirm({
        title: 'Unshare',
        message: `Unshare session "${layer.name}" with ${layer.shareEmail}`,
        confirm: 'Unshare',
        cancel: 'Cancel',
      })
    ) {
      await this.savedSessionEndpoint.unshare(layer.shareId)
      this.init()
    }
  }

  async selfUnshareProject(layer: any) {
    if (
      await this.quickDialogs.confirm({
        title: 'Remove Shared Session',
        message: `Would you like to remove shared session ${layer.name} from your list?`,
        confirm: 'Remove',
        cancel: 'Cancel',
      })
    ) {
      await this.savedSessionEndpoint.unshare(layer.shareId)
      this.init()
    }
  }

  changeTab(value: 'mine' | 'shared' | 'sharing') {
    this.view = value
    this.init()
  }
}
