import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { MatDialogRef, MatTabChangeEvent } from '@angular/material'
import { MatchpointEndpoint } from '../../../api/matchpoint'
import { AppModel } from '../../../model/appModel.service'
import { Indicators } from '@targomo/client'
import { DataLoadingModel } from '../../../model/dataLoadingModel'
import { MatchpointModel } from '../../../model/matchpoint/matchpointModel'
import { MatchpointConfigPanelComponent } from '../matchpointConfigPanel/matchpointConfigPanel.component'

@Component({
  selector: 'app-matchpoint-dialog',
  templateUrl: './matchpointDialog.component.html',
  styleUrls: ['./matchpointDialog.component.less'],
})
export class MatchpointDialogComponent implements OnInit, OnDestroy {
  @ViewChild(MatchpointConfigPanelComponent) configPanel: MatchpointConfigPanelComponent

  readonly model: MatchpointModel
  private currentTab: number = null

  constructor(
    readonly dialogRef: MatDialogRef<MatchpointDialogComponent>,
    private matchpointEndpoint: MatchpointEndpoint,
    private appModel: AppModel,
    private indicators: Indicators,
    private dataLoadingModel: DataLoadingModel
  ) {
    this.model = new MatchpointModel(this.appModel, this.matchpointEndpoint, dataLoadingModel, this.indicators)
  }

  ngOnInit() {}

  changeTab(event: MatTabChangeEvent) {
    if (this.currentTab === 3) {
      this.updateConfigModel()
    }

    this.currentTab = event.index
  }

  ngOnDestroy() {
    if (this.currentTab === 3) {
      this.updateConfigModel()
    }
  }

  private async updateConfigModel() {
    this.appModel.settings.displaySettings.nextProperty(
      'matchpointUserSettings',
      await this.configPanel.getUpdatedConfig()
    )
  }
}
