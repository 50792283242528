import { ReportModel } from "./exportPdfReport";
import { PDFHelper } from "./helper";
import { CensusStatisticsGroup, AllStatistics } from "../../../common/models/statistics/statistics";
import { largeTableWithIndex } from "./largeFormatTableHelpers";
import { graphValueIndex, graphPie } from "./graphs";
import { StatisticsValues } from "@targomo/core";

const Color = require('color')

const LABELS_GENDER = {
  "males": "Males",
  "females": "Females",
}

const LABELS_AGES = {
  'aged0to4': '0-4',
  'aged5to7': '5-7',
  'aged8to9': '8-9',
  'aged10to14': '10-14',
  'aged15': '15',
  'aged16to17': '16-17',
  'aged18to19': '18-19',
  'aged20to24': '20-24',
  'aged25to29': '25-29',
  'aged30to44': '30-44',
  'aged45to59': '45-59',
  'aged60to64': '60-64',
  'aged65to74': '65-74',
  'aged75to84': '75-84',
  'aged85to89': '85-89',
  'aged90AndOver': '90+',
}

const LABELS_AGES_ROI = {
  'aged0to4': '0-4',
  'aged5to7': '5-7',
  'aged8to9': '8-9',
  'aged10to14': '10-14',
  'aged15': '15',
  'aged16to17': '16-17',
  'aged18to19': '18-19',
  'aged20to24': '20-24',
  'aged25to29': '25-29',
  'aged30to44': '30-44',
  'aged45to59': '45-59',
  'aged60to64': '60-64',
  'aged65to74': '65-74',
  'aged75to84': '75-84',
  'aged85to89': '85+',
}

const LABELS_SOCIAL_CLASSES = {
  'ab': 'AB',
  'c1': 'C1',
  'c2': 'C2',
  'de': 'DE',
}

function generateColors(helper: PDFHelper, labels: {[id: string]: string}) {
  const ids = Object.keys(labels)
  const result: {[id: string]: string} = {}

  ids.forEach((id, i) => {
    result[id] = Color(helper.tableBorder()).rotate(i * 5).lighten((i % 5) * 0.15)
  })

  return result
}

export function populationReport(reportModel: ReportModel, helper: PDFHelper, travelTitle: string) {
  const COLORS_GENDER = generateColors(helper, LABELS_GENDER)

  const COLORS_AGES = generateColors(helper, LABELS_AGES)

  const COLORS_SOCIAL_CLASSES = generateColors(helper, LABELS_SOCIAL_CLASSES)

  const hasOver90 = reportModel[reportModel.length - 1].statistics.census.ages.aged90AndOver.percent !== undefined  

  return []
  .concat(
    populationReportItem(reportModel, helper, 
                        (statistics: AllStatistics) => statistics.census.gender, LABELS_GENDER, COLORS_GENDER,
                        'Gender', travelTitle
                        )
  )
  .concat(
    populationReportItem(reportModel, helper, 
                        (statistics: AllStatistics) => statistics.census.ages, hasOver90 ? LABELS_AGES : LABELS_AGES_ROI, COLORS_AGES,
                        'Age', travelTitle
                        )
  )
  .concat(
    populationReportItem(reportModel, helper, 
                        (statistics: AllStatistics) => statistics.census.socialClass, LABELS_SOCIAL_CLASSES, COLORS_SOCIAL_CLASSES,
                        'Social class', travelTitle
                        )
  )
  
}

function populationReportItem(
  reportModel: ReportModel, 
  helper: PDFHelper,
  callback: (item: any) => CensusStatisticsGroup,
  labels: {[id: string]: string},
  colors: {[id: string]: string},
  title: string,
  travelTitle: string
) {  
  const result = largeTableWithIndex(reportModel, helper, title, reportSegment => callback(reportSegment.statistics), labels)

  const totalItemsLength = Object.keys(labels).length
  const totalHeight = 180 + (120 * totalItemsLength)
  const graph1 = graphValueIndex(helper, callback(reportModel[reportModel.length - 1].statistics), labels, totalHeight, colors, travelTitle)
  const graph2 = graphPie(helper, callback(reportModel[reportModel.length - 1].statistics), labels, colors)

  return [
    [{pageBreak: 'before', pageOrientation: 'portrait', text: ''}],
    [helper.fullWidthHeader('Population By ' + title)],
    result,
    ' ',
    totalItemsLength > 6 ? {pageBreak: 'before', text: ''}: ' ',
    {columns: [
      [
        {text: title, style: 'graphHeader'},
        ' ',
        {
          image: graph2,
          width: 260,
          alignment: 'center',
        },
      ],
      {
        text: ' ',
        width: 10,
      },
      [
        {text: 'Area vs Regional Index', style: 'graphHeader'},
        ' ',
        {
          image: graph1,
          width: 260,
          alignment: 'center',
        }
      ]
    ]},
  ]
}
