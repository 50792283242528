/// <reference path="./injected.d.ts" />

// require('es6-promise').polyfill()
// require('es6-object-assign').polyfill()

// import 'core-js/client/shim.min.js'
// import 'zone.js'
// import 'reflect-metadata'
import 'hammerjs'

import 'rxjs/add/operator/toPromise'
import 'rxjs/add/observable/combineLatest'

import {
  TgmMainRouterComponent,
  TgmMapScaleComponent,
  TgmMapScaleModule,
  TgmPipesModule,
  TgmMapboxModule,
  TgmRainbowModule,
  TgmTravelFabModule,
  TgmSlideOpacityAnimatorModule,
  TgmHttpModule,
  TgmAuthApiConf,
  TGM_AUTH_API_CONF,
} from '@targomo/client'
import { NvD3Module } from 'ng2-nvd3'

import { NgModule, Pipe, PipeTransform, InjectionToken, Injector } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpModule, JsonpModule } from '@angular/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { APP_BASE_HREF } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { routing, appRoutingProviders } from './routes'
import { HomeComponent } from './main/home.component'
import { AppModel } from './model/appModel.service'
import { MapMenuComponent } from './main/mapMenu/mapMenu.component'

import { RouterModule } from '@angular/router'
import { LocationsPanelComponent } from './main/locationsPanel.component'
// const DatepickerModule = require('angular2-material-datepicker').DatepickerModule
import { SettingsEndpoint } from './api/settings'
import { DataProfileEndpoint } from './api/dataProfile'
import { DataSetEndpoint } from './api/dataSet'
import { UserEndpoint } from './api/user'
import { CategoryEndpoint } from './api/category'
import { PlaceEndpoint } from './api/place'
import { RequestPasswordResetComponent } from './pages/account/requestPasswordReset'
import { PasswordResetComponent } from './pages/account/passwordReset'
import { AdminUsersComponent } from './pages/admin/adminUsers.component'
import { AdminUserComponent } from './pages/admin/adminUser.component'
import { AdminComponent } from './pages/admin/admin.component'
import { LoginComponent } from './pages/account/login.component'
import { AdminHeaderComponent } from './pages/admin/adminHeader.component'
import { AdminCategoryEditComponent } from './pages/admin/adminCategoryEdit.component'
import { AdminDataProfileCategoriesComponent } from './pages/admin/adminDataProfileCategories.component'
import { AdminDataProfilesListComponent } from './pages/admin/adminDataProfilesList.component'
import { AdminDataProfileDetailsComponent } from './pages/admin/adminDataProfileDetails.component'
import { AdminDataSetsListComponent } from './pages/admin/adminDataSetsList.component'
import { AdminSettingsComponent } from './pages/admin/adminSettings.component'
import { SidetabsComponent } from './main/sidenav/sideTabs.component'
import { LegendPanelComponent } from './main/sidenav/legendPanel.component'
import { LayersPanelComponent } from './main/sidenav/layersPanel.component'
import { MiniReportsPanelComponent } from './main/sidenav/miniReportsPanel.component'
import { LayerSelectPanelComponent } from './main/sidenav/layerSelectPanel/layerSelectPanel.component'
import { CensusMiniReportComponent } from './main/mini/censusMiniReport.component'
import { LifestyleMiniReportComponent } from './main/mini/lifestyleMiniReport.component'
import { LifestylePersonicxMiniReportComponent } from './main/mini/lifestylePersonicxMiniReport.component'
import { SourceTitlePanelComponent } from './main/mini/components/sourceTitlePanel/sourceTitlePanel.component'
import { WorkforceMiniReportComponent } from './main/mini/workforceMiniReport.component'
import { CompetitionMiniReportComponent } from './main/mini/competitionMiniReport.component'
import { MaxiReportDialog } from './main/maxi/maxiReportDialog.component'
import { CompetitionMaxiReportComponent } from './main/maxi/competitionMaxiReport'
import { BenchmarkReportComponent } from './main/maxi/benchmarkReport'
import { ExportPDFReport } from './main/pdf/exportPdfReport'
import { DecimalPipe, CommonModule } from '@angular/common/'
import { PlaceDetailsComponent } from './main/sidenav/placeDetails.component'
import { NewLocationDialog } from './main/dialogs/newLocationDialog.component'
import { ColorSelectDialog } from './main/dialogs/colorSelectDialog/colorSelectDialog.component'
import { StatisticSwitchComponent } from './main/sidenav/statisticsSwitch'
import { MapPrintDialog } from './main/dialogs/mapPrintDialog.component'
import { SelectToolComponent } from './main/selectTool/selectTool.component'
import { ExportDialog } from './main/dialogs/exportDialog.component'
import { CodcodimamaBenchmarkComponent } from './main/maxi/benchmarks/cocodimamaBenchmark.component'
import { StatisticsEndpoint } from './api/statistics'
import { OptionsPanelComponent } from './main/sidenav/optionsPanel.component'
import { IndicesSwitchComponent } from './main/sidenav/indicesSwitch'
import { HoverInfoDisplayComponent } from './main/sidenav/hoverInfoDisplay.component'
import { UploadLayerComponent } from './main/uploadLayer/uploadLayer.component'
import { AdminUploadDataSetComponent } from './pages/admin/uploadDataSet.component'
import { AdminDataSetVersionsComponent } from './pages/admin/adminDataSetVersions.component'
import { ExpandablePanelComponent } from './main/ui/expandablePanel.component'
import { AppComponent } from './app/app.component'
import { HttpClientModule } from '@angular/common/http'
import {
  MatButtonModule,
  MatListModule,
  MatRippleModule,
  MatSidenavModule,
  MatIconModule,
  MatToolbarModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatTabsModule,
  MatInputModule,
  MatMenuModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTableModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSortModule,
  MatOptionModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatRadioModule,
} from '@angular/material'
import { TgmMainRouterModule } from '@targomo/client'
import { TgmGeosearchModule } from '@targomo/client'
import { TgmBargraphModule } from '@targomo/client'
import { TgmLoadableModule } from '@targomo/client'
import { TgmFileUploaderModule } from '@targomo/client'
import { TgmMapPopupModule } from '@targomo/client'
import { FlexLayoutModule } from '@angular/flex-layout'
import { TgmSaveableModule } from '@targomo/client'
import { TgmRangesliderModule } from '@targomo/client'
import { TgmSpeeddialModule, TgmAuthModule } from '@targomo/client'
import { TgmQuickDialogsModule } from '@targomo/client'
import { ViewLayersComponent } from './main/viewLayers/viewLayers.component'
import { BackButtonComponent } from './main/backButton/backButton.component'
import { LoadSessionComponent } from './pages/home/loadSession/loadSession.component'
import { SaveSessionComponent } from './pages/home/saveSession/saveSession.component'
import { SavedSessionEndpoint } from './api/savedSession'
import { SavedSessionModel } from './model/savedSessionModel'
import { TgmToolbarComponent } from './components/tgm-toolbar/tgm-toolbar.component'
import { TgmToolbarButtonComponent } from './components/tgm-toolbar-button/tgm-toolbar-button.component'
import { TgmToolbarPanelComponent } from './components/tgm-toolbar-panel/tgm-toolbar-panel.component'
import { ColorPickerModule } from 'ngx-color-picker'
import { AdminActivityLogComponent } from './pages/admin/activityLog/activityLog.component'
import { UserEventLogEndpoint } from './api/userEventLog'
import { LocationsPresentComponent } from './main/mini/locationsPresent.component'
import { PlanetOrganicBenchmarkComponent } from './main/maxi/benchmarks/planetOrganicBenchmark.component'
import { UserSettingsEndpoint } from './api/userSettings'
import { UserSettingsPersistenceService } from './model/userSettingsPersitenceService'
import { FeatureModel } from './model/featureModel'
import { ExclusiveSwitchComponent } from './main/sidenav/exclusiveSwitch'
import { SettingsModel } from './model/settingsModel'
import { ZoneLayersModel } from './model/zoneLayersModel'
import { ZoneLayersEndpoint } from './api/sectors'
import { ZoneLayerPanelComponent } from './pages/home/sectorsPanel/zoneLayerPanel.component'
import { SaveZoneLayerComponent } from './pages/home/sectorsPanel/saveZoneLayer.component'
import { AdminZoneLayersListComponent } from './pages/admin/zones/adminZoneLayersList.component'
import { AdminUploadZoneLayerComponent } from './pages/admin/zones/uploadZoneLayer.component'
import { AdminZoneLayerVersionsComponent } from './pages/admin/zones/adminZoneVersions.component'
import { AdminZoneLayerComponent } from './pages/admin/zones/adminZoneLayer.component'
import { ZoneLayerSwitchComponent } from './main/sidenav/zoneLayerSwitch'
import { DataLoadingModel } from './model/dataLoadingModel'
import { CategorySearchPanelComponent } from './main/categorySearchPanel/categorySearchPanel.component'
import { AdminGeoRegionsListComponent } from './pages/admin/georegions/adminGeoRegionsList.component'
import { AdminUploadGeoRegionComponent } from './pages/admin/georegions/uploadGeoRegion.component'
import { AdminGeoRegionVersionsComponent } from './pages/admin/georegions/adminGeoRegionVersions.component'
import { AdminGeoRegionComponent } from './pages/admin/georegions/adminGeoRegions.component'
import { GeoRegionsEndpoint } from './api/georegions'
import { PopulationForecastComponent } from './main/populationForecast/populationForecast.component'
import { AdminActivityLogReportComponent } from './pages/admin/activityLogReport/activityLogReport.component'
import { TrafficLightPanelComponent } from './pages/home/trafficLightPanel/trafficLightPanel.component'
import { RiskAssessmentEndpoint } from './api/riskAssessment'
import { AdminPlanningApplicationsListComponent } from './pages/admin/planningApplications/planningApplicationsList.component'
import { AdminPlanningApplicationVersionsComponent } from './pages/admin/planningApplications/planningApplicationVersions.component'
import { AdminPlanningApplicationUploadComponent } from './pages/admin/planningApplications/planningApplicationUpload.component'
import { PlanningApplicationDataSetEndpoint } from './api/planningApplicationDataSet'
import { AdminVersionsComponent } from './pages/admin/components/adminVersions/adminVersions.component'
import { AdminSelectFasciasComponent } from './pages/admin/components/adminSelectFascias/adminSelectFascias.component'
import { AdminSelectFasciasCategoryComponent } from './pages/admin/components/adminSelectFascias/adminSelectFasciasCategory.component'
import { SelectPlacesLabelComponent } from './main/optionsDialog/selectPlacesLabel/selectPlacesLabel.component'
import { AdminReportOptionsComponent } from './pages/admin/adminReportOptions/adminReportOptions.component'
import { AdminReportTemplatesListComponent } from './pages/admin/adminReportTemplatesList/adminReportTemplatesList.component'
import { AdminReportTemplateComponent } from './pages/admin/adminReportTemplate/adminReportTemplate.component'
import { ReportTemplateEndpoint } from './api/reportTemplate'
import { CenterPointReportComponent } from './main/sidenav/centerPointReport/centerPointReport.component'
import { PieChartComponent } from './main/components/pie-chart/pie-chart.component'
import { MaxiComparisonReportComponent } from './main/maxi/maxiComparisonReport/maxiComparisonReport.component'
import { LayerStateStack } from './model/layerStateStack.service'
import { ComparisonReportTemplateEndpoint } from './api/comparisonReportTemplate'
import { AdminComparisonReportTemplatesListComponent } from './pages/admin/adminComparisonReportTemplatesList/adminComparisonTemplatesList.component'
import { AdminComparisonReportTemplateComponent } from './pages/admin/adminComparisonReportTemplate/adminComparisonReportTemplate.component'
import { AdminComparisonReportOptionsComponent } from './pages/admin/adminComparisonReportOptions/adminComparisonReportOptions.component'
import { AdminComparisonRatioComponent } from './pages/admin/adminComparisonRatio/adminComparisonRatio.component'
import { UploadZoneLayerComponent } from './pages/home/sectorsPanel/uploadZoneLayer.component'
import { AdminMessageCenterComponent } from './pages/admin/adminMessageCenter/adminMessageCenter.component'
import { AdminMessageCenterEditComponent } from './pages/admin/adminMessageCenter/adminMessageCenterEdit.component'
import { MessagesEndpoint } from './api/message'
import { MessagePopupComponent } from './main/messagePopup/messagePopup.component'
import { PerCapitaHouseholdsSwitchComponent } from './main/components/perCapitaHouseholdsSwitch/perCapitaHouseholdsSwitch.component'
import { CustomTravelColorDialogComponent } from './main/dialogs/customTravelColorDialog/customTravelColorDialog.component'
import { AdminCompanyListComponent } from './pages/admin/adminCompanyList/adminCompanyList.component'
import { AdminCompanyEditComponent } from './pages/admin/adminCompanyEdit/adminCompanyEdit.component'
import { AdminEditPermissionsComponent } from './pages/admin/components/adminEditPermissions/adminEditPermissions.component'
import { CompanyEndpoint } from './api/company'
import { ProjectNumberDialogComponent } from './main/dialogs/projectNumberDialog/projectNumberDialog.component'
import { ProjectNumberModel } from './model/projectNumberModel'
import { CategoryColorModel } from './model/categoryColorModel'
import { ChatPanelComponent } from './main/components/chatPanel/chatPanel.component'
import { MatchpointDialogComponent } from './main/matchpoint/matchpointDialog/matchpointDialog.component'
import { MatchpointSimilarCentresComponent } from './main/matchpoint/matchpointSimilarCentres/matchpointSimilarCentres.component'
import { MatchpointSubjectCentreOperatorsComponent } from './main/matchpoint/matchpointSubjectCentreOperators/matchpointSubjectCentreOperators.component'
import { MatchpointGapOpportunitiesComponent } from './main/matchpoint/matchpointGapOpportunities/matchpointGapOpportunities.component'
import { AdminMatchpointPanelComponent } from './pages/admin/adminMatchpointPanel/adminMatchpointPanel.component'
import { MatchpointEndpoint } from './api/matchpoint'
import { DrawZoneLayerComponent } from './pages/home/sectorsPanel/drawZoneLayer.component'
import { Ng5SliderModule } from 'ng5-slider'
import { FilterDatesComponent } from './main/sidenav/filterDates/filterDates.component'
import { CustomRangesliderComponent } from './main/components/customRangeSlider/customRangeSlider.component'
import { TgmCustomBargraphComponent } from './components/tgm-custom-bargraph/bargraph.component'
import { MaxiReportModel } from './model/maxi/maxiReportModel'
import { OperatorsPresentTravelComponent } from './main/mini/components/operatorsPresentTravel/operatorsPresentTravel.component'
import { WaitDialogComponent } from './main/ui/waitDialog/waitDialog.component'
import { WaitDialog } from './main/ui/waitDialog/waitDialog.service'
import { TgmGeosearchComponent } from './main/components/geosearch/geosearch.component'
import { HistogramTableComponent } from './main/mini/components/histogramTable/histogramTable.component'
import { ShareSessionDialogComponent } from './pages/home/shareSessionDialog/shareSessionDialog.component'
import { ShareSessionResolver } from './resolvers/shareSessionResolver'
import { SimpleMapLayoutComponent } from './main/simpleMapLayout/simple-map-layout.component'
import { ExportPDFGapReport } from './main/pdf/exportPdfGapReport'
import { OpenDialog } from './main/ui/openDialog.service'
import { loginRedirect } from './util/loginRedirect'
import { TgmInfoDialogComponent } from './main/dialogs/info-dialog/info-dialog.component'
import { CareHomeMiniReportComponent } from './main/mini/components/careHome/careHomeMiniReport/careHomeMiniReport.component'
import { CareHomeSegmentTableComponent } from './main/mini/components/careHome/careHomeMiniReport/careHomeSegmentTable.component'
import { AdminCareHomeComponent } from './pages/admin/adminCareHome/adminCareHome.component'
import { ForecastYearSliderComponent } from './main/mini/components/forecastYearSlider/forecastYearSlider.component'
import { OptionsDialogComponent } from './main/optionsDialog/optionsDialog/optionsDialog.component'
import { OptionsDialogService } from './main/optionsDialog/optionsDialog.service'
import { SelectImageListComponent } from './main/optionsDialog/selectImageList/selectImageList.component'
import { SearchOptionsHeaderComponent } from './main/optionsDialog/searchOptionsHeader/searchOptionsHeader.component'
import { SelectTravelTimesOptionsPanelComponent } from './main/optionsDialog/travelTimesOptionPanel/travelTimesOptionPanel.component'
import { LabelOptionsPanelComponent } from './main/optionsDialog/labelOptionsPanel/labelOptionsPanel.component'
import { MapFiltersOptionsPanelComponent } from './main/optionsDialog/mapFiltersOptionsPanel/mapFiltersOptionsPanel.component'
import { TravelThematicsOptionsPanelComponent } from './main/optionsDialog/travelThematicsOptionsPanel/travelThematicsOptionsPanel.component'
import { TravelMethodOptionsPanelComponent } from './main/optionsDialog/travelMethodOptionsPanel/travelMethodOptionsPanel.component'
import { TravelColorsOptionsPanelComponent } from './main/optionsDialog/travelColorsOptionsPanel/travelColorsOptionsPanel.component'
import { PointColorsOptionsPanelComponent } from './main/optionsDialog/pointColorsOptionsPanel/pointColorsOptionsPanel.component'
import { OptionImageComponent } from './main/optionsDialog/optionImage/optionImage.component'
import { MapStyleOptionsPanelComponent } from './main/optionsDialog/mapStyleOptionsPanel/mapStyleOptionsPanel.component'
import { ZonesOptionsPanelComponent } from './main/optionsDialog/zonesOptionsPanel/zonesOptionsPanel.component'
import { LocationsPresentOptionsPanelComponent } from './main/optionsDialog/locationsPresentOptionsPanel/locationsPresentOptionsPanel.component'
import { BatchStatisticsModel } from './model/batchStatisticsModel'
import { SearchTemporaryLocationComponent } from './main/searchTemporaryLocation/searchTemporaryLocation.component'
import { RoadsVolumeEndpoint } from './api/roadsVolume'
import { AdminEditGapAnalysisReportComponent } from './pages/admin/components/adminEditGapAnalysisReport/adminEditGapAnalysisReport.component'
import { UrlInitialStateResolver } from './resolvers/urlInitialStateResolver'
import { HousePricesMiniReportComponent } from './main/mini/components/housePricesMiniReport/housePricesMiniReport.component'
import { NewRoadLabelDialogComponent } from './main/dialogs/newRoadLabelDialog/newRoadLabelDialog.component'
import { TravelTimeDistanceSwitchComponent } from './main/components/travelTimeDistanceSwitch/travelTimeDistanceSwitch.component'
import { ExportAllEndpoint } from './api/exportAll'
import { ExportAllPanelComponent } from './pages/home/exportAllPanel/exportAllPanel.component'
import { PrimaryFiltersPanelComponent } from './main/optionsDialog/primaryFiltersPanel/primaryFiltersPanel.component'
import { SecondaryFiltersPanelComponent } from './main/optionsDialog/secondaryFiltersPanel/secondaryFiltersPanel.component'
import { MatchpointConfigPanelComponent } from './main/matchpoint/matchpointConfigPanel/matchpointConfigPanel.component'
import { LoadMatchpointPanelComponent } from './main/matchpoint/loadMatchpointPanel/loadMatchpointPanel.component'
import { SaveMatchpointConfigComponent } from './main/matchpoint/saveMatchpointConfig/saveMatchpointConfig.component'
import { MatchpointCategoryFilterComponent } from './main/matchpoint/matchpointCategoryFilter/matchpointCategoryFilter.component'
import { IconSelectDialog } from './main/dialogs/iconSelectDialog/iconSelectDialog.component'
import { CategoryIconComponent } from './main/mini/components/categoryIcon/categoryIcon.component'
import { PostcodeAnalysisComponent } from './main/mini/components/postcodeAnalysis/postcodeAnalysis.component'
import { PostcodeAnalysisEndpoint } from './api/postcodeAnalysis'
import { PostcodeAnalysisModel } from './model/postcodeAnalyssModel'
import { CustomMapboxComponent } from './main/mapBox/mapbox.component'
import { AdminUploadPostcodesComponent } from './pages/admin/postcodes/uploadPostcodes.component'

@Pipe({ name: 'minutesHoursSimple' })
export class HoursAndMinutesPipe implements PipeTransform {
  transform(input: any) {
    if (isFinite(input)) return Math.ceil(input / 60)
    else return ''
  }
}

@Pipe({ name: 'simpleNumber' })
export class SimpleNumberPipe implements PipeTransform {
  transform(value: any, args: string) {
    let pipe = new DecimalPipe('en')
    if (isNaN(value)) {
      return ''
    }

    return pipe.transform(+value, args)
  }
}

@Pipe({ name: 'maybeNumber' })
export class MaybeNumberPipe implements PipeTransform {
  readonly pipe = new DecimalPipe('en')
  transform(value: any, args: string) {
    if (isNaN(value)) {
      return value
    }

    return this.pipe.transform(+value, args)
  }
}

@Pipe({ name: 'maybeNegativeNumber' })
export class MaybeNegativeNumberPipe implements PipeTransform {
  readonly pipe = new DecimalPipe('en')
  transform(value: any, args: string) {
    if (isNaN(value)) {
      return value
    }

    if (+value >= 0) {
      return this.pipe.transform(+value, args)
    } else {
      return '(' + this.pipe.transform(Math.abs(+value), args) + ')'
    }
  }
}

@Pipe({ name: 'distance' })
export class DistancePipe implements PipeTransform {
  pipe = new DecimalPipe('en')

  transform(input: any) {
    if (isFinite(input)) {
      return this.pipe.transform(input / 1000, '1.0-1')
    } else {
      return ''
    }
  }
}

export let angularInjector: Injector

@NgModule({
  declarations: [
    AppComponent,

    HomeComponent,
    MapMenuComponent,
    OptionsPanelComponent,
    LocationsPanelComponent,

    RequestPasswordResetComponent,
    PasswordResetComponent,
    AdminUsersComponent,
    AdminUserComponent,
    AdminComponent,
    LoginComponent,
    AdminHeaderComponent,

    AdminCategoryEditComponent,
    AdminDataProfileCategoriesComponent,
    AdminDataProfileDetailsComponent,
    AdminDataProfilesListComponent,
    AdminDataSetsListComponent,
    AdminSettingsComponent,

    SidetabsComponent,
    LayersPanelComponent,
    MiniReportsPanelComponent,
    LegendPanelComponent,
    LayerSelectPanelComponent,

    CensusMiniReportComponent,
    CompetitionMiniReportComponent,
    // LifestyleGraphController,
    LifestyleMiniReportComponent,
    LifestylePersonicxMiniReportComponent,
    SourceTitlePanelComponent,
    WorkforceMiniReportComponent,

    MaxiReportDialog,
    CompetitionMaxiReportComponent,
    BenchmarkReportComponent,
    CodcodimamaBenchmarkComponent,
    PlaceDetailsComponent,
    NewLocationDialog,
    ColorSelectDialog,
    IconSelectDialog,
    StatisticSwitchComponent,
    UploadZoneLayerComponent,

    MapPrintDialog,
    SelectToolComponent,
    ExportDialog,
    HoursAndMinutesPipe,
    SimpleNumberPipe,
    MaybeNumberPipe,
    MaybeNegativeNumberPipe,
    DistancePipe,

    OptionsPanelComponent,
    IndicesSwitchComponent,
    HoverInfoDisplayComponent,
    UploadLayerComponent,

    AdminUploadDataSetComponent,
    AdminDataSetVersionsComponent,

    ExpandablePanelComponent,
    ViewLayersComponent,
    BackButtonComponent,

    LoadSessionComponent,
    SaveSessionComponent,

    TgmToolbarPanelComponent,
    TgmToolbarButtonComponent,
    TgmToolbarComponent,

    AdminActivityLogComponent,
    LocationsPresentComponent,

    PlanetOrganicBenchmarkComponent,
    ExclusiveSwitchComponent,
    ZoneLayerPanelComponent,
    SaveZoneLayerComponent,

    AdminZoneLayersListComponent,
    AdminUploadZoneLayerComponent,
    AdminZoneLayerVersionsComponent,
    AdminZoneLayerComponent,
    ZoneLayerSwitchComponent,
    CategorySearchPanelComponent,

    AdminGeoRegionsListComponent,
    AdminUploadGeoRegionComponent,
    AdminGeoRegionVersionsComponent,
    AdminGeoRegionComponent,

    PopulationForecastComponent,
    AdminActivityLogReportComponent,

    TrafficLightPanelComponent,

    AdminPlanningApplicationsListComponent,
    AdminPlanningApplicationVersionsComponent,
    AdminPlanningApplicationUploadComponent,

    AdminVersionsComponent,
    AdminSelectFasciasCategoryComponent,
    AdminSelectFasciasComponent,
    SelectPlacesLabelComponent,
    AdminReportOptionsComponent,

    AdminReportTemplatesListComponent,
    AdminReportTemplateComponent,
    AdminComparisonReportOptionsComponent,

    CenterPointReportComponent,

    PieChartComponent,

    MaxiComparisonReportComponent,

    AdminComparisonReportTemplatesListComponent,
    AdminComparisonReportTemplateComponent,
    AdminComparisonRatioComponent,
    AdminMessageCenterComponent,
    AdminMessageCenterEditComponent,
    MessagePopupComponent,
    PerCapitaHouseholdsSwitchComponent,
    CustomTravelColorDialogComponent,
    AdminCompanyListComponent,
    AdminCompanyEditComponent,
    AdminEditPermissionsComponent,
    ProjectNumberDialogComponent,
    ChatPanelComponent,
    DrawZoneLayerComponent,

    MatchpointDialogComponent,
    MatchpointSimilarCentresComponent,
    MatchpointSubjectCentreOperatorsComponent,
    MatchpointGapOpportunitiesComponent,
    AdminMatchpointPanelComponent,

    FilterDatesComponent,
    CustomRangesliderComponent,
    TgmCustomBargraphComponent,
    OperatorsPresentTravelComponent,
    WaitDialogComponent,
    TgmGeosearchComponent,
    HistogramTableComponent,
    ShareSessionDialogComponent,
    SimpleMapLayoutComponent,
    TgmInfoDialogComponent,
    CareHomeMiniReportComponent,
    CareHomeSegmentTableComponent,
    AdminCareHomeComponent,
    ForecastYearSliderComponent,
    OptionsDialogComponent,
    SelectImageListComponent,
    SelectTravelTimesOptionsPanelComponent,
    SearchOptionsHeaderComponent,
    SelectTravelTimesOptionsPanelComponent,
    LabelOptionsPanelComponent,
    MapFiltersOptionsPanelComponent,
    TravelThematicsOptionsPanelComponent,
    TravelMethodOptionsPanelComponent,
    TravelColorsOptionsPanelComponent,
    PointColorsOptionsPanelComponent,
    OptionImageComponent,
    MapStyleOptionsPanelComponent,
    ZonesOptionsPanelComponent,
    LocationsPresentOptionsPanelComponent,
    SearchTemporaryLocationComponent,
    AdminEditGapAnalysisReportComponent,
    HousePricesMiniReportComponent,
    NewRoadLabelDialogComponent,
    TravelTimeDistanceSwitchComponent,
    ExportAllPanelComponent,
    PrimaryFiltersPanelComponent,
    SecondaryFiltersPanelComponent,
    MatchpointConfigPanelComponent,
    LoadMatchpointPanelComponent,
    SaveMatchpointConfigComponent,
    MatchpointCategoryFilterComponent,
    CategoryIconComponent,
    PostcodeAnalysisComponent,
    CustomMapboxComponent,
    AdminUploadPostcodesComponent,
  ],
  imports: [
    // BrowserAnimationsModule,
    // FormsModule,
    // RouterModule,
    // ReactiveFormsModule,
    // BrowserModule,
    // FormsModule,
    // HttpModule,
    // JsonpModule,
    // routing,

    FlexLayoutModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    MatButtonModule,
    MatListModule,
    MatRippleModule,
    // MatSidenavModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatRippleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    MatRadioModule,
    MatSliderModule,

    MatNativeDateModule,
    MatDatepickerModule,

    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TgmMainRouterModule,
    TgmGeosearchModule,
    TgmBargraphModule,
    TgmLoadableModule,
    TgmFileUploaderModule,
    TgmMapPopupModule,
    TgmSaveableModule,
    TgmRangesliderModule,
    TgmSpeeddialModule,
    TgmQuickDialogsModule,
    TgmMapScaleModule,

    {
      ngModule: TgmHttpModule,
      providers: [
        {
          provide: <InjectionToken<TgmAuthApiConf>>TGM_AUTH_API_CONF,
          useValue: {
            loginRedirectFunction: loginRedirect,
          },
        },
      ],
    },

    TgmAuthModule.forRoot({
      loginRedirectFunction: loginRedirect,
    }),
    TgmPipesModule,
    HttpModule,
    routing,
    Ng5SliderModule,

    // MatAutocompleteModule,
    MatFormFieldModule,
    MatTabsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatButtonToggleModule,

    ColorPickerModule,
    NvD3Module,

    // FormsModule,
    // CommonModule,
    // ReactiveFormsModule,
    // MatFormFieldModule,
    // MatTabsModule,
    // MatInputModule,
    // MatIconModule,
    // MatButtonModule,
    // MatSidenavModule,
    // FlexLayoutModule,
    TgmMapboxModule,
    // TgmMapPopupModule,
    TgmRainbowModule,
    TgmTravelFabModule,
    TgmSlideOpacityAnimatorModule,
  ],
  providers: [
    GeoRegionsEndpoint,
    ZoneLayersEndpoint,
    SettingsModel,
    ZoneLayersModel,
    PlaceEndpoint,
    DataLoadingModel,
    CategoryColorModel,
    AppModel,
    appRoutingProviders,
    { provide: APP_BASE_HREF, useValue: '/' },

    CategoryEndpoint,
    UserEndpoint,
    CompanyEndpoint,
    SettingsEndpoint,
    DataProfileEndpoint,
    DataSetEndpoint,
    ExportAllEndpoint,
    StatisticsEndpoint,
    SavedSessionEndpoint,
    UserEventLogEndpoint,
    SavedSessionModel,
    UserSettingsEndpoint,
    UserSettingsPersistenceService,
    RiskAssessmentEndpoint,
    PlanningApplicationDataSetEndpoint,
    ReportTemplateEndpoint,
    ComparisonReportTemplateEndpoint,
    MessagesEndpoint,
    MatchpointEndpoint,
    LayerStateStack,

    ExportPDFReport,
    DecimalPipe,
    ProjectNumberModel,
    WaitDialog,
    ShareSessionResolver,
    UrlInitialStateResolver,
    ExportPDFGapReport,
    OpenDialog,
    FeatureModel,
    OptionsDialogService,
    BatchStatisticsModel,
    RoadsVolumeEndpoint,
    PostcodeAnalysisEndpoint,
    PostcodeAnalysisModel,
  ],
  entryComponents: [
    MaxiReportDialog,
    NewLocationDialog,
    ColorSelectDialog,
    IconSelectDialog,
    MapPrintDialog,
    ExportDialog,
    CustomTravelColorDialogComponent,
    ProjectNumberDialogComponent,
    MatchpointDialogComponent,
    WaitDialogComponent,
    ShareSessionDialogComponent,
    TgmInfoDialogComponent,
    OptionsDialogComponent,
    NewRoadLabelDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    angularInjector = injector
  }
}
