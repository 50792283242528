<section style="padding: 0px 16px">
  <h3>Edit zone</h3>

  <div class="tip">
    <p>
      Click on the map to select and deselect sectors for this zone.
    </p>

    <p>
      <b style="color: black">NOTE:</b> Zones are only saved when a project is saved.
    </p>
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column" novalidate>
    <mat-form-field>
      <input matInput type="text" formControlName="name"  required placeholder="Zone name">

      <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
        <span>Zone name is required</span>
      </mat-hint>
    </mat-form-field>

    <div class="bottom-buttons">
      <button type="button" mat-button (click)="cancel()"><span>Cancel</span></button>
      <button mat-button color="primary" type="submit"><span>Save</span></button>
    </div>
  </form>
</section>