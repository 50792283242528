import {ReportTemplate, DataSet}  from '../../common/models'
import {Injectable} from '@angular/core';
import {RESTHelper} from '@targomo/client';

export type ReportTemplate = ReportTemplate

@Injectable()
export class ReportTemplateEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/reportTemplates')
  }

  get(profile: {id: number}) {
    return this.http.get<ReportTemplate>(`/${profile.id}`)
  }

  save(profile: ReportTemplate) {
    return this.http.put<ReportTemplate>(`/${profile.id}`, profile)
  }

  delete(profile: ReportTemplate) {
    return this.http.delete<ReportTemplate>(`/${profile.id}`)
  }

  create(profile: ReportTemplate) {
    return this.http.post<ReportTemplate>(`/`, profile)
  }

  findAll() {
    return this.http.get<ReportTemplate[]>('')
  }
}
