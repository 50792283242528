import { Component, OnInit, AfterViewInit, ElementRef, Input, OnChanges } from '@angular/core';
import * as c3 from 'c3'
import { RANDOM_COLORS } from '@targomo/client';

export interface PieGraphValues {
  key: string
  y: number
}

@Component({
  selector: 'tgm-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.less']
})
export class PieChartComponent implements AfterViewInit, OnChanges {
  componentId: string = 'pie' + Math.round(Math.random() * 1000000) + 'x' + new Date().getTime()
  chart: c3.ChartAPI
  @Input() data: PieGraphValues[] = []
  @Input() title = ''
  columns: any = []

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.chart = c3.generate({
      bindto: '#' + this.componentId,
      color: {
        pattern: RANDOM_COLORS
      },
      data: {
          columns: [
          ],
          type : 'pie',
          // onclick: function (d, i) { console.log('onclick', d, i); },
          // onmouseover: function (d, i) { console.log('onmouseover', d, i); },
          // onmouseout: function (d, i) { console.log('onmouseout', d, i); }
      },
      donut: {
          title: this.title
      },
      size: {
        height: 300
      }
    })

    setTimeout(() => {
      this.ngOnChanges()
    })
  }

  ngOnChanges() {
    if (this.chart && this.data) {
      const columns = {
        columns: this.data.map(item => {
          return [item.key, item.y]
        }),
        unload: this.columns
      }

      this.columns = this.data.map(item => item.key)
      setTimeout(() => {
        this.chart.load(columns)
      })
    }
  }
}
