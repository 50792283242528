
import { AppModel } from '../../model/appModel.service';
import { Place } from "../../api/index";
import {Subscription} from 'rxjs/Subscription';
import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { SubscriberComponent } from "@targomo/client";
import { HomeComponent } from '../home.component';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/merge'
import { BoundingBox, geometry } from '@targomo/core';
import { Category } from '../../api/category';
import {array as arrays, object as objects} from '@targomo/common'
import { NgZone } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'locations-present',
  templateUrl: './locationsPresent.component.html',
  styleUrls: ['../sidenav/layerSelectPanel/layerSelectPanel.less']
})
export class LocationsPresentComponent extends SubscriberComponent implements OnInit {
  visibleList: Category[] = []
  invisibleList: Category[] = []
  state: any = {}

  constructor(private appModel: AppModel, private changeDetectorRef: ChangeDetectorRef) {
    super()

  }

  ngOnInit() {
    const visibleList$ = this.appModel.places.locationsPresent.operatorsPresent$.pipe(
      map(operators => {
        return arrays.sortBy(operators.map(operator => operator.category), 'name')
      })
    )

    const invisibleList$ = this.appModel.places.locationsPresent.operatorsNotPresent$.pipe(
      map(operators => {
        return arrays.sortBy(operators.map(operator => operator.category), 'name')
      })
    )

    this.watch(visibleList$, values => {
      this.visibleList = values
      this.changeDetectorRef.detectChanges()
    })

    this.watch(invisibleList$, values => {
      this.invisibleList = values
      this.changeDetectorRef.detectChanges()
    })        
  }

  toggle(key: string) {
    this.state[key] = !this.state[key]
  }
}