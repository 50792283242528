import { Component, Output, Input, ViewChild, ViewContainerRef, EventEmitter } from '@angular/core'
import {
  MapLayoutStyleOptions,
  MapLayoutTravelTimesOptions,
  MapLayoutOptions,
} from './simple-map-layout.component.type'
import { UndoQueue, TgmMapPopupComponent, TRAVEL_COLORS, MaxEdgeWeightOption } from '@targomo/client'
import { BoundingBox } from '@targomo/core'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

const SIDE_PADDING = 500 + 2 * 12

/**
 * Standard map, sidenav, fabs layout for the apps we build. It can be customized using transclusion
 *
 * The following transclution slots are defined:
 *
 * `.map-layout-sidenav-main`  The main contents of the sidenav
 * `.map-layout-sidenav-details` The contents for a "details" panel in sidenav
 * `.map-layout-sidenav-options` The contents for a "options" panel in sidenav
 * `.map-layout-sidenav-reports` The contents for a "report" panel in sidenav
 * `.map-layout-hover-info` A sidenav panel showing info for hover over map cells
 * `.map-layout-extra-fabs`  Additional fabs in the right fab column
 */
@Component({
  selector: 'simple-map-layout',
  templateUrl: './simple-map-layout.component.html',
  styleUrls: ['./simple-map-layout.component.less'],
})
export class SimpleMapLayoutComponent {
  @Input() undoQueue: UndoQueue<{}, {}>
  @Input() styleOptions: MapLayoutStyleOptions
  @Input() options: MapLayoutOptions = {}
  @Input() travelOptions: any = { travelType: 'car', maxEdgeWeight: 900 }
  @Input() isSideBarOpened: boolean = true
  @Input() customMaxEdgeWeight: number = null

  @Output() travelOptionsChange = new EventEmitter<MapLayoutTravelTimesOptions>()
  @Output() hoverInfoChange = new EventEmitter<boolean>()

  @ViewChild('sidenav', { read: ViewContainerRef }) sidenavRef: ViewContainerRef
  @ViewChild('map') readonly mapView: CustomMapboxComponent
  @ViewChild('mapMenuPopup') readonly mapMenuPopup: TgmMapPopupComponent

  colors: string[] = TRAVEL_COLORS
  private lastMaxEdgeWeightOptions: MaxEdgeWeightOption[]

  ngDoCheck() {
    if (this.lastMaxEdgeWeightOptions != this.options.maxEdgeWeightOptions) {
      this.lastMaxEdgeWeightOptions = this.options.maxEdgeWeightOptions

      if (this.lastMaxEdgeWeightOptions) {
        this.colors = this.lastMaxEdgeWeightOptions.map((option) => option.color)
      }
    }
  }

  async sidenavStateChange(state: boolean) {
    this.isSideBarOpened = state
    const map = await this.mapView.getMap()
    map.resize()
  }

  travelOptionsChanged() {
    this.travelOptionsChange.emit(this.travelOptions)
  }

  async setBounds(bounds: BoundingBox) {
    this.mapView.setBounds(bounds, {
      padding: {
        left: this.isSideBarOpened ? SIDE_PADDING : 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    })
  }

  async getBounds() {
    const map = await this.mapView.getMap()

    // If sidenav is opened extract it from bounds
    if (this.isSideBarOpened) {
      const canvas = map.getCanvas()
      const pixelRatio = window.devicePixelRatio || 1
      const w = canvas.width / pixelRatio
      const h = canvas.height / pixelRatio

      // width is 500px and 2 times margin of 12
      const LEFT = this.isSideBarOpened ? SIDE_PADDING : 0

      // calculate the complete bounding box manually
      // so that we will not have problems with map bearing
      const points = [map.unproject([LEFT, 0]), map.unproject([w, 0]), map.unproject([w, h]), map.unproject([LEFT, h])]

      const lats = points.map((point) => point.lat)
      const lngs = points.map((point) => point.lng)

      const boundsSidenav: BoundingBox = {
        northEast: {
          lat: Math.max.apply(null, lats),
          lng: Math.max.apply(null, lngs),
        },
        southWest: {
          lat: Math.min.apply(null, lats),
          lng: Math.min.apply(null, lngs),
        },
      }

      return boundsSidenav
    } else {
      const bounds = this.mapView.getBounds()
      return bounds
    }
  }
}
