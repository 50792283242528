import { Component, AfterViewInit, OnInit } from "@angular/core";
import { SubscriberComponent, Indicators } from "@targomo/client";
import { PlaceEndpoint, Place } from "../../../api";
import { AppModel } from "../../../model/appModel.service";
import { PieGraphValues } from "../../components/pie-chart/pie-chart.component";
import { MatCheckboxChange } from "@angular/material";
import { combineLatest, Observable } from "rxjs";
import { DataLoadingModel } from "../../../model/dataLoadingModel";
import { MarkerSizeProperty } from "../../../model/settingsModel";
import {dateQuarters} from '../../../model/dateQuarter'
import { shareReplay, map, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'filter-dates',
  templateUrl: './filterDates.component.html',
  styleUrls: ['./filterDates.component.less'],
})
export class FilterDatesComponent extends SubscriberComponent implements OnInit {
  maxValue: number = 0
  minValue: number = 0

  lowerValue: number = null
  upperValue: number = null

  lowerValueText: string = null
  upperValueText: string = null

  showWithoutDate$: Observable<boolean>

  constructor(
    readonly appModel: AppModel, 
    private indicators: Indicators,
    private dataLoadingModel: DataLoadingModel,
  ) {
    super()

    this.showWithoutDate$ = this.appModel.settings.dateAddedQuarterMinMaxUpdates.pipe(
      map(minMax => !minMax || minMax.showNull),
      distinctUntilChanged(),
      shareReplay(1)
    )
  }

  ngOnInit() {
    this.watch(combineLatest(
      this.dataLoadingModel.maximums,
    ), ([maximums]) => {

      if (maximums.dateAdded && maximums.dateAdded.max) {
        this.minValue = dateQuarters.fromDate(maximums.dateAdded.min)
        this.maxValue = dateQuarters.fromDate(maximums.dateAdded.max)

        this.lowerValue = this.lowerValue == null ? this.minValue: Math.max(this.lowerValue, this.minValue)
        this.upperValue = this.upperValue == null ? this.maxValue: Math.max(this.lowerValue, Math.min(this.upperValue, this.maxValue))

        this.lowerValueText = dateQuarters.toQuarterString(this.lowerValue)
        this.upperValueText = dateQuarters.toQuarterString(this.upperValue)
      }

    })


    this.watch(this.appModel.settings.dateAddedQuarterMinMaxUpdates, minMax => {
      if (minMax) {
        this.lowerValue = minMax.min
        this.upperValue = minMax.max

        this.lowerValueText = dateQuarters.toQuarterString(this.lowerValue)
        this.upperValueText = dateQuarters.toQuarterString(this.upperValue)
      }
    })
  }

  onSliderChange(which: 'upper' | 'lower') {
    this.appModel.settings.displaySettings.nextPropertyWithCurrent('dateAddedQuarterMinMax', (current: any) => {
      current = current || {min: this.minValue, max: this.maxValue, showNull: true}

      current.min = this.lowerValue
      current.max = this.upperValue

      return {...current}
    })    
  }

  toggleShowWithoutDate(event: MatCheckboxChange) {
    this.appModel.settings.displaySettings.nextPropertyWithCurrent('dateAddedQuarterMinMax', (current: any) => {
      current = current || {min: this.minValue, max: this.maxValue, showNull: true}
      current.showNull = event.checked

      return {...current}
    })
  }
}
