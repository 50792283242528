import { Indicators } from '@targomo/client'
import { from, combineLatest, Observable } from 'rxjs'
import { map, shareReplay, switchMap } from 'rxjs/operators'
import { CareHomeBedFactorsValues } from '../../../common/models/settings'
import {
  AllStatistics,
  CensusStatisticsAges,
  DwellingPerPrice,
  DwellingTypesAreaValue,
  DwellingTypesPerPrice,
  LabelledStatisticsValue,
  ProjectedAges,
  StatisticsValue,
  sumStatisticsValues,
  POPULATION_FORECAST_TO_YEAR,
} from '../../../common/models/statistics/statistics'
import { SettingsEndpoint } from '../../api'
import { AbstractLocation } from '../entities'
import { PlacesModel } from '../placesModel'
import { SettingsModel } from '../settingsModel'
import { StatisticsModel } from '../statisticsModel'

export const CARE_HOME_PROPERTY_BEDS = 'Max Occupancy'
export const CARE_HOME_PROPERTY_EN_SUITE = 'Rooms with ensuite'

// TODO
function isCareHome(location: AbstractLocation) {
  return CARE_HOME_PROPERTY_BEDS in location.properties
}

export class CareHomeBedFactors implements CareHomeBedFactorsValues {
  agesUnder65?: number
  ages65to69?: number
  ages70to74?: number
  ages75to79?: number
  ages80to84?: number
  ages85to89?: number
  ages90plus?: number

  constructor(data?: CareHomeBedFactorsValues) {
    if (data) {
      Object.assign(this, data)
    }
  }

  calculateBedRequirement(input: CareHomeBedFactorsValues): number {
    const keys: (keyof CareHomeBedFactorsValues)[] = [
      'agesUnder65',
      'ages65to69',
      'ages70to74',
      'ages75to79',
      'ages80to84',
      'ages85to89',
      'ages90plus',
    ]

    return keys.reduce((acc, key) => {
      const factor = +this[key] || 0
      const value = +input[key] || 0

      return acc + (factor / 100) * value
    }, 0)
  }
}

export class CareHomeYearReport {
  aged65to74: LabelledStatisticsValue
  aged75to84: LabelledStatisticsValue
  aged85AndOver: LabelledStatisticsValue
  aged65AndOver: LabelledStatisticsValue
  population: LabelledStatisticsValue
  bedRequirement: LabelledStatisticsValue

  provisionBeds: number
  provisionHomes: number
  provisionEnSuite: number
  overUnderSupply: number
  overUnderSupplyEnSuite: number
}

export type CareHomePricesReport = Record<
  string,
  {
    avgValue: number
    avgValueIndexVsAvg: number
    sqmPrice: number
    sqmPriceIndexVsAvg: number
  }
>

export class CareHomeReport {
  constructor(
    readonly currentYear: number,
    readonly current: CareHomeYearReport,
    readonly horizon: CareHomeYearReport,
    readonly horizonYear: number,
    readonly statistics: AllStatistics,
    readonly competition: AbstractLocation[],
    readonly locations: AbstractLocation[],
    readonly sources: AbstractLocation[],
    readonly additional?: {
      year: number
      horizon: CareHomeYearReport
    }[]
  ) {}

  summaryDwellingTypes() {
    const KEYS_COUNT = ['semiDetached', 'detatched', 'terrace', 'flat']
    const KEYS = ['semiDetached', 'detached', 'terraced', 'flat', 'all']

    return KEYS.reduce((acc, key, i) => {
      const units: number =
        key === 'all'
          ? sumStatisticsValues([
              this.statistics.census.accomodation.semiDetached,
              this.statistics.census.accomodation.terrace,
              this.statistics.census.accomodation.detatched,
              this.statistics.census.accomodation.flat,
            ]).total
          : this.statistics.census.accomodation[KEYS_COUNT[i]].total
      const areaValue: DwellingTypesAreaValue = (this.statistics.census.dwellingAreaValuePerType as any)[key]

      const avgValue = areaValue.value.total / units
      const sqmPrice = areaValue.value.total / areaValue.area.total

      acc[key] = {
        avgValue,
        avgValueIndexVsAvg: (avgValue / areaValue.value.index) * 100,
        sqmPrice,
        sqmPriceIndexVsAvg: (sqmPrice / (areaValue.value.index / areaValue.area.index)) * 100,
      }

      return acc
    }, {} as CareHomePricesReport)
  }

  summaryForTravelBands(bands: number[]) {
    const bandLocations = bands.map(() => [])
    ;(this.locations || []).forEach((location) => {
      bands.forEach((time, i) => {
        if (time * 60 > location.travelTime && location.travelTime >= 0 && isFinite(location.travelTime)) {
          bandLocations[i].push(location)
        }
      })
    })

    function sumStats(...statisticsValues: StatisticsValue[]) {
      const bandStatistics = bands.map(() => 0)
      statisticsValues.forEach((value) => {
        bands.forEach((time, i) => {
          Object.keys(value.values).forEach((timeKey) => {
            if (+timeKey <= time * 60) {
              bandStatistics[i] += +value.values[+timeKey] || 0
            }
          })
        })
      })

      return bandStatistics
    }

    const ages65plus = sumStats(
      this.statistics.census.ages.aged65to74,
      this.statistics.census.ages.aged75to84,
      this.statistics.census.ages.aged85to89,
      this.statistics.census.ages.aged90AndOver
    )

    return {
      locations: bandLocations.map((items) => items.length),
      beds: bandLocations.map((items) =>
        items.reduce((acc, cur) => {
          return acc + (+cur.properties[CARE_HOME_PROPERTY_BEDS] || 0)
        }, 0)
      ),
      ages65plus,
    }
  }

  summaryTotal() {
    const bandLocations = this.competition
    const ages65plus = sumStatisticsValues([
      this.statistics.census.ages.aged65to74,
      this.statistics.census.ages.aged75to84,
      this.statistics.census.ages.aged85to89,
      this.statistics.census.ages.aged90AndOver,
    ]).total

    return {
      locations: bandLocations.length,
      beds: bandLocations.reduce((acc, cur) => {
        return acc + (+cur.properties[CARE_HOME_PROPERTY_BEDS] || 0)
      }, 0),
      ages65plus,
    }
  }
}

export class CareHomeReportModel {
  promise$: Observable<Promise<CareHomeReport>>
  value$: Observable<CareHomeReport>

  constructor(
    private indicators: Indicators,
    private settings: SettingsModel,
    private statistics: StatisticsModel,
    private places: PlacesModel,
    private settingsEndpoint: SettingsEndpoint
  ) {
    this.promise$ = this.initReport()
    this.value$ = this.promise$.pipe(
      switchMap((x) => x),
      shareReplay(1)
    )
  }

  createReport() {}

  private percentIndex(values: CareHomeYearReport) {
    Object.keys(values).forEach((key: keyof CareHomeYearReport) => {
      const property: LabelledStatisticsValue = values[key] as any
      if (property.index != null && property.percent != null) {
        property.percentOverIndex = (property.percent * 100) / property.index
      }
    })
  }

  private initReport() {
    // TODO:
    const locations$ = combineLatest(this.places.reachableFilteredPlaces.value, this.places.sources.observable).pipe(
      map(([locations, sources]) => {
        const competition = (locations || []).filter(isCareHome)
        locations = [].concat(competition, (sources || []).filter(isCareHome))

        const provisionBeds = locations.reduce((acc, cur) => {
          return acc + (+cur.properties[CARE_HOME_PROPERTY_BEDS] || 0)
        }, 0)

        const provisionEnSuite = locations.reduce((acc, cur) => {
          return acc + (+cur.properties[CARE_HOME_PROPERTY_EN_SUITE] || 0)
        }, 0)

        return {
          locations,
          competition,
          current: {
            provisionBeds,
            provisionHomes: locations.length,
            provisionEnSuite,
          },
        }
      }),
      shareReplay(1)
    )

    return combineLatest(
      this.statistics.census.value,
      this.settings.careHomeForecastYearUpdates,
      locations$,
      this.places.sources.observable,
      from(this.settingsEndpoint.getPublic())
    ).pipe(
      map(async ([statistics, projectedYear, locations, sources, settings]) => {
        if (!statistics) {
          return null
        }

        function sumAges(...keys: (keyof CensusStatisticsAges)[]) {
          return sumStatisticsValues(keys.map((key) => statistics.census.ages[key]))
        }

        function sumYearAges(from: number, to: number) {
          return sumStatisticsValues(statistics.census.agesPerYear.ages.slice(from, to + 1))
        }

        const current = new CareHomeYearReport()
        current.aged65to74 = { name: 'Aged 65 to 74', ...statistics.census.ages.aged65to74 }
        current.aged75to84 = { name: 'Aged 75 to 84', ...statistics.census.ages.aged75to84 }
        current.aged85AndOver = { name: 'Ages 85+', ...sumAges('aged85to89', 'aged90AndOver') }
        current.aged65AndOver = {
          name: 'Ages 65+',
          ...sumAges('aged65to74', 'aged75to84', 'aged85to89', 'aged90AndOver'),
        }
        current.population = { name: 'All Population', ...statistics.census.population2011.population }

        const bedFactors = new CareHomeBedFactors(
          (settings && settings.careHomeReport && settings.careHomeReport.ratios) || {}
        )

        const currentBedGroups = {
          agesUnder65: sumYearAges(0, 64).total,
          ages65to69: sumYearAges(65, 69).total,
          ages70to74: sumYearAges(70, 74).total,
          ages75to79: sumYearAges(75, 79).total,
          ages80to84: sumYearAges(80, 84).total,
          ages85to89: sumYearAges(85, 89).total,
          ages90plus: sumYearAges(90, 101).total,
        }

        current.bedRequirement = <any>{
          name: `Care Bed Requirement`,
          total: bedFactors.calculateBedRequirement(currentBedGroups),
        }

        function decorateWithCurrentSupply(yearReport: CareHomeYearReport) {
          if (locations) {
            yearReport.provisionBeds = locations.current.provisionBeds
            yearReport.provisionEnSuite = locations.current.provisionEnSuite
            yearReport.provisionHomes = locations.current.provisionHomes
            yearReport.overUnderSupply = yearReport.provisionBeds - yearReport.bedRequirement.total
            yearReport.overUnderSupplyEnSuite = yearReport.provisionEnSuite - yearReport.bedRequirement.total
          }
        }

        this.percentIndex(current)

        const { horizon } = this.calculateForYear(projectedYear, statistics, bedFactors, current)

        const currentYear = new Date().getFullYear()

        const additional = [
          this.calculateForYear(currentYear + 5, statistics, bedFactors, current),
          this.calculateForYear(currentYear + 10, statistics, bedFactors, current),
        ]

        decorateWithCurrentSupply(current)
        decorateWithCurrentSupply(horizon)
        decorateWithCurrentSupply(additional[0].horizon)
        decorateWithCurrentSupply(additional[1].horizon)

        return new CareHomeReport(
          currentYear,
          current,
          horizon,
          projectedYear,
          statistics,
          locations.competition,
          locations.locations,
          sources,
          additional
        )
      }),
      shareReplay(1)
    )
  }

  private calculateForYear(
    year: number,
    statistics: AllStatistics,
    bedFactors: CareHomeBedFactors,
    baseReport: CareHomeYearReport
  ) {
    year = Math.min(year, POPULATION_FORECAST_TO_YEAR)

    const projectedPopulation: ProjectedAges = Object.assign(
      new ProjectedAges(),
      JSON.parse(JSON.stringify(statistics.projectedPopulation.year[year]))
    )

    projectedPopulation.seal()

    function sumProjected(...keys: (keyof ProjectedAges)[]) {
      return sumStatisticsValues(keys.map((key) => projectedPopulation[key]))
    }

    const horizon = new CareHomeYearReport()
    horizon.aged65to74 = { name: 'Aged 65 to 74', ...sumProjected('age65to69', 'age70to74') }

    horizon.aged75to84 = { name: 'Aged 75 to 84', ...sumProjected('age75to79', 'age80to84') }

    horizon.aged85AndOver = { name: 'Ages 85+', ...sumProjected('age85to89', 'age90Plus') }

    horizon.aged65AndOver = {
      name: 'Ages 65+',
      ...sumProjected('age65to69', 'age70to74', 'age75to79', 'age80to84', 'age85to89', 'age90Plus'),
    }

    horizon.population = {
      name: `All Population (${year})`,
      ...sumProjected(
        'age0to4',
        'age5to9',
        'age10to14',
        'age15to19',
        'age20to24',
        'age25to29',
        'age30to34',
        'age35to39',
        'age40to44',
        'age45to49',
        'age50to54',
        'age55to59',
        'age60to64',
        'age65to69',
        'age70to74',
        'age75to79',
        'age80to84',
        'age85to89',
        'age90Plus'
      ),
    }

    const horizonBedGroups = {
      // ben said ignore?
      agesUnder65: sumProjected(
        'age0to4',
        'age5to9',
        'age10to14',
        'age15to19',
        'age20to24',
        'age25to29',
        'age30to34',
        'age35to39',
        'age40to44',
        'age45to49',
        'age50to54',
        'age55to59',
        'age60to64'
      ).total,
      ages65to69: sumProjected('age65to69').total,
      ages70to74: sumProjected('age70to74').total,
      ages75to79: sumProjected('age75to79').total,
      ages80to84: sumProjected('age80to84').total,
      ages85to89: sumProjected('age85to89').total,
      ages90plus: sumProjected('age90Plus').total,
    }

    horizon.bedRequirement = <any>{
      name: `Care Bed Requirement (${year})`,
      total: bedFactors.calculateBedRequirement(horizonBedGroups),
    }
    this.percentIndex(horizon)

    const comparisonKeys: (keyof CareHomeYearReport)[] = [
      'aged65to74',
      'aged75to84',
      'aged85AndOver',
      'aged65AndOver',
      'population',
      'bedRequirement',
    ]

    comparisonKeys.forEach((key) => {
      const horizonValue: LabelledStatisticsValue = horizon[key] as LabelledStatisticsValue
      const baseValue: LabelledStatisticsValue = baseReport[key] as LabelledStatisticsValue
      horizonValue.changeOverBase = (horizonValue.total - baseValue.total) / baseValue.total
    })

    return { horizon, year }
  }
}
