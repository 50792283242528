<div *ngIf="minValue != null && maxValue != null">
  <div class="quarter-labels" fxLayout="row">
    <div fxFlex="50%">{{lowerValueText}}</div>
    <div fxFlex="50%">{{upperValueText}}</div>
  </div>

  <tgm-custom-rangeslider 
    [margin]="1" [step]="1" [min]="minValue"  [max]="maxValue" 
    [(minValue)]="lowerValue" [(maxValue)]="upperValue" 
    (minValueChange)="onSliderChange('lower')" (maxValueChange)="onSliderChange('upper')"></tgm-custom-rangeslider>

  <div style="margin: 0px 16px">
    <mat-checkbox
      (change)="toggleShowWithoutDate($event)"
      [checked]="showWithoutDate$ | async">Show locations without date</mat-checkbox>
  </div>
</div>