import { DisplaySettings, TravelDisplayMode } from '../../model/settingsModel'

import { Component, Input, OnInit, ElementRef, SimpleChange, AfterViewInit, NgZone } from '@angular/core'
import { LatLng } from '@targomo/core'
import { Subscription } from 'rxjs/Subscription'
// import { AppMapModel } from '../../models/behaviors/appMap';
// import { CUSTOMER_STATISTICS } from '../../models/constants/statistics';
import { LocalMapModel } from '../../model/localMapModel'
import { HomeComponent } from '../home.component'
import { AppModel } from '../../model/appModel.service'
import { SubscriberComponent } from '@targomo/client'
import { DecimalPipe } from '@angular/common'

// use: <hover-info-display class="map-layout-hover-info" [showHover]="showHover" [mapModel]="appModel.mapModel"></hover-info-display>
// transcludes into map sidenav

const PEDESTRIAN_STATISTICS: any[] = [
  {
    id: 0,
    name: 'pedestrian',
    label: 'Pedestrian flow',
  },
]

const WORKFORCE_STATISTICS: any[] = [
  {
    id: 0,
    name: 'workforce',
    label: 'Workforce',
  },
]

const ROADS_VOLUME_STATISTICS: any[] = [
  { key: 'all_hgvs', label: 'All hgvs' },
  { key: 'all_motor_vehicles', label: 'All motor vehicles' },
  { key: 'buses_and_coaches', label: 'Buses and coaches' },
  { key: 'cars_and_taxis', label: 'Cars and taxis' },
  { key: 'hgvs_2_rigid_axle', label: 'Hgvs 2 rigid axle' },
  { key: 'hgvs_3_or_4_articulated_axle', label: 'Hgvs 3 or 4 articulated axle' },
  { key: 'hgvs_3_rigid_axle', label: 'Hgvs 3 rigid axle' },
  { key: 'hgvs_4_or_more_rigid_axle', label: 'Hgvs 4 or more rigid axle' },
  { key: 'hgvs_5_articulated_axle', label: 'Hgvs 5 articulated axle' },
  { key: 'hgvs_6_articulated_axle', label: 'Hgvs 6 articulated axle' },
  { key: 'lgvs', label: 'Lgvs' },
  { key: 'pedal_cycles', label: 'Pedal cycles' },
  { key: 'two_wheeled_motor_vehicles', label: 'Two wheeled motor vehicles' },
]

@Component({
  selector: 'hover-info-display',
  styleUrls: ['./hoverInfoDisplay.less'],
  template: `
    <back-button></back-button>
    <div style="padding: 0px 16px;">
      <div class="pinned" *ngIf="hoverInfoPinned && showThematic">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h3 fxFlex>Thematic layer information (Pinned)</h3>
          <button title="Clear" (click)="clearPinned()" type="button" mat-icon-button>
            <mat-icon class="mat-20">clear</mat-icon>
          </button>
        </div>

        <div class="hover-info-list">
          <div class="hover-list-item" *ngFor="let item of hoverInfoPinned">
            <div>{{ item.label }}</div>
            <div class="hover-list-value">
              <div>{{ item.value > 0 ? DECIMAL_PIPE.transform(item.value, '1.0') : '-' }}</div>
              <div>{{ item.type === 'percent' && item.value > 0 ? '%' : '' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showThematic">
        <h3>Thematic layer information</h3>

        <div class="hover-info-list">
          <div class="hover-list-item" *ngFor="let item of hoverInfo">
            <div>{{ item.label }}</div>
            <div class="hover-list-value">
              <div>{{ item.value > 0 ? DECIMAL_PIPE.transform(item.value, '1.0') : '-' }}</div>
              <div>{{ item.type === 'percent' && item.value > 0 ? '%' : '' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showHex">
        <h3>Statistics layer information</h3>

        <div class="hover-info-list">
          <div class="hover-list-item" *ngFor="let item of hoverInfoHex">
            <div>{{ item.label }}</div>
            <div class="hover-list-value">
              <div>{{ item.value > 0 ? DECIMAL_PIPE.transform(item.value, '1.0') : '-' }}</div>
              <div>{{ item.type === 'percent' && item.value > 0 ? '%' : '' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class HoverInfoDisplayComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  hoverInfoPinned: any[] = []
  hoverInfo: any[] = []
  hoverInfoHex: any[] = []
  showThematic: boolean = true
  showHex: boolean = false
  showHover: boolean = false

  DECIMAL_PIPE = new DecimalPipe('en')

  constructor(
    private elementRef: ElementRef,
    private zone: NgZone,
    private homeComponent: HomeComponent,
    private appModel: AppModel
  ) {
    super()
  }

  ngOnInit() {
    this.hoverInfo = []
    //  this.mapModel.getAllStatisticsKeys().map((statistic: any) => {
    //   return {label: statistic.label, value: '-'}
    // })

    this.remember(
      this.appModel.settings.cellHoverUpdates.subscribe((visible) => {
        this.showHover = visible
      })
    )

    this.remember(
      this.appModel.displaySettings.subscribe((options: DisplaySettings) => {
        if (!options.pedestrianLayer && !options.workforceLayer && !options.roadsVolumeLayer) {
          this.hoverInfo = []
          this.showThematic = false
        } else {
          this.showThematic = true
        }

        if (options.travelDisplayMode == TravelDisplayMode.NoThematicPolygons) {
          this.hoverInfoHex = []
          this.showHex = false
        } else {
          this.showHex = true
        }
      })
    )
  }

  ngAfterViewInit() {
    this.remember(
      this.appModel.mapModel.clickRoadsVolume.subscribe((cell) => {
        if (!this.showHover) return

        this.zone.run(() => {
          if (cell) {
            this.hoverInfoPinned = ROADS_VOLUME_STATISTICS.map((statistic: any) => {
              return { label: statistic.label, value: ~~cell.properties[statistic.key], type: statistic.type }
            })
          } else {
            this.hoverInfoPinned = null
          }
        })
      })
    )

    this.remember(
      this.appModel.mapModel.hoverRoadsVolume.subscribe((cell) => {
        if (!this.showHover) return

        this.zone.run(() => {
          if (cell) {
            this.hoverInfo = ROADS_VOLUME_STATISTICS.map((statistic: any) => {
              return { label: statistic.label, value: ~~cell.properties[statistic.key], type: statistic.type }
            })
          } else {
            this.hoverInfo = ROADS_VOLUME_STATISTICS.map((statistic: any) => {
              return { label: statistic.label, value: '-' }
            })
          }
        })
      })
    )

    this.remember(
      this.appModel.mapModel.hoverWorkforce.subscribe((cell) => {
        if (!this.showHover) return

        this.zone.run(() => {
          if (cell) {
            this.hoverInfo = WORKFORCE_STATISTICS.map((statistic: any) => {
              return { label: statistic.label, value: ~~cell.properties[statistic.id], type: statistic.type }
            })
          } else {
            this.hoverInfo = WORKFORCE_STATISTICS.map((statistic: any) => {
              return { label: statistic.label, value: '-' }
            })
          }
        })
      })
    )

    this.remember(
      this.appModel.mapModel.hoverPedestrian.subscribe((cell) => {
        if (!this.showHover) return

        this.zone.run(() => {
          if (cell) {
            this.hoverInfo = PEDESTRIAN_STATISTICS.map((statistic: any) => {
              return { label: statistic.label, value: ~~cell.properties[statistic.id], type: statistic.type }
            })
          } else {
            this.hoverInfo = PEDESTRIAN_STATISTICS.map((statistic: any) => {
              return { label: statistic.label, value: '-' }
            })
          }
        })
      })
    )

    this.remember(
      this.homeComponent.mainLayer.events.hoverCell.subscribe((event) => {
        const options = <DisplaySettings>this.appModel.displaySettings.getValue()
        const statistic = options.statistic

        if (!this.showHover || options.travelDisplayMode == TravelDisplayMode.NoThematicPolygons) {
          return
        }

        this.zone.run(() => {
          if (event.properties && event.properties.statistics != null) {
            this.hoverInfoHex = [statistic].map((statistic: any) => {
              return { label: statistic.label, value: ~~event.properties.statistics }
            })
          } else if (event.properties) {
            this.hoverInfoHex = [statistic].map((statistic: any) => {
              return { label: statistic.label, value: ~~event.properties[statistic.id], type: statistic.type }
            })
          } else {
            this.hoverInfoHex = [statistic].map((statistic: any) => {
              return { label: statistic.label, value: '-' }
            })
          }
        })
      })
    )
  }

  clearPinned() {
    this.hoverInfoPinned = null
  }
}
