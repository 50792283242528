<section style="padding: 0px 16px">
  <h3>Draw zone</h3>

  <div>
    <div class="tip">
      <p>
        Click on the map to draw an area for this zone.
      </p>

      <p>
        <b style="color: black">NOTE:</b> Zones are only saved when a project is saved.
      </p>
    </div>

    <div fxLayout="row" fxLayoutGap="6px">
        <button [disabled]="isCircle" title="Draw shape" mat-icon-button (click)="toggle()">
          <mat-icon [color]="currentMode == 'draw_polygon' ? 'primary' : null" class="mat-20">create</mat-icon>
        </button>

        <button [disabled]="!mapDrawLayer.isEmpty()" title="Draw radius" mat-icon-button (click)="drawCircle()">
          <mat-icon [color]="currentMode == 'draw_circle' ? 'primary' : null" class="mat-20">add_circle_outline</mat-icon>
        </button>

        <button title="Edit shape nodes" mat-icon-button  (click)="select()">
            <mat-icon [color]="currentMode == 'direct_select' ? 'primary' : null" class="mat-20">device_hub</mat-icon>
          </button>

        <div fxLayout="row" fxLayoutGap="6px">
          <button title="Remove current shape" mat-icon-button (click)="delete()">
            <mat-icon  class="mat-20">delete</mat-icon>
          </button>

        </div>
      </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column" novalidate>
    <mat-form-field>
      <input matInput type="text" formControlName="name"  required placeholder="Zone name">

      <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
        <span>Zone name is required</span>
      </mat-hint>
    </mat-form-field>
  </form>

  <div [hidden]="!isCircle" style="margin: 16px 0px">
    <ng5-slider
        [value]="circleRadius"
        [options]="sliderOptions"
        (valueChange)="circleRadiusChange($event)"
    ></ng5-slider>

  </div>

  <div class="bottom-buttons">
    <button type="button" mat-button (click)="cancel()"><span>Cancel</span></button>
    <button mat-button color="primary" (click)="submit()" type="submit"><span>Save</span></button>
  </div>
</section>