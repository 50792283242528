import { Component } from '@angular/core';
import { DataSet } from "../../../api";
import {Auth, TgmQuickDialogs} from '@targomo/client';
import {DataSetEndpoint} from '../../../api/dataSet';
import {Router, ActivatedRoute} from '@angular/router';
import { GeoRegionsEndpoint } from '../../../api/georegions';
import { ComparisonReportTemplate } from '../../../../common/models';
import { ComparisonReportTemplateEndpoint } from '../../../api/comparisonReportTemplate';

@Component({
  selector: 'admin-comparison-report-templates-list',
  templateUrl: './adminComparisonReportTemplatesList.component.html'
})
export class AdminComparisonReportTemplatesListComponent {
  templates: ComparisonReportTemplate[]
  loading: Promise<any[]>

  constructor(
    private templateEndpoint: ComparisonReportTemplateEndpoint, 
    private route: ActivatedRoute, 
    private router: Router,
    private quickDialogs: TgmQuickDialogs) {
  }

  ngOnInit() {
    this.updateList()
  }

  async updateList() {
    this.templates = await this.templateEndpoint.findAll()
  }

  async deleteTemplate(template: ComparisonReportTemplate) {
    if (await this.quickDialogs.confirm({
      title: 'Delete Report Template',
      message: `Would you like to delete template ${template.name})?`,
      confirm: 'Delete',
      cancel: 'Cancel'
    })) {
      await this.templateEndpoint.delete(template)
      this.updateList()
    }
  }

  showMenu(event: any) {
    event.stopPropagation()
  }
}
