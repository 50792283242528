
    <mat-list-item class="select-boundaries" title="Boundaries Layer">
      <div fxFlex *ngIf="label">Boundaries Layer</div>

      <mat-select fxFlex [ngModel]="selected" (ngModelChange)="changeStatistic($event)">
      <mat-option title="None" [value]="-1">None</mat-option>
      <mat-option title="{{option.name}}" *ngFor="let option of listOptions" [value]="option.id">{{option.name}}</mat-option>
      </mat-select>
    </mat-list-item>

    <mat-list-item>
      <div fxFlex>Show only user zones</div>

      <mat-slide-toggle [ngModel]="appModel.settings.showOnlyUserZonesUpdates | async" (ngModelChange)="changeShowOnlyUser($event)">
      </mat-slide-toggle>
    </mat-list-item>    
  