// import {PlacesModel} from '../places/PlacesModel'
// import {Entity, Place, PlaceResource} from '../../../models'
// import * as exportData from './exportMaxiReport'
// import {statistics} from '../../../api/index';

// // TODO: add some common code that accepts a promise, shows a loading widget and when done pops the dialog

import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChange,
  OnDestroy,
  ViewChild,
  Inject,
  Renderer,
  ElementRef,
  ViewContainerRef,
} from '@angular/core'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatTabChangeEvent } from '@angular/material'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs/Subscription'
import { AppModel } from '../../model/appModel.service'
import { ExportPDFReport, ExportMapImage } from '../pdf/exportPdfReport'
import { exportData } from './exportMaxiReport'
import { AllStatistics } from '../../../common/models/statistics/statistics'
import { PlanetOrganicBenchmarkComponent } from './benchmarks/planetOrganicBenchmark.component'
import { CodcodimamaBenchmarkComponent } from './benchmarks/cocodimamaBenchmark.component'
import { BenchmarkReportComponent } from './benchmarkReport'
import { PlaceEndpoint } from '../../api/place'
import { ZoneLayersModel } from '../../model/zoneLayersModel'
import { Observable } from 'rxjs/Observable'
import { UserEventLogEndpoint } from '../../api/userEventLog'
import { SubscriberComponent, Auth, Indicators } from '@targomo/client'
import { UserSettingsEndpoint } from '../../api/userSettings'
import { BENCHMARK_SOURCES_THRESHOLD, COMPARISON_SOURCES_THRESHOLD } from '../../model/placesModel'
import { MapPrintDialog } from '../dialogs/mapPrintDialog.component'
import { OpenDialog } from '../ui/openDialog.service'

// const TABLE_STATISTICS = [
//     {name: 'name', label: 'Name'},
//     {name: 'address', label: 'Adresse'},
//   ].concat(LIST_STATISTICS)

@Component({
  selector: 'maxi-report-dialog',
  templateUrl: './maxiReportDialog.html',
  styleUrls: ['../sidenav/miniReportsPanel.less', './maxiReportDialog.less'],
})
export class MaxiReportDialog extends SubscriberComponent implements OnInit {
  @ViewChild('benchmarkChilango') benchmarkChilango: BenchmarkReportComponent
  @ViewChild('benchmarkCocoDiMama') benchmarkCocoDiMama: CodcodimamaBenchmarkComponent
  @ViewChild('benchmarkPlanetOrganic') benchmarkPlanetOrganic: PlanetOrganicBenchmarkComponent

  available: boolean = false
  showBenchmark = false
  showWait = false
  showCocodimamaBenchmark: boolean = true
  showPlanetOrganicBenchmark: boolean = true
  statistics: AllStatistics
  showPlanning = false
  permissions: any

  benchmarkDisabled = false
  comparisonDisabled = false

  constructor(
    readonly dialogRef: MatDialogRef<MaxiReportDialog>,
    readonly appModel: AppModel,
    private exportPDFReport: ExportPDFReport,
    private places: PlaceEndpoint,
    readonly zoneLayersModel: ZoneLayersModel,
    private userEventLogEndpoint: UserEventLogEndpoint,
    private userSettingsEndpoint: UserSettingsEndpoint,
    private auth: Auth,
    private viewContainerRef: ViewContainerRef,
    private element: ElementRef,
    private indicators: Indicators,
    private openDialog: OpenDialog,
    @Inject(MAT_DIALOG_DATA)
    private dialogData: {
      exportMapReportImage: ExportMapImage
    }
  ) {
    super()

    this.watch(this.appModel.places.sources.observable, (sources) => {
      this.benchmarkDisabled = sources && sources.length > BENCHMARK_SOURCES_THRESHOLD
      this.comparisonDisabled = sources && sources.length > COMPARISON_SOURCES_THRESHOLD
    })

    this.watch(this.appModel.statistics.census.promise, async (promise) => {
      this.available = false
      const statistics = (this.statistics = await promise)
      this.available = !!statistics
    })

    const dataSets = this.appModel.places.dataSets.getValue()
    function findPlaces(name: string) {
      name = name.toUpperCase()
      return dataSets.filter((dataset) => dataset.name.toUpperCase() == name).length > 0
    }

    this.showBenchmark = findPlaces('Chilango Direct Competitors') && findPlaces('Chilango Complementary')
    this.showCocodimamaBenchmark = findPlaces('Coco di Mama Competitors')
    this.showPlanetOrganicBenchmark = findPlaces('Planet Organic Competitors')
    this.initPermisions()
  }

  private async initPermisions() {
    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
    }
  }

  async ngOnInit() {
    this.watch(this.appModel.places.reachableFilteredPlanningNonPassive.value, (planningPlaces) => {
      this.showPlanning = planningPlaces && planningPlaces.length > 0
    })
  }

  async export(completeData: boolean = false) {
    const dialogOptions = { fileName: 'storepointgeo_report', title: '', showTitle: false }
    let { fileName } = (await this.openDialog.show(MapPrintDialog, dialogOptions)) || dialogOptions

    this.indicators.add(this.appModel.maxiReportModel.export(completeData, fileName))

    // const reports: {locations: any[], columns: any[], fileName: string}[] = []

    // if (this.benchmarkChilango) {
    //   reports.push(await this.benchmarkChilango.export())
    // }

    // if (this.benchmarkCocoDiMama) {
    //   reports.push(await this.benchmarkCocoDiMama.export())
    // }

    // if (this.benchmarkPlanetOrganic) {
    //   reports.push(await this.benchmarkPlanetOrganic.export())
    // }

    // // workaround
    // const zonesSelected = await Observable.combineLatest(this.zoneLayersModel.selectionExistsUpdates).map(([value]) => value).take(1).toPromise()
    // exportData(this.appModel, this.places, reports, completeData, zonesSelected)
  }

  async exportPdf() {
    const dialogOptions = { fileName: 'storepointgeo_report', title: '' }
    let { title, fileName } = (await this.openDialog.show(MapPrintDialog, dialogOptions)) || dialogOptions

    this.showWait = true
    this.exportPDFReport.save(this.dialogData.exportMapReportImage, { title, fileName }).then(() => {
      this.showWait = false
    })

    this.userEventLogEndpoint.pdfDownloaded()
  }

  exportBatchPdf() {
    this.showWait = true
    this.exportPDFReport.saveBatch(this.dialogData.exportMapReportImage).then(() => {
      this.showWait = false
    })

    this.userEventLogEndpoint.pdfDownloaded()
  }

  changeTab(event: MatTabChangeEvent) {
    const comparisonTab = event.tab.textLabel === 'Comparison'
    const panel = document.querySelector('.maxi-dialog-container-marker-class')
    if (panel) {
      if (comparisonTab) {
        panel.classList.add('comparison-tab')
      } else {
        panel.classList.remove('comparison-tab')
      }
    }
  }
}
