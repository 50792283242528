import { Injectable } from '@angular/core';
import { RESTHelper } from '@targomo/client';
import { Settings } from '../../common/models';
import { SettingsCareHome } from '../../common/models/settings';

export type Settings = Settings

@Injectable()
export class SettingsEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/settings')
  }

  get() {
    return this.http.get<Settings>(`/`)
  }

  getPublic() {
    return this.http.get<Settings>(`/public`)
  }

  save(settings: Settings) {
    return this.http.put(`/`, settings)
  }

  saveCareHome(settings: SettingsCareHome) {
    return this.http.put(`/carehomereport`, settings)
  }

  getCareHome() {
    return this.http.get<SettingsCareHome>(`/carehomereport`)
  }
}