<div class="admin-panel">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Data Profiles</h2>

    <div class="secondary">
      <button mat-button class="mat-primary" routerLink="/admin/profile/new"><mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> New Data Profile</button>
    </div>
  </div>

  <mat-list>
    <!-- <mat-divider></mat-divider> -->
    <mat-list-item *ngFor="let profile of dataProfiles" routerLink="/admin/profile/{{profile.id}}">
      <div class="name-section" fxFlex fxLayout="column" >
        <span>{{profile.name}}</span>
      </div>

      <!--<mat-menu class="mat-secondary">
        <button mat-button (click)="$mdOpenMenu($event)" class="mat-icon-button" aria-label="Open some menu">
          <i class="material-icons">menu</i>
        </button>-->

        <button (click)="showMenu($event)" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="mat-24">more_vert</mat-icon></button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="/admin/profile/{{profile.id}}" aria-label="Edit">
            <mat-icon mat-font-set="material-icons">mode_edit</mat-icon>
            Edit Data Profile
          </button>


          <button mat-menu-item (click)="removeProfile(profile)" aria-label="Edit">
            <mat-icon mat-font-set="material-icons">delete</mat-icon>
            Remove Data Profile
          </button>
        </mat-menu>

        <!--<mat-menu-content>
          <mat-menu-item>
            <button mat-button routerLink="/admin/profile/{{profile.id}}" aria-label="Edit">
              <mat-icon mat-font-set="material-icons">mode_edit</mat-icon>
              Edit Data Profile
            </button>
          </mat-menu-item>

          <mat-menu-item>
            <button mat-button (click)="removeProfile(profile)" aria-label="Edit">
              <mat-icon mat-font-set="material-icons">delete</mat-icon>
              Remove Data Profile
            </button>
          </mat-menu-item>
        </mat-menu-content>-->
      <!--</mat-menu>-->

      <!-- <mat-divider></mat-divider> -->
    </mat-list-item>
  </mat-list>
</div>