<form class="form" (submit)="save()" novalidate [formGroup]="form" >
  <h3 class="mat-title">Upload Data Set</h3>

  <section fxLayout="column">
      <mat-form-field fxFlex style="width:100%">
        <input [readonly]="!!set" matInput type="text" formControlName="name" required placeholder="Data set name">
  
        <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
          <span translate="layers.layerNameRequired">Layer name required</span>
        </mat-hint>
      </mat-form-field>

      <mat-select *ngIf="showCategorizeField" placeholder="Categorize by" formControlName="categorize" fxFlex style="width:100%; margin: 30px 0px 30px 0px">
        <mat-option value="secondary_category">Secondary Category</mat-option>
        <mat-option value="holding_company">Holding Company</mat-option>
      </mat-select>
        
      <tgm-file-uploader placeholder="Upload CSV layer" (selected)="selectFile($event)"></tgm-file-uploader>

      <mat-hint *ngIf="locationError" [ngStyle]="{'color': 'red'}" style="font-size: 75%">
        <span>{{locationError}}</span>
      </mat-hint>
      
      <div class="found-locations" *ngIf="!!locationsFound">
        {{locationsLength}} location(s) found in file
      </div>
  </section>

  <mat-hint *ngIf="locationCategorizeError" [ngStyle]="{'color': 'red'}" style="font-size: 75%">
    <span>{{locationCategorizeError}}</span>
  </mat-hint>

  <div class="align-right" style="margin-top: 16px">
    <div style="margin: 8px; font-size: 13px; text-align: center" *ngIf="saving">Please wait...</div>

    <div style="margin-bottom: 6px"><tgm-saveable [model]="saving"></tgm-saveable></div>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button [disabled]="!locationsFound || !!locationError || isSaving" mat-flat-button color="primary" type="submit">Save</button>
  </div>

  <expandable-panel class="help-panel" text="Help">
    <div>
      <div style="margin-bottom: 20px; line-height: 1.4em">
        <p>You can upload a CSV file with longitude/latitude coordinates in WGS84 (EPSG:4326). </p>
        <p>Fields are comma <code class="quote">,</code> separated. Fields containing commas have to be quoted in straight double-quotes <code class="quote">"</code>.</p>
        <p>The first line must be the header line with the column names, it must contain the following columns:</p>
      </div>

      <div class="fields-description">
        <div *ngFor="let field of availableDataFields"><code>{{field}}</code> <code style="font-size: 11px; margin-left: 8px; color: red" *ngIf="requiredDataFields[field]" class="required">[REQUIRED]</code></div>
          
        <!-- <div><code>lat</code></div>
        <div><code>lng</code></div>
        <div><code>lat_alt</code></div>
        <div><code>lng_alt</code></div>
        <div><code>store_id</code></div>
        <div><code>primary_category</code></div>
        <div><code>secondary_category</code></div>
        <div><code>holding_company</code></div>
        <div><code>fascia</code></div>
        <div><code>sitename</code></div>
        <div><code>paon</code></div>
        <div><code>saon</code></div>
        <div><code>taon</code></div>
        <div><code>street</code></div>
        <div><code>suburb</code></div>
        <div><code>town</code></div>
        <div><code>postcode</code></div>
        <div><code>district</code></div>
        <div><code>county</code></div>
        <div><code>region</code></div>
        <div><code>net_sales_area</code></div>
        <div><code>net_sales_area_source</code></div>
        <div><code>gross_internal_area</code></div>
        <div><code>gross_internal_area_source</code></div> -->
      </div>
    </div>
  </expandable-panel>  


  <expandable-panel class="help-panel" text="Templates" *ngIf="showTemplates">
      <div><a target="_blank" href="/assets/files/layer_template.csv">CSV Layer Template </a></div>
  </expandable-panel>
</form>
