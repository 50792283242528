import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppModel } from '../../../model/appModel.service';
import { TRAVEL_COLORS } from '@targomo/client';

@Component({
  selector: 'custom-travel-color-dialog',
  templateUrl: './customTravelColorDialog.component.html',
  styleUrls: ['./customTravelColorDialog.component.less']
})
export class CustomTravelColorDialogComponent implements OnInit {
  colors = [].concat(TRAVEL_COLORS)
  selectedIndex = 0

  constructor(
    public dialogRef: MatDialogRef<CustomTravelColorDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
    this.colors = dialogData && dialogData.colors || [].concat(TRAVEL_COLORS)
  }

  ngOnInit() {
  }

  selectColorIndex(i: number) {
    this.selectedIndex = i
  }

  save() {
    this.dialogRef.close(this.colors)
  }
}
