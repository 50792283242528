import * as chartjs from 'chart.js'

export async function createGraph(type: string, data: any, options: chartjs.ChartOptions = {}, width = 800, height = 500) {
  const createdCanvas = document.createElement('canvas')
  createdCanvas.width = width
  createdCanvas.height = height
  const ctx = createdCanvas.getContext('2d')

  const createdCanvasParent = document.createElement('div')
  createdCanvasParent.appendChild(createdCanvas)

  const chart = new chartjs.Chart(ctx, {
    type,
    data,
    options: {
      ...options,
      animation: false as any,
      responsive: false
    }
  })

  chart.update()

  await new Promise(resolve => setTimeout(() => resolve(true), 10))

  const dataURL = createdCanvas.toDataURL('image/png')

  return dataURL
}