import { Injectable } from '@angular/core'
import { DataSet, DataSetEndpoint } from '../api/dataSet'
import { Category } from '../api/category'
import { PlaceEndpoint, CategoryAverageSizes } from '../api/index'
import { Indicators } from '@targomo/client'
import { Observable } from 'rxjs/Observable'
import { DataSetLocation } from './index'
import { Place } from '../api/place'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Subject } from 'rxjs/Subject'
import { AbstractLocation } from './entities'
import * as models from '../../common/models'
import { map, take } from 'rxjs/operators'
import { object as objects } from '@targomo/common'

export type PostLoadFunction = (places: AbstractLocation[]) => void

const SIZE_CENTERPOIT_FILTER_CODES: Record<string, string> = {
  'TOWN CENTRE': 'HS',
  'SUBURBAN CENTRE': 'HS',

  'SHOPPING CENTRE': 'SC',
  'TOWN CENTRE SHOPPING CENTRE': 'SC',

  SOLUS: 'OOT',
  'INDUSTRIAL / TRADE': 'OOT',
  'LEISURE PARK': 'OOT',
  'RETAIL / SHOPPING PARK': 'OOT',
}

export function getCategoryKey(category: models.Category) {
  const version = category.dataSet != null ? category.dataSet.id + '@' : '?@'
  return version + category.grouping + '@' + category.code
}

export class CentrePointCategorize {
  private categoryIdsMap: Record<number, string> = {}

  constructor(
    dataSets: DataSet[],
    private centrepointMap: Record<string, AbstractLocation>,
    private filterCodes: Record<string, string>,
    private defaultCode = 'OT'
  ) {
    const centrePoint = dataSets.find((item) => item.name.toLowerCase() === 'centrepoint')

    if (centrePoint) {
      this.categoryIdsMap = centrePoint.categories.reduce((acc, cur) => {
        const code = filterCodes[cur.name.toUpperCase()] || filterCodes[cur.grouping.toUpperCase()] || defaultCode
        acc[cur.id] = code
        return acc
      }, {} as Record<number, string>)
    } else {
      this.categoryIdsMap = {}
    }
  }

  getCode(place: AbstractLocation): string {
    const other = place && place.other

    if (!other) {
      return null
    }

    const classification = other['Centre Classification'] || other['CENTRE CLASSIFICATION']
    if (classification) {
      return this.filterCodes[classification] || this.defaultCode
    }

    const centrePointId = other['CentrePoint ID'] || other['CENTREPOINT ID']

    if (centrePointId) {
      const centrePoint = this.centrepointMap[centrePointId]
      if (centrePoint) {
        return this.categoryIdsMap[centrePoint.category.id]
      }
    }

    return null
  }
}

// export class CategoryAverageCounter {
//   private totalCount = 0
//   private count = 0
//   private value = 0

//   add(value: number) {
//     this.totalCount++

//     if (value != null) {
//       this.count++
//       this.value += value
//     }
//   }

//   getAverage() {
//     return this.count === 0 ? null : this.value / this.count || 0
//   }
// }

@Injectable()
export class DataLoadingModel {
  readonly dataSets: Observable<DataSet[]>
  readonly dataSetVersionToDataSetMap: Observable<Record<number, DataSet>>
  readonly categoriesMap: Observable<{ [index: string]: Category }>
  private allPlacesSubject: BehaviorSubject<AbstractLocation[]>
  readonly allPlaces: Observable<AbstractLocation[]>
  private loaded: { [id: string]: Promise<any> } = {}
  private categoryDataSetMap: { [id: number]: DataSet } = {}
  readonly maximums: Observable<{
    netSalesArea: {
      min: any
      max: any
    }
    grossInternalArea: {
      min: any
      max: any
    }
    dateAdded: {
      min: string
      max: string
    }
  }>

  public readonly dataSetLoadedOrUpdated = new Subject<DataSet>()

  private postLoadFunctions: PostLoadFunction[]

  readonly centrepointMap = new BehaviorSubject<Record<string, AbstractLocation>>({})

  constructor(
    private placeEndpoint: PlaceEndpoint,
    private dataSetEndpoint: DataSetEndpoint,
    private indicators: Indicators
  ) {}

  init() {
    const self = this as any
    self.dataSets = this.initDataSets()
    self.categoriesMap = this.initCategoriesMap()
    self.allPlacesSubject = this.initLoadAll()
    self.allPlaces = this.allPlacesSubject.asObservable()
    self.maximums = this.initMaximums()
    self.loaded = {}
    self.categoryDataSetMap = {}
    self.postLoadFunctions = []

    self.dataSetVersionToDataSetMap = self.dataSets.pipe(
      map((dataSets) => {
        return ((dataSets as DataSet[]) || []).reduce((acc, cur: DataSet) => {
          acc[cur.versionId] = cur
          return acc
        }, {} as Record<number, DataSet>)
      })
    )

    this.loadSpecialLayers()
  }

  registerPostLoad(postLoad: PostLoadFunction) {
    this.postLoadFunctions.push(postLoad)
  }

  private initMaximums() {
    return Observable.fromPromise(this.indicators.add(this.placeEndpoint.maximums()))
  }

  private initDataSets() {
    return Observable.fromPromise(this.indicators.add(this.dataSetEndpoint.me()))
  }

  private initCentrePointMap(places: AbstractLocation[]) {
    this.centrepointMap.next(
      places.reduce((acc, cur) => {
        acc[cur.storeId] = cur
        return acc
      }, {} as Record<string, AbstractLocation>)
    )
  }

  private loadSpecialLayers() {
    const specialDataSetNames: any = {
      centrepoint: true,
      'chilango direct competitors': true,
      'chilango complementary': true,
      'coco di mama competitors': true,
      'planet organic competitors': true,
    }

    this.dataSets.take(1).subscribe(async (dataSets) => {
      const loadDataSets = dataSets.filter((dataSet) => specialDataSetNames[(dataSet.name || '').toLowerCase()])

      for (let dataSet of loadDataSets) {
        await this.loadDataSet(dataSet, true)
      }

      // Not sure if we should do this...but lets do it for now
      // If you only have a few data sets, load them all
      if (dataSets.length <= 5) {
        for (let dataSet of dataSets) {
          await this.loadDataSet(dataSet, true)
        }
      }
    })
  }

  public categoryKey(category: models.Category) {
    return getCategoryKey(category)
    // const version = category.dataSet != null ? category.dataSet.id + '@' : '?@'
    // return version + category.grouping + '@' + category.code
  }

  private initCategoriesMap() {
    return this.dataSets.map((profiles) => {
      var i = 0
      const result: { [index: string]: Category } = {}

      for (var profile of profiles) {
        for (var category of profile.categories) {
          i++
          ;(<any>category).markerStyleCategory = category.color

          this.categoryDataSetMap[category.id] = profile

          const simpleKey = profile.versionId + '@' + category.code
          const key = profile.versionId + '@' + category.grouping + '@' + category.code
          result[key] = <any>category
          result[simpleKey] = <any>category // just in case
        }
      }

      return result
    })
  }

  initLoadAll() {
    return new BehaviorSubject<AbstractLocation[]>([])
    // return this.categoriesMap.switchMap(async categoriesMap =>  {
    //   const places = await this.placeEndpoint.findAllByCurrentUser()
    //   const result: Place[] = []

    //   // TODO: setId -> then fascia
    //   places.forEach((place: any) => {
    //     const categoryId = place.dataSetId + '@' + place.secondaryCategory + '@' + place.fascia
    //     place.category = categoriesMap[categoryId]
    //     if (!place.category) {
    //       // just in case (will be like before instead of totally broken)
    //       place.category = categoriesMap[place.dataSetId + '@' + place.fascia]
    //     }

    //     place.properties = {}
    //     place.properties['marker-type'] = place.category.markerStyleCategory

    //     result.push(new ItemLocation(place))
    //   })

    //   return result
    // })
  }

  async applyDefaultNameByVersionId(places: Place[]) {
    const dataSetsMap = await this.dataSetVersionToDataSetMap.pipe(take(1)).toPromise()
    return places.map((place: any) => {
      const dataSet = dataSetsMap[place.dataSetId]
      if (dataSet && dataSet.labelColumn) {
        place.defaultName = place.name =
          dataSet.labelColumn in place ? place[dataSet.labelColumn] : place.properties[dataSet.labelColumn]
      } else {
        place.name = place.fascia
      }
      return place
    })
  }

  private processPlaces(dataSets: DataSet[], categoriesMap: { [index: string]: Category }, places: Place[]) {
    const centrepointMap = this.centrepointMap.getValue()
    const centrePointCategorize = new CentrePointCategorize(dataSets, centrepointMap, SIZE_CENTERPOIT_FILTER_CODES)

    objects.values(categoriesMap).forEach((category) => {
      category.averageSizes = category.averageSizes || new CategoryAverageSizes()
    })

    const result: DataSetLocation[] = []

    // TODO: setId -> then fascia
    places.forEach((place: any) => {
      const categoryId = place.dataSetId + '@' + place.secondaryCategory + '@' + place.fascia
      place.category = categoriesMap[categoryId]
      if (!place.category) {
        // just in case (will be like before instead of totally broken)
        place.category = categoriesMap[place.dataSetId + '@' + place.fascia]
      }

      if (place.category) {
        place.category.placesCount = (place.category.placesCount || 0) + 1
        place.category.placesCountOriginal = place.category.placesCount
      }

      place.properties = place.other ? { ...place.other } : {}
      place.properties['marker-type'] = place.category.markerStyleCategory

      const dataSet = this.categoryDataSetMap[place.category.id]
      if (dataSet.labelColumn) {
        place.defaultName = place.name =
          dataSet.labelColumn in place ? place[dataSet.labelColumn] : place.properties[dataSet.labelColumn]
      } else {
        place.name = place.fascia
      }

      // Calculate category averages
      if (place.category) {
        place.category.averageSizes.add('netSalesArea', 'ALL', place.netSalesArea)
        place.category.averageSizes.add('grossInternalArea', 'ALL', place.grossInternalArea)

        const code = centrePointCategorize.getCode(place)
        if (code) {
          place.category.averageSizes.add('netSalesArea', code, place.netSalesArea)
          place.category.averageSizes.add('grossInternalArea', code, place.grossInternalArea)
        }
      }

      result.push(new DataSetLocation(place))
    })

    return result
  }

  async loadDataSetsByName(dataSetNames: string[], silent: boolean = false) {
    const namesMap = (dataSetNames || []).reduce((acc, cur) => {
      acc[cur.toUpperCase().trim()] = true
      return acc
    }, {} as any)

    const dataSets = await this.dataSets.take(1).toPromise()
    // Not sure if we should do this...but lets do it for now
    // If you only have a few data sets, load them all
    for (let dataSet of dataSets) {
      if (namesMap[(dataSet.name || '').toUpperCase().trim()]) {
        await this.loadDataSet(dataSet, silent)
      }
    }
  }

  async loadDataSet(dataSet: DataSet, silent: boolean = false) {
    if (!dataSet || dataSet.postcodesDataSet) {
      return
    }

    const key = 'DATASET@' + dataSet.id

    if (this.loaded[key]) {
      if (!silent) {
        this.indicators.add(this.loaded[key])
      }
      return this.loaded[key]
    }

    const loadLayer = () => {
      if (silent) {
        return this.placeEndpoint.findAllByDataSet(<any>dataSet)
      } else {
        return this.indicators.add(this.placeEndpoint.findAllByDataSet(<any>dataSet))
      }
    }

    const dataSets = await this.dataSets.pipe(take(1)).toPromise()

    const observable = Observable.combineLatest(this.categoriesMap.take(1), Observable.fromPromise(loadLayer()))
      .withLatestFrom(this.allPlaces)
      .map(([[categoriesMap, result], places]) => {
        const processed = this.processPlaces(dataSets, categoriesMap, result)
        this.postLoadFunctions.forEach((postLoadFunction) => {
          postLoadFunction(processed)
        })

        if (dataSet.name.toLowerCase() === 'centrepoint') {
          this.initCentrePointMap(processed)
        }
        this.allPlacesSubject.next(places.concat(processed))
      })

    await (this.loaded[key] = observable.toPromise())
    this.dataSetLoadedOrUpdated.next(dataSet)
  }

  async loadCategory(dataSet: DataSet, category: Category) {
    this.loadDataSet(dataSet)
  }

  async decoratePlacesWithDataSets(places: Place[]) {
    const dataSets = await this.dataSets.take(1).toPromise()
    const dataSetsMap = (dataSets || []).reduce((acc, cur) => {
      acc[cur.versionId] = cur
      return acc
    }, {} as { [id: number]: DataSet })

    places.forEach((place) => {
      if (!place.dataSet) {
        place.dataSet = dataSetsMap[place.dataSetId]
      }
    })
    return places
  }

  async decoratePlacesWithCategories(places: Place[]) {
    const dataSets = await this.dataSets.pipe(take(1)).toPromise()
    const categoriesMap = await this.categoriesMap.take(1).toPromise()
    return this.processPlaces(dataSets, categoriesMap, places)
  }

  initUnavailableDataSets() {
    return Observable.fromPromise(this.indicators.add(this.dataSetEndpoint.meUnavailable()))
  }
}
