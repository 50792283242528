import { Component, OnInit } from '@angular/core';
import { UserEndpoint } from '../../../api';

declare var window: any

@Component({
  selector: 'chat-panel',
  templateUrl: './chatPanel.component.html',
  styleUrls: ['./chatPanel.component.less']
})
export class ChatPanelComponent implements OnInit {
  private readyState: any = null

  constructor(
    private userEndpoint: UserEndpoint,
  ) { }

  async ngOnInit() {
    try {
      const user = await this.userEndpoint.me()
      const api = (await this.loadChatWigdet())

      // api.on('chatbox:ready', function () {    
        api.set('visitor.name', user.name)
        api.set('visitor.email', user.email)
      // })
    } catch (e) {
      // in case they change something on their side
      console.error(e)      
    }
  }

  private async loadChatWigdet() {
    if (!window.purechatApi) {
      window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } }; (function () { var done = false; var script = document.createElement('script'); script.async = true; script.type = 'text/javascript'; script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; document.getElementsByTagName('HEAD').item(0).appendChild(script); (<any>script).onreadystatechange = script.onload = function (this: any, e) { if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { var w = new window.PCWidget({c: '16e24174-d38c-46ce-b28d-7de8026c6138', f: true }); done = true; } }; })();
    }

    await this.waitCondition(() => window.purechatApi && window.purechatApi.api && window.purechatApi.set)

    return window.purechatApi
  }

  private waitCondition(condition: () => boolean) {
    return new Promise(resolve => {
      const wait = () => {
        if (!condition()) {
          setTimeout(wait, 1000)
        } else {
          resolve()
        }
      }
      wait()
    })
  }
}
