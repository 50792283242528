
    <section style="min-width: 400px">
      <h3 mat-dialog-title translate="layers.addLocation">Optional Report Title</h3>

      <form (ngSubmit)="save()">
        <div fxLayout="column">
          <mat-form-field *ngIf="showTitle" fxFlex>
            <input matInput [(ngModel)]="model.title" name="title" placeholder="Optional Title" />
          </mat-form-field>

          <mat-form-field fxFlex>
            <input matInput [(ngModel)]="model.fileName" name="fileName" placeholder="Optional File Name" />
          </mat-form-field>
        </div>

        <div class="dialog-bottom">
          <button mat-button (click)="dialogRef.close()" mat-dialog-close>
            <span translate="dialog.cancel">Cancel</span>
          </button>
          <button mat-raised-button color="primary" mat-button><span translate="dialog.save">Download</span></button>
        </div>
      </form>
    </section>
  