<tgm-loadable [model]="loading">
  <mat-list class="sections" [class.unavailable]="type === 'alsoAvailable'">
    <ng-container *ngFor="let profile of profiles">
        <mat-list-item  (click)="toggle(profile.id)" *ngIf="profile.filtered !== false">
          <div class="location-item" fxFlex fxLayout="row">
            <div fxFlex>{{profile.name}} <span [hidden]="profile.placesCount == null" class="child-count">({{profile.placesCount | number}})</span></div>
            <mat-icon [class.toggled]="visible[profile.id]" class="mat-secondary animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
          </div>
        </mat-list-item>

        <div class="content" [hidden]="!visible[profile.id] || profile.filtered === false">
          <layer-select-panel [type]="type" [dataSet]="profile" [values]="profile.categories"></layer-select-panel>
        </div>
    </ng-container>
  </mat-list>
</tgm-loadable>
