<div class="admin-panel">
    <h2 class="mat-title">Matchpoint</h2>
  
    <form class="form" name="form" (submit)="save($event)" novalidate>

      <section>
        <h3 style="text-transform: uppercase" class="mat-title">Enabled categories</h3>

        <section>
          <div style="font-weight: bold; padding: 4px; margin-bottom: 16px">
              <mat-checkbox (ngModelChange)="toggleOnlySelected($event)"  name="onlySelected" [(ngModel)]="showOnlySelected" aria-label="">Show only selected</mat-checkbox>
          </div>

          <table style="width: 100%">
            <tr>
              <th></th>
              <th>Statistic</th>
              <th>Minimum %</th>
              <th>Maximum %</th>
              <th>Type</th>
            </tr>

            <tr *ngFor="let profile of visibleCategories; let i = index" >
              <td (click)="toggleCategory(profile)"><mat-checkbox style="pointer-events: none"  [checked]="profile.selected" aria-label=""></mat-checkbox></td>
              <td (click)="toggleCategory(profile)">{{profile.label}}</td>
              <td>
                  <mat-form-field style="width: 90px">
                    <input [disabled]="!profile.selected" matInput [(ngModel)]="profile.minimum" name="minimum{{i}}" placeholder="Minimum"/>
                  </mat-form-field>
              </td>
              <td>
                  <mat-form-field style="width: 90px">
                      <input [disabled]="!profile.selected" matInput [(ngModel)]="profile.maximum" name="maximum{{i}}" placeholder="Maximum"/>
                  </mat-form-field>
              </td>

              <td>
                <mat-select style="width: 150px" [(ngModel)]="profile.type" name="type{{i}}" placeholder="Type">
                  <mat-option *ngFor="let option of avalableConditionTypes" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>      
        
              </td>
            </tr>
          </table>
        </section>
      </section>    
  
      <div class="align-right">
        <tgm-saveable [model]="saving"></tgm-saveable>
        <button mat-flat-button color="primary" type="submit">Save</button>
      </div>
    </form>
  </div>