
    <back-button></back-button>
    <div style="padding: 0px 16px;">
      <div class="pinned" *ngIf="hoverInfoPinned && showThematic">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h3 fxFlex>Thematic layer information (Pinned)</h3>
          <button title="Clear" (click)="clearPinned()" type="button" mat-icon-button>
            <mat-icon class="mat-20">clear</mat-icon>
          </button>
        </div>

        <div class="hover-info-list">
          <div class="hover-list-item" *ngFor="let item of hoverInfoPinned">
            <div>{{ item.label }}</div>
            <div class="hover-list-value">
              <div>{{ item.value > 0 ? DECIMAL_PIPE.transform(item.value, '1.0') : '-' }}</div>
              <div>{{ item.type === 'percent' && item.value > 0 ? '%' : '' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showThematic">
        <h3>Thematic layer information</h3>

        <div class="hover-info-list">
          <div class="hover-list-item" *ngFor="let item of hoverInfo">
            <div>{{ item.label }}</div>
            <div class="hover-list-value">
              <div>{{ item.value > 0 ? DECIMAL_PIPE.transform(item.value, '1.0') : '-' }}</div>
              <div>{{ item.type === 'percent' && item.value > 0 ? '%' : '' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showHex">
        <h3>Statistics layer information</h3>

        <div class="hover-info-list">
          <div class="hover-list-item" *ngFor="let item of hoverInfoHex">
            <div>{{ item.label }}</div>
            <div class="hover-list-value">
              <div>{{ item.value > 0 ? DECIMAL_PIPE.transform(item.value, '1.0') : '-' }}</div>
              <div>{{ item.type === 'percent' && item.value > 0 ? '%' : '' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  