import { ReportModel } from "./exportPdfReport";
import { PDFHelper, tableOptions } from "./helper";
import { Group } from "../maxi/competitionMaxiReport";
import { DataSetLocation } from "../../model";
import {object as objects, array as arrays} from '@targomo/common'
import { Place } from "../../api/place";
import { Category } from "../../../common/models/place";

interface Grouping {
  key: string,
  counts: number[],
  total: number,
  category: string,
  categoryRaw: Category | string,
  fascia: string,  
  isTotal?: boolean
}

function getTableOptions(helper: PDFHelper) {
  return {
    border: '#ffffff',
    background: ['#ffffff', null],
    layout: {
      hLineWidth: function (i: number, node: any) {
        return 1
      },
      vLineWidth: function (i: number, node: any) {
        return 1
      },
      hLineColor: function (i: number, node: any) {
        return helper.tableBorder()
      },
      vLineColor: function (i: number, node: any) {
        return helper.tableBorder()
      },

      fillColor: function (i: number, node: any) {
        if (i === 0) {
          return helper.tableBorder();
        } else {
          return '#ffffff'
        }
      }
    }
  }
}

function getPlacePrimaryCategory(place: Place) {
  if (place.category) {
    return place.category.grouping || ''
  } else {
    return place.primaryCategory || ''
  }
}

function getPlaceSecondaryCategory(place: Place) {
  if (place.category) {
    return place.category.name || ''
  } else {
    return place.fascia || ''
  }
}

function placesByCategoryFascia(reportModel: ReportModel, places: Place[], modeCategories = false): Grouping[] {
  const result: {[index: string]: Grouping} = {}

  places.forEach(place => {
    const key = modeCategories 
      ? (getPlacePrimaryCategory(place))
      : (getPlacePrimaryCategory(place)) + '@' + place.fascia
    result[key] = result[key] || {
        key,
        total: 0,
        categoryRaw: getPlacePrimaryCategory(place),
        counts: reportModel.map(item => 0), 
        category: getPlacePrimaryCategory(place), 
        fascia: place.fascia
    }

    for (let i = 0; i < reportModel.length; i++) {
      if (place.travelTime >=0 && (place.travelTime / 60) <= reportModel[i].minutes) {
        result[key].counts[i]++
        result[key].total++
        break
      }
    }

    // result[key].count++
  })

  function addTotals(items: Grouping[]) {
    const result: Grouping[] = []
    let sublist: Grouping[] = []
    let lastCategory: Category | string = null

    function merge(sublist: Grouping[], label: string = null) {
      if (!sublist.length) {
        // if (!sublist.length || modeCategories) {
        return
      }

      const totalsTableRow: Grouping = {
        key: '',
        counts: sublist[0].counts.map(item => 0),
        total: 0,
        category: label || ((sublist[0].category || '') + ' Total'),
        categoryRaw: null,
        fascia: '',  
        isTotal: true,
      }

      sublist.forEach(item => {
        item.counts.forEach((count, i) => {
          totalsTableRow.counts[i] += count
        })

        totalsTableRow.total += item.total
      })

      result.push(totalsTableRow)
    }

    items.forEach(item => {
      if (item.categoryRaw !== lastCategory) {
        if (!modeCategories) {
          merge(sublist)
        }
        sublist = []
        lastCategory = item.categoryRaw
      }

      result.push(item)
      sublist.push(item)
    })

    if (!modeCategories) {
      merge(sublist)
    }
    merge(items, 'Grand Total')

    return result
  }

  return addTotals(arrays.sortBy(objects.values(result), 'key'))
}


export function competitionSummaryFascias(reportModel: ReportModel, places: Place[], sources: Place[], totals: Group, helper: PDFHelper, zonesVisible: boolean) {
  const items = placesByCategoryFascia(reportModel, places)

  const rows = [
    [
      {text: 'Category', style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'},
      {text: 'Fascia', style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'},
    ].concat(reportModel.map(reportItem => {
      return {text: reportItem.nameFullFromToExclusive, style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'}
    }).concat(
      [{text: 'Grand Total', style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'}]
    ))
  ].concat(items.map(row => {
    const styleName = row.isTotal ? 'defaultCellGroup' : 'defaultCellLeft'
    return <any[]>[
      {text: row.category, style: styleName, rowBackground: '#ffffff'},
      {text: row.fascia, style: styleName, rowBackground: '#ffffff'},
    ].concat(<any[]>row.counts.map(count => {
      return {text: count, style: styleName, rowBackground: '#ffffff'}
    }).concat(<any>
      [{text: helper.formatNumber(row.total), style: styleName, rowBackground: '#ffffff'}]
    ))
  }))

  return helper.table(null, rows, 'defaultTable', getTableOptions(helper))
}


export function competitionSummaryCategories(reportModel: ReportModel, places: Place[], sources: Place[], totals: Group, helper: PDFHelper, zonesVisible: boolean) {
  const items = placesByCategoryFascia(reportModel, places, true)

  const rows = [
    [
      {text: 'Category', style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'},
      // {text: 'Fascia', style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'},
    ].concat(reportModel.map(reportItem => {
      return {text: reportItem.nameFullFromToExclusive, style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'}
    }).concat(
      [{text: 'Grand Total', style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'}]
    ))
  ].concat(items.map(row => {
    const styleName = row.isTotal ? 'defaultCellGroup' : 'defaultCellLeft'
    return <any[]>[
      {text: row.category, style: styleName, rowBackground: '#ffffff'},
    ].concat(<any[]>row.counts.map(count => {
      return {text: count, style: styleName, rowBackground: '#ffffff'}
    }).concat(<any>
      [{text: helper.formatNumber(row.total), style: styleName, rowBackground: '#ffffff'}]
    ))
  }))

  return helper.table(null, rows, 'defaultTable', getTableOptions(helper))

  // const rows: any[] = [[
  //   {text: 'Travel Time', style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'}
  // ].concat(items.map(item => {
  //   return {text: item.category, style: 'defaultCellGroup', rowBackground: '#ffffff', color: '#ffffff'}
  // }))]


  // reportModel.map((reportItem, i) => {
  //   const row = [
  //     {text: '' + reportItem.nameFull, style: 'defaultCellGroup', rowBackground: '#ffffff'}
  //   ].concat(items.map(item => {
  //     return {text: helper.formatNumber(item.counts[i]), style: 'defaultCellGroup', rowBackground: '#ffffff'}
  //   }))

  //   rows.push(row)
  // })

  // const rowTotal = [
  //   {text: 'Total', style: 'defaultCellGroup', rowBackground: '#ffffff'}
  // ].concat(items.map(item => {
  //   return {text: helper.formatNumber(item.total), style: 'defaultCellGroup', rowBackground: '#ffffff'}
  // }))

  // rows.push(rowTotal)  

  // return helper.table(null, rows, 'defaultTable', getTableOptions(helper))
}

export function competitionSummary(reportModel: ReportModel, places: Place[], sources: Place[], totals: Group, helper: PDFHelper, zonesVisible: boolean) {
  const report1 = competitionSummaryCategories(reportModel, places, sources, totals, helper, zonesVisible)
  const report2 = competitionSummaryFascias(reportModel, places, sources, totals, helper, zonesVisible)
  return report1.concat([' ']).concat(report2)
}
