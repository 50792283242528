import { LatLngProperties } from '@targomo/core'
import { Layer } from 'mapbox-gl'
import { MapLayer, ObservableExpression } from '@targomo/client'
import { Observable } from 'rxjs'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

export interface MarkerLabelsLayerOptions {
  paint?: any
  layout?: any // TODO:have moer specific params
  minzoom?: number
  maxzoom?: number
  filter?: string[]
  property?: string
  sourceLayer?: string
  defaultFilter?: any
}

/**
 * A layer representing text labels that correspond to markers. The text to display is taked by default from the `name` property of the
 * marker LatLngProprties but can be configured
 */
export class LabelsLayer<T extends LatLngProperties> extends MapLayer<any> {
  private options: MarkerLabelsLayerOptions = {}
  private currentFilter: any[] = null

  constructor(
    map: CustomMapboxComponent,
    source: ObservableExpression<T[]>,
    optionsObservable?: ObservableExpression<MarkerLabelsLayerOptions>,
    filter?: Observable<(number | string)[]>
  ) {
    super(map as any, source)

    if (optionsObservable) {
      this.watch(optionsObservable, (style) => {
        this.options = style || {}
        this.update()
      })
    }

    if (filter) {
      this.watch(filter, (style) => {
        this.update((map) => {
          this.currentFilter = style
          map.setFilter(this.id, style)
        })
      })
    }
  }

  get(): Partial<Layer> {
    return {
      type: 'symbol',
      'source-layer': this.options.sourceLayer,
      minzoom: this.options.minzoom != null ? this.options.minzoom : 7,
      maxzoom: this.options.maxzoom != null ? this.options.maxzoom : 24,
      filter: this.currentFilter || this.options.defaultFilter || ['in', 'id'],
      layout: this.options.layout || {
        'symbol-placement': 'point',
        'text-field': this.options.property ? `{${this.options.property}}` : '{name}',
        'text-allow-overlap': false,
        'symbol-avoid-edges': false,
        'text-size': 12,
        'text-anchor': 'left',
        'text-offset': [1, 0],
      },
      paint: this.options.paint || {
        'text-color': '#ffffff',
        'text-halo-color': '#555555',
        'text-halo-width': 1,
      },
    }
  }
}
