import {Component, Input, OnInit, OnChanges, SimpleChange} from '@angular/core'
import {MAP_STYLES} from '@targomo/client'
import {StatisticsKey} from '@targomo/core'
import {STATISTICS_WITH_NONE, STATISTICS, STATISTICS_MAP} from '../../model/constants';
import {AppModel} from '../../model/appModel.service';

@Component({
  selector: 'statistic-switch',
  template: `
    <mat-list-item title="Selected Statistic">
      <mat-select fxFlex [ngModel]="appModel.displaySettings.getValue().statistic" (ngModelChange)="changeStatistic($event)">
        <mat-option title="{{option.label}}" *ngFor="let option of statisticsOptions" [value]="option">{{option.label}}</mat-option>
      </mat-select>
    </mat-list-item>
  `,
  styles: [`
    :host {
      display: block;
    }

    .mat-select-underline {
      background: none !important;
    }

    .mat-list-item-content {
      padding: 0px !important;
    }
  `]
})
export class StatisticSwitchComponent {
  statisticsOptions = STATISTICS_WITH_NONE
  statistic = STATISTICS_MAP[0]

  constructor(readonly appModel: AppModel) {
  }

  changeStatistic(value: StatisticsKey) {
    this.appModel.displaySettings.nextProperty('statistic', value)
  }
}