import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { getBasePath, Indicators } from '@targomo/client';
import {UserEndpoint} from '../../api/user'
import {DataProfileEndpoint, DataProfile} from '../../api/dataProfile';
import { User, DataSetEndpoint, DataSet, PlanningApplicationDataSetEndpoint, PlanningApplicationDataSet } from "../../api/index";
import { Auth } from '@targomo/client';
import { APP_BASE_PATH } from "../../util/basePath";
import { AppModel } from '../../model/appModel.service';
import { GeoRegion, GeoRegionsEndpoint } from '../../api/georegions';
import { SelectFasciasModel } from './components/adminSelectFascias/selectFasciasModel';
import { UserSettingsEndpoint } from '../../api/userSettings';
import { PdfReportSettings } from '../../model/settingsModel';
import { ReportTemplate, UserSettings } from '../../../common/models';
import { ReportTemplateEndpoint } from '../../api/reportTemplate';
import { MatSelectChange } from '@angular/material';
import { ComparisonReportTemplateEndpoint, ComparisonReportTemplate } from '../../api/comparisonReportTemplate';
import { Message, MessagesEndpoint } from '../../api/message';
import { Company, CompanyEndpoint } from '../../api/company';
import { SelectFasciasDefinition } from '../../../common/models/planningApplication';

interface SelectableDataProfile extends DataProfile {
  selected: boolean
}

interface SelectableGeoRegion extends GeoRegion {
  selected: boolean
}

@Component({
  selector: 'admin-user',
  templateUrl: './adminUser.html',
  styleUrls: ['./adminUser.less']  
})
export class AdminUserComponent {
  form: FormGroup
  loading: Promise<any>
  user: User = <any>{}
  ready = false

  urlLogo: string
  url: string
  urlCompany: string
  availableMessages: Message[]
  availableCompanies: {id: number, name: string}[]

  constructor(
    private auth: Auth,
    private appModel: AppModel,
    private formBuilder: FormBuilder,
    private usersEndpoint: UserEndpoint,
    private planningEndpoint: PlanningApplicationDataSetEndpoint,
    private route: ActivatedRoute,
    private router: Router,
    private indicators: Indicators,
    private messagesEndpoint: MessagesEndpoint,
    private companyEndpoint: CompanyEndpoint,
  ) {
    // this.loadingDataSets = this.indicators.add(this.planningEndpoint.findAllFull())
    this.init()


  }

  private async init() {
    const params = this.route.snapshot.params
    const passwordValidators = [Validators.minLength(3)]

    // const selectedFascias = await this.loadingDataSets.then(async dataSets => {
    //   this.dataSets = dataSets
    //   const params = this.route.snapshot.params

    //   if (params.id !== 'new') {
    //     const selectedFascias = await this.planningEndpoint.getUserFascias({id: params.id})
    //     new SelectFasciasModel(this.dataSets, false).updateSelected(selectedFascias)  
    //   } else {
    //     return []
    //   }      
    // })    

    // this.availableComparisonTemplates = await this.comparisonTemplateEndpoint.findAll()
    // this.availableTemplates = [{id: null, name: 'Default', data: {}}].concat(await this.templateEndpoint.findAll())
    // const dataProfiles = await this.dataProfileEndpoint.findAll().then(dataProfiles => this.dataProfiles = <SelectableDataProfile[]>dataProfiles)
    // const geoRegions = await this.regionsEndpoint.findAll().then(regions => this.availableRegions = <SelectableGeoRegion[]>regions)
    // this.availableRegions = this.availableRegions.filter(profile => profile && profile.name && profile.name.toLowerCase() !== 'default')
    this.availableCompanies = [{id: null, name: 'None'}].concat(<any>await this.indicators.add(this.companyEndpoint.findAll()))

    if (params.id !== 'new') {
      this.user = await (this.loading = this.usersEndpoint.find({id: +params.id}))
      this.availableMessages = await this.messagesEndpoint.findForUser(this.user)

      if (this.user.hasPicture) {
        this.url  = `${APP_BASE_PATH}api/users/${params.id}/picture`
      }

      if (this.user.hasCompanyPicture) {
        this.urlCompany  = `${APP_BASE_PATH}api/users/${params.id}/companypicture`
      }

      if (this.user.hasLogo) {
        this.urlLogo  = `${APP_BASE_PATH}api/users/${params.id}/logo`
      }

      // dataProfiles.forEach(profile => {
      //   profile.selected = (this.user.dataProfiles && this.user.dataProfiles.indexOf(profile.id) > -1)
      // })

      // geoRegions.forEach(profile => {
      //   profile.selected = (this.user.geoRegions && this.user.geoRegions.indexOf(profile.id) > -1)
      // })


      // this.availableComparisonTemplates.forEach(template => {
      //   template.selected = (this.user.permissions.comparisonReportTemplates && this.user.permissions.comparisonReportTemplates.indexOf(template.id) > -1)
      // })
    } else {
      this.availableMessages = await this.messagesEndpoint.findAll()
      passwordValidators.push(Validators.required)
    }

    let selectedFascias: SelectFasciasDefinition[] = []
    if (params.id !== 'new') {
      selectedFascias = await this.planningEndpoint.getUserFascias({id: params.id})

      // const selectedFascias = await this.planningEndpoint.getUserFascias({id: params.id})
      // new SelectFasciasModel(this.dataSets, false).updateSelected(selectedFascias)

    //   this.reportSettings = (this.user && this.user.permissions) ? {
    //     customReport: this.user.permissions.customReport,
    //     reportTemplateId: this.user.permissions.reportTemplateId,
    //     data: null
    //   } : null

    //   // await this.userSettingsEndpoint.loadUserReport({id: params.id})
    //   this.reportSettings = this.reportSettings || <any>{}
    //   this.reportSettings.customReport = this.reportSettings.customReport || {
    //     printShowSizeLegend: true,
    //     printShowFasciaLegend: true,
    //   }
    // } else {
    //   this.reportSettings = this.reportSettings || <any>{}
    //   this.reportSettings.customReport = this.reportSettings.customReport || {
    //     printShowSizeLegend: true,
    //     printShowFasciaLegend: true,
    //   }
    }


    this.form = this.formBuilder.group({
      'username': [this.user.username, [Validators.required]],
      'email': [this.user.email, [Validators.required]],
      'address': [this.user.address, [Validators.required]],
      'password': [null, passwordValidators],
      'name':     [this.user.name, [Validators.required]],
      'companyId':     [this.user.companyId, []],
      'userPermissions': [{userOrCompany: this.user, selectedFascias}, []],
    })

    this.ready = true
  }

  async submit() {
    (<any>this.form).submitted = true

    if (this.form.valid) {
      // this.user.dataProfiles = this.dataProfiles.filter(profile => profile.selected).map(profile => profile.id)
      // this.user.geoRegions = this.availableRegions.filter(profile => profile.selected).map(profile => profile.id)
      this.user.username = this.form.value.username
      this.user.name = this.form.value.name
      this.user.password = this.form.value.password
      this.user.address = this.form.value.address
      this.user.email = this.form.value.email
      this.user.companyId = this.form.value.companyId
      // await this.planningEndpoint.saveCompanyFascias(<any>this.company, this.form.value.company.selectedFascias)

      // const selectedPlanningFascias = new SelectFasciasModel(this.dataSets).getSelectedFascias()
      this.user.permissions = this.user.permissions || {}
      // this.user.permissions.comparisonReportTemplates = this.availableComparisonTemplates.filter(template => template.selected).map(template => template.id)
      // this.user.permissions.customReport = this.reportSettings.customReport
      // this.user.permissions.reportTemplateId = this.reportSettings.reportTemplateId

      if (this.user.id) {
        await this.usersEndpoint.update(this.user)
        await this.planningEndpoint.saveUserFascias(<any>this.user, this.form.value.userPermissions.selectedFascias)
        await this.messagesEndpoint.saveUserMessages(<any>this.user, this.availableMessages)

        const me = await this.auth.me()
        if (me && me.id == this.user.id) {
          await this.auth.refreshMe()
          this.appModel.logoUpdated.next(new Date().getTime())
        }

        this.router.navigate(['/admin/users'])
      } else {
        const result = await this.usersEndpoint.create(this.user)
        if (result) {
          await this.planningEndpoint.saveUserFascias(result, this.form.value.userPermissions.selectedFascias)
          await this.messagesEndpoint.saveUserMessages(result, this.availableMessages)
        }

        if (result) {
          this.router.navigate(['/admin/users'])
        }
      }
    }
  }

  onSelectPicture(which: 'picture' | 'companyPicture' | 'logo', property: 'url' | 'urlCompany' | 'urlLogo', files: FileList) {
    if (!files || !files[0]) {
      this[property] = null
      this.user[which] = null
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      const result = reader.result

      this.user[which] = result.substring(result.indexOf(',') + 1)
      this[property] = result
    }

    reader.readAsDataURL(files[0])
  }

  onSelectProfilePicture(files: FileList) {
    this.onSelectPicture('picture', 'url', files)
  }

  onSelectCompanyPicture(files: FileList) {
    this.onSelectPicture('companyPicture', 'urlCompany', files)
  }

  onSelectLogoPicture(files: FileList) {
    this.onSelectPicture('logo', 'urlLogo', files)
  }

  changeComparisonTemplate(value: any) {
    value.selected = !value.selected
  }

  toggleMessage(value: any) {
    value.selected = !value.selected
  }
}