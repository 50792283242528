import { Component, EventEmitter, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SubscriberComponent, TgmQuickDialogs } from '@targomo/client'
import { MatchpointEndpoint } from '../../../api/matchpoint'
import { SavedSession } from '../../../api/savedSession'
import { MatchpointConfigPanelComponent } from '../matchpointConfigPanel/matchpointConfigPanel.component'

@Component({
  selector: 'save-matchpoint-config-panel',
  styleUrls: ['./saveMatchpointConfig.component.less'],
  templateUrl: './saveMatchpointConfig.component.html',
})
export class SaveMatchpointConfigComponent extends SubscriberComponent {
  form: FormGroup
  submitted = false
  currentSession: SavedSession
  saveAs = true

  @Output() back = new EventEmitter()

  constructor(
    private formBuilder: FormBuilder,
    private matchpointEndpoint: MatchpointEndpoint,
    private quickDialogs: TgmQuickDialogs,
    private parent: MatchpointConfigPanelComponent
  ) {
    super()

    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
    })
  }

  saveNew() {
    this.saveAs = true
  }

  async saveUpdate() {
    // const project = { name: this.form.value.name, data: await this.savedSessionsModel.snapshot(this.home.mapView) }
    // const result = await this.savedSessionsEndpoint.update(this.currentSession.id, project)

    // this.savedSessionsModel.currentSession.next(result)

    this.quickDialogs.snack('Matchpoint selection criteria saved')
    this.back.emit(true)
  }

  async submit() {
    this.submitted = true

    if (this.form.valid) {
      const data = await this.parent.getUpdatedConfig()
      const project = { name: this.form.value.name, data }
      await this.matchpointEndpoint.saveUserSettings(project)

      this.quickDialogs.snack('Matchpoint selection criteria saved')
      this.back.emit(true)
    }
  }

  clickBack() {
    this.back.emit(true)
  }
}
