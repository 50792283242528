<mat-list-item *ngIf="parent.permissions && parent.permissions.locationsInView">
  <div fxFlex>Area of concern</div>

  <mat-select
    style="width: 200px"
    [ngModel]="settings.gapReportLocationsUpdates | async"
    (ngModelChange)="changeGapReportLocationsUpdates($event)"
  >
    <mat-option *ngFor="let option of gapReportOptions" [value]="option.value">{{ option.name }}</mat-option>
  </mat-select>
</mat-list-item>
