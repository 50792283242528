// styleUrls: ['./LifestyleMiniReport.less']

import {Input, Component} from '@angular/core';
import {AppModel} from '../../model/appModel.service';
import {Subscription} from 'rxjs/Subscription';
import {AllStatistics, LifestyleStatistics, CensusStatistics} from '../../../common/models/statistics/statistics';
import {LIFESTYLE_SEGMENTS} from './model/reportSegments';
import { ReportPerCapitaHousehold } from '../../model/settingsModel';
import { AbstractMiniReportComponent } from './components/abstractMiniReportComponent/abstractMiniReportComponent';

@Component({
  selector: 'lifestyle-mini-report',
  templateUrl: './censusMiniReport.html'
})
export class LifestyleMiniReportComponent extends AbstractMiniReportComponent {
  @Input() allColumns: boolean

   // The application wide model keeping track of selected and visible places
  loading: Promise<AllStatistics>
  statistics: LifestyleStatistics
  allStatistics: AllStatistics
  census: CensusStatistics

  currencySymbol = '£'
  perHouseholdCapitaTitle: string = ''

  groups = LIFESTYLE_SEGMENTS

  constructor(private appModel: AppModel) {
    super()
  }

  ngOnInit() {
    this.watch(this.appModel.statistics.census.promise, promise => this.loading = promise)
    this.watch(this.appModel.statistics.census.value, statistics => {
      if (statistics) {
        this.statistics = statistics.lifestyle
        this.census     = statistics.census
        this.allStatistics = statistics
        this.currencySymbol = this.allStatistics.currencySymbol()
        this.perHouseholdCapitaTitle = statistics.householdsOrPopulation === ReportPerCapitaHousehold.PER_CAPITA ? 'Per Capita' : 'Per Household'
      } else {
        this.statistics = null
        this.census     = null
        this.allStatistics = null
      }
    })

    // this.unsubscribe = this.placesModel.censusStatistics.watchPromise(statisticsPromise => {
    //   this.loading = statisticsPromise
    //   statisticsPromise.then(statistics => {
    //     if (statistics) {
    //       this.statistics = statistics.lifestyle
    //       this.census     = statistics.census
    //     }
    //   })
    // })
  }

  getViewMode(id: string) {
    return 'table'
  }

  showGraphs() {
    return false
  }

  click(node: any) {
    if (node.parent) {
      this.groups.forEach(group => {
        if (group.id == node.id) {
          group.visible = !group.visible
          node.visible = group.visible
        }
      })
    }
  }
}