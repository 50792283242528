import { Component, EventEmitter, Output } from '@angular/core'
import { Indicators, TgmQuickDialogs, SubscriberComponent, files } from '@targomo/client'
import { SavedSession, SavedSessionEndpoint } from '../../../api/savedSession'
import { SavedSessionModel } from '../../../model/savedSessionModel'
import { Router } from '@angular/router'
import { HomeComponent } from '../../../main/home.component'
import { MatDialog } from '@angular/material'
import { ShareSessionDialogComponent } from '../shareSessionDialog/shareSessionDialog.component'
import { SettingsModel } from '../../../model/settingsModel'
import { ExportAllEndpoint } from '../../../api/exportAll'
import { BatchStatisticsModel } from '../../../model/batchStatisticsModel'
import { DatePipe } from '@angular/common'

const DATE_PIPE = new DatePipe('en')

@Component({
  selector: 'export-all-panel',
  styleUrls: ['./exportAllPanel.component.less'],
  templateUrl: './exportAllPanel.component.html',
})
export class ExportAllPanelComponent extends SubscriberComponent {
  loadingProjects: Promise<SavedSession[]>
  projects: any[]

  view: 'mine' | 'shared' | 'sharing' = 'mine'
  defaultLoadSessionId: number

  constructor(
    private indicators: Indicators,
    private home: HomeComponent,
    private router: Router,
    private exportAllEndpoint: ExportAllEndpoint,
    private quickDialogs: TgmQuickDialogs,
    private settings: SettingsModel,
    private batchStatisticsModel: BatchStatisticsModel
  ) {
    super()

    this.watch(this.settings.defaultLoadSessionIdUpdates, (defaultLoadSessionId) => {
      this.defaultLoadSessionId = defaultLoadSessionId
    })

    this.watch(batchStatisticsModel.exports$, (exports) => {
      this.projects = exports

      console.log('PR', exports)
    })

    this.watch(batchStatisticsModel.exportsAutoRefresh$, () => {})

    this.batchStatisticsModel.reloadExports()
  }

  async loadExport(layer: any) {
    const data = await this.indicators.add(this.exportAllEndpoint.download(layer.id))
    const name = [
      'storepointgeo',
      this.getTypeFileName(layer.type),
      DATE_PIPE.transform(layer.created, 'yyyy-mm-dd_hhMM'),
    ]
      .filter((item) => !!item)
      .join('_')
    files.saveFile(data.output, 'text/csv', name + '.csv')
  }

  async deleteExport(layer: any) {
    if (
      await this.quickDialogs.confirm({
        title: 'Delete Data Export',
        message: `Would you like to delete data export ${layer.name}?`,
        confirm: 'Delete',
        cancel: 'Cancel',
      })
    ) {
      await this.exportAllEndpoint.delete(layer.id)
      this.batchStatisticsModel.reloadExports()
    }
  }

  getTypeFileName(type: string) {
    return this.getTypeName(type).toLowerCase()
  }

  getTypeName(type: string) {
    switch (type) {
      case 'report':
        return 'all data combined'
      case 'competitors':
        return 'competitors'
      default:
        return ''
    }
  }
}
