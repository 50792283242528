import { Place } from '../../api/place'
import { Category } from '../../api/category'
import { DataSet } from '../../api/dataSet'
import { LatLngIdTravelTime } from '@targomo/core'
export { EXPORTABLE_PROPERTIES } from '../../../common/models/place'

export abstract class AbstractLocation implements Place, LatLngIdTravelTimeDistance {
  id: any
  lat: number
  lng: number
  storeId?: string
  fascia?: string
  sitename?: string
  primaryCategory?: string
  netSalesArea?: number
  grossInternalArea?: number

  street?: string
  town?: string
  suburb?: string
  postcode?: string
  county?: string
  region?: string
  country?: string

  travelTime?: number
  dataSetId?: number

  properties: any

  category?: Category
  deleted?: boolean
  dataSet?: DataSet

  latAlt?: number
  lngAlt?: number

  other?: any

  name?: string
  defaultName?: string

  planningLocation?: boolean
  planningValue?: any

  constructor(fromObject?: any) {
    if (fromObject) {
      Object.assign(this, fromObject)
    }
  }

  getColor() {
    return (this.properties && this.properties['marker-type']) || (this.category && this.category.color)
  }

  toString() {
    return (
      (this.properties && this.properties.name) ||
      this.name ||
      this.fascia ||
      this.sitename ||
      `${this.lng}, ${this.lat}`
    )
  }

  async update() {
    // const result = await api.reverseGeocode(this)
    // this.updateTexts(result)
  }

  // private updateTexts(result: any) {
  //   this.properties.location = `${this.lng}, ${this.lat}`
  //   this.properties.address = result.address || 'Standort'
  // }

  isCustom() {
    return false
  }

  isStar() {
    return this.properties['marker-icon-type'] != null
    // return false
  }

  isTemporaryCustom() {
    return false
  }

  abstract copy(): this
}

export class DataSetLocation extends AbstractLocation {
  id: number

  copy() {
    return new DataSetLocation(this) as this
  }
}

export class CustomLocation extends AbstractLocation {
  copy() {
    return new CustomLocation(this) as this
  }

  isStar() {
    return true
  }

  isCustom() {
    return true
  }
}

export class TemporaryCustomLocation extends CustomLocation {
  constructor(fromObject?: any) {
    super(fromObject)

    this.properties = this.properties || {}
  }

  copy() {
    return new TemporaryCustomLocation(this) as this
  }

  isTemporaryCustom() {
    return true
  }
}

export class PlanningLocation extends AbstractLocation {
  copy() {
    return new PlanningLocation(this) as this
  }

  isCustom() {
    return true
  }
}

export interface LatLngIdTravelTimeDistance extends LatLngIdTravelTime {
  fastestTravelDistance?: number
  shortestTravelDistance?: number
}
