import {Component, Input, OnInit, OnChanges, SimpleChange} from '@angular/core'
import {MAP_STYLES} from '@targomo/client'
import {StatisticsKey} from '@targomo/core'
import {GraphValue} from '@targomo/client';
import {BarGraphOptions} from '@targomo/client';

@Component({
  selector: 'expandable-panel',
  template: `
  <div class="title" (click)="toggle()">
    <div class="location-item" fxLayout="row">
      <div fxFlex>{{text}}</div>
      <mat-icon [class.toggled]="expanded" class="animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
    </div>
  </div>

  <div class="second-level" *ngIf="expanded">
    <ng-content></ng-content>
  </div>
  `,
  styleUrls: ['./expandablePanel.less']
})
export class ExpandablePanelComponent {
  @Input() expanded: boolean = false
  @Input() text: string = ''

  constructor() {
  }

  toggle() {
    this.expanded = !this.expanded
  }
}