import { FeatureInteractionLayer, MapCellEvent } from '@targomo/client'
import { Observable, Subject } from 'rxjs'

export class ExtenedInteractionLayer extends FeatureInteractionLayer {
  readonly events: {
    readonly click: Observable<MapCellEvent>
    readonly hover: Observable<MapCellEvent>
    readonly context: Observable<MapCellEvent>
  } = {
    click: new Subject<MapCellEvent>(),
    hover: new Subject<MapCellEvent>(),
    context: new Subject<MapCellEvent>(),
  }

  async initEvents() {
    await super.initEvents()

    const map = await this.map.getMap()

    map.on('contextmenu', (event: any) => {
      const features = this.queryMapRenderedFeatures(map, event.point, {
        layers: (this as any).layers.map((layer: any) => layer.id),
      })
      if (features.length) {
        const cellEvent = new MapCellEvent(
          event.lngLat,
          event.point,
          features[0],
          features[0] && features[0].properties
        )

        this.fire(this.events.context, cellEvent)
      }
    })
  }
}
