import { LatLng } from '@targomo/core'
import { AbstractLocation } from '../../../model/entities/place'

/**
 * A search result.
 */
export class SearchResult extends AbstractLocation implements LatLng {
  constructor(public lat: number, public lng: number, public title: string, public description: string) {
    super()
  }

  toString() {
    return this.title
  }

  copy() {
    const result = new SearchResult(this.lat, this.lng, this.title, this.description) as this

    result.properties = this.properties && { ...this.properties }

    return result
  }
}

export class SuggestionResult {
  constructor(
    public text: string,
    public magicKey: string,
    public query: string,
    public center: LatLng,
    public filterCountry: string
  ) {}

  toString() {
    return this.text
  }
}
