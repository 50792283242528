import * as models from '../../common/models'
import {Injectable} from '@angular/core';
import {RESTHelper} from '@targomo/client';

export interface User extends models.User {
  dataProfiles?: number[]
  geoRegions?: number[]
  permissions?: any
}

@Injectable()
export class UserEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/users')
  }

  me() {
    return this.http.get<User>(`/me`)
  }

  preflight() {
    return this.http.get<any>(`/preflight`)
  }


  get(user: {id: number}) {
    return this.find(user)
  }

  find(user: {id: number}) {
    return this.http.get<User>(`/${user.id}`)
  }

  save(user: User) {
    return this.update(user)
  }

  create(user: User) {
    return this.http.post<{id: number}>(`/`, user)
  }

  update(user: User) {
    return this.http.put(`/${user.id}`, user)
  }

  delete(user: User) {
    return this.http.delete(`/${user.id}`)
  }

  findAll() {
    return this.http.get<User[]>(`/`)
  }

  requestPasswordReset(email: string) {
    return this.http.post('/requestPasswordreset', {email})
  }

  /**
   *
   */
  resetPassword(resetKey: string, password: string) {
    return this.http.post('/resetPassword', {resetKey, password})
  }

}