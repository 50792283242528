import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

interface RatioItem {
  left: string
  right: string
  format: string
}

const AVAILABLE_FORMATS = [
  {key: '1.0-0', label: '#'},
  {key: '1.0-1', label: '#.#'},
  {key: '1.0-2', label: '#.##'},
  {key: '1.0-3', label: '#.###'},
  {key: '1.0-4', label: '#.####'},
]

@Component({
  selector: 'admin-comparison-ratio',
  templateUrl: './adminComparisonRatio.component.html',
  styleUrls: ['./adminComparisonRatio.component.less']
})
export class AdminComparisonRatioComponent implements OnInit, OnChanges {
  @Input() available: {label: string, key: any}[]
  @Input() value: any[] = []
  @Output() valueChange = new EventEmitter()

  template: RatioItem[] = []
  availableFormats = AVAILABLE_FORMATS

  constructor() { }

  ngOnInit() {
    this.ngOnChanges()
  }

  ngOnChanges() {
    if (this.value) {
      this.template = this.value.map(item => {
        const [_, left, right, format] = item
        return {left, right, format: format || AVAILABLE_FORMATS[1].key}
      })
    } else {
      this.template = []
    }
  }

  add() {
    this.template.push({left: this.available[0].key, right: '1', format: AVAILABLE_FORMATS[1].key})
    this.fire()
  }

  delete(item: any) {
    this.template = this.template.filter(element => element !== item)
    this.fire()
  }

  fire() {
    this.valueChange.emit(this.template.map(item => ['/', item.left, item.right, item.format]))
  }
}
