<div *ngIf="allowGroupings">
  <mat-list>
    <mat-list-item class="top-level" (click)="toggleSelectAll()" *ngIf="groupings?.length > 0">
        <div class="location-item" fxFlex fxLayout="row">
          <div fxFlex>
            <b [hidden]="selectedAll">Select All</b>
            <b [hidden]="!selectedAll">Deselect All</b>
          </div>
    
          <mat-checkbox style="pointer-events: none" class="mat-secondary" [checked]="selectAll" aria-label="Select/Deselect All"></mat-checkbox>
        </div>
      </mat-list-item>
      
    <div class="no-children-data" *ngIf="groupings?.length == 0">
      No Data
    </div>
    
    <ng-container *ngFor="let grouping of groupings">
      <mat-list-item (click)="toggle(grouping)" *ngIf="grouping.filtered != false">
        <div class="location-item" fxFlex fxLayout="row">
          <mat-icon [class.toggled]="grouping.visible" class="mat-secondary animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
          <div fxFlex>{{grouping.name}}</div>
          <mat-checkbox (click)="toggleSelectGrouping(grouping, $event)" class="mat-secondary" [checked]="!!grouping.selected" aria-label=""></mat-checkbox>
        </div>
      </mat-list-item>


      <div *ngIf="grouping.visible && grouping.filtered != false" class="children">
        <mat-list>
          <ng-container *ngFor="let category of grouping.children">
            <mat-list-item (click)="toggleSelect(category)"  *ngIf="category.filtered != false">
              <div class="location-item" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <span class="colorbox" [style.background]="category.color || '#ddd'"></span>
                <div fxFlex>{{category.name}}</div>

                <mat-checkbox style="pointer-events: none" class="mat-secondary" [checked]="!!category.selected" aria-label=""></mat-checkbox>
              </div>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </div>
    </ng-container>
  </mat-list>
</div>


<div *ngIf="!allowGroupings">
  <mat-list>
    <div class="no-children-data" *ngIf="categories?.length == 0">
      No Data
    </div>

    <mat-list-item class="top-level" (click)="toggleSelectAll()" *ngIf="categories?.length > 0">
      <div class="location-item" fxFlex fxLayout="row" >
        <div fxFlex>
          <b [hidden]="selectedAll">Select All</b>
          <b [hidden]="!selectedAll">Deselect All</b>
        </div>

        <mat-checkbox style="pointer-events: none" class="mat-secondary" [checked]="selectAll" aria-label="Select/Deselect All"></mat-checkbox>
      </div>
    </mat-list-item>
  
    <ng-container *ngFor="let category of categories">
      <mat-list-item (click)="toggleSelect(category)"  *ngIf="category.filtered != false">
        <div class="location-item" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <span class="colorbox" [style.ground]="category.color || '#ddd'"></span>
        <div fxFlex>{{category.name}}</div>

        <mat-checkbox style="pointer-events: none" class="mat-secondary" [checked]="!!category.selected" aria-label=""></mat-checkbox>
      </div>
      </mat-list-item>
    </ng-container>
  </mat-list>
</div>