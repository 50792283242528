<tgm-loadable class="mini-report-panel" [model]="loading">
  <span *ngIf="!statistics" class="no-data">No Data</span>

  <mat-list *ngIf="!!statistics">
    <ng-container *ngFor="let node of groups">
      <ng-container *ngIf="!!statistics[node.id].total || !!node.special">
        <mat-list-item class="statistics top-level" (click)="toggle(node)" [class.tertiary]="node.child" [class.tertiary-parent]="node.parent">
          <div class="location-item" fxFlex fxLayout="row">
            <div fxFlex>{{node.name}}</div>
            <mat-icon [class.toggled]="node.visible" class="mat-secondary animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
          </div>
        </mat-list-item>

        <div *ngIf="node.visible && !!node.special">
          <lifestyle-personicx-mini-report [allColumns]="allColumns" places-model="placesModel"></lifestyle-personicx-mini-report>
        </div>

        <div *ngIf="node.children && node.visible && !node.special" class="statistics second-level" [class.tertiary]="node.child" [class.tertiary-parent]="node.parent" [class.visible]="node.visible" [class.invisible]="!node.visible">
            <histogram-table
              [statistics]="statistics"
              [allStatistics]="allStatistics"
              [allColumns]="allColumns"
              [node]="node"
              [enableGraph]="showGraphs()"
              [view]="getViewMode(node.id)"
              (viewChange)="changeViewMode(node.id, $event)"
              (clickRow)="click($event)"
            >

            </histogram-table>
        </div>
      </ng-container>
    </ng-container>
  </mat-list>
</tgm-loadable>