<p>Select your travel time bands:</p>

<mat-radio-group [ngModel]="settings.travelRangeIdUpdates | async">
  <div>
    <div
      (click)="changeTravelRange(option.id)"
      class="option"
      *ngFor="let option of travelRanges | async"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div fxFlex>
        <mat-radio-button [value]="option.id">{{ option.name }}</mat-radio-button>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div
          class="travel"
          *ngFor="let travel of option.options"
          [style.background]="travel.color"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          {{ travel.value / 60 }}
        </div>
      </div>
    </div>

    <div (click)="changeTravelRange(-1)" class="option">
      <div fxFlex>
        <mat-radio-button [value]="-1">Custom Max Travel Time</mat-radio-button>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="settings.useCustomMaxTimeUpdates | async">
        <mat-form-field>
          <input matInput [(ngModel)]="customMaxTime" placeholder="Custom Travel Time" required />
        </mat-form-field>
      </div>
    </div>

    <div (click)="changeTravelRange(-2)" class="option">
      <div fxFlex>
        <mat-radio-button [value]="-2">Custom Report Travel Times</mat-radio-button>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="settings.useCustomReportTimesUpdates | async">
          <div fxFlex></div>
          <div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field>
                <input matInput [(ngModel)]="customReportTime1" placeholder="Custom Report Time 1" />
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field>
                <input matInput [(ngModel)]="customReportTime2" placeholder="Custom Report Time 2" />
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field>
                <input matInput [(ngModel)]="customReportTime3" placeholder="Custom Report Time 3" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-radio-group>
