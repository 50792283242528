import { SubscriberComponent } from "@targomo/client";

export class AbstractMiniReportComponent extends SubscriberComponent {
  allColumns: boolean  
  viewModes: {[id: string]: string} = {}

  getViewMode(id: string) {
    if (this.allColumns) {
      return 'table'
    } else {
      return this.viewModes[id] || 'graph'
    }
  }

  changeViewMode(id: string, mode: string) {
    this.viewModes[id] = mode
  }

  toggle(node: {visible: boolean}) {
    node.visible = !node.visible
  }

  showGraphs() {
    return !this.allColumns
  }

  click(node: any) {
  }  
}