import { Observable, combineLatest, of } from 'rxjs'
import { AbstractLocation } from '../../../model'
import { geometry, TravelTypeEdgeWeightOptions, StatisticsGroup } from '@targomo/core'
import { switchMap, map } from 'rxjs/operators'
import { StatisticsModel } from '../../../model/statisticsModel'
import { MaxEdgeWeightOption, Indicators } from '@targomo/client'
import { AllStatistics, StatisticsValue } from '../../../../common/models/statistics/statistics'
import { AppModel } from '../../../model/appModel.service'
import { canonicalPositions } from '../../../api'
import {
  STATISTICS,
  STATISTICS_GROUP,
  STATISTICS_WORKFORCE,
  STATISTICS_GROUP_WORKFORCE,
} from '../../../model/constants'
import { object as objects } from '@targomo/common'
import {
  getCensusSegments,
  CENSUS_SEGMENTS_RAW,
  ReportSegment,
  WORKFORCE_SEGMENTS,
} from '../../mini/model/reportSegments'
import { ALL_COMPARISON_REPORT_COLUMNS_MAP } from '../../../pages/admin/adminComparisonReportOptions/adminComparisonReportOptions.component'
import { COMPARISON_SOURCES_THRESHOLD } from '../../../model/placesModel'

export enum ComparisonHighlight {
  HIGHEST_PERCENT,
  LOWEST_PERCENT,
  HIGHEST_INDEX,
  LOWEST_INDEX,
}

export const COMPARISON_HIGHLIGHT = [
  { key: ComparisonHighlight.HIGHEST_PERCENT, label: 'Highest percent' },
  { key: ComparisonHighlight.LOWEST_PERCENT, label: 'Lowest percent' },
  { key: ComparisonHighlight.HIGHEST_INDEX, label: 'Highest index' },
  { key: ComparisonHighlight.LOWEST_INDEX, label: 'Lowest index' },
]

export enum ComparisonIndex {
  REGION_AVERAGE,
  GROUP_AVERAGE,
  GB_AVERAGE,
}

export const COMPARISON_INDEX = [
  { key: ComparisonIndex.REGION_AVERAGE, label: 'Region average', columnLabel: 'Reg Avg' },
  { key: ComparisonIndex.GROUP_AVERAGE, label: 'Group average', columnLabel: 'Group Avg' },
  { key: ComparisonIndex.GB_AVERAGE, label: 'GB average', columnLabel: 'GB Avg' },
]

export type ComparisonModelSegmentType = 'index' | 'indexDominant' | 'property'
export type ComparisonModelGroupType =
  | 'demographics'
  | 'spend'
  | 'competition'
  | 'property'
  | 'workforce'
  | 'spendSummary'

export interface ComparisonTemplate {
  competitionColumns: string[]
  competitionRatios: string[]
  demographicsColumns: string[]
  lifestyleColumns: string[]
  locationColumnsColumns: string[]
  locationRatios: string[]
  workforceColumns: string[]
}

export interface CompetitionData {
  reachable: AbstractLocation[]
  count: number
  netSalesArea: number
  grossInternalArea: number

  ratioNSAPopulation: number
  ratioGIAPopulation: number

  ratioPopulationNSA: number
  ratioPopulationGIA: number
}

export interface ComparisonModelRecipe {
  segments: {
    title: string
    type: ComparisonModelSegmentType
    children: {
      type: ComparisonModelGroupType
      title: string
      key: string

      children: {
        title: string
        key: string
        type?: 'number' | 'string'
      }[]
    }[]
  }[]
}

export interface ComparisonModelResult {
  segments: ComparisonModelSegment[]
  sources: AbstractLocation[]
}

export interface ComparisonModelSegment {
  title: string
  type: ComparisonModelSegmentType
  children: ComparisonModelGroup[]
}

export interface ComparisonModelGroup {
  title: string
  children: ComparisonModelRow[]
}

export interface ComparisonModelRow {
  title: string
  values: ComparisonModelValue[]
}

export interface ComparisonModelValue {
  type: 'string' | 'number'
  format?: string
  value: string | number
  valueIndex: number
  dominant: boolean
}

export class ComparisonModel {
  readonly statistics$: Observable<{
    sources: AbstractLocation[]
    statistics: AllStatistics[]
    competition: CompetitionData[]
    indexType: ComparisonIndex
    indexOptions: { label: string; key: ComparisonIndex; columnLabel: string }[]
  }>

  readonly report$: Observable<ComparisonModelResult>

  constructor(
    private appModel: AppModel,
    private indicators: Indicators,
    private recipe$: Observable<ComparisonTemplate>,
    private highlight$: Observable<ComparisonHighlight>,
    private index$: Observable<ComparisonIndex>
  ) {
    this.statistics$ = this.initAllCalculations()
    this.report$ = this.initReport()
  }

  private initAllCalculations() {
    const calculations$ = combineLatest(
      this.appModel.places.sources.observable,
      this.appModel.settings.travelOptionsUpdates
    ).pipe(
      switchMap(async ([sources, travelOptions]) => {
        if (!sources || !sources.length || sources.length > COMPARISON_SOURCES_THRESHOLD) {
          return {
            sources: [],
            statistics: null,
            competition: null,
          }
        }

        const statistics = await Promise.all(
          sources.map((source) => this.calculateStatistics(this.appModel, this.indicators, [source]))
        )
        const reachable = await Promise.all(
          sources.map((source) => this.calculateCompetition(this.appModel, this.indicators, [source]))
        )

        const competition = reachable.map((places, i) =>
          this.calculateReachableSummary(statistics[i], <any>places.reachable)
        )

        return {
          sources,
          statistics,
          competition,
        }
      })
    )

    return combineLatest(calculations$, this.index$, this.appModel.settings.reportPerCapitaHouseholdsUpdates).pipe(
      map(([calculations, indexType, reportPerCapitaHouse]) => {
        if (!calculations.statistics) {
          return null
        }

        let useRegionIndex = true

        switch (indexType) {
          case ComparisonIndex.REGION_AVERAGE:
            break
          case ComparisonIndex.GROUP_AVERAGE:
            break
          case ComparisonIndex.GB_AVERAGE:
            useRegionIndex = false
            break
        }

        let { region, calculatedRegion, country } = this.appModel.statistics.singleRegion(
          calculations.sources,
          useRegionIndex
        )
        calculations.statistics.forEach((statistics) => {
          statistics.updateIndices(region, calculatedRegion, country, reportPerCapitaHouse)
        })

        const indexOptions = COMPARISON_INDEX.filter((row) => {
          if (row.key === ComparisonIndex.REGION_AVERAGE) {
            return !(calculatedRegion === 'GB' || calculatedRegion === 'ROI')
          }

          return true
        }).map((row) => {
          const result = { ...row }

          if (row.key === ComparisonIndex.GB_AVERAGE) {
            if (country === 'ROI') {
              result.label = 'ROI average'
              result.columnLabel = 'ROI Avg'
            }
          }

          return result
        })

        return { ...calculations, indexType, indexOptions }
      })
    )
  }

  private initReport(): Observable<ComparisonModelResult> {
    function calculateRowValues(
      statisticsSet: AllStatistics[],
      locations: AbstractLocation[],
      competition: CompetitionData[],
      groupKey: string,
      childKey: string,
      rawValue: boolean,
      indexType: ComparisonIndex,
      valueType?: 'string' | 'number'
    ): ComparisonModelValue[] {
      function getIndex(
        statistics: AllStatistics,
        calculationType: 'percent' | 'valueHousehold',
        getter: (statistics: AllStatistics) => StatisticsValue
      ) {
        if (indexType === ComparisonIndex.GROUP_AVERAGE) {
          return (
            statisticsSet.reduce((acc, cur) => {
              let value = 0
              if (calculationType === 'percent') {
                value = getter(cur).percent
              } else if (calculationType === 'valueHousehold') {
                const households = statistics.populationOrHouseholds // census.population2011.households.total
                value = getter(cur).total / households
              }

              return acc + value
            }, 0) / statisticsSet.length
          )
        } else {
          return getter(statistics).index
        }
      }

      function calculateSingleValue(
        childKey: string | string[],
        statistics: AllStatistics,
        index: number,
        rawValue: boolean
      ): ComparisonModelValue {
        if (childKey instanceof Array) {
          if (childKey[0] === '/') {
            const format = childKey[3] || '1.0-1'
            const parts = childKey.slice(1, 3).map((item) => {
              const newKey = item.trim()
              if (item === '1') {
                return { value: 1 }
              }
              return calculateSingleValue(newKey, statistics, index, true)
            })

            return {
              type: <any>'ratio',
              value: +parts[0].value / +parts[1].value,
              valueIndex: 0,
              format,
              dominant: false,
            }
          } else {
            throw new Error('wrong type')
          }
        }

        let type: string
        let key: string

        if (childKey[0] === '*') {
          key = childKey.slice(1)
          type = 'property'
        } else {
          const nameParts = childKey.split('@')
          if (nameParts.length === 3) {
            ;[type, groupKey, key] = nameParts
          } else {
            ;[type, key] = nameParts
          }
        }

        switch (type) {
          case 'demographics': {
            const index = getIndex(statistics, 'percent', (statistics) => statistics.census[groupKey][key])
            return {
              type: valueType || <any>'number',
              value: rawValue ? statistics.census[groupKey][key].total : statistics.census[groupKey][key].percent,
              valueIndex: (statistics.census[groupKey][key].percent / index) * 100,
              dominant: false,
            }
          }

          case 'allWorkforce': {
            return {
              type: <any>'number',
              value: rawValue ? statistics.census['allWorkforce'].total : statistics.census['allWorkforce'].percent,
              valueIndex: 0,
              dominant: false,
            }
          }

          case 'workforce': {
            const index = getIndex(statistics, 'percent', (statistics) => statistics.census[groupKey][key])
            return {
              type: <any>'number',
              value: rawValue ? statistics.census[groupKey][key].total : statistics.census[groupKey][key].percent,
              valueIndex: (statistics.census[groupKey][key].percent / index) * 100,
              dominant: false,
            }
          }

          case 'property':
          case 'properties': {
            if (key === '!spaceEfficiency') {
              return {
                type: valueType || <any>'percent',
                value: (locations as any)[index].netSalesArea / (locations as any)[index].grossInternalArea,
                valueIndex: 0,
                dominant: false,
              }
            }
            return {
              type: valueType || <any>'string',
              value: (locations as any)[index][key] || (locations[index].other && locations[index].other[key]),
              valueIndex: 0,
              dominant: false,
            }
          }

          case 'spend': {
            const households = statistics.populationOrHouseholds // census.population2011.households.total
            const value = statistics.lifestyle.spend[key].total / households
            const index = getIndex(statistics, 'valueHousehold', (statistics) => statistics.lifestyle.spend[key])
            return {
              type: <any>'number',
              value,
              valueIndex: (value / index) * 100,
              dominant: false,
            }
          }

          case 'spendSummary': {
            const households = statistics.populationOrHouseholds // census.population2011.households.total
            const value = statistics.lifestyle.spendSummary[key].total / households
            const index = getIndex(statistics, 'valueHousehold', (statistics) => statistics.lifestyle.spendSummary[key])
            return {
              type: <any>'number',
              value,
              valueIndex: (value / index) * 100,
              dominant: false,
            }
          }

          case 'competition': {
            return {
              type: <any>'number',
              value: (<any>competition[index])[key],
              valueIndex: 0,
              dominant: false,
            }
          }

          default:
            throw new Error('Wrong type: ' + type)
        }
      }

      return statisticsSet.map((statistics, index) => {
        return calculateSingleValue(childKey, statistics, index, rawValue)
      })
    }

    function markDominant(highlightType: ComparisonHighlight, children: ComparisonModelRow[]) {
      children[0].values.forEach((_, column) => {
        let highest: ComparisonModelValue = null

        children.forEach((row) => {
          const value = row.values[column]

          if (!highest) {
            highest = value
          } else {
            switch (highlightType) {
              case ComparisonHighlight.HIGHEST_PERCENT:
                if (value.value > highest.value) {
                  highest = value
                }
                break

              case ComparisonHighlight.LOWEST_PERCENT:
                if (value.value < highest.value) {
                  highest = value
                }
                break

              case ComparisonHighlight.HIGHEST_INDEX:
                if (value.valueIndex > highest.valueIndex) {
                  highest = value
                }
                break

              case ComparisonHighlight.LOWEST_INDEX:
                if (value.valueIndex < highest.valueIndex) {
                  highest = value
                }
                break
            }
          }
        })

        if (highest) {
          highest.dominant = true
        }
      })

      return children
    }

    return combineLatest(
      this.recipe$,
      this.statistics$,
      this.appModel.statistics.census.value,
      this.highlight$,
      this.index$
    ).pipe(
      switchMap(async ([template, statistics, generalStatistics, highlightType, indexType]) => {
        if (!statistics) {
          return {
            segments: [],
            sources: [],
          }
        }

        const recipe = this.templateToRecipe(template, generalStatistics)
        const resultSegments = recipe.segments
          .filter((segment) => !!segment)
          .map((recipeSegment) => {
            return {
              title: recipeSegment.title,
              type: recipeSegment.type,
              children: recipeSegment.children.map((recipeGroup) => {
                return {
                  title: recipeGroup.title,
                  children: markDominant(
                    highlightType,
                    recipeGroup.children.map((child) => {
                      return {
                        title: child.title,
                        values: calculateRowValues(
                          statistics.statistics,
                          statistics.sources,
                          statistics.competition,
                          recipeGroup.key,
                          child.key,
                          recipeSegment.type === 'property',
                          statistics.indexType,
                          child.type
                        ),
                      }
                    })
                  ),
                }
              }),
            }
          })

        return {
          segments: resultSegments,
          sources: statistics.sources,
        }
      })
    )
  }

  private calculateReachableSummary(statistics: AllStatistics, places: AbstractLocation[]) {
    let netSalesArea = 0
    let grossInternalArea = 0

    places.forEach((place) => {
      netSalesArea += place.netSalesArea || 0
      grossInternalArea += place.grossInternalArea || 0
    })

    const population = statistics.census.population2011.population.total

    return {
      reachable: places,
      count: places.length,
      netSalesArea,
      grossInternalArea,

      ratioNSAPopulation: netSalesArea / population,
      ratioGIAPopulation: grossInternalArea / population,

      ratioPopulationNSA: population / netSalesArea,
      ratioPopulationGIA: population / grossInternalArea,
    }
  }

  private async calculateCompetition(appModel: AppModel, indicators: Indicators, sources: AbstractLocation[]) {
    const travelOptions = await appModel.settings.travelOptionsUpdates.take(1).toPromise()

    const [options, places] = await Observable.combineLatest(
      appModel.settings.travelOptionsUpdates,
      // appModel.places.filteredPlaces.value
      appModel.places.filteredNonPassivePlaces
    )
      .take(1)
      .toPromise()

    const boundingBox = geometry.boundingBoxListWithinTravelOptions(sources, travelOptions)

    const sourcesMap: any = {}
    sources.forEach((source) => {
      sourcesMap[source.id] = source
    })

    const isNotSource = (place: any) => !sourcesMap[place.id]

    let boundingPlaces = places
    if (boundingBox) {
      boundingPlaces = places.filter((place) => geometry.contains(boundingBox, place))
    }

    boundingPlaces = boundingPlaces.filter(isNotSource)
    const reachableLocations =
      boundingPlaces.length > 0
        ? await appModel.places.rechabilityLocations(
            canonicalPositions(sources),
            canonicalPositions(boundingPlaces),
            options
          )
        : []

    return {
      reachable: reachableLocations,
    }
  }

  private async calculateStatistics(appModel: AppModel, indicators: Indicators, sources: AbstractLocation[]) {
    const client = appModel.settings.clientStatistics
    const travelOptions = await appModel.settings.travelOptionsUpdates.take(1).toPromise()

    const [options, exclusiveMode, zonesVisible, selectedZones, reportPerCapitaHouseholds] =
      await Observable.combineLatest(
        appModel.settings.travelOptionsUpdates,
        appModel.settings.exclusiveTravelUpdates,
        appModel.zoneLayersModel.selectionExistsUpdates,
        appModel.zoneLayersModel.selectedZones,
        appModel.settings.reportPerCapitaHouseholdsUpdates
      )
        .take(1)
        .toPromise()

    let time = travelOptions.maxEdgeWeight

    if (zonesVisible) {
      return null
    } else {
      const requestOptions = { ...options }
      requestOptions.maxEdgeWeight = time

      const results: StatisticsGroup[] = await Promise.all([
        await indicators.add(
          client.statistics.combined(canonicalPositions(sources), {
            statistics: STATISTICS,
            statisticsGroup: STATISTICS_GROUP,
            ...requestOptions,
            inactiveSources: canonicalPositions(
              await appModel.places.getInactiveSources(exclusiveMode, sources, options)
            ),
          })
        ),

        await indicators.add(
          client.statistics.combined(canonicalPositions(sources), {
            statistics: STATISTICS_WORKFORCE,
            statisticsGroup: STATISTICS_GROUP_WORKFORCE,
            ...requestOptions,
            inactiveSources: canonicalPositions(
              await appModel.places.getInactiveSources(exclusiveMode, sources, options)
            ),
          })
        ),
      ])

      const result = objects.assign({}, results[0], results[1])
      const statistics = new AllStatistics(result, (await appModel.publicSettings).gbpToEuroRate)
      const { region, calculatedRegion, country } = appModel.statistics.singleRegion(sources)

      statistics.updateIndices(region, calculatedRegion, country, reportPerCapitaHouseholds)

      return statistics
    }
  }

  private templateToRecipe(template: ComparisonTemplate, generalStatistics: AllStatistics): ComparisonModelRecipe {
    function listToSegment(
      defaultCellType: string,
      type: ComparisonModelSegmentType,
      title: string,
      lists: string[][],
      sourceSegments: ReportSegment[] = []
    ) {
      const list: string[] = Array.prototype.concat.apply(
        [],
        lists.filter((item) => !!item)
      )

      if (!list || !list.length) {
        return null
      }

      function keyToTitle(key: string | string[] | string[][]): string {
        if (key instanceof Array) {
          if (key[0] === '/') {
            return [keyToTitle(key[1]), keyToTitle(key[2])].filter((item) => !!item).join(' / ')
          } else {
            throw new Error('unknown')
          }
        } else {
          if (key[0] === '*') {
            return key.slice(1)
          } else if (key === '1') {
            return ''
          }

          const item = ALL_COMPARISON_REPORT_COLUMNS_MAP[key]
          return item ? item.label : key
        }
      }

      function keyToChild(key: string | string[] | string[][]) {
        let isNumber = false
        const result: any = { key, title: keyToTitle(key) }

        if (key instanceof Array) {
          isNumber = key[0] === '/'
        } else {
          if (ALL_COMPARISON_REPORT_COLUMNS_MAP[key] && ALL_COMPARISON_REPORT_COLUMNS_MAP[key].type) {
            result.type = ALL_COMPARISON_REPORT_COLUMNS_MAP[key].type
          }
        }

        if (isNumber) {
          result.type = 'number'
        } else if (defaultCellType) {
          result.type = defaultCellType
        }

        return result
      }

      function keyToGroup(key: string) {
        const parts = key.split('@')
        const part = parts[parts.length - 1]
        const sourceSegment = sourceSegments.filter((segment) => segment.id === part)[0]

        const prefix = parts.length > 0 ? `${parts[0]}@` : ''

        if (sourceSegment) {
          return {
            title: sourceSegment.name,
            type: 'demographics' as ComparisonModelGroupType,
            key: sourceSegment.id,
            children: sourceSegment.children.map((child) => {
              return {
                key: prefix + child.id,
                title: child.name,
              }
            }),
          }
        } else {
          return null
        }
      }

      function keysToGroups() {
        if (type === 'indexDominant') {
          return list.map(keyToGroup)
        } else {
          return [
            {
              type: 'spend' as ComparisonModelGroupType,
              title: null as string,
              key: null as string,
              children: list.map(keyToChild),
              // [
              //   {key: 'comparisonTotal',  title: 'Comparison goods spend'},
              //   {key: 'convenience',  title: 'Convenience goods spend (total, per household, index)'},
              // ]
            },
          ]
        }
      }

      return {
        type: type,
        title: title,
        children: keysToGroups(),
      }
    }

    return {
      segments: [
        listToSegment(null, 'property', 'Operator, Location & Store Details', [
          template.locationColumnsColumns,
          template.locationRatios,
        ]),
        listToSegment('integer', 'property', 'Demographics', [
          template.demographicsColumns.filter((item) => item.split('@').length >= 3),
        ]),
        listToSegment(
          'number',
          'indexDominant',
          'Demographics',
          [template.demographicsColumns.filter((item) => item.split('@').length < 3)],
          getCensusSegments(generalStatistics)
        ),
        listToSegment('number', 'index', 'Spend', [template.lifestyleColumns]),
        listToSegment('number', 'indexDominant', 'Workforce', [template.workforceColumns], WORKFORCE_SEGMENTS),
        listToSegment('number', 'property', 'Competition', [template.competitionColumns, template.competitionRatios]),
      ].filter((segment) => !!segment),
    }
  }
}
