import { Component } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { PlanningApplicationDataSetEndpoint } from '../../../api/planningApplicationDataSet';
import { PlanningApplicationDataSet } from '../../../../common/models';

@Component({
  selector: 'admin-planning-applications-list-list',
  templateUrl: './planningApplicationsList.component.html'
})
export class AdminPlanningApplicationsListComponent {
  dataSets: PlanningApplicationDataSet[] = []

  constructor(private datasetEndpoint: PlanningApplicationDataSetEndpoint, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.updateList()
  }

  async updateList() {
    this.dataSets = await this.datasetEndpoint.findAll()
  }

  showMenu(event: any) {
    event.stopPropagation()
  }  
}