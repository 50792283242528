import { PDFHelper } from "./helper";
import { CensusStatisticsGroup, StatisticsValue } from "../../../common/models/statistics/statistics";
const Color = require('color')

function drawGraph(callback: (ctx: CanvasRenderingContext2D) => void, width = 1000, height = 500) {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')

  ctx.fillStyle = '#ffffff'
  ctx.strokeStyle = '2px solid #000000'

  callback(ctx)

  return canvas.toDataURL()
}

function processData(data: CensusStatisticsGroup) {
  return Object.keys(data).filter(key => data[key] && data[key].total != null).map(key => {
    return {
      key,
      data: data[key] as StatisticsValue
    }
  })
}


export function graphWorkforce(helper: PDFHelper, data: CensusStatisticsGroup, individualLabels: {[index: string]: string}): string {
  const TOTAL_HEIGHT = 400
  const TOTAL_WIDTH = 2000

  return drawGraph(ctx => {
    const items = processData(data)

    const FONT_HEIGHT = 24
    ctx.font = '24px "open sans"'

    const width = Math.floor(TOTAL_WIDTH / items.length)
    items.forEach((item, i) => {
      ctx.fillStyle = helper.tableBorder()
      const height = Math.round(TOTAL_HEIGHT * item.data.percent / 100)
      ctx.fillRect(i * width + 10, TOTAL_HEIGHT - height, width - 20, height)

      const label = individualLabels[item.key]
      const valueLabel = helper.formatNumber(item.data.percent, 1) + '%'

      const labelMetrics = ctx.measureText(label)
      const valueLabelMetrics = ctx.measureText(valueLabel)

      const textLeft = i * width + (width / 2)

      ctx.fillStyle = '#555555'
      ctx.fillText(label, textLeft - (labelMetrics.width / 2), TOTAL_HEIGHT + 40)

      if (FONT_HEIGHT > height - 30) {
        ctx.fillStyle = helper.tableBorder()
        ctx.fillText(valueLabel, textLeft - (valueLabelMetrics.width / 2), TOTAL_HEIGHT - height - FONT_HEIGHT - 20)
      } else {
        ctx.fillStyle = '#ffffff'
        ctx.fillText(valueLabel, textLeft - (valueLabelMetrics.width / 2), TOTAL_HEIGHT - (height / 2) + FONT_HEIGHT / 2)
      }
    })

  }, TOTAL_WIDTH, TOTAL_HEIGHT + 100)
}


function drawLegend(ctx: CanvasRenderingContext2D, items: {label: string, color: string}[], y: number, width: number) {
  let totalWidth = 0
  const GAP = 30
  const BOX = 20

  let currentRow: any[] = []
  let rows: any[][] = [currentRow]
  items.forEach(item => {
    const labelMetrics = ctx.measureText(item.label)
    const currentWidth = labelMetrics.width + GAP + BOX
    totalWidth += currentWidth
    if (totalWidth > width) {
      totalWidth = currentWidth
      currentRow = []
      rows.push(currentRow)
    }

    currentRow.push(item)
  })

  // const rows = [items]
  rows.forEach((rowItems, i) => {
    drawLegendRow(ctx, rowItems, y + (i * 50), width, GAP, BOX)
  })
}

function drawLegendRow(
  ctx: CanvasRenderingContext2D, 
  items: {label: string, color: string}[], 
  y: number, 
  width: number,
  gap: number,
  box: number,

) {
  let totalWidth = 0

  items.forEach(item => {
    const labelMetrics = ctx.measureText(item.label)
    totalWidth += labelMetrics.width + gap + box
  })

  let start = (width - totalWidth) / 2
  items.forEach(item => {
    const labelMetrics = ctx.measureText(item.label)
    ctx.fillStyle = item.color
    ctx.fillRect(start, y + 2, box - 4, box - 4)
    ctx.fillStyle = '#555555'
    ctx.fillText(item.label, start + box + 4, y + 16) // FIXME

    start += labelMetrics.width + gap + box
  })
}

export function graphValueIndex(
  helper: PDFHelper, 
  data: CensusStatisticsGroup, 
  individualLabels: {[index: string]: string}, 
  totalHeight: number,
  individualColors: {[index: string]: string},  
  travelTitle: string
): string {
  const TOTAL_HEIGHT = totalHeight
  const TOTAL_WIDTH = 1000

  return drawGraph(ctx => {
    const items = processData(data).filter(item => individualLabels[item.key])

    const FONT_HEIGHT = 24
    ctx.font = '24px "open sans"'

    let maxLabelWidth = 0
    let maxValue = 0
    items.forEach(item => {
      const label = individualLabels[item.key]
      const labelMetrics = ctx.measureText(label)
      maxLabelWidth = Math.max(maxLabelWidth, labelMetrics.width)
      maxValue = Math.max(maxValue, Math.max(item.data.index || 0, item.data.percent || 0))
    })

    const widthFactor = 90 / maxValue

    const GAP = 50
    maxLabelWidth += 40
    const barWidth = (TOTAL_WIDTH - maxLabelWidth) * widthFactor
    const height = TOTAL_HEIGHT / items.length

    items.forEach((item, i) => {
      const label = individualLabels[item.key]
      const valueLabel = helper.formatNumber(item.data.percent, 2) + '%'
      const indexLabel = helper.formatNumber(item.data.index, 2) + '%'

      const labelMetrics = ctx.measureText(label)
      const barHeight = (height - GAP) / 2

      const top = i * height
      ctx.fillStyle = '#000000'
      ctx.fillText(label, maxLabelWidth - labelMetrics.width - 10, top + barHeight)

      ctx.fillStyle = helper.tableBorder()
      // ctx.fillStyle = individualColors[item.key] // helper.tableBorder()
      ctx.fillRect(maxLabelWidth, top, barWidth / 100 * item.data.percent, barHeight)

      // ctx.fillStyle = Color(individualColors[item.key]).lighten(0.5).hex()
      ctx.fillStyle = Color(helper.tableBorder()).lighten(0.5).hex()
      ctx.fillRect(maxLabelWidth, top + barHeight, barWidth  / 100 * item.data.index, barHeight)

      function drawLabel(text: string, y: number, width: number) {
        const textMetrics = ctx.measureText(text)
        if (textMetrics.width > width - 30) {
          ctx.fillStyle = helper.tableBorder()
          ctx.fillText(text, maxLabelWidth + width + 30, y)
        } else {
          ctx.fillStyle = '#ffffff'
          ctx.fillText(text, maxLabelWidth + (width / 2) - (textMetrics.width / 2), y)
        }
      }

      const labelExtra = 7
      drawLabel(valueLabel, top + (barHeight / 2)  + labelExtra, barWidth / 100 * item.data.percent)
      drawLabel(indexLabel, top + barHeight + (barHeight / 2) + labelExtra, barWidth / 100 * item.data.index)
    })

    const legendItems = [
      {label: travelTitle || 'Travel-Time', color: helper.tableBorder()},
      {label: 'Region Average', color: Color(helper.tableBorder()).lighten(0.5).hex()},
    ]
    drawLegend(ctx, legendItems, TOTAL_HEIGHT, TOTAL_WIDTH)

  }, TOTAL_WIDTH, TOTAL_HEIGHT + 150)
}


export function graphPie(
  helper: PDFHelper,
  data: CensusStatisticsGroup,
  individualLabels: {[index: string]: string},
  individualColors: {[index: string]: string},
): string {
  const TOTAL_HEIGHT = 700
  const TOTAL_WIDTH = 1000

  return drawGraph(ctx => {
    const items = processData(data).filter(item => individualLabels[item.key])

    const FONT_HEIGHT = 24
    ctx.font = '24px "open sans"'

    let angle = 270
    items.forEach(item => {
      ctx.fillStyle = individualColors[item.key]
      ctx.strokeStyle = '#000000'
      let valueAngle = item.data.percent / 100 * 360

      ctx.save()
      ctx.beginPath();
      ctx.moveTo(TOTAL_WIDTH / 2, TOTAL_HEIGHT / 2);

      const startRadians = (angle % 360) * (Math.PI / 180)
      const endRadians = ((angle - valueAngle) % 360) * (Math.PI / 180)

      ctx.arc(TOTAL_WIDTH / 2, TOTAL_HEIGHT / 2, (TOTAL_HEIGHT * .9) / 2, startRadians, endRadians, true);

      ctx.closePath();
      ctx.stroke();
      ctx.fill();
      ctx.restore()

      angle -= valueAngle
    })


    angle = 90
    items.forEach(item => {
      ctx.fillStyle = '#ffffff'
      ctx.strokeStyle = '#ffffff'

      let valueAngle = item.data.percent / 100 * 360
      // const startRadians = (270 - (angle % 360)) * (Math.PI / 180)
      const endRadians = ((90 + (angle + (valueAngle / 2))) % 360) * (Math.PI / 180)

      const labelText = helper.formatNumber(item.data.percent) + '%'
      const labelDistance = 200
      ctx.fillText(labelText, TOTAL_WIDTH / 2 + (Math.sin(endRadians)) * labelDistance, TOTAL_HEIGHT / 2 + (Math.cos(endRadians)) * labelDistance)

      angle += valueAngle

    })

    const legendItems = items.map(item => {
      return {
        label: individualLabels[item.key],
        color: individualColors[item.key],
      }
    })

    drawLegend(ctx, legendItems, TOTAL_HEIGHT, TOTAL_WIDTH)
  }, TOTAL_WIDTH, TOTAL_HEIGHT + 150)
}