import { Component, OnInit } from '@angular/core'
import { AppModel } from '../../../model/appModel.service'
import { SubscriberComponent } from '@targomo/client'

@Component({
  selector: 'travel-time-distance-switch',
  templateUrl: './travelTimeDistanceSwitch.component.html',
  styleUrls: ['./travelTimeDistanceSwitch.component.less'],
})
export class TravelTimeDistanceSwitchComponent extends SubscriberComponent {
  region: string
  country: string
  value: boolean = true

  constructor(readonly appModel: AppModel) {
    super()
  }

  ngOnChanges() {}

  changeValue(value: any) {
    this.appModel.displaySettings.nextProperty('travelTimeDistanceSorting', value)
  }
}
