import { Component } from '@angular/core';
import { DataSet } from "../../../api";
import {Auth} from '@targomo/client';
import {DataSetEndpoint} from '../../../api/dataSet';
import {Router, ActivatedRoute} from '@angular/router';
import { GeoRegionsEndpoint } from '../../../api/georegions';

@Component({
  selector: 'admin-georegions-list',
  templateUrl: './adminGeoRegionsList.html'
})
export class AdminGeoRegionsListComponent {
  dataSets: DataSet[]

  constructor(private datasetEndpoint: GeoRegionsEndpoint, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.updateList()
  }

  async updateList() {
    this.dataSets = await this.datasetEndpoint.findAll()
  }

  showMenu(event: any) {
    event.stopPropagation()
  }
}
