import { Component } from '@angular/core'
import { SubscriberComponent } from '@targomo/client'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { CustomBarGraphOptions } from '../../../../components/tgm-custom-bargraph/bargraph.component'
import { AppModel } from '../../../../model/appModel.service'
import { PostcodeAnalysisModel, PostcodeAnalysisReport } from '../../../../model/postcodeAnalyssModel'
import { travelLabel } from '../../../pdf/travelLabel'
import { PercentPipe } from '@angular/common'
import { MEASURER } from '../histogramTable/histogramTable.component'

const PERCENT_PIPE = new PercentPipe('en')
@Component({
  selector: 'postcode-analysis',
  templateUrl: './postcodeAnalysis.component.html',
  styleUrls: ['./postcodeAnalysis.component.less'],
})
export class PostcodeAnalysisComponent extends SubscriberComponent {
  travelTitle$: Observable<string>
  travelTitleHint$: Observable<string>
  report$: Observable<PostcodeAnalysisReport>

  graphPopulationOptions: CustomBarGraphOptions

  constructor(readonly appModel: AppModel, readonly postcodeAnalysisModel: PostcodeAnalysisModel) {
    super()

    this.travelTitle$ = this.appModel.settings.travelOptionsUpdates.pipe(
      map((options) => travelLabel(options.travelType) + '-Time')
    )

    this.travelTitleHint$ = this.appModel.settings.travelOptionsUpdates.pipe(
      map((options) => travelLabel(options.travelType).toLocaleLowerCase() + ' time')
    )

    this.report$ = this.postcodeAnalysisModel.value$

    this.graphPopulationOptions = new CustomBarGraphOptions({
      width: 420,
      showValues: true,
      showControls: false,
      axisLabelDistance: 5,
      xAxisLabelRotate: -45,
      yAxisTickValues: [0, 0.5, 1],
      xAxisLabel: '',
      yAxisLabel: '',
      height: 450,
      valuesFormat: (value: any) => PERCENT_PIPE.transform(value, '1.0-0'),
      margin: { left: 60, bottom: 150 },
      tooltip: (item: any) => {
        return `
        <table>
          <tr>
            <td class="legend-color-guide"><div style="background-color: ${item.color}"></div></td>
            <td class="key">${item.data ? item.data.label : ''}</td>
          </tr>
          <tr>
            <td></td>
            <td class="value">${item.data ? PERCENT_PIPE.transform(item.data.value, '1.0-0') : ''}</td>
          </tr>
        </table>
        `
      },
      color: (item: any, index: number) => {
        return '#33aeff'
      },
    })

    this.initGraphOptions()
  }

  private initGraphOptions() {
    this.watch(this.report$, (report) => {
      if (!report || !report.graphModel || !report.graphModel[0]) {
        return
      }

      const values = report.graphModel[0].values.map((item) => item.value)

      const maxValue = Math.max.apply(null, values || [])
      const tickBase = Math.round((maxValue * 100) / 4) / 100

      if (tickBase) {
        const tickValues = []
        for (let v = 0; v < maxValue; v += tickBase) {
          tickValues.push(v)
        }

        this.graphPopulationOptions.yAxisTickValues = tickValues
      }

      const labels = report.graphModel[0].values.map((item) => item.label) || []
      const maxLength = Math.round(
        Math.max.apply(
          null,
          labels.map((v) => MEASURER.width(v))
        )
      )

      const maxLengthFinite = isFinite(maxLength) ? maxLength * 1.3 + 40 : 0

      this.graphPopulationOptions.height = 300 + maxLengthFinite
      this.graphPopulationOptions.margin = { left: 60, bottom: maxLengthFinite }
    })
  }
}
