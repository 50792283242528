<tgm-loadable loading="loading">
  <mat-list style="text-align: center; margin: 10px 0px; background: #f0f0f0; padding: 0px">
    <travel-time-distance-switch></travel-time-distance-switch>
  </mat-list>

  <span class="no-data" *ngIf="!places.length">No Data</span>

  <ng-container *ngIf="!!places.length">
    <ng-container *ngIf="!groups">
      <table>
        <tr>
          <th>Name</th>
          <ng-container [ngSwitch]="travelTimeDistanceSorting">
            <th *ngSwitchCase="'travelTime'">Travel Time (mins)</th>
            <th *ngSwitchCase="'shortestTravelDistance'">Shortest Distance (km)</th>
            <th *ngSwitchCase="'fastestTravelDistance'">Fastest Travel Distance Time (km)</th>
          </ng-container>

          <th>GIA (sq ft)</th>
          <th>NSA (sq ft)</th>
        </tr>

        <tr
          (click)="click(item)"
          *ngFor="let item of places"
          (mouseleave)="hover(null)"
          (mouseenter)="hover(item)"
          [class.hover]="hoverPlace && (hoverPlace.id == item.id)"
        >
          <td>{{item.defaultName}}</td>

          <ng-container [ngSwitch]="travelTimeDistanceSorting">
            <td *ngSwitchCase="'travelTime'">{{item.travelTime | minutesHoursSimple}}</td>
            <td *ngSwitchCase="'shortestTravelDistance'">{{item.shortestTravelDistance | distance}}</td>
            <td *ngSwitchCase="'fastestTravelDistance'">{{item.fastestTravelDistance | distance}}</td>
          </ng-container>

          <td>{{item.grossInternalArea | number}}</td>
          <td>{{item.netSalesArea | number}}</td>
        </tr>
      </table>
    </ng-container>

    <ng-container *ngIf="!!groups">
      <table>
        <ng-container *ngFor="let group of groups">
          <tr>
            <td colspan="4" class="group-title">{{group.name}}</td>
          </tr>

          <tr>
            <th>Fascia</th>
            <th>Count</th>
            <th>GIA (sq ft)</th>
            <th>NSA (sq ft)</th>
          </tr>

          <tr
            (click)="click(item)"
            *ngFor="let item of group?.fascias"
            [class.hover]="hoverPlace && (hoverPlace.id == item.id)"
          >
            <td>{{item.category.name}}</td>
            <td>{{item.count | number}}</td>
            <td>{{item.grossInternalArea | number}}</td>
            <td>{{item.netSalesArea | number}}</td>
          </tr>

          <tr>
            <td><b>Total</b></td>
            <td>{{group.values.length | number}}</td>
            <td>{{group.grossInternalArea | number}}</td>
            <td>{{group.netSalesArea | number}}</td>
          </tr>
        </ng-container>

        <tr>
          <td><b>Grand Total</b></td>
          <td></td>
          <td>{{totals.grossInternalArea | number}}</td>
          <td>{{totals.netSalesArea | number}}</td>
        </tr>
      </table>
    </ng-container>
  </ng-container>
</tgm-loadable>
