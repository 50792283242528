import {Component} from '@angular/core'
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {UserEndpoint} from '../../api/user';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'password-reset-component',
  templateUrl: './passwordReset.html',
  styleUrls: ['./account.less']
})
export class PasswordResetComponent {
  user = {
    password: ''
  }
  form: FormGroup
  requestSent = false
  saving: Promise<any>

  constructor(private formBuilder: FormBuilder, private userEndpoint: UserEndpoint, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    const areEqual = () => {
      if (this.form.value.password != this.form.value.confirmPassword) {
        return null
      }

      return {
        areEqual: true
      }
    }

    this.form = this.formBuilder.group({
      'password': [null, [Validators.required, Validators.minLength(3)]],
      'confirmPassword': [null, [Validators.required]]//, {validator: areEqual}]],
    })
  }

  async submit() {
    const params = this.route.snapshot.params
    const code = params.code

    ;(<any>this.form).submitted = true

    if (this.form.valid) {
      this.saving = this.userEndpoint.resetPassword(code, this.form.value.password).catch(error => {
        throw new Error("Password reset failed")
      })
      await this.saving
      this.requestSent = true
    }
  }
}