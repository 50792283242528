import { PDFHelper, TABLE_BACKGROUNDS } from './helper'
import { PostcodeAnalysisReport } from '../../model/postcodeAnalyssModel'
import { PercentPipe } from '@angular/common'

const PERCENT_PIPE = new PercentPipe('en')

const BACKGROUNDS = ['#fafafa', null]

export function postcodeAnalysisReport(reportModel: PostcodeAnalysisReport, helper: PDFHelper) {
  const grouppedData = reportModel.groups.map((group) => {
    const table = helper.table(
      [{ text: group.title, colspan: 2, style: 'postcodesCellGroup' }, ''],
      [['Location', '% of Store Customer Postcodes']].concat(
        group.categories.map((category) => [category.name, PERCENT_PIPE.transform(category.percent)])
      ),
      'defaultTable',
      {
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i == 1 ? 1 : 0
          },

          vLineWidth: function (i: number, node: any) {
            return 0
          },

          hLineColor: function (i: number, node: any) {
            return '#ccc'
          },

          vLineColor: function (i: number, node: any) {
            return helper.tableBorder(1)
          },

          fillColor: function (i: number, node: any) {
            return BACKGROUNDS[i % TABLE_BACKGROUNDS.length]
          },
        },
      }
    )

    return [{ stack: [table], margin: [0, 20, 0, 0] }]
  })

  return [
    {
      text: `Count of existng estate customer home postcodes, by drive-time, from subject locaton`,
      style: 'postcodesHint',
    },
    ...Array.prototype.concat.apply([], grouppedData),
    {
      text: `‘Others’ include stores where customer home postcode counts are less than or equal to 2.`,
      style: 'postcodesHint',
    },
  ]
}
