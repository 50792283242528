<div class="admin-panel">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Edit Geo Region</h2>
    <div class="secondary">
        <button mat-button class="mat-primary" routerLink="/admin/georegion/upload/{{id}}"><mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> Upload New Geo Region Version</button>
    </div>
  </div>

  <form [formGroup]="form" class="form" name="form" (submit)="save(form)"  novalidate style="margin-bottom: 30px">
    <section fxLayout="column">
      <mat-form-field>
        <input placeholder="Name" matInput type="text" formControlName="name"/>

      </mat-form-field>
    </section>

    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-button type="button" (click)="back()">&larr; Back</button>
      <button mat-flat-button color="primary" type="submit">Save</button>
    </div>
  </form>

  <section style="margin-bottom: 20px">
    <h3 class="mat-title subtitle">Versions</h3>
    <admin-georegion-versions (updated)="updatedVersion()" [id]="id"></admin-georegion-versions>
  </section>
</div>