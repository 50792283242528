<section style="width: 80vw; max-width: 350px">
  <form>
    <div>
      {{this.model.message}}
    </div>

    <div class="spinner-container" fxLayout="row" fxLayoutLign="center center">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </form>
</section>