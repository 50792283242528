import {Component, ViewChild} from '@angular/core'
import {MatMenuTrigger} from '@angular/material'
import {MatDialog} from '@angular/material'
import { UserEndpoint } from '../../api/user'
import { TgmQuickDialogs } from '@targomo/client'
import { User } from "../../api/index"
import { DataSetLocation } from '../../model/index'
import {files as filesUtil} from '@targomo/client'
import * as importLayer from '../../../common/util/importLayer'
import { DataSetEndpoint, DataSet } from '../../api/dataSet';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_BASE_PATH } from "../../util/basePath";
import { NgZone } from '@angular/core';

export function waitTimeout(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export const DATA_SET_UPLOAD_FIELDS  = [
  'lat',
  'lng',
  'lat_alt',
  'lng_alt',
  'store_id',
  'primary_category',
  'secondary_category',
  'holding_company',
  'fascia',
  'sitename',
  'paon',
  'saon',
  'taon',
  'street',
  'suburb',
  'town',
  'postcode',
  'district',
  'county',
  'region',
  'net_sales_area',
  'net_sales_area_source',
  'gross_internal_area',
  'gross_internal_area_source',
]

@Component({
  selector: 'admin-upload-data-set',
  templateUrl: './uploadDataSet.html'
})
export class AdminUploadDataSetComponent {
  locations: DataSetLocation[]
  locationError: string = null
  locationCategorizeError: string = null

  basePath = APP_BASE_PATH
  saving: Promise<{}> | boolean
  set: DataSet = null
  form: FormGroup
  isSaving: boolean

  locationsFound = false
  locationsLength = 0

  private holdingCompanyCount = 0
  private secondaryCategoryCount = 0
  private skipClientValidation = false


  availableDataFields: string[] = DATA_SET_UPLOAD_FIELDS
  requiredDataFields: any = {
    'lat' : true,
    'lng' : true,
  }

  showCategorizeField = true
  showTemplates = true

  submitted = false
  private files: any


  constructor(private formBuilder: FormBuilder, private usersEndpoint: UserEndpoint, 
              private dataSetEndpoint: DataSetEndpoint, private dialog: MatDialog, 
              private zone: NgZone,
              private quickDialogs: TgmQuickDialogs, private route: ActivatedRoute, private router: Router) {
    this.form = this.formBuilder.group({
      'name': [null, []],
      'categorize': [null, []],
    })

    this.init()
  }

  async init() {
    const params = this.route.snapshot.params
    const id = params.id

    if (id) {
      this.set = await this.dataSetEndpoint.get({id})
    }

    this.form = this.formBuilder.group({
      'name': [this.set ? this.set.name : null, [Validators.required]],
      'categorize': [id == 1 ? 'holding_company' : 'secondary_category', [Validators.required]],
    })
  }

  async save() {
    this.submitted = true

    if (this.form.valid && !this.locationError) {
      this.locationCategorizeError = null
      const categorize = this.form.value.categorize === 'holding_company' ? 'holdingCompany' : 'secondaryCategory'
      if ((categorize === 'holdingCompany' && this.locationsLength > this.holdingCompanyCount)
          || (categorize === 'secondaryCategory' && this.locationsLength > this.secondaryCategoryCount)) {
        this.locationCategorizeError = 'Empty column `' + this.form.value.categorize + '`'
      }
    }

    if (this.form.valid && !this.locationError && !this.locationCategorizeError) {
      this.isSaving = true
      console.log('SAVE SET', this.set)
      const result = await (this.saving = this.dataSetEndpoint.uploadMultipart(this.files, this.set || {name: this.form.value.name}, this.form.value.categorize))
      this.router.navigate(['/admin/dataset/' + result])
    }
  }

  async selectFile(files: File[]) {
    this.files = files

    if (!files || !files.length) {
      this.locations = null
      return
    }

    if (this.skipClientValidation) {
      this.locationsFound = true
      return
    }

    this.locationError = null
    try {
      this.locations = null
      let total = 0

      this.holdingCompanyCount = 0
      this.secondaryCategoryCount = 0

      const result = <string>await filesUtil.readFile(files[0])
      this.zone.run(async () => {
        this.saving = true
      })
      
      await waitTimeout(100)
      
      await new Promise((resolve, reject) => {
        this.zone.runOutsideAngular(async () =>  {
          try {
            await importLayer.parse(result, async items => {
              total += items.length

              items.forEach(item => {
                if (item['holdingCompany']) {
                  this.holdingCompanyCount++
                }

                if (item['secondaryCategory']) {
                  this.secondaryCategoryCount++
                }
              })
              
            }, 10000)

            await waitTimeout(20)
            resolve(true)
          } catch (e) {
            reject(e)
          }
        })
      })

      this.zone.run(async () => {
        this.saving = false
      })
      await waitTimeout(200)
      
      this.locationsLength = total//this.locations.length
      this.locationsFound = true

      await waitTimeout(100)

    } catch (e) {
      this.locationError = e.message || 'Layer data not valid'
    }
  }

  cancel() {
    if (this.set)
      this.router.navigate(['/admin/dataset/' + this.set.id])
    else
      this.router.navigate(['/admin/datasets'])
  }
}
