import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { AppModel } from '../../model/appModel.service'
import { SettingsModel } from '../../model/settingsModel'
import { OptionsDialogService } from '../optionsDialog/optionsDialog.service'

@Component({
  selector: 'category-search-panel',
  templateUrl: './categorySearchPanel.component.html',
  styleUrls: ['./categorySearchPanel.component.less'],
})
export class CategorySearchPanelComponent implements OnInit {
  searchString: string
  placeholder: string

  formControl: FormControl = new FormControl()

  constructor(
    readonly appModel: AppModel,
    readonly settings: SettingsModel,
    private optionsDialogService: OptionsDialogService
  ) {}

  ngOnInit() {
    this.formControl.valueChanges.subscribe((query) => {
      this.appModel.places.categorySearchText.next((query || '').trim())
    })
  }

  clearSelection() {
    this.formControl.setValue('')
  }

  async showMapped() {
    this.settings.displaySettings.nextPropertyWithCurrent('showOnlyMappedCategories', (current) => {
      return !current
    })
  }

  clearLayers() {
    this.appModel.places.setVisibleCategories({}, true)
    this.appModel.sessionLoaded.next(true)
  }

  mapFilters() {
    this.optionsDialogService.open('map_filters_primary')
  }
}
