<h3 style="text-transform: uppercase" class="mat-title">Gap Report Options</h3>

<mat-list>
  <mat-list-item (click)="toggleGeneral('showCompetition')">
    <mat-checkbox style="pointer-events: none" [checked]="model?.showCompetition"></mat-checkbox>
    Show Competition
  </mat-list-item>

  <!-- <mat-list-item (click)="toggleGeneral('showCatchment')">
    <mat-checkbox style="pointer-events: none" [checked]="model?.showCatchment" aria-label=""></mat-checkbox>
    Show Travel Area
  </mat-list-item> -->
</mat-list>

<h3 style="text-transform: uppercase" class="mat-title">Gap Report Columns</h3>

<mat-list>
  <mat-list-item *ngFor="let profile of availableCompetitionColumns" (click)="toggleCompetitionColumn(profile)">
    <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
    {{ profile.label }}
  </mat-list-item>
</mat-list>
