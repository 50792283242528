export interface CustomImageData {
  data: string
  width: number
  height: number
}

export function reportHeader(imageData :string, title: string) {
  return (i: any): any => i > 1 ? {
    columns: [
      [
        {text: title || 'Travel Time Report'},
      ],
      reportHeader ? [{image: imageData, width: 100, style: 'alignRight'}] : ''
    ],
    style: 'contentHeader'
   } : []
}


const calculateCustomImage = (customImage: CustomImageData) => {
  if (!customImage.data)
    return []

  var height = 25
  var width  = Math.round(customImage.width * 25 / customImage.height)

  return [{image: customImage.data, width: width, style: 'alignRight'}]
}

export function reportFooter(copyright: string, customImage: CustomImageData, shortFooter: boolean) {
  return (i: any): any => {
    const pageNumber = (+i > 1) ? ('' + (i - 1)) : ''

    return [
      {
        text: '' + (i),
        style: 'pageNumber',
        absolutePosition: {x: 40,  y: 320}

      },
      {
        columns: [
          {
            stack: [
              {text: copyright},              
            ].concat(shortFooter ? [] : [{text: 'T: 01865 821112 | E: info@oxretail.com | www.oxretail.com'}]),
            width: '40%'
          },

          {
            text: pageNumber,
            style: 'pageNumber',
            width: '20%'

          },

          {
            stack: [
              calculateCustomImage(customImage)
            ],
            width: '40%'
          },
          
          // [
          //   {text: copyright},
          //   // {text: 'T: 01865 821112 | E: info@oxretail.com | www.oxretail.com'}
          // ],

          // {text: '' + (i), width: '100%', style: 'alignCenter'},
          
        ],
        style: 'contentFooter',
      }
    ]
  }
}