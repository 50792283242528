<section style="width: 80vw; max-width: 350px">
  <form (ngSubmit)="save()">
    <div *ngIf="withColors">
      <h3 mat-dialog-title translate="menu.selectLayerColor">Select color</h3>

      <span
        [style.background]="color"
        cpWidth="350px"
        [cpToggle]="true"
        [cpDialogDisplay]="'inline'"
        [cpCancelButton]="false"
        [(colorPicker)]="color"
      ></span>
    </div>

    <div *ngIf="withIcons">
      <h3 mat-dialog-title translate="menu.selectLayerColor">Select icon</h3>

      <div>
        <button type="button" (click)="selectIcon(icon)" mat-icon-button *ngFor="let icon of ICONS">
          <!-- <img [class.selected]="selectedIcon == icon.id" class="marker-icon" src="assets/images/{{ icon.file }}" /> -->
          <mat-icon
            class="marker-icon"
            color="primary"
            [class.selected]="selectedIcon == icon.id"
            svgIcon="tgm_marker_{{ icon.id }}"
          ></mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="withIcons" style="margin-top: 16px">
      <h3 mat-dialog-title translate="menu.selectLayerColor" style="margin: 0px">Select icon size</h3>
      <mat-select name="iconSize" style="width: 100%" [value]="iconSize" (selectionChange)="selectIconSize($event)">
        <mat-option *ngFor="let item of SIZES" [value]="item.value">{{ item.label }}</mat-option>
      </mat-select>
    </div>

    <div class="dialog-bottom">
      <button mat-button (click)="dialogRef.close()" mat-dialog-close>
        <span translate="dialog.cancel">Cancel</span>
      </button>
      <button mat-raised-button color="primary" mat-button><span translate="dialog.save">Save</span></button>
    </div>
  </form>
</section>
