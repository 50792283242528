import * as core from '@targomo/core'
import { TargomoClient, LatLngId, TravelType, TravelTypeEdgeWeightOptions, SimpleLRU, TravelMoreRequestOptions, } from '@targomo/core';
import { UrlUtil } from '../util/urlUtil';
import { requests } from '../util/requestUtil';
import { BIKE_WALK_SPEED_PENALTIES } from '../model/appModel.service';

class TypedSimpleLRU extends SimpleLRU<any> {
  get<U>(key: any, factory?: () => Promise<U>): Promise<U> {
    return super.get(key, factory)
  }
}

export interface MultigraphOverview {
  amountSources?: number
  routingProgress?: number
  status?: 'CREATED' | 'ROUTING' | 'MERGING' | 'AGGREGATING' | 'COMPLETED' | 'FAILED' // We could show this in the UI
  // baseGraphIds?: number[]
  multiGraphInfo?: MultigraphOverviewInfo
  // data?: {
  //   maxValue: number
  //   minValue: number
  //   northEast: { x: number; y: number; z: number }
  //   southWest: { x: number; y: number; z: number }
  //   valueBreaks?: number[]
  //   kmeansValueBreaks?: number[]
  //   equalIntervalValueBreaks?: number[]
  // }
}

export interface MultigraphOverviewInfo extends Partial<MultigraphMinMax> {
  northEast: { x: number; y: number; z: number }
  southWest: { x: number; y: number; z: number }
  layersInfo?: { [key: string]: MultigraphMinMax }
}

export interface MultigraphMinMax {
  maxValue: number
  minValue: number
  kmeansValueBreaks?: number[]
  equalIntervalValueBreaks?: number[]
  errorMessage?: string
}

const CACHE = new TypedSimpleLRU(50)

function createMultigraphPayload(
  client: TargomoClient,  
  sources: LatLngId[],
  options: {
    travel: TravelTypeEdgeWeightOptions & {transitFrameTime?: number, transitFrameDate?: number},
    statisticsGroup: number,
    statisticsId: number,
  }
) {
  const sourcesPayload = sources.map(source => {
    return {
      id: source.id,
      x: source.lng,
      y: source.lat,
    }
  })

  const sourcesIdsMap = sources.reduce((acc, cur) => {
    acc[cur.id] = {}
    return acc
  }, {} as any)

  const hasStatistics = options.statisticsId >= 0

  const statistics = !hasStatistics ? {} : {
    "multiGraphReferencedStatisticIds": {
      "statistics": options.statisticsId
    }
  }

  return {
    "multiGraphLayerGeometryDetailPerTile": 6,
    "multiGraphLayerMinGeometryDetailLevel": 5,
    "multiGraphLayerMaxGeometryDetailLevel": 18,
    "elevationEnabled": true,
    "multiGraphAggregationType": "none",
    "multiGraphSerializationFormat": "mvt",
    "multiGraphSerializationMaxGeometryCount": 100000,
    "multiGraphAggregationInputParameters": hasStatistics 
      ? {
          "reachability": {},
          "statistics": {}
      }
      : {
        "reachability": {},
      }
      ,
    "multiGraphAggregationIgnoreOutliers": false,
    "multiGraphDomainType": "statistic_geometry",
    "edgeWeight": "time",
    "travelType": options.travel.travelType,
    "maxEdgeWeight": options.travel.maxEdgeWeight,
    "serviceKey": client.serviceKey,
    "serviceUrl": client.serviceUrl,
    "multiGraphPreAggregationPipeline": {
        "reachability": {
            "type": "routing_union",
            "minSourcesCount": 1,
            "maxResultValueRatio": 1,
            "ignoreOutliers": false,
            "aggregationInputParameters": sourcesIdsMap
        }
    },
    "multiGraphLayerType": "identity",
    // "maxTransfers": null as any,
    // "maxWalkingTimeFromSource": -1,
    // "rushHour": false,
    "time": options.travel.transitFrameTime !== undefined ? options.travel.transitFrameTime : null,
    "date": options.travel.transitFrameDate !== undefined ? options.travel.transitFrameDate : null,
    "frame": 33000,
    "sources": sourcesPayload,
    "multiGraphDomainStatisticGroupId": options.statisticsGroup,
    "multiGraphLayerUnboundedStatistics": true,
    ...statistics,
    ...BIKE_WALK_SPEED_PENALTIES[options.travel.travelType]
  }
}

export class StatefulMultigraphClient {
  constructor(
    private client: TargomoClient,
  ) {
  }

  async overfiew(hash: string): Promise<MultigraphOverview> {
    const url = new UrlUtil.TargomoUrl(this.client)
      .host(this.client.config.statisticsUrl)
      .part('multigraph/' + hash)
      .key()
      .params({ serviceUrl: this.client.serviceUrl })
      .toString()

    return await requests(this.client).fetch(url, 'GET')
  }

  async requestHash(
    sources: LatLngId[],
    options: {
      travel: TravelTypeEdgeWeightOptions,
      statisticsGroup: number,
      statisticsId: number,
    }
  ): Promise<string> {
    if (!sources || sources.length === 0) {
      return null
    }

    const client = this.client

    const payload = createMultigraphPayload(client, sources, options)
    const cacheKey = JSON.stringify(payload)

    return CACHE.get(cacheKey, async () => {
      const url = new UrlUtil.TargomoUrl(client)
      .host(client.config.statisticsUrl)
      .part('multigraph')
      .key()
      .params({ serviceUrl: client.serviceUrl })
      .toString()
    
      const hash = await requests(client).fetchCachedData(
        true,
        url,
        'POST-RAW',
        JSON.stringify(payload),
        { Accept: 'text/plain' }
      )

      return hash      
    })
  }
}

//   const url = new UrlUtil.TargomoUrl(client)
//   .host(client.config.statisticsUrl)
//   .part('multigraph')
//   .key()
//   .params({ serviceUrl: client.serviceUrl })
//   .toString()


// const result = await requests(client).fetchCachedData(
//   true,
//   url,
//   'POST-RAW',
//   JSON.stringify(payload),
//   { Accept: 'text/plain' }
// )
// return result  

  // const client = await new TargomoClient(body?.serviceUrl, body?.serviceKey)

  // let hash: string = body.hash // support presupplied hash

  // if (!hash) {
  //   if (body.raw) {
  //     const url = new UrlUtil.TargomoUrl(client)
  //       .host(client.config.statisticsUrl)
  //       .part('multigraph')
  //       .key()
  //       .params({ serviceUrl: client.serviceUrl })
  //       .toString()

  //     if (body.sources) {
  //       body.sources = <any>body.sources.map((source) => {
  //         return {
  //           id: source.id,
  //           x: source.lng ?? (<any>source).x,
  //           y: source.lat ?? (<any>source).y,
  //         }
  //       })
  //     }

  //     hash = await new core.RequestsUtil().fetchCachedData(
  //       false,
  //       url,
  //       'POST-RAW',
  //       JSON.stringify(body),
  //       { Accept: 'text/plain' }
  //     )
  //   } else {
  //     hash = await client.statefulMultigraph.create(body.sources, body)
  //   }
  // }

  // const infoResult = await pollUntil(
  //   5000,
  //   async () => {
  //     const info = await client.statefulMultigraph.info(hash)
  //     if (info.status === 'COMPLETED') {
  //       return info
  //     } else if (info.status === 'FAILED') {
  //       return info
  //     } else {
  //       return undefined
  //     }
  //   },
  //   10
  // )

  // const tiles = client.statefulMultigraph.getTiledMultigraphUrl(hash, 'mvt')

  // return {
  //   id: hash,
  //   hash: hash,
  //   info: infoResult,
  //   tiles,
  //   zoom: async (zoomLevel: number) => {
  //     const ensembles = new Ensembles(await client.statistics.ensembles())
  //     const statisticsGroup = body.raw ? (<any>body).multiGraphDomainStatisticGroupId : body?.multigraph?.domain?.statisticGroupId
  //     const adjustedStatisticsGroup = await ensembles.findStatisticsGroupForZoom(+statisticsGroup, zoomLevel)
  //     if (adjustedStatisticsGroup !== statisticsGroup) {
  //       if (body.raw) {
  //         (<any>body).multiGraphDomainStatisticGroupId = adjustedStatisticsGroup
  //       } else {
  //         body.multigraph.domain.statisticGroupId = adjustedStatisticsGroup
  //       }

  //       return await (await requestMultigraph(<any>body)).tiles
  //     }

  //     return tiles
  //   },
  // }
// }
