import { Component, NgZone } from '@angular/core';
import { DataSet, UserEndpoint } from "../../../api/index";
import {TgmQuickDialogs} from '@targomo/client';
import {Router, ActivatedRoute} from '@angular/router';
import { ZoneLayersEndpoint } from '../../../api/sectors';
import { DataSetLocation } from '../../../model';
import { APP_BASE_PATH } from '../../../util/basePath';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import {files as filesUtil} from '@targomo/client'
import * as importLayer from '../../../../common/util/importLayer'
import { PlanningApplicationDataSetEndpoint } from '../../../api/planningApplicationDataSet';
import { PLANNING_APPLICATION_DEFINITIONS } from '../../../../common/util/columns';
import { PlanningApplicationDataSet } from '../../../../common/models';

export function waitTimeout(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export const DATA_SET_UPLOAD_FIELDS  = [
  'lat',
  'lng',
  'lat_alt',
  'lng_alt',
  'store_id',
  'primary_category',
  'secondary_category',
  'holding_company',
  'fascia',
  'sitename',
  'paon',
  'saon',
  'taon',
  'street',
  'suburb',
  'town',
  'postcode',
  'district',
  'county',
  'region',
  'net_sales_area',
  'net_sales_area_source',
  'gross_internal_area',
  'gross_internal_area_source',

  'developer',
  'stage',
  'planning_app',
  'link_to_planning',
  'app_date',
  'decision_date',
  'open_date',
  'latest_comment',
  'date_checked', 
]

@Component({
  selector: 'admin-planning-application-upload',
  templateUrl: '../uploadDataSet.html'
})
export class AdminPlanningApplicationUploadComponent {
  locations: DataSetLocation[]
  locationError: string = null
  locationCategorizeError: string = null

  basePath = APP_BASE_PATH
  saving: Promise<{}> | boolean
  set: PlanningApplicationDataSet = null
  form: FormGroup
  isSaving: boolean

  locationsFound = false
  locationsLength = 0

  private holdingCompanyCount = 0
  private secondaryCategoryCount = 0
  private skipClientValidation = false

  showCategorizeField = false
  showTemplates = false
  availableDataFields: string[] = DATA_SET_UPLOAD_FIELDS
  requiredDataFields: any = {
    'lat' : true,
    'lng' : true,
    'fascia' : true,
    'open_date' : true
  }

  submitted = false
  private files: any


  constructor(private formBuilder: FormBuilder, private usersEndpoint: UserEndpoint, 
              private dialog: MatDialog, 
              private zone: NgZone,
              private dataSetEndpoint: PlanningApplicationDataSetEndpoint,
              private quickDialogs: TgmQuickDialogs, private route: ActivatedRoute, private router: Router) {
    this.form = this.formBuilder.group({
      'name': [null, []],
      'categorize': [null, []],
    })

    this.init()
  }

  async init() {
    const params = this.route.snapshot.params
    const id = params.id

    if (id) {
      this.set = await this.dataSetEndpoint.get({id})
    }

    this.form = this.formBuilder.group({
      'name': [this.set ? this.set.name : null, [Validators.required]],
      'categorize': [id == 1 ? 'holding_company' : 'secondary_category', [Validators.required]],
    })
  }

  async save() {
    this.submitted = true
    this.locationError = null

    if (this.form.valid) {
      this.isSaving = true
      try {
        const result = await (this.saving = this.dataSetEndpoint.uploadMultipart(this.files, this.set || {name: this.form.value.name}))
        this.router.navigate(['/admin/planningApplications/' + result])
      } catch (e) {
        this.saving = null
        this.isSaving = false

        if (e && e.error && e.error.message) {
          this.locationError = e.error.message
        } else {
          this.locationError = 'Upload unsucessful'
        }
      }
    }
  }

  async selectFile(files: File[]) {
    this.files = files

    if (!files || !files.length) {
      this.locations = null
      return
    }

    if (this.skipClientValidation) {
      this.locationsFound = true
      return
    }

    this.locationError = null
    try {
      this.locations = null
      let total = 0

      this.holdingCompanyCount = 0
      this.secondaryCategoryCount = 0

      const result = <string>await filesUtil.readFile(files[0])
      this.zone.run(async () => {
        this.saving = true
      })
      
      await waitTimeout(100)
      
      try {
        await new Promise((resolve, reject) => {
          this.zone.runOutsideAngular(async () =>  {
            try {
              let first = true
              await importLayer.parse(result, async (items, columns, translatedColumns) => {
                if (first) {
                  first = false

                  if (translatedColumns.indexOf('fascia') == -1) {
                    throw new Error('Required column `fascia` missing from file')
                  }

                  if (translatedColumns.indexOf('openDate') == -1) {
                    throw new Error('Required column `open_date` or `openDate` missing from file')
                  }                
                }

                total += items.length

                items.forEach(item => {
                  if (!item.fascia) {
                    throw new Error('Location missing required value for column `fascia`')
                  }

                  if (item['holdingCompany']) {
                    this.holdingCompanyCount++
                  }

                  if (item['secondaryCategory']) {
                    this.secondaryCategoryCount++
                  }
                })
                
              }, 10000, PLANNING_APPLICATION_DEFINITIONS)

              await waitTimeout(20)
              resolve()
            } catch (e) {
              reject(e)
            }
          })
        }) 
      } finally {
        this.zone.run(async () => {
          this.saving = false
        })  
      }

      await waitTimeout(200)
      
      this.locationsLength = total//this.locations.length
      this.locationsFound = true

      await waitTimeout(100)

    } catch (e) {
      this.locationError = e.message || 'Layer data not valid'
    }
  }


  cancel() {
    if (this.set)
      this.router.navigate(['/admin/planningApplications/' + this.set.id])
    else
      this.router.navigate(['/admin/planningApplications'])
  }
}