

<div fxLayout="row">
    <h2 fxFlex>User Activity Report <span *ngIf="fromDateLoaded && toDateLoaded">: {{fromDateLoaded | date: 'mediumDate'}} -  {{toDateLoaded | date: 'mediumDate'}}</span></h2>

    <div class="secondary" [hidden]="!allowDownload">
      <button (click)="download()" mat-button class="mat-primary" ><mat-icon class="arrow-icon" mat-font-set="material-icons">file_download</mat-icon> Download</button>
    </div>
  </div>


<div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center">

  <mat-form-field fxFlex>
    <input matInput [(ngModel)]="fromDate" [matDatepicker]="pickerFrom" placeholder="From date">
    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
    <mat-datepicker #pickerFrom></mat-datepicker>
  </mat-form-field>

  <mat-form-field fxFlex>
    <input matInput [(ngModel)]="toDate" [matDatepicker]="pickerTo" placeholder="To date">
    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
    <mat-datepicker #pickerTo></mat-datepicker>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="projectNumber">Per Project Number</mat-checkbox>
  
  <div fxLayout="column" fxLayoutAlign="center center">
    <button mat-flat-button color="primary" (click)="load()"><mat-icon class="arrow-icon" mat-font-set="material-icons">refresh</mat-icon> Load</button>
  </div>
</div>

<tgm-loadable [model]="loading">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

    <!-- ID Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Name </th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="email"> Email </th>
      <td mat-cell *matCellDef="let row"> {{row.email}} </td>
    </ng-container>

    <ng-container matColumnDef="projectNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="projectNumber"> Project Code </th>
        <td mat-cell *matCellDef="let row"> {{row.projectNumber}} </td>
      </ng-container>
  
    <ng-container matColumnDef="countLogin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="countLogin"> Logins </th>
      <td mat-cell *matCellDef="let row"> {{row.countLogin | number}} </td>
    </ng-container>

    <ng-container matColumnDef="timeSession">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="timeSession"> Minutes logged in </th>
      <td mat-cell *matCellDef="let row"> {{((row.timeSession || 0) / 60000) | number: '1.0-0'}} </td>
    </ng-container>

    
    <ng-container matColumnDef="countDataLayer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="countDataLayer"> Data Layers </th>
      <td mat-cell *matCellDef="let row"> {{row.countDataLayer | number}} </td>
    </ng-container>
      
    <ng-container matColumnDef="countTravelTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="countTravelTime"> Travel Times </th>
      <td mat-cell *matCellDef="let row"> {{row.countTravelTime | number}} </td>
    </ng-container>

    <ng-container matColumnDef="countMapCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="countMapCreated"> Maps Created </th>
      <td mat-cell *matCellDef="let row"> {{row.countMapCreated | number}} </td>
    </ng-container>
      
    <ng-container matColumnDef="countMaxiReports">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="countMaxiReports"> Maxi Reports </th>
      <td mat-cell *matCellDef="let row"> {{row.countMaxiReports | number}} </td>
    </ng-container>
            
    <ng-container matColumnDef="countPdfDownloads">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="countPdfDownloads"> Pdf Downloads </th>
      <td mat-cell *matCellDef="let row"> {{row.countPdfDownloads | number}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</tgm-loadable>

<mat-paginator [length]="dataLength" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>