import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialogRef } from '@angular/material'

@Component({
  selector: 'new-road-label-dialog',
  templateUrl: './newRoadLabelDialog.component.html',
  styleUrls: ['./newRoadLabelDialog.component.less'],
})
export class NewRoadLabelDialogComponent implements OnInit {
  form: FormGroup
  logo: string

  constructor(
    public dialogRef: MatDialogRef<NewRoadLabelDialogComponent>,
    private formBuilder: FormBuilder // private userEndpoint: UserEndpoint, // @Optional() @Inject(MAT_DIALOG_DATA) data: string
  ) {
    this.form = this.formBuilder.group({
      label: ['', []],
    })
  }

  async ngOnInit() {
    // const user = await this.userEndpoint.me()
    // this.logo = APP_BASE_PATH + `api/users/${user.id}/companypicture/public`
  }

  save() {
    this.dialogRef.close(this.form.value.label)
  }
}
