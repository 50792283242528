import { Component } from '@angular/core';
import { DataSet } from "../../../api/index";
import {Auth} from '@targomo/client';
import {DataSetEndpoint} from '../../../api/dataSet';
import {Router, ActivatedRoute} from '@angular/router';
import { ZoneLayersEndpoint } from '../../../api/sectors';

@Component({
  selector: 'admin-zone-layers-list',
  templateUrl: './adminZoneLayersList.html'
})
export class AdminZoneLayersListComponent {
  dataSets: DataSet[]

  constructor(private datasetEndpoint: ZoneLayersEndpoint, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.updateList()
  }

  async updateList() {
    this.dataSets = await this.datasetEndpoint.findAll()
  }

  showMenu(event: any) {
    event.stopPropagation()
  }
}
