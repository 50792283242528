  <mat-list>
     <mat-list-item>
      <div fxFlex translate="settings.mapStyle">Map style</div>

      <mat-select style="width: 110px" [ngModel]="appModel.settings.mapStyleUpdates | async" (ngModelChange)="changeMapStyle($event)">
        <mat-option *ngFor="let option of mapStyleOptions" [value]="option.key">{{option.name}}</mat-option>
      </mat-select>
    </mat-list-item>

    <mat-list-item>
      <div fxFlex translate="settings.mapStyle">Travel times colours</div>

      <mat-select style="width: 110px" [ngModel]="selectedTravelColors" (ngModelChange)="changeMapColorRange($event)">
        <mat-option *ngFor="let option of travelColors" [value]="option">{{option.name}}</mat-option>
      </mat-select>
    </mat-list-item>

    <div style="text-align: center; margin-bottom: 6px"><button (click)="editCustomTravelColors()" mat-button>Edit custom travel colours</button></div>

    <mat-list-item *ngIf="permissions.extendedTravel">
      <div fxFlex translate="settings.mapStyle">Travel times range</div>

      <mat-select style="width: 110px" [ngModel]="selectedTravelRange" (ngModelChange)="changeTravelRange($event)">
        <mat-option *ngFor="let option of travelRanges" [value]="option">{{option.name}}</mat-option>
      </mat-select>
    </mat-list-item>

    <mat-list-item>
      <div fxFlex>Use regional indices</div>

      <mat-slide-toggle [ngModel]="appModel.settings.displaySettings.getValue().regonalIndices" (ngModelChange)="changeIndices($event)">
      </mat-slide-toggle>
    </mat-list-item>

    <mat-list-item *ngIf="permissions && permissions.thematicPedestrian">
      <div fxFlex>Show pedestrian thematic layer</div>

      <mat-slide-toggle [ngModel]="appModel.settings.displaySettings.getValue().pedestrianLayer" (ngModelChange)="changePedestrianLayer($event)">
      </mat-slide-toggle>
    </mat-list-item>

    <mat-list-item *ngIf="permissions && permissions.thematicWorkforce">
      <div fxFlex>Show workforce thematic layer</div>

      <mat-slide-toggle [ngModel]="appModel.settings.displaySettings.getValue().workforceLayer" (ngModelChange)="changeWorkforceLayer($event)">
      </mat-slide-toggle>
    </mat-list-item>

    <mat-list-item>
      <div fxFlex>Show/Hide all labels</div>

      <mat-slide-toggle [ngModel]="(appModel.settings.displaySettings | async).showLabels" (ngModelChange)="changeShowLabels($event)">
      </mat-slide-toggle>
    </mat-list-item>

<!--
    <mat-list-item>
      <div fxFlex>Show postal sectors</div>

      <mat-slide-toggle [ngModel]="(appModel.settings.displaySettings | async).showSectors" (ngModelChange)="changeShowSectors($event)">
      </mat-slide-toggle>
    </mat-list-item> -->
    <!-- <mat-list-item>
        <div fxFlex>Show travel times as polygons</div>

        <mat-slide-toggle [ngModel]="appModel.settings.displaySettings.getValue().isochrones" (ngModelChange)="changeIsochrones($event)">
        </mat-slide-toggle>
      </mat-list-item> -->

      <mat-list-item >
        <div fxFlex translate="settings.mapStyle">Travel and thematic display</div>

        <mat-select [disabled]="disabledTravelDisplay" [disabled]="appModel.settings.exclusiveTravelUpdates | async" style="width: 110px" [ngModel]="(appModel.settings.displaySettings | async).travelDisplayMode" (ngModelChange)="changeTravelDisplayMode($event)">
          <mat-option *ngFor="let option of travelDisplayModeOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-list-item>

      <mat-list-item *ngIf="permissions && permissions.intersectionMode">
        <div fxFlex translate="settings.mapStyle">Travel-time overlaps</div>

        <mat-select [disabled]="disabledIntersection" style="width: 110px" [ngModel]="appModel.settings.intersectionExclusiveModeUpdates | async" (ngModelChange)="changeIntersectionMode($event)">
          <mat-option *ngFor="let option of intersectionModelValues" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-list-item>

      <mat-list-item>
        <div fxFlex>Point colours</div>

        <mat-select style="width: 110px" [ngModel]="(appModel.settings.displaySettings | async).showCustomCategoryColors" (ngModelChange)="changeShowCustomCategoryColors($event)">
          <mat-option [value]="false">Default colours</mat-option>
          <mat-option [value]="true">Custom colours</mat-option>
        </mat-select>

        <!-- <mat-slide-toggle [ngModel]="(appModel.settings.displaySettings | async).showCustomCategoryColors" (ngModelChange)="changeShowCustomCategoryColors($event)">
        </mat-slide-toggle> -->
      </mat-list-item>


      <mat-list-item *ngIf="permissions && permissions.locationsInView">
        <div fxFlex >Locations Present/Not Present</div>

        <mat-select style="width: 110px" [ngModel]="(appModel.settings.gapReportLocationsUpdates | async)" (ngModelChange)="changeGapReportLocationsUpdates($event)">
          <mat-option *ngFor="let option of gapReportOptions" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
      </mat-list-item>

      <zone-layer-switch *ngIf="permissions && permissions.zones" label="true"></zone-layer-switch>

      <select-places-label [ngModel]="appModel.settings.labelsConfigUpdates | async" (ngModelChange)="changeLabelsConfig($event)"></select-places-label>
  </mat-list>
