<back-button></back-button>
<section style="padding: 0px 16px">
  <h3>Load saved sessions</h3>

  <mat-button-toggle-group #group="matButtonToggleGroup" [value]="view" (change)="changeTab($event.value)">
    <mat-button-toggle value="mine"> Own </mat-button-toggle>

    <mat-button-toggle value="shared"> Shared with me </mat-button-toggle>

    <mat-button-toggle value="sharing"> Sharing with others </mat-button-toggle>
  </mat-button-toggle-group>

  <tgm-loadable [model]="loadingProjects">
    <div class="empty-message" *ngIf="projects?.length == 0">No sessions</div>
    <mat-list>
      <mat-list-item class="region" *ngFor="let project of projects">
        <div class="list-item-full-center" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex>
            <div>{{ project.name }}</div>
            <ng-container [ngSwitch]="view">
              <div *ngSwitchCase="'sharing'" class="created-date">
                {{ project.shareEmail }} on {{ project.shareDate | date: 'yyyy-MM-dd' }}
              </div>

              <div *ngSwitchCase="'shared'" class="created-date">
                by {{ project.ownerName }} on {{ project.shareDate | date: 'yyyy-MM-dd' }}
              </div>

              <div *ngSwitchDefault class="created-date">{{ project.created | date: 'yyyy-MM-dd' }}</div>
            </ng-container>
          </div>

          <ng-container [ngSwitch]="view">
            <ng-container *ngSwitchCase="'sharing'">
              <button (click)="unshareProject(project)" title="Unshare project" mat-icon-button>
                <mat-icon class="mat-24">delete_sweep</mat-icon>
              </button>
            </ng-container>

            <ng-container *ngSwitchCase="'shared'"> </ng-container>

            <ng-container *ngSwitchDefault>
              <button (click)="shareProject(project)" title="Share project" mat-icon-button>
                <mat-icon class="mat-24">share</mat-icon>
              </button>
            </ng-container>
          </ng-container>

          <button
            [color]="project.id == defaultLoadSessionId ? 'primary' : null"
            (click)="setDefaultProject(project)"
            title="Set load by default"
            mat-icon-button
          >
            <mat-icon class="mat-24">star</mat-icon>
          </button>

          <button (click)="loadProject(project)" title="Load project" mat-icon-button>
            <mat-icon class="mat-24">file_download</mat-icon>
          </button>

          <ng-container [ngSwitch]="view">
            <ng-container *ngSwitchCase="'sharing'"> </ng-container>

            <ng-container *ngSwitchCase="'shared'">
              <button (click)="selfUnshareProject(project)" title="Unshare project" mat-icon-button>
                <mat-icon class="mat-24">delete_sweep</mat-icon>
              </button>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <button (click)="deleteProject(project)" title="Delete project" mat-icon-button>
                <mat-icon class="mat-24">delete</mat-icon>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </mat-list-item>
    </mat-list>
  </tgm-loadable>
</section>
