<div style="margin: 24px; margin-right: 12px">
  <div class="year-scale" fxLayout="row">
    <div fxFlex="8.333%" *ngFor="let yearLabel of labelYears">{{yearLabel}}</div>
  </div>

  <div style="margin-right: 12px">
    <mat-slider
      color="primary"
      class="slider-above"
        [max]="maxValue"
        [min]="minValue"
        [step]="1"
        [value]="year"
        (change)="onSliderChange($event)"
      >
  </mat-slider>
  </div>
</div>