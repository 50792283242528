import { Component } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { PostcodeAnalysisEndpoint } from '../../../api/postcodeAnalysis'
import { DataSetLocation } from '../../../model'
import { APP_BASE_PATH } from '../../../util/basePath'
import { ColorSelectDialog } from '../../../main/dialogs/colorSelectDialog/colorSelectDialog.component'
import { MatDialog } from '@angular/material'

export function waitTimeout(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

@Component({
  selector: 'admin-upload-postcodes',
  templateUrl: './uploadPostcodes.html',
})
export class AdminUploadPostcodesComponent {
  locations: DataSetLocation[]
  locationError: string = null
  locationSuccess: string = null
  locationCategorizeError: string = null

  basePath = APP_BASE_PATH
  saving: Promise<{}> | boolean
  form: FormGroup
  isSaving: boolean

  locationsFound = false
  locationsLength = 0

  submitted = false
  private files: any
  colors: { name: string; color: string }[]

  constructor(
    private formBuilder: FormBuilder,
    private dataSetEndpoint: PostcodeAnalysisEndpoint,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.form = this.formBuilder.group({
      name: [null, []],
      categorize: [null, []],
    })

    this.init()
  }

  async init() {
    this.form = this.formBuilder.group({})
    await this.initColors()
  }

  async initColors() {
    const colors = await this.dataSetEndpoint.colors()
    this.colors = Object.keys(colors)
      .map((name) => {
        return { name, color: colors[name] }
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        } else {
          return 0
        }
      })
  }

  async save() {
    this.submitted = true
    this.locationError = null
    this.locationSuccess = null

    if (this.form.valid) {
      this.isSaving = true
      try {
        const result = await (this.saving = this.dataSetEndpoint.uploadMultipart(this.files))
        this.locationSuccess = 'Upload completed'
        this.saving = null
        this.isSaving = false

        await this.initColors()
      } catch (e) {
        this.saving = null
        this.isSaving = false

        if (e && e.error && e.error.message) {
          this.locationError = e.error.message
        } else {
          this.locationError = 'Upload unsucessful'
        }
      }
    }
  }

  async selectFile(files: File[]) {
    this.files = files
  }

  cancel() {
    this.router.navigate(['/admin/'])
  }

  async clickCategory(category: any, $event: any) {
    $event.preventDefault()

    const dialogRef = this.dialog.open(ColorSelectDialog, {
      disableClose: false,
      data: { value: { color: category.color } },
    })

    const result = await dialogRef.afterClosed().toPromise()
    if (result) {
      category.color = result.color
      await this.dataSetEndpoint.updateColor(category.name, category.color)
    }
  }
}
