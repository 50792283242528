import {BehaviorSubject} from 'rxjs/BehaviorSubject'

/**
 * Extends BehaviorSubject with some additional methods
 */
export class ExtendedBehaviorSubject<T> extends BehaviorSubject<T> {
  /**
   * Updates the observable by calling a function with the current value and using its result as the new observable value
   */
  public nextWithCurrent(callback: (current: T) => T) {
    const current = this.getValue()
    const updated = callback(current)
    this.next(updated === undefined ? current : updated)
  }

  /**
   *
   */
  public nextPropertyWithCurrent<U>(property: keyof T, callback: (current: U) => U) {
    const currentObject: any = this.getValue()
    const current: U = currentObject[property]
    const updated = callback(current)

    if (updated !== undefined) {
      currentObject[property] = updated
      this.next(currentObject)
    }
  }

  /**
   * Updates a property of the current value if the new value is not undefined. Fires if the set value was different from the old one
   */
  public nextProperty<U>(property: keyof T, updated: U) {
    const currentObject: any = this.getValue()
    const current: U = currentObject[property]

    if (updated !== undefined && updated !== current) {
      currentObject[property] = updated
      this.next(currentObject)
    }
  }

  /**
   * Fire update with the current value
   */
  public touch() {
    this.next(this.getValue())
  }
}