import * as models from '../../common/models'
import {Injectable} from '@angular/core';
import {RESTHelper} from '@targomo/client';

export interface Company extends models.Company {
  dataProfiles?: number[]
  geoRegions?: number[]
  permissions?: any
}

@Injectable()
export class CompanyEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/companies')
  }

  get(user: {id: number}) {
    return this.find(user)
  }

  find(user: {id: number}) {
    return this.http.get<Company>(`/${user.id}`)
  }

  save(user: Company) {
    return this.update(user)
  }

  create(user: Company) {
    return this.http.post<{id: number}>(`/`, user)
  }

  update(user: Company) {
    return this.http.put(`/${user.id}`, user)
  }

  delete(user: Company) {
    return this.http.delete(`/${user.id}`)
  }

  findAll() {
    return this.http.get<Company[]>(`/`)
  }
}