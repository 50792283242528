export class TextMeasurer {
  private context: CanvasRenderingContext2D

  constructor(font: string) {
    let canvas = document.createElement('canvas')
    this.context = canvas.getContext('2d')
    this.context.font = font
  }
  
  measure(text: string) {
    const tm = this.context.measureText(String(text))
    return tm
  }

  width(text: string) {
    return this.measure(text).width
  }  
}