import { Observable } from 'rxjs'
import { shareReplay, map, debounceTime } from 'rxjs/operators'
import { Category } from '../api'
import { PlacesModel } from './placesModel'
import {object as objects} from '@targomo/common'

export type FasciasSelection = {[key: string]: {category: Category, count: number, some: boolean, all: boolean}}

export class FasciasSelectionModel {
  selected$: Observable<FasciasSelection>

  constructor(
    private places: PlacesModel,
  ) {

    this.selected$ = this.init()
  }

  private init() {
    return this.places.sources.observable.pipe(
      debounceTime(10),
      map((sources) => {

        if (sources && sources.length > 0) {
          const fascias = sources.reduce((acc, cur) => {
            if (acc[cur.category.id]) {
              acc[cur.category.id].count++
            } else {
              acc[cur.category.id] = {category: cur.category, count: 1}
            }
            return acc
          }, {} as {[key: string]: {category: Category, count: number}})

          objects.values(fascias).forEach(item => {
            if (item.category) {
              if (item.count === item.category.placesCount) {
                item.all = true
              } 
              
              if (item.count > 0) {
                item.some = true
              }
            }
          })

          return fascias
        }
        
        return {} as any
      }),
      shareReplay(1)
    ) 
  }
}