<form class="form" (submit)="save()" novalidate [formGroup]="form" >
  <h3 class="mat-title">Upload Geo Region</h3>

  <section fxLayout="column">
      <mat-form-field fxFlex style="width:100%">
        <input [readonly]="!!set" matInput type="text" formControlName="name" required placeholder="Geo Region name">
  
        <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
          <span translate="layers.layerNameRequired">Region name required</span>
        </mat-hint>
      </mat-form-field>

      <tgm-file-uploader placeholder="Upload GeoJSON geometry" (selected)="selectFile($event)"></tgm-file-uploader>

      <mat-hint *ngIf="locationError" [ngStyle]="{'color': 'red'}" style="font-size: 75%">
        <span>{{locationError}}</span>
      </mat-hint>      
  </section>


  <div class="align-right" style="margin-top: 16px">
    <div style="margin: 8px; font-size: 13px; text-align: center" *ngIf="saving">Please wait...</div>

    <div style="margin-bottom: 6px"><tgm-saveable *ngIf="saving" [model]="saving"></tgm-saveable></div>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button [disabled]="isSaving" mat-flat-button color="primary" type="submit">Save</button>
  </div>

  <expandable-panel class="help-panel" [expanded]="true" text="Help">
    <div>
      <div style="margin-bottom: 20px; line-height: 1.4em">
        <p>You can upload a GeoJSON file with geometry coordinates in WGS84 (EPSG:4326). </p>
      </div>
    </div>
  </expandable-panel>  
</form>
