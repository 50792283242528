import { Component, OnInit } from '@angular/core'
import { GapReportLocations, SettingsModel } from '../../../model/settingsModel'
import { OptionsDialogComponent } from '../optionsDialog/optionsDialog.component'

const GAP_REPORT_LOCATIONS_OPTIONS = [
  { name: 'Map Window', value: GapReportLocations.WITHIN_MAP_WINDOW },
  { name: 'Reachable Area', value: GapReportLocations.WITHIN_REACHABLE_AREA },
]

@Component({
  selector: 'locations-present-options-panel',
  templateUrl: './locationsPresentOptionsPanel.component.html',
  styleUrls: ['./locationsPresentOptionsPanel.component.less'],
})
export class LocationsPresentOptionsPanelComponent {
  gapReportOptions = GAP_REPORT_LOCATIONS_OPTIONS

  constructor(readonly settings: SettingsModel, readonly parent: OptionsDialogComponent) {}

  changeGapReportLocationsUpdates(value: any) {
    this.settings.displaySettings.nextProperty('gapReportLocations', value)
  }
}
