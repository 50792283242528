import {Component, EventEmitter, Output, Input, NgZone} from '@angular/core'
import {TgmQuickDialogs} from '@targomo/client'
import {Validators, FormGroup, FormBuilder} from '@angular/forms'
import { SavedSessionEndpoint } from '../../../api/savedSession';
import { SavedSessionModel } from '../../../model/savedSessionModel';
import { Router } from '@angular/router';
import { HomeComponent } from '../../../main/home.component';
import { ZoneSelection } from '../../../api/sectors';
import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'save-zone-layer-panel',
  templateUrl: './saveZoneLayer.component.html',
  styleUrls: ['./saveZoneLayer.component.less'],
})
export class SaveZoneLayerComponent implements OnInit {
  form: FormGroup
  submitted = false

  @Input() input: ZoneSelection = <any>{}
  @Output() output = new EventEmitter<ZoneSelection>()

  constructor(
    private formBuilder: FormBuilder,
    private home: HomeComponent,
    private router: Router,
    private savedSessionsEndpoint: SavedSessionEndpoint,
    private savedSessionsModel: SavedSessionModel,
    private quickDialogs: TgmQuickDialogs,
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      'name': [this.input && this.input.name || '', [Validators.required]],
    })
  }

  async cancel() {
    this.output.next(null)
  }

  async submit() {
    this.submitted = true
    
    if (this.form.valid) {
      // const sector = new ZoneSelection()
      this.input.name = this.form.value.name
      this.output.next(this.input)
    }
  }
}
