<section fxLayout="column" layout-align="center center" >
  <div>
    <h1 class="mat-title">Reset Password</h1>

    <div *ngIf="!!requestSent">
      Your request has been received. Please check your email for further steps.
    </div>

    <div *ngIf="!requestSent">
      <form [formGroup]="form" (ngSubmit)="submit(form, $event)" fxLayout="column" novalidate>
        <mat-form-field>
          <input placeholder="Your Login Email" matInput type="email" formControlName="email">

          <mat-hint *ngIf="form.submitted && form.get('email').hasError('required')">
            Email is required
          </mat-hint>
        </mat-form-field>

        <div class="align-right main-button">
          <div style="margin-bottom: 6px"><tgm-saveable [model]="saving"></tgm-saveable></div>
          <button mat-flat-button color="primary" type="submit">Send Reset</button>
        </div>
      </form>
    </div>
  </div>
</section>
