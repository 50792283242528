
import {AppModel} from '../../model/appModel.service'
import {Indicators} from '@targomo/client'
import { Component, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import {MaxiReportDialog} from '../maxi/maxiReportDialog.component'
import {MatDialog} from '@angular/material';
import { Auth } from '@targomo/client';
import { SubscriberComponent } from "@targomo/client";
import { UserEventLogEndpoint } from '../../api/userEventLog';
import { HomeComponent } from '../home.component';

@Component({
  selector: 'sidetabs',
  templateUrl: './sideTabs.html',
  styleUrls: ['./sideTabs.less']
})
export class SidetabsComponent extends SubscriberComponent {
  @Input() currentTab: number = 0
  @Output() uploadLayer = new EventEmitter<boolean>()

  hasSources: boolean = false
  permissions: any
  visibleAlsoAvailable = true

  constructor(
    private indicators: Indicators, 
    readonly appModel: AppModel, 
    public dialog: MatDialog, 
    private auth: Auth,
    private userEventLogEndpoint: UserEventLogEndpoint,
    private homeComponent: HomeComponent
  ) {
    super()
    this.initPermisions()
  }

  private async initPermisions() {
    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
    }
  }
  
  ngOnInit() {
    this.with(this.appModel.places.sources.observable.subscribe(sources => this.hasSources = sources.length > 0))


    this.watch(this.appModel.places.categorySearchText, search => {
      if (search) {
        this.alsoAvailable(true)
      }
    })
  }

  showMaxiReport() {
    const dialogRef = this.dialog.open(MaxiReportDialog, {
      disableClose: false,
      width: '100vw',
      height: '100vh',
      minWidth: '100wv',
      maxWidth: '100wv',
      panelClass: 'maxi-dialog-container-marker-class',
      data: {
        exportMapReportImage: this.homeComponent.exportMapReportImage
      }
    })

    this.userEventLogEndpoint.maxiReportViewed()

  }

  clearSources() {
    this.appModel.places.sources.clear()
  }

  uploadCustomLayer() {
    this.uploadLayer.emit(true)
  }

  alsoAvailable(value: boolean) {    
    this.visibleAlsoAvailable = value
  }
}