<h3 class="mat-title">Symbol Size Represents:</h3>

<mat-list>
  <mat-list-item *ngFor="let option of markerSizeValues" (click)="toggle(option)">
    <mat-checkbox style="pointer-events: none" [checked]="option.selected" aria-label="{{option.name}}"></mat-checkbox>
    <span>{{option.name}}</span>
  </mat-list-item>
</mat-list>

<h3 class="mat-title">Filter Symbols:</h3>

<tgm-rangeslider [margin]="0.001" [step]="0.01" [max]="maxValue" [(minValue)]="lowerValue" [(maxValue)]="upperValue" (minValueChange)="onSliderChange('lower')" (maxValueChange)="onSliderChange('upper')"></tgm-rangeslider>

<div fxLayout="row" style="width: 100%; padding: 0px 16px" fxLayoutGap="32px">
  <mat-form-field fxFlex="50%">
    <input matInput type="number" (ngModelChange)="onInputChange('lower')" [(ngModel)]="lowerActualValue" required placeholder="Min sq ft">
  </mat-form-field>

  <mat-form-field fxFlex="50%">
    <input matInput type="number" (ngModelChange)="onInputChange('upper')" [(ngModel)]="upperActualValue" required placeholder="Max sq ft">
  </mat-form-field>
</div>      


<ng-container *ngIf="permissions && permissions.dateAddedFilter">
  <h3 class="mat-title">Filter by Date Added:</h3>
  <filter-dates></filter-dates>
</ng-container>

<h3 class="mat-title">Symbol Sizes:</h3>

<table class="marker-legend">
  <tr *ngFor="let option of markerSizeLegend; let i=index">
    <td><span class="legend-panel-marker" [style.width]="'' + sizes[i] + 'px'" [style.height]="'' + sizes[i] + 'px'"></span></td>
    <td style="width: 100%"><span>{{option.name}}</span></td>
  </tr>
</table>