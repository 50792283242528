export const STATISTICS_EXTENDED = [
  {
    id: 525,
    topic: 47,
    name: 'id_525_Total_Gross_Income',
    label: 'Total spending power: Total gross income of all inhabitants',
  },
  {
    id: 526,
    topic: 47,
    name: 'id_526_Total_Net_Income',
    label: 'Total spending power: Total net income of all inhabitants',
  },
  {
    id: 527,
    topic: 47,
    name: 'id_527_Total_after_housing_costs',
    label: 'Total spending power: Total net income of all inhabitants after deducting housing costs',
  },
  { id: 528, topic: 44, name: 'id_528_100k_', label: 'Household income: Number of households with income ≥ 100k' },
  { id: 529, topic: 44, name: 'id_529_90_100k', label: 'Household income: Number of households with income 90–100k' },
  { id: 530, topic: 44, name: 'id_530_80_90k', label: 'Household income: Number of households with income 80–90k' },
  { id: 531, topic: 44, name: 'id_531_70_80k', label: 'Household income: Number of households with income 70–80k' },
  { id: 532, topic: 44, name: 'id_532_60_70k', label: 'Household income: Number of households with income 60–70k' },
  { id: 533, topic: 44, name: 'id_533_50_60k', label: 'Household income: Number of households with income 50–60k' },
  { id: 534, topic: 44, name: 'id_534_40_50k', label: 'Household income: Number of households with income 40–50k' },
  { id: 535, topic: 44, name: 'id_535_30_40k', label: 'Household income: Number of households with income 30–40k' },
  { id: 536, topic: 44, name: 'id_536_20_30k', label: 'Household income: Number of households with income 20–30k' },
  { id: 537, topic: 44, name: 'id_537__20k', label: 'Household income: Number of households with income ≤ 20k' },
  { id: 538, topic: 2, name: 'id_538_below_1_years', label: 'Population by age: Population less than 1 year' },
  { id: 539, topic: 2, name: 'id_539_1_year', label: 'Population by age: Population 1 year' },
  { id: 540, topic: 2, name: 'id_540_2_years', label: 'Population by age: Population 2 years' },
  { id: 541, topic: 2, name: 'id_541_3_years', label: 'Population by age: Population 3 years' },
  { id: 542, topic: 2, name: 'id_542_4_years', label: 'Population by age: Population 4 years' },
  { id: 543, topic: 2, name: 'id_543_5_years', label: 'Population by age: Population 5 years' },
  { id: 544, topic: 2, name: 'id_544_6_years', label: 'Population by age: Population 6 years' },
  { id: 545, topic: 2, name: 'id_545_7_years', label: 'Population by age: Population 7 years' },
  { id: 546, topic: 2, name: 'id_546_8_years', label: 'Population by age: Population 8 years' },
  { id: 547, topic: 2, name: 'id_547_9_years', label: 'Population by age: Population 9 years' },
  { id: 548, topic: 2, name: 'id_548_10_years', label: 'Population by age: Population 10 years' },
  { id: 549, topic: 2, name: 'id_549_11_years', label: 'Population by age: Population 11 years' },
  { id: 550, topic: 2, name: 'id_550_12_years', label: 'Population by age: Population 12 years' },
  { id: 551, topic: 2, name: 'id_551_13_years', label: 'Population by age: Population 13 years' },
  { id: 552, topic: 2, name: 'id_552_14_years', label: 'Population by age: Population 14 years' },
  { id: 553, topic: 2, name: 'id_553_15_years', label: 'Population by age: Population 15 years' },
  { id: 554, topic: 2, name: 'id_554_16_years', label: 'Population by age: Population 16 years' },
  { id: 555, topic: 2, name: 'id_555_17_years', label: 'Population by age: Population 17 years' },
  { id: 556, topic: 2, name: 'id_556_18_years', label: 'Population by age: Population 18 years' },
  { id: 557, topic: 2, name: 'id_557_19_years', label: 'Population by age: Population 19 years' },
  { id: 558, topic: 2, name: 'id_558_20_years', label: 'Population by age: Population 20 years' },
  { id: 559, topic: 2, name: 'id_559_21_years', label: 'Population by age: Population 21 years' },
  { id: 560, topic: 2, name: 'id_560_22_years', label: 'Population by age: Population 22 years' },
  { id: 561, topic: 2, name: 'id_561_23_years', label: 'Population by age: Population 23 years' },
  { id: 562, topic: 2, name: 'id_562_24_years', label: 'Population by age: Population 24 years' },
  { id: 563, topic: 2, name: 'id_563_25_years', label: 'Population by age: Population 25 years' },
  { id: 564, topic: 2, name: 'id_564_26_years', label: 'Population by age: Population 26 years' },
  { id: 565, topic: 2, name: 'id_565_27_years', label: 'Population by age: Population 27 years' },
  { id: 566, topic: 2, name: 'id_566_28_years', label: 'Population by age: Population 28 years' },
  { id: 567, topic: 2, name: 'id_567_29_years', label: 'Population by age: Population 29 years' },
  { id: 568, topic: 2, name: 'id_568_30_years', label: 'Population by age: Population 30 years' },
  { id: 569, topic: 2, name: 'id_569_31_years', label: 'Population by age: Population 31 years' },
  { id: 570, topic: 2, name: 'id_570_32_years', label: 'Population by age: Population 32 years' },
  { id: 571, topic: 2, name: 'id_571_33_years', label: 'Population by age: Population 33 years' },
  { id: 572, topic: 2, name: 'id_572_34_years', label: 'Population by age: Population 34 years' },
  { id: 573, topic: 2, name: 'id_573_35_years', label: 'Population by age: Population 35 years' },
  { id: 574, topic: 2, name: 'id_574_36_years', label: 'Population by age: Population 36 years' },
  { id: 575, topic: 2, name: 'id_575_37_years', label: 'Population by age: Population 37 years' },
  { id: 576, topic: 2, name: 'id_576_38_years', label: 'Population by age: Population 38 years' },
  { id: 577, topic: 2, name: 'id_577_39_years', label: 'Population by age: Population 39 years' },
  { id: 578, topic: 2, name: 'id_578_40_years', label: 'Population by age: Population 40 years' },
  { id: 579, topic: 2, name: 'id_579_41_years', label: 'Population by age: Population 41 years' },
  { id: 580, topic: 2, name: 'id_580_42_years', label: 'Population by age: Population 42 years' },
  { id: 581, topic: 2, name: 'id_581_43_years', label: 'Population by age: Population 43 years' },
  { id: 582, topic: 2, name: 'id_582_44_years', label: 'Population by age: Population 44 years' },
  { id: 583, topic: 2, name: 'id_583_45_years', label: 'Population by age: Population 45 years' },
  { id: 584, topic: 2, name: 'id_584_46_years', label: 'Population by age: Population 46 years' },
  { id: 585, topic: 2, name: 'id_585_47_years', label: 'Population by age: Population 47 years' },
  { id: 586, topic: 2, name: 'id_586_48_years', label: 'Population by age: Population 48 years' },
  { id: 587, topic: 2, name: 'id_587_49_years', label: 'Population by age: Population 49 years' },
  { id: 588, topic: 2, name: 'id_588_50_years', label: 'Population by age: Population 50 years' },
  { id: 589, topic: 2, name: 'id_589_51_years', label: 'Population by age: Population 51 years' },
  { id: 590, topic: 2, name: 'id_590_52_years', label: 'Population by age: Population 52 years' },
  { id: 591, topic: 2, name: 'id_591_53_years', label: 'Population by age: Population 53 years' },
  { id: 592, topic: 2, name: 'id_592_54_years', label: 'Population by age: Population 54 years' },
  { id: 593, topic: 2, name: 'id_593_55_years', label: 'Population by age: Population 55 years' },
  { id: 594, topic: 2, name: 'id_594_56_years', label: 'Population by age: Population 56 years' },
  { id: 595, topic: 2, name: 'id_595_57_years', label: 'Population by age: Population 57 years' },
  { id: 596, topic: 2, name: 'id_596_58_years', label: 'Population by age: Population 58 years' },
  { id: 597, topic: 2, name: 'id_597_59_years', label: 'Population by age: Population 59 years' },
  { id: 598, topic: 2, name: 'id_598_60_years', label: 'Population by age: Population 60 years' },
  { id: 599, topic: 2, name: 'id_599_61_years', label: 'Population by age: Population 61 years' },
  { id: 600, topic: 2, name: 'id_600_62_years', label: 'Population by age: Population 62 years' },
  { id: 601, topic: 2, name: 'id_601_63_years', label: 'Population by age: Population 63 years' },
  { id: 602, topic: 2, name: 'id_602_64_years', label: 'Population by age: Population 64 years' },
  { id: 603, topic: 2, name: 'id_603_65_years', label: 'Population by age: Population 65 years' },
  { id: 604, topic: 2, name: 'id_604_66_years', label: 'Population by age: Population 66 years' },
  { id: 605, topic: 2, name: 'id_605_67_years', label: 'Population by age: Population 67 years' },
  { id: 606, topic: 2, name: 'id_606_68_years', label: 'Population by age: Population 68 years' },
  { id: 607, topic: 2, name: 'id_607_69_years', label: 'Population by age: Population 69 years' },
  { id: 608, topic: 2, name: 'id_608_70_years', label: 'Population by age: Population 70 years' },
  { id: 609, topic: 2, name: 'id_609_71_years', label: 'Population by age: Population 71 years' },
  { id: 610, topic: 2, name: 'id_610_72_years', label: 'Population by age: Population 72 years' },
  { id: 611, topic: 2, name: 'id_611_73_years', label: 'Population by age: Population 73 years' },
  { id: 612, topic: 2, name: 'id_612_74_years', label: 'Population by age: Population 74 years' },
  { id: 613, topic: 2, name: 'id_613_75_years', label: 'Population by age: Population 75 years' },
  { id: 614, topic: 2, name: 'id_614_76_years', label: 'Population by age: Population 76 years' },
  { id: 615, topic: 2, name: 'id_615_77_years', label: 'Population by age: Population 77 years' },
  { id: 616, topic: 2, name: 'id_616_78_years', label: 'Population by age: Population 78 years' },
  { id: 617, topic: 2, name: 'id_617_79_years', label: 'Population by age: Population 79 years' },
  { id: 618, topic: 2, name: 'id_618_80_years', label: 'Population by age: Population 80 years' },
  { id: 619, topic: 2, name: 'id_619_81_years', label: 'Population by age: Population 81 years' },
  { id: 620, topic: 2, name: 'id_620_82_years', label: 'Population by age: Population 82 years' },
  { id: 621, topic: 2, name: 'id_621_83_years', label: 'Population by age: Population 83 years' },
  { id: 622, topic: 2, name: 'id_622_84_years', label: 'Population by age: Population 84 years' },
  { id: 623, topic: 2, name: 'id_623_85_years', label: 'Population by age: Population 85 years' },
  { id: 624, topic: 2, name: 'id_624_86_years', label: 'Population by age: Population 86 years' },
  { id: 625, topic: 2, name: 'id_625_87_years', label: 'Population by age: Population 87 years' },
  { id: 626, topic: 2, name: 'id_626_88_years', label: 'Population by age: Population 88 years' },
  { id: 627, topic: 2, name: 'id_627_89_years', label: 'Population by age: Population 89 years' },
  { id: 628, topic: 2, name: 'id_628_90_years', label: 'Population by age: Population 90 years' },
  { id: 629, topic: 2, name: 'id_629_91_years', label: 'Population by age: Population 91 years' },
  { id: 630, topic: 2, name: 'id_630_92_years', label: 'Population by age: Population 92 years' },
  { id: 631, topic: 2, name: 'id_631_93_years', label: 'Population by age: Population 93 years' },
  { id: 632, topic: 2, name: 'id_632_94_years', label: 'Population by age: Population 94 years' },
  { id: 633, topic: 2, name: 'id_633_95_years', label: 'Population by age: Population 95 years' },
  { id: 634, topic: 2, name: 'id_634_96_years', label: 'Population by age: Population 96 years' },
  { id: 635, topic: 2, name: 'id_635_97_years', label: 'Population by age: Population 97 years' },
  { id: 636, topic: 2, name: 'id_636_98_years', label: 'Population by age: Population 98 years' },
  { id: 637, topic: 2, name: 'id_637_99_years', label: 'Population by age: Population 99 years' },
  { id: 638, topic: 2, name: 'id_638_100_years', label: 'Population by age: Population 100 years and older' },
  { id: 639, topic: 75, name: 'id_639_OAC_1a', label: 'Literacy: Farming Communities – Output Area Classification 1a' },
  { id: 640, topic: 75, name: 'id_640_OAC_1b', label: 'Literacy: Rural tenants – Output Area Classification 1b' },
  {
    id: 641,
    topic: 75,
    name: 'id_641_OAC_1c',
    label: 'Literacy: Ageing rural dwellers – Output Area Classification 1c',
  },
  {
    id: 642,
    topic: 75,
    name: 'id_642_OAC_2a',
    label: 'Literacy: Students around campus – Output Area Classification 2a',
  },
  { id: 643, topic: 75, name: 'id_643_OAC_2b', label: 'Literacy: Inner city students – Output Area Classification 2b' },
  {
    id: 644,
    topic: 75,
    name: 'id_644_OAC_2c',
    label: 'Literacy: Comfortable cosmopolitan – Output Area Classification 2c',
  },
  {
    id: 645,
    topic: 75,
    name: 'id_645_OAC_2d',
    label: 'Literacy: Aspiring and affluent – Output Area Classification 2d',
  },
  { id: 646, topic: 75, name: 'id_646_OAC_3a', label: 'Literacy: Ethnic family life – Output Area Classification 3a' },
  {
    id: 647,
    topic: 75,
    name: 'id_647_OAC_3b',
    label: 'Literacy: Endeavouring Ethnic Mix – Output Area Classification 3b',
  },
  { id: 648, topic: 75, name: 'id_648_OAC_3c', label: 'Literacy: Ethnic dynamics – Output Area Classification 3c' },
  {
    id: 649,
    topic: 75,
    name: 'id_649_OAC_3d',
    label: 'Literacy: Aspirational techies – Output Area Classification 3d',
  },
  {
    id: 650,
    topic: 75,
    name: 'id_650_OAC_4a',
    label: 'Literacy: Rented family living – Output Area Classification 4a',
  },
  {
    id: 651,
    topic: 75,
    name: 'id_651_OAC_4b',
    label: 'Literacy: Challenged Asian terraces – Output Area Classification 4b',
  },
  { id: 652, topic: 75, name: 'id_652_OAC_4c', label: 'Literacy: Asian traits – Output Area Classification 4c' },
  {
    id: 653,
    topic: 75,
    name: 'id_653_OAC_5a',
    label: 'Literacy: Urban professionals and families – Output Area Classification 5a',
  },
  { id: 654, topic: 75, name: 'id_654_OAC_5b', label: 'Literacy: Ageing urban living – Output Area Classification 5b' },
  { id: 655, topic: 75, name: 'id_655_OAC_6a', label: 'Literacy: Suburban achievers – Output Area Classification 6a' },
  {
    id: 656,
    topic: 75,
    name: 'id_656_OAC_6b',
    label: 'Literacy: Semi-detached suburbia – Output Area Classification 6b',
  },
  {
    id: 657,
    topic: 75,
    name: 'id_657_OAC_7a',
    label: 'Literacy: Challenged diversity – Output Area Classification 7a',
  },
  {
    id: 658,
    topic: 75,
    name: 'id_658_OAC_7b',
    label: 'Literacy: Constrained flat dwellers – Output Area Classification 7b',
  },
  { id: 659, topic: 75, name: 'id_659_OAC_7c', label: 'Literacy: White communities – Output Area Classification 7c' },
  {
    id: 660,
    topic: 75,
    name: 'id_660_OAC_7d',
    label: 'Literacy: Ageing city dwellers – Output Area Classification 7d',
  },
  {
    id: 661,
    topic: 75,
    name: 'id_661_OAC_8a',
    label: 'Literacy: Industrious communities – Output Area Classification 8a',
  },
  {
    id: 662,
    topic: 75,
    name: 'id_662_OAC_8b',
    label: 'Literacy: Challenged terraced workers – Output Area Classification 8b',
  },
  {
    id: 663,
    topic: 75,
    name: 'id_663_OAC_8c',
    label: 'Literacy: Hard pressed ageing workers – Output Area Classification 8c',
  },
  { id: 664, topic: 75, name: 'id_664_OAC_8d', label: 'Literacy: Migration and churn – Output Area Classification 8d' },
  { id: 665, topic: 13, name: 'id_665_A', label: 'Employment: A – Agriculture, forestry and fishing' },
  { id: 666, topic: 13, name: 'id_666_B', label: 'Employment: B – Mining and quarrying' },
  { id: 667, topic: 13, name: 'id_667_C', label: 'Employment: C – All manufacturing' },
  {
    id: 668,
    topic: 13,
    name: 'id_668_CA',
    label: 'Employment: CA – Manufacture of food products, beverages and tobacco products',
  },
  {
    id: 669,
    topic: 13,
    name: 'id_669_CB',
    label: 'Employment: CB – Manufacture of textiles, apparel, leather and related products',
  },
  {
    id: 670,
    topic: 13,
    name: 'id_670_CC',
    label: 'Employment: CC – Manufacture of wood and paper products, and printing',
  },
  {
    id: 671,
    topic: 13,
    name: 'id_671_CD_CG',
    label: 'Employment: CD–CG – Manufacture of chemicals, rubber and plastics products',
  },
  { id: 672, topic: 13, name: 'id_672_CH', label: 'Employment: CH – Low-tech manufacture' },
  { id: 673, topic: 13, name: 'id_673_CI_CL', label: 'Employment: CI–CL – High-tech manufacture' },
  { id: 674, topic: 13, name: 'id_674_CM', label: 'Employment: CM – Other manufacturing' },
  {
    id: 675,
    topic: 13,
    name: 'id_675_D',
    label: 'Employment: D – Electricity, gas, steam and air-conditioning supply',
  },
  {
    id: 676,
    topic: 13,
    name: 'id_676_E',
    label: 'Employment: E – Water supply, sewerage, waste management and remediation',
  },
  { id: 677, topic: 13, name: 'id_677_F', label: 'Employment: F – Construction' },
  {
    id: 678,
    topic: 13,
    name: 'id_678_G',
    label: 'Employment: G – Wholesale and retail trade, repair of motor vehicles and motorcycles',
  },
  { id: 679, topic: 13, name: 'id_679_H', label: 'Employment: H – Transportation and storage' },
  { id: 680, topic: 13, name: 'id_680_I', label: 'Employment: I – Accommodation and food service activities' },
  { id: 681, topic: 13, name: 'id_681_J', label: 'Employment: J – Information and communication' },
  { id: 682, topic: 13, name: 'id_682_K', label: 'Employment: K – Financial and insurance activities' },
  { id: 683, topic: 13, name: 'id_683_L', label: 'Employment: L – Real estate activities' },
  { id: 684, topic: 13, name: 'id_684_M', label: 'Employment: M – Professional, scientific and technical activities' },
  { id: 685, topic: 13, name: 'id_685_N', label: 'Employment: N – Administrative and support service activities' },
  {
    id: 686,
    topic: 13,
    name: 'id_686_O',
    label: 'Employment: O – Public administration and defence; compulsory social security',
  },
  { id: 687, topic: 13, name: 'id_687_P', label: 'Employment: P – Education' },
  { id: 688, topic: 13, name: 'id_688_Q', label: 'Employment: Q – Human health and social work activities' },
  {
    id: 689,
    topic: 13,
    name: 'id_689_RS',
    label: 'Employment: RS – Arts, entertainment and recreation; other service activities',
  },
  {
    id: 690,
    topic: 13,
    name: 'id_690_T',
    label:
      'Employment: T – Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use',
  },
  {
    id: 691,
    topic: 13,
    name: 'id_691_U',
    label: 'Employment: U – Activities of extraterritorial organisations and bodies',
  },
  { id: 692, topic: 9, name: 'id_692_No_qualifications', label: 'Education: No qualifications' },
  {
    id: 693,
    topic: 9,
    name: 'id_693_1_4_O_Levels_CSE_GCSE',
    label: 'Education: 1-4 O Levels, CSE, GCSEs (any grades), Entry Level',
  },
  {
    id: 694,
    topic: 9,
    name: 'id_694_NVQ_Lvl_1_Foundation_GNVQ_basic_skills',
    label: 'Education: Foundation Diploma, NVQ level 1, Foundation GNVQ, Basic/Essential Skills',
  },
  {
    id: 695,
    topic: 9,
    name: 'id_695_1_A_Level_2_3_AS_Levels_Welsh_Bacc_Interm_Diploma',
    label:
      'Education: 5+ O Level (Passes)/CSEs (Grade 1)/GCSEs (Grades A*-C), School Certificate, 1 A Level/ 2-3 AS Levels/VCEs, Intermediate/Higher Diploma, Welsh Baccalaureate Intermediate Diploma',
  },
  {
    id: 696,
    topic: 9,
    name: 'id_696_NVQ_Lvl_2_Interm_GNVQ_etc',
    label: 'Education: NVQ level 2, Intermediate GNVQ, City and Guilds Craft, BTEC First/General Diploma, RSA Diploma',
  },
  { id: 697, topic: 9, name: 'id_697_Apprenticeship', label: 'Education: Apprenticeship' },
  {
    id: 698,
    topic: 9,
    name: 'id_698_2_A_Levels_4_AS_Levels_etc',
    label:
      'Education: 2+ A Levels/VCEs, 4+ AS Levels, Higher School Certificate, Progression/Advanced Diploma, Welsh Baccalaureate Advanced Diploma',
  },
  {
    id: 699,
    topic: 9,
    name: 'id_699_NVQ_Lvl_3_Adv_GNVQ_etc',
    label:
      'Education: NVQ Level 3, Advanced GNVQ, City and Guilds Advanced Craft, ONC, OND, BTEC National, RSA Advanced Diploma',
  },
  {
    id: 700,
    topic: 9,
    name: 'id_700_Degree_BA_BSc_MA_PhD_PGCE',
    label: 'Education: Degree (for example BA, BSc), Higher Degree (for example MA, PhD, PGCE)',
  },
  {
    id: 701,
    topic: 9,
    name: 'id_701_NVQ_Lvl_4_5_HNC_HND_etc',
    label: 'Education: NVQ Level 4-5, HNC, HND, RSA Higher Diploma, BTEC Higher level',
  },
  {
    id: 702,
    topic: 9,
    name: 'id_702_Professional_qualifications',
    label: 'Education: Professional qualifications (for example teaching, nursing, accountancy)',
  },
  {
    id: 703,
    topic: 9,
    name: 'id_703_Vocational_work_related_quals',
    label: 'Education: Other vocational/work-related Qualifications',
  },
  { id: 704, topic: 9, name: 'id_704_Foreign_quals', label: 'Education: Foreign Qualifications' },
  {
    id: 705,
    topic: 62,
    name: 'id_705_Employed_part_time',
    label: 'Economic Activity: Economically Active: Employed part-time',
  },
  {
    id: 706,
    topic: 62,
    name: 'id_706_Employed_full_time',
    label: 'Economic Activity: Economically Active: Employed full-time',
  },
  {
    id: 707,
    topic: 62,
    name: 'id_707_Self_employed_part_time_with_employees',
    label: 'Economic Activity: Economically Active: Self-employed part-time with employees',
  },
  {
    id: 708,
    topic: 62,
    name: 'id_708_Self_employed_full_time_with_employees',
    label: 'Economic Activity: Economically Active: Self-employed full-time with employees',
  },
  {
    id: 709,
    topic: 62,
    name: 'id_709_Self_employed_part_time_no_employees',
    label: 'Economic Activity: Economically Active: Self-employed part-time no employees',
  },
  {
    id: 710,
    topic: 62,
    name: 'id_710_Self_employed_full_time_no_employees',
    label: 'Economic Activity: Economically Active: Self-employed full-time no employees',
  },
  { id: 711, topic: 62, name: 'id_711_Unemployed', label: 'Economic Activity: Economically Active: Unemployed' },
  {
    id: 712,
    topic: 62,
    name: 'id_712_Student_full_time',
    label: 'Economic Activity: Economically Active: Full-time student',
  },
  { id: 713, topic: 62, name: 'id_713_Retired', label: 'Economic Activity: Economically Inactive: Retired' },
  { id: 714, topic: 62, name: 'id_714_Student', label: 'Economic Activity: Economically Inactive: Student' },
  {
    id: 715,
    topic: 62,
    name: 'id_715_Caring_for_home_or_family',
    label: 'Economic Activity: Economically Inactive: Caring for home or family',
  },
  {
    id: 716,
    topic: 62,
    name: 'id_716_Long_term_sick',
    label: 'Economic Activity: Economically Inactive: Long-term sick',
  },
  { id: 717, topic: 62, name: 'id_717_Other_inactive', label: 'Economic Activity: Economically Inactive: Other' },
  { id: 718, topic: 36, name: 'id_718_1_person', label: 'Household size: Household size: 1 person' },
  { id: 719, topic: 36, name: 'id_719_2_persons', label: 'Household size: Household size: 2 persons' },
  { id: 720, topic: 36, name: 'id_720_3_persons', label: 'Household size: Household size: 3 persons' },
  { id: 721, topic: 36, name: 'id_721_4_persons', label: 'Household size: Household size: 4 persons' },
  { id: 722, topic: 36, name: 'id_722_5_persons', label: 'Household size: Household size: 5 persons' },
  { id: 723, topic: 36, name: 'id_723_6_persons', label: 'Household size: Household size: 6 persons' },
  { id: 724, topic: 36, name: 'id_724_7_persons', label: 'Household size: Household size: 7 persons' },
  { id: 725, topic: 36, name: 'id_725_8_persons', label: 'Household size: Household size: 8 or more persons' },
  { id: 726, topic: 76, name: 'id_726_Part_time_Total', label: 'Working Hours: Part-time: Total' },
  { id: 727, topic: 76, name: 'id_727_Part_time_15h', label: 'Working Hours: Part-time: 15 hours/week or less' },
  { id: 728, topic: 76, name: 'id_728_Part_time_16_30h', label: 'Working Hours: Part-time: 16 to 30 hours/week' },
  { id: 729, topic: 76, name: 'id_729_Full_time_Total', label: 'Working Hours: Full-time: Total' },
  { id: 730, topic: 76, name: 'id_730_Full_time_31_48h', label: 'Working Hours: Full-time: 31 to 48 hours/week' },
  { id: 731, topic: 76, name: 'id_731_Full_time_49_h', label: 'Working Hours: Full-time: 49 or more hours/week' },
  {
    id: 732,
    topic: 76,
    name: 'id_732_Full_time_Students_Employed',
    label: 'Working Hours: Full-time students: economically active, employed',
  },
  {
    id: 733,
    topic: 76,
    name: 'id_733_Full_time_Students_Unemployed',
    label: 'Working Hours: Full-time students: economically active, unemployed',
  },
  {
    id: 734,
    topic: 76,
    name: 'id_734_Full_time_Students_economically_inactive',
    label: 'Working Hours: Full-time students: economically inactive',
  },
  { id: 735, topic: 77, name: 'id_735_2_or_more', label: 'Occupancy Rating: Occupancy rating: 2+ or more' },
  { id: 736, topic: 77, name: 'id_736_1_', label: 'Occupancy Rating: Occupancy rating: 1+' },
  { id: 737, topic: 77, name: 'id_737_0', label: 'Occupancy Rating: Occupancy rating: 0' },
  { id: 738, topic: 77, name: 'id_738__1', label: 'Occupancy Rating: Occupancy rating: -1' },
  { id: 739, topic: 77, name: 'id_739__2_or_less', label: 'Occupancy Rating: Occupancy rating: -2 or less' },
  { id: 740, topic: 78, name: 'id_740_Day_to_day_limited_a_lot', label: 'Health: Day-to-day limited a lot' },
  { id: 741, topic: 78, name: 'id_741_Day_to_day_limited_a_little', label: 'Health: Day-to-day limited a little' },
  { id: 742, topic: 78, name: 'id_742_Day_to_day_not_limited', label: 'Health: Day-to-day not limited' },
  { id: 743, topic: 78, name: 'id_743_Very_good', label: 'Health: Very good' },
  { id: 744, topic: 78, name: 'id_744_Good', label: 'Health: Good' },
  { id: 745, topic: 78, name: 'id_745_Fair', label: 'Health: Fair' },
  { id: 746, topic: 78, name: 'id_746_Bad', label: 'Health: Bad' },
  { id: 747, topic: 78, name: 'id_747_Very_bad', label: 'Health: Very bad' },
  { id: 748, topic: 38, name: 'id_748_Christian', label: 'Religion: Christian' },
  { id: 749, topic: 38, name: 'id_749_Buddhist', label: 'Religion: Buddhist' },
  { id: 750, topic: 38, name: 'id_750_Hindu', label: 'Religion: Hindu' },
  { id: 751, topic: 38, name: 'id_751_Jewish', label: 'Religion: Jewish' },
  { id: 752, topic: 38, name: 'id_752_Muslim', label: 'Religion: Muslim' },
  { id: 753, topic: 38, name: 'id_753_Sikh', label: 'Religion: Sikh' },
  { id: 754, topic: 38, name: 'id_754_Other_religion', label: 'Religion: Other religion' },
  { id: 755, topic: 38, name: 'id_755_None', label: 'Religion: None' },
  { id: 756, topic: 38, name: 'id_756_Not_stated', label: 'Religion: Not stated' },
  { id: 757, topic: 16, name: 'id_757_English_Welsh', label: 'Language: All English/Welsh' },
  { id: 758, topic: 16, name: 'id_758_EU_French', label: 'Language: EU French' },
  { id: 759, topic: 16, name: 'id_759_EU_Portuguese', label: 'Language: EU Portuguese' },
  { id: 760, topic: 16, name: 'id_760_EU_Spanish', label: 'Language: EU Spanish' },
  { id: 761, topic: 16, name: 'id_761_EU_Total', label: 'Language: EU Total' },
  { id: 762, topic: 16, name: 'id_762_EU_Italian', label: 'Language: EU Italian' },
  { id: 763, topic: 16, name: 'id_763_EU_German', label: 'Language: EU German' },
  { id: 764, topic: 16, name: 'id_764_EU_Polish', label: 'Language: EU Polish' },
  { id: 765, topic: 16, name: 'id_765_EU_Slovak', label: 'Language: EU Slovak' },
  { id: 766, topic: 16, name: 'id_766_EU_Czech', label: 'Language: EU Czech' },
  { id: 767, topic: 16, name: 'id_767_EU_Romanian', label: 'Language: EU Romanian' },
  { id: 768, topic: 16, name: 'id_768_EU_Lithuanian', label: 'Language: EU Lithuanian' },
  { id: 769, topic: 16, name: 'id_769_EU_Latvian', label: 'Language: EU Latvian' },
  { id: 770, topic: 16, name: 'id_770_EU_Hungarian', label: 'Language: EU Hungarian' },
  { id: 771, topic: 16, name: 'id_771_EU_Bulgarian', label: 'Language: EU Bulgarian' },
  { id: 772, topic: 16, name: 'id_772_EU_Greek', label: 'Language: EU Greek' },
  { id: 773, topic: 16, name: 'id_773_EU_Dutch', label: 'Language: EU Dutch' },
  { id: 774, topic: 16, name: 'id_774_EU_Swedish', label: 'Language: EU Swedish' },
  { id: 775, topic: 16, name: 'id_775_EU_Danish', label: 'Language: EU Danish' },
  { id: 776, topic: 16, name: 'id_776_EU_Other', label: 'Language: EU Other' },
  { id: 777, topic: 16, name: 'id_777_Albanian', label: 'Language: Albanian' },
  { id: 778, topic: 16, name: 'id_778_Serbian_Croatian_Bosnian', label: 'Language: Serbian, Croatian, Bosnian' },
  { id: 779, topic: 16, name: 'id_779_Russian', label: 'Language: Russian' },
  { id: 780, topic: 16, name: 'id_780_Turkish', label: 'Language: Turkish' },
  { id: 781, topic: 16, name: 'id_781_Arabic', label: 'Language: Arabic' },
  { id: 782, topic: 16, name: 'id_782_Kurdish', label: 'Language: Kurdish' },
  { id: 783, topic: 16, name: 'id_783_Persian_Farsi', label: 'Language: Persian, Farsi' },
  { id: 784, topic: 16, name: 'id_784_Pashto', label: 'Language: Pashto' },
  { id: 785, topic: 16, name: 'id_785_Urdu', label: 'Language: Urdu' },
  { id: 786, topic: 16, name: 'id_786_Hindi', label: 'Language: Hindi' },
  { id: 787, topic: 16, name: 'id_787_Panjabi', label: 'Language: Panjabi' },
  {
    id: 788,
    topic: 16,
    name: 'id_788_Pakistani_Pahari_Mirpuri_Potwari',
    label: 'Language: Pakistani: Pahari, Mirpuri, Potwari',
  },
  { id: 789, topic: 16, name: 'id_789_Bengali_Sylheti_Chatgaya', label: 'Language: Bengali, Sylheti, Chatgaya' },
  { id: 790, topic: 16, name: 'id_790_Gujarati', label: 'Language: Gujarati' },
  { id: 791, topic: 16, name: 'id_791_Telugu', label: 'Language: Telugu' },
  { id: 792, topic: 16, name: 'id_792_Tamil', label: 'Language: Tamil' },
  { id: 793, topic: 16, name: 'id_793_Malayalam', label: 'Language: Malayalam' },
  { id: 794, topic: 16, name: 'id_794_Sinhala', label: 'Language: Sinhala' },
  { id: 795, topic: 16, name: 'id_795_Nepalese', label: 'Language: Nepalese' },
  { id: 796, topic: 16, name: 'id_796_Chinese_Mandarin', label: 'Language: Chinese: Mandarin' },
  { id: 797, topic: 16, name: 'id_797_Chinese_Cantonese', label: 'Language: Chinese: Cantonese' },
  { id: 798, topic: 16, name: 'id_798_Chinese_all_other', label: 'Language: Chinese: All other' },
  { id: 799, topic: 16, name: 'id_799_Japanese', label: 'Language: Japanese' },
  { id: 800, topic: 16, name: 'id_800_Korean', label: 'Language: Korean' },
  { id: 801, topic: 16, name: 'id_801_Vietnamese', label: 'Language: Vietnamese' },
  { id: 802, topic: 16, name: 'id_802_Thai', label: 'Language: Thai' },
  { id: 803, topic: 16, name: 'id_803_Malay', label: 'Language: Malay' },
  { id: 804, topic: 16, name: 'id_804_Tagalog_Filipino', label: 'Language: Tagalog Filipino' },
  { id: 805, topic: 16, name: 'id_805_East_Asian_Other', label: 'Language: East Asian: Other' },
  { id: 806, topic: 16, name: 'id_806_Amharic', label: 'Language: Amharic' },
  { id: 807, topic: 16, name: 'id_807_Tigrinya', label: 'Language: Tigrinya' },
  { id: 808, topic: 16, name: 'id_808_Somali', label: 'Language: Somali' },
  { id: 809, topic: 16, name: 'id_809_Akan', label: 'Language: Akan' },
  { id: 810, topic: 16, name: 'id_810_Yoruba', label: 'Language: Yoruba' },
  { id: 811, topic: 16, name: 'id_811_Swahili_Kiswahili', label: 'Language: Swahili, Kiswahili' },
  { id: 812, topic: 16, name: 'id_812_Shona', label: 'Language: Shona' },
  { id: 813, topic: 16, name: 'id_813_Afrikaans', label: 'Language: Afrikaans' },
  { id: 814, topic: 16, name: 'id_814_Other_language', label: 'Language: Other language' },
  { id: 815, topic: 16, name: 'id_815_Sign', label: 'Language: Sign' },
  { id: 816, topic: 16, name: 'id_816_Sign_BSL', label: 'Language: Sign BSL' },
  { id: 817, topic: 79, name: 'id_817_1_dimension', label: 'Deprivation: Household is deprived in 1 dimension' },
  { id: 818, topic: 79, name: 'id_818_2_dimensions', label: 'Deprivation: Household is deprived in 2 dimensions' },
  { id: 819, topic: 79, name: 'id_819_3_dimensions', label: 'Deprivation: Household is deprived in 3 dimensions' },
  { id: 820, topic: 79, name: 'id_820_4_dimensions', label: 'Deprivation: Household is deprived in 4 dimensions' },
  { id: 821, topic: 4, name: 'id_821_1_Person', label: 'Household type: Single person' },
  {
    id: 822,
    topic: 4,
    name: 'id_822_Married_dep_children',
    label: 'Household type: Married couple family with dependent children',
  },
  {
    id: 823,
    topic: 4,
    name: 'id_823_Married_no_dep_children',
    label: 'Household type: Married couple without dependent children',
  },
  {
    id: 824,
    topic: 4,
    name: 'id_824_Civil_partner_dep_children',
    label: 'Household type: Civil partner couple family with dependent children',
  },
  {
    id: 825,
    topic: 4,
    name: 'id_825_Civil_partner_no_dep_children',
    label: 'Household type: Civil partner couple without dependent children',
  },
  {
    id: 826,
    topic: 4,
    name: 'id_826_Cohabiting_dep_children',
    label: 'Household type: Cohabiting couple family with dependent children',
  },
  {
    id: 827,
    topic: 4,
    name: 'id_827_Cohabiting_no_dep_children',
    label: 'Household type: Cohabiting couple without dependent children',
  },
  {
    id: 828,
    topic: 4,
    name: 'id_828_Lone_parent_dep_children',
    label: 'Household type: Lone parent family with dependent children',
  },
  {
    id: 829,
    topic: 4,
    name: 'id_829_Lone_parent_no_dep_children',
    label: 'Household type: Lone parent family without dependent children',
  },
  { id: 830, topic: 4, name: 'id_830_Multi_family_students', label: 'Household type: Multi-family full-time students' },
  { id: 831, topic: 4, name: 'id_831_Multi_family_other', label: 'Household type: Multi-family: Other' },
  { id: 832, topic: 80, name: 'id_832_Couple_total', label: 'Living Arrangements: Couple total' },
  { id: 833, topic: 80, name: 'id_833_Couple_married', label: 'Living Arrangements: Couple married' },
  {
    id: 834,
    topic: 80,
    name: 'id_834_Couple_cohabiting_opposite_sex',
    label: 'Living Arrangements: Couple cohabiting: opposite sex',
  },
  {
    id: 835,
    topic: 80,
    name: 'id_835_Couple_cohabiting_civil_partners_or_same_sex',
    label: 'Living Arrangements: Couple cohabiting: civil partners or same sex',
  },
  { id: 836, topic: 80, name: 'id_836_Not_a_couple_Total', label: 'Living Arrangements: Not a couple: Total' },
  { id: 837, topic: 80, name: 'id_837_Not_a_couple_Single', label: 'Living Arrangements: Not a couple: Single' },
  {
    id: 838,
    topic: 80,
    name: 'id_838_Not_a_couple_Married_civil_partnership',
    label: 'Living Arrangements: Not a couple: Married, civil partnership',
  },
  { id: 839, topic: 80, name: 'id_839_Not_a_couple_Separated', label: 'Living Arrangements: Not a couple: Separated' },
  { id: 840, topic: 80, name: 'id_840_Not_a_couple_Divorced', label: 'Living Arrangements: Not a couple: Divorced' },
  { id: 841, topic: 80, name: 'id_841_Not_a_couple_Widowed', label: 'Living Arrangements: Not a couple: Widowed' },
  { id: 842, topic: 81, name: 'id_842_16_24', label: 'Population by social grade, age and sex: 16–24' },
  { id: 843, topic: 81, name: 'id_843_25_34', label: 'Population by social grade, age and sex: 25–34' },
  { id: 844, topic: 81, name: 'id_844_35_44', label: 'Population by social grade, age and sex: 35–44' },
  { id: 845, topic: 81, name: 'id_845_45_54', label: 'Population by social grade, age and sex: 45–54' },
  { id: 846, topic: 81, name: 'id_846_55_64', label: 'Population by social grade, age and sex: 55–64' },
  { id: 847, topic: 81, name: 'id_847_AB_All', label: 'Population by social grade, age and sex: AB All' },
  { id: 848, topic: 81, name: 'id_848_AB_16_24', label: 'Population by social grade, age and sex: AB 16–24' },
  { id: 849, topic: 81, name: 'id_849_AB_25_34', label: 'Population by social grade, age and sex: AB 25–34' },
  { id: 850, topic: 81, name: 'id_850_AB_35_44', label: 'Population by social grade, age and sex: AB 35–44' },
  { id: 851, topic: 81, name: 'id_851_AB_45_54', label: 'Population by social grade, age and sex: AB 45–54' },
  { id: 852, topic: 81, name: 'id_852_AB_55_64', label: 'Population by social grade, age and sex: AB 55–64' },
  { id: 853, topic: 81, name: 'id_853_C1_All', label: 'Population by social grade, age and sex: C1 All' },
  { id: 854, topic: 81, name: 'id_854_C1_16_24', label: 'Population by social grade, age and sex: C1–16 24' },
  { id: 855, topic: 81, name: 'id_855_C1_25_34', label: 'Population by social grade, age and sex: C1–25 34' },
  { id: 856, topic: 81, name: 'id_856_C1_35_44', label: 'Population by social grade, age and sex: C1–35 44' },
  { id: 857, topic: 81, name: 'id_857_C1_45_54', label: 'Population by social grade, age and sex: C1–45 54' },
  { id: 858, topic: 81, name: 'id_858_C1_55_64', label: 'Population by social grade, age and sex: C1–55 64' },
  { id: 859, topic: 81, name: 'id_859_C2_All', label: 'Population by social grade, age and sex: C2 All' },
  { id: 860, topic: 81, name: 'id_860_C2_16_24', label: 'Population by social grade, age and sex: C2–16 24' },
  { id: 861, topic: 81, name: 'id_861_C2_25_34', label: 'Population by social grade, age and sex: C2–25 34' },
  { id: 862, topic: 81, name: 'id_862_C2_35_44', label: 'Population by social grade, age and sex: C2–35 44' },
  { id: 863, topic: 81, name: 'id_863_C2_45_54', label: 'Population by social grade, age and sex: C2–45 54' },
  { id: 864, topic: 81, name: 'id_864_C2_55_64', label: 'Population by social grade, age and sex: C2–55 64' },
  { id: 865, topic: 81, name: 'id_865_DE_All', label: 'Population by social grade, age and sex: DE All' },
  { id: 866, topic: 81, name: 'id_866_DE_16_24', label: 'Population by social grade, age and sex: DE 16–24' },
  { id: 867, topic: 81, name: 'id_867_DE_25_34', label: 'Population by social grade, age and sex: DE 25–34' },
  { id: 868, topic: 81, name: 'id_868_DE_35_44', label: 'Population by social grade, age and sex: DE 35–44' },
  { id: 869, topic: 81, name: 'id_869_DE_45_54', label: 'Population by social grade, age and sex: DE 45–54' },
  { id: 870, topic: 81, name: 'id_870_DE_55_64', label: 'Population by social grade, age and sex: DE 55–64' },
  { id: 871, topic: 81, name: 'id_871_Males_All', label: 'Population by social grade, age and sex: Males All' },
  { id: 872, topic: 81, name: 'id_872_Males_16_24', label: 'Population by social grade, age and sex: Males 16–24' },
  { id: 873, topic: 81, name: 'id_873_Males_25_34', label: 'Population by social grade, age and sex: Males 25–34' },
  { id: 874, topic: 81, name: 'id_874_Males_35_44', label: 'Population by social grade, age and sex: Males 35–44' },
  { id: 875, topic: 81, name: 'id_875_Males_45_54', label: 'Population by social grade, age and sex: Males 45–54' },
  { id: 876, topic: 81, name: 'id_876_Males_55_64', label: 'Population by social grade, age and sex: Males 55–64' },
  { id: 877, topic: 81, name: 'id_877_Males_AB_All', label: 'Population by social grade, age and sex: Males AB All' },
  {
    id: 878,
    topic: 81,
    name: 'id_878_Males_AB_16_24',
    label: 'Population by social grade, age and sex: Males AB 16–24',
  },
  {
    id: 879,
    topic: 81,
    name: 'id_879_Males_AB_25_34',
    label: 'Population by social grade, age and sex: Males AB 25–34',
  },
  {
    id: 880,
    topic: 81,
    name: 'id_880_Males_AB_35_44',
    label: 'Population by social grade, age and sex: Males AB 35–44',
  },
  {
    id: 881,
    topic: 81,
    name: 'id_881_Males_AB_45_54',
    label: 'Population by social grade, age and sex: Males AB 45–54',
  },
  {
    id: 882,
    topic: 81,
    name: 'id_882_Males_AB_55_64',
    label: 'Population by social grade, age and sex: Males AB 55–64',
  },
  { id: 883, topic: 81, name: 'id_883_Males_C1_All', label: 'Population by social grade, age and sex: Males C1 All' },
  {
    id: 884,
    topic: 81,
    name: 'id_884_Males_C1_16_24',
    label: 'Population by social grade, age and sex: Males C1–16 24',
  },
  {
    id: 885,
    topic: 81,
    name: 'id_885_Males_C1_25_34',
    label: 'Population by social grade, age and sex: Males C1–25 34',
  },
  {
    id: 886,
    topic: 81,
    name: 'id_886_Males_C1_35_44',
    label: 'Population by social grade, age and sex: Males C1–35 44',
  },
  {
    id: 887,
    topic: 81,
    name: 'id_887_Males_C1_45_54',
    label: 'Population by social grade, age and sex: Males C1–45 54',
  },
  {
    id: 888,
    topic: 81,
    name: 'id_888_Males_C1_55_64',
    label: 'Population by social grade, age and sex: Males C1–55 64',
  },
  { id: 889, topic: 81, name: 'id_889_Males_C2_All', label: 'Population by social grade, age and sex: Males C2 All' },
  {
    id: 890,
    topic: 81,
    name: 'id_890_Males_C2_16_24',
    label: 'Population by social grade, age and sex: Males C2–16 24',
  },
  {
    id: 891,
    topic: 81,
    name: 'id_891_Males_C2_25_34',
    label: 'Population by social grade, age and sex: Males C2–25 34',
  },
  {
    id: 892,
    topic: 81,
    name: 'id_892_Males_C2_35_44',
    label: 'Population by social grade, age and sex: Males C2–35 44',
  },
  {
    id: 893,
    topic: 81,
    name: 'id_893_Males_C2_45_54',
    label: 'Population by social grade, age and sex: Males C2–45 54',
  },
  {
    id: 894,
    topic: 81,
    name: 'id_894_Males_C2_55_64',
    label: 'Population by social grade, age and sex: Males C2–55 64',
  },
  { id: 895, topic: 81, name: 'id_895_Males_DE_All', label: 'Population by social grade, age and sex: Males DE All' },
  {
    id: 896,
    topic: 81,
    name: 'id_896_Males_DE_16_24',
    label: 'Population by social grade, age and sex: Males DE 16–24',
  },
  {
    id: 897,
    topic: 81,
    name: 'id_897_Males_DE_25_34',
    label: 'Population by social grade, age and sex: Males DE 25–34',
  },
  {
    id: 898,
    topic: 81,
    name: 'id_898_Males_DE_35_44',
    label: 'Population by social grade, age and sex: Males DE 35–44',
  },
  {
    id: 899,
    topic: 81,
    name: 'id_899_Males_DE_45_54',
    label: 'Population by social grade, age and sex: Males DE 45–54',
  },
  {
    id: 900,
    topic: 81,
    name: 'id_900_Males_DE_55_64',
    label: 'Population by social grade, age and sex: Males DE 55–64',
  },
  { id: 901, topic: 81, name: 'id_901_Females_All', label: 'Population by social grade, age and sex: Females All' },
  { id: 902, topic: 81, name: 'id_902_Females_16_24', label: 'Population by social grade, age and sex: Females 16–24' },
  { id: 903, topic: 81, name: 'id_903_Females_25_34', label: 'Population by social grade, age and sex: Females 25–34' },
  { id: 904, topic: 81, name: 'id_904_Females_35_44', label: 'Population by social grade, age and sex: Females 35–44' },
  { id: 905, topic: 81, name: 'id_905_Females_45_54', label: 'Population by social grade, age and sex: Females 45–54' },
  { id: 906, topic: 81, name: 'id_906_Females_55_64', label: 'Population by social grade, age and sex: Females 55–64' },
  {
    id: 907,
    topic: 81,
    name: 'id_907_Females_AB_All',
    label: 'Population by social grade, age and sex: Females AB All',
  },
  {
    id: 908,
    topic: 81,
    name: 'id_908_Females_AB_16_24',
    label: 'Population by social grade, age and sex: Females AB 16–24',
  },
  {
    id: 909,
    topic: 81,
    name: 'id_909_Females_AB_25_34',
    label: 'Population by social grade, age and sex: Females AB 25–34',
  },
  {
    id: 910,
    topic: 81,
    name: 'id_910_Females_AB_35_44',
    label: 'Population by social grade, age and sex: Females AB 35–44',
  },
  {
    id: 911,
    topic: 81,
    name: 'id_911_Females_AB_45_54',
    label: 'Population by social grade, age and sex: Females AB 45–54',
  },
  {
    id: 912,
    topic: 81,
    name: 'id_912_Females_AB_55_64',
    label: 'Population by social grade, age and sex: Females AB 55–64',
  },
  {
    id: 913,
    topic: 81,
    name: 'id_913_Females_C1_All',
    label: 'Population by social grade, age and sex: Females C1 All',
  },
  {
    id: 914,
    topic: 81,
    name: 'id_914_Females_C1_16_24',
    label: 'Population by social grade, age and sex: Females C1–16 24',
  },
  {
    id: 915,
    topic: 81,
    name: 'id_915_Females_C1_25_34',
    label: 'Population by social grade, age and sex: Females C1–25 34',
  },
  {
    id: 916,
    topic: 81,
    name: 'id_916_Females_C1_35_44',
    label: 'Population by social grade, age and sex: Females C1–35 44',
  },
  {
    id: 917,
    topic: 81,
    name: 'id_917_Females_C1_45_54',
    label: 'Population by social grade, age and sex: Females C1–45 54',
  },
  {
    id: 918,
    topic: 81,
    name: 'id_918_Females_C1_55_64',
    label: 'Population by social grade, age and sex: Females C1–55 64',
  },
  {
    id: 919,
    topic: 81,
    name: 'id_919_Females_C2_All',
    label: 'Population by social grade, age and sex: Females C2 All',
  },
  {
    id: 920,
    topic: 81,
    name: 'id_920_Females_C2_16_24',
    label: 'Population by social grade, age and sex: Females C2–16 24',
  },
  {
    id: 921,
    topic: 81,
    name: 'id_921_Females_C2_25_34',
    label: 'Population by social grade, age and sex: Females C2–25 34',
  },
  {
    id: 922,
    topic: 81,
    name: 'id_922_Females_C2_35_44',
    label: 'Population by social grade, age and sex: Females C2–35 44',
  },
  {
    id: 923,
    topic: 81,
    name: 'id_923_Females_C2_45_54',
    label: 'Population by social grade, age and sex: Females C2–45 54',
  },
  {
    id: 924,
    topic: 81,
    name: 'id_924_Females_C2_55_64',
    label: 'Population by social grade, age and sex: Females C2–55 64',
  },
  {
    id: 925,
    topic: 81,
    name: 'id_925_Females_DE_All',
    label: 'Population by social grade, age and sex: Females DE All',
  },
  {
    id: 926,
    topic: 81,
    name: 'id_926_Females_DE_16_24',
    label: 'Population by social grade, age and sex: Females DE 16–24',
  },
  {
    id: 927,
    topic: 81,
    name: 'id_927_Females_DE_25_34',
    label: 'Population by social grade, age and sex: Females DE 25–34',
  },
  {
    id: 928,
    topic: 81,
    name: 'id_928_Females_DE_35_44',
    label: 'Population by social grade, age and sex: Females DE 35–44',
  },
  {
    id: 929,
    topic: 81,
    name: 'id_929_Females_DE_45_54',
    label: 'Population by social grade, age and sex: Females DE 45–54',
  },
  {
    id: 930,
    topic: 81,
    name: 'id_930_Females_DE_55_64',
    label: 'Population by social grade, age and sex: Females DE 55–64',
  },
  { id: 931, topic: 82, name: 'id_931_D_100k', label: 'House Price: Detached <100k' },
  { id: 932, topic: 82, name: 'id_932_D_100k_200k', label: 'House Price: Detached 100k-200k' },
  { id: 933, topic: 82, name: 'id_933_D_200k_300k', label: 'House Price: Detached 200k-300k' },
  { id: 934, topic: 82, name: 'id_934_D_300k_400k', label: 'House Price: Detached 300k-400k' },
  { id: 935, topic: 82, name: 'id_935_D_400k_500k', label: 'House Price: Detached 400k-500k' },
  { id: 936, topic: 82, name: 'id_936_D_500k_600k', label: 'House Price: Detached 500k-600k' },
  { id: 937, topic: 82, name: 'id_937_D_600k_700k', label: 'House Price: Detached 600k-700k' },
  { id: 938, topic: 82, name: 'id_938_D_700k_800k', label: 'House Price: Detached 700k-800k' },
  { id: 939, topic: 82, name: 'id_939_D_800k_900k', label: 'House Price: Detached 800k-900k' },
  { id: 940, topic: 82, name: 'id_940_D_900k_1m', label: 'House Price: Detached 900k-1m' },
  { id: 941, topic: 82, name: 'id_941_D_1m_1_1m', label: 'House Price: Detached 1m-1.1m' },
  { id: 942, topic: 82, name: 'id_942_D_1_1m_1_2m', label: 'House Price: Detached 1.1m-1.2m' },
  { id: 943, topic: 82, name: 'id_943_D_1_2m_1_3m', label: 'House Price: Detached 1.2m-1.3m' },
  { id: 944, topic: 82, name: 'id_944_D_1_3m_1_4m', label: 'House Price: Detached 1.3m-1.4m' },
  { id: 945, topic: 82, name: 'id_945_D_1_4m_1_5m', label: 'House Price: Detached 1.4m-1.5m' },
  { id: 946, topic: 82, name: 'id_946_D_1_5m', label: 'House Price: Detached >1.5m' },
  { id: 947, topic: 82, name: 'id_947_S_100k', label: 'House Price: Semi-detached <100k' },
  { id: 948, topic: 82, name: 'id_948_S_100k_200k', label: 'House Price: Semi-detached 100k-200k' },
  { id: 949, topic: 82, name: 'id_949_S_200k_300k', label: 'House Price: Semi-detached 200k-300k' },
  { id: 950, topic: 82, name: 'id_950_S_300k_400k', label: 'House Price: Semi-detached 300k-400k' },
  { id: 951, topic: 82, name: 'id_951_S_400k_500k', label: 'House Price: Semi-detached 400k-500k' },
  { id: 952, topic: 82, name: 'id_952_S_500k_600k', label: 'House Price: Semi-detached 500k-600k' },
  { id: 953, topic: 82, name: 'id_953_S_600k_700k', label: 'House Price: Semi-detached 600k-700k' },
  { id: 954, topic: 82, name: 'id_954_S_700k_800k', label: 'House Price: Semi-detached 700k-800k' },
  { id: 955, topic: 82, name: 'id_955_S_800k_900k', label: 'House Price: Semi-detached 800k-900k' },
  { id: 956, topic: 82, name: 'id_956_S_900k_1m', label: 'House Price: Semi-detached 900k-1m' },
  { id: 957, topic: 82, name: 'id_957_S_1m_1_1m', label: 'House Price: Semi-detached 1m-1.1m' },
  { id: 958, topic: 82, name: 'id_958_S_1_1m_1_2m', label: 'House Price: Semi-detached 1.1m-1.2m' },
  { id: 959, topic: 82, name: 'id_959_S_1_2m_1_3m', label: 'House Price: Semi-detached 1.2m-1.3m' },
  { id: 960, topic: 82, name: 'id_960_S_1_3m_1_4m', label: 'House Price: Semi-detached 1.3m-1.4m' },
  { id: 961, topic: 82, name: 'id_961_S_1_4m_1_5m', label: 'House Price: Semi-detached 1.4m-1.5m' },
  { id: 962, topic: 82, name: 'id_962_S_1_5m', label: 'House Price: Semi-detached >1.5m' },
  { id: 963, topic: 82, name: 'id_963_T_100k', label: 'House Price: Terraced <100k' },
  { id: 964, topic: 82, name: 'id_964_T_100k_200k', label: 'House Price: Terraced 100k-200k' },
  { id: 965, topic: 82, name: 'id_965_T_200k_300k', label: 'House Price: Terraced 200k-300k' },
  { id: 966, topic: 82, name: 'id_966_T_300k_400k', label: 'House Price: Terraced 300k-400k' },
  { id: 967, topic: 82, name: 'id_967_T_400k_500k', label: 'House Price: Terraced 400k-500k' },
  { id: 968, topic: 82, name: 'id_968_T_500k_600k', label: 'House Price: Terraced 500k-600k' },
  { id: 969, topic: 82, name: 'id_969_T_600k_700k', label: 'House Price: Terraced 600k-700k' },
  { id: 970, topic: 82, name: 'id_970_T_700k_800k', label: 'House Price: Terraced 700k-800k' },
  { id: 971, topic: 82, name: 'id_971_T_800k_900k', label: 'House Price: Terraced 800k-900k' },
  { id: 972, topic: 82, name: 'id_972_T_900k_1m', label: 'House Price: Terraced 900k-1m' },
  { id: 973, topic: 82, name: 'id_973_T_1m_1_1m', label: 'House Price: Terraced 1m-1.1m' },
  { id: 974, topic: 82, name: 'id_974_T_1_1m_1_2m', label: 'House Price: Terraced 1.1m-1.2m' },
  { id: 975, topic: 82, name: 'id_975_T_1_2m_1_3m', label: 'House Price: Terraced 1.2m-1.3m' },
  { id: 976, topic: 82, name: 'id_976_T_1_3m_1_4m', label: 'House Price: Terraced 1.3m-1.4m' },
  { id: 977, topic: 82, name: 'id_977_T_1_4m_1_5m', label: 'House Price: Terraced 1.4m-1.5m' },
  { id: 978, topic: 82, name: 'id_978_T_1_5m', label: 'House Price: Terraced >1.5m' },
  { id: 979, topic: 82, name: 'id_979_F_100k', label: 'House Price: Flat <100k' },
  { id: 980, topic: 82, name: 'id_980_F_100k_200k', label: 'House Price: Flat 100k-200k' },
  { id: 981, topic: 82, name: 'id_981_F_200k_300k', label: 'House Price: Flat 200k-300k' },
  { id: 982, topic: 82, name: 'id_982_F_300k_400k', label: 'House Price: Flat 300k-400k' },
  { id: 983, topic: 82, name: 'id_983_F_400k_500k', label: 'House Price: Flat 400k-500k' },
  { id: 984, topic: 82, name: 'id_984_F_500k_600k', label: 'House Price: Flat 500k-600k' },
  { id: 985, topic: 82, name: 'id_985_F_600k_700k', label: 'House Price: Flat 600k-700k' },
  { id: 986, topic: 82, name: 'id_986_F_700k_800k', label: 'House Price: Flat 700k-800k' },
  { id: 987, topic: 82, name: 'id_987_F_800k_900k', label: 'House Price: Flat 800k-900k' },
  { id: 988, topic: 82, name: 'id_988_F_900k_1m', label: 'House Price: Flat 900k-1m' },
  { id: 989, topic: 82, name: 'id_989_F_1m_1_1m', label: 'House Price: Flat 1m-1.1m' },
  { id: 990, topic: 82, name: 'id_990_F_1_1m_1_2m', label: 'House Price: Flat 1.1m-1.2m' },
  { id: 991, topic: 82, name: 'id_991_F_1_2m_1_3m', label: 'House Price: Flat 1.2m-1.3m' },
  { id: 992, topic: 82, name: 'id_992_F_1_3m_1_4m', label: 'House Price: Flat 1.3m-1.4m' },
  { id: 993, topic: 82, name: 'id_993_F_1_4m_1_5m', label: 'House Price: Flat 1.4m-1.5m' },
  { id: 994, topic: 82, name: 'id_994_F_1_5m', label: 'House Price: Flat >1.5m' },
  { id: 995, topic: 82, name: 'id_995_Detached_total_area', label: 'House Price: Detached total area' },
  { id: 996, topic: 82, name: 'id_996_Detached_total_value', label: 'House Price: Detached total value' },
  { id: 997, topic: 82, name: 'id_997_Semi_detached_total_area', label: 'House Price: Semi-detached total area' },
  { id: 998, topic: 82, name: 'id_998_Semi_detached_total_value', label: 'House Price: Semi-detached total value' },
  { id: 999, topic: 82, name: 'id_999_Terraced_total_area', label: 'House Price: Terraced total area' },
  { id: 1000, topic: 82, name: 'id_1000_Terraced_total_value', label: 'House Price: Terraced total value' },
  { id: 1001, topic: 82, name: 'id_1001_Flat_total_area', label: 'House Price: Flat total area' },
  { id: 1002, topic: 82, name: 'id_1002_Flat_total_value', label: 'House Price: Flat total value' },
  { id: 1003, topic: 82, name: 'id_1003_Total_area', label: 'House Price: Total area' },
  { id: 1004, topic: 82, name: 'id_1004_Total_value', label: 'House Price: Total value' },
]
