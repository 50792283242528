import { IndexReportModel } from '../mini/model/IndexReportModel'
import {
  getCensusSegments,
  WORKFORCE_SEGMENTS,
  LIFESTYLE_SEGMENTS,
  LIFESTYLE_SUMMMARY_SEGMENTS,
} from '../mini/model/reportSegments'
import * as JSZip from 'jszip'
import { AppModel } from '../../model/appModel.service'
import { PersonicxStatisticsSummary, StatisticsValue } from '../../../common/models/statistics/statistics'
import { toCSV } from '../../util/exportPlaces'
import * as exportFile from '../../util/exportFile'
import { PlaceEndpoint } from '../../api/place'
import { object as objects } from '@targomo/common'
import { csv } from '../../util/csv'
import { ensureSuffix } from '../../util/fileNameUtil'

export async function processBenchmarksData(
  placesEndpoint: PlaceEndpoint,
  benchmarks: { locations: any[]; columns: any[]; fileName: string }[],
  completeData: boolean
) {
  const locationIds: any = {}
  benchmarks.forEach((benchmark) => {
    benchmark.locations.forEach((location) => {
      if (location.id >= 0) {
        locationIds[location.id] = true
      }
    })
  })

  const placesList = await placesEndpoint.findAllByIds(Object.keys(locationIds))
  const placesMap = objects.mapFromArray(placesList, 'id')

  const result = benchmarks.map((benchmark) => {
    const locations = benchmark.locations.map((original) => {
      const location: any = { ...placesMap[original.id] }

      benchmark.columns.forEach((column) => {
        location[column.label] = original[column.key]
      })

      delete location.dataSetId
      delete location.id

      if (location.other) {
        for (let key in location.other) {
          location[key] = location.other[key]
        }
      }

      delete location.other

      if (completeData) {
        if (original.statistics.input) {
          for (let inputKey in original.statistics.input) {
            let statistic = original.statistics.input[inputKey]
            location[inputKey] = statistic.total
          }
        }
      }

      return location
    })

    return { locations, fileName: benchmark.fileName }
  })

  return result
}

export async function exportData(
  appModel: AppModel,
  placesEndpoint: PlaceEndpoint,
  benchmarks: { locations: any[]; columns: any[]; fileName: string }[],
  completeData: boolean,
  zonesSelected: boolean,
  fileName: string
) {
  const statistics = appModel.statistics.census.getValue()

  const reportModelCensus = new IndexReportModel(
    statistics.census,
    getCensusSegments(statistics),
    statistics.populationOrHouseholds,
    statistics.regionLabel()
  )
  const reportModelWorkforce = new IndexReportModel(
    statistics.census,
    WORKFORCE_SEGMENTS,
    statistics.populationOrHouseholds,
    statistics.regionLabel()
  )
  const reportModelLifeStyle = new IndexReportModel(
    statistics.lifestyle,
    LIFESTYLE_SEGMENTS,
    statistics.populationOrHouseholds,
    statistics.regionLabel()
  )

  var zip = new JSZip()
  var folder = zip.folder('storepointgeo')

  reporToCSV(reportModelCensus, folder)
  reporToCSV(reportModelWorkforce, folder)
  reporToCSV(reportModelLifeStyle, folder)

  if (!zonesSelected) {
    const processedBenchmarks = await processBenchmarksData(placesEndpoint, benchmarks, completeData)
    benchmarksToCSV(processedBenchmarks, folder)
  }

  folder.file('lifestyle.csv', personicxToCsv(statistics.lifestyle.personicxSummary))

  const places = appModel.places.reachableFilteredPlacesNonPassive.getValue()
  folder.file('placesTraveltimes.csv', places && places.length ? toCSV(places) : '')

  const placesPlanning = appModel.places.reachableFilteredPlanningNonPassive.getValue()
  folder.file(
    'planningApplicationsTraveltimes.csv',
    placesPlanning && placesPlanning.length ? toCSV(placesPlanning) : ''
  )

  zip.generateAsync({ type: 'blob' }).then(function (content: any) {
    exportFile.saveFile(
      content as any,
      'application/octet-stream',
      fileName ? ensureSuffix(fileName, '.zip') : 'storepointgeo-reports.zip'
    )
  })

  function reporToCSV(report: IndexReportModel, folder: typeof JSZip) {
    for (var item of report.values) {
      if (item.special) {
      } else {
        folder.file(item.id + '.csv', item.children && item.children.length ? csv(item.children || []) : '')
      }
    }
  }

  function benchmarksToCSV(benchmarks: { locations: any[]; fileName: string }[], folder: typeof JSZip) {
    benchmarks.forEach((benchmark) => {
      folder.file(
        benchmark.fileName + '.csv',
        benchmark.locations && benchmark.locations.length ? csv(benchmark.locations || []) : ''
      )
    })
  }

  function personicxToCsv(item: PersonicxStatisticsSummary) {
    function addItem(name: string, values: StatisticsValue[]) {
      const row: any = { name }
      values.forEach((item, i) => {
        row[`${i + 1}`] = item.total
      })

      return row
    }

    return csv(LIFESTYLE_SUMMMARY_SEGMENTS.map((shape) => addItem(shape.name, item[shape.id])))
  }
}
