import { DecimalPipe } from '@angular/common'
import { MaxEdgeWeightOption } from '@targomo/client'
import { object as objects } from '@targomo/common'
import { take } from 'rxjs/operators'
import { AbstractLocation } from '../../../model'
import { AppModel } from '../../../model/appModel.service'
import {
  CareHomeReport,
  CARE_HOME_PROPERTY_BEDS,
  CARE_HOME_PROPERTY_EN_SUITE,
} from '../../../model/careHome/careHomeModel'
import { OperatorCategorized } from '../../../model/operatorsWithinModel'
import { competitionReport, placesByMinutes } from '../competition'
import { ExportMapImage, ExportPDFReport } from '../exportPdfReport'
import { reportFooter, reportHeader } from '../headerFooter'
import { PDFHelper } from '../helper'
import { homePageSources } from '../homePage'
import { largeWorkforceTableSimpleAlt } from '../largeFormatTableHelpers'
import { mapReportExplicitSide } from '../mapReport'
import { PDF_TABLE_TRAVEL_INTERVALS, getReportTimes } from '../reportModel'
import { createStyle } from '../styles'
import { travelLabel } from '../travelLabel'
import { careDemographics } from './careDemographics'
import { careHomePrices } from './careHomePrices'
import { careOverUnderSupply } from './careOverUnderSupply'
import { carePopulationForecast } from './carePopulationForecast'

const pdfMake = require('pdfmake/build/pdfmake.js')
const pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

const CARE_TYPE_CODE_MAP = {
  'Learning Disability': 'LD',
  'Mental Health Condition': 'MHC',
  Dementia: 'DE',
  'Physical Disability': 'PD',
  'Sensory Impairment': 'SI',
  'Substance Misuse': 'SM',
  'Detention Under Mental Health Act': 'MHA',
  'Eating Disorders': 'ED',
}

export class CareHomePdf {
  private helper: PDFHelper

  constructor(
    private exportMapImage: ExportMapImage,
    private appModel: AppModel,
    private exportPDFReport: ExportPDFReport,
    private careHomeReport: CareHomeReport
  ) {
    this.helper = new PDFHelper(new DecimalPipe('en-US'))
  }

  private async homePage(imageData: any, travelMode: string, title: string = '') {
    title =
      title ||
      `${travelMode}Time, Competition & Demographics - ${this.careHomeReport.currentYear} & ${this.careHomeReport.additional[1].year}`

    const sourcesTitles = await this.appModel.titles.value$.take(1).toPromise()

    const sourcesRows = homePageSources(sourcesTitles, (location) => [
      location.sitename,
      location.town,
      location.postcode,
    ])
    return []
      .concat([
        { image: imageData, width: 230, style: 'logoCenter' },
        { text: 'Care Home Report', style: 'homeHeader4' },
      ])
      .concat(sourcesRows, [
        { style: 'homeHeader2', text: title, margin: [40, 40, 40, 40] },
        { text: this.helper.formatDate(new Date()), style: 'frontPageDate' },
        { style: 'copyright', text: '' },
      ])
  }

  private async competition(
    sources: AbstractLocation[],
    places: AbstractLocation[],
    edgeWeights: MaxEdgeWeightOption[],
    zonesVisible: boolean
  ) {
    const totalsProperties = [CARE_HOME_PROPERTY_BEDS, CARE_HOME_PROPERTY_EN_SUITE]

    const tableProperties = [
      'fascia',
      'holdingCompany',
      'town',
      'postcode',
      'careType',
      'travelTime',
      CARE_HOME_PROPERTY_BEDS,
      CARE_HOME_PROPERTY_EN_SUITE,
    ]

    const tableCoalesceProperties = ['fascia', 'holdingCompany', CARE_HOME_PROPERTY_BEDS, CARE_HOME_PROPERTY_EN_SUITE]

    const tablePropertiesLabels = [null, 'Operator', null, null, 'Care Type', null, 'Beds', 'Ensuite']

    function makeServiceKeys(places: AbstractLocation[]) {
      return Object.keys(
        places.reduce((acc, cur) => {
          const properties = cur.properties || {}
          Object.keys(CARE_TYPE_CODE_MAP).forEach((key: keyof typeof CARE_TYPE_CODE_MAP) => {
            if (properties[key] === 'y' || properties[key] === 'Y') {
              acc[CARE_TYPE_CODE_MAP[key]] = true
            }
          })

          return acc
        }, {} as Record<string, boolean>)
      )
        .sort()
        .join(' ')
    }

    function cloneAndPreparePlaces(places: AbstractLocation[]) {
      if (places) {
        return places.map((place) => {
          const result = place.copy()
          result.fascia = result.sitename
          result.properties.careType = makeServiceKeys([result])
          return result
        })
      } else {
        return places
      }
    }

    if (sources) {
      sources = cloneAndPreparePlaces(sources)
    }

    places = cloneAndPreparePlaces(places)

    function categorizeLocations(locations: AbstractLocation[]): OperatorCategorized[] {
      return objects.values(
        locations.reduce((acc, place) => {
          const categorizationId = place.sitename

          if (acc[categorizationId]) {
            acc[categorizationId].count++
            ;(acc[categorizationId][CARE_HOME_PROPERTY_BEDS] += +place.properties[CARE_HOME_PROPERTY_BEDS] || 0),
              (acc[categorizationId][CARE_HOME_PROPERTY_EN_SUITE] +=
                +place.properties[CARE_HOME_PROPERTY_EN_SUITE] || 0),
              acc[categorizationId].locations.push(place)
          } else {
            acc[categorizationId] = {
              [CARE_HOME_PROPERTY_BEDS]: +place.properties[CARE_HOME_PROPERTY_BEDS] || 0,
              [CARE_HOME_PROPERTY_EN_SUITE]: +place.properties[CARE_HOME_PROPERTY_EN_SUITE] || 0,
              category: <any>{
                id: place.sitename as any,
                name: place.sitename,
                code: place.sitename,
                grouping: (place as any).holdingCompany,
                color: place.category.color,
              },
              count: 1,
              locations: [place],
              holdingCompany: (place as any).holdingCompany,
            }
          }
          return acc
        }, {} as Record<string, OperatorCategorized>)
      )
    }

    // const minutesGroup = edgeWeights[0].value / 5
    const minutesGroup = Math.ceil(edgeWeights[0].value / 5 / 60) * 60
    const groupsTotals = placesByMinutes(places, zonesVisible, minutesGroup, totalsProperties)

    return competitionReport(
      places,
      groupsTotals.groups,
      sources,
      groupsTotals.totals,
      this.helper,
      zonesVisible,
      tableProperties,
      false,
      tablePropertiesLabels,
      tableCoalesceProperties,
      categorizeLocations,
      false
    ).concat({
      fontSize: 7,
      margin: [0, 10, 0, 0],
      text: 'Key: PD: Physical Disability, DE: Dementia, SI: Sensory Impairments, MHC: Mental Health Condition, LD: Learning Difculties, SM: Substance Misuse, ED: Eating Disorders, MHA: Detention Under Mental Health Act.',
    })
  }

  private pageBreak(text = '', pageOrientation = 'landscape') {
    return {
      pageBreak: 'before',
      pageOrientation,
      style: 'carePageHeader',
      text,
    }
  }

  private async mapReport(
    maxEdgeWeight: number,
    edgeWeights: MaxEdgeWeightOption[],
    travelTitlePart: string,
    zonesVisible: boolean
  ) {
    const travelTitle = travelTitlePart + 'Time'
    const mapImageData = await (await this.exportMapImage('competition')).imageData
    // const travelIntervals = PDF_TABLE_TRAVEL_INTERVALS[Math.floor(maxEdgeWeight / 60)]

    const travelIntervals = (await getReportTimes(this.appModel)).map((item) => item.minutes)

    const summaryReport = this.careHomeReport.summaryForTravelBands(travelIntervals)
    const summaryReportTotal = this.careHomeReport.summaryTotal()

    function makeBandModel(key: keyof typeof summaryReport) {
      if (zonesVisible) {
        return [
          {
            name: edgeWeights[0].label,
            total: summaryReportTotal[key],
          },
        ]
      } else {
        return travelIntervals.map((interval, i) => {
          return {
            name: `${interval} minutes`,
            total: summaryReport[key][i],
          }
        })
      }
    }

    const sideColunms: any[] = [
      largeWorkforceTableSimpleAlt(makeBandModel('ages65plus'), this.helper, '65+ Population', travelTitle),
      ' ',
      largeWorkforceTableSimpleAlt(makeBandModel('locations'), this.helper, 'Care Homes', travelTitle),
      ' ',
      largeWorkforceTableSimpleAlt(makeBandModel('beds'), this.helper, 'Care Home Beds', travelTitle),
    ]

    return mapReportExplicitSide(mapImageData, edgeWeights, sideColunms)
  }

  private async overUnderSupply() {
    return careOverUnderSupply(this.helper, this.careHomeReport)
  }

  private async demographics() {
    return careDemographics(this.helper, this.careHomeReport)
  }

  private async populationForecasts() {
    return await carePopulationForecast(this.helper, this.careHomeReport)
  }

  private async housePrices(travelLabel: string) {
    return await careHomePrices(this.helper, this.careHomeReport, travelLabel)
  }

  async save(title: string, fileName: string) {
    fileName = fileName || 'storepointgeo_care_home_report.pdf'
    const settings = await this.exportPDFReport.pdfGeneralSettings()
    // const maxEdgeWeight = (await this.appModel.settings.travelOptionsUpdates.take(1).toPromise()).maxEdgeWeight
    const travelMode = (await this.appModel.settings.travelOptionsUpdates.take(1).toPromise()).travelType
    const zonesVisible = await this.appModel.zoneLayersModel.selectionExistsUpdates.take(1).toPromise()

    const edgeWeights = await this.appModel.settings.edgeWeightsUpdates.take(1).toPromise()
    let travelOrZoneEdgeWeights = edgeWeights
    const mapImageData = await (await this.exportMapImage('competition')).imageData

    const currentYear = this.careHomeReport.currentYear
    const finalYear = this.careHomeReport.additional[1].year

    const travelLabelText = zonesVisible ? 'Travel-' : travelLabel(travelMode)

    if (zonesVisible) {
      const selectedZones = await this.appModel.zoneLayersModel.selectedZones.pipe(take(1)).toPromise()

      travelOrZoneEdgeWeights = [{ label: selectedZones[0].name, value: null }]
    }

    const travelIntervals = (await getReportTimes(this.appModel)).map((item) => item.time)
    const maxEdgeWeight = travelIntervals[travelIntervals.length - 1]

    const content: any[] = ([] as any[]).concat(
      await this.homePage(settings.logo, travelLabelText, title),
      this.pageBreak(`${Math.floor(maxEdgeWeight / 60)} Minute ${travelLabelText}Time Summary`),
      await this.mapReport(maxEdgeWeight, travelOrZoneEdgeWeights, travelLabelText, zonesVisible),
      this.pageBreak(`Over/Under Supply Summary: ${currentYear} - ${finalYear}`),
      await this.overUnderSupply(),
      this.pageBreak(`Competition - ${currentYear}`),
      await this.competition(this.careHomeReport.sources, this.careHomeReport.competition, edgeWeights, zonesVisible),
      this.pageBreak(`Demographics - ${currentYear}:`),
      await this.demographics(),
      this.pageBreak(`Population Forecasts: ${currentYear} - ${finalYear}`),
      await this.populationForecasts(),
      this.pageBreak('Dwellings & House Prices'),
      await this.housePrices(travelLabelText)
    )

    const styles = createStyle(settings.reportSettings)
    styles.logoCenter.margin = [0, 30, 0, 15]
    styles.homeHeader4.fontSize = 18
    styles.frontPageDate = {
      fontSize: 14,
      alignment: 'center',
      margin: [0, 10, 0, 0],
    }

    const docDefinition: any = {
      footer: reportFooter(settings.settings.copyright, settings.logo, settings.reportSettings.shortFooter),
      header: reportHeader(settings.customLogo.data, 'Care Home Report'),
      content,
      styles,
      pageMargins: [30, 80, 30, 80],
      pageSize: 'A4',
      pageOrientation: 'landscape',
    }

    pdfMake.createPdf(docDefinition).download(fileName)
  }
}
