import { DecimalPipe, PercentPipe } from '@angular/common'
import { Place } from '../../api/place'
import { ReportModel } from './exportPdfReport'
import { DEFAULT_CORPORATE_COLOR } from './styles'

// const r360 = require('route360')
export const PERCENT_PIPE = new PercentPipe('en')

function getHeaderTableOptions(helper: PDFHelper) {
  return {
    border: helper.tableBorder(),
    background: [helper.tableBorder(), null],
    // widths: () => ['50', '50'],
    layout: {
      hLineWidth: function (i: number, node: any) {
        return 1
      },
      vLineWidth: function (i: number, node: any) {
        return 1
      },
      hLineColor: function (i: number, node: any) {
        return helper.tableBorder() //'#b06977'//CORPORATE_COLOR
      },
      vLineColor: function (i: number, node: any) {
        return helper.tableBorder() // '#b06977'//CORPORATE_COLOR
      },

      fillColor: function (i: number, node: any) {
        return helper.tableBorder()
      },
    },
  }
}

export class PDFHelper {
  public icons: {
    home?: string
    logo?: string
    people?: string
    customLogo?: string
    car?: string
  } = {}

  borderColor: string = DEFAULT_CORPORATE_COLOR

  constructor(readonly numberFilter: DecimalPipe) {}

  setBorderColor(color: string) {
    this.borderColor = color || DEFAULT_CORPORATE_COLOR
  }

  setIcons(icons: any) {
    this.icons = icons
  }

  table(headings: any[], rows: any[][], style?: any, options?: any) {
    options = options || {}
    options.border = options.border || '#f0f0f0'
    options.background = options.background || ['#fafafa', null]
    options.layout = options.layout || competitionTableOptions(options)

    const cells = (headings ? [headings] : []).concat(rows).map((row) => {
      const callback = (cell: any, i: number) => {
        if (typeof cell === 'string') {
          return {
            text: cell,
            style: i == 0 ? options.defaultCellFirst || 'defaultCellFirst' : options.defaultCell || 'defaultCell',
          }
        } else {
          return cell
        }
      }

      return row.map(options.cellTransform || callback)
    })

    const table: any = {
      table: {
        widths: options.widths ? options.widths(cells[0]) : cells[0].map((value, i) => (i == 0 ? '*' : 'auto')),
        body: cells,
      },
      layout: options.layout,
    }

    if (style) {
      table.style = style
    }

    if (options.headerRows != null) {
      table.table.headerRows = options.headerRows
    }

    return [table]
  }

  formatCell(value: any, precision: number = 0, suffix = '', prefix = '') {
    if (typeof value == 'string') {
      return value + suffix
    }

    if (value === null || value === undefined || isNaN(value)) {
      return ''
    }

    if (typeof value == 'number') {
      return '' + prefix + this.numberFilter.transform(value, `1.${precision}-${precision}`) + suffix
    }
    // return value.toFixed(precision) + suffix

    return '' + prefix + value + suffix
  }

  formatMinutes(value: any) {
    if (value === null || value === undefined || isNaN(value)) return ''

    // return r360.Util.secondsToHoursAndMinutes(value)
    return '' + Math.ceil(value / 60)
  }

  formatNumber(value: any, precision: number = 0, suffix = '') {
    if (value === null || value === undefined || isNaN(value)) return ''

    // return r360.Util.secondsToHoursAndMinutes(value)
    return '' + this.numberFilter.transform(value, `1.${precision}-${precision}`) + suffix
  }

  formatPercent(value: any, precision: number = 0, suffix = '') {
    if (value === null || value === undefined || isNaN(value)) return ''

    return '' + PERCENT_PIPE.transform(value, `1.${precision}-${precision}`) + suffix
  }

  styleColorColumn(value: any, style = 'defaultCellColorBoxCenter2') {
    value.style = style
    return value
  }

  convertImgToDataURLviaCanvas(url: string, outputFormat: string): Promise<any> {
    return new Promise((resolve) => {
      var img = new Image()
      img.crossOrigin = 'Anonymous'
      img.onload = function () {
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        var dataURL
        canvas.height = (<any>this).height
        canvas.width = (<any>this).width
        ctx.drawImage(<any>this, 0, 0)
        dataURL = canvas.toDataURL(outputFormat)
        resolve({
          data: dataURL,
          width: (<any>this).width,
          height: canvas.height,
        })
        canvas = null
      }

      img.onerror = function () {
        resolve({ width: 0, height: 0, data: '' }) // don't stop the show just for missing client/logo
      }

      img.src = url
    })
  }

  cellLeftRight(starting: number) {
    return (cell: any, i: number) => {
      if (typeof cell === 'string') {
        return { text: cell, style: i <= starting ? 'defaultCellFirst' : 'defaultCell' }
      } else {
        return cell
      }
    }
  }

  formatDate(date: Date) {
    const MONTHS = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const month = MONTHS[date.getMonth()]
    return `${date.getDate()} ${month}, ${date.getFullYear()}`
  }

  tableBorder(index?: number) {
    if (index == null || this.borderColor) {
      return this.borderColor
    } else {
      return TABLE_BORDERS[index]
    }
  }

  fullWidthHeader(header: string, style = 'headerInverse') {
    return [this.table(null, [[{ text: header.toUpperCase(), style }]], null, getHeaderTableOptions(this)), ' ']
  }
}

export const TABLE_BACKGROUNDS = ['#e7f1f9', '#fbfdf9']
export const TABLE_BORDERS = ['#548235', '#a9d08e', '#e2efda', '#f0f0f0', '#f0f0f0', '#f0f0f0']
// const TABLE_BORDERS = [this.borderColor, this.borderColor, this.borderColor, this.borderColor, this.borderColor, this.borderColor]

// export class TableOptions {
//   layout: any

export function tableOptions(helper: PDFHelper, reportModel: ReportModel, headRows = 3, segmentSize = 5) {
  return {
    layout: {
      hLineWidth: function (i: number, node: any) {
        const hasBorder = node.table.body[i] && node.table.body[i][0] && node.table.body[i][0].group

        return hasBorder || i == 0 ? 2 : 0
      },

      vLineWidth: function (i: number, node: any) {
        const firstInSegment = ~~(i % segmentSize) == 1
        if (firstInSegment) return 2
        else return 0
      },

      hLineColor: function (i: number, node: any) {
        return helper.tableBorder(0)
      },

      vLineColor: function (i: number, node: any) {
        const index = Math.floor((i - 1) / segmentSize)
        if (index == 0 || index >= reportModel.length) return helper.tableBorder(0)
        else return helper.tableBorder(1)
      },

      fillColor: function (i: number, node: any) {
        if (i < headRows) {
          return null
        }

        if (node.table.body[i] && node.table.body[i][0] && node.table.body[i][0].group) return '#ffffff'

        if (node.table.body[i] && node.table.body[i][0] && node.table.body[i][0].rowBackground)
          return node.table.body[i][0].rowBackground

        return TABLE_BACKGROUNDS[i % TABLE_BACKGROUNDS.length]
      },
    },
  }
}

export function competitionTableOptions(options: any = {}) {
  return {
    hLineWidth(i: number, node: any) {
      let hasBorder: boolean
      // console.log(node.table.body[i])isSectionHeader: true,
      // var hasBorder = //(i === 0 || i === node.table.body.length - 1)
      if (
        node.table.body[i] &&
        node.table.body[i][0] &&
        (node.table.body[i][0].total || node.table.body[i][0].isSectionHeader)
      ) {
        hasBorder = true
      }

      return hasBorder ? 1 : 0
    },

    vLineWidth(i: number, node: any) {
      var hasBorder = i === 0 || i === node.table.widths.length

      return 0 //hasBorder ? 1 : 0
    },

    hLineColor(i: number, node: any) {
      if (node.table.body[i] && node.table.body[i][0] && node.table.body[i][0].cellBorder) {
        return node.table.body[i][0].cellBorder
      }

      return options.border || 'black'
    },

    vLineColor(i: number, node: any): any {
      return null //options.border || 'black'
    },

    fillColor(i: number, node: any) {
      if (node.table.body[i] && node.table.body[i][0] && node.table.body[i][0].total) return '#ffffff'

      return options.background[i % options.background.length]
    },
  }
}

export function colorSquare(place: Place, w = 10, h = 10, x = 0, y = 0) {
  return {
    style: 'defaultCellColorBox',
    canvas: [
      {
        type: 'rect',
        x,
        y,
        w,
        h,
        color: (place.category && place.category.color) || '#f6f6f6',
      },
    ],
  }
}

export function colorSquareGeneral(color: string, w = 10, h = 10, x = 0, y = 0) {
  return {
    style: 'defaultCellColorBox',
    canvas: [
      {
        type: 'rect',
        x,
        y,
        w,
        h,
        color: color,
      },
    ],
  }
}

export function colorCircle(color: string, r = 10, x = 5, y = 5, lineColor: string = undefined) {
  return {
    style: 'defaultCellColorBox',
    canvas: [
      {
        type: 'ellipse',
        x,
        y,
        r1: r,
        r2: r,
        color: color,
        lineColor: lineColor || color,
        lineWidth: 0.2,
      },
    ],
  }
}
