import { ReportModel } from './exportPdfReport'
import { PDFHelper, TABLE_BACKGROUNDS } from './helper'
import { valueGraph } from './spend'

export function generationsLegend(reportModel: ReportModel, helper: PDFHelper) {
  const WIDTH = 450
  const HEIGHT = 145

  const START_YEAR = 1920
  const END_YEAR = 2030

  const GENS = [
    { textColor: '#384a72', name: 'Alpha', from: 2013, to: 2025, color: '#ff66ff', yearLabel: 'Post 2010' },
    { textColor: '#384a72', name: 'Gen Z', from: 1997, to: 2012, color: '#ffff03', yearLabel: '1997-10' },
    { textColor: '#384a72', name: 'Millenials', from: 1981, to: 1996, color: '#c6e0b4', yearLabel: '1981-96' },
    { textColor: '#384a72', name: 'Gen X', from: 1965, to: 1980, color: '#ffe699', yearLabel: '1965-80' },
    { textColor: '#384a72', name: 'Boomers', from: 1946, to: 1964, color: '#f8cbad', yearLabel: '1946-64' },
    { textColor: '#ffffff', name: 'Silent', from: START_YEAR, to: 1945, color: '#acb9ca', yearLabel: 'Pre 1945' },
  ]

  const YEARS = [1920, 1940, 1960, 1980, 2000, 2020]

  function yearX(year: number) {
    return (WIDTH / (END_YEAR - START_YEAR)) * (year - START_YEAR)
  }

  const YEAR_LINES = Array.from({ length: 22 * 2 }).map((x, i) => START_YEAR + i * 2.5)
  const ROW_HEIGHT = 24

  return {
    margin: [10, 10, 0, 0],
    stack: [
      {
        canvas: [
          ...YEAR_LINES.map((i) => {
            return {
              type: 'rect',
              x: yearX(i),
              y: 0,
              w: 0.5,
              h: HEIGHT,
              color: '#000000',
            }
          }),

          ...GENS.map((gen, i) => {
            return {
              type: 'rect',
              x: yearX(gen.from), //10 + i * (WIDTH / COLORS.length),
              y: ROW_HEIGHT * i,
              w: yearX(gen.to) - yearX(gen.from), //WIDTH / COLORS.length - 20,
              h: 16,
              color: gen.color,
            }
          }),
        ],
      },

      ...GENS.map((gen, i) => {
        return {
          text: gen.yearLabel,
          color: gen.textColor,
          fontSize: 8,
          relativePosition: {
            x: 5 + yearX(gen.from),
            y: ROW_HEIGHT * i - HEIGHT + 3,
          },
        }
      }),

      ...YEARS.map((label, i) => {
        return {
          text: label,
          fontSize: 8,
          relativePosition: {
            x: yearX(+label),
            y: 10,
          },
        }
      }),

      ...GENS.map((gen, i) => {
        return {
          text: gen.name,
          fontSize: 8,
          relativePosition: {
            x: WIDTH + 5,
            y: ROW_HEIGHT * i - HEIGHT + 2,
          },
        }
      }),
    ],
  }
}

export function generations(reportModel: ReportModel, helper: PDFHelper) {
  const COLORS = ['#fbfdf9', '#e7f1f9']
  const pageElements: any[] = []

  // const createHeads = () => {
  //   const head: any[] = [{ text: '', border: [false, false, false, false] }]
  //   const subhead: any[] = ['']

  //   reportModel.forEach((report) => {
  //     head.push({ text: 'Banana', style: 'defaultCellLeft' })
  //     head.push('')
  //     head.push({ text: 'Index vs ' + report.statistics.regionLabel() + ' Avg', style: 'defaultCellRight' })

  //     subhead.push({ text: '-100', style: 'percentHeaderLeft' })
  //     subhead.push({ text: '0', style: 'percentHeaderCenter' })
  //     subhead.push({ text: '100', style: 'percentHeaderRight' })
  //   })

  //   rows.push(head)
  //   rows.push(subhead)
  // }

  reportModel.forEach((report) => {
    const options = {
      layout: {
        hLineWidth: function (i: number, node: any) {
          return 0
        },
        vLineWidth: function (i: number, node: any) {
          return 0
        },
        hLineColor: function (i: number, node: any) {
          return helper.tableBorder(0)
        },
        vLineColor: function (i: number, node: any) {
          const index = Math.floor((i - 1) / 3)
          if (index == 0 || index >= reportModel.length) return helper.tableBorder(0)
          else return helper.tableBorder(1)
          // return  TABLE_BORDERS[(~~(i - 1) / 3)]//(i === 0 || i === node.table.widths.length) ? 'black' : 'black'
        },

        fillColor: function (i: number, node: any) {
          return null as any
        },
      },

      defaultCellFirst: 'defaultCellFirst2',
      defaultCell: 'defaultCell2',
    }

    const rows: any[] = []

    const generations = [
      { name: 'Alpha', statistic: report.statistics.populationByYear.rangeByYears(2013, 2023) },
      { name: 'Gen Z', statistic: report.statistics.populationByYear.rangeByYears(1997, 2012) },
      { name: 'Millenials', statistic: report.statistics.populationByYear.rangeByYears(1981, 1996) },
      { name: 'Gen X', statistic: report.statistics.populationByYear.rangeByYears(1965, 1980) },
      { name: 'Boomers', statistic: report.statistics.populationByYear.rangeByYears(1946, 1964) },
      { name: 'Silent', statistic: report.statistics.populationByYear.rangeByYears(1900, 1945) },
    ]

    rows.push(
      ['Generation', '%', '', `Ind vs ${report.statistics.regionLabel()} Avg`].map((text, i) => {
        return {
          text,
          bold: true,
          style: i === 0 ? 'defaultCellFirst2' : 'defaultCell2',
        }
      })
    )

    generations.forEach((item, i) => {
      const row: any[] = [item.name]

      const value = item.statistic.percent
      const index = item.statistic.index

      row.push({
        text: helper.formatCell(value, 0, '%'),
        style: 'defaultCell2',
        border: [false, false, false, false],
      })

      const graphValue = (value && index && value / index) || 0
      row.push(valueGraph(graphValue, '#ffffff', 8, 20, 'defaultCell2', true))
      row.push({
        text: helper.formatCell(graphValue * 100),
        bold: graphValue > 1,
        style: 'defaultCell2',
      })

      rows.push(row)
    })

    pageElements.push({ text: report.nameFull, style: 'largeCellGroupUnderline' })
    pageElements.push({
      stack: [helper.table(null, rows, 'defaultTable', options)],
      // width: 260,
      // style: 'legendStyle',
      margin: [150, 0, 150, 20],
    })
  })

  pageElements.push(generationsLegend(reportModel, helper))

  return pageElements
}
