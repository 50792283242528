import { LabelledStatisticsValue } from '../../../../common/models/statistics/statistics'
import { CareHomeReport, CareHomeYearReport } from '../../../model/careHome/careHomeModel'
import { PDFHelper } from '../helper'
import { careGraphPopulation } from './careGraph'
import { TABLE_OPTIONS_OTHER } from './tableStyle'
import { underlineCell } from './underlineCell'


function populationTable(
  helper: PDFHelper, 
  keys: (keyof CareHomeYearReport)[], 
  labels: string[],
  report: CareHomeReport,
) {
  // const current = report.current
  let rows: any[][] = keys.map((key, i) => {
    const item = report.current[key] as LabelledStatisticsValue
    const itemForecast = (report.additional[1].horizon)[key] as LabelledStatisticsValue

    return [
      labels[i],
      helper.formatNumber(item.total),
      helper.formatNumber(item.percent || 0, 1),
      helper.formatNumber(key === 'population' ? 100 /* total */ : 100 * item.percent / item.index || 0),
      helper.formatNumber(itemForecast.total || 0),
      helper.formatNumber(itemForecast.changeOverBase * 100 || 0, 1),
    ]
  })

  rows = [[
    {text: 'Population', margin: [2, 7, 2, 2],},
    underlineCell('2021'),
    underlineCell('%'),
    underlineCell(`Ind vs GB Avg`),
    underlineCell('' + report.additional[1].year),
    underlineCell('% change'),
  ]].concat(rows)


  rows = rows.map((row, i) => row.map((cell, j) => {
    if (typeof cell === 'string') {
      cell = {text: cell, style: 'defaultCellBiggerFont'}
    } else if (!cell.style) {
      cell = {...cell, style: 'defaultCellBiggerFont'}
    }

    cell.alignment = 'center'
    if (j === 0) {
      cell.alignment = 'right'
    }

    if (i === 0) {
      cell.bold = true
    }

    if (j === 0) {
      cell.border = [false, false, false, false]
    }

    return cell
  }))


  return helper.table(null, rows, 'defaultTable', TABLE_OPTIONS_OTHER)
}

export async function carePopulationForecast(helper: PDFHelper, careHomeReport: CareHomeReport) {
  const populationKeys: (keyof CareHomeYearReport)[] = [
    'population',
    'aged65to74',
    'aged75to84',
    'aged85AndOver',
    'aged65AndOver',
  ]

  const populationLabels: string[] = [
    'Total',
    '65-74',
    '75-84',
    '85+',
    '65+',
  ]  

  return {
    columnGap: 30,
    columns: [
      {
        image: await careGraphPopulation(careHomeReport),
        width: 360,
      },
      [
        populationTable(helper, populationKeys, populationLabels, careHomeReport)
      ]
    ]
  }
}
