import { Component, OnInit } from '@angular/core'
import { TRAVEL_COLORS } from '@targomo/client'
import * as d3scales from '../../util/d3scales'
import { array as arrays } from '@targomo/common'
import { SettingsModel } from '../../../model/settingsModel'
import { CustomTravelColorDialogComponent } from '../../dialogs/customTravelColorDialog/customTravelColorDialog.component'
import { MatDialog } from '@angular/material'

export const MAP_COLOR_RANGES: { name: string; value: string[]; key: string }[] = [
  { key: 'greenToRed', name: 'Green to Red', value: TRAVEL_COLORS },
  { key: 'colorblind', name: 'Colorblind', value: ['#142b66', '#4525ab', '#9527bc', '#ce29a8', '#df2a5c', '#f0572c'] },
  {
    key: 'classic',
    name: 'Classic',
    value: [
      'rgb(179,255,64)',
      'rgb(255,152,48)',
      'rgb(0, 104, 55)',
      'rgb(234,56,193)',
      'rgb(14,137,255)',
      'rgb(237,4,31)',
    ],
  },
  // {name: 'Classic', value: ['rgb(179,255,64)', 'rgb(255,152,48)', 'rgb(255,255,176)', 'rgb(234,56,193)', 'rgb(14,137,255)', 'rgb(237,4,31)']},
]

const COLORS = [
  'options_colors_classic.png',
  'options_colors_greenToRed.png',
  'options_colors_colorblind.png',
  'options_colors_classic.png',
  'options_colors_interpolateYlOrRd.png',
  'options_colors_interpolateRdYlBu.png',
  'options_colors_interpolateRdYlGn.png',
  'options_colors_interpolateSpectral.png',
  'options_colors_interpolateYlGnBu.png',
  'options_colors_interpolateRdPu.png',
  'options_colors_interpolateBlues.png',
  'options_colors_interpolateGreens.png',
  'options_colors_interpolatePurples.png',
  'options_colors_interpolateWarm.png',
  'options_colors_interpolateCool.png',
  'options_colors_interpolatePlasma.png',
  'options_colors_interpolateGreys.png',
]

@Component({
  selector: 'travel-colors-options-panel',
  templateUrl: './travelColorsOptionsPanel.component.html',
  styleUrls: ['./travelColorsOptionsPanel.component.less'],
})
export class TravelColorsOptionsPanelComponent {
  colorRanges: typeof MAP_COLOR_RANGES

  constructor(readonly settings: SettingsModel, private dialog: MatDialog) {
    function initMapColors() {
      const colorRamps = [
        { name: 'Yellow-Orange-Red', key: 'interpolateYlOrRd' },
        { name: 'Red-Yellow-Blue', key: 'interpolateRdYlBu' },
        { name: 'Red-Yellow-Green', key: 'interpolateRdYlGn' },
        { name: 'Spectral', key: 'interpolateSpectral' },
        { name: 'Yellow-Green-Blue', key: 'interpolateYlGnBu' },
        { name: 'Red-Purple', key: 'interpolateRdPu' },
        { name: 'Blues', key: 'interpolateBlues' },
        { name: 'Greens', key: 'interpolateGreens' },
        { name: 'Purples', key: 'interpolatePurples' },
        { name: 'Warm', key: 'interpolateWarm' },
        { name: 'Cool', key: 'interpolateCool' },
        { name: 'Plasma', key: 'interpolatePlasma' },
        { name: 'GrayScale', key: 'interpolateGreys' },
      ]

      function processRamp(interpolator: string) {
        function getColor(idx: number, stops: number, interpolator: string) {
          const opacity = 1
          const colorScale = (<any>d3scales)[interpolator]((idx + 1) / stops)
          const rgb = d3.rgb(colorScale)

          return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + opacity + ')'
        }

        return arrays.empty(6).map((value, i) => getColor(i, 6, interpolator))
      }

      return [].concat(MAP_COLOR_RANGES).concat(
        colorRamps.map((ramp) => {
          return { key: ramp.key, name: ramp.name, value: processRamp(ramp.key) }
        })
      )
    }

    this.colorRanges = [{ key: 'custom', name: 'User Defined', value: null }]
      .concat(initMapColors())
      .map((item: any, i) => {
        item.image = COLORS[i]
        return item
      })
  }

  async changeColor(value: string) {
    // this.settings.displaySettings.nextProperty('travelColorsRangeName', value)

    const customColors = await this.settings.customTravelColorsUpdates.take(1).toPromise()
    this.settings.displaySettings.nextWithCurrent((current) => {
      const option = this.colorRanges.find((item) => item.key === value)
      const optionColors: string[] = option.value

      if (option.key === 'custom') {
        current.travelColors = [].concat(customColors)
      } else {
        current.travelColors = option.value
      }

      current.travelColorsRangeName = option.key

      return current
    })
  }

  async editCustomTravelColors() {
    const currentColors = await this.settings.customTravelColorsUpdates.take(1).toPromise()
    const dialogRef = this.dialog.open(CustomTravelColorDialogComponent, {
      disableClose: false,
      data: { colors: currentColors },
    })

    const result: any = await dialogRef.afterClosed().toPromise()
    if (result) {
      this.settings.displaySettings.nextProperty('customTravelColors', [].concat(result))
    }
  }
}
