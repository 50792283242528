import { Component, ElementRef, Input, OnInit } from '@angular/core'
import { getBasePath } from '@targomo/client'

@Component({
  selector: 'option-image',
  templateUrl: './optionImage.component.html',
  styleUrls: ['./optionImage.component.less'],
})
export class OptionImageComponent implements OnInit {
  @Input() image: string = 'options_travel_overlapping.png'
  readonly basePath = getBasePath()

  constructor(readonly element: ElementRef) {}

  ngOnInit() {
    this.element.nativeElement.style.backgroundImage = `url('${this.basePath}/assets/images/${this.image}')`
  }
}
