import { Indicators } from '@targomo/client'
import { array as arrays, object as objects } from '@targomo/common'
import { TravelTypeEdgeWeightOptions } from '@targomo/core'
import { combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { AllStatistics } from '../../../common/models/statistics/statistics'
import { canonicalPositions, Place } from '../../api/place'
import { StatisticsEndpoint } from '../../api/statistics'
import { AppModel } from '../appModel.service'
import {
  STATISTICS_BENCHMARKS_CHILANGO,
  STATISTICS_BENCHMARKS_CHILANGO_WORKFORCE,
  STATISTICS_GROUP,
  STATISTICS_GROUP_WORKFORCE,
} from '../constants'
import { BENCHMARK_SOURCES_THRESHOLD } from '../placesModel'
import { AbstractBenchmarkReport } from './abstractBenchmarkReport'

const COLUMNS: { key: string; label: string }[] = [
  { key: 'fascia', label: 'Name' },
  { key: 'street', label: 'Street' },
  { key: 'town', label: 'Town' },
  { key: 'postcode', label: 'Postcode' },
  { key: 'value0', label: 'All Pop' },
  { key: 'value1', label: 'All HH' },
  { key: 'value2', label: 'Target HH' },
  { key: 'value3', label: 'Students HH' },
  { key: 'value4', label: 'All (Workforce)' },
  { key: 'value5', label: 'Target (Workforce)' },
  { key: 'value6', label: 'All (Spend)' },
  { key: 'value7', label: 'Target (Spend)' },
  { key: 'value8', label: 'Complementary' },
  { key: 'value9', label: 'Competitor' },
]

const FILENAME = 'benchmark_chilango'

export class BenchmarkChilangoModel extends AbstractBenchmarkReport<any> {
  constructor(
    protected appModel: AppModel,
    protected indicators: Indicators,
    protected statisticsEndpoint: StatisticsEndpoint
  ) {
    super(COLUMNS, FILENAME, appModel, indicators, statisticsEndpoint)
  }

  protected init() {
    return combineLatest(
      this.appModel.places.sources.observable,
      this.appModel.places.dataSets,
      this.appModel.places.places.value,
      this.appModel.settings.travelOptionsUpdates
    ).pipe(
      map(async ([sources, dataSets, places, options]) => {
        if (sources && sources.length > BENCHMARK_SOURCES_THRESHOLD) {
          return null
        }

        function findPlaces(name: string) {
          name = name.toUpperCase()
          const dataset = dataSets.filter((dataset) => dataset.name.toUpperCase() == name)[0]
          return places.filter((place) => place.dataSetId == dataset.versionId)
        }

        const competitors = findPlaces('Chilango Direct Competitors')
        const complementary = findPlaces('Chilango Complementary')
        var locations = competitors.filter((place) => place.fascia === 'CHILANGO')

        locations = [].concat(sources).concat(locations)

        return this.loadStatistics(locations, competitors, complementary, options)
      })
    )
  }

  private async loadStatistics(
    locations: Place[],
    competitors: Place[],
    complementary: Place[],
    options: TravelTypeEdgeWeightOptions
  ) {
    const countWithinReach = (sources: Place[], places: Place[]) => {
      // const requestOptions = travelOptions.forTimes({sources: sources, travelSpeed: options.travelSpeed, travelType: options.travelType, travelTime: options.travelTime})
      // requestOptions.setMaxRoutingTime(options.travelTime)
      // return api.timesCount(requestOptions, places)
      return this.appModel.settings.client.reachability.count(
        canonicalPositions(sources),
        canonicalPositions(places),
        options
      )
    }

    const load = (locations: Place[]) => {
      return arrays.asyncMap(locations, async (location) => {
        const sources = [location]

        // const requestOptions = travelOptions.forTimes({sources: sources, travelSpeed: options.travelSpeed, travelType: options.travelType, travelTime: options.travelTime})
        // requestOptions.setMaxRoutingTime(options.maxEdgeWeight)

        // requestOptions.travelTime = options.maxEdgeWeight

        const results = await Promise.all([
          this.indicators.add(
            this.appModel.settings.client.statistics.combined(canonicalPositions(sources), {
              statistics: STATISTICS_BENCHMARKS_CHILANGO,
              statisticsGroup: STATISTICS_GROUP,
              ...options,
            })
          ),
          this.indicators.add(
            this.appModel.settings.client.statistics.combined(canonicalPositions(sources), {
              statistics: STATISTICS_BENCHMARKS_CHILANGO_WORKFORCE,
              statisticsGroup: STATISTICS_GROUP_WORKFORCE,
              ...options,
            })
          ),
        ])

        const data = new AllStatistics(
          objects.assign({}, results[0], results[1]),
          (await this.appModel.publicSettings).gbpToEuroRate
        )
        // const data = new AllStatistics(await this.indicators.add(api.statistics(sources, STATISTICS_BENCHMARKS_CHILANGO, STATISTICS_GROUP, options)))

        return Promise.all([countWithinReach(sources, competitors), countWithinReach(sources, complementary)]).then(
          ([comperitorCount, complementaryCount]) => {
            const value0 = data.census.population2011.population.total
            const value1 = data.lifestyle.personicxSummary.total
            const value2 =
              data.lifestyle.personicxSummary.a[0].total +
              data.lifestyle.personicxSummary.a[1].total +
              data.lifestyle.personicxSummary.a[2].total +
              data.lifestyle.personicxSummary.a[3].total +
              data.lifestyle.personicxSummary.a[4].total +
              data.lifestyle.personicxSummary.c[0].total
            const value3: any = data.census.occupationsCensus.students.total
            const value4 = data.census.allWorkforce.total
            const value5 = data.census.workerAges.aged16to24.total + data.census.workerAges.aged25to34.total
            const value6 = data.lifestyle.spend.eatingOut.total
            const value7: any =
              data.lifestyle.social.eatingOut.a[0].total +
              data.lifestyle.social.eatingOut.a[1].total +
              data.lifestyle.social.eatingOut.a[2].total +
              data.lifestyle.social.eatingOut.a[4].total +
              data.lifestyle.social.eatingOut.c[0].total // null //eating out c1 /c2/ c2
            const value8 = complementaryCount
            const value9 = comperitorCount

            return {
              statistics: data,
              id: location.id,
              fascia: location.fascia,
              sitename: location.sitename,
              street: location.street,
              town: location.town,
              postcode: location.postcode,
              category: location.category,
              value0,
              value1,
              value2,
              value3,
              value4,
              value5,
              value6,
              value7,
              value8,
              value9,
            }
          }
        )
      })
    }

    return arrays.combine(await Promise.all(arrays.splitBy(locations, 4).map(load)))
    // setTimeout(async () => {
    //   this.loading = Promise.all(arrays.splitBy(locations, 4).map(load))
    //   try {
    //     this.locations = arrays.combine(await this.loading)
    //     // this.updateSort()
    //     this.completeResolve()
    //   } catch (e) {
    //     console.log("ERROR locations", e)
    //   }
    // })
  }
}
