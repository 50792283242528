<div class="message-page" *ngIf="visible">
  <div class="spacer"></div>

  <div fxLayout="row">
    <div fxFlex></div>
    <section class="message-popup-panel">
      <div class="box-title" fxLayout="row" fxLayoutAlign="start center">
        <h3 fxFlex>StorePointGeo Message Service <span class="count" *ngIf="messages?.length > 1">({{index + 1}} of {{messages?.length}})</span></h3>
        <mat-icon mat-font-set="material-icons">mail_outline</mat-icon>
      </div>
      <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
        <div fxFlex class="content">
          <div class="title">{{message?.title}}</div>

          <div *ngIf="!!message?.image" class="image"><img [src]="message.image"></div>
          

          <div *ngIf="!message?.html" class="body">{{message?.body}}</div>
          <div *ngIf="!!message?.html" class="body" [innerHTML]="message?.html"></div>
        </div>

        <div class="footer" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
            <button fxFlex="50%" mat-flat-button color="primary" (click)="closeRequest()" ><span >Request Details</span></button>
            <button fxFlex="50%" mat-flat-button color="primary" (click)="close()">
              <span *ngIf="nextButton">Next message</span>
              <span *ngIf="!nextButton">Proceed to StorePointGeo</span>
            </button>
        </div>
      </div>
    </section>
  </div>
</div>