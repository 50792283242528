import { SettingsModel } from '../../model/settingsModel'
import { PDFHelper, colorSquareGeneral } from './helper'
import { TRAVEL_COLORS } from '@targomo/client'
const Color = require('color')

const TABLE_OPTIONS = {
  border: '#ffffff',
  background: ['#ffffff', null],
  widths: () => ['auto', '*'],
  layout: {
    hLineWidth: function (i: number, node: any) {
      return 0
    },
    vLineWidth: function (i: number, node: any) {
      return 0
    },
    hLineColor: function (i: number, node: any) {
      return '#ffffff'
    },
    vLineColor: function (i: number, node: any) {
      return '#ffffff'
    },

    fillColor: function (i: number, node: any) {
      return '#ffffff'
    },
  },
}

export async function travelTimesLegend(settings: SettingsModel) {
  const helper = new PDFHelper(null)
  const travelMinutesGrouping = await settings.travelMinutesGroupingUpdates.take(1).toPromise()
  const travelOptions = await settings.travelOptionsUpdates.take(1).toPromise()

  const travelTimesOriginal = TRAVEL_COLORS.map((color, i) => {
    return {
      color: color,
      index: i,
      time: (i + 1) * 300 * travelMinutesGrouping.minuteLabelFactor,
      text: `${i * 5 * travelMinutesGrouping.minuteLabelFactor} to ${
        (i + 1) * travelMinutesGrouping.minuteLabelFactor * 5
      } minutes`,
    }
  })

  // const name = markerSizeValues.filter(item => item.key == settings.markerSizeProperty)[0].name
  const colors = (await settings.edgeWeightsColorsUpdates.take(1).toPromise()).map((row) => Color(row.color).hex())

  const rows: any[] = travelTimesOriginal
    .filter((row) => row.time <= travelOptions.maxEdgeWeight)
    .map((travelTime, i) => {
      return [
        // styleColorColumn(colorSquareGeneral(travelTime.color, 15, 10)),
        helper.styleColorColumn(colorSquareGeneral(colors[i], 15, 10)),
        { text: travelTime.text, style: 'defaultCellLeft2' },
      ]
    })

  return {
    style: 'legendSection',
    stack: [{ text: 'Travel Times:', style: 'headerMap' }, helper.table(null, rows, 'defaultTable', TABLE_OPTIONS)],
  }
}
