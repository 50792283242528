
import {AppModel} from '../../model/appModel.service'
import {Indicators, getBasePath} from '@targomo/client'
import { Component, EventEmitter, Output, NgZone } from '@angular/core';
import { DataSet } from "../../api/index";
import {Subscription} from 'rxjs/Subscription';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {files as filesUtil} from '@targomo/client'
import * as importLocation from '@targomo/common'
import { DataSetLocation, CustomLocation } from '../../model/index';
import { Router } from '@angular/router';
import * as importLayer from '../../../common/util/importLayer'

@Component({
  selector: 'upload-layer',
  templateUrl: './uploadLayer.html',
  styleUrls: ['./uploadLayer.less']
})
export class UploadLayerComponent {
  @Output() close = new EventEmitter()
  private subscriptions: Subscription[] = []

  basePath = getBasePath()

  form: FormGroup
  locations: DataSetLocation[]
  locationError: boolean = false
  locationsOutside = 0
  submitted = false

  constructor(
    private formBuilder: FormBuilder,
    private indicators: Indicators,
    readonly appModel: AppModel,
    readonly router: Router,
    private zone: NgZone
  ) {
    this.form = this.formBuilder.group({
      'name': [null, [Validators.required]],
    })
  }

  submit(event?: any) {
    this.submitted = true

    if (this.form.valid && !this.locationError) {
      this.appModel.places.addCustomLayer(this.form.value.name, this.locations)
      this.router.navigate(['/'])
    }
  }

  async selectFile(files: File[]) {
    if (!files || !files.length) {
      this.locations = null
      return
    }

    function createLocation(lng: number, lat: number, properties: any) {
      const result = new CustomLocation({properties: properties})
      result.lat = lat
      result.lng = lng
      return result
    }

    this.locationError = false
    try {
      this.locations = null

      const result = <string>await filesUtil.readFile(files[0])

      // Note....just in case IE, otherwise String.endsWith would do just fine
      const endsWith = (value: string, suffix: string) => {
        return value.lastIndexOf(suffix) === (value.length - suffix.length)
      }

      let locationsResult: any[] = []
      if (files[0].type === 'text/csv' || endsWith(files[0].name, '.csv')) {
        await new Promise((resolve, reject) => {
          this.zone.runOutsideAngular(async () =>  {
            try {
              await importLayer.parse(result, async items => {
                locationsResult = locationsResult.concat(items)
                // total += items.length

                // items.forEach(item => {
                //   if (item['holdingCompany']) {
                //     this.holdingCompanyCount++
                //   }

                //   if (item['secondaryCategory']) {
                //     this.secondaryCategoryCount++
                //   }
                // })

              }, 10000)

              // await waitTimeout(20)
              resolve()
            } catch (e) {
              reject(e)
            }
          })
        })

        locationsResult.forEach(location => {
          location.properties = {...location}
        })
        this.locations = locationsResult
      } else {
        this.locations = importLocation.fromGeoJSON(result, createLocation)
      }

      this.locations = this.locations.map(value => new CustomLocation(value))

      const beforeFilterLocations = this.locations
      const isAllowed = await this.appModel.places.getLatLngAllowedFunction()
      this.locations = this.locations.filter(isAllowed)
      this.locationsOutside = beforeFilterLocations.length - this.locations.length

    } catch (e) {
      this.locationError = true
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}