<div fxLayout="row">
  <nav>
    <header>
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>settings</mat-icon>
        Settings
      </div>
    </header>
    <main>
      <ng-container *ngFor="let option of navbarSections">
        <ng-container *ngIf="!option.permission || (permissions && permissions[option.permission])">
          <div (click)="clickNav(option)" [ngClass]="option.classes" [class.selected]="option.key == selected.key">
            {{ option.name }}
          </div>
        </ng-container>
      </ng-container>
    </main>
  </nav>

  <div class="divider"></div>

  <main>
    <header>
      <div fxLayout="row" fxLayoutAlign="start center">
        {{ selected.parent }}
        <mat-icon *ngIf="selected.parent">arrow_forward_ios</mat-icon>
        {{ selected.name }}
      </div>

      <div class="close-panel">
        <button mat-icon-button #closeButton (click)="close()">
          <mat-icon mat-font-set="material-icons">close</mat-icon>
        </button>
      </div>
    </header>

    <main>
      <ng-container [ngSwitch]="selected.key">
        <ng-container *ngSwitchCase="'map_style'">
          <map-style-options-panel></map-style-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'custom_label_options'">
          <label-options-panel></label-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'map_filters'">
          <map-filters-options-panel></map-filters-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'map_filters_primary'">
          <primary-filters-panel></primary-filters-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'map_filters_secondary'">
          <secondary-filters-panel></secondary-filters-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'max_travel_time'">
          <travel-times-options-panel></travel-times-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'travel_time_colours'">
          <travel-colors-options-panel></travel-colors-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'point_colours'">
          <point-colors-options-panel></point-colors-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'travel_time_method'">
          <travel-method-options-panel></travel-method-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'thematics'">
          <travel-thematics-options-panel></travel-thematics-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'zones'">
          <zones-options-panel></zones-options-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'locations_present'">
          <locations-present-options-panel></locations-present-options-panel>
        </ng-container>
      </ng-container>
    </main>
  </main>
</div>
