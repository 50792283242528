import { Observable } from 'rxjs';

export function awaitAll() {
  return function<T>(source: Observable<Promise<T>[]>): Observable<T[]> {
    let current: Promise<T>[] = undefined
    return new Observable(subscriber => {
      source.subscribe({
        async next(value) {
          current = value
          const ready = value.map(item => undefined)

          value.forEach(async (promise, i) => {
            if (current !== value) {
              return
            }

            try {
              await promise
            } catch (e) {
              console.error(e)
            }

            ready[i] = promise
            subscriber.next(ready)
          })
        },
        error(error) {
          subscriber.error(error)
        },
        complete() {
          subscriber.complete()
        }
      })
    })
  }
}
