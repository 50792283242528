import { Indicators } from '@targomo/client'
import { array as arrays, object as objects } from '@targomo/common'
import { TravelTypeEdgeWeightOptions } from '@targomo/core'
import { combineLatest } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { AllStatistics } from '../../../common/models/statistics/statistics'
import { canonicalPositions, Place } from '../../api/place'
import { StatisticsEndpoint } from '../../api/statistics'
import { AppModel } from '../appModel.service'
import { STATISTICS_BENCHMARKS_PLANET_ORGANIC, STATISTICS_BENCHMARKS_PLANET_ORGANIC_WORKFORCE, STATISTICS_GROUP, STATISTICS_GROUP_WORKFORCE } from '../constants'
import { BENCHMARK_SOURCES_THRESHOLD } from '../placesModel'
import { AbstractBenchmarkReport } from './abstractBenchmarkReport'

const COLUMNS: {key: string, label: string}[] = [
  {key: "fascia", label: "Name"},
  {key: "street", label: "Street"},
  {key: "town", label: "Town"},
  {key: "postcode", label: "Postcode"},
/*    {key: "value1", label: "Ped Flow"},
  {key: "value5", label: "All"},
  {key: "value6", label: "16-34"},
  {key: "value7", label: "AB"},
  {key: "value9", label: "Competitor"},*/

  {key: "value1", label: "Population"},
  {key: "valueHouseholds", label: "Households"},
  {key: "value2", label: "Target Households"},
  {key: "value3", label: "All Workforce"},
  {key: "value4", label: "AB Workforce"},
  {key: "value5", label: "Workforce 25-44"},

  {key: "value6", label: "Competitors (Food to Go)"},
  {key: "value7", label: "Competitors (Health & Beauty)"},
  {key: "value8", label: "Competitors (Supermarkets)"},
  {key: "value9", label: "Competitors (Direct)"},
]

const FILENAME = 'benchmark_planet_organic'

export class BenchmarkPlanetOrganicModel extends AbstractBenchmarkReport<any> {
  constructor(
    protected appModel: AppModel,
    protected indicators: Indicators,
    protected statisticsEndpoint: StatisticsEndpoint,
  ) {
    super(COLUMNS, FILENAME, appModel, indicators, statisticsEndpoint)
  }

  protected init() {
    return combineLatest(
      this.appModel.places.sources.observable,
      this.appModel.places.dataSets,
      this.appModel.places.places.value,
      this.appModel.settings.travelOptionsUpdates,
    ).pipe(
      map(async ([sources, dataSets, places, options]) => {
        if (sources && sources.length > BENCHMARK_SOURCES_THRESHOLD) {
          return null
        }
                
        function findPlaces(name: string) {
          name = name.toUpperCase()
          const dataset = dataSets.filter(dataset => dataset.name.toUpperCase() === name)[0]
          return places.filter(place => place.dataSetId == dataset.versionId)
        }

        const dataSet = findPlaces('Planet Organic Competitors')
        const ourDataSet = dataSet // findPlaces('Food Stores')

        const competitors = dataSet.filter(place => place.fascia !== 'PLANET ORGANIC')
        const complementary: Place[] = []
        var locations = ourDataSet.filter(place => place.fascia === 'PLANET ORGANIC')

        locations = [].concat(sources).concat(locations)

        return this.loadStatistics(locations, competitors, complementary, options)
      })
    )
  }

  private async loadStatistics(locations: Place[], competitors: Place[], complementary: Place[], options: TravelTypeEdgeWeightOptions) {
    const countWithinReach = async (sources: Place[], places: Place[]) => {
      if (sources.length == 0 || places.length == 0) {
        return  {
          ftg: 0,
          hbc: 0,
          supermarket: 0,
          direct: 0,
        }
      }

      const result = await this.appModel.places.rechabilityLocations(canonicalPositions(sources), canonicalPositions(places), options)
      const counts = {
        ftg: result.filter(place => place.category.grouping.toUpperCase() === 'FTG').length,
        hbc: result.filter(place => place.category.grouping.toUpperCase() === 'HBC').length,
        supermarket: result.filter(place => place.category.grouping.toUpperCase() === 'SUPERMARKET').length,
        direct: result.filter(place => place.category.grouping.toUpperCase() === 'DIRECT').length,
      }

      return counts
    }

    // const pedestrianFlow = await this.statisticsEndpoint.pedestrianFlow(locations)

    const load = (locations: Place[]) => {
      return arrays.asyncMap(locations, async location => {
        const sources = [location]

        // const requestOptions = travelOptions.forTimes({sources: sources, travelSpeed: options.travelSpeed, travelType: options.travelType, travelTime: options.travelTime})
        // requestOptions.setMaxRoutingTime(options.maxEdgeWeight)

        // requestOptions.travelTime = options.maxEdgeWeight

        const results = await Promise.all([
          this.indicators.add(this.appModel.settings.client.statistics.combined(canonicalPositions(sources), {statistics: STATISTICS_BENCHMARKS_PLANET_ORGANIC, statisticsGroup: STATISTICS_GROUP, ...options})),
          this.indicators.add(this.appModel.settings.client.statistics.combined(canonicalPositions(sources), {statistics: STATISTICS_BENCHMARKS_PLANET_ORGANIC_WORKFORCE, statisticsGroup: STATISTICS_GROUP_WORKFORCE, ...options})),
        ])

        const data = new AllStatistics(objects.assign({}, results[0], results[1]), (await this.appModel.publicSettings).gbpToEuroRate)
        // const data = new AllStatistics(await this.indicators.add(api.statistics(sources, STATISTICS_BENCHMARKS_CHILANGO, STATISTICS_GROUP, options)))

        return Promise.all([countWithinReach(sources, competitors), countWithinReach(sources, complementary)])
        .then(([comperitorCount, complementaryCount]) => {

          const value1 = data.census.population2011.population.total
          const valueHouseholds = data.census.population2011.households.total
          const value2 = data.lifestyle.social.households.a[0].total + data.lifestyle.social.households.b[0].total + data.lifestyle.social.households.c[0].total //data.census.population2011.households.total
          const value3: any = data.census.allWorkforce.total
          const value4 = data.census.socialClassWorkforce.ab.total
          // const value5 = data.census.allWorkforce.total
          const value5 = data.census.workerAges.aged25to34.total + data.census.workerAges.aged35to44.total
          // const value7 = data.census.socialClassWorkforce.ab.total
          // const value8 = complementaryCount
          // const value9 = comperitorCount

          const value6 = comperitorCount.ftg
          const value7 = comperitorCount.hbc
          const value8 = comperitorCount.supermarket
          const value9 = comperitorCount.direct

          return {
            statistics: data,
            id: location.id,
            fascia: location.fascia,
            sitename: location.sitename,
            street: location.street,
            town: location.town,
            postcode: location.postcode,
            category: location.category,
            valueHouseholds,
            value1,
            value2,
            value3,
            value4,
            value5,

            value6,
            value7,
            value8,
            value9,
          }
        })
      })
    }


    return arrays.combine(await Promise.all(arrays.splitBy(locations, 4).map(load)))
    // setTimeout(async () => {
    //   this.loading = Promise.all(arrays.splitBy(locations, 4).map(load))
    //   try {
    //     this.locations = arrays.combine(await this.loading)
    //     // this.updateSort()
    //     this.completeResolve()
    //   } catch (e) {
    //     console.log("ERROR locations", e)
    //   }
    // })
  }
}