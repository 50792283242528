import {ReportSegment} from './reportSegments'
import {CensusStatisticsGroup} from '../../../../common/models/statistics/statistics';

export interface IndexReportSegmentChildrenValues {
    id: string
    name: string
    value: number
    percent?: number
    perHousehold?: number
    indexVsGBAverage?: number
    gbAverage?: number
    indexVsRegionAverage?: number
    regionAverage?: number
}

class IndexReportSegmentValues {
  id: string
  name: string
  skipTotal?: boolean
  skipPercent?: boolean
  lifestyleSummary?: boolean
  showPerHousehold?: boolean
  special?: boolean

  total?: number

  children?: IndexReportSegmentChildrenValues[]
}

function number(value: any, positions = 0) {
  return +(+(value || 0)).toFixed(positions)
}

export class IndexReportModel {
  public readonly values: IndexReportSegmentValues[]

  constructor(private source: CensusStatisticsGroup, private shapes: ReportSegment[], private totalHouseholds: number, private region: string) {
    this.values = this.shapes.map(shape => {
      var group = new IndexReportSegmentValues()

      group.id = shape.id
      group.name = shape.name
      group.skipTotal = shape.skipTotal
      group.skipPercent = shape.skipPercent
      group.lifestyleSummary = shape.lifestyleSummary
      group.showPerHousehold = shape.showPerHousehold
      group.special = shape.special

      if (shape.children) {
        group.children = this.calculateChildren(this.source, shape)
      }

      return group
    })
  }

  private calculateChildren(source: any, shape: ReportSegment) {
  // private calculateChildren(source: CensusStatisticsGroup, shape: ReportSegment) {
    return shape.children.filter(child => !child.parent).map(child => {
      var value   = source[shape.id][child.id].total
      var percent = source[shape.id][child.id].percent
      var perHousehold = (source[shape.id][child.id].total / this.totalHouseholds) || 0
      var index   = (source[shape.id][child.id].percent * 100 / source[shape.id][child.id].index) || 0

      const result: IndexReportSegmentChildrenValues = {
        id: child.id,
        name: child.name,
        value: number(value),
      }

      if (!shape.skipPercent) {
        result.percent = number(percent, 2)
      }

      if (shape.showPerHousehold) {
        result.perHousehold = number(perHousehold)

      } else if (!shape.skipPercent || shape.showPerHousehold) {
        const indexValue = number(index, 2)
        if (this.region == 'GB' || this.region == 'ROI') {
          result.indexVsGBAverage = indexValue
        } else {
          result.indexVsRegionAverage = indexValue
        }
      }

      if (!shape.skipPercent || shape.showPerHousehold) {
        const indexValue = number(source[shape.id][child.id].index, 2)
        if (this.region == 'GB' || this.region == 'ROI') {
          result.gbAverage = indexValue
        } else {
          result.regionAverage = indexValue
        }
      }

      return result
    })
  }
}