import { Component, NgZone } from '@angular/core'
import { SubscriberComponent, Indicators, files } from '@targomo/client'
import { OnInit, OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks'
import { AppModel } from '../../../model/appModel.service'
import { RiskAssessmentEndpoint } from '../../../api/riskAssessment'
import { switchMap, debounceTime } from 'rxjs/operators'
import { Place, PlanningApplicationDataSetEndpoint, PlaceEndpoint } from '../../../api'
import { HomeComponent } from '../../../main/home.component'
import { PlanningApplication } from '../../../../common/models'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { array as arrays } from '@targomo/common'
import { LayerStateStack } from '../../../model/layerStateStack.service'
import { AbstractLocation, EXPORTABLE_PROPERTIES } from '../../../model'
import { MatSelectChange } from '@angular/material'
import { csv } from '../../../util/csv'

interface RiskListLocation {
  id: any
  value: number
  name: string
  location: AbstractLocation
}

@Component({
  selector: 'traffic-light-panel',
  styleUrls: ['./trafficLightPanel.component.less'],
  templateUrl: `./trafficLightPanel.component.html`,
})
export class TrafficLightPanelComponent extends SubscriberComponent implements OnInit, OnDestroy {
  locations: { id: number; name: string; value: number; location: Place }[] = null
  planningLocations: PlanningApplication[]
  loading: Promise<any> = null
  hoverPlace: Place
  selectedPlace: Place
  // time = 1

  private timeSubject: Observable<number>
  // private selectedPlaceSubject = new BehaviorSubject<Place>(null)
  selectedDetailsPlaceSubject = new BehaviorSubject<Place | PlanningApplication>(null)

  timeOptions = [
    { name: '1 month', value: 1 },
    { name: '2 months', value: 2 },
    { name: '3 months', value: 3 },
    { name: '4 months', value: 4 },
    { name: '5 months', value: 5 },
    { name: '6 months', value: 6 },
    { name: '9 months', value: 9 },
    { name: '12 months', value: 12 },
    { name: '24 months', value: 24 },
  ]

  constructor(
    private riskAssessmentEndpoint: RiskAssessmentEndpoint,
    private planningEndpoint: PlanningApplicationDataSetEndpoint,
    private indicators: Indicators,
    private appModel: AppModel,
    private homeComponent: HomeComponent,
    private placeEndpoint: PlaceEndpoint,
    private zone: NgZone,
    private layerStateStack: LayerStateStack
  ) {
    super()

    this.timeSubject = this.appModel.settings.trafficLightMonthsUpdates
  }

  // ngOnDestroy() {
  //   this.appModel.places.planningApplicationsPlaces.next(null)
  //   this.appModel.places.selectedPlanningPlace.next(null)
  //   this.appModel.places.selectedPlaceAlt.next(null)

  //   super.ngOnDestroy()
  // }

  async ngOnInit() {
    this.watch(this.appModel.places.hoverPlace, (place) => {
      if (this.hoverPlace !== place) {
        this.zone.run(() => (this.hoverPlace = place))
      }
    })

    const riskLocations = combineLatest(
      this.appModel.places.filteredPlaces.value,
      this.timeSubject,
      this.appModel.settings.travelOptionsUpdates
    ).pipe(
      debounceTime(100),
      switchMap(async ([places, time, travelOptions]) => {
        const loading = this.indicators.add(
          this.riskAssessmentEndpoint.report(time, travelOptions.travelType, travelOptions.maxEdgeWeight)
        )
        this.loading = loading
        return { places, values: await loading }
      }),
      switchMap(async (reportData) => {
        const { places, values } = reportData

        const result = places
          .filter((place) => values[place.id])
          .map((place) => {
            return {
              id: place.id,
              value: values[place.id].value,
              name: place.fascia,
              town: place.town,
              postcode: place.postcode,
              location: place,
            }
          })

        return arrays.sortBy(result, 'value', true)
      })
    )

    this.watch(riskLocations, (locations) => {
      this.locations = locations
    })

    this.watch(this.appModel.places.selectedPlacePlanningRisk, (location) => {
      this.selectedPlace = location
    })

    const detailsReportObservable = combineLatest(
      this.appModel.places.selectedPlacePlanningRisk,
      this.timeSubject,
      this.appModel.settings.travelOptionsUpdates
    ).pipe(
      debounceTime(100),
      switchMap(async ([place, time, travelOptions]) => {
        if (!place) {
          return null
        }

        return await this.indicators.add(
          this.riskAssessmentEndpoint.reportSingle(time, travelOptions.travelType, travelOptions.maxEdgeWeight, place)
        )
      })
    )

    this.watch(detailsReportObservable, (report: any[]) => {
      this.planningLocations = report
      this.appModel.places.planningApplicationsPlaces.next(
        report &&
          report.map((item: any) => {
            return {
              id: item.id,
              lat: item.lat,
              lng: item.lng,
              properties: {
                name: item.fascia,
              },

              toString() {
                return item.fascia
              },
            }
          })
      )
    })

    this.watch(this.appModel.places.selectedPlanningPlace, async (item) => {
      if (item) {
        if (item.type === 'location') {
          const place = await this.indicators.add(this.placeEndpoint.get(item.location))
          this.selectedDetailsPlaceSubject.next(<Place>place)
        } else {
          const place = await this.indicators.add(this.planningEndpoint.getApplication(item.location))
          this.selectedDetailsPlaceSubject.next(place)
        }
      }
    })
  }

  hover(item: RiskListLocation) {
    this.zone.runOutsideAngular(async () => {
      this.appModel.places.hoverPlace.next(item && item.location)
    })
  }

  hoverPlanning(item: PlanningApplication) {
    this.zone.runOutsideAngular(async () => {
      this.appModel.places.hoverPlanningPlace.next(item)
    })
  }

  async click(item: RiskListLocation) {
    if (item) {
      this.homeComponent.mapView.setView(item.location)
      this.appModel.places.selectedPlacePlanningRisk.next(item.location)
      this.layerStateStack.push()
    }
  }

  async clickPlanning(item: PlanningApplication) {
    if (item) {
      this.appModel.places.selectedPlanningPlace.next({ type: 'planning', location: item })
    }
  }

  back() {
    this.selectedPlace = null
    this.layerStateStack.pop()
    this.appModel.places.planningApplicationsPlaces.next(null)
    this.appModel.places.selectedPlacePlanningRisk.next(null)
  }

  backDetails() {
    this.selectedDetailsPlaceSubject.next(null)
    // this.appModel.places.selectedPlanningPlace.next(null)
    // this.appModel.places.selectedPlacePlanningRisk.next(null)
  }

  export() {
    const properties = EXPORTABLE_PROPERTIES
    // [
    //   'storeId',
    //   'lat',
    //   'lng',
    //   'primaryCategory',
    //   'secondaryCategory',
    //   'holdingCompany',
    //   'fascia',
    //   'sitename',
    //   'paon',
    //   'saon',
    //   'taon',
    //   'street',
    //   'suburb',
    //   'town',
    //   'postcode',
    //   'district',
    //   'county',
    //   'region',
    //   'netSalesArea',
    //   'netSalesAreaSource',
    //   'grossInternalArea',
    //   'grossInternalAreaSource',
    // ]

    const exported = csv(
      this.locations.map((item) => {
        const result: any = { reachablePlanningApplcations: item.value }
        properties.forEach((key) => {
          result[key] = (<any>item.location)[key]
        })

        return result
      })
    )

    files.saveFile(exported, 'text/csv', 'storepointgeo_risk_assessment.csv')
  }

  exportPlanning() {
    files.saveFile(
      csv(
        this.planningLocations.map((item) => {
          const { id, ...data } = item
          return data
        })
      ),
      'text/csv',
      'storepointgeo_risk_assessment_details_' + this.selectedPlace.fascia + '_' + this.selectedPlace.storeId + '.csv'
    )
  }

  changeTime(time: MatSelectChange) {
    this.appModel.displaySettings.nextProperty('trafficLightMonths', time.value)
  }
}
