import { ReportModel } from './exportPdfReport'
import { PDFHelper, TABLE_BACKGROUNDS, tableOptions } from './helper'
import { getCensusSegments, ReportSegment } from '../mini/model/reportSegments'
import { PDF_REPORT_DEMOGRAPHICS, PDF_REPORT_DEMOGRAPHICS_COLLIERS } from './values'
import { PdfReportSettings } from '../../model/settingsModel'

const INDICES = require('../../../common/models/statistics/indices_avg_households_prices.json')

export default function demographicsReport(
  reportModel: ReportModel,
  helper: PDFHelper,
  zonesVisible: boolean,
  reportSettings: PdfReportSettings,
  inputGroups?: typeof PDF_REPORT_DEMOGRAPHICS
) {
  function hasSection(key: string) {
    return reportSettings && (reportSettings.sections || []).indexOf(key) > -1
  }

  const rows: any[] = []

  const createHeads = () => {
    const head: any[] = [{ text: '', group: true, border: [false, false, false, false] }]
    const headMinutes: any[] = [{ text: '', border: [false, false, false, false] }]
    const headTotals: any[] = [
      { image: helper.icons.people, width: 16, style: 'headerIcon2', border: [false, false, false, false] },
    ]
    const headTotals2: any[] = [
      { image: helper.icons.home, width: 16, style: 'headerIcon2', border: [false, false, false, false] },
    ]

    reportModel.forEach((report) => {
      head.push({ text: 'Total', style: 'defaultCellLeft', group: true, total: true })
      head.push('%')
      head.push({ text: 'Index vs ' + report.statistics.regionLabel() + ' Avg', style: 'defaultCellRight' })

      headTotals.push({
        text: helper.formatCell(report.statistics.census.population2011.population.total),
        style: 'defaultCellCenter',
        colSpan: 3,
      })
      headTotals.push('')
      headTotals.push('')

      headTotals2.push({
        text: helper.formatCell(report.statistics.census.population2011.households.total),
        style: 'defaultCellCenter',
        colSpan: 3,
      })
      headTotals2.push('')
      headTotals2.push('')

      // const title =
      headMinutes.push({ text: report.nameFull, style: 'defaultCellCenter', colSpan: 3 })
      headMinutes.push('')
      headMinutes.push('')
    })

    head.push(reportModel[0].statistics.regionLabel() + ' Avg %')
    headTotals.push('')
    headTotals2.push('')
    headMinutes.push('')

    rows.push(headMinutes)
    rows.push(headTotals)
    rows.push(headTotals2)
    rows.push(head)
    // rows.push(subhead)
  }

  createHeads()

  inputGroups =
    inputGroups || (hasSection('colliersDemographics') ? PDF_REPORT_DEMOGRAPHICS_COLLIERS : PDF_REPORT_DEMOGRAPHICS)
  // const inputGroups = PDF_REPORT_DEMOGRAPHICS

  const censusGroups = inputGroups.map((group: ReportSegment) => {
    const originalGroup = getCensusSegments(reportModel[reportModel.length - 1].statistics).filter(
      (item: any) => item.id == group.id
    )[0]

    group.children = (<any>group).items || originalGroup.children
    return <ReportSegment>group
  })

  censusGroups.forEach((group) => {
    const calculateSection = () => {
      const row: any[] = [
        {
          text: group.name,
          ...(group.pageBreak ? { pageBreak: 'before' } : {}),
          group: true,
          style: 'defaultCellGroup',
        },
      ]

      reportModel.forEach((segment, i) => {
        row.push('')
        row.push('')
        row.push('')

        if (i == reportModel.length - 1) row.push('')
      })

      rows.push(row)
    }

    calculateSection()

    group.children.forEach((segment: any) => {
      const row: any[] = [
        { text: segment.name, style: segment.intensity ? 'defaultCellRightBold' : 'defaultCellRight' },
      ]

      // Check if values exist in any of the report times
      let exists = false
      reportModel.forEach((report, i) => {
        const keys = (<any>segment).items || [segment.id]
        const statistic =
          group.id === 'spendSummary'
            ? report.statistics.lifestyle.spendSummary
            : group.id === 'spend'
            ? report.statistics.lifestyle.spend
            : group.id === 'agesDetailed'
            ? report.statistics.populationByYear
            : group.id === 'dwellingTypesAveragePrice'
            ? report.statistics.census.dwellingTypes().dwellingTypesAveragePrice
            : report.statistics.census[group.id] //[segment.id]

        var total = 0

        keys.forEach((id: any) => {
          total += statistic[id].total || 0
        })

        if (total) {
          exists = true
        }
      })

      // if not values found then skip
      if (!exists) {
        return
      }

      reportModel.forEach((report, i) => {
        const keys = (<any>segment).items || [segment.id]
        let statistic =
          group.id === 'spendSummary'
            ? report.statistics.lifestyle.spendSummary
            : group.id === 'spend'
            ? report.statistics.lifestyle.spend
            : group.id === 'agesDetailed'
            ? report.statistics.populationByYear
            : group.id === 'dwellingTypesAveragePrice'
            ? report.statistics.census.dwellingTypes().dwellingTypesAveragePrice
            : report.statistics.census[group.id] //[segment.id]

        if (group.id === 'dwellingTypesAveragePrice' || group.id === 'householsIncome') {
          statistic = statistic[keys[0]]

          let region = report.statistics.indicesRegion
          const indicesRegion = INDICES[region] || {}

          const total =
            group.id === 'householsIncome'
              ? statistic.total / report.statistics.census.population2011.households.total
              : statistic.total

          const percentOverIndex =
            group.id === 'householsIncome' ? (total / indicesRegion[keys[0]]) * 100 : statistic.percentOverIndex

          row[0] = ''

          row.push({
            text: helper.formatCell(total, 0, '', '£'),
            colSpan: 2,
            alignment: 'center',
            style: segment.intensity ? 'defaultCellBold' : 'defaultCell',
          })
          row.push('')
          row.push(helper.formatCell(percentOverIndex))

          if (i == reportModel.length - 1) row.push('')
        } else {
          const calculateValue = () => {
            var total = 0
            var percent = 0
            var index = 0

            keys.forEach((id: any) => {
              total += statistic[id].total || 0
              index += statistic[id].index || 0
              percent += statistic[id].percent || 0
            })

            return [total, total, percent, index]
          }

          let [value, total, percent, index] = calculateValue()

          row.push(helper.formatCell(total))
          row.push(helper.formatCell(percent, 1, ''))

          if (group.id === 'spend' || group.id === 'spendSummary') {
            index = index / 100
            row.push(helper.formatCell(statistic[keys[0]].percentOverIndex))
          } else {
            row.push(helper.formatCell((percent && index && (100 * percent) / index) || ''))
          }

          if (i == reportModel.length - 1) row.push(helper.formatCell(index, 1)) // TODO: should actully be regional average
        }
      })

      rows.push(
        row.map((item) => {
          if (typeof item === 'string') {
            return { text: item, style: segment.intensity ? 'defaultCellBold' : 'defaultCell' }
          }

          return item
        })
      )
    })
  })

  const head: any[] = [{ text: '', border: [false, false, false, false] }]
  reportModel.forEach((report, i) => {
    head.push('')
    head.push('')
    head.push('')
  })
  head.push('')

  const options = tableOptions(helper, reportModel, 5, 3)

  options.layout = { ...options.layout }
  ;(options.layout.vLineColor = function (i: number, node: any) {
    const segmentSize = 3
    const index = Math.ceil((i - 1) / segmentSize)
    if (index == 0 || index > reportModel.length) return helper.tableBorder(0)
    else return helper.tableBorder(1)

    // return TABLE_BORDERS[(~~(i - 1) / segmentSize)] || '#f0f0f0'
  }),
    (options.layout.vLineWidth = function (i: number, node: any) {
      if (i == 2 + 3 * reportModel.length) return 2

      const segmentSize = 3
      const firstInSegment = ~~(i % segmentSize) == 1
      if (firstInSegment) return 2
      else return 0
    })

  const t = helper.table(head, rows, 'defaultTable', options)

  if (hasSection('colliersDemographics')) {
    // just in case
    if (t[0] && t[0].table) {
      t[0].table.headerRows = 5
    }
  }

  return t
}
