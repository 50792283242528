import { Component } from '@angular/core';
import { DataProfile } from "../../api/index";
import {DataProfileEndpoint} from '../../api/dataProfile'
import {TgmQuickDialogs} from '@targomo/client';

@Component({
  selector: 'admin-dataprofiles-list',
  templateUrl: './adminDataProfilesList.html'
})
export class AdminDataProfilesListComponent {
  dataProfiles: DataProfile[]
  submitted = false

  constructor(private dataProfileEndpoint: DataProfileEndpoint, private quickDialogs: TgmQuickDialogs) {
  }

  ngOnInit() {
    this.updateList()
  }

  async updateList() {
    this.dataProfiles = await this.dataProfileEndpoint.findAll()
  }

  async removeProfile(profile: DataProfile) {
    if (await this.quickDialogs.confirm({
      title: 'Delete User',
      message: `Would you like to delete profile ${profile.name})?`,
      confirm: 'Delete',
      cancel: 'Cancel'
    })) {
      const result = await this.dataProfileEndpoint.delete(profile)
      this.updateList()
    }

    // var confirm = this.$mdDialog.confirm()
    //               .title(`Would you like to delete profile ${profile.name}?`)
    //               .ariaLabel('Delete Profile')
    //               .ok('Delete')
    //               .cancel('Cancel')

    // this.$mdDialog.show(confirm).then(() => {
    //   this.DataProfile.delete(profile).then(() => this.updateList())
    // }, () => {
    // })
  }

  showMenu(event: any) {
    event.stopPropagation()
  }
}