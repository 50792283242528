<section fxLayout="column" layout-align="center center" >
  <div>
    <div>
      <h1 class="mat-title">Reset Password</h1>
    </div>

    <div *ngIf="!!requestSent">
      Your password has been reset. Please <a routerLinkActive="active" routerLink="/login">login</a> to continue.
    </div>

    <div *ngIf="!requestSent">
      <form class="form" [formGroup]="form" (submit)="submit(form)" fxLayout="column" novalidate>
        <mat-form-field>
          <input matInput placeholder="Password" type="password" formControlName="password"/>

          <mat-hint *ngIf="form.submitted && form.get('password').hasError('required')" [ngStyle]="{'color': 'red'}">
            Password is required
          </mat-hint>

          <mat-hint *ngIf="form.submitted && form.get('password').hasError('minlength')" [ngStyle]="{'color': 'red'}">
            Password must be at least 3 characters long
          </mat-hint>

        </mat-form-field>

        <mat-form-field>
          <input placeholder="Confirm Password" matInput type="password" formControlName="confirmPassword"/>
          <mat-hint *ngIf="form.submitted && form.get('confirmPassword').hasError('areEqual')" [ngStyle]="{'color': 'red'}">
            Passwords must match
          </mat-hint>
        </mat-form-field>

        <div class="align-right main-button">
          <div style="margin-bottom: 6px"><tgm-saveable [model]="saving"></tgm-saveable></div>
          <button mat-flat-button color="primary" mat-button type="submit">Reset Password</button>
        </div>
      </form>
    </div>
  </div>
</section>
