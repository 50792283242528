import { AppModel } from '../../model/appModel.service'
import { Place } from '../../api/index'
import { Subscription } from 'rxjs/Subscription'

import * as colors from '@targomo/client'
import { Component, Input } from '@angular/core'
import { array as arrays } from '@targomo/common'
import { TRAVEL_COLORS } from '@targomo/client'
import { SubscriberComponent } from '@targomo/client'
import { Observable } from 'rxjs/Observable'
import { ZoneLayersModel } from '../../model/zoneLayersModel'
import { SourcesTitleFascia } from '../../model/sourcesTitleModel'
import { categorizeLocations, OperatorCategorized, OperatorPresent } from '../../model/operatorsWithinModel'
import { AbstractLocation } from '../../model/entities/place'
import {
  COMPETITION_THRESHOLD_COALESCE_LOCATIONS,
  COMPETITION_THRESHOLD_COALESCE_SOURCES,
} from '../../model/placesModel'

export const COLUMNS_BASIC = [
  { key: 'fascia', label: 'Name' },
  { key: 'street', label: 'Street' },
  { key: 'town', label: 'Town' },
  { key: 'postcode', label: 'Postcode' },
]

const COLUMNS_TRAVEL = [{ key: 'travelTime', label: 'Travel Time (mins)' }]

export const COLUMNS_AREA = [
  { key: 'grossInternalArea', label: 'GIA (sq ft)' },
  { key: 'netSalesArea', label: 'NSA (sq ft)' },
]

const COLUMNS_PLANNING_VALUE = [{ key: 'planningValue', label: 'Value' }]

export function groupByTravelBands(
  places: AbstractLocation[],
  zonesVisible: boolean,
  edgeWeights: colors.MaxEdgeWeightOption[]
) {
  const groups: Group[] = []
  const totals = new Group('', 'white')

  const minutesGroup = edgeWeights[0].value / 5
  const levelTitleFactor = minutesGroup / 60

  places.forEach((place) => {
    let level = Math.max(0, Math.floor((Math.ceil((place.travelTime || 0) / minutesGroup) - 1) / 5))

    if (zonesVisible) {
      level = 0
    }

    if (!groups[level]) {
      groups[level] = new Group(
        `${level * levelTitleFactor * 5} to ${(level + 1) * levelTitleFactor * 5} minutes`,
        edgeWeights[level].color
      )
    }

    groups[level].add(place)
    totals.add(place)
  })

  groups.forEach((group) => {
    if (group) {
      group.fascias = categorizeLocations(group.values)
    }
  })

  for (let i = groups.length - 1; i >= 0; i--) {
    if (groups[i]) {
      groups[i].isLast = true
      return { groups, totals }
    }
  }

  return { groups, totals }
}

export class Group {
  values: AbstractLocation[] = []
  fascias: OperatorPresent[]
  totals: Record<string, number> = {}
  keysMap: Record<string, boolean> = {}
  isLast = false

  constructor(
    public name: string,
    public background: string,
    public minutes?: number,
    public keys = ['grossInternalArea', 'netSalesArea']
  ) {
    this.keysMap = this.keys.reduce((acc, cur) => {
      acc[cur] = true
      return acc
    }, {} as Record<string, boolean>)
  }

  add(place: AbstractLocation) {
    this.keys.forEach((key) => {
      if (key in place) {
        this.totals[key] = (this.totals[key] || 0) + (+(place as any)[key] || 0)
      } else if (key in place.properties) {
        this.totals[key] = (this.totals[key] || 0) + (+place.properties[key] || 0)
      }
    })

    this.values.push(place)
  }
}

@Component({
  selector: 'competition-maxi-report',
  templateUrl: './competitionMaxiReport.html',
  styleUrls: ['../mini/competitionMiniReport.less', '../sidenav/miniReportsPanel.less', './competitionMaxiReport.less'],
})
export class CompetitionMaxiReportComponent extends SubscriberComponent {
  @Input() type: 'place' | 'planningApplication' = 'place'

  currentSorting: string = 'travelTime'
  inverseSorting = false

  // The application wide model keeping track of selected and visible places
  loading: Promise<Place[]>

  // The list of places that are to be rendered in the view
  places: Place[] = []
  groups: Group[]

  totals: Group
  sources: Place[]
  sourcesFascias: OperatorCategorized[]

  zonesVisible: boolean = false
  tableColSpan = 4
  useLocationFascias = false

  columns = [
    { key: 'fascia', label: 'Name' },
    { key: 'street', label: 'Street' },
    { key: 'town', label: 'Town' },
    { key: 'postcode', label: 'Postcode' },
    { key: 'travelTime', label: 'Travel Time (mins)' },
    { key: 'grossInternalArea', label: 'GIA (sq ft)' },
    { key: 'netSalesArea', label: 'NSA (sq ft)' },
  ]

  columnsFascias = [
    { key: 'name', label: 'Name', colspan: 3 },
    { key: 'count', label: 'Count' },
    { key: 'grossInternalArea', label: 'GIA (sq ft)' },
    { key: 'netSalesArea', label: 'NSA (sq ft)' },
  ]

  constructor(readonly appModel: AppModel, readonly zoneLayersModel: ZoneLayersModel) {
    super()
  }

  sortByColumn(column: string) {
    if (this.currentSorting == column) {
      this.inverseSorting = !this.inverseSorting
    }

    this.currentSorting = column
    this.updateSort()
  }

  ngOnInit() {
    this.with(
      <any>(
        this.appModel.places.reachableFilteredPlacesNonPassive.promise.subscribe((promise) => (this.loading = promise))
      )
    )

    this.watch(
      Observable.combineLatest(
        this.type === 'place'
          ? this.appModel.places.reachableFilteredPlacesNonPassive.value
          : this.appModel.places.reachableFilteredPlanningNonPassive.value,
        this.zoneLayersModel.selectionExistsUpdates,
        this.appModel.settings.edgeWeightsUpdates
      ),
      ([places, zonesVisible, edgeWeights]) => {
        this.useLocationFascias = places && places.length > COMPETITION_THRESHOLD_COALESCE_LOCATIONS
        this.zonesVisible = zonesVisible
        this.loadPlaces(places, zonesVisible, edgeWeights)
        this.updateSort()
      }
    )

    this.watch(this.appModel.places.sources.observable, (sources) => {
      this.sources = sources
      if (this.sources.length > COMPETITION_THRESHOLD_COALESCE_SOURCES) {
        this.sourcesFascias = categorizeLocations(sources)
      } else {
        this.sourcesFascias = null
      }
    })
  }

  updateSort() {
    if (this.sources) arrays.sortBy(this.sources, this.currentSorting, this.inverseSorting)

    if (this.groups) {
      this.groups.forEach((group) => {
        if (group) arrays.sortBy(group.values, this.currentSorting, this.inverseSorting)
      })
    }
  }

  getColor(item: any) {
    if (!item) {
      return '#ddd'
    }

    if (item.getColor) {
      return item.getColor()
    } else if (item.category) {
      return item.category.color
    } else {
      return '#ddd'
    }
  }

  // Fetch the current place list from model and make it available for rendering
  loadPlaces = (places: AbstractLocation[], zonesVisible: boolean, edgeWeights: colors.MaxEdgeWeightOption[]) => {
    this.tableColSpan = 4
    let columns = COLUMNS_BASIC

    if (this.type === 'planningApplication') {
      this.tableColSpan++
      columns = columns.concat(COLUMNS_PLANNING_VALUE)
    }

    if (!zonesVisible) {
      this.tableColSpan++
      columns = columns.concat(COLUMNS_TRAVEL)
    }

    this.columns = columns.concat(COLUMNS_AREA)

    this.columnsFascias[0].colspan = this.columns.length - this.columnsFascias.length + 1

    this.groups = []
    this.places = places
    this.totals = new Group('', 'white')

    const options = this.appModel.settings.displaySettings.getValue().travelOptions.maxEdgeWeight

    // const minutesGroup = edgeWeights[0].value / 5 //
    const minutesGroup = Math.ceil(edgeWeights[0].value / 5 / 60) * 60
    const maxMinutes = options / 60
    const levelTitleFactor = minutesGroup / 60 //

    places.forEach((place) => {
      let level = Math.max(0, Math.floor((Math.ceil((place.travelTime || 0) / minutesGroup) - 1) / 5))

      if (zonesVisible) {
        level = 0
      }

      if (!this.groups[level]) {
        this.groups[level] = new Group(
          `${level * levelTitleFactor * 5} to ${Math.min(maxMinutes, (level + 1) * levelTitleFactor * 5)} minutes`,
          edgeWeights[level].color
        )
      }

      this.groups[level].add(place)
      this.totals.add(place)
    })

    this.groups.forEach((group) => {
      if (group) {
        group.fascias = categorizeLocations(group.values)
      }
    })

    for (var i = this.groups.length - 1; i >= 0; i--) {
      if (this.groups[i]) {
        this.groups[i].isLast = true
        return
      }
    }
  }
}
