import {Component, ViewChild} from '@angular/core'
import {MatMenuTrigger} from '@angular/material'
import {MatDialog} from '@angular/material'
import { UserEndpoint } from '../../../api/user'
import { TgmQuickDialogs } from '@targomo/client'
import { User } from "../../../api/index"
import { DataSetLocation } from '../../../model/index'
import {files as filesUtil} from '@targomo/client'
import * as importLayer from '../../../../common/util/importLayer'
import { DataSetEndpoint, DataSet } from '../../../api/dataSet';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_BASE_PATH } from "../../../util/basePath";
import { NgZone } from '@angular/core';
import { ZoneLayersEndpoint } from '../../../api/sectors';

export function waitTimeout(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}


@Component({
  selector: 'admin-upload-zone-layer-set',
  templateUrl: './uploadZoneLayer.html'
})
export class AdminUploadZoneLayerComponent {
  locations: DataSetLocation[]
  locationError: string = null
  locationCategorizeError: string = null

  basePath = APP_BASE_PATH
  saving: Promise<{}> | boolean
  set: DataSet = null
  form: FormGroup
  isSaving: boolean

  locationsFound = false
  locationsLength = 0

  private holdingCompanyCount = 0
  private secondaryCategoryCount = 0
  private skipClientValidation = false

  submitted = false
  private files: any


  constructor(private formBuilder: FormBuilder, private usersEndpoint: UserEndpoint, 
              private dataSetEndpoint: ZoneLayersEndpoint, private dialog: MatDialog, 
              private zone: NgZone,
              private quickDialogs: TgmQuickDialogs, private route: ActivatedRoute, private router: Router) {
    this.form = this.formBuilder.group({
      'name': [null, []],
      'categorize': [null, []],
    })

    this.init()
  }

  async init() {
    const params = this.route.snapshot.params
    const id = params.id

    if (id) {
      this.set = await this.dataSetEndpoint.get({id})
    }

    this.form = this.formBuilder.group({
      'name': [this.set ? this.set.name : null, [Validators.required]],
    })
  }

  async save() {
    this.submitted = true
    this.locationError = null

    if (this.form.valid) {
      this.isSaving = true
      try {
        const result = await (this.saving = this.dataSetEndpoint.uploadMultipart(this.files, this.set || {name: this.form.value.name}))
        this.router.navigate(['/admin/zone/' + result])
      } catch (e) {
        this.saving = null
        this.isSaving = false

        if (e && e.error && e.error.message) {
          this.locationError = e.error.message
        } else {
          this.locationError = 'Upload unsucessful'
        }
      }
    }
  }

  async selectFile(files: File[]) {
    this.files = files
  }

  cancel() {
    if (this.set)
      this.router.navigate(['/admin/zones/' + this.set.id])
    else
      this.router.navigate(['/admin/zones'])
  }
}
