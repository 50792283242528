import { Component, OnInit } from '@angular/core'
import { MatchpointEndpoint } from '../../../api/matchpoint'
import { SubscriberComponent, Indicators } from '@targomo/client'
import { array as arrays } from '@targomo/common'
import { MATCHPOINT_COLUMNS } from '../adminComparisonReportOptions/adminComparisonReportOptions.component'
import { MatchpointSettingType } from '../../../../common/models/matchpoint'

const CONDITION_TYPES = [
  { label: 'Percent / Reg Index', value: MatchpointSettingType.PERCENT_INDEX },
  { label: 'Absolute Value', value: MatchpointSettingType.ABSOLUTE_VALUE },
]

const CATEGORIES = MATCHPOINT_COLUMNS.concat([
  { key: '$$$CatchmentSpend', label: '* Retail GIA (including F&B and Leisure)' },
])

@Component({
  selector: 'admin-matchpoint-panel',
  templateUrl: './adminMatchpointPanel.component.html',
  styleUrls: ['./adminMatchpointPanel.component.less'],
})
export class AdminMatchpointPanelComponent extends SubscriberComponent implements OnInit {
  saving: Promise<{}>
  availableCategories: {
    selected?: boolean
    key: string
    label: string
    minimum?: number
    maximum?: number
    type?: number
  }[] = arrays.sortBy(JSON.parse(JSON.stringify(CATEGORIES)), 'label')

  visibleCategories = this.availableCategories

  showOnlySelected = false
  avalableConditionTypes = CONDITION_TYPES

  constructor(private matchpointEndpoint: MatchpointEndpoint, private indicators: Indicators) {
    super()
  }

  async ngOnInit() {
    const current = await this.indicators.add(this.matchpointEndpoint.getSettings())

    const currentMap = current.reduce((acc: any, cur: any) => {
      acc[cur.statistic] = cur
      return acc
    }, {})

    this.availableCategories.forEach((profile) => {
      profile.selected = !!currentMap[profile.key]

      profile.minimum = null
      profile.maximum = null
      profile.type = 0

      if (profile.selected) {
        profile.minimum = currentMap[profile.key].minimum
        profile.maximum = currentMap[profile.key].maximum
        profile.type = currentMap[profile.key].type || 0
      }
    })
  }

  async save(event: any) {
    const selectedCategories = this.availableCategories
      .filter((profile) => profile.selected)
      .map((value: any) => {
        return {
          statistic: value.key,
          minimum: value.minimum,
          maximum: value.maximum,
          type: value.type,
        }
      })

    this.saving = this.indicators.add(this.matchpointEndpoint.saveSettings(selectedCategories))
  }

  toggleCategory(category: { selected: boolean }) {
    category.selected = !category.selected
  }

  toggleOnlySelected(value: any) {
    if (value) {
      this.visibleCategories = this.availableCategories.filter((item) => !!item.selected)
    } else {
      this.visibleCategories = this.availableCategories
    }
  }
}
