import { TravelTypeEdgeWeightOptions } from '@targomo/core'
import { Observable, of } from 'rxjs'
import { canonicalPositions, Place } from '../../api/place'
import { AppModel } from '../appModel.service'
import {array as arrays} from '@targomo/common'
import {object as objects} from '@targomo/common'
import { AllStatistics } from '../../../common/models/statistics/statistics'
import { Indicators } from '@targomo/client'
import { StatisticsEndpoint } from '../../api/statistics'
import { filter, map, switchMap, shareReplay, catchError } from 'rxjs/operators'

export abstract class AbstractBenchmarkReport<T> {
  readonly loading$: Observable<Promise<T[]>>
  readonly export$: Observable<Promise<{locations: T[], columns: {key: string, label: string}[], fileName: string}>>
  readonly values$: Observable<T[]>

  constructor(
    readonly columns: {key: string, label: string}[],
    readonly exportFileName: string, 
    protected appModel: AppModel,
    protected indicators: Indicators,
    protected statisticsEndpoint: StatisticsEndpoint,
  ) {
    this.loading$ = this.init().pipe(
      shareReplay(1),
    )

    this.values$ = this.loading$.pipe(
      switchMap(async promise => await promise),
      catchError(ex => {
        console.error(ex)
        return of(null)
      }),
      shareReplay(1),
    )

    this.export$ = this.loading$.pipe(
      map(async promise => {
        const locations = await promise
        if (locations) {
          return {locations, columns: this.columns, fileName: this.exportFileName}
        } else {
          return null
        }
      }),
      shareReplay(1),
    )
  }

  protected abstract init(): Observable<Promise<T[]>>
}