<form class="form" (submit)="save()" novalidate [formGroup]="form">
  <h3 class="mat-title">Upload Postcode Analysis data</h3>

  <section fxLayout="column">
    <tgm-file-uploader placeholder="Upload Csv" (selected)="selectFile($event)"></tgm-file-uploader>

    <mat-hint *ngIf="locationError" [ngStyle]="{'color': 'red'}" style="font-size: 75%">
      <span>{{locationError}}</span>
    </mat-hint>

    <mat-hint *ngIf="locationSuccess" [ngStyle]="{'color': 'green'}" style="font-size: 75%">
      <span>{{locationSuccess}}</span>
    </mat-hint>
  </section>

  <div class="align-right" style="margin-top: 16px">
    <div style="margin: 8px; font-size: 13px; text-align: center" *ngIf="saving">Please wait...</div>

    <div style="margin-bottom: 6px"><tgm-saveable *ngIf="saving" [model]="saving"></tgm-saveable></div>

    <button matTooltip="Back" matTooltipPosition="below" mat-button type="button" (click)="cancel()">
      <mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span>Back</span>
    </button>

    <button [disabled]="isSaving" mat-flat-button color="primary" type="submit">Save</button>
  </div>

  <expandable-panel class="help-panel" [expanded]="true" text="Help">
    <div>
      <div style="margin-bottom: 20px; line-height: 1.4em">
        <p>You can upload a .csv file with columns: lat, lng, count, store name, postcode.</p>
      </div>
    </div>
  </expandable-panel>

  <div>
    <mat-list>
      <mat-list-item *ngFor="let category of colors" (click)="clickCategory(category, $event)">
        <div class="mat-avatar" [style.background]="category.color || '#ddd'"></div>
        <div class="name-section" fxFlex fxLayout="column">
          <span>{{category.name}}</span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</form>
