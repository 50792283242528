import { Observable } from 'rxjs'

export function ready() {
  return function<T>(source: Observable<T>): Observable<boolean> {
    let current: T = undefined
    return new Observable(subscriber => {
      source.subscribe({
        async next(value) {
          current = value
          subscriber.next(false)
          try {
            await value
          } finally {
            if (value === current) {
              subscriber.next(true)
            }
          }
        },
        error(error) {
          subscriber.error(error)
        },
        complete() {
          subscriber.complete()
        }
      })
    })
  }
}
