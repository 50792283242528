<tgm-loadable [model]="loading">
  <!--<lifestyle-graph model="report.statistics"></lifestyle-graph>-->

  <table class="lifestyle-summary">
    <ng-container *ngFor="let report of reports ">
      <tbody>
        <tr>
          <th colspan="1"></th>
          <th style="text-align: center" colspan="5">Household Income Group</th>
          <th colspan="1"></th>
        </tr>
            
        <tr>
          <th>{{report.title}}</th>
          <th>High</th>
          <th style="text-align: center" colspan="3">Average</th>
          <th >Low</th>
          <th></th>
        </tr>

        <tr>
          <th>Lifestage</th>
          <th *ngFor="let value of affluence">
            {{value}}
          </th>
          <th class="totals">Total</th>
        </tr>
      </tbody>

      <tbody *ngIf="!report.index">
        <tr *ngFor="let child of groups; let i=index">
          <td>{{child.name}}</td>
          <td *ngFor="let value of report.statistics[child.id]">
            {{value.total | number: '1.0-0'}}
          </td>
          <td class="totals">{{report.statistics.vertical[i] | number: '1.0-0'}}</td>
        </tr>

        <tr class="totals">
          <td>Total</td>
          <td *ngFor="let value of report.statistics.horizontal">
            {{value | number: '1.0-0'}}
          </td>
          <td>{{report.statistics.total | number: '1.0-0'}}</td>
        </tr>
      </tbody>

      <tbody *ngIf="!!report.index">
          <tr *ngFor="let child of groups; let i=index">
          <td>{{child.name}}</td>
          <td *ngFor="let value of report.statistics[child.id]">
            {{((value.total / report.statistics.total) / value.index) * 100 | number: '1.0-0'}}
          </td>
          <td class="totals">{{((report.statistics.vertical[i] / report.statistics.total) / report.statistics.verticalIndices[i]) * 100 | number: '1.0-0'}}</td>
        </tr>

        <tr class="totals">
          <td>Total</td>
          <td *ngFor="let value of report.statistics.horizontal; let i=index">
            {{((value / report.statistics.total) / report.statistics.horizontalIndices[i]) * 100 | number: '1.0-0'}}
          </td>
          <td>{{100 | number: '1.0-0'}}</td>
        </tr>
      </tbody>
        
    </ng-container>
  </table>
</tgm-loadable>