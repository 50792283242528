import { Component } from '@angular/core'
import { Settings } from "../../api/index";
import {SettingsEndpoint} from '../../api/settings';
import {Router, ActivatedRoute} from '@angular/router';
import { APP_BASE_PATH } from '../../util/basePath';
import { AppModel } from '../../model/appModel.service';
import { EXCHANGE_RATE_GBP_TO_EURO } from '../../../common/models/statistics/statistics';

@Component({
  selector: 'admin-settings',
  templateUrl: './adminSettings.html'
})
export class AdminSettingsComponent {
  settings: Settings = <any>{}
  url: string
  saving: Promise<any>

  constructor(private settingsEndpoint: SettingsEndpoint, private route: ActivatedRoute, private router: Router, private appModel: AppModel) {
  }

  async ngOnInit() {
    this.settings = (await this.settingsEndpoint.get()) || <any>{}
    if (this.settings.gbpToEuroRate == null) {
      this.settings.gbpToEuroRate = EXCHANGE_RATE_GBP_TO_EURO
    }

    if (this.settings.hasLogo) {
      this.url  = `${APP_BASE_PATH}api/settings/uploadedlogo`
    }
  }

  async save(event?: any) {
    // if (form.$valid) {
      await (this.saving = this.settingsEndpoint.save(this.settings))
      this.appModel.logoUpdated.next(new Date().getTime())
      this.router.navigate(['/admin/settings'])

    // }
  }


  onSelectPicture(files: FileList) {
    if (!files || !files[0]) {
      this.url = null
      this.settings.logo = null
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      const result = reader.result

      this.settings.logo = result.substring(result.indexOf(',') + 1)
      this.url = result
    }

    reader.readAsDataURL(files[0])
  }

  onSelectLogo(files: FileList) {
    this.onSelectPicture(files)
  }  
}