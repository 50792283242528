import { Point, PointLike } from 'mapbox-gl'
import { LatLngProperties, LatLng } from '@targomo/core'
import {
  InteractionLayer,
  MarkerMapLayer,
  SymbolMarkerMapLayer,
  GeojsonMapSource,
  DefaultMapLayer,
  MapInteractionEvent,
  EMPTY_GEOJSON_DATA,
  ObservableExpression,
  PrimaryLayer,
  MarkerMapSource,
} from '@targomo/client'
import { Observable, Subject } from 'rxjs'

export interface MarkerInteractionLayerOptions<T> {
  dragTimeoutValue?: number
  showHover?: boolean
  isLayerDraggable?: boolean
  hoverTitle?: (marker: T) => string
}

/**
 *  A layer providing hover and click events for a markers layer
 */
export class LoopMarkerInteractionLayer<T extends LatLngProperties> extends InteractionLayer {
  private hoverTitle: (marker: T) => string
  private _visible: boolean // FIXME::
  private layers: (MarkerMapLayer<T> | SymbolMarkerMapLayer<T>)[]

  private hoverSource: GeojsonMapSource
  private hoverLayer: DefaultMapLayer

  private dragTimeout: any
  private dragTimeoutValue = 500
  private showHover = true

  private eventListeners: { [name: string]: any } = {} // TODO: generalize but lets do this for now

  readonly events: {
    readonly click: Observable<MapInteractionEvent<T>>
    readonly bboxSelect: Observable<MapInteractionEvent<T>>
    readonly context: Observable<MapInteractionEvent<T>>
    readonly hover: Observable<MapInteractionEvent<T>>
    readonly drag: Observable<MapInteractionEvent<T>>
    readonly clickMaybeMap: Observable<MapInteractionEvent<T>>
  } = {
    click: new Subject<MapInteractionEvent<T>>(),
    bboxSelect: new Subject<MapInteractionEvent<T>>(),
    context: new Subject<MapInteractionEvent<T>>(),
    hover: new Subject<MapInteractionEvent<T>>(),
    drag: new Subject<MapInteractionEvent<T>>(),
    clickMaybeMap: new Subject<MapInteractionEvent<T>>(),
  }
  isLayerDraggable: boolean

  constructor(
    layers: PrimaryLayer<MarkerMapLayer<T> | SymbolMarkerMapLayer<T>>[],
    options?: ObservableExpression<MarkerInteractionLayerOptions<T>>
  ) {
    super((layers[0].getPrimaryLayer() as any).map)

    if (options) {
      this.watch(options, (value) => {
        this.dragTimeoutValue = value.dragTimeoutValue != null ? value.dragTimeoutValue : 500
        this.showHover = value.showHover != null ? value.showHover : true
        this.hoverTitle = value.hoverTitle
        this.isLayerDraggable = value.isLayerDraggable
      })
    }

    this.layers = layers.map((layer) => layer.getPrimaryLayer())

    this.hoverSource = new GeojsonMapSource(EMPTY_GEOJSON_DATA)
    this.hoverLayer = new DefaultMapLayer(this.map, this.hoverSource, {
      type: 'circle',
      paint: {
        'circle-radius': 20,
        'circle-color': 'rgba(56, 135, 190, 0.7)',
      },
    })

    this.hoverLayer.setVisible(true)

    this.map.zone.runOutsideAngular(() => {
      this.initEvents()
    })
  }

  isActiveFeature(marker: LatLngProperties) {
    return marker && marker.properties && marker.properties['marker-active'] !== false
  }

  async initEvents() {
    const map = await this.map.getMap()
    const layerIds = this.layers.map((layer) => layer.id)
    const sources: { [index: string]: MarkerMapSource<T> } = {}

    map.boxZoom.disable()

    this.layers.forEach((layer) => {
      sources[layer.id] = layer.getSource()
    })

    function markerByFeature(feature: any) {
      if (feature && feature.properties) {
        const index = feature.properties.index
        const source = sources[feature.layer && feature.layer.id]
        if (source) {
          return source.byIndex(index)
        }
        return null
      } else {
        return null
      }
    }

    function markersByFeatures(features: any[]) {
      return features.map((f) => markerByFeature(f))
    }

    const canvas = await map.getCanvasContainer()

    let downPoint: any = null
    let currentPoint: any = null

    let hoverLocation: any = null
    let hoverPoint: any = null
    // let hoverFeature: any = null
    // let isMouseDown = false
    let isDragging = false
    let isMoved = false

    const onMapEvent = <U>(event: string, callback: (ev: U) => void) => {
      map.on(event, callback)
      this.eventListeners[event] = callback
    }

    const onHoverMarker = (event: any, features: any[]) => {
      const marker = features[0] && markerByFeature(features[0])
      if (marker == hoverLocation) {
        return
      }

      hoverLocation = marker
      const mapEvent = new MapInteractionEvent<LatLng>(
        event.lngLat,
        this.mousePoint(event),
        marker,
        event.originalEvent
      )
      this.map.angularZoneMouseMove(() => {
        this.fire(this.events.hover, mapEvent)
      })
    }

    onMapEvent('mousedown', (e: any) => {
      if (!(e.originalEvent.shiftKey && e.originalEvent.button === 0)) {
        return
      }

      const start = this.getMousePosition(e, canvas)

      map.dragPan.disable()

      let dragBox: HTMLElement
      let current: any

      const getMousePosition = this.getMousePosition
      const queryMapRenderedFeatures = this.queryMapRenderedFeatures
      const zone = this.map.zone
      const fireBbox = (event: MapInteractionEvent<LatLng>) => this.fire(this.events.bboxSelect, event)

      function bboxMouseMove(mmevt: any) {
        current = getMousePosition(mmevt, canvas)

        if (!dragBox) {
          dragBox = document.createElement('div')
          dragBox.classList.add('selection-bounding-box')
          canvas.appendChild(dragBox)
        }

        const minX = Math.min(start.x, current.x)
        const maxX = Math.max(start.x, current.x)
        const minY = Math.min(start.y, current.y)
        const maxY = Math.max(start.y, current.y)

        const pos = `translate(${minX}px,${minY}px)`

        dragBox.style.transform = pos
        dragBox.style.webkitTransform = pos
        dragBox.style.width = maxX - minX + 'px'
        dragBox.style.height = maxY - minY + 'px'
      }

      function bboxMouseUp(muevt: any) {
        map.off('mousemove', bboxMouseMove)
        map.off('mouseup', bboxMouseUp)

        if (!!dragBox) {
          canvas.removeChild(dragBox)
          dragBox = null
        }

        const bbox: [PointLike, PointLike] = [start, getMousePosition(muevt, canvas)]

        if (!!bbox && bbox.length === 2) {
          const features = queryMapRenderedFeatures(map, bbox, { layers: layerIds })
          const bboxEvent = new MapInteractionEvent<any>(
            e.lngLat,
            current,
            markersByFeatures(features),
            e.originalEvent
          )
          zone.run(() => {
            fireBbox(bboxEvent)
          })
        }

        map.dragPan.enable()
      }

      map.on('mousemove', bboxMouseMove)
      map.on('mouseup', bboxMouseUp)
    })

    onMapEvent('click', (event: any) => {
      this.map.zone.run(() => {
        const features = this.queryMapRenderedFeatures(map, event.point, {
          layers: layerIds,
        })

        if (features.length) {
          const marker = markerByFeature(features[0])

          if (this.isActiveFeature(marker)) {
            const cellEvent = new MapInteractionEvent(event.lngLat, this.mousePoint(event), marker, event.originalEvent)
            this.fire(this.events.click, cellEvent)
            this.fire(this.events.clickMaybeMap, cellEvent)
            return
          }
        }

        const cellEvent = new MapInteractionEvent(event.lngLat, this.mousePoint(event), null, event.originalEvent)
        this.fire(this.events.clickMaybeMap, cellEvent)
      })
    })

    onMapEvent('contextmenu', (event: any) => {
      this.map.zone.run(() => {
        const features = this.queryMapRenderedFeatures(map, event.point, {
          layers: layerIds,
        })
        if (features.length) {
          const marker = markerByFeature(features[0])

          if (this.isActiveFeature(marker)) {
            const cellEvent = new MapInteractionEvent<LatLng>(
              event.lngLat,
              this.mousePoint(event),
              marker,
              event.originalEvent
            )
            this.fire(this.events.context, cellEvent)
          }
        }
      })
    })

    onMapEvent('mouseup', (event: any) => {
      clearTimeout(this.dragTimeout)
      isDragging = false
      // isMouseDown = false
      map.dragPan.enable()
      canvas.style.cursor = ''

      this.hoverSource.updateValue(EMPTY_GEOJSON_DATA)

      if (event.originalEvent.which > 1) {
        return
      }

      if (hoverPoint && isMoved) {
        const location = markerByFeature(hoverPoint)
        this.map.angularZoneMouseMove(() => {
          this.fire(this.events.drag, new MapInteractionEvent(event.lngLat, null, location, event.originalEvent))
        })
      }

      hoverPoint = null
      downPoint = null
      currentPoint = null
    })

    const mouseDown = (event: any) => {
      // isMouseDown = true
      isMoved = false
      const features = this.queryMapRenderedFeatures(map, event.point, {
        layers: layerIds,
      })

      if (this.isLayerDraggable || (features.length && features[0].properties['marker-draggable'])) {
        hoverPoint = features[0]
        downPoint = this.mousePoint(event)

        this.dragTimeout = setTimeout(() => {
          if (currentPoint) {
            const diff = Math.sqrt(
              Math.pow(downPoint.x - currentPoint.x, 2) + Math.pow(downPoint.y - currentPoint.y, 2)
            )
            if (diff > 15) {
              // 15 pixel movement threshold for stating drag
              return
            } else if (diff > 0) {
              isMoved = true
            }
          }

          isDragging = true
          this.hoverSource.updateValue(hoverPoint)
          canvas.style.cursor = 'move'
        }, this.dragTimeoutValue)
      }
    }

    onMapEvent('mousemove', (event: any) => {
      if (event.originalEvent.which > 1) {
        return
      }

      if (hoverPoint) {
        let coords = event.lngLat
        hoverPoint.geometry.coordinates = [coords.lng, coords.lat]
        currentPoint = this.mousePoint(event)
      }

      if (isDragging) {
        isMoved = true
        canvas.style.cursor = 'grabbing'
        // hoverPoint.geometry.coordinates = [coords.lng, coords.lat]
        this.hoverSource.updateValue(hoverPoint)
      } else {
        const features = this.queryMapRenderedFeatures(map, event.point, {
          layers: layerIds,
        })

        if (features.length && (this.isLayerDraggable || features[0].properties['marker-draggable'])) {
          const marker = markerByFeature(features[0])
          map.dragPan.disable()

          if (!this.dragTimeoutValue) {
            canvas.style.cursor = 'move' // TODO: better to add/remove class instead
          } else if (this.isActiveFeature(marker)) {
            canvas.style.cursor = 'pointer'
          }

          if (this.showHover) {
            canvas.title = this.hoverTitle ? this.hoverTitle(marker) : marker.toString()
          }
        } else if (!hoverPoint) {
          map.dragPan.enable()

          let cursor = '' // TODO: better to add/remove class instead

          if (features.length) {
            const marker = markerByFeature(features[0])

            if (this.showHover) {
              canvas.title = this.hoverTitle ? this.hoverTitle(marker) : marker.toString()
            }

            if (this.isActiveFeature(marker)) {
              cursor = 'pointer'
            }
          } else {
            canvas.title = ''
          }

          canvas.style.cursor = cursor
        }

        onHoverMarker(event, features)
      }
    })

    onMapEvent('mousedown', mouseDown)
  }

  getMousePosition = (e: any, canvas: HTMLElement) => {
    const rect = canvas.getBoundingClientRect()

    return new Point(
      e.originalEvent.clientX - rect.left - canvas.clientLeft,
      e.originalEvent.clientY - rect.top - canvas.clientTop
    )
  }

  setVisible(value: boolean) {
    this._visible = value
    // NOTE: maybe have an interaction layer parent class ... or maybe not call it layer
    return this
  }

  isVisible(): boolean {
    return this._visible
  }

  async onRemoveChildren(removeSource?: boolean) {
    const map = await this.map.getMap()
    for (let key in this.eventListeners) {
      map.off(key, this.eventListeners[key])
    }
  }
}
