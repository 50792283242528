<div *ngIf="zoneSelection">
    <span class="main">{{zoneSelectionTitle}}</span>
</div>

<div *ngIf="!zoneSelection">
  <div *ngFor="let source of fascias">
    <span class="main">{{source.name}}</span>
    <span class="count">
      <span *ngIf="!!source.count">({{source.count}})</span>
    </span>
  </div>

  <div *ngFor="let source of sources">
    <span class="main">{{source.defaultName}}</span><span class="secondary">
      <span *ngIf="!!source.street">{{source.street}}</span>
      <span *ngIf="!!source.town">{{source.town}}</span>
      <span *ngIf="!!source.postcode">{{source.postcode}}</span>
    </span>
  </div>
</div>