import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WaitDialogComponent } from './waitDialog.component';

export interface WaitDialogOptions {
  title?: string
  message: string
}

@Injectable({
  providedIn:'root'
})
export class WaitDialog {
  constructor(/** @hidden */ private dialog: MatDialog) {
  }

  /**
   * Shows a confirmation dialog
   *
   * @param options
   */
  async wait(promise: Promise<any>, options: WaitDialogOptions) {
    const dialogRef = this.dialog.open(WaitDialogComponent, {
      disableClose: false,
      data: options
    })

    try {
      await promise
    } catch (e) {
      console.error(e)
    } finally {
      return dialogRef.close()
    }
  }
}
