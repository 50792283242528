
  <div class="title" (click)="toggle()">
    <div class="location-item" fxLayout="row">
      <div fxFlex>{{text}}</div>
      <mat-icon [class.toggled]="expanded" class="animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
    </div>
  </div>

  <div class="second-level" *ngIf="expanded">
    <ng-content></ng-content>
  </div>
  