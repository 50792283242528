import {Component, EventEmitter, Output} from '@angular/core'
import {TgmQuickDialogs, SubscriberComponent} from '@targomo/client'
import {Validators, FormGroup, FormBuilder} from '@angular/forms'
import { SavedSessionEndpoint, SavedSession } from '../../../api/savedSession';
import { SavedSessionModel } from '../../../model/savedSessionModel';
import { Router } from '@angular/router';
import { HomeComponent } from '../../../main/home.component';

@Component({
  selector: 'save-session-panel',
  styleUrls: ['./saveSession.component.less'],
  templateUrl: './saveSession.component.html',
})
export class SaveSessionComponent extends SubscriberComponent {
  form: FormGroup
  submitted = false
  currentSession: SavedSession
  saveAs = true

  constructor(
    private formBuilder: FormBuilder,
    private home: HomeComponent,
    private router: Router,
    private savedSessionsEndpoint: SavedSessionEndpoint,
    readonly savedSessionsModel: SavedSessionModel,
    private quickDialogs: TgmQuickDialogs)
  {
    super()

    this.form = this.formBuilder.group({
      'name': [null, [Validators.required]],
    })

    this.watch(this.savedSessionsModel.currentSession, session => {
      if (session) {
        this.saveAs = false
      }
      this.currentSession = session
    })
  }

  saveNew() {
    this.saveAs = true
  }

  async saveUpdate() {
    const project = {name: this.form.value.name, data: await this.savedSessionsModel.snapshot(this.home.mapView)}
    const result = await this.savedSessionsEndpoint.update(this.currentSession.id, project)

    this.savedSessionsModel.currentSession.next(result)

    this.quickDialogs.snack('Session saved')
    this.router.navigate(['/'])
}

  async submit() {
    this.submitted = true

    if (this.form.valid) {
      const project = {name: this.form.value.name, data: await this.savedSessionsModel.snapshot(this.home.mapView)}
      const result = await this.savedSessionsEndpoint.create(project)

      this.savedSessionsModel.currentSession.next(result)

      this.quickDialogs.snack('Session saved')
      this.router.navigate(['/'])
    }
  }
}
