import { Injectable } from '@angular/core'
import { Indicators, ObservableList, RANDOM_COLORS } from '@targomo/client'
import { LatLng, LatLngProperties } from '@targomo/core'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/switchMap'
import 'rxjs/add/operator/take'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Observable } from 'rxjs/Observable'
import { ZoneLayersEndpoint, ZoneSelection } from '../api/sectors'
import { SettingsModel } from './settingsModel'

@Injectable()
export class ZoneLayersModel {
  private sectorsList = new ObservableList<ZoneSelection>()
  private editState = new BehaviorSubject<any>(null)
  private editDrawnState = new BehaviorSubject<ZoneSelection>(null)

  readonly sectors = this.sectorsList.observable
  readonly previewSectors = new BehaviorSubject<ZoneSelection[]>(null)

  readonly selectedZones = this.sectors.map((sectors) => {
    return sectors.filter((sector) => sector.selected)
  })

  readonly visibleLayerFeaturesIntermal = this.initVisibleLayerFeatures()
  readonly visibleLayerFeatures = this.visibleLayerFeaturesIntermal.map((item) => item.visible).shareReplay(1)
  readonly visibleLayerMapFeatures = this.visibleLayerFeaturesIntermal.map((item) => item.visibleMap).shareReplay(1)
  readonly visibleLayerFeaturesGeometry = this.initVisibleLayerFeaturesGeometry().shareReplay(1)

  readonly editingUpdates = this.editState
    .map((value) => !!value)
    .distinctUntilChanged()
    .shareReplay(1)
  readonly editingDrawnUpdates = this.editDrawnState
    .map((value) => !!value)
    .distinctUntilChanged()
    .shareReplay(1)
  readonly selectionExistsUpdates = this.selectedZones
    .map((value) => !!value && value.length > 0)
    .distinctUntilChanged()
    .shareReplay(1)

  readonly selectionExistsWithIndividualParts = this.selectedZones
    .map((value) => {
      return (
        !!value &&
        value.filter(
          (item) => !(item && item.geometry && item.geometry.properties && item.geometry.properties.isCircle)
        ).length > 0
      )
    })
    .distinctUntilChanged()
    .shareReplay(1)

  readonly layerGeometries = this.initLayerGeometries()
  readonly selectedGeometries = this.visibleLayerFeaturesGeometry

  readonly userZoneLabels = this.initUserZoneLabels()

  fetchingGeometriesPromise: Promise<any[]>

  constructor(
    private zoneLayersEndpoint: ZoneLayersEndpoint,
    private setting: SettingsModel,
    private indicators: Indicators
  ) {
    // this.layerGeometries.subscribe(x => {
    //   console.log('layer geometries', x)
    // })
  }

  snapshot() {
    return this.sectorsList.getValues().map((sector) => {
      if (sector.freehand) {
        return sector
      } else {
        const { geometry, ...result } = sector
        return result
      }
    })
  }

  restoreState(sectors: ZoneSelection[]) {
    this.sectorsList.update(sectors)
  }

  addSector(sector: ZoneSelection) {
    if (!sector.freehand) {
      sector.geometry = null
    }

    if (this.sectorsList.contains(sector)) {
      this.sectorsList.touch()
    } else {
      this.sectorsList.add(sector)
    }
  }

  addSectors(sector: ZoneSelection[]) {
    this.sectorsList.addAll(sector)
  }

  removeSector(sector: ZoneSelection) {
    this.sectorsList.remove(sector)
  }

  async toggleSectorFeature(id: string) {
    const current = await this.editState.take(1).toPromise()

    if (!current) {
      return
    }

    const selected = { ...(current || {}) }

    if (selected[id]) {
      delete selected[id]
    } else {
      selected[id] = true
    }

    this.editState.next(selected)
  }

  clearSelected() {
    const sectors = this.sectorsList.getValues()
    sectors.forEach((sector) => {
      sector.selected = false
    })
    this.sectorsList.touch()
  }

  toggle(sector: ZoneSelection) {
    this.editState.next(null)

    const newValue = !sector.selected
    const sectors = this.sectorsList.getValues()
    sectors.forEach((sector) => {
      sector.selected = false
    })

    sector.selected = newValue
    this.sectorsList.touch()
  }

  editDrawnSector(zone: ZoneSelection) {
    this.editDrawnState.next(zone)
    this.editState.next(null)
  }

  editSector(values: { [index: number]: boolean }) {
    this.editState.next(values)
    this.editDrawnState.next(null)
  }

  private initVisibleLayerFeatures() {
    return Observable.combineLatest(this.editState, this.selectedZones).map(([editState, selected]) => {
      function answer(visible: any[], visibleMap: any[]) {
        return { editState, selected, visible, visibleMap }
      }

      if (editState) {
        return answer(Object.keys(editState), Object.keys(editState))
      } else if (selected) {
        let result: any = {}

        selected.forEach((sector) => {
          if (sector.features) {
            sector.features.forEach((feature) => (result[feature] = true))
          }
        })

        return answer(Object.keys(result), [])
      } else {
        return answer([], [])
      }
    })
  }

  private initVisibleLayerFeaturesGeometry() {
    const freehandGeometries$ = this.selectedZones.map((zones) => {
      return zones && zones.filter((zone) => zone.freehand)
    })

    return Observable.combineLatest(this.visibleLayerFeaturesIntermal, freehandGeometries$)
      .withLatestFrom(this.setting.zoneLayerUpdates)
      .switchMap(async (value) => {
        const [[row, freehand], layer] = value

        let features = row.visible
        let selected = row.selected
        let editState = row.editState

        if (editState) {
          return null
        }

        // Assume only one selecte for now
        if (freehand && freehand.length) {
          return freehand[0].geometry
        }

        if (editState || !layer || !features || !features.length) {
          return null
        }

        // Assume only one selecte for now
        return await this.zoneLayersEndpoint.getGeometries(layer, features)
      })

    // return Observable.combineLatest(
    //   this.selectedSectors,
    //   this.editState,
    //   this.visibleLayerFeatures,
    //   this.setting.zoneLayerUpdates,
    // )
    // .debounceTime(100)
    // .mergeMap(async ([features, selected, editState, layer]) => {
    //   console.log('******FEOMETRY FEATURES', features, editState)

    //   if (editState) {
    //     return null
    //   }

    //   return await this.zoneLayersEndpoint.getGeometries(layer, features)
    // })
  }

  private initLayerGeometries() {
    return Observable.combineLatest(
      this.sectors,
      this.previewSectors,
      this.setting.zoneLayerUpdates,
      this.editDrawnState
    )
      .debounceTime(50)
      .switchMap(async ([zones, previewZones, layer, editDrawnState]) => {
        const requestZones: ZoneSelection[] = []

        if (previewZones) {
          for (let i = 0; i < previewZones.length; i++) {
            let zone = previewZones[i]

            if (!zone.color) {
              zone.color = RANDOM_COLORS[i % RANDOM_COLORS.length]
            }

            if (!zone.geometry) {
              // zone.geometry = await this.zoneLayersEndpoint.getGeometries(zone.layer, zone.features)
              requestZones.push(zone)
              // zone.geometry.properties.color = zone.color
            }
          }
        } else if (zones) {
          for (let i = 0; i < zones.length; i++) {
            let zone = zones[i]

            if (!zone.color) {
              zone.color = RANDOM_COLORS[i % RANDOM_COLORS.length]
            }

            if (zone.freehand && zone.geometry && zone.geometry.properties) {
              zone.geometry.properties.color = zone.color
            }

            if (!zone.geometry) {
              // zone.geometry = await this.zoneLayersEndpoint.getGeometries(zone.layer, zone.features)
              requestZones.push(zone)
              // zone.geometry.properties.color = zone.color
            }

            if (zone.children) {
              for (let j = 0; j < zone.children.length; j++) {
                let childZone = zone.children[j]

                if (!childZone.color) {
                  childZone.color = RANDOM_COLORS[i % RANDOM_COLORS.length]
                }

                if (!childZone.geometry) {
                  // childZone.geometry = await this.zoneLayersEndpoint.getGeometries(childZone.layer, childZone.features)
                  requestZones.push(childZone)
                  // childZone.geometry.properties.color = childZone.color
                }
              }
            }
          }
        }

        if (requestZones.length) {
          const resultZones = await this.indicators.add(
            (this.fetchingGeometriesPromise = this.zoneLayersEndpoint.getGeometriesMultiple(requestZones))
          )
          resultZones.forEach((resultZone, i) => {
            requestZones[i].geometry = resultZone
            if (requestZones[i].geometry && requestZones[i].geometry.properties) {
              requestZones[i].geometry.properties.color = requestZones[i].color
            }
          })
        }

        function flattenZone() {
          const resultList: any[] = []

          zones.forEach((zone) => {
            if (zone.children) {
              zone.children.forEach((child) => {
                if (child.geometry) {
                  resultList.push(child.geometry)
                }
              })
            } else {
              if (zone.geometry && zone !== editDrawnState) {
                resultList.push(zone.geometry)
              }
            }
          })

          return resultList
        }

        return {
          type: 'FeatureCollection',
          features: previewZones
            ? previewZones.filter((zone) => zone.layer == layer && zone.geometry).map((zone) => zone.geometry)
            : zones
            ? flattenZone()
            : [],
        }
      })
  }

  private initUserZoneLabels() {
    return this.layerGeometries
      .withLatestFrom(this.sectors, this.previewSectors, this.setting.zoneLayerUpdates)
      .map(([geometries, zones, previewZones, layer]) => {
        const result: LatLngProperties[] = []

        if (previewZones) {
          zones = previewZones
        }

        zones.forEach((zone) => {
          if (zone.layer !== layer && !zone.freehand) {
            return
          }

          if (zone.children) {
            zone.children.forEach((childZone) => {
              if (childZone.geometry && childZone.geometry.properties && childZone.geometry.properties.labels) {
                childZone.geometry.properties.labels.forEach((point: LatLng) => {
                  result.push({ ...point, properties: { name: childZone.name } })
                })
              }
            })
          } else {
            if (zone.geometry && zone.geometry.properties && zone.geometry.properties.labels) {
              zone.geometry.properties.labels.forEach((point: LatLng) => {
                result.push({ ...point, properties: { name: zone.name } })
              })
            }
          }
        })

        return result
      })
  }
}
