export function saveCSV(data: string, fileName: string) {
  saveFile(data, 'text/csv', fileName)
}

export function saveJSON(data: string, fileName: string) {
  saveFile(data, 'application/vnd.geo+json', fileName)
}


/**
 * Save contest as file
 */
export function saveFile(data: string, dataType: string, fileName: string) {
  const anchor: any = document.createElement('a')
  const blob        = new Blob([data], {type: dataType})
  anchor.href       = URL.createObjectURL(blob)
  anchor.download   = fileName

  document.body.appendChild(anchor)
  try {
    anchor.click()
  } catch (e) {
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, anchor.download)
    }
  }
  document.body.removeChild(anchor)
}