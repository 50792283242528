import { Component, Input } from '@angular/core'
import { MatDialog } from '@angular/material'
import { ExportDialog } from '../dialogs/exportDialog.component'
import { MapboxDrawLayer } from '@targomo/client'
import { AppModel } from '../../model/appModel.service'
import exportPlaces from '../../util/exportPlaces'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

@Component({
  selector: 'select-tool',
  templateUrl: './selectTool.html',
  styleUrls: ['./selectTool.less'],
})
export class SelectToolComponent {
  @Input() private mapView: CustomMapboxComponent
  private mapDrawLayer: MapboxDrawLayer

  constructor(private dialog: MatDialog, private appModel: AppModel) {}

  ngOnInit() {
    this.ngOnChanges()
  }

  ngOnChanges() {}

  async save() {
    const filter = this.mapDrawLayer.asFilter()
    const result = this.appModel.places.filteredPlaces.getValue().filter(filter)

    const dialogRef = this.dialog.open(ExportDialog, {
      disableClose: false,
    })

    dialogRef.componentInstance.show(result)
    const exportType: any = await dialogRef.afterClosed().toPromise()
    if (exportType) {
      if (result.length && exportType) {
        // Note max 500 places can be exportes at a time
        exportPlaces(result.slice(0, 500), exportType, 'storepointgeo_exported.' + exportType)
      }
    }
  }

  delete() {
    this.mapDrawLayer.delete()
  }

  deleteAll() {
    this.mapDrawLayer.deleteAll()
  }

  select() {
    this.mapDrawLayer.modeSelect()
  }

  selectNode() {
    this.mapDrawLayer.modeNodeSelect()
  }

  draw() {
    this.mapDrawLayer.modeDraw()
  }

  change(state: boolean) {
    if (state) {
      if (this.mapView && !this.mapDrawLayer) {
        this.mapDrawLayer = new MapboxDrawLayer(this.mapView as any, {
          displayControlsDefault: false,
        })
      }
    }
  }
}
