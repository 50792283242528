import { ReportModel } from "./exportPdfReport";
import { PDFHelper } from "./helper";
import { largeWorkforceTableSimple } from "./largeFormatTableHelpers";
import { MaxEdgeWeightOption } from "@targomo/client";


async function drawOnMap(
  imageData: string,
  callback: (ctx: CanvasRenderingContext2D, width: number, height: number) => void
) {
  return new Promise<string>(resolve =>  {
    const image = new Image()
    image.src = imageData
    image.onload = function() {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0)

      callback(ctx, image.width, image.height)

      resolve(canvas.toDataURL())
    }
  })
}

export async function mapReportExplicitSide(
  mapImageData: string,
  edgeWeights: MaxEdgeWeightOption[],
  sideColunms: any[]
) {
  function drawTravelLegend(ctx: CanvasRenderingContext2D, width: number, height: number) {
    const startX = width / 3
    const barWidth = width / 3 / edgeWeights.length
    const barHeight = 50;

    let x = startX
    const y = height - barHeight - 40

    edgeWeights.forEach((item, i) => {
      ctx.fillStyle = item.color
      ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)'

      ctx.fillRect(x, y, barWidth, barHeight)
      ctx.strokeRect(x, y, barWidth, barHeight)
      x += barWidth
    })

    x = startX
    const textSize = 22
    edgeWeights.forEach((item, i) => {
      ctx.font = textSize + 'px "Open Sans"'
      ctx.fillStyle = '#ffffff'

      const text = item.label + ' min'
      const textWidth = ctx.measureText(text).width

      const fudge = textSize / 3 // lets just do this, since measureText besides width stuff is not available in all browser
      ctx.fillText(text, x + (barWidth / 2) - (textWidth / 2), y + barHeight / 2 + fudge)
      x += barWidth
    })
  }

  const result: any[] = [
    {
      columns: [
        {stack: sideColunms, width: 260, style: 'legendStyle', margin: [4, 4, 4, 4]},
        {
          image: await drawOnMap(mapImageData, drawTravelLegend),
          width: 460,
          alignment: 'right',
          absolutePosition: {x: 240,  y: 120}
        },
      ],
    }
  ]

  return result
}

export async function mapReport(reportModel: ReportModel, helper: PDFHelper, mapImageData: string, edgeWeights: MaxEdgeWeightOption[], travelTitle: string) {
  const sideColunms: any[] = [
    largeWorkforceTableSimple(reportModel, helper, 'Population', reportSegment => reportSegment.statistics.census.population2011.population, travelTitle),
    ' ',
    largeWorkforceTableSimple(reportModel, helper, 'Households', reportSegment => reportSegment.statistics.census.population2011.households, travelTitle),
    ' ',
    largeWorkforceTableSimple(reportModel, helper, 'Workforce', reportSegment => reportSegment.statistics.census.allWorkforce, travelTitle),
  ]

  return mapReportExplicitSide(mapImageData, edgeWeights, sideColunms)
}
