import { TravelType } from '@targomo/core';
import { CensusStatisticsGroup, StatisticsValue } from '../../../../common/models/statistics/statistics';
import { CareHomePricesReport, CareHomeReport } from '../../../model/careHome/careHomeModel';
import { CENSUS_SEGMENTS_RAW, ReportSegment } from '../../mini/model/reportSegments';
import { graphPie } from '../graphs';
import { PDFHelper } from '../helper';
import { travelLabel } from '../travelLabel';
import { careHouse } from './careGraph';
import { TABLE_OPTIONS_OTHER } from './tableStyle';
import { underlineCell } from './underlineCell';

const COLORS = {
  'detatched' : '#ffda96',
  'flat' : '#99aad8',
  'semiDetached' : '#f4b397',
  'terrace' : '#c8c8c8',
}

function pricesTable(helper: PDFHelper, segment: CareHomePricesReport, statistics: CensusStatisticsGroup, careHomeReport: CareHomeReport) {
  const regionLabel = careHomeReport.statistics.regionLabel()
  const KEYS = [ 'all', 'semiDetached', 'detached', 'terraced', 'flat']
  const LABELS = [ 'All', 'Semi-detached', 'Detached', 'Terraced', 'Flat']

  let rows: any[][] = KEYS.map((key, i) => {
    const item = segment[key]

    return [
      LABELS[i],
      '£' + helper.formatNumber(item.avgValue || 0, 0),
      helper.formatNumber(item.avgValueIndexVsAvg || 0, 0),
      '£' + helper.formatNumber(item.sqmPrice || 0, 0),
      helper.formatNumber(item.sqmPriceIndexVsAvg || 0, 0),
    ]
  })

  rows = [[
    {text: ' ', margin: [2, 7, 2, 2],},
    'Avg Value',
    `Index Vs ${regionLabel}`,
    '£/Sq M',
    `Index Vs ${regionLabel}`,
    ].map((text, i) => {
      const cell = {
        text, bold: true
      }
      return i === 0 ? cell : underlineCell(cell)
    })
  ].concat(rows)

  rows = rows.map((row, i) => row.map((cell, j) => {
    if (typeof cell === 'string') {
      cell = {text: cell, style: 'defaultCellBiggerFont'}
    } else if (!cell.style) {
      cell = {...cell, style: 'defaultCellBiggerFont'}
    }

    if (j === 0) {
      cell.alignment = 'left'
      cell.border = [false, false, false, false]
    } else {
      cell.alignment = 'center'
    }

    return cell
  }))

  return helper.table(null, rows, 'defaultTable', TABLE_OPTIONS_OTHER)
}


export async function careHomePrices(helper: PDFHelper, careHomeReport: CareHomeReport, travelLabel: string) {
  const statistics = careHomeReport.statistics
  const segment: ReportSegment = JSON.parse(JSON.stringify(CENSUS_SEGMENTS_RAW.find(item => item.id === 'accomodation')))
  segment.children = segment.children.filter(item => item.id !== 'temporaryStructure')

  const labels = segment.children.reduce((acc, cur) => {
    acc[cur.id] = cur.name
    return acc
  },  {} as Record<string, string>)

  const graph = graphPie(helper, statistics.census.accomodation, labels, COLORS)

  const pricesReport = careHomeReport.summaryDwellingTypes()

  function textDescription() {
    const travelLabelText = travelLabel.toLowerCase()

    const housesAboveBelow = pricesReport.all.avgValueIndexVsAvg - 100
    const detahcedAboveBelow = pricesReport.detached.avgValueIndexVsAvg - 100

    function aboveBelowText(value: number) {
      return `${helper.formatNumber(Math.abs(value), 1)}% ${value < 0 ? 'below' : 'above'}`
    }

    const region = statistics.region
    const nationalRegionalText = (region != 'GB' && region != 'ROI') ? 'regional' : 'national'

    return [[
      `Detached Homes make up ${helper.formatNumber(statistics.census.accomodation.detatched.percent, 1)}% of`,
      ` homes in the ${travelLabelText}time, which is`,
      ` ${aboveBelowText(statistics.census.accomodation.detatched.percent - statistics.census.accomodation.detatched.index)}`,
      ` the ${nationalRegionalText} average.`,
    ].join(''),
    ' ',
    `${helper.formatNumber(statistics.census.tenure.owned.percent, 1)}% of homes are owned, with ${helper.formatNumber(statistics.census.tenure.ownsOutright.percent, 1)}% owned outright`,
    ' ',
    `House Prices are ${aboveBelowText(housesAboveBelow)} the ${nationalRegionalText} average, with detached homes ${aboveBelowText(detahcedAboveBelow)}.`]
  }

  return {
    columnGap: 30,
    columns: [[
      {
        image: graph,
        width: 260,
        alignment: 'center',
      },
      {stack: textDescription(), style: 'careDescription'},
    ], [
      pricesTable(helper, pricesReport, statistics.census.accomodation, careHomeReport),

      {text: 'HOUSE PRICE BY VALUE BANDS - ALL HOMES', color: '#444444', margin: [5, 20, 5, 10]},

      {
        image: await careHouse(careHomeReport.statistics.census.dwellingPerType.all),
        width: 360,
      },

      {text: 'HOUSE PRICE BY VALUE BANDS - DETACHED HOMES', color: '#444444', margin: [5, 20, 5, 8]},

      {
        image: await careHouse(careHomeReport.statistics.census.dwellingPerType.detached),
        width: 360,
      },
    ]]
  }
}
