import { StatisticsValue, AllStatistics } from '../../../../common/models/statistics/statistics'
import { Place } from '../../../../common/models/place'
import { MatchpointSettingType } from '../../../../common/models/matchpoint'

export const BLACKLISTED_DOMINANT_STATISTICS: { [groupKey: string]: boolean } = {
  'demographics@accomodation@temporaryStructure': true,
}

export const WHITELISTED_DOMINANT_STATISTICS_GROUPS: { [groupKey: string]: { [groupKey: string]: boolean } } = {
  'lifestyle#A1D5': {
    'lifestyle@a@0': true,
    'lifestyle@a@1': true,
    'lifestyle@a@2': true,
    'lifestyle@a@3': true,
    'lifestyle@a@4': true,

    'lifestyle@b@0': true,
    'lifestyle@b@1': true,
    'lifestyle@b@2': true,
    'lifestyle@b@3': true,
    'lifestyle@b@4': true,

    'lifestyle@c@0': true,
    'lifestyle@c@1': true,
    'lifestyle@c@2': true,
    'lifestyle@c@3': true,
    'lifestyle@c@4': true,

    'lifestyle@d@0': true,
    'lifestyle@d@1': true,
    'lifestyle@d@2': true,
    'lifestyle@d@3': true,
    'lifestyle@d@4': true,
  },

  'lifestyle#A1C5': {
    'lifestyle@a@0': true,
    'lifestyle@a@1': true,
    'lifestyle@a@2': true,
    'lifestyle@a@3': true,
    'lifestyle@a@4': true,

    'lifestyle@b@0': true,
    'lifestyle@b@1': true,
    'lifestyle@b@2': true,
    'lifestyle@b@3': true,
    'lifestyle@b@4': true,

    'lifestyle@c@0': true,
    'lifestyle@c@1': true,
    'lifestyle@c@2': true,
    'lifestyle@c@3': true,
    'lifestyle@c@4': true,
  },

  'demographics@tenure#ownedrented': {
    'demographics@tenure@owned': true,
    'demographics@tenure@socialRented': true,
    'demographics@tenure@privateRented': true,
  },
}

export function findDominantVariables(
  sources: Place[],
  statistics: AllStatistics,
  reportSettings: { statistic: string; type: number; subKey: string }[],
  excludeCatchment: boolean
) {
  const result: { [id: string]: Partial<StatisticsValue> } = {}

  function serializeItem(item: StatisticsValue, originalKey: string, subKey: string = undefined, divide = true) {
    if (item) {
      return {
        index: item.index,
        percent: item.percent,
        total: divide ? item.total / sources.length : item.total,
        indexHouseholdsOrPopulation: item.indexHouseholdsOrPopulation,
        percentOverIndex: item.percentOverIndex,
        subKey,
        originalKey,
      }
    } else {
      return null
    }
  }

  reportSettings.forEach((settingsrow) => {
    const [cleansedStatistic] = settingsrow.statistic.split('#')
    const useUserSubKey =
      settingsrow.type === MatchpointSettingType.USER_INDEX ||
      settingsrow.type === MatchpointSettingType.USER_ABSOLUTE_VALUE

    const parts = cleansedStatistic.split('@')
    const [prefix, key, subkey] = parts
    const entity =
      prefix === 'allWorkforce'
        ? statistics.census[prefix]
        : prefix === 'house'
        ? statistics.dwellingTypesAndIncomeAverages()
        : prefix === 'spend' || prefix === 'spendSummary'
        ? statistics.lifestyle[prefix][key]
        : statistics.census[key]

    if (cleansedStatistic === 'lifestyle') {
      let maximumKey: string = null
      let maximumValue: number = null
      let maximumStatistic: StatisticsValue

      const lifestyleCategories = ['a', 'b', 'c', 'd']
      lifestyleCategories.forEach((category) => {
        for (let i = 0; i < 5; i++) {
          let subKey = `${category}@${i}`
          const statisticWithSubkey = cleansedStatistic + '@' + subKey

          if (
            BLACKLISTED_DOMINANT_STATISTICS[statisticWithSubkey] ||
            (WHITELISTED_DOMINANT_STATISTICS_GROUPS[settingsrow.statistic] &&
              !WHITELISTED_DOMINANT_STATISTICS_GROUPS[settingsrow.statistic][statisticWithSubkey])
          ) {
            // don't use these
          } else {
            const childStatistic = statistics.lifestyle.personicxSummary[category][i]
            const comparisonValue = childStatistic.percentOverIndex
            if (maximumValue == null || comparisonValue > maximumValue) {
              maximumValue = comparisonValue
              maximumKey = subKey
              maximumStatistic = childStatistic
            }
          }
        }
      })

      if (useUserSubKey) {
        maximumKey = settingsrow.subKey || maximumKey
      }

      result[cleansedStatistic + '@' + maximumKey] = serializeItem(maximumStatistic, settingsrow.statistic, maximumKey)
      // prefix =
    } else if ((prefix === 'demographics' || prefix === 'workforce') && parts.length === 2) {
      let maximumKey: string = null
      let maximumValue: number = null

      Object.keys(entity).forEach((subKey) => {
        const childStatistic = entity[subKey]
        const statisticWithSubkey = cleansedStatistic + '@' + subKey

        if (
          BLACKLISTED_DOMINANT_STATISTICS[statisticWithSubkey] ||
          (WHITELISTED_DOMINANT_STATISTICS_GROUPS[settingsrow.statistic] &&
            !WHITELISTED_DOMINANT_STATISTICS_GROUPS[settingsrow.statistic][statisticWithSubkey])
        ) {
          // don't use these
        } else if (childStatistic && childStatistic.values) {
          const comparisonValue = childStatistic.percentOverIndex
          if (maximumValue == null || comparisonValue > maximumValue) {
            maximumValue = comparisonValue
            maximumKey = subKey
          }
        }
      })

      if (useUserSubKey) {
        maximumKey = settingsrow.subKey || maximumKey
      }

      result[cleansedStatistic + '@' + maximumKey] = serializeItem(
        entity[maximumKey],
        settingsrow.statistic,
        maximumKey
      )
    } else if (settingsrow.statistic === '$$$CatchmentSpend') {
      if (!excludeCatchment) {
        result[settingsrow.statistic] = {
          total: 0, // source && source.other ? +source.other['_CPCatchCGSpend'] : 0
        }
      }
    } else if (prefix === 'allWorkforce') {
      result['census@allWorkforce'] = serializeItem(entity, settingsrow.statistic)
    } else if (prefix === 'allWorkforce') {
      result['census@allWorkforce'] = serializeItem(entity, settingsrow.statistic)
    } else if (prefix === 'house') {
      const subkey = key === 'householsIncome' ? 'totalGross' : 'all'

      result[settingsrow.statistic] = serializeItem(entity[key][subkey], settingsrow.statistic, undefined, false)
    } else {
      result[settingsrow.statistic] = serializeItem(
        subkey && entity[subkey] ? entity[subkey] : entity,
        settingsrow.statistic
      )
    }
  })

  return { dominant: result }
}
