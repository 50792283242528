import { Injectable } from '@angular/core'
import { RESTHelper } from '@targomo/client'
import { Place } from './place'
import { TravelType } from '@targomo/core'

export interface MatchpointSetting {
  statistic: string
  minimum?: number
  maximum?: number
}

@Injectable()
export class MatchpointEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/matchpoint')
  }

  getSimilar(sourcesOriginal: Place[], configuration: any, centerPointId: string) {
    const sources = sourcesOriginal.map((item) => {
      return {
        id: item.id,
        lat: item.lat,
        lng: item.lng,
      }
    })

    return this.http.post<any>(`/report`, { sources, configuration, centerPointId })
  }

  getSimilarExport(places: Place[], configuration: any, maxEdgeWeight: number, travelType: TravelType) {
    return this.http.post<any>(`/exportSimilar`, {
      places: places.map((item) => item.id),
      configuration,
      maxEdgeWeight,
      travelType,
    })
  }

  getOperators(sources: Place[], centerPointId: string) {
    return this.http.post<Place[]>(
      `/operators?centerPointId=${encodeURIComponent(centerPointId)}`,
      sources.map((item) => {
        return {
          id: item.id,
          storeId: item.storeId,
        }
      })
    )
  }

  listUserSettings() {
    return this.http.get<any>(`/settings/user`)
  }

  saveUserSettings(data: any) {
    return this.http.post<any>(`/settings/user`, data)
  }

  deleteUserSettings(id: number) {
    return this.http.delete<any>(`/settings/user/` + id)
  }

  loadUserSettings(id: number) {
    return this.http.get<any>(`/settings/user/` + id)
  }

  getSettings() {
    return this.http.get<any>(`/settings`)
  }

  getSettingsPublic() {
    return this.http.get<any>(`/settings/public`)
  }

  saveSettings(settings: MatchpointSetting[]) {
    return this.http.put<any>(`/settings`, settings)
  }

  jobStatus(versionId: number) {
    return this.http.get<{ value: number; total: number }>('/job/status/' + versionId)
  }
}
