import { AutoUpdateLog, PlanningApplicationDataSet } from '../../common/models'
import { Category } from './category'
import { Injectable } from '@angular/core'
import { RESTHelper } from '@targomo/client'
import {
  PlanningApplicationDataSetVersion,
  SelectFasciasDefinition,
  PlanningApplication,
} from '../../common/models/planningApplication'

export type PlanningApplicationDataSet = PlanningApplicationDataSet

@Injectable()
export class PlanningApplicationDataSetEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/planningApplication')
  }

  get(Set: { id: number }) {
    return this.http.get<PlanningApplicationDataSet>(`/${Set.id}`).then((Set) => {
      // Set.categories = Set.categories.map(category => angular.extend(new Category(), category).init())

      return Set
    })
  }

  getApplication(place: { id: number }) {
    return this.http.get<PlanningApplication>(`/application/${place.id}`).then((Set) => {
      // Set.categories = Set.categories.map(category => angular.extend(new Category(), category).init())

      return Set
    })
  }

  getAutoUpdateLog(set: { id: number }) {
    return this.http.get<AutoUpdateLog>(`/${set.id}/autoupdate`)
  }

  delete(set: { id: number }) {
    return this.http.delete<any>(`/${set.id}`)
  }

  // getCategories(set: {id: number}) {
  //   return this.http.get<Category[]>(`/${set.id}/categories`).then(categories =>
  //     categories.map(category => object.assign(new Category(), category).init())
  //   )
  // }

  // getAvailableProperties(set: {id: number}) {
  //   return this.http.get<string[]>(`/${set.id}/availableProperties`)
  // }

  getCalculatingStatus(set: { id: number }) {
    return this.http.get<string>(`/${set.id}/status`)
  }

  getVersions(set: { id: number }) {
    return this.http.get<PlanningApplicationDataSetVersion[]>(`/${set.id}/versions`)
  }

  saveActive(version: { id: number }, value: boolean = true) {
    return this.http.put(`/version/${version.id}/active/${value}`, {})
  }

  savePreview(version: { id: number }, value: boolean = true) {
    return this.http.put(`/version/${version.id}/preview/${value}`, {})
  }

  deleteVersion(version: { id: number }) {
    return this.http.delete(`/version/${version.id}`)
  }

  save(Set: PlanningApplicationDataSet) {
    return this.http.put<PlanningApplicationDataSet>(`/${Set.id}`, Set)
  }

  saveUserFascias(user: { id: number }, selectedFascias: SelectFasciasDefinition[]) {
    return this.http.put<void>(`/user/${user.id}`, selectedFascias)
  }

  getUserFascias(user: { id: number }) {
    return this.http.get<SelectFasciasDefinition[]>(`/user/${user.id}`)
  }

  saveCompanyFascias(user: { id: number }, selectedFascias: SelectFasciasDefinition[]) {
    return this.http.put<void>(`/company/${user.id}`, selectedFascias)
  }

  getCompanyFascias(user: { id: number }) {
    return this.http.get<SelectFasciasDefinition[]>(`/company/${user.id}`)
  }

  upload(data: string, set?: { id?: number; name?: string }, categorize?: string) {
    if (set && set.id) {
      return this.http.put<number>(`/upload/${set.id}/${categorize}`, data)
    } else {
      return this.http.post<number>(`/upload/new/${encodeURIComponent(set.name)}/${categorize}`, data)
    }
  }

  uploadMultipart(files: any, set?: { id?: number; name?: string }, categorize?: string) {
    const data = new FormData()
    data.append('files', files[0])

    if (set && isFinite(set.id)) {
      return this.http.put<number>(`/upload/${set.id}`, data)
    } else {
      return this.http.post<number>(`/upload/new/${encodeURIComponent(set.name)}`, data)
    }
  }

  findAll() {
    return this.http.get<PlanningApplicationDataSet[]>('')
  }

  findAllFull() {
    return this.http.get<PlanningApplicationDataSet[]>('/full')
  }
  // async me() {
  //   const sets = await this.http.get<PlanningApplicationDataSet[]>('/me')
  //   sets.forEach(set => {
  //     set.categories = set.categories.map(category => {
  //       const result = object.assign(new Category(), category).init()
  //       result.dataSetName = set.name
  //       return result
  //     })
  //   })

  //   return sets.filter(set => !!set.versionId)
  // }

  // async meUnavailable() {
  //   const sets = await this.http.get<PlanningApplicationDataSet[]>('/meUnavailable')
  //   sets.forEach(set => {
  //     set.categories = set.categories.map(category => {
  //       const result = object.assign(new Category(), category).init()
  //       result.dataSetName = set.name
  //       return result
  //     })
  //   })

  //   return sets.filter(set => !!set.versionId)
  // }
}
