import {Place as PlaceCommon, Category, Position}  from '../../common/models'
import {RESTHelper} from '@targomo/client';
import {Injectable} from '@angular/core';
import { DataSetLocation, AbstractLocation } from '../model';
import { DataSet } from '../../common/models/dataSet';
import { Entity } from '../../common/models/entity';

export function canonicalPositions(places: (Place | AbstractLocation)[]) {
  if (!places) {
    return []
  } else {
    return places.map(place => {
      if (place.latAlt === 0 && place.lngAlt === 0) {
        return place
      }

      if (place.latAlt != null || place.lngAlt != null) {
        const result = (place instanceof AbstractLocation ) ? place.copy() : new DataSetLocation(place)
        result.lat = place.latAlt
        result.lng = place.lngAlt
        return result
      } else {
        return (place instanceof AbstractLocation ) ? place.copy() : new DataSetLocation(place)
      }
    })
  }
}

export interface Place extends PlaceCommon {
  category?: Category
  region?: string
  country?: string
  name?: string
  defaultName?: string
  planningLocation?: boolean
  planningValue?: any
  other?: any
  deleted?: boolean
  dataSet?: DataSet
}

export type Position = Position

export class SearchPlace {
  constructor(public position: Position, public name: string, public description: string) {
  }

  full() {
    return [this.name, this.description].join(', ')
  }
}

@Injectable()
export class PlaceEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/places')
  }

  async get(place: {id: number}) {
    if (place && place.id >= 0)
      return await this.http.get<Place>(`/${place.id}`)
    else
      return place
  }

  findAllByCurrentUser() {
    return this.http.get<Place[]>(`/me`)
  }

  async maximums() {
    const result = await this.http.get<any>(`/me/maximums`)

    return {
      netSalesArea: {
        min: result.netSalesAreaMin,
        max: result.netSalesAreaMax,
      },

      grossInternalArea: {
        min: result.grossInternalAreaMin,
        max: result.grossInternalAreaMax,
      },

      dateAdded: {
        min: result.dateAddedMin,
        max: result.dateAddedMax,        
      }
    }
  }

  findAllByCategory(category: Partial<DataSet> & Entity) {
    return this.http.get<Place[]>(`/me/category/${category.id}`)
  }

  findCenterPoint(id: number) {
    return this.http.get<Place[]>(`/me/centerpoint/${id}`)
  }

  async findAllByDataSet(dataSet: DataSet) {
    if (dataSet.id < 0) {
      return []
    }
    const results = await this.http.get<Place[]>(`/me/dataset/${dataSet.id}`)
    if (dataSet.planningDataSet) {
      results.forEach(location => {
        location.dataSet = dataSet
        location.planningLocation = true
        location.planningValue = (location.other && location.other[dataSet.planningValueColumn]) || (<any>location)[dataSet.planningValueColumn] || null
      })
    } else {
      results.forEach(location => {
        location.dataSet = dataSet
      })
    }

    return results
  }


  async findAllByIds(ids: string[]) {
    ids = ids.filter(id => +id >= 0)

    if (!ids || ids.length === 0) {
      return []
    } else {
      return await this.http.post<Place[]>(`/loadByIds`, ids)
    }
  }

  findByText(query: {query: string}) {
    // return this.http.get<SearchPlace[]>(`/search`, {params: query})
    return this.http.get<SearchPlace[]>(`/search?query=${encodeURIComponent(query.query)}`)
  }
}
