import { Entity } from './entity'
import { TravelTypeEdgeWeightOptions, LatLngId } from '@targomo/core'

export class ExportDataRequestZoneSelection {
  name: string
  features: string[]
  layer: number
  selected: boolean
  geometry?: any
  color?: string
  children?: ExportDataRequestZoneSelection[]
  freehand?: boolean
}

export interface VisibleLocationsConfig {
  visiblePlaceIds: string[]
  customPlaces: LatLngId[]
  visibleDataSets: number[]
}

export interface ExportDataRequestBatchConfig {
  name?: string
  sources: LatLngId[]
  zones: ExportDataRequestZoneSelection[]
  travelOptions: TravelTypeEdgeWeightOptions
  intersectionMode: string
  filteredPlaces: LatLngId[]
  exclusiveMode: boolean
  visiblePlaceIds: string[]
  visiblePlanningIds: string[]
  customPlaces: LatLngId[]
  exportType: 'report' | 'competitors'
  visibleDataSets: number[]
}

export enum ExportDataRequestStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  READY = 'ready',
  ERROR = 'error',
}
export interface ExportDataRequest extends Entity {
  owner: number
  progress: number
  total: number
  created: Date
  input: ExportDataRequestBatchConfig
  output: string
  status: ExportDataRequestStatus
  name: string
  type: string
}
