import { AppModel } from '../../model/appModel.service'
import { Subscription } from 'rxjs/Subscription'
import { Place } from '../../api/index'
import { array as arrays } from '@targomo/common'
import { Component, NgZone } from '@angular/core'
import { AbstractLocation } from '../../model'
import { SubscriberComponent } from '@targomo/client'
import { Group, groupByTravelBands } from '../maxi/competitionMaxiReport'
import { COMPETITION_THRESHOLD_COALESCE_LOCATIONS } from '../../model/placesModel'
import { combineLatest } from 'rxjs'
import { ZoneLayersModel } from '../../model/zoneLayersModel'

@Component({
  selector: 'competition-mini-report',
  templateUrl: './competitionMiniReport.html',
  styleUrls: ['./competitionMiniReport.less'],
})
export class CompetitionMiniReportComponent extends SubscriberComponent {
  // The application wide model keeping track of selected and visible places
  loading: Promise<Place[]>
  travelTimeDistanceSorting: 'travelTime' | 'shortestTravelDistance' | 'fastestTravelDistance' = 'travelTime'
  // The list of places that are to be rendered in the view
  places: AbstractLocation[] = []
  hoverPlace: AbstractLocation
  groups: Group[] = null
  totals: Group = null

  constructor(private appModel: AppModel, private zone: NgZone, readonly zoneLayersModel: ZoneLayersModel) {
    super()
  }

  ngOnInit() {
    this.watch(this.appModel.settings.travelTimeDistanceSortingUpdates, (travelTimeDistanceSorting) => {
      this.travelTimeDistanceSorting = travelTimeDistanceSorting
    })

    this.watch(this.appModel.places.hoverPlace, (place) => {
      if (this.hoverPlace !== place) this.zone.run(() => (this.hoverPlace = place))
    })

    this.watch(this.appModel.places.reachableFilteredPlacesNonPassive.promise, (promise) => (this.loading = promise))

    this.watch(
      combineLatest(
        this.appModel.places.reachableFilteredPlacesNonPassive.value,
        this.zoneLayersModel.selectionExistsUpdates,
        this.appModel.settings.edgeWeightsUpdates,
        this.appModel.settings.travelTimeDistanceSortingUpdates
      ),
      ([places, selectionExists, edgeWeights, travelTimeDistanceSorting]) => {
        this.places = arrays
          .sortBy(places, travelTimeDistanceSorting)
          .filter(
            (location: any) => location[travelTimeDistanceSorting] != null && location[travelTimeDistanceSorting] > -1
          )

        if (places.length > COMPETITION_THRESHOLD_COALESCE_LOCATIONS) {
          const { groups, totals } = groupByTravelBands(places, selectionExists, edgeWeights)
          this.groups = groups
          this.totals = totals
        } else {
          this.groups = null
          this.totals = null
        }
      }
    )
  }

  hover(place: AbstractLocation) {
    this.hoverPlace = place
    this.appModel.places.hoverPlace.next(place)
  }

  click(place: AbstractLocation) {}
}
