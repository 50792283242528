<ng-container *ngIf="!!model">
  <table>
    <tr class="total-row">
      <td colspan="3">{{model.population.name}}</td>
      <td>{{model.population.total | number: '1.0-0'}}</td>
    </tr>

    <tr>
      <th width="50%">Segment</th>
      <th>%</th>
      <th>Ind vs {{statistics && statistics.regionLabel()}} Avg</th>
      <th>Total</th>
    </tr>

    <tr *ngFor='let row of values'>
      <td width="50%">{{row.name}}</td>
      <td>{{row.percent | number: '1.1-1'}}</td>
      <td>{{row.percentOverIndex | number: '1.0-0'}}</td>
      <td>{{row.total | number: '1.0-0'}}</td>
    </tr>

    <tr class="total-row">
      <td colspan="3">{{model.bedRequirement.name}}</td>
      <td>{{model.bedRequirement.total | number: '1.0-0'}}</td>
    </tr>


  </table>
</ng-container>