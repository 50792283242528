import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { environment } from './environments/environment';

try {
  if (window.location.search && window.location.search.indexOf('disableStatistics=true') > -1) {
    localStorage['STOREPOINTGEO_DISABLE_STATISTICS'] = true
  } else if (window.location.search && window.location.search.indexOf('disableStatistics=false') > -1) {
    delete localStorage['STOREPOINTGEO_DISABLE_STATISTICS']
  }
} catch (e) {
}

// if (environment.production) { 
// if (window.location.hostname !== 'localhost') {
  enableProdMode()
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err))
