import * as parseCsv from 'csv-parse'

export function parseZoneCsv(content: string): Promise<any[][]> {
  return new Promise((resolve, reject) => {
    parseCsv(content, (err: any | Error, output: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(output)
      }
    })
  })
}