import { Injectable } from '@angular/core'
import { RESTHelper } from '@targomo/client'
import { BoundingBox } from '@targomo/core'

export class GeoRegion {
  id?: number
  name: string
}

export class GeoRegionVersion {
  id?: number
  created: string
}

@Injectable()
export class RoadsVolumeEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/thematic/roads')
  }

  breakpoints(bounds: BoundingBox, zoom: number) {
    return this.http.post<any>(`/breakpoints`, { bounds, zoom })
  }
}
