import { Component } from '@angular/core'
import { of } from 'rxjs'
import { SettingsModel, TravelDisplayMode } from '../../../model/settingsModel'
import { OptionsDialogComponent } from '../optionsDialog/optionsDialog.component'

@Component({
  selector: 'travel-thematics-options-panel',
  templateUrl: './travelThematicsOptionsPanel.component.html',
  styleUrls: ['./travelThematicsOptionsPanel.component.less'],
})
export class TravelThematicsOptionsPanelComponent {
  disabledTravelDisplay$ = of(false)

  readonly options = [
    { name: 'No Thematic, Isochrone Travel-Times', value: TravelDisplayMode.NoThematicPolygons },
    { name: 'Thematic Background, Inverted Isochrones', value: TravelDisplayMode.ThematicPolygonsInverted },
    { name: 'Thematic Background, Hex Travel-Times', value: TravelDisplayMode.ThematicNoPolygons },
  ]
  constructor(readonly settings: SettingsModel, readonly parent: OptionsDialogComponent) {}

  changePedestrianLayer(value: string) {
    this.settings.displaySettings.nextProperty('pedestrianLayer', value)
  }

  changeWorkforceLayer(value: string) {
    this.settings.displaySettings.nextProperty('workforceLayer', value)
  }

  changeRoadsVolumeLayer(value: string) {
    this.settings.displaySettings.nextProperty('roadsVolumeLayer', value)
  }

  changeSelection(event: any) {
    this.settings.displaySettings.nextProperty('travelDisplayMode', event.value)
  }

  changeRoadsVolumeLayerBreaks(value: string) {
    this.settings.displaySettings.nextProperty('roadsVolumeLayerViewportBreaks', value)
  }

  changePostcodesAnalysisLayer(value: string) {
    this.settings.displaySettings.nextProperty('postcodesAnalysisLayer', value)
  }
}
