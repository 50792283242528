import { PDFHelper } from './helper'
import { Group } from '../maxi/competitionMaxiReport'
import { Category, Place } from '../../api/index'
import { array as arrays } from '@targomo/common'
import * as colors from '@targomo/client'
import { TRAVEL_COLORS } from '@targomo/client'
import { AbstractLocation } from '../../model/entities/place'
import {
  COMPETITION_THRESHOLD_COALESCE_LOCATIONS,
  COMPETITION_THRESHOLD_COALESCE_SOURCES,
} from '../../model/placesModel'
import { categorizeLocations, OperatorCategorized } from '../../model/operatorsWithinModel'

const COALESCED_COLUMNS = {
  '': true,
  fascia: true,
  primaryCategory: true,
  secondaryCategory: true,
  grossInternalArea: true,
  netSalesArea: true,
}

const COLUMNS_MAP: { [index: string]: string } = {
  fascia: 'Name',
  holdingCompany: 'Company',
  primaryCategory: 'Primary Category',
  secondaryCategory: 'Secondary Category',
  street: 'Street',
  town: 'Town',
  postcode: 'Postcode',
  grossInternalArea: 'GIA (sq ft)',
  netSalesArea: 'NSA (sq ft)',
  travelTime: 'Time (mins)',
}

const COLUMNS_WITH_TRAVEL: any[] = [
  '',
  { text: 'Name', style: 'defaultCellLeftNoMargin' },
  'Street',
  'Town',
  'Postcode',
  'Time (mins)',
  'GIA (sq ft)',
  'NSA (sq ft)',
]
const COLUMN_KEYS_WITH_TRAVEL: any[] = [
  '',
  'fascia',
  'street',
  'town',
  'postcode',
  'travelTime',
  'grossInternalArea',
  'netSalesArea',
]

const COLUMNS_WITHOUT_TRAVEL: any[] = [
  '',
  { text: 'Name', style: 'defaultCellLeftNoMargin' },
  'Street',
  'Town',
  'Postcode',
  'GIA (sq ft)',
  'NSA (sq ft)',
]

const COLUMN_KEYS_WITHOUT_TRAVEL: any[] = [
  '',
  'fascia',
  'street',
  'town',
  'postcode',
  'grossInternalArea',
  'netSalesArea',
]

export function competitionReport(
  places: AbstractLocation[],
  groups: Group[],
  sources: AbstractLocation[],
  totals: Group,
  helper: PDFHelper,
  zonesVisible: boolean,
  useColumns: string[],
  planningReport = false,
  useColumnsLabels?: string[],
  useCoalesceColumns?: string[],
  categorizeLocationsCustom: (locations: AbstractLocation[]) => OperatorCategorized[] = categorizeLocations,
  shouldCoalesce = true
) {
  const result: any[] = []
  let tableRows: any[][] = []

  const coalesceSources = !!sources && shouldCoalesce && sources.length > COMPETITION_THRESHOLD_COALESCE_SOURCES
  const coalescePlaces = !!places && shouldCoalesce && places.length > COMPETITION_THRESHOLD_COALESCE_LOCATIONS

  if (!zonesVisible && sources) {
    tableRows = tableRows.concat(
      placesGroupAsTable(
        'Origins',
        null,
        sources,
        helper,
        null,
        {},
        zonesVisible,
        useColumns,
        false,
        coalesceSources,
        useColumnsLabels,
        useCoalesceColumns,
        categorizeLocationsCustom
      )
    )
  }

  const OPTIONS: any = [
    { background: ['#eef5f2', '#f8fbfa'], border: '#d2d8d6' },
    { background: ['#f2faf3', '#fafdfa'], border: '#dddfdd' },
    { background: ['#f7fbf2', '#fcfdfa'], border: '#e1e1e1' },
    { background: ['#fef8f0', '#fffcf9'], border: '#e6e6e6' },
    { background: ['#fef4f0', '#fffaf9'], border: '#e0d7d4' },
    { background: ['#fbf1f1', '#fdf9f9'], border: '#dfdcdc' },
  ]

  if (coalesceSources !== coalescePlaces) {
    const options: any = {}

    options.background = tableRows.map((row) => {
      return (row[0] && row[0].cellBackground) || '#ffffff'
    })

    options.widths = function (cells: any): any {
      return cells.map((value: any, i: number) => {
        if (i == 0) {
          return <any>12
        } else if (i == 1) {
          return '*'
        } else {
          return 'auto'
        }
      })
    }

    if (tableRows.length > 0) {
      result.push(helper.table(null, tableRows, 'defaultTable', options))
    }
    tableRows = []
  }

  groups.forEach((group, i) => {
    const options = { ...OPTIONS[i] }
    tableRows = tableRows.concat(
      placesGroupAsTable(
        zonesVisible ? '' : group.name,
        group,
        group.values,
        helper,
        i == groups.length - 1 ? totals : null,
        options,
        zonesVisible,
        useColumns,
        planningReport,
        coalescePlaces,
        useColumnsLabels,
        useCoalesceColumns,
        categorizeLocationsCustom
      )
    )
  })

  if (tableRows.length) {
    const options: any = {}

    options.background = tableRows.map((row) => {
      return (row[0] && row[0].cellBackground) || '#ffffff'
    })

    options.widths = function (cells: any): any {
      return cells.map((value: any, i: number) => {
        if (i == 0) {
          return <any>12
        } else if (i == 1) {
          return '*'
        } else {
          return 'auto'
        }
      })
    }

    result.push(helper.table(null, tableRows, 'defaultTable', options))
  }

  return result
}

function placesGroupAsTable(
  title: string,
  group: Group,
  places: AbstractLocation[],
  helper: PDFHelper,
  totals: Group,
  options: any,
  zonesVisible: boolean,
  useColumns: string[],
  planningReport: boolean,
  coalesce: boolean,
  useColumnsLabels?: string[],
  useCoalesceColumns?: string[],
  categorizeLocationsCustom?: (locations: AbstractLocation[]) => OperatorCategorized[]
) {
  const coalescedColumns: Record<string, boolean> = { ...COALESCED_COLUMNS }

  if (useCoalesceColumns) {
    useCoalesceColumns.forEach((key) => {
      coalescedColumns[key] = true
    })
  }

  let columns: any[] = zonesVisible ? COLUMNS_WITHOUT_TRAVEL : COLUMNS_WITH_TRAVEL
  let columnsKeys: any[] = zonesVisible ? COLUMN_KEYS_WITHOUT_TRAVEL : COLUMN_KEYS_WITH_TRAVEL

  const placesFascias = categorizeLocationsCustom(places)

  if (useColumns && useColumns.length) {
    columns = [
      {
        text: '',
        isSectionHeader: true,
        cellBorder: options && options.border,
        cellBackground: (options && options.background && options.background[0]) || '#fafafa',
      },
    ]
    columnsKeys = ['']

    const useColumnsMap = useColumns.reduce((acc, cur) => {
      acc[cur] = true
      return acc
    }, {} as { [id: string]: boolean })

    let columnsLabelsMap = { ...COLUMNS_MAP }
    if (useColumnsLabels) {
      useColumnsLabels.forEach((name, i) => {
        const key = useColumns[i]
        if (name) {
          columnsLabelsMap[key] = name
        }
      })
    }

    Object.keys(columnsLabelsMap)
      .filter((key) => useColumnsMap[key])
      .forEach((key) => {
        if (key === 'travelTime' && zonesVisible) {
          return
        }

        columnsKeys.push(key)
        const cellBackground = options && options.background && options.background[0]

        if (key === 'fascia') {
          columns.push({ cellBackground, isSectionHeader: true, text: 'Name', style: 'defaultCellLeftNoMargin' })
        } else {
          columns.push({ cellBackground, isSectionHeader: true, text: columnsLabelsMap[key], style: 'defaultCell' })
        }
      })
  }

  if (planningReport) {
    columns = columns.concat(['Value'])
    columnsKeys = columnsKeys.concat(['planningValue'])
  }

  const columnsFascias: any[] = []
  const columnsKeysFascias: any[] = []

  // Columns when locations are grouped by category
  columnsKeys.forEach((key, i) => {
    if ((<any>coalescedColumns)[key]) {
      columnsFascias.push(columns[i])
      columnsKeysFascias.push(columnsKeys[i])

      if (key === 'fascia') {
        columnsFascias.push('Count')
        columnsKeysFascias.push('count')
      }
    }
  })

  if (coalesce) {
    columns = columnsFascias
    columnsKeys = columnsKeysFascias
  }

  function colorSquare(color: string, category: Category) {
    return {
      style: 'defaultCellColorBox',
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 10,
          h: 10,
          color: color || (category && category.color) || '#f6f6f6',
        },
      ],
    }
  }

  const propertiesAsList = (object: any, i: number) => {
    function decorateBackground(cell: any) {
      if (options && options.background && typeof cell === 'object') {
        cell.cellBackground = options.background[(i + 1) % options.background.length]
      }
      return cell
    }

    return columnsKeys
      .map((column) => {
        if (column == '') {
          return colorSquare(object && object.properties && object.properties['marker-type'], object && object.category)
        } else if (column == 'fascia') {
          // return { text: helper.formatCell(object[column]), style: 'defaultCellLeftNoMargin' }
          return { text: helper.formatCell(object.defaultName), style: 'defaultCellLeftNoMargin' }
          // return [, helper.formatCell(object[column])]
        } else if (column == 'travelTime') {
          return helper.formatMinutes(object[column])
        } else if (column in object) {
          return helper.formatCell(object[column])
        } else {
          return helper.formatCell(object.properties[column])
        }
      })
      .map(decorateBackground)
  }

  const fasciasAsList = (object: OperatorCategorized, i: number) => {
    function decorateBackground(cell: any) {
      if (options && options.background && typeof cell === 'object') {
        cell.cellBackground = options.background[(i + 1) % options.background.length]
      }
      return cell
    }

    return columnsKeysFascias
      .map((column) => {
        if (column == '') {
          return colorSquare(object && object.properties && object.properties['marker-type'], object.category)
        } else if (column == 'fascia') {
          return { text: helper.formatCell(object.category.name), style: 'defaultCellLeftNoMargin' }
        } else if (column == 'primaryCategory') {
          return helper.formatCell(object.category.grouping)
        } else if (column == 'secondaryCategory') {
          return helper.formatCell(object.category.name)
        } else if (column == 'grossInternalArea') {
          return helper.formatCell(object.grossInternalArea)
        } else if (column == 'netSalesArea') {
          return helper.formatCell(object.netSalesArea)
        } else if (column == 'count') {
          return helper.formatCell(object.count)
        } else {
          return helper.formatCell((object as any)[column])
        }
      })
      .map(decorateBackground)
  }

  const rows = coalesce
    ? placesFascias.map((row, i) => <any>fasciasAsList(row, i))
    : places.map((row, i) => <any>propertiesAsList(row, i))

  // const shouldHaveTotals = columnsKeys.filter(key => key === 'netSalesArea' || key === 'grossInternalArea').length > 0
  const shouldHaveTotals = columnsKeys.filter((key) => group && group.keysMap[key]).length > 0

  if (shouldHaveTotals) {
    const createTotalsRow = (label: string, values: Group) => {
      let found = false

      const result: any[] = []
      columnsKeys.forEach((key, i) => {
        const isTotalKey = values && values.keysMap[key] // key === 'netSalesArea' || key === 'grossInternalArea'
        if (isTotalKey) {
          if (!found) {
            result.push({ text: label, style: 'defaultCell', colSpan: i, total: true, bold: true })
            for (let j = 1; j < i; j++) {
              result.push('')
            }
          }

          if (values.keysMap[key]) {
            result.push(helper.formatCell(values.totals[key]))
          }
          // if (key === 'netSalesArea') {
          //   result.push(helper.formatCell(values.netSalesArea))
          // } else if  (key === 'grossInternalArea') {
          //   result.push(helper.formatCell(values.grossInternalArea))
          // }

          found = true
        } else if (found) {
          result.push('')
        }
      })

      return result
    }

    if (group) {
      rows.push(createTotalsRow('Total', group))
    }

    if (totals) {
      rows.push(createTotalsRow('Grand Total', totals))
    }
  }

  options.cellTransform = helper.cellLeftRight(1)
  const groupHeader: any[] = columns.map((row) => '')
  if (title) {
    groupHeader[0] = { text: title, style: 'subheader', colSpan: groupHeader.length, cellBackground: '#ffffff' }
  }

  return [].concat([groupHeader]).concat([columns]).concat(rows)
}

export function placesByMinutes(
  places: AbstractLocation[],
  zonesVisible: boolean,
  minutesFactor: number,
  keys?: string[],
  maxMinutes?: number
) {
  const groups: Group[] = []
  const totals = new Group('', 'white', undefined, keys)
  const levelMultiplier = minutesFactor / 60 //

  places.forEach((place) => {
    ;(<any>place).travelTimeMinute = Math.max(0, Math.floor(Math.ceil((place.travelTime || 0) / minutesFactor) - 1))
    const level = Math.max(0, Math.floor((Math.ceil((place.travelTime || 0) / minutesFactor) - 1) / 5))

    if (!isFinite(level)) return

    if (!groups[level]) {
      const upperMinutesLabel = !zonesVisible && (level + 1) * levelMultiplier * 5
      const maxMinutesLabel = maxMinutes && !zonesVisible ? Math.min(maxMinutes, upperMinutesLabel) : upperMinutesLabel

      const title = zonesVisible ? 'In selected zone' : `${level * levelMultiplier * 5} to ${maxMinutesLabel} minutes`
      groups[level] = new Group(title, TRAVEL_COLORS[level], (level + 1) * 5 * levelMultiplier, keys)
    }

    groups[level].add(place)
    totals.add(place)
  })

  groups.forEach((group) => {
    group.values = arrays.sortBy(group.values, ['travelTime', 'fascia'])
  })

  for (var i = groups.length - 1; i >= 0; i--) {
    if (groups[i]) {
      groups[i].isLast = true
      return { groups: groups, totals: totals }
    }
  }

  return { groups: groups, totals: totals }
}
