import { Injectable } from "@angular/core";
import { AppModel } from "./appModel.service";
import { CookieService } from "ngx-cookie-service";
import { UserSettingsPersistenceService } from "./userSettingsPersitenceService";

const PROJECT_NUMBER_COOKIE = 'spg_pnum';
const PROJECT_NUMBER_DISMISSED = 'spg_pnum_dismissed';

@Injectable()
export class ProjectNumberModel {
  constructor(
    private appModel: AppModel,
    private cookies: CookieService,
    private userSettingsPersistenceService: UserSettingsPersistenceService
  ) {
  }

  hasProjectNumber() {
    try {
      if (sessionStorage[PROJECT_NUMBER_DISMISSED]) {
        return true
      }
    } catch (e) {
    }

    return !!this.cookies.get(PROJECT_NUMBER_COOKIE)
  }

  setProjectNumber(projectNumber: string) {
    projectNumber = (projectNumber || '').trim()
    this.cookies.set(PROJECT_NUMBER_COOKIE, projectNumber, undefined, '/')
    this.appModel.settings.displaySettings.nextProperty('projectNumber', projectNumber)
    this.dismissProjectNumber()
  }

  async getSuggestedProjectNumber() {
    await this.userSettingsPersistenceService.ready()
    return this.appModel.settings.displaySettings.getValue().projectNumber
  }

  clearProjectNumber() {
    this.cookies.delete(PROJECT_NUMBER_COOKIE, '/')

    try {
      delete sessionStorage[PROJECT_NUMBER_DISMISSED]
    } catch (e) {
    }
  }

  dismissProjectNumber() {
    try {
      sessionStorage[PROJECT_NUMBER_DISMISSED] = 'true'
    } catch (e) {
    }
  }
}