<div class="admin-panel">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Data Sets</h2>
    <div class="secondary">
      <button mat-button class="mat-primary" routerLink="/admin/dataset/upload"><mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> Upload New Data Set</button>
    </div>
  </div>    


  <mat-list>
    <!-- <mat-divider></mat-divider> -->
    <mat-list-item *ngFor="let set of dataSets" routerLink="/admin/dataset/{{set.id}}">
      <div class="name-section" fxFlex fxLayout="column" >
        <span>{{set.name}}</span>
      </div>

      <button (click)="showMenu($event)" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="mat-24">more_vert</mat-icon></button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/admin/dataset/{{set.id}}" aria-label="Edit">
          <mat-icon mat-font-set="material-icons">mode_edit</mat-icon>
          Edit Data Set
        </button>
      </mat-menu>
        <!--<mat-menu-content>
          <mat-menu-item>
            <button mat-button routerLink="/admin/datasset/{{set.id}}" aria-label="Edit">
              <mat-icon mat-font-set="material-icons">mode_edit</mat-icon>
              Edit Data Set
            </button>
          </mat-menu-item>
        </mat-menu-content>
      </mat-menu>-->

      <!-- <mat-divider></mat-divider> -->
    </mat-list-item>
  </mat-list>
</div>