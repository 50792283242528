import { Component, OnInit } from '@angular/core'
import { SettingsModel } from '../../../model/settingsModel'
import { OptionsDialogComponent } from '../optionsDialog/optionsDialog.component'

@Component({
  selector: 'zones-options-panel',
  templateUrl: './zonesOptionsPanel.component.html',
  styleUrls: ['./zonesOptionsPanel.component.less'],
})
export class ZonesOptionsPanelComponent {
  constructor(readonly settings: SettingsModel, readonly parent: OptionsDialogComponent) {}
}
