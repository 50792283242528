<tgm-loadable [model]="model?.loading$ | async">
  <div fxLayout="row">
    <div flex="30%">
      <h3>Centre Categories</h3>
      <mat-list *ngIf="categories$">
        <mat-list-item (click)="model.toggleCategorySelectedAll()">
          <mat-checkbox [checked]="model.isCategorySelectedAll()" style="pointer-events: none"></mat-checkbox>
          <b>Select All</b>
        </mat-list-item>

        <mat-list-item *ngFor="let profile of categories$ | async" (click)="model.toggleCategorySelected(profile)">
          <mat-checkbox [checked]="model.isCategorySelected(profile)" style="pointer-events: none"></mat-checkbox>
          {{ profile }}
        </mat-list-item>
      </mat-list>
    </div>

    <div flex="70%" style="width: 70%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <h3 fxFlex>Similar Centres</h3>
        <div style="margin: 0px 8px">
          <button (click)="downloadCsv()" mat-button>Download List as CSV</button>
        </div>

        <!-- <mat-checkbox (change)="model.setIncludeCatchment($event.checked)" [checked]="model.includeCatchment$ | async"
          >Include Catchment</mat-checkbox
        > -->
      </div>

      <div class="search-panel">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field fxFlex>
            <input
              matInput
              [(ngModel)]="filter"
              (ngModelChange)="filterChanged($event)"
              name="Filter"
              placeholder="Filter"
            />
          </mat-form-field>

          <button (click)="filterChanged(null)" title="Clear search" type="button" mat-icon-button>
            <mat-icon class="mat-20">clear</mat-icon>
          </button>
        </div>
      </div>

      <div *ngIf="placesTruncated" class="truncated-hint">
        Showing only first 100 of total {{ placesTotal }} locations matched
      </div>

      <table
        style="width: 100%"
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)"
        matSortActive="name"
        matSortDirection="asc"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="matchpointSitenameOrFascia">Name</th>
          <td mat-cell *matCellDef="let row">
            <span class="checkbox" [style.background]="row.category.color || '#ddd'"></span>
            {{ row.defaultName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="secondaryCategory">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="secondaryCategory">Category</th>
          <td mat-cell *matCellDef="let row">{{ row.secondaryCategory }}</td>
        </ng-container>

        <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="street">Street</th>
          <td mat-cell *matCellDef="let row">{{ row.street }}</td>
        </ng-container>

        <ng-container matColumnDef="town">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="town">Town</th>
          <td mat-cell *matCellDef="let row">{{ row.town }}</td>
        </ng-container>

        <ng-container matColumnDef="postcode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="postcode">Postcode</th>
          <td mat-cell *matCellDef="let row">{{ row.postcode }}</td>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>Whitelist</th>
          <td mat-cell *matCellDef="let row" class="clickable-cell" (click)="model.toggleSimilarSelected(row)">
            <mat-checkbox [checked]="model.isSimilarSelected(row)" style="pointer-events: none"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="blacklist">
          <th mat-header-cell *matHeaderCellDef>Blacklist</th>
          <td mat-cell *matCellDef="let row" class="clickable-cell" (click)="model.toggleSimilarBlacklisted(row)">
            <mat-checkbox [checked]="model.isSimilarBlacklisted(row)" style="pointer-events: none"></mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          [class.unselected]="!model.isSimilarSelectedForAnalysis(row)"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </div>
</tgm-loadable>
