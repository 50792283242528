import { Injectable } from '@angular/core'
import { RESTHelper } from '@targomo/client'
import { ExportDataRequest } from '../../common/models/exportDataRequest'

@Injectable()
export class ExportAllEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/exportAll')
  }

  download(id: number) {
    return this.http.get<{ output: string }>(`/` + id)
  }

  delete(id: number) {
    return this.http.delete<any>(`/${id}`)
  }

  create(data: any) {
    return this.http.post<any>(`/`, data)
  }

  list() {
    return this.http.get<Partial<ExportDataRequest>[]>(`/`)
  }
}
