import { DataSet } from "../../../api/dataSet";
import { combineLatest } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { DataLoadingModel } from "../../dataLoadingModel";

export function allCentrePoints(
  placesLoadingModel: DataLoadingModel
) {
  return combineLatest(
    placesLoadingModel.dataSets,
    placesLoadingModel.allPlaces
  ).pipe(
    map(([dataSets, places]) => {
      const centrePointDataSets: {[id: number]: DataSet} = {}
      ; (dataSets || []).forEach(dataSet => {
        const name = (dataSet.name || '').toUpperCase()
        if (name.indexOf('CENTREPOINT') > -1) {
          centrePointDataSets[dataSet.id] = dataSet
        }
      })

      return (places || []).filter(place => {
        return place.dataSet && centrePointDataSets[place.dataSet.id]
      })
    }),
    shareReplay(1)
  )  
}