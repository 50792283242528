<mat-list>

  <mat-list-item class="top-level"  (click)="toggle('visible')">
    <div class="location-item" fxFlex fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [class.toggled]="state['visible']" class="animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
      <div fxFlex>Within</div>
      <button fxLayout="row" fxLayoutAlignn="start center" fxLayoutGap="0.5rem" (click)="exportCsvWithin($event)" mat-button><mat-icon>get_app</mat-icon><span>Export as CSV</span></button>
    </div>
  </mat-list-item>
  
  <div  class="second-level" *ngIf="state['visible']">
    <div style="padding: 8px;" *ngIf="(withinList$ | async)?.length == 0">No locations</div>
  
    <mat-list-item *ngFor="let item of withinList$ | async">
      <div class="location-item" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <span class="checkbox" [style.background]="item.category.color || '#ddd'"></span>
        <div fxFlex>{{item.category.name}}</div>
        <div class="count">{{item.count | number}}</div>
        <div class="count-total"> ({{item.category?.placesCount | number}})</div>
      </div>
    </mat-list-item>
  </div>
  
  
  <mat-list-item class="top-level"  (click)="toggle('invisible')">
      <div class="location-item" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [class.toggled]="state['invisible']" class="animation-rotate" mat-font-set="material-icons">keyboard_arrow_right</mat-icon>
        <div fxFlex>Not Within</div>
        <button fxLayout="row" fxLayoutAlignn="start center" fxLayoutGap="0.5rem" (click)="exportCsvNotWithin($event)" mat-button><mat-icon>get_app</mat-icon><span>Export as CSV</span></button>
      </div>
    </mat-list-item>
        
    <div  class="second-level" *ngIf="state['invisible']">
      <div style="padding: 8px;" *ngIf="(notWithinList$ | async).length == 0">No locations</div>
  
      <mat-list-item *ngFor="let item of notWithinList$ | async">
        <div class="location-item" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <span class="checkbox" [style.background]="item.category.color || '#ddd'"></span>
          <div fxFlex>{{item.category.name}}</div>
          <div class="count">{{item.count | number}}</div>
          <div class="count-total"> ({{item.category?.placesCount | number}})</div>
        </div>
      </mat-list-item>
    </div>  
  
  </mat-list> 
  