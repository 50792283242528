import './newLocationDialog.less'

import { Component, Input, OnInit, OnChanges, SimpleChange } from '@angular/core'
import { MatDialogRef, MatDialog } from '@angular/material'
import { DataSetLocation } from '../../model/index'
import { AppModel } from '../../model/appModel.service'
import { Place } from '../../api/place'

@Component({
  selector: 'new-location-dialog',
  template: `
    <section>
      <h3 mat-dialog-title translate="layers.addLocation">{{ editing }} Location</h3>

      <form (ngSubmit)="save()">
        <div fxLayout="column">
          <mat-form-field>
            <input matInput [(ngModel)]="model.properties.name" name="name" placeholder="Name" required />
          </mat-form-field>

          <mat-form-field>
            <input matInput [(ngModel)]="model.properties.street" name="street" placeholder="Street" />
          </mat-form-field>

          <mat-form-field>
            <input matInput [(ngModel)]="model.properties.town" name="town" placeholder="Town" />
          </mat-form-field>

          <mat-form-field>
            <input matInput [(ngModel)]="model.properties.postcode" name="postcode" placeholder="Postcode" />
          </mat-form-field>

          <mat-form-field>
            <input type="number" matInput [(ngModel)]="model.properties.gia" name="gia" placeholder="GIA (sq ft)" />
          </mat-form-field>

          <mat-form-field>
            <input type="number" matInput [(ngModel)]="model.properties.nsa" name="nsa" placeholder="NSA (sq ft)" />
          </mat-form-field>
        </div>

        <div class="dialog-bottom">
          <button mat-button (click)="dialogRef.close()" mat-dialog-close>
            <span translate="dialog.cancel">Cancel</span>
          </button>
          <button mat-raised-button color="primary" mat-button><span translate="dialog.save">Save</span></button>
        </div>
      </form>
    </section>
  `,
})
export class NewLocationDialog {
  model: any = <any>{}
  editing: string = 'Add'

  constructor(public dialogRef: MatDialogRef<NewLocationDialog>, readonly appModel: AppModel) {}

  public show(location: Place, editing = 'Add') {
    this.model = location
    this.editing = editing
  }

  save(event?: any) {
    this.dialogRef.close(this.model)
  }
}
