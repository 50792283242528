import { Component, Input } from '@angular/core'
import { Category } from '../../../../../common/models'

@Component({
  selector: 'category-icon',
  templateUrl: './categoryIcon.component.html',
  styleUrls: ['./categoryIcon.component.less'],
})
export class CategoryIconComponent {
  @Input() category: Category = null
}
