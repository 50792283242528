import {Observable, Subject} from 'rxjs'


function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return 'touches' in event && !('clientX' in event)
}

function mousePointFromEvent(event: MouseEvent | TouchEvent) {
  if ((<any>event).point) {
    return (<any>event).point
  }

  if ((<any>event).originalEvent && !(<any>event).clientX) {
    event = (<any>event).originalEvent
  }

  if (isTouchEvent(event)) {
      if (!event.touches[0]) {
        return {x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY}
      } else {
        return {x: event.touches[0].clientX, y: event.touches[0].clientY}
      }
  } else {
      return {x: event.clientX, y: event.clientY}
  }
}

export function fire<U>(target: Observable<U>, event: U) {
  (target as Subject<U>).next(event)
}

export function mousePoint(event: MouseEvent | TouchEvent) {
  return mousePointFromEvent(event)
}
