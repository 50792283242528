import { Component, Input, OnInit, OnChanges, SimpleChange, Inject, Optional } from '@angular/core'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material'
import { AppModel } from '../../../model/appModel.service'

@Component({
  selector: 'icon-select-dialog',
  templateUrl: './iconSelectDialog.component.html',
  styleUrls: ['./iconSelectDialog.component.less'],
})
export class IconSelectDialog {
  selected = 0

  readonly ICONS = Array.from({ length: 14 }).map((item, i) => {
    return { id: i, file: `marker_icons/marker-${i}.svg` }
  })

  constructor(
    public dialogRef: MatDialogRef<IconSelectDialog>,
    private appModel: AppModel,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {}

  ngOnInit() {}

  save(event?: any) {
    this.dialogRef.close({ icon: this.selected })
  }

  select(icon: { id: number }) {
    this.selected = icon.id
  }
}
