<div class="admin-panel">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Edit Applications Data Data Set</h2>
    <div class="secondary">
      <button mat-button class="mat-primary" routerLink="/admin/planningApplications/upload/{{ id }}">
        <mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> Upload New Layer Version
      </button>
    </div>
  </div>

  <form [formGroup]="form" class="form" name="form" (submit)="save(form)" novalidate style="margin-bottom: 30px">
    <section fxLayout="column">
      <mat-form-field>
        <input placeholder="Name" matInput type="text" formControlName="name" />
      </mat-form-field>
    </section>
    <!-- 
    <section style="margin-top: 20px; width: 100%">
      <h3 class="mat-title subtitle">Auto Update</h3>
      <div fxLayout="row" style="margin: 8px 0px">
        <div fxFlex>Auto update enabled</div>
        <mat-checkbox placeholder="Auto update enabled" formControlName="autoUpdate"> </mat-checkbox>
      </div>

      <div style="width: 100%" [hidden]="!form.value.autoUpdate">
        <mat-form-field style="width: 100%">
          <input placeholder="Auto Update URL" matInput type="text" formControlName="autoUpdateURL" />
        </mat-form-field>
      </div>
    </section> -->

    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-button type="button" (click)="back()">&larr; Back</button>
      <button mat-flat-button color="primary" type="submit">Save</button>
    </div>
  </form>

  <!-- <section class="auto-update-info" *ngIf="set.autoUpdate">
    <table>
      <tr>
        <td>Last AutoUpdate Attempt:</td>
        <td>{{ autoUpdateLog?.startTime | date: 'medium' }}</td>
      </tr>
      <tr>
        <td>Last AutoUpdate Success:</td>
        <td>{{ autoUpdateLog?.successTime | date: 'medium' }}</td>
      </tr>
      <tr>
        <td>Last AutoUpdate Failure:</td>
        <td>{{ autoUpdateLog?.failureTime | date: 'medium' }}</td>
      </tr>
      <tr>
        <td colspan="2">
          <div style="margin-bottom: 8px">Last AutoUpdate Message:</div>
          <code>{{ autoUpdateLog?.message }}</code>
        </td>
      </tr>
    </table>
  </section> -->

  <section style="margin-bottom: 20px">
    <h3 class="mat-title subtitle">Versions</h3>
    <admin-versions
      [endpoint]="planningEndpoint"
      listUrl="/admin/planningApplications"
      (updated)="updatedVersion()"
      [id]="id"
    ></admin-versions>
  </section>

  <section style="margin-bottom: 20px; margin-top: 40px">
    <h3 class="mat-title subtitle">Calculate for Fascias</h3>

    <div class="status" *ngIf="status">
      <div *ngIf="status == 'none'">Status: No calculations pending</div>

      <div *ngIf="status != 'none'" fxLayout="row" fxLayoutAlign="start center">
        Status: {{ status }} <mat-icon class="arrow-icon" mat-font-set="material-icons">hourglass_empty</mat-icon>
      </div>
    </div>

    <!-- <div class="enabled-for" *ngIf="locationEnabledCount != null">Enabled for {{locationEnabledCount}} location(s)</div> -->
    <!-- <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-button type="button" (click)="recalculate()">Recalculate</button>
    </div> -->

    <tgm-loadable [model]="loadingDataSets">
      <!-- <admin-versions [endpoint]="dataSetEndpoint" listUrl="/admin/planningApplications" (updated)="updatedVersion()" [id]="id"></admin-versions> -->
      <admin-select-fascias [dataSets]="dataSets" (selectionChange)="updateFascias($event)"></admin-select-fascias>
    </tgm-loadable>
  </section>
</div>
