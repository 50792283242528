import { LatLngProperties } from '@targomo/core'
import { MapLayer, MarkerMapSource, ObservableExpression, MarkerMapLayer } from '@targomo/client'
import { Layer } from 'mapbox-gl'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

export class SymbolMarkerMapLayerOptions {
  layout: any = {}
  paint: any = {}
}

/**
 * A layer representing markers on a map, implemented by a mapbox `symbol` layer
 */
export class SymbolMarkerMapLayer<T extends LatLngProperties> extends MarkerMapLayer<T> {
  private options: SymbolMarkerMapLayerOptions

  /**
   * @param map The mapbox component this layer will be added to
   * @param source The source of markers data to be shown
   * @param layoutObservable A mapbox-gl layout object definition that can be used to style this layer
   */
  constructor(
    map: CustomMapboxComponent,
    source: ObservableExpression<T[]> | MarkerMapSource<T>,
    layoutObservable?: ObservableExpression<any>
  ) {
    super(map as any, source instanceof MarkerMapSource ? source : new MarkerMapSource(source))

    if (layoutObservable) {
      this.watch(layoutObservable, (layout) => {
        this.options = Object.assign(new SymbolMarkerMapLayerOptions(), layout)
        this.update()
      })
    }
  }

  get(): Partial<Layer> {
    const result = {
      interactive: true,
      type: 'symbol' as 'symbol',
      layout: {},
      paint: {},
    }

    Object.assign(result, this.options)

    return result
  }
}
