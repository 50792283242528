import {Settings} from '../../common/models'
import {RESTHelper} from '@targomo/client';
import {Injectable} from '@angular/core';
import { Place } from './place';
import { LatLngId } from '@targomo/core';

export type Settings = Settings

@Injectable()
export class StatisticsEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/statistics')
  }

  pedestrianFlow(locations: Place[]) {
    return this.http.post<any>(`/pedestrian/flow`, this.simplifyLocations(locations))
  }

  locationRegion(locations: LatLngId[]) {
    return this.http.post<any>(`/sources/regions`, this.simplifyLocations(locations))
  }

  singleRegion(locations: LatLngId[]) {
    return this.http.post<{region: string, country: string}>(`/sources/regions/single`, this.simplifyLocations(locations))
  }
  
  private simplifyLocations(locations: LatLngId[]): LatLngId[] {
    return locations ? locations.map(place => ({id: place.id, lat: place.lat, lng: place.lng})) : []
  }
}