import {Settings} from '../../common/models'
import {RESTHelper} from '@targomo/client';
import {Injectable} from '@angular/core';
import { Place } from './place';
import { StatisticsGeometryResult, LatLngId, StatisticsGeometryRequestOptions } from '@targomo/core';
import { User } from './user';

export class Message {
  id?: number
  title: string
  body: string
  html?: string
  image?: string
  selected?: boolean
  userId?: number
}

@Injectable()
export class MessagesEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/message')
  }

  delete(set: {id: number}) {
    return this.http.delete<any>(`/${set.id}`)
  }

  get(set: {id: number}) {
    return this.http.get<any>(`/${set.id}`)
  }


  dismiss(message: Message) {
    return this.http.post<Message[]>(`/${message.id}/dismiss`, {})
  }

  requestInfo(message: Message) {
    return this.http.post<Message[]>(`/${message.id}/requestInfo`, {})
  }
  
  me() {
    return this.http.get<Message[]>(`/me`)
  }

  findAll() {
    return this.http.get<Message[]>(`/`)
  }

  assignCompany(company: {id: number}, message: Message) {
    return this.http.post<Message[]>(`/${message.id}/company/${company.id}`, {})
  }

  unAssignCompany(company: {id: number}, message: Message) {
    return this.http.delete<Message[]>(`/${message.id}/company/${company.id}`)
  }

  saveUserMessages(user: {id: number}, messages: Message[]) {
    return this.http.put<Message[]>(`/user/${user.id}`, messages.filter(message => message.selected).map(message => message.id))
  }

  async findForUser(user: User) {
    return (await this.http.get<Message[]>(`/user/${user.id}`)).map(message => {
      message.selected = message.userId != null
      return message
    })
  }

  save(layer: Message) {
    if (layer.id) {
      return this.http.put<Message>(`/${layer.id}`, layer)
    } else {
      return this.http.post<Message>(`/`, layer)
    }
  }

  preview(body: string) {
    return this.http.post<Partial<Message>>(`/preview`, {body})
  }  
}