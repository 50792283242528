<back-button></back-button>
<div style="padding: 0px 16px">
  <h3 translate="layers.uploadLayer">Upload layer</h3>

  <form [formGroup]="form" (ngSubmit)="submit($event)" fxLayout="column" novalidate>
    <mat-form-field>
      <input matInput type="text" formControlName="name" required placeholder="Layer name">

      <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
        <span translate="layers.layerNameRequired">Layer name required</span>
      </mat-hint>
    </mat-form-field>


    <div>
      <tgm-file-uploader placeholder="Upload GeoJSON/CSV layer" (selected)="selectFile($event)"></tgm-file-uploader>

      <mat-hint *ngIf="locationError" [ngStyle]="{'color': 'red'}" style="font-size: 75%">
        <span>Layer entries not valid</span>
      </mat-hint>

      <div class="found-locations" *ngIf="!!locations">
        {{locations.length}} location(s) found in file
      </div>

      <div class="outside-locations" style="font-size: 75%" *ngIf="!!locationsOutside">
        Warning: {{locationsOutside}} location(s) outside allowed area will be excluded
      </div>

    </div>

    <expandable-panel class="help-panel" text="Help">
      <div>
        <div style="margin-bottom: 20px; line-height: 1.4em">
          You can upload a GeoJSON file with longitude/latitude coordinates in WGS84 (EPSG:4326), or a CSV file which includes columns named lat and lng.
          In addition the following properties are recognized:
        </div>

        <div class="fields-description">
          <div><code>fascia</code> or <code>name</code></div>
          <div><code>primaryCategory</code> or <code>primary-category</code>  or <code>primary_category</code>  </div>
          <div><code>secondaryCategory</code> or <code>secondary-category</code>  or <code>secondary_category</code>  </div>
          <div><code>holdingCompany</code> or <code>holding-company</code>  or <code>holding_company</code>  </div>
          <div><code>sitename</code></div>
          <div><code>paon</code></div>
          <div><code>saon</code></div>
          <div><code>taon</code></div>
          <div><code>street</code></div>
          <div><code>suburb</code></div>
          <div><code>town</code></div>
          <div><code>postcode</code></div>
          <div><code>district</code></div>
          <div><code>county</code></div>
          <div><code>region</code></div>
          <div><code>netSalesArea</code> or <code>net-sales-area</code> or <code>net_sales_area</code></div>
          <div><code>netSalesAreaSource</code> or <code>net-sales-area-source</code> or <code>net_sales_area_source</code></div>
          <div><code>grossInternalArea</code> or <code>gross-internal-area</code> or <code>gross_internal_area</code></div>
          <div><code>grossInternalAreaSource</code> or <code>gross-internal-area-source</code> or <code>gross_internal_area_source</code></div>
        </div>
      </div>
    </expandable-panel>

    <expandable-panel class="help-panel" text="Templates">
      <p><a download target="_blank" href="{{basePath}}assets/files/layer_template.csv">CSV Layer Template </a></p>
      <p><a download target="_blank" href="{{basePath}}assets/files/layer_template.geojson">GeoJSON Layer Template </a></p>
    </expandable-panel>

    <div class="bottom-buttons">
      <button mat-flat-button color="primary" type="submit"><span translate="dialog.save">Save</span></button>
    </div>
  </form>
</div>