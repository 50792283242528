import { TravelType } from '@targomo/core'

export function defaultDateTime(travelType: TravelType) {
  if (travelType !== 'transit') {
    return {
      transitFrameDate: null,
      transitFrameTime: null,
      date: null,
      time: null,
    }
  } else {
    // As in targomo-js lib
    const DEFAULT_DATE = 20170801
    const DEFAULT_TIME = 39600

    return {
      transitFrameDate: DEFAULT_DATE,
      transitFrameTime: DEFAULT_DATE,
      date: DEFAULT_TIME,
      time: DEFAULT_TIME,
    }
  }
}