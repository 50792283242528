import { STATISTICS_EXTENDED } from '../../../common/constants/statisticsExtended'
import { AgesByYear } from '../../../common/models/statistics/statistics'

function topic(id: number) {
  return STATISTICS_EXTENDED.filter((item) => item.topic === id).map((item) => {
    return { ...item, lookupInInput: true }
  })
}

const ages = [
  { name: '0-15', items: ['aged0to4', 'aged5to7', 'aged8to9', 'aged10to14', 'aged15'] },
  { name: '16-24', items: ['aged16to17', 'aged18to19', 'aged20to24'] },
  { name: '25-44', items: ['aged25to29', 'aged30to44'] },
  { name: '45-64', items: ['aged45to59', 'aged60to64'] },
  { name: '65+', items: ['aged65to74', 'aged75to84', 'aged85to89', 'aged90AndOver'] },
]

export const PDF_REPORT_DEMOGRAPHICS = [
  { id: 'ages', name: 'Age', items: ages },
  { id: 'tenure', name: 'Household Tenure' },
  { id: 'accomodation', name: 'Dwelling Type' },
  { id: 'economicallyActive', name: 'Economic Activity' },
  { id: 'cars', name: 'Car Ownership' },
  { id: 'socialClass', name: 'Social Grade' },
  { id: 'householsIncome', name: 'Household Income (Gross)', items: [{ id: 'totalGross', name: 'Gross' }] },
  { id: 'dwellingTypesAveragePrice', name: 'Average House Price', items: [{ id: 'all', name: 'All' }] },
]

const agesColliers = [
  { name: '0-9', items: AgesByYear.keysForRange(0, 9) },
  { name: '10-19', items: AgesByYear.keysForRange(10, 19) },
  { name: '20-29', items: AgesByYear.keysForRange(20, 29) },
  { name: '30-39', items: AgesByYear.keysForRange(30, 39) },
  { name: '40-49', items: AgesByYear.keysForRange(40, 49) },
  { name: '50-59', items: AgesByYear.keysForRange(50, 59) },
  { name: '60-69', items: AgesByYear.keysForRange(60, 69) },
  { name: '70-79', items: AgesByYear.keysForRange(70, 79) },
  { name: '80+', items: AgesByYear.keysForRange(80, 100) },
]

const tenureCollers = [
  { items: ['owned'], name: 'Owned', intensity: true },
  { items: ['ownsOutright'], name: 'Owns outright' },
  { items: ['ownsMortgageLoan'], name: 'Owns with a mortgage or loan' },
  { items: ['privateRented'], name: 'Private rented', intensity: true },
  { items: ['privateLandlordLettingAgency'], name: 'Private landlord or letting agency' },
  {
    items: ['otherPrivateRented', 'employerHouseholdMember', 'relativeFriendHouseholdMember'],
    name: 'Other private rented',
  },
  { items: ['socialRented'], name: 'Social rented', intensity: true },
  { items: ['rentedCouncilLocalAuthority'], name: 'Rented from Council (Local Authority)' },
  { items: ['otherSocialRented'], name: 'Other social rented' },
  { items: ['livingRentFree'], name: 'Living rent free ', intensity: true },
  { items: ['sharedOwnership'], name: 'Shared ownership', intensity: true },
]

const ethnicityCollers = [
  { intensity: true, id: 'white', name: 'White' },
  { intensity: true, id: 'bangladeshi', name: 'Bangladeshi' },
  { intensity: true, id: 'indian', name: 'Indian' },
  { intensity: true, id: 'pakistani', name: 'Pakistani' },
  { intensity: true, id: 'black', name: 'Black' },
  { id: 'african', name: 'African' },
  { id: 'caribbean', name: 'Caribbean' },
  { id: 'otherBlack', name: 'Other Black' },
  { intensity: true, id: 'chinese', name: 'Chinese' },
  { intensity: true, id: 'arab', name: 'Arab' },
  { intensity: true, id: 'asianOther', name: 'Asian Other' },
  { intensity: true, id: 'mixed', name: 'Mixed' },
  { intensity: true, id: 'otherEthnicGroup', name: 'Other Ethnic Group' },
]

export const PDF_REPORT_DEMOGRAPHICS_COLLIERS = [
  { id: 'agesDetailed', name: 'Age', items: agesColliers },
  { id: 'accomodation', name: 'Dwelling Type' },
  { id: 'tenure', pageBreak: true, name: 'Tenure', items: tenureCollers },
  { id: 'socialClass', name: 'Social Grade' },
  {
    id: 'nationalStatisticsSocialClass',
    name: 'Socio Economic Classification',
  },
  {
    id: 'industries',
    name: 'Industry',
    pageBreak: true,
    items: [
      // NEW
      { id: 'agricultureForestryFishing', name: 'A Agriculture, forestry & fishing' },
      { id: 'miningQuarrying', name: 'B Mining & quarrying' },
      { id: 'manufacturing', name: 'C Manufacturing' },
      { id: 'electricityGasSteam', name: 'D Electricity, gas, steam & air conditioning supply' },
      { id: 'waterSewerageWasteManagement', name: 'E Water supply, sewerage, waste management & remediation' },
      { id: 'construction', name: 'F Construction' },
      { id: 'wholesaleRetailTradeRepairMotorVehicles', name: 'G Wholesale & retail trade; repair of motor vehicles' },
      { id: 'transportStorage', name: 'H Transport & storage' },
      { id: 'accomodationFoodServices', name: 'I Accommodation & food service' },
      { id: 'informationCommunication', name: 'J Information & communication' },
      { id: 'financialInsuranceActivities', name: 'K Financial & insurance' },
      { id: 'realEstateActivities', name: 'L Real estate' },
      { id: 'professionalScientificTechnicalActivities', name: 'M Professional, scientific & technical' },
      { id: 'administrativeSupportServiceActivities', name: 'N Administrative & support service' },
      {
        id: 'publicAdministrationDefenceCompulsorySocialSecurity',
        name: 'O Public administration & defence; compulsory social security',
      },
      { id: 'education', name: 'P Education' },
      { id: 'humanHealthSocialWorkActivities', name: 'Q Human health & social work' },
      {
        id: 'artsEntertainmentRecreationOtherServiceActivities',
        name: 'R,S Arts, entertainment & recreation; other service',
      },
      { id: 'other1', name: 'T households as employers; undifferentiated goods - & services' },
      { id: 'other2', name: 'U Activities of extraterritorial organisations and bodies' },
    ],
  },

  {
    id: 'education',
    name: 'Education',
    pageBreak: true,
    items: [
      { id: 'education1_4_O_Levels_CSE_GCSE', name: '1 to 4 Olevels CSE GCSE etc' },
      { id: 'educationNVQ_Lvl_1_Foundation_GNVQ_basic_skills', name: 'NVQ Level 1 Foundation GNVQ, Basic Skills' },
      {
        id: 'education1_A_Level_2_3_AS_Levels_Welsh_Bacc_Interm_Diploma',
        name: '1 A level, 2 or 3 AS Levels, Welsh Bacc, Intermediate Diploma',
      },
      { id: 'educationNVQ_Lvl_2_Interm_GNVQ_etc', name: 'NVQ Level 2, Intermediate GNVQ etc' },
      { id: 'educationApprenticeship', name: 'Apprenticeship' },
      { id: 'education2_A_Levels_4_AS_Levels_etc', name: '2+ A levels, 4+s AS levels etc' },
      { id: 'educationNVQ_Lvl_3_Adv_GNVQ_etc', name: 'NVQ Level 3, Advanced GNVQ etc' },
      { id: 'educationDegree_BA_BSc_MA_PhD_PGCE', name: 'Degree BA BSC MA PhD PGCE' },
      { id: 'educationNVQ_Lvl_4_5_HNC_HND_etc', name: 'NVQ Level 4 or 5 HNC HND etc' },
      {
        id: 'educationProfessional_qualifications',
        name: 'qualifications eg teaching nursing account Education_Professional_qualifications',
      },
      { id: 'educationVocational_work_related_quals', name: 'Vocational work related qualifications' },
      { id: 'educationForeign_quals', name: 'Foreign qualifications' },
      { id: 'educationNo_qualifications', name: 'No qualifications' },
    ],
  },

  { id: 'ethnicity', name: 'Ethnicity', items: ethnicityCollers },

  {
    id: 'householdSize',
    name: 'Household Size',
    pageBreak: true,
    items: [
      // NEW
      { id: 'householdSize_1Person', name: '1 Person Household' },
      { id: 'householdSize_2Persons', name: '2 Person Household' },
      { id: 'householdSize_3Persons', name: '3 Person Household' },
      { id: 'householdSize_4Persons', name: '4 Person Household' },
      { id: 'householdSize_5Persons', name: '5 Person Household' },
      { id: 'householdSize_6Persons', name: '6 Person Household' },
      { id: 'householdSize_7Persons', name: '7 Person Household' },
      { id: 'householdSize_8OrMorePersons', name: '7 Person Household' },
    ],
  },

  {
    id: 'householdType',
    name: 'Household Type',
    items: [
      // NEW
      { id: 'househole1_Person', name: 'One person' },
      { id: 'householeMarried_dep_children', name: 'Married couple: With dependent children' },
      { id: 'householeMarried_no_dep_children', name: 'Married couple: No dependent children' },
      { id: 'householeCivil_partner_dep_children', name: 'Same-sex civil partnership couple: With dependent children' },
      {
        id: 'householeCivil_partner_no_dep_children',
        name: 'Same-sex civil partnership couple: No dependent children',
      },
      { id: 'householeCohabiting_dep_children', name: 'Cohabiting couple: With dependent children' },
      { id: 'householeCohabiting_no_dep_children', name: 'Cohabiting couple: No dependent children' },
      { id: 'householeLone_parent_dep_children', name: 'Lone parent: With dependent children' },
      { id: 'householeLone_parent_no_dep_children', name: 'Lone parent: No dependent children' },
      { id: 'householeMulti_family_students', name: 'Multi-person: All full-time students' },
      { id: 'householeMulti_family_other', name: 'Multi-person: Other' },
    ],
  },

  {
    id: 'religion',
    name: 'Religion',
    pageBreak: true,
    items: [
      // NEW
      { id: 'christian', name: 'Christian' },
      { id: 'buddhist', name: 'Religion_Buddhist' },
      { id: 'hindu', name: 'Hindu' },
      { id: 'jewish', name: 'Jewish' },
      { id: 'muslim', name: 'Muslim (Islam) ' },
      { id: 'sikh', name: 'Sikh' },
      { id: 'otherReligion', name: 'Other religion' },
      { id: 'none', name: 'No Religion' },
      { id: 'notStated', name: 'Religion not stated' },
    ],
  },

  {
    id: 'healthDayToDay',
    name: 'Health - Day To Day',
    items: [
      // Name
      { id: 'dayToDayLimitedALot', name: 'Day-to-day activities limited a lot' },
      { id: 'dayToDayLimitedALittle', name: 'Day-to-day activities limited a little' },
      { id: 'dayToDayNotLimited', name: 'Day-to-day activities not limited' },
    ],
  },

  {
    id: 'health',
    name: 'Health - General',
    items: [
      // Name
      { id: 'veryGood', name: 'Very good health' },
      { id: 'good', name: 'Good health' },
      { id: 'fair', name: 'Fair health' },
      { id: 'bad', name: 'Bad health' },
      { id: 'veryBad', name: 'Very bad health' },
    ],
  },

  { id: 'travelWork', name: 'Travel To Work Mode', pageBreak: true },
  {
    id: 'economicActivity',
    name: 'Economic Activity',
    items: [
      // New
      { id: 'economicallyActiveEmployedPartTime', name: 'Employed Full-time' },
      { id: 'economicallyActiveEmployedFullTime', name: 'Employed Part-time' },
      { id: 'economicallyActiveSelfEmployedPartTimeWithEmployees', name: 'SelfEmployed Part-time, With Employees' },
      { id: 'economicallyActiveSelfEmployedFullTimeWithEmployees', name: 'SelfEmployed Full-time, With Employees' },
      { id: 'economicallyActiveSelfEmployedPartTimeNoEmployees', name: 'SelfEmployed Part-time, No Employees' },
      { id: 'economicallyActiveSelfEmployedFullTimeNoEmployees', name: 'SelfEmployed Full-time, No Employees' },
      { id: 'economicallyActiveUnemployed', name: 'Unemployed' },
      { id: 'economicallyActiveFullTimeStudent', name: 'Full-time Student' },
      { id: 'economicallyInactiveRetired', name: 'Retired' },
      { id: 'economicallyInactiveStudent', name: 'Economically Inactive: Student' },
      { id: 'economicallyInactiveCaringForHomeOrFamily', name: 'Economically Inactive: Caring For Home or Family' },
      { id: 'economicallyInactiveLongTermSick', name: 'Economically Inactive: Longterm Sick' },
      { id: 'economicallyInactiveOther', name: 'Economically Inactive: Other' },
    ],
  },

  {
    id: 'householdsByIncomeBand',
    name: 'Household By Income Band',
    pageBreak: true,
    items: [
      // NEW
      { id: 'householdsWithIncome_100k', name: '£100k Plus' },
      { id: 'householdsWithIncome_90_100k', name: '£90-100k' },
      { id: 'householdsWithIncome_80_90k', name: '£80-90k' },
      { id: 'householdsWithIncome_70_80k', name: '£70-80k' },
      { id: 'householdsWithIncome_60_70k', name: '£60-70k' },
      { id: 'householdsWithIncome_50_60k', name: '£50-60k' },
      { id: 'householdsWithIncome_40_50k', name: '£40-50k' },
      { id: 'householdsWithIncome_30_40k', name: '£30-40k' },
      { id: 'householdsWithIncome_20_30k', name: '£20-30k' },
      { id: 'householdsWithIncome_20k', name: 'Less than 20k' },
    ],
  },

  { id: 'householsIncome', name: 'Household Income (Gross)', items: [{ id: 'totalGross', name: 'Gross' }] },
  { id: 'dwellingTypesAveragePrice', name: 'Average House Price', items: [{ id: 'all', name: 'All' }] },

  {
    id: 'outputClassification',
    name: 'Output Area Classifications',
    pageBreak: true,
    items: [
      // NEW
      { id: 'farmingCommunities_1a', name: '1A - Farming communities' },
      { id: 'ruralTenants_1b', name: '1B - Rural tenants' },
      { id: 'ageingRuralDwellers_1c', name: '1C - Ageing rural dwellers' },
      { id: 'studentsAroundCampus_2a', name: '2A - Students around campus' },
      { id: 'innerCityStudents_2b', name: '2B - Inner city students' },
      { id: 'comfortableCosmopolitan_2c', name: '2C - Comfortable cosmopolitan' },
      { id: 'aspiringAndAffluent_2d', name: '2D - Aspiring and affluent' },
      { id: 'ethnicFamilyLife_3a', name: '3A - Ethnic family life' },
      { id: 'endeavouringEthnicMix_3b', name: '3B - Endeavouring Ethnic Mix' },
      { id: 'ethnicDynamics_3c', name: '3C - Ethnic dynamics' },
      { id: 'aspirationalTechies_3d', name: '3D - Aspirational techies' },
      { id: 'rentedFamilyLiving_4a', name: '4A - Rented family living' },
      { id: 'challengedAsianTerraces_4b', name: '4B - Challenged Asian terraces' },
      { id: 'asianTraits_4c', name: '4C - Asian traits' },
      { id: 'urbanProfessionalsAndFamilies_5a', name: '5A - Urban professionals and families' },
      { id: 'ageingUrbanLiving_5b', name: '5B - Ageing urban living' },
      { id: 'suburbanAchievers_6a', name: '6A - Suburban achievers' },
      { id: 'semiDetachedSuburbia_6b', name: '6B - Semi-detached suburbia' },
      { id: 'challengedDiversity_7a', name: '7A - Challenged diversity' },
      { id: 'constrainedFlatDwellers_7b', name: '7B - Constrained flat dwellers' },
      { id: 'whiteCommunities_7c', name: '7C - White communities' },
      { id: 'ageingCityDwellers_7d', name: '7D - Ageing city dwellers' },
      { id: 'industriousCommunities_8a', name: '8A - Industrious communities' },
      { id: 'challengedTerracedWorkers_8b', name: '8B - Challenged terraced workers' },
      { id: 'hardPressedAgeingWorkers_8c', name: '8C - Hard pressed ageing workers' },
      { id: 'migrationAndChurn_8d', name: '8D - Migration and churn' },
    ],
  },
]

export const PDF_REPORT_SPEND_COLLIERS = [
  {
    id: 'spend',
    name: 'Spend - Leisure Items',
    items: [
      { name: 'Alcohol Out Of Home', items: ['alcoholOutofHome'] },
      { name: 'Cinemas', items: ['cinemas'] },
      { name: 'Clubs, Dances, Disco & Bingo', items: ['clubsDancesdiscoBingo'] },
      { name: 'Take Away Meals', items: ['eatingOut'] },
      { name: 'Live Entertainment', items: ['liveEntertainment'] },
      { name: 'Museum Zoo Theme Park House', items: ['museumZooThemeParkHouse'] },
      { name: 'Restaurant and cafe meals', items: ['restaurantandCafemeals'] },
    ],
  },

  {
    id: 'spendSummary',
    name: 'Spend Summary',
    items: [
      { id: 'comparisonTotal', name: 'Comparison Goods' },
      { id: 'convenience', name: 'Food & Convenience' },
    ],
  },

  // 'bakedGoods'
  // 'confectionery'
  // 'otherFoodStuffs'
  // 'fruitAndVegetables'
  // 'meatFishAndPoultry'
  // 'alcoholicDrinks'
  // 'cleaningAndPaperProducts'
  // 'nonAlcoholicDrinks'
  // 'petsAndPetfood'
  // 'babyEquipment'
  // 'books'
  // 'cards'
  // 'stationery'
  // 'childrenswear'
  // 'electricalGasAppliances'
  // 'electricalGasAccessories'
  // 'fashionAccessories'
  // 'floorCoverings'
  // 'footwear'
  // 'furniture'
  // 'haberdashery'
  // 'healthBeauty'
  // 'homeFurnishingsGifts'
  // 'homeImprovementsAndGardening'
  // 'jewellery'
  // 'menswear'
  // 'mobilePhones'
  // 'motorAccessories'
  // 'musicAndVideo'
  // 'newspapersAndMagazines'
  // 'oTCPharmaceuticals'
  // 'photography'
  // 'softFurnishingsLinens'
  // 'spectaclesAndLenses'
  // 'sportsAndCampingEquipment'
  // 'tobacco'
  // 'toys'
  // 'eatingOut'
  // 'travelGoods'
  // 'womenswear'
  // 'cinemas'
  // 'leisureCentres'
  // 'clubsDancesdiscoBingo'
  // 'liveEntertainment'
  // 'museumZooThemeParkHouse'
  // 'alcoholOutofHome'
  // 'restaurantandCafemeals'
]

export const PDF_REPORT_SPEND_SUMMARY = [
  { items: ['comparisonTotal'], name: 'Comparison Goods Total' },
  { items: ['convenience'], name: 'Convenience Goods Total' },
  { items: ['foodAndBeverage'], name: 'Food & Beverage' },
  { items: ['leisure'], name: 'Leisure' },
]

export const SPEND_REPORT = [
  {
    name: 'Books, Cards & Stationery',
    items: ['books', 'cards', 'stationery'],
  },
  {
    name: 'Fashion, Footwear & Jewellery',
    items: ['fashionAccessories', 'footwear', 'jewellery', 'womenswear', 'childrenswear', 'menswear'],
  },
  {
    name: 'Electrical Goods, Mobile Phones & Photography',
    items: ['mobilePhones', 'photography', 'electricalGasAppliances', 'electricalGasAccessories'],
  },
  {
    name: 'Home Improvements & Gardening',
    items: ['homeImprovementsAndGardening'],
  },
  {
    name: 'Floor Coverings',
    items: ['floorCoverings'],
  },
  {
    name: 'Furniture',
    items: ['furniture'],
  },
  {
    name: 'Home Furnishings & Gifts',
    items: ['homeFurnishingsGifts'],
  },
  {
    name: 'Soft Furnishings & Linens',
    items: ['softFurnishingsLinens'],
  },
  {
    name: 'Health & Beauty (inc OTC Pharmacy)',
    items: ['healthBeauty', 'oTCPharmaceuticals'],
  },
  {
    name: 'Music, DVDs, Games & Toys',
    items: ['toys', 'musicAndVideo'],
  },
  {
    name: 'Other (Spectacles & Lenses, Travel Goods, Motor Accessories)',
    items: ['travelGoods', 'motorAccessories', 'spectaclesAndLenses'],
  },
  {
    name: 'Sports And Camping Equipment',
    items: ['sportsAndCampingEquipment'],
  },
  {
    name: 'Eating Out, Restaurants & Cafes',
    items: ['eatingOut', 'restaurantandCafemeals', 'alcoholOutofHome'],
  },
  {
    name: 'Leisure Centres, Attractions & Live Entertainment etc',
    items: ['leisureCentres', 'clubsDancesdiscoBingo', 'liveEntertainment', 'museumZooThemeParkHouse', 'cinemas'],
  },
  {
    name: 'Convenience Goods Total',
    items: ['convenience'],
  },
]

// {id: "bakedGoods", name: "Baked Goods"},
// {id: "confectionery", name: "Confectionery"},
// {id: "otherFoodStuffs", name: "Other Food Stuffs"},
// {id: "fruitAndVegetables", name: "Fruit And Vegetables"},
// {id: "meatFishAndPoultry", name: "MeatFish And Poultry"},
// {id: "alcoholicDrinks", name: "Alcoholic Drinks"},

// {id: "cleaningAndPaperProducts", name: "Cleaning And PaperProducts"},
// {id: "nonAlcoholicDrinks", name: "Non Alcoholic Drinks"},
// {id: "petsAndPetfood", name: "Pets And Petfood"},
// {id: "babyEquipment", name: "Baby Equipment"},
// {id: "newspapersAndMagazines", name: "Newspapers And Magazines"},
// {id: "tobacco", name: "Tobacco"},
