<h2 class="mat-title">{{message && message.id && 'Edit Message' || 'New Message'}}</h2>

<tgm-loadable [model]="loading">
  <form [formGroup]="form" (ngSubmit)="submit()" novalidate *ngIf="ready">
    <section fxLayout="row">

      <section fxLayout="column" fxFlex>
        <mat-form-field style="width: 100%; margin-bottom: 10px">
          <input matInput placeholder="Title" type="text" formControlName="title"/>

            <mat-hint *ngIf="form.submitted && form.get('title').hasError('required')" [ngStyle]="{'color': 'red'}">
              Title is required
            </mat-hint>
        </mat-form-field>

        <tgm-file-uploader filter=".jpeg,.jpg,.png,.gif" style="width: 100%; height: 150px; margin: 16px 0px;" (selected)="onSelectImage($event)" [background]="image"></tgm-file-uploader>

        <mat-form-field style="width: 100%; margin-bottom: 10px">
          <textarea cdkTextareaAutosize matInput placeholder="Body" type="text" formControlName="body"></textarea>

            <mat-hint *ngIf="form.submitted && form.get('body').hasError('required')" [ngStyle]="{'color': 'red'}">
              Body is required
            </mat-hint>
        </mat-form-field>

        <div style="width: 100%; margin-bottom: 10px">
          <mat-checkbox formControlName="sendToAll">Send to all Users</mat-checkbox>
        </div>

      </section>
    </section>

    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-button type="button" routerLink="/admin/messages"><mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span translate="dialog.back">Back</span></button>
      <button mat-flat-button color="primary" type="submit"><span translate="dialog.save">Save</span></button>
    </div>
  </form>

  <section>
    <h3>Preview</h3>

      <section class="message-popup-panel">
        <div class="box-title" fxLayout="row" fxLayoutAlign="start center">
          <h3 fxFlex>StorePointGeo Message Service</h3>
          <mat-icon mat-font-set="material-icons">mail_outline</mat-icon>
        </div>
        <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
          <div fxFlex class="content">
            <div class="title">{{message?.title}}</div>
  
            <div *ngIf="!!image" class="image"><img [src]="image"></div>
            
            <div *ngIf="!message?.html" class="body">{{message?.body}}</div>
            <div *ngIf="!!message?.html" class="body" [innerHTML]="message?.html"></div>
          </div>  
        </div>
      </section>

  </section>
</tgm-loadable>
