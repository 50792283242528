import * as models from '../../common/models'
import {Injectable} from '@angular/core';
import {RESTHelper} from '@targomo/client';
import { Place } from './place';
import { PlanningApplication } from '../../common/models/planningApplication';

@Injectable()
export class RiskAssessmentEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/riskAssessment')
  }

  report(months: number, travelType: string, travelTime: number) {
    return this.http.post<any>(`/report`, {months, travelTime, travelType})
  }

  reportSingle(months: number, travelType: string, travelTime: number, location: Place) {
    return this.http.post<any>(`/reportSingle`, {id: location.id, months, travelTime, travelType})
  }

  reportMultiple(travelType: string, travelTime: number, locations: Place[]) {
    return this.http.post<any>(`/reportMultiple`, {ids: locations.map(place => place.id), travelType, travelTime})
  }
}