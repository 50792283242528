<mat-list>
  <mat-list-item class="top-level" (click)="toggleAll()" *ngIf="type !== 'alsoAvailable'">
    <div class="location-item" fxFlex fxLayout="row">
      <div fxFlex>
        <b [hidden]="selectedAll">Select All</b>
        <b [hidden]="!selectedAll">Deselect All</b>
      </div>

      <mat-checkbox
        style="pointer-events: none"
        class="mat-secondary"
        [checked]="selectedAll"
        aria-label="Select/Deselect All"
      ></mat-checkbox>
    </div>
  </mat-list-item>

  <ng-container *ngFor="let node of nodes">
    <ng-container *ngIf="node.filtered !== false">
      <mat-list-item class="top-level" (click)="node.toggle()">
        <div class="location-item" fxFlex fxLayout="row" fxLayoutGap="0.75rem">
          <mat-icon [class.toggled]="node.visible" class="animation-rotate" mat-font-set="material-icons"
            >keyboard_arrow_right</mat-icon
          >
          <div fxFlex>
            {{node.name}}
            <span [hidden]="node.placesCount == null" class="child-count">({{node.placesCount | number}})</span>
          </div>
          <mat-checkbox
            *ngIf="type !== 'alsoAvailable'"
            class="mat-secondary"
            (change)="toggleNode(node)"
            [checked]="node.selected"
            aria-label=""
          ></mat-checkbox>
          <mat-icon class="lock-icon" *ngIf="type === 'alsoAvailable'" mat-font-set="material-icons">lock</mat-icon>
        </div>
      </mat-list-item>

      <div *ngIf="node.visible" class="second-level">
        <ng-container *ngFor="let category of node.children">
          <mat-list-item *ngIf="category.filtered !== false" (click)="toggle(node, category)">
            <div class="location-item" fxFlex fxLayout="row" fxLayoutGap="0.75rem">
              <!-- <span
                (click)="editColor(category, $event)"
                title="Click to edit color"
                class="checkbox editable"
                [style.background]="category.color || '#ddd'"
              ></span> -->

              <category-icon
                class="editable"
                (click)="editColor(category, $event)"
                title="Click to edit color"
                [category]="category"
              ></category-icon>

              <div fxFlex>
                {{category.name}}
                <span [hidden]="category.placesCount == null" class="child-count"
                  >({{category.placesCount | number}})</span
                >
              </div>

              <mat-icon
                class="eye-icon"
                [class.selectedAll]="category?.pinned"
                (click)="togglePin(category, $event)"
                mat-font-set="material-icons"
                >push_pin</mat-icon
              >

              <mat-icon
                [class.selectedSome]="fasciasSelection[category?.id]?.some"
                [class.selectedAll]="fasciasSelection[category?.id]?.all"
                (click)="toggleFascia(node, category, $event)"
                class="eye-icon"
                *ngIf="type != 'alsoAvailable' && (permissions?.withinTool || permissions?.selectFascia)"
                [title]="fasciasSelection[category?.id]?.all ? 'Remove all locations from analysis' : 'Add all locations to analysis'"
                mat-font-set="material-icons"
                >visibility</mat-icon
              >

              <mat-checkbox
                *ngIf="type !== 'alsoAvailable'"
                style="pointer-events: none"
                class="mat-secondary"
                [checked]="!!category.visible"
                aria-label=""
              ></mat-checkbox>
              <mat-icon class="lock-icon" *ngIf="type === 'alsoAvailable'" mat-font-set="material-icons">lock</mat-icon>
            </div>
          </mat-list-item>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</mat-list>
