<section style="padding: 0px 16px">
  <h3>Upload zone</h3>

  <div class="tip">
    <p>
      <b style="color: black">NOTE:</b> Zones are only saved when a project is saved.
    </p>
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column" novalidate>
    <div>
      <tgm-file-uploader placeholder="Upload CSV zones" (selected)="selectFile($event)"></tgm-file-uploader>

      <!-- <mat-hint *ngIf="locationError" [ngStyle]="{'color': 'red'}" style="font-size: 75%">
        <span>Layer entries not valid</span>
      </mat-hint>

      <div class="found-locations" *ngIf="!!locations">
        {{locations.length}} location(s) found in file
      </div>

      <div class="outside-locations" style="font-size: 75%" *ngIf="!!locationsOutside">
        Warning: {{locationsOutside}} location(s) outside allowed area will be excluded
      </div> -->

    </div>
    
    <div [hidden]="!needsName">
      <mat-form-field style="width: 100%">
        <input matInput type="text" formControlName="name"  required placeholder="Zone name">

        <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
          <span>Zone name is required</span>
        </mat-hint>
      </mat-form-field>
    </div>

    <div *ngIf="!sectorsList">
      <div class="zone-warning" *ngIf="resultList && resultList[0] && resultList[0]?.features?.length === 0">No features matched</div>
    </div>

    <div *ngIf="!!sectorsList">
      <h4 style="margin-top: 16px">Multiple named zones found in file:</h4>

      <div style="margin: 16px 0px">
          <mat-checkbox name="separate" [ngModelOptions]="{standalone: true}" 
                        (ngModelChange)="updateImportSingle($event)"
                        [(ngModel)]="importSingle">Import as single zone</mat-checkbox>
      </div>

        <mat-list >      
          <mat-list-item  [class.disabled]="zone.layer != currentLayer" class="region" [class.selected]="zone.selected" *ngFor="let zone of resultList">
            <div class="list-item-full-center" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon [style.color]="zone.color || 'black'"  class="front-icon mat-24">layers</mat-icon>
    
              <div fxFlex>
                <div>{{zone.name}}</div>
                <div class="zone-warning" *ngIf="zone?.features?.length === 0">No features matched</div>
              </div>
    
              <!-- <button class="delete-button" style="pointer-events: all !important" (click)="deleteSector(zone, $event)" title="Delete zone" mat-icon-button
                ><mat-icon class="mat-24">delete</mat-icon></button>
                <button (click)="editSector(zone, $event)" title="Edit zone" mat-icon-button
                ><mat-icon class="mat-24">edit</mat-icon></button> -->
            </div>
          </mat-list-item>
        </mat-list>
    </div>      
  

    <!-- <expandable-panel class="help-panel" text="Help">
      <div>
        <div style="margin-bottom: 20px; line-height: 1.4em">
          You can upload a CSV file which includes columns id and optionally name.
        </div>

        <div class="fields-description">
          <div><code>fascia</code> or <code>name</code></div>
          <div><code>primaryCategory</code> or <code>primary-category</code>  or <code>primary_category</code>  </div>
        </div>
      </div>
    </expandable-panel> -->

    <expandable-panel class="help-panel" text="Templates">
      <p><a download target="_blank" href="{{basePath}}assets/files/zone_template.csv">CSV Zone Template</a></p>
    </expandable-panel>

    <div class="bottom-buttons">
      <button type="button" mat-button (click)="cancel()"><span>Cancel</span></button>
      <button mat-button color="primary" type="submit"><span>Save</span></button>
    </div>
  </form>
</section>