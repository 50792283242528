import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'select-image-list',
  templateUrl: './selectImageList.component.html',
  styleUrls: ['./selectImageList.component.less'],
})
export class SelectImageListComponent {
  @Input() options: { image: string; name: string; key: string }[] = []
  @Input() selected: string
  @Output() selectionChange = new EventEmitter()

  constructor() {}

  select(option: { key: string }) {
    this.selectionChange.emit(option.key)
  }
}
