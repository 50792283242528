import { Component } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { SettingsModel, TravelTimeRangeOption, TRAVEL_TIME_RANGE_OPTIONS } from '../../../model/settingsModel'
import { MatCheckboxChange } from '@angular/material'

@Component({
  selector: 'travel-times-options-panel',
  templateUrl: './travelTimesOptionPanel.component.html',
  styleUrls: ['./travelTimesOptionPanel.component.less'],
})
export class SelectTravelTimesOptionsPanelComponent {
  set customMaxTime(v: number) {
    this.settings.displaySettings.nextProperty('travelRangeCustomMaxEdgeWeight', v * 60)
  }

  get customMaxTime() {
    return this.settings.displaySettings.getValue().travelRangeCustomMaxEdgeWeight / 60
  }

  setCustomReportTime(index: number, v: number) {
    this.settings.displaySettings.nextPropertyWithCurrent('customReportTimes', (current: any) => {
      if (current) {
        current = [...current]
      } else {
        current = [null, null, null]
      }

      current[index] = v * 60
      return current
    })
  }

  getCustomReportTime3(index: number) {
    const array = this.settings.displaySettings.getValue().customReportTimes || []
    return (array[index] || 0) / 60
  }

  // Close your eyes ... no time ...
  set customReportTime1(v: number) {
    this.setCustomReportTime(0, v)
  }

  get customReportTime1() {
    return this.getCustomReportTime3(0)
  }

  set customReportTime2(v: number) {
    this.setCustomReportTime(1, v)
  }

  get customReportTime2() {
    return this.getCustomReportTime3(1)
  }

  set customReportTime3(v: number) {
    this.setCustomReportTime(2, v)
  }

  get customReportTime3() {
    return this.getCustomReportTime3(2)
  }

  readonly travelRanges: Observable<TravelTimeRangeOption[]> = of(TRAVEL_TIME_RANGE_OPTIONS)

  constructor(readonly settings: SettingsModel) {
    this.travelRanges = settings.travelColorsUpdates.pipe(
      map((colors) => {
        if (!colors) {
          return TRAVEL_TIME_RANGE_OPTIONS
        }

        return TRAVEL_TIME_RANGE_OPTIONS.map((travelRange) => {
          return {
            ...travelRange,
            options: travelRange.options.map((option, i) => {
              return {
                ...option,
                color: colors[i],
              }
            }),
          }
        })
      }),
      shareReplay(1)
    )
  }

  changeTravelRange(id: number) {
    this.settings.displaySettings.nextProperty('travelRange', id)
  }

  changeTravelRangeCustom() {
    this.settings.displaySettings.nextProperty('travelRange', -1)
  }

  changeCustomReportTimes(ev: MatCheckboxChange) {
    this.settings.displaySettings.nextProperty('useCustomReportTimes', ev.checked)
  }
}
