import { MatchpointPlace } from '../matchpointModel'
import { Place } from '../../../api/place'
import { DataSetLocation } from '../../entities/place'
import { object as objects } from '@targomo/common'

export function uniqueOperators(places: Place[], excludePlaces: Place[], totalCount: number, centerpointId: string) {
  const resultMap: { [id: string]: MatchpointPlace } = {}
  const excludeMap: { [id: string]: boolean } = {}

  excludePlaces = excludePlaces || []
  excludePlaces.forEach((place) => {
    const key = (place.category && place.category.id) + '@' + place.fascia
    excludeMap[key] = true
  })

  places = places || []
  places.forEach((place) => {
    const key = (place.category && place.category.id) + '@' + place.fascia

    if (excludeMap[key]) {
      return
    }

    if (!resultMap[key]) {
      resultMap[key] = new DataSetLocation(place)
      resultMap[key].centerPointMap = {}
    }

    // resultMap[key].centerPointMap[place.other && place.other['CentrePoint ID']] = true
    resultMap[key].centerPointMap[place.other && place.other[centerpointId]] = true
  })

  const resulList: MatchpointPlace[] = objects.values(resultMap)
  resulList.forEach((place) => {
    place.centerPointPercent = Object.keys(place.centerPointMap).length / totalCount
    place.centerPointMap = null
  })

  return resulList
}
