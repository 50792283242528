import {
  AllStatistics,
  CensusStatisticsGroup,
  StatisticsValue,
  sumStatisticsValues,
} from '../../../../common/models/statistics/statistics'

export interface ReportSegmentChild {
  sumOf?: string[]
  id: string
  name: string
  parent?: boolean
  intensity?: boolean
  children?: ReportSegmentChild[]
}

export interface ReportSegment {
  id: string
  name: string
  skipTotal?: boolean
  skipPercent?: boolean
  lifestyleSummary?: boolean
  showPerHousehold?: boolean
  indexMoney?: boolean
  special?: boolean
  child?: boolean
  parent?: boolean
  visible?: boolean
  totalLabel?: string
  percentOverIndex?: boolean
  pageBreak?: boolean

  children?: ReportSegmentChild[]

  transform?: NodeTransformFunction
}

export function sumSegments(segment: ReportSegment, statistics: CensusStatisticsGroup): CensusStatisticsGroup {
  const result = new CensusStatisticsGroup()
  result.total = 0

  segment.children.forEach((child) => {
    if (child.sumOf) {
      result[child.id] = sumStatisticsValues(child.sumOf.map((key) => statistics[key]))
    } else {
      result[child.id] = statistics[child.id]
    }

    result.total += result[child.id].total
  })

  return result
}

export type NodeTransformFunction = (
  input: { [id: string]: StatisticsValue },
  children: { id: string; name: string }[]
) => { children: { id: string; name: string }[]; statisticsValues: { [id: string]: StatisticsValue } }

const AGES_NORMAL = {
  id: 'ages',
  name: 'Age',
  children: [
    { id: 'aged0to4', name: '0-4' },
    { id: 'aged5to7', name: '5-7' },
    { id: 'aged8to9', name: '8-9' },
    { id: 'aged10to14', name: '10-14' },
    { id: 'aged15', name: '15' },
    { id: 'aged16to17', name: '16-17' },
    { id: 'aged18to19', name: '18-19' },
    { id: 'aged20to24', name: '20-24' },
    { id: 'aged25to29', name: '25-29' },
    { id: 'aged30to44', name: '30-44' },
    { id: 'aged45to59', name: '45-59' },
    { id: 'aged60to64', name: '60-64' },
    { id: 'aged65to74', name: '65-74' },
    { id: 'aged75to84', name: '75-84' },
    { id: 'aged85to89', name: '85-89' },
    { id: 'aged90AndOver', name: '90+' },
  ],
}

const AGES_ROI = {
  id: 'ages',
  name: 'Age',
  children: [
    { id: 'aged0to4', name: '0-4' },
    { id: 'aged5to7', name: '5-7' },
    { id: 'aged8to9', name: '8-9' },
    { id: 'aged10to14', name: '10-14' },
    { id: 'aged15', name: '15' },
    { id: 'aged16to17', name: '16-17' },
    { id: 'aged18to19', name: '18-19' },
    { id: 'aged20to24', name: '20-24' },
    { id: 'aged25to29', name: '25-29' },
    { id: 'aged30to44', name: '30-44' },
    { id: 'aged45to59', name: '45-59' },
    { id: 'aged60to64', name: '60-64' },
    { id: 'aged65to74', name: '65-74' },
    { id: 'aged75to84', name: '75-84' },
    { id: 'aged85to89', name: '85+' },
  ],
}

export function getCensusSegments(statistics: AllStatistics) {
  const result = [].concat(CENSUS_SEGMENTS_RAW)

  if (!statistics || !!statistics.census) {
    return result
  }

  if (!statistics.census.ages.aged90AndOver.percent) {
    result[2] = AGES_ROI
  } else {
    result[2] = AGES_NORMAL
  }
  return result
}

export const HOUSE_PRICES_SEGMENTS: ReportSegment[] = [
  {
    id: 'dwellingTypesAveragePrice',
    name: 'House Price',
    skipTotal: true,
    skipPercent: true,
    percentOverIndex: true,
    totalLabel: 'Avg Price (£)',
    children: [
      { id: 'all', name: 'All' },
      { id: 'detached', name: 'Detached' },
      { id: 'semiDetached', name: 'Semi detached' },
      { id: 'terraced', name: 'Terraced' },
      { id: 'flat', name: 'Flat' },
    ],
  },

  {
    id: 'dwellingTypesAveragePricePerSqFt',
    name: 'House Price per Sq M',
    skipTotal: true,
    skipPercent: true,
    percentOverIndex: true,
    totalLabel: 'Avg £/Sq M',
    children: [
      { id: 'all', name: 'All' },
      { id: 'detached', name: 'Detached' },
      { id: 'semiDetached', name: 'Semi detached' },
      { id: 'terraced', name: 'Terraced' },
      { id: 'flat', name: 'Flat' },
    ],
  },

  {
    id: 'householsIncome',
    name: 'Household Income',
    skipTotal: true,
    skipPercent: true,
    percentOverIndex: true,
    totalLabel: 'Avg Income (£)',
    children: [
      { id: 'totalGross', name: 'Gross' },
      { id: 'totalNet', name: 'Net' },
      { id: 'totalAfterHousingCosts', name: 'After Housing Costs' },
    ],
  },
]

export const CENSUS_SEGMENTS_RAW: ReportSegment[] = [
  {
    id: 'population2011',
    name: 'Households & Population 2021',
    skipTotal: true,
    skipPercent: true,
    children: [
      { id: 'households', name: 'Households' },
      { id: 'population', name: 'Population' },
    ],
  },

  {
    id: 'socialClass',
    name: 'Social Grade',
    children: [
      { id: 'ab', name: 'AB' },
      { id: 'c1', name: 'C1' },
      { id: 'c2', name: 'C2' },
      { id: 'de', name: 'DE' },
    ],
  },

  {
    id: 'ages',
    name: 'Age',
    children: [
      { id: 'aged0to4', name: '0-4' },
      { id: 'aged5to7', name: '5-7' },
      { id: 'aged8to9', name: '8-9' },
      { id: 'aged10to14', name: '10-14' },
      { id: 'aged15', name: '15' },
      { id: 'aged16to17', name: '16-17' },
      { id: 'aged18to19', name: '18-19' },
      { id: 'aged20to24', name: '20-24' },
      { id: 'aged25to29', name: '25-29' },
      { id: 'aged30to44', name: '30-44' },
      { id: 'aged45to59', name: '45-59' },
      { id: 'aged60to64', name: '60-64' },
      { id: 'aged65to74', name: '65-74' },
      { id: 'aged75to84', name: '75-84' },
      { id: 'aged85to89', name: '85-89' },
      { id: 'aged90AndOver', name: '90+' },
    ],
  },

  {
    id: 'gender',
    name: 'Gender',
    children: [
      { id: 'males', name: 'Males' },
      { id: 'females', name: 'Females' },
    ],
  },

  {
    id: 'ethnicity',
    name: 'Ethnicity',
    children: [
      { id: 'bangladeshi', name: 'Bangladeshi' },
      { id: 'indian', name: 'Indian' },
      { id: 'pakistani', name: 'Pakistani' },
      { id: 'black', name: 'Black' },
      { id: 'african', name: 'African' },
      { id: 'caribbean', name: 'Caribbean' },
      { id: 'otherBlack', name: 'Other Black' },
      { id: 'chinese', name: 'Chinese' },
      { id: 'mixed', name: 'Mixed' },
      { id: 'otherEthnicGroup', name: 'Other Ethnic Group' },
      { id: 'white', name: 'White' },
      { id: 'asianOther', name: 'Asian Other' },
      { id: 'arab', name: 'Arab' },
    ],
  },

  {
    id: 'arrival',
    name: 'Year Arrived in UK',
    children: [
      { id: 'bornUk', name: 'Born in UK' },
      { id: 'pre41', name: 'Pre 1941' },
      { id: 'from41to50', name: '1941-1950' },
      { id: 'from51to60', name: '1951-1960' },
      { id: 'from61to70', name: '1961-1970' },
      { id: 'from71to80', name: '1971-1980' },
      { id: 'from81to90', name: '1981-1990' },
      { id: 'from91to00', name: '1991-2000' },
      { id: 'from01to03', name: '2001-2003' },
      { id: 'from04to06', name: '2004-2006' },
      { id: 'from07to09', name: '2007-2009' },
      { id: 'from10to11', name: '2010-2011' },
    ],
  },

  {
    id: 'economicallyActive',
    name: 'Economic Activity (Age 16 to Retirement)',
    children: [
      { id: 'economicallyActive', name: 'Economically active' },
      { id: 'economicallyInactive', name: 'Economically inactive' },
    ],
  },

  {
    id: 'nationalStatisticsSocialClass',
    name: 'Socio Economic Classification',
    children: [
      { id: 'higherManagerialProfessionalOccupations', name: 'Higher managerial and professional occupations' },
      { id: 'lowerManagerialProfessionalOccupations', name: 'Lower managerial and professional occupations' },
      { id: 'intermediateOccupations', name: 'Intermediate occupations' },
      { id: 'smallEmployersOwnAccountWorkers', name: 'Small employers and own account workers' },
      { id: 'lowerSupervisoryTechnicalOccupations', name: 'Lower supervisory and technical occupations' },
      { id: 'semiRoutineOccupations', name: 'Semi-routine occupations' },
      { id: 'routineOccupations', name: 'Routine occupations' },
      { id: 'neverWorkedLongTermUnemployed', name: 'Never worked and long-term unemployed' },
      { id: 'notClassified', name: 'NS-SeC not classified' },
    ],
  },

  {
    id: 'occupationsCensus',
    name: 'Occupations',
    children: [
      { id: 'managersDirectorsSeniorOfficials', name: 'Managers, directors and senior officials' },
      { id: 'professional', name: 'Professional occupations' },
      { id: 'associateProfessional', name: 'Associate professional and technical occupations' },
      { id: 'adminSecretarial', name: 'Administrative and secretarial occupations' },
      { id: 'skilledTrades', name: 'Skilled trades occupations' },
      { id: 'caringLeisureOtherService', name: 'Caring, leisure and other service occupations' },
      { id: 'salesCustomerService', name: 'Sales and customer service occupations' },
      { id: 'processPlantMachineOperatives', name: 'Process, plant and machine operatives' },
      { id: 'elementaryOccupations', name: 'Elementary occupations' },
      { id: 'students', name: 'Students' },
    ],
  },

  {
    id: 'accomodation',
    name: 'Dwelling Type',
    children: [
      { id: 'detatched', name: 'Detached' },
      { id: 'flat', name: 'Flat' },
      { id: 'semiDetached', name: 'Semi-detached' },
      { id: 'temporaryStructure', name: 'Temporary structure' },
      { id: 'terrace', name: 'Terrace' },
    ],
  },

  {
    id: 'tenure',
    name: 'Tenure',
    children: [
      { id: 'employerHouseholdMember', name: 'Employer of a household member' },
      { id: 'relativeFriendHouseholdMember', name: 'Relative or friend of a household member' },
      { id: 'livingRentFree', name: 'Living rent free' },
      { id: 'privateLandlordLettingAgency', name: 'Private landlord or letting agency' },
      { id: 'ownsMortgageLoan', name: 'Owns with a mortgage or loan' },
      { id: 'sharedOwnership', name: 'Shared ownership' },
      { id: 'owned', name: 'Owned' },
      { id: 'ownsOutright', name: 'Owns outright' },
      { id: 'otherPrivateRented', name: 'Other private rented' },
      { id: 'privateRented', name: 'Private rented' },
      { id: 'rentedCouncilLocalAuthority', name: 'Rented from Council (Local Authority)' },
      { id: 'otherSocialRented', name: 'Other social rented' },
      { id: 'socialRented', name: 'Social rented' },
    ],
    transform: (statisticsValues: { [id: string]: StatisticsValue }, children: { id: string; name: string }[]) => {
      const newChildren = [
        { id: 'owned', name: 'Owned', intensity: true },
        { id: 'ownsOutright', name: 'Owns outright' },
        { id: 'ownsMortgageLoan', name: 'Owns with a mortgage or loan' },
        { id: 'privateRented', name: 'Private rented', intensity: true },
        { id: 'privateLandlordLettingAgency', name: 'Private landlord or letting agency' },
        { id: 'sum1', name: 'Other private rented' },
        { id: 'socialRented', name: 'Social rented', intensity: true },
        { id: 'rentedCouncilLocalAuthority', name: 'Rented from Council (Local Authority)' },
        { id: 'otherSocialRented', name: 'Other social rented' },
        { id: 'sum2', name: 'Other', intensity: true },
      ]

      const newValues = JSON.parse(JSON.stringify(statisticsValues))
      newValues.sum1 = sumStatisticsValues([
        statisticsValues.otherPrivateRented,
        statisticsValues.employerHouseholdMember,
        statisticsValues.relativeFriendHouseholdMember,
      ])
      newValues.sum2 = sumStatisticsValues([statisticsValues.livingRentFree, statisticsValues.sharedOwnership])

      return {
        statisticsValues: newValues,
        children: newChildren,
      }
    },
  },

  {
    id: 'cars',
    name: 'Access To Cars',
    children: [
      { id: 'noCar', name: 'No car' },
      { id: 'with1car', name: '1 car or van' },
      { id: 'with2car', name: '2 car or van' },
      { id: 'with3orMore', name: '3 or more cars or vans' },
    ],
  },

  {
    id: 'travelWork',
    name: 'Method of travel to work',
    children: [
      { id: 'worksMainlyHome', name: 'Works mainly at or from home' },
      { id: 'undergroundMetroLightRailTram', name: 'Underground, metro, light rail, tram' },
      { id: 'train', name: 'Train' },
      { id: 'busMinibusCoach', name: 'Bus, minibus or coach' },
      { id: 'taxiMinicab', name: 'Taxi or minicab' },
      { id: 'motorcycleScooterMoped', name: 'Motorcycle, scooter or moped' },
      { id: 'drivingCarVan', name: 'Driving a car or van' },
      { id: 'passengerCarVan', name: 'Passenger in a car or van' },
      { id: 'bicycle', name: 'Bicycle' },
      { id: 'onFoot', name: 'On foot' },
      { id: 'otherTravelSituation', name: 'Other travel situation' },
      { id: 'notCurrentlyWorking', name: 'Not currently working' },
    ],
  },
]

export const LIFESTYLE_SEGMENTS_VIEW: ReportSegment[] = [
  {
    id: 'spendSummary',
    name: 'Spend',
    parent: true,
    skipPercent: true,
    showPerHousehold: true,
    skipTotal: true,
    children: [
      { id: 'comparisonTotal', name: 'Comparison Goods Total' },
      { id: 'convenience', name: 'Convenience Goods Total' },
      { id: 'foodAndBeverage', name: 'Food & Beverage' },
      { id: 'leisure', name: 'Leisure' },
      { id: 'spend', name: 'All', parent: true },
    ],
  },
]

export const LIFESTYLE_SEGMENTS: ReportSegment[] = [
  {
    id: 'spendSummary',
    name: 'Spend',
    parent: true,
    skipPercent: true,
    showPerHousehold: true,
    indexMoney: true,
    skipTotal: true,
    children: [
      { id: 'comparisonTotal', name: 'Comparison Goods Total' },
      { id: 'convenience', name: 'Convenience Goods Total' },
      { id: 'foodAndBeverage', name: 'Food & Beverage' },
      { id: 'leisure', name: 'Leisure' },
      { id: 'spend', name: 'All', parent: true },
    ],
  },

  {
    id: 'spend',
    name: 'Spend All',
    child: true,
    skipPercent: true,
    showPerHousehold: true,
    skipTotal: true,
    children: [
      { id: 'bakedGoods', name: 'Baked Goods' },
      { id: 'confectionery', name: 'Confectionery' },
      { id: 'otherFoodStuffs', name: 'Other Food Stuffs' },
      { id: 'fruitAndVegetables', name: 'Fruit And Vegetables' },
      { id: 'meatFishAndPoultry', name: 'MeatFish And Poultry' },
      { id: 'alcoholicDrinks', name: 'Alcoholic Drinks' },
      { id: 'cleaningAndPaperProducts', name: 'Cleaning And PaperProducts' },
      { id: 'nonAlcoholicDrinks', name: 'Non Alcoholic Drinks' },
      { id: 'petsAndPetfood', name: 'Pets And Petfood' },
      { id: 'babyEquipment', name: 'Baby Equipment' },
      { id: 'books', name: 'Books' },
      { id: 'cards', name: 'Cards' },
      { id: 'stationery', name: 'Stationery' },
      { id: 'childrenswear', name: 'Childrenswear' },
      { id: 'electricalGasAppliances', name: 'Electrical Gas Appliances' },
      { id: 'electricalGasAccessories', name: 'Electrical Gas Accessories' },
      { id: 'fashionAccessories', name: 'Fashion Accessories' },
      { id: 'floorCoverings', name: 'Floor Coverings' },
      { id: 'footwear', name: 'Footwear' },
      { id: 'furniture', name: 'Furniture' },
      { id: 'haberdashery', name: 'Haberdashery' },
      { id: 'healthBeauty', name: 'Health Beauty' },
      { id: 'homeFurnishingsGifts', name: 'Home Furnishings Gifts' },
      { id: 'homeImprovementsAndGardening', name: 'Home Improvements And Gardening' },
      { id: 'jewellery', name: 'Jewellery' },
      { id: 'menswear', name: 'Menswear' },
      { id: 'mobilePhones', name: 'Mobile Phones' },
      { id: 'motorAccessories', name: 'Motor Accessories' },
      { id: 'musicAndVideo', name: 'Music And Video' },
      { id: 'newspapersAndMagazines', name: 'Newspapers And Magazines' },
      { id: 'oTCPharmaceuticals', name: 'OTC Pharmaceuticals' },
      { id: 'photography', name: 'Photography' },
      { id: 'softFurnishingsLinens', name: 'Soft Furnishings Linens' },
      { id: 'spectaclesAndLenses', name: 'Spectacles And Lenses' },
      { id: 'sportsAndCampingEquipment', name: 'Sports And Camping Equipment' },
      { id: 'tobacco', name: 'Tobacco' },
      { id: 'toys', name: 'Toys' },
      { id: 'eatingOut', name: 'EatingOut' },
      { id: 'travelGoods', name: 'Travel Goods' },
      { id: 'womenswear', name: 'Womenswear' },
      // {id: "comparisonTotal", name: "Comparison Goods Total"},
      // {id: "foodTotal", name: "Convenience Goods Total"},
      { id: 'cinemas', name: 'Cinemas' },
      { id: 'leisureCentres', name: 'Leisure Centres' },
      { id: 'clubsDancesdiscoBingo', name: 'Clubs Dances Disco Bingo' },
      { id: 'liveEntertainment', name: 'Live Entertainment' },
      { id: 'museumZooThemeParkHouse', name: 'Museum Zoo Theme Park House' },
      { id: 'alcoholOutofHome', name: 'Alcohol Out-of-Home' },
      { id: 'restaurantandCafemeals', name: 'Restaurant and Cafe meals' },
    ],
  },

  { id: 'personicxSummary', name: 'Lifestyle Summary', special: true, lifestyleSummary: true },

  // {id: "personicx", name: "Lifestyle", children: [
  //   {id: "gx301", name:"Adventurous Students"},
  //   {id: "gx203", name:"Social Explorers"},
  //   {id: "gx304", name:"Small Town Office Workers"},
  //   {id: "gx105", name:"Urban Travellers"},
  //   {id: "gx506", name:"Striving Students"},
  //   {id: "gx210", name:"Healthy Urbanites"},
  //   {id: "gx315", name:"City Singletons"},
  //   {id: "gx418", name:"Value Brands and Tabloids"},
  //   {id: "gx520", name:"Unemployment Blues"},
  //   {id: "gx324", name:"Independent Thinkers"},
  //   {id: "gx127", name:"Flats and Convertibles"},
  //   {id: "gx229", name:"Educated Professionals"},
  //   {id: "gx337", name:"Small Town Tradesmen"},
  //   {id: "gf202", name:"Hobbies in City Suburbs"},
  //   {id: "gf307", name:"Budget Families"},
  //   {id: "gf408", name:"Income Supported"},
  //   {id: "gf109", name:"Affluent Modern Families"},
  //   {id: "gf411", name:"Family Focus"},
  //   {id: "gf512", name:"Full City Homes"},
  //   {id: "gf314", name:"Metropolitan Semis"},
  //   {id: "gf216", name:"Travel and Timeshare"},
  //   {id: "gf319", name:"City Manual Workers"},
  //   {id: "gf221", name:"Rural Connections"},
  //   {id: "gf422", name:"Children and Camping"},
  //   {id: "gf326", name:"Traditional Country Life"},
  //   {id: "gf128", name:"Spending Sporty Families"},
  //   {id: "gf430", name:"Factories and Council Estates"},
  //   {id: "gr345", name:"Companionship and Coaches"},
  //   {id: "gr346", name:"Wildlife and the Environment"},
  //   {id: "gr547", name:"Small Town Bungalows"},
  //   {id: "gr448", name:"Retired and Rural"},
  //   {id: "gr549", name:"Widowed and Retired"},
  //   {id: "gr250", name:"Financially Savvy Retirees"},
  //   {id: "gr551", name:"Betting and Bingo"},
  //   {id: "gr152", name:"Organic Supporters"},
  //   {id: "gr153", name:"Mature Convertible Drivers"},
  //   {id: "gr354", name:"Coaches and Conservatories"},
  //   {id: "gr355", name:"Conventional Givers"},
  //   {id: "gr256", name:"Charities and Trust Funds"},
  //   {id: "gr557", name:"Aged in the City Suburbs"},
  //   {id: "gr458", name:"Gardening and Grandchildren"},
  //   {id: "gr559", name:"Budget Conscious Pensioners"},
  //   {id: "gr560", name:"Pastoral Volunteers"},
  //   {id: "gm313", name:"Single Students"},
  //   {id: "gm517", name:"Low Cash, Low Credit"},
  //   {id: "gm223", name:"Organic and Urban"},
  //   {id: "gm225", name:"Urban Terraces"},
  //   {id: "gm531", name:"Rent Books and Benefits"},
  //   {id: "gm232", name:"Healthy, Wealthy and Wise"},
  //   {id: "gm433", name:"Jam-packed Households"},
  //   {id: "gm234", name:"Country Pursuits"},
  //   {id: "gm235", name:"Semi-detached Success"},
  //   {id: "gm336", name:"Gardeners World"},
  //   {id: "gm238", name:"Rural Couples Nearing Retirement"},
  //   {id: "gm539", name:"Retired Solos on a Tight Budget"},
  //   {id: "gm440", name:"Long Standing Tenants"},
  //   {id: "gm541", name:"Local Life"},
  //   {id: "gm342", name:"Shrewd Solos"},
  //   {id: "gm143", name:"Gourmet Travellers"},
  //   {id: "gm244", name:"Detached in the City Suburbs"},
  // ]}
]

export const WORKFORCE_SEGMENTS: ReportSegment[] = [
  {
    id: 'workerAges',
    name: 'Workforce',
    children: [
      { id: 'aged16to24', name: '16 to 24' },
      { id: 'aged25to34', name: '25 to 34' },
      { id: 'aged35to44', name: '35 to 44' },
      { id: 'aged45to54', name: '45 to 54' },
      { id: 'aged55to64', name: '55 to 64' },
      { id: 'aged65to74', name: '65 to 74' },
    ],
  },
  {
    id: 'malesAges',
    name: 'Workforce Males',
    children: [
      { id: 'aged16to24', name: '16 to 24' },
      { id: 'aged25to34', name: '25 to 34' },
      { id: 'aged35to44', name: '35 to 44' },
      { id: 'aged45to54', name: '45 to 54' },
      { id: 'aged55to64', name: '55 to 64' },
      { id: 'aged65to74', name: '65 to 74' },
    ],
  },
  {
    id: 'femalesAges',
    name: 'Workforce Females',
    children: [
      { id: 'aged16to24', name: '16 to 24' },
      { id: 'aged25to34', name: '25 to 34' },
      { id: 'aged35to44', name: '35 to 44' },
      { id: 'aged45to54', name: '45 to 54' },
      { id: 'aged55to64', name: '55 to 64' },
      { id: 'aged65to74', name: '65 to 74' },
    ],
  },
  {
    id: 'occupations',
    name: 'Occupation',
    children: [
      { id: 'managersDirectorsSeniorOfficials', name: 'Managers, directors and senior officials' },
      { id: 'professional', name: 'Professional occupations' },
      { id: 'associateProfessional', name: 'Associate professional and technical occupations' },
      { id: 'adminSecretarial', name: 'Administrative and secretarial occupations' },
      { id: 'skilledTrades', name: 'Skilled trades occupations' },
      { id: 'caringLeisureOtherService', name: 'Caring, leisure and other service occupations' },
      { id: 'salesCustomerService', name: 'Sales and customer service occupations' },
      { id: 'processPlantMachineOperatives', name: 'Process, plant and machine operatives' },
      { id: 'elementaryOccupations', name: 'Elementary occupations' },
    ],
  },
  {
    id: 'industries',
    name: 'Industries',
    children: [
      { id: 'agricultureForestryFishing', name: 'Agriculture, forestry and fishing' },
      { id: 'miningQuarrying', name: 'Mining and quarrying' },
      { id: 'manufacturing', name: 'Manufacturing' },
      { id: 'electricityGasSteam', name: 'Electricity, gas, steam and air conditioning supply' },
      {
        id: 'waterSewerageWasteManagement',
        name: 'Water supply, sewerage, waste management and remediation activities',
      },
      { id: 'construction', name: 'Construction' },
      {
        id: 'wholesaleRetailTradeRepairMotorVehicles',
        name: 'Wholesale and retail trade; repair of motor vehicles and motor cycles',
      },
      { id: 'transportStorage', name: 'Transport and storage' },
      { id: 'accomodationFoodServices', name: 'Accommodation and food service activities' },
      { id: 'informationCommunication', name: 'Information and communication' },
      { id: 'financialInsuranceActivities', name: 'Financial and insurance activities' },
      { id: 'realEstateActivities', name: 'Real estate activities' },
      { id: 'professionalScientificTechnicalActivities', name: 'Professional, scientific and technical activities' },
      { id: 'administrativeSupportServiceActivities', name: 'Administrative and support service activities' },
      {
        id: 'publicAdministrationDefenceCompulsorySocialSecurity',
        name: 'Public administration and defence; compulsory social security',
      },
      { id: 'education', name: 'Education' },
      { id: 'humanHealthSocialWorkActivities', name: 'Human health and social work activities' },
      {
        id: 'artsEntertainmentRecreationOtherServiceActivities',
        name: 'Arts, entertainment and recreation; other service activities',
      },
      {
        id: 'other1',
        name: 'Activities of households as employers; undifferentiated goods - and services - producing activities of households for own use',
      },
      { id: 'other2', name: 'Activities of extraterritorial organisations and bodies' },
    ],
  },
  {
    id: 'socialClassWorkforce',
    name: 'Social Grade',
    children: [
      { id: 'ab', name: 'AB' },
      { id: 'c1', name: 'C1' },
      { id: 'c2', name: 'C2' },
      { id: 'de', name: 'DE' },
    ],
  },
]

export const LIFESTYLE_SUMMMARY_SEGMENTS = [
  { id: 'a', name: 'PreFamily' },
  { id: 'b', name: 'Families' },
  { id: 'c', name: 'Empty Nesters' },
  { id: 'd', name: 'Retired, Seniors' },
]

// export const MATCHPOINT_ALL_CATEGORIES =
//   CENSUS_SEGMENTS_RAW
//   .concat(LIFESTYLE_SEGMENTS[0].children)
//   .concat(LIFESTYLE_SEGMENTS[1].children)
