import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

/**
 * Because angular2 material at the moment lacks a quick way to make a dialog with some standard buttons,
 * this class provides this functionality
 * It shows a dialog with a given text message and two buttons, corresponding to ok and cancel
 */
@Component({
  selector: 'tgm-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.less']
})
export class TgmInfoDialogComponent {
  model: any = null

  constructor(public dialogRef: MatDialogRef<TgmInfoDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) readonly dialogData: any) {
    this.model = dialogData
  }
}
