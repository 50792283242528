import { Component, OnInit } from '@angular/core';
import { SubscriberComponent } from '@targomo/client';
import { Observable } from 'rxjs';
import { Category } from '../../../../api/category';
import { AppModel } from '../../../../model/appModel.service';
import { OperatorNotPresent, OperatorPresent } from '../../../../model/operatorsWithinModel';
import { map,shareReplay } from 'rxjs/operators';

function sortOperators(operators: OperatorPresent[]) {
  function compare(a: OperatorPresent, b: OperatorPresent) {
    if (a.category.name < b.category.name) {
      return -1
    } else if (a.category.name > b.category.name) {
      return 1
    } else {
      return 0
    }
  }

  return (operators || []).sort(compare)
}

@Component({
  selector: 'operators-present-travel',
  templateUrl: './operatorsPresentTravel.component.html',
  styleUrls: ['../../../sidenav/layerSelectPanel/layerSelectPanel.less', './operatorsPresentTravel.component.less']
})
export class OperatorsPresentTravelComponent extends SubscriberComponent {
  state: any = {}

  withinList$: Observable<OperatorPresent[]>
  notWithinList$: Observable<OperatorNotPresent[]>

  constructor(private appModel: AppModel) {
    super()

    this.withinList$ = appModel.places.operatorsWithin.operatorsWithin$.pipe(
      map(v => sortOperators(v)),
      shareReplay(1)
    )

    this.notWithinList$ = appModel.places.operatorsWithin.operatorsNotWithin$.pipe(
      map(v => sortOperators(v)),
      shareReplay(1)
    )
  }

  ngOnInit() {
  }

  toggle(key: string) {
    this.state[key] = !this.state[key]
  }

  exportCsvWithin(event: any) {
    event.stopPropagation()

    this.appModel.places.operatorsWithin.exportCsvWithin()
  }

  exportCsvNotWithin(event: any) {
    event.stopPropagation()

    this.appModel.places.operatorsWithin.exportCsvNotWithin()
  }
}
