import { Observable, combineLatest } from 'rxjs'
import { Place } from '../../../api/place'
import { AllStatistics, ReportPerCapitaHousehold } from '../../../../common/models/statistics/statistics'
import { MatchpointEndpoint } from '../../../api/matchpoint'
import { StatisticsModel } from '../../statisticsModel'
import { TravelTypeEdgeWeightOptions } from '@targomo/core'
import { MatchpointResult } from '../matchpointModel'
import { map, shareReplay } from 'rxjs/operators'
import { findDominantVariables } from '../helpers/findDominantVariable'
import { DataLoadingModel } from '../../dataLoadingModel'
import { processLocationOnLoad } from '../helpers/processLocationsOnLoad'

export function loadMatchpointReport(
  matchpointEndpoint: MatchpointEndpoint,
  dataLoadingModel: DataLoadingModel,
  sources$: Observable<Place[]>,
  statisticsModel: StatisticsModel,
  travelOptionsUpdates$: Observable<TravelTypeEdgeWeightOptions>,
  includeCatchment$: Observable<boolean>,
  matchpointSettings$: Observable<any[]>,
  centerPointId$: Observable<string>
): Observable<Promise<MatchpointResult>> {
  const processLocation = async (places: Place[]): Promise<Place[]> => {
    return processLocationOnLoad(places, dataLoadingModel)
  }

  return combineLatest(
    sources$,
    statisticsModel.census.value,
    travelOptionsUpdates$,
    includeCatchment$,
    matchpointSettings$,
    centerPointId$
  ).pipe(
    map(async ([sources, census, travelOptions, includeCatchment, settings, centerPointId]) => {
      if (!census || !sources || sources.length == 0) {
        return null
      }

      if (!settings || settings.length === 0) {
        settings = await matchpointEndpoint.getSettingsPublic()
      }

      const statistics = new AllStatistics(
        census.inputOriginal,
        census.gbpToEuroRate,
        sources[0].region,
        sources[0].region,
        sources[0].country,
        ReportPerCapitaHousehold.PER_HOUSEHOLD
      )

      const dominant = findDominantVariables(sources, statistics, settings, !includeCatchment).dominant

      const result = await matchpointEndpoint.getSimilar(sources, { dominant, travelOptions, settings }, centerPointId)
      result.similar = await processLocation(result.similar)
      result.subjectOperators = await processLocation(result.subjectOperators)
      result.similarOperators = await processLocation(result.similarOperators)

      return <any>{
        similar: result.similar,
        subjectOperatorLocations: result.subjectOperators,
        similarOperatorLocations: result.similarOperators,
      }
    }),
    shareReplay(1)
  )
}
