<ng-container *ngIf="currentPanel == 'edit'">
  <div class="admin-panel">
    <form class="form" name="form" (submit)="save($event)" novalidate>
      <section>
        <div class="title">Enabled categories</div>

        <section>
          <div style="font-weight: bold; padding: 4px; margin-bottom: 16px">
            <mat-checkbox
              (ngModelChange)="toggleOnlySelected($event)"
              name="onlySelected"
              [(ngModel)]="showOnlySelected"
              aria-label=""
              >Show only selected</mat-checkbox
            >
          </div>

          <table style="width: 100%">
            <tr>
              <th></th>
              <th>Statistic</th>
              <th>Type</th>
              <th colspan="2">Subject</th>
              <th>Minimum %</th>
              <th>Maximum %</th>
            </tr>

            <tr class="subheader">
              <th></th>
              <th></th>
              <th></th>
              <th>Dominant / Selected</th>
              <th>Value</th>
              <th></th>
              <th></th>
            </tr>

            <tr
              *ngFor="let profile of visibleCategories; let i = index"
              [class.disabledCatchment]="profile.isCatchment && !(model?.catchmentPossible$ | async)"
            >
              <td (click)="toggleCategory(profile)">
                <mat-checkbox style="pointer-events: none" [checked]="profile.selected" aria-label=""></mat-checkbox>
              </td>
              <td [class.disabled]="!profile.selected" (click)="toggleCategory(profile)">{{ profile.label }}</td>

              <td [class.disabled]="!profile.selected">
                <mat-select
                  (ngModelChange)="modelChanged($event)"
                  style="width: 150px"
                  [(ngModel)]="profile.type"
                  name="type{{ i }}"
                  placeholder="Type"
                >
                  <ng-container *ngFor="let option of avalableConditionTypes">
                    <mat-option
                      *ngIf="
                        !(
                          (option.value === USER_INDEX || option.value === USER_ABSOLUTE_VALUE) &&
                          !profile.children?.length
                        )
                      "
                      [value]="option.value"
                    >
                      {{ option.label }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </td>

              <td [class.disabled]="!profile.selected">
                <ng-container *ngIf="profile.selected">
                  <ng-container *ngIf="profile.type == USER_INDEX || profile.type == USER_ABSOLUTE_VALUE">
                    <mat-select
                      *ngIf="profile.children.length > 0"
                      (ngModelChange)="modelChanged($event)"
                      style="width: 100px"
                      [(ngModel)]="profile.subKey"
                      placeholder="Dominant"
                      name="subKey{{ i }}"
                    >
                      <mat-option *ngFor="let optionSub of profile.children" [value]="optionSub.key">
                        {{ optionSub.label }}
                      </mat-option>
                    </mat-select>
                  </ng-container>

                  <ng-container *ngIf="!(profile.type == USER_INDEX || profile.type == USER_ABSOLUTE_VALUE)">
                    {{ profile.childrenMap[dominant[profile.key]?.subKey]?.label }}
                  </ng-container>
                </ng-container>
              </td>

              <td [class.disabled]="!profile.selected">
                <ng-container *ngIf="profile.selected">
                  <ng-container *ngIf="!!(profile.type == USER_INDEX || profile.type == PERCENT_INDEX)">
                    {{ dominant[profile.key]?.percentOverIndex | number: '1.0-0' }}
                  </ng-container>

                  <ng-container *ngIf="!(profile.type == USER_INDEX || profile.type == PERCENT_INDEX)">
                    {{ dominant[profile.key]?.total | number: '1.0-0' }}
                  </ng-container>
                </ng-container>
              </td>

              <td [class.disabled]="!profile.selected">
                <mat-form-field style="width: 90px">
                  <input
                    [disabled]="!profile.selected"
                    matInput
                    [(ngModel)]="profile.minimum"
                    (ngModelChange)="modelChanged($event)"
                    name="minimum{{ i }}"
                    placeholder="Minimum"
                  />
                </mat-form-field>
              </td>

              <td [class.disabled]="!profile.selected">
                <mat-form-field style="width: 90px">
                  <input
                    (ngModelChange)="modelChanged($event)"
                    [disabled]="!profile.selected"
                    matInput
                    [(ngModel)]="profile.maximum"
                    name="maximum{{ i }}"
                    placeholder="Maximum"
                  />
                </mat-form-field>
              </td>
            </tr>
          </table>
        </section>
      </section>

      <div class="buttons-panel" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <tgm-saveable [model]="saving"></tgm-saveable>
        <button (click)="load()" mat-button type="button">Load</button>
        <button (click)="save()" mat-flat-button type="button">Save</button>
        <!-- <button (click)="use()" mat-flat-button color="primary" type="submit">Use</button> -->
      </div>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="currentPanel == 'save'">
  <save-matchpoint-config-panel (back)="back()"></save-matchpoint-config-panel>
</ng-container>

<ng-container *ngIf="currentPanel == 'load'">
  <load-matchpoint-panel (back)="back()"></load-matchpoint-panel>
</ng-container>
