import { Component, Input, OnInit, OnChanges, SimpleChange, Inject, Optional } from '@angular/core'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material'
import { AppModel } from '../../../model/appModel.service'

@Component({
  selector: 'color-select-dialog',
  templateUrl: './colorSelectDialog.component.html',
  styleUrls: ['./colorSelectDialog.component.less'],
})
export class ColorSelectDialog {
  color: string = '#f0f0f0'
  selectedIcon: number = 0
  iconSize = 1
  withIcons = false
  withColors = true

  SIZES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((size) => {
    return {
      value: size,
      label: '' + size * 100 + '%',
    }
  })

  readonly ICONS = Array.from({ length: 14 }).map((item, i) => {
    return { id: i, file: `marker_icons/marker-${i}.svg` }
  })

  constructor(
    public dialogRef: MatDialogRef<ColorSelectDialog>,
    private appModel: AppModel,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private dialogData: {
      withIcons: boolean
      withColors: boolean
      value: { color: string; icon: number; iconSize: number }
    }
  ) {}

  ngOnInit() {
    const value = this.dialogData && this.dialogData.value
    this.color = (value && value.color) || '#f0f0f0'
    this.selectedIcon = (value && value.icon) || 0
    this.iconSize = (value && value.iconSize) || 1
    this.withIcons = this.dialogData && !!this.dialogData.withIcons
    this.withColors = this.dialogData && this.dialogData.withColors !== false
  }

  save(event?: any) {
    this.dialogRef.close({ color: this.color, icon: this.selectedIcon, iconSize: this.iconSize || 1 })
  }

  selectIcon(icon: { id: number }) {
    this.selectedIcon = icon.id
  }

  selectIconSize(size: any) {
    this.iconSize = size.value
  }
}
