import { ChartOptions } from "chart.js"
import { DwellingPerPrice, DwellingTypesPerPrice } from "../../../../common/models/statistics/statistics"
import { CareHomeReport } from "../../../model/careHome/careHomeModel"
import { POPULATION_FORECAST_KEYS, POPULATION_FORECAST_LABELS } from "../../../model/statisticsModel"
import { createGraph } from "../createGraph"

export const HOME_PRICE_KEYS = [
  'lessThan100k',
  'from100kto200k',
  'from200kto300k',
  'from300kto400k',
  'from400kto500k',
  'from500kto600k',
  'from600kto700k',
  'from700kto800k',
  'from800kto900k',
  'from900kto1m',
  'from1mto1_1m',
  'from1_1mto1_2m',
  'from1_2mto1_3m',
  'from1_3mto1_4m',
  'from1_4mto1_5m',
  'above1_5m',
]

export const HOME_PRICE_LABELS = [
  '0-100k',
  '100k-200k',
  '200k-300k',
  '300k-400k',
  '400k-500k',
  '500k-600k',
  '600k-700k',
  '700k-800k',
  '800k-900k',
  '900k-1m',
  '1m-1.1m',
  '1.1m-1.2m',
  '1.2m-1.3m',
  '1.3m-1.4m',
  '1.4m-1.5m',
  '1.5m+',
]

const GRAPH_OPTIONS: ChartOptions = {
  scales: {
    yAxes: [{
      ticks: {
        callback: function(value: any) {
          return `${value}%`
        }
      }
    }]
  }
}

export async function careGraphPopulation(report: CareHomeReport) {
  const years = [report.currentYear, report.additional[0].year]
  const colors = ['#7d40fc', '#c44042']

  const data = {
    labels: POPULATION_FORECAST_LABELS,
    datasets: years.map((year, i) => {
      return {
        label: '' + year,
        borderColor: colors[i],
        backgroundColor: colors[i],
        data: POPULATION_FORECAST_KEYS.map(key => report.statistics.projectedPopulation.year[year][key].total)
      }
    })
  }

  return await createGraph('bar', data, {...GRAPH_OPTIONS}, 600, 400)
}


export async function careHouse(report: DwellingPerPrice) {
  const valueKeys = ['percent', 'index']
  const colors = ['#7d40fc', '#c44042']
  const types = ['bar', 'line']
  const labels = ['Subject', 'Avg']

  const data = {
    labels: HOME_PRICE_LABELS,
    datasets: valueKeys.map((year, i) => {
      return {
        type: types[i],
        label: labels[i],
        borderColor: colors[i],
        backgroundColor: i === 0 ? colors[i] : 'rgba(0, 0, 0, 0)',
        data: HOME_PRICE_KEYS.map(key => report[key][valueKeys[i]])
      }
    })
  }

  return await createGraph('bar', data, {...GRAPH_OPTIONS}, 600, 170)
}

