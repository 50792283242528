<div [hidden]="!!saveAs">
  <back-button></back-button>
  <section style="padding: 0px 16px">
    <div *ngIf="currentSession">
      <h3>Save Selection Criteria</h3>

      <div style="text-align: center; margin: 32px">
        <div>Currenty loaded criteria:</div>
        <div class="session-name">{{ currentSession?.name }}</div>
      </div>

      <div class="bottom-buttons" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <button (click)="saveUpdate()" mat-flat-button color="primary" type="button">
          <span>Update current criteria</span>
        </button>
        <button (click)="saveNew()" mat-flat-button color="primary" type="button">
          <span>Save as new criteria</span>
        </button>
      </div>
    </div>
  </section>
</div>

<div [hidden]="!saveAs">
  <section style="padding: 0px 16px">
    <h3>Save Selection Criteria</h3>

    <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column" novalidate>
      <mat-form-field>
        <input matInput type="text" formControlName="name" required placeholder="Criteria name" />

        <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{ color: 'red' }">
          <span>Selection Criteria name is required</span>
        </mat-hint>
      </mat-form-field>

      <div class="bottom-buttons" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <button matTooltip="Back" matTooltipPosition="below" mat-button type="button" (click)="clickBack()">
          <mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span>Back</span>
        </button>
        <button mat-flat-button color="primary" type="submit"><span>Save</span></button>
      </div>
    </form>
  </section>
</div>
