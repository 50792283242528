
    <div fxLayout="row">
      <div fxFlex>
        <button style="color: white" mat-button routerLink="/"><img class="logo" src="{{basePath}}api/settings/logo/{{user.id}}?ts={{appModel.logoUpdated | async}}"/></button>
      </div>

      <div fxLayout="column" layout-align="center center" class="names">
        <div class="name">{{user.name}}</div>
        <div class="username">{{user.username}}</div>
      </div>

      <div>
        <img *ngIf="user.hasPicture" class="profile" src="{{basePath}}api/users/{{user.id}}/picture"/>
        <img *ngIf="!user.hasPicture" class="profile" src="{{basePath}}assets/images/profile.png">
      </div>
    </div>
  