<div class="admin-panel">
  <!--<div class="align-right">
      <button type="button" (click)="back()">&larr; Back</button>
    </div>-->

  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Edit Data Set</h2>
    <div class="secondary">
      <button mat-button class="mat-primary" routerLink="/admin/dataset/upload/{{setId}}">
        <mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> Upload New Data Set Version
      </button>
    </div>
  </div>

  <form [formGroup]="form" class="form" name="form" (submit)="save(form)" novalidate style="margin-bottom: 30px">
    <section fxLayout="column">
      <mat-form-field>
        <input placeholder="Name" matInput type="text" formControlName="name" />

        <!--<mat-hint *ngIf="form.submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
          Please enter a name
        </mat-hint>-->
      </mat-form-field>

      <mat-form-field>
        <!-- <input placeholder="Label column" matInput type="text" formControlName="labelColumn"/> -->

        <mat-select placeholder="Label column" formControlName="labelColumn">
          <mat-option *ngFor="let option of properties" [value]="option">{{option}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input placeholder="Copyright Notice" matInput type="text" formControlName="copyright" />
      </mat-form-field>

      <div fxLayout="row" style="margin: 8px 0px">
        <div fxFlex>Show as "Also available"</div>
        <mat-checkbox placeholder="Show as Also Available" formControlName="publicView"> </mat-checkbox>
      </div>

      <div fxLayout="row" style="margin: 32px 0px">
        <div fxFlex>Planning data set</div>
        <mat-checkbox placeholder="Planning data set" formControlName="planningDataSet"> </mat-checkbox>
      </div>

      <!-- <div fxLayout="row" style="margin: 8px 0px">
        <div fxFlex>Postcodes data set</div>
        <mat-checkbox placeholder="Postcodes data set" formControlName="postcodesDataSet"> </mat-checkbox>
      </div> -->

      <mat-form-field>
        <mat-select placeholder="Planning value column" formControlName="planningValueColumn">
          <mat-option *ngFor="let option of properties" [value]="option">{{option}}</mat-option>
        </mat-select>
      </mat-form-field>

      <section style="margin-top: 20px; width: 100%">
        <h3 class="mat-title subtitle">Auto Update</h3>
        <div fxLayout="row" style="margin: 8px 0px">
          <div fxFlex>Auto update enabled</div>
          <mat-checkbox placeholder="Auto update enabled" formControlName="autoUpdate"> </mat-checkbox>
        </div>

        <div style="width: 100%" [hidden]="!form.value.autoUpdate">
          <mat-form-field style="width: 100%">
            <input placeholder="Auto Update URL" matInput type="text" formControlName="autoUpdateURL" />
          </mat-form-field>
        </div>

        <div style="width: 100%" [hidden]="!form.value.autoUpdate">
          <mat-form-field style="width: 100%">
            <mat-select placeholder="Categorize by" formControlName="categorizeBy">
              <mat-option value="secondary_category">Secondary Category</mat-option>
              <mat-option value="holding_company">Holding Company</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </section>
    </section>

    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-button type="button" (click)="back()">&larr; Back</button>
      <button mat-flat-button color="primary" type="submit">Save</button>
    </div>
  </form>

  <section class="auto-update-info" *ngIf="set.autoUpdate">
    <table>
      <tr>
        <td>Last AutoUpdate Attempt:</td>
        <td>{{autoUpdateLog?.startTime | date: 'medium'}}</td>
      </tr>
      <tr>
        <td>Last AutoUpdate Success:</td>
        <td>{{autoUpdateLog?.successTime | date: 'medium'}}</td>
      </tr>
      <tr>
        <td>Last AutoUpdate Failure:</td>
        <td>{{autoUpdateLog?.failureTime | date: 'medium'}}</td>
      </tr>
      <tr>
        <td colspan="2">
          <div style="margin-bottom: 8px">Last AutoUpdate Message:</div>
          <code>{{autoUpdateLog?.message}}</code>
        </td>
      </tr>
      <tr *ngIf="updateNowScheduled || set.autoUpdateNow">
        <td>Update Now Scheduled:</td>
        <td>yes (note: reload page to refresh this status)</td>
      </tr>
    </table>

    <div class="auto-update-now-button-container">
      <button
        color="primary"
        [disabled]="updateNowScheduled || set.autoUpdateNow"
        mat-button
        type="button"
        (click)="updateNow()"
      >
        Update Now
      </button>
    </div>
  </section>

  <section style="margin-bottom: 20px">
    <h3 class="mat-title subtitle">Versions</h3>
    <admin-data-set-versions (updated)="updatedVersion()" [setId]="setId"></admin-data-set-versions>
  </section>

  <h3 class="mat-title subtitle">Categories</h3>

  <tgm-loadable loading="loading">
    <mat-list>
      <!-- <mat-divider></mat-divider> -->
      <div *ngFor="let category of categories">
        <mat-list-item [hidden]="currentEdit == category.id" (click)="editCategory(category)">
          <div class="mat-avatar" [style.background]="category.color || '#ddd'"></div>
          <div class="name-section" fxFlex fxLayout="column">
            <span>{{category.name}}</span>
          </div>

          <button (click)="showMenu($event)" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="mat-24">more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-button routerLink="/admin/profile/{{category.id}}/category/{{set.id}}" aria-label="Edit">
              <mat-icon mat-font-set="material-icons">mode_edit</mat-icon>
              Edit Data Profile
            </button>
          </mat-menu>
          <!-- <mat-divider></mat-divider> -->
        </mat-list-item>
        <admin-category-edit
          [model]="category"
          (onClose)="onCloseEdit($event)"
          *ngIf="currentEdit == category.id"
        ></admin-category-edit>
      </div>
    </mat-list>
  </tgm-loadable>
</div>
