<div class="admin-panel">
  <h2 class="mat-title">{{profile.id && 'Edit' || 'New'}} Data Profile</h2>

  <form [formGroup]="form" class="form" name="form" (submit)="save(form)"  novalidate style="margin-bottom: 30px">
    <section fxLayout="column">
      <mat-form-field fxFlex>
        <input matInput placeholder="Name" type="text" required formControlName="name" name="name"/>

        <mat-hint *ngIf="submitted && form.get('name').hasError('required')" [ngStyle]="{'color': 'red'}">
          Name is required
        </mat-hint>
      </mat-form-field>
    </section>

    <mat-list>
      <mat-list-item *ngFor="let set of available" (click)="toggle(set)">
          <mat-checkbox style="pointer-events: none" [checked]="set.selected" (change)="toggle(set)"></mat-checkbox>
          <div class="name-section" fxFlex fxLayout="column" >
            <span>{{set.name}}</span>
          </div>

          <button (click)="showMenu($event)" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="mat-24">more_vert</mat-icon></button>

          <mat-menu #menu="matMenu">
            <button mat-button (click)="editDataSet(set)" aria-label="Edit">
              <mat-icon mat-font-set="material-icons">mode_edit</mat-icon>
              Edit Data Set
            </button>
          </mat-menu>
        <!-- <mat-divider></mat-divider> -->
      </mat-list-item>
    </mat-list>


    <div class="align-right" style="margin-top: 24px">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-button type="button" (click)="back()">&larr; Back</button>
      <button mat-flat-button color="primary" type="submit">Save</button>
    </div>
  </form>
</div>