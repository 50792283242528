

import {Component} from '@angular/core'
import {UserEndpoint, User} from '../../api/user';
import { APP_BASE_PATH } from "../../util/basePath";
import { AppModel } from '../../model/appModel.service';

@Component({
  selector: 'admin-header',
  template: `
    <div fxLayout="row">
      <div fxFlex>
        <button style="color: white" mat-button routerLink="/"><img class="logo" src="{{basePath}}api/settings/logo/{{user.id}}?ts={{appModel.logoUpdated | async}}"/></button>
      </div>

      <div fxLayout="column" layout-align="center center" class="names">
        <div class="name">{{user.name}}</div>
        <div class="username">{{user.username}}</div>
      </div>

      <div>
        <img *ngIf="user.hasPicture" class="profile" src="{{basePath}}api/users/{{user.id}}/picture"/>
        <img *ngIf="!user.hasPicture" class="profile" src="{{basePath}}assets/images/profile.png">
      </div>
    </div>
  `,
  styleUrls: ['./adminHeader.less']
  
})
export class AdminHeaderComponent {
  basePath = APP_BASE_PATH
  user: User = <any>{}

  constructor(private userEndpoint: UserEndpoint, readonly appModel: AppModel) {
  }

  async ngOnInit() {
    this.user = await this.userEndpoint.me()
  }
}