import { ExtendedStatisticsKey, StatisticsSet } from '@targomo/core'
import { STATISTICS_FORECAST } from './constantsForecast'
import { STATISTICS_EXTENDED } from './statisticsExtended'

export const GREAT_BRITAIN_HEX_500M = 99
export const GREAT_BRITAIN_HEX_100M = 98
export const GREAT_BRITAIN_WORKFORCE = 169

export const STATISTICS_GROUP = GREAT_BRITAIN_HEX_100M
export const STATISTICS_GROUP_WORKFORCE = GREAT_BRITAIN_WORKFORCE
// export const STATISTICS_GROUP = 'zensus_100m_statistics'
// export const STATISTICS_LAYER_GROUP = 'germany_zensus_100m_2011'
// export const STATISTICS: ExtendedStatisticsKey[] = [
// ]

export const STATISTICS: ExtendedStatisticsKey[] = [
  {
    id: 0,
    name: 'Households',
    label: 'Households - Count of households',
    type: 'value',
    chart: true,
  },
  {
    id: 1,
    name: 'Population',
    label: 'Population - Count of population',
    type: 'value',
    chart: true,
  },
  {
    id: 2,
    name: 'A1_Households',
    label: 'A1 Households - Pre Family, High Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 3,
    name: 'A2_Households',
    label: 'A2 Households - Pre Family, Above Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 4,
    name: 'A3_Households',
    label: 'A3 Households - Pre Family, Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 5,
    name: 'A4_Households',
    label: 'A4 Households - Pre Family, Below Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 6,
    name: 'A5_Households',
    label: 'A5 Households - Pre Family, Low Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 7,
    name: 'B1_Households',
    label: 'B1 Households - Family, High Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 8,
    name: 'B2_Households',
    label: 'B2 Households - Family, Above Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 9,
    name: 'B3_Households',
    label: 'B3 Households - Family, Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 10,
    name: 'B4_Households',
    label: 'B4 Households - Family, Below Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 11,
    name: 'B5_Households',
    label: 'B5 Households - Family, Low Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 12,
    name: 'C1_Households',
    label: 'C1 Households - Empty Nester, High Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 13,
    name: 'C2_Households',
    label: 'C2 Households - Empty Nester, Above Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 14,
    name: 'C3_Households',
    label: 'C3 Households - Empty Nester, Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 15,
    name: 'C4_Households',
    label: 'C4 Households - Empty Nester, Below Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 16,
    name: 'C5_Households',
    label: 'C5 Households - Empty Nester, Low Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 17,
    name: 'D1_Households',
    label: 'D1 Households - Retired, Senior, High Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 18,
    name: 'D2_Households',
    label: 'D2 Households - Retired, Senior, Above Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 19,
    name: 'D3_Households',
    label: 'D3 Households - Retired, Senior, Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 20,
    name: 'D4_Households',
    label: 'D4 Households - Retired, Senior, Below Average Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 21,
    name: 'D5_Households',
    label: 'D5 Households - Retired, Senior, Low Income Households',
    type: 'value',
    chart: true,
  },
  {
    id: 22,
    name: 'AGED001501',
    label: 'Age: 0-15',
    type: 'value',
    chart: true,
  },
  {
    id: 23,
    name: 'AGED162401',
    label: 'Age: 16-24',
    type: 'value',
    chart: true,
  },
  {
    id: 24,
    name: 'AGED254401',
    label: 'Age: 25-44',
    type: 'value',
    chart: true,
  },
  {
    id: 25,
    name: 'AGED456401',
    label: 'Age: 45-64',
    type: 'value',
    chart: true,
  },
  {
    id: 26,
    name: 'Aged65PLS01',
    label: 'Age: 65+',
    type: 'value',
    chart: true,
  },
  {
    id: 27,
    name: 'ACCHDET01',
    label: 'Dwelling Type: Detached',
    type: 'value',
    chart: true,
  },
  {
    id: 28,
    name: 'ACCHFLAT01',
    label: 'Dwelling Type: Flat',
    type: 'value',
    chart: true,
  },
  {
    id: 29,
    name: 'ACCHSEMI01',
    label: 'Dwelling Type: Semi-detached',
    type: 'value',
    chart: true,
  },
  {
    id: 30,
    name: 'ACCHTEMP01',
    label: 'Dwelling Type: Temporary structure',
    type: 'value',
    chart: true,
  },
  {
    id: 31,
    name: 'ACCHTER01',
    label: 'Dwelling Type: Terrace',
    type: 'value',
    chart: true,
  },
  {
    id: 32,
    name: 'CAR0_01',
    label: 'Access To Cars: 0',
    type: 'value',
    chart: true,
  },
  {
    id: 33,
    name: 'CAR1_01',
    label: 'Access To Cars: 1',
    type: 'value',
    chart: true,
  },
  {
    id: 34,
    name: 'CAR2_01',
    label: 'Access To Cars: 2',
    type: 'value',
    chart: true,
  },
  {
    id: 35,
    name: 'CAR3PLS_01',
    label: 'Access To Cars: 3+',
    type: 'value',
    chart: true,
  },
  {
    id: 36,
    name: 'TENHEMP01',
    label: 'Tenure: Employer of a household member',
    type: 'value',
    chart: true,
  },
  {
    id: 37,
    name: 'TENHFAM01',
    label: 'Tenure: Relative or friend of a household member',
    type: 'value',
    chart: true,
  },
  {
    id: 38,
    name: 'TENHFREE01',
    label: 'Tenure: Rent free',
    type: 'value',
    chart: true,
  },
  {
    id: 39,
    name: 'TENHHTOT01',
    label: 'Tenure: Tenancy Total',
    type: 'value',
    chart: true,
  },
  {
    id: 40,
    name: 'TENHLLLA01',
    label: 'Tenure: Private landlord or letting agency',
    type: 'value',
    chart: true,
  },
  {
    id: 41,
    name: 'TENHMTG01',
    label: 'Tenure: Mortgage or loan',
    type: 'value',
    chart: true,
  },
  {
    id: 42,
    name: 'TENHOSH01',
    label: 'Tenure: Shared ownership',
    type: 'value',
    chart: true,
  },
  {
    id: 43,
    name: 'TENHOWN01',
    label: 'Tenure: Owned',
    type: 'value',
    chart: true,
  },
  {
    id: 44,
    name: 'TENHOWO01',
    label: 'Tenure: Owns outright',
    type: 'value',
    chart: true,
  },
  {
    id: 45,
    name: 'TENHPOTH01',
    label: 'Tenure: Other private rented',
    type: 'value',
    chart: true,
  },
  {
    id: 46,
    name: 'TENHPRRT01',
    label: 'Tenure: Private rented',
    type: 'value',
    chart: true,
  },
  {
    id: 47,
    name: 'TENHRLA01',
    label: 'Tenure: Rented from Council (Local Authority)',
    type: 'value',
    chart: true,
  },
  {
    id: 48,
    name: 'TENHROS01',
    label: 'Tenure: Other social rented',
    type: 'value',
    chart: true,
  },
  {
    id: 49,
    name: 'TENHSCR01',
    label: 'Tenure: Social rented',
    type: 'value',
    chart: true,
  },
  {
    id: 50,
    name: 'ETHAB_UK01',
    label: 'Ethnicity: Bangladeshi',
    type: 'value',
    chart: true,
  },
  {
    id: 51,
    name: 'ETHAI_UK01',
    label: 'Ethnicity: Indian',
    type: 'value',
    chart: true,
  },
  {
    id: 52,
    name: 'ETHALLUK01',
    label: 'Ethnicity: Ethnic Group Total',
    type: 'value',
    chart: true,
  },
  {
    id: 53,
    name: 'ETHAP_UK01',
    label: 'Ethnicity: Pakistani',
    type: 'value',
    chart: true,
  },
  {
    id: 54,
    name: 'ETHB_UK01',
    label: 'Ethnicity: Black',
    type: 'value',
    chart: true,
  },
  {
    id: 55,
    name: 'ETHBA_UK01',
    label: 'Ethnicity: African',
    type: 'value',
    chart: true,
  },
  {
    id: 56,
    name: 'ETHBC_UK01',
    label: 'Ethnicity: Caribbean',
    type: 'value',
    chart: true,
  },
  {
    id: 57,
    name: 'ETHBO_UK01',
    label: 'Ethnicity: Other Black',
    type: 'value',
    chart: true,
  },
  {
    id: 58,
    name: 'ETHCH_UK01',
    label: 'Ethnicity: Chinese',
    type: 'value',
    chart: true,
  },
  {
    id: 59,
    name: 'ETHM_UK01',
    label: 'Ethnicity: Mixed',
    type: 'value',
    chart: true,
  },
  {
    id: 60,
    name: 'ETHOTHUK01',
    label: 'Ethnicity: Other Ethnic Group',
    type: 'value',
    chart: true,
  },
  {
    id: 61,
    name: 'ETHW_UK01',
    label: 'Ethnicity: White',
    type: 'value',
    chart: true,
  },
  {
    id: 62,
    name: 'ETHAOTH_UK01',
    label: 'Ethnicity: Asian Other',
    type: 'value',
    chart: true,
  },
  {
    id: 63,
    name: 'ETHARA_UK01',
    label: 'Ethnicity: Arab',
    type: 'value',
    chart: true,
  },
  {
    id: 64,
    name: 'MTHOMR7401',
    label: 'Method of Travel To Work: Work from home',
    type: 'value',
    chart: true,
  },
  {
    id: 65,
    name: 'MTLTRR7401',
    label: 'Method of Travel To Work: Underground, metro, light rail, tram',
    type: 'value',
    chart: true,
  },
  {
    id: 66,
    name: 'MTTRNR7401',
    label: 'Method of Travel To Work: Train',
    type: 'value',
    chart: true,
  },
  {
    id: 67,
    name: 'MTBUSR7401',
    label: 'Method of Travel To Work: Bus, minibus or coach',
    type: 'value',
    chart: true,
  },
  {
    id: 68,
    name: 'MTCABR7401',
    label: 'Method of Travel To Work: Taxi or minicab',
    type: 'value',
    chart: true,
  },
  {
    id: 69,
    name: 'MTMBKR7401',
    label: 'Method of Travel To Work: Motorcycle, scooter or moped',
    type: 'value',
    chart: true,
  },
  {
    id: 70,
    name: 'MTDRVR7401',
    label: 'Method of Travel To Work: Driving a car or van',
    type: 'value',
    chart: true,
  },
  {
    id: 71,
    name: 'MTPASR7401',
    label: 'Method of Travel To Work: Passenger in a car or van',
    type: 'value',
    chart: true,
  },
  {
    id: 72,
    name: 'MTBICR7401',
    label: 'Method of Travel To Work: Bicycle',
    type: 'value',
    chart: true,
  },
  {
    id: 73,
    name: 'MTFTR7401',
    label: 'Method of Travel To Work: On foot',
    type: 'value',
    chart: true,
  },
  {
    id: 74,
    name: 'MTOTHR7401',
    label: 'Method of Travel To Work: Other',
    type: 'value',
    chart: true,
  },
  {
    id: 75,
    name: 'MTNOTR7401',
    label: 'Method of Travel To Work: Not working',
    type: 'value',
    chart: true,
  },
  {
    id: 76,
    name: 'ARR_BRNUK',
    label: 'Year Arrived in UK: Born in UK',
    type: 'value',
    chart: true,
  },
  {
    id: 77,
    name: 'ARR_PRE41',
    label: 'Year Arrived in UK: pre 1941',
    type: 'value',
    chart: true,
  },
  {
    id: 78,
    name: 'ARR_41_50',
    label: 'Year Arrived in UK: 1941-1950',
    type: 'value',
    chart: true,
  },
  {
    id: 79,
    name: 'ARR_51_60',
    label: 'Year Arrived in UK: 1951-1960',
    type: 'value',
    chart: true,
  },
  {
    id: 80,
    name: 'ARR_61_70',
    label: 'Year Arrived in UK: 1961-1970',
    type: 'value',
    chart: true,
  },
  {
    id: 81,
    name: 'ARR_71_80',
    label: 'Year Arrived in UK: 1971-1980',
    type: 'value',
    chart: true,
  },
  {
    id: 82,
    name: 'ARR_81_90',
    label: 'Year Arrived in UK: 1981-1990',
    type: 'value',
    chart: true,
  },
  {
    id: 83,
    name: 'ARR_91_00',
    label: 'Year Arrived in UK: 1991-2000',
    type: 'value',
    chart: true,
  },
  {
    id: 84,
    name: 'ARR_01_03',
    label: 'Year Arrived in UK: 2001-2003',
    type: 'value',
    chart: true,
  },
  {
    id: 85,
    name: 'ARR_04_06',
    label: 'Year Arrived in UK: 2004-2006',
    type: 'value',
    chart: true,
  },
  {
    id: 86,
    name: 'ARR_07_09',
    label: 'Year Arrived in UK: 2007-2009',
    type: 'value',
    chart: true,
  },
  {
    id: 87,
    name: 'ARR_10_11',
    label: 'Year Arrived in UK: 2010-2011',
    type: 'value',
    chart: true,
  },
  {
    id: 88,
    name: 'Social_AB',
    label: 'Social Grade: AB',
    type: 'value',
    chart: true,
  },
  {
    id: 89,
    name: 'Social_C1',
    label: 'Social Grade: C1',
    type: 'value',
    chart: true,
  },
  {
    id: 90,
    name: 'Social_C2',
    label: 'Social Grade: C2',
    type: 'value',
    chart: true,
  },
  {
    id: 91,
    name: 'Social_DE',
    label: 'Social Grade: DE',
    type: 'value',
    chart: true,
  },
  {
    id: 92,
    name: 'OCCU_All',
    label: 'Occupation: Occupation Total',
    type: 'value',
    chart: true,
  },
  {
    id: 93,
    name: 'OCCU_1',
    label: 'Occupation: Managers, directors and senior officials',
    type: 'value',
    chart: true,
  },
  {
    id: 94,
    name: 'OCCU_2',
    label: 'Occupation: Professional occupations',
    type: 'value',
    chart: true,
  },
  {
    id: 95,
    name: 'OCCU_3',
    label: 'Occupation: Associate professional and technical',
    type: 'value',
    chart: true,
  },
  {
    id: 96,
    name: 'OCCU_4',
    label: 'Occupation: Administrative and secretarial',
    type: 'value',
    chart: true,
  },
  {
    id: 97,
    name: 'OCCU_5',
    label: 'Occupation: Skilled trades occupations',
    type: 'value',
    chart: true,
  },
  {
    id: 98,
    name: 'OCCU_6',
    label: 'Occupation: Caring, leisure and other service',
    type: 'value',
    chart: true,
  },
  {
    id: 99,
    name: 'OCCU_7',
    label: 'Occupation: Sales and customer service',
    type: 'value',
    chart: true,
  },
  {
    id: 100,
    name: 'OCCU_8',
    label: 'Occupation: Process, plant and machine operatives',
    type: 'value',
    chart: true,
  },
  {
    id: 101,
    name: 'OCCU_9',
    label: 'Occupation: Elementary',
    type: 'value',
    chart: true,
  },
  {
    id: 102,
    name: 'AGE_0_to_4',
    label: 'Age: 0-4',
    type: 'value',
    chart: true,
  },
  {
    id: 103,
    name: 'AGE_5_to_7',
    label: 'Age: 5-7',
    type: 'value',
    chart: true,
  },
  {
    id: 104,
    name: 'AGE_8_to_9',
    label: 'Age: 8-9',
    type: 'value',
    chart: true,
  },
  {
    id: 105,
    name: 'AGE_10_to_14',
    label: 'Age: 10-14',
    type: 'value',
    chart: true,
  },
  {
    id: 106,
    name: 'AGE_15',
    label: 'Age: 15',
    type: 'value',
    chart: true,
  },
  {
    id: 107,
    name: 'AGE_16_to_17',
    label: 'Age: 16-17',
    type: 'value',
    chart: true,
  },
  {
    id: 108,
    name: 'AGE_18_to_19',
    label: 'Age: 18-19',
    type: 'value',
    chart: true,
  },
  {
    id: 109,
    name: 'AGE_20_to_24',
    label: 'Age: 20-24',
    type: 'value',
    chart: true,
  },
  {
    id: 110,
    name: 'AGE_25_to_29',
    label: 'Age: 25-29',
    type: 'value',
    chart: true,
  },
  {
    id: 111,
    name: 'AGE_30_to_44',
    label: 'Age: 30-44',
    type: 'value',
    chart: true,
  },
  {
    id: 112,
    name: 'AGE_45_to_59',
    label: 'Age: 45-59',
    type: 'value',
    chart: true,
  },
  {
    id: 113,
    name: 'AGE_60_to_64',
    label: 'Age: 60-64',
    type: 'value',
    chart: true,
  },
  {
    id: 114,
    name: 'AGE_65_to_74',
    label: 'Age: 65-74',
    type: 'value',
    chart: true,
  },
  {
    id: 115,
    name: 'AGE_75_to_84',
    label: 'Age: 75-84',
    type: 'value',
    chart: true,
  },
  {
    id: 116,
    name: 'AGE_85_to_89',
    label: 'Age: 85-89',
    type: 'value',
    chart: true,
  },
  {
    id: 117,
    name: 'AGE_90_and_over',
    label: 'Age: 90+',
    type: 'value',
    chart: true,
  },
  {
    id: 118,
    name: 'Males',
    label: 'Gender: Males',
    type: 'value',
    chart: true,
  },
  {
    id: 119,
    name: 'Females',
    label: 'Gender: Females',
    type: 'value',
    chart: true,
  },
  {
    id: 120,
    name: 'EMPACTRT01',
    label: 'Economic Activity: Economically active',
    type: 'value',
    chart: true,
  },
  {
    id: 121,
    name: 'EMPINART01',
    label: 'Economic Activity: Economically inactive',
    type: 'value',
    chart: true,
  },
  {
    id: 122,
    name: 'EMPPOPRT01',
    label: 'Economic Activity: Economically active/inactive total',
    type: 'value',
    chart: true,
  },
  {
    id: 123,
    name: 'SEC1_7401',
    label: 'Socio Economic Class: Higher managerial and professional',
    type: 'value',
    chart: true,
  },
  {
    id: 124,
    name: 'SEC2_7401',
    label: 'Socio Economic Class: Lower managerial and professional',
    type: 'value',
    chart: true,
  },
  {
    id: 125,
    name: 'SEC3_7401',
    label: 'Socio Economic Class: Intermediate',
    type: 'value',
    chart: true,
  },
  {
    id: 126,
    name: 'SEC4_7401',
    label: 'Socio Economic Class: Small employers and own account workers',
    type: 'value',
    chart: true,
  },
  {
    id: 127,
    name: 'SEC5_7401',
    label: 'Socio Economic Class: Lower supervisory and technical',
    type: 'value',
    chart: true,
  },
  {
    id: 128,
    name: 'SEC6_7401',
    label: 'Socio Economic Class: Semi-routine',
    type: 'value',
    chart: true,
  },
  {
    id: 129,
    name: 'SEC7_7401',
    label: 'Socio Economic Class: Routine',
    type: 'value',
    chart: true,
  },
  {
    id: 130,
    name: 'SEC8_7401',
    label: 'Socio Economic Class: Never worked and long-term unemployed',
    type: 'value',
    chart: true,
  },
  {
    id: 131,
    name: 'SECNC_7401',
    label: 'Socio Economic Class: NS-SeC not classified',
    type: 'value',
    chart: true,
  },
  {
    id: 132,
    name: 'SECPOP7401',
    label: 'Socio Economic Class: NS-SeC population',
    type: 'value',
    chart: true,
  },
  {
    id: 133,
    name: 'AlcoholicDrinks',
    label: 'Household Spend (total): Alcoholic Drinks',
    type: 'value',
    chart: true,
  },
  {
    id: 134,
    name: 'AlcoholOutOfHome',
    label: 'Household Spend (total): Alcohol Out-of-Home',
    type: 'value',
    chart: true,
  },
  {
    id: 135,
    name: 'BabyEquipment',
    label: 'Household Spend (total): Baby Equipment',
    type: 'value',
    chart: true,
  },
  {
    id: 136,
    name: 'BakedGoods',
    label: 'Household Spend (total): Baked Goods',
    type: 'value',
    chart: true,
  },
  {
    id: 137,
    name: 'Books',
    label: 'Household Spend (total): Books',
    type: 'value',
    chart: true,
  },
  {
    id: 138,
    name: 'Cards',
    label: 'Household Spend (total): Cards',
    type: 'value',
    chart: true,
  },
  {
    id: 139,
    name: 'Childrenswear',
    label: 'Household Spend (total): Childrenswear',
    type: 'value',
    chart: true,
  },
  {
    id: 140,
    name: 'Cinemas',
    label: 'Household Spend (total): Cinemas',
    type: 'value',
    chart: true,
  },
  {
    id: 141,
    name: 'CleaningAndPaperProducts',
    label: 'Household Spend (total): Cleaning And PaperProducts',
    type: 'value',
    chart: true,
  },
  {
    id: 142,
    name: 'ClubsDancesdiscoBingo',
    label: 'Household Spend (total): Clubs Dances Disco Bingo',
    type: 'value',
    chart: true,
  },
  {
    id: 143,
    name: 'ComparisonTotal',
    label: 'Household Spend (total): Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 144,
    name: 'Confectionery',
    label: 'Household Spend (total): Confectionery',
    type: 'value',
    chart: true,
  },
  {
    id: 145,
    name: 'EatingOut',
    label: 'Household Spend (total): Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 146,
    name: 'ElectricalGasAccessories',
    label: 'Household Spend (total): Electrical Gas Accessories',
    type: 'value',
    chart: true,
  },
  {
    id: 147,
    name: 'ElectricalGasAppliances',
    label: 'Household Spend (total): Electrical Gas Appliances',
    type: 'value',
    chart: true,
  },
  {
    id: 148,
    name: 'FashionAccessories',
    label: 'Household Spend (total): Fashion Accessories',
    type: 'value',
    chart: true,
  },
  {
    id: 149,
    name: 'FloorCoverings',
    label: 'Household Spend (total): Floor Coverings',
    type: 'value',
    chart: true,
  },
  {
    id: 150,
    name: 'FoodTotal',
    label: 'Household Spend (total): Convenience Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 151,
    name: 'Footwear',
    label: 'Household Spend (total): Footwear',
    type: 'value',
    chart: true,
  },
  {
    id: 152,
    name: 'FruitAndVegetables',
    label: 'Household Spend (total): Fruit And Vegetables',
    type: 'value',
    chart: true,
  },
  {
    id: 153,
    name: 'Furniture',
    label: 'Household Spend (total): Furniture',
    type: 'value',
    chart: true,
  },
  {
    id: 154,
    name: 'Haberdashery',
    label: 'Household Spend (total): Haberdashery',
    type: 'value',
    chart: true,
  },
  {
    id: 155,
    name: 'HealthBeauty',
    label: 'Household Spend (total): Health Beauty',
    type: 'value',
    chart: true,
  },
  {
    id: 156,
    name: 'HomeFurnishingsGifts',
    label: 'Household Spend (total): Home Furnishings Gifts',
    type: 'value',
    chart: true,
  },
  {
    id: 157,
    name: 'HomeImprovementsAndGardening',
    label: 'Household Spend (total): Home Improvements And Gardening',
    type: 'value',
    chart: true,
  },
  {
    id: 158,
    name: 'Jewellery',
    label: 'Household Spend (total): Jewellery',
    type: 'value',
    chart: true,
  },
  {
    id: 159,
    name: 'LeisureCentres',
    label: 'Household Spend (total): Leisure Centres',
    type: 'value',
    chart: true,
  },
  {
    id: 160,
    name: 'LiveEntertainment',
    label: 'Household Spend (total): Live Entertainment',
    type: 'value',
    chart: true,
  },
  {
    id: 161,
    name: 'MeatFishAndPoultry',
    label: 'Household Spend (total): MeatFish And Poultry',
    type: 'value',
    chart: true,
  },
  {
    id: 162,
    name: 'Menswear',
    label: 'Household Spend (total): Menswear',
    type: 'value',
    chart: true,
  },
  {
    id: 163,
    name: 'MobilePhones',
    label: 'Household Spend (total): Mobile Phones',
    type: 'value',
    chart: true,
  },
  {
    id: 164,
    name: 'MotorAccessories',
    label: 'Household Spend (total): Motor Accessories',
    type: 'value',
    chart: true,
  },
  {
    id: 165,
    name: 'MuseumZooThemeParkHouse',
    label: 'Household Spend (total): Museum Zoo Theme Park House',
    type: 'value',
    chart: true,
  },
  {
    id: 166,
    name: 'MusicAndVideo',
    label: 'Household Spend (total): Music And Video',
    type: 'value',
    chart: true,
  },
  {
    id: 167,
    name: 'NewspapersAndMagazines',
    label: 'Household Spend (total): Newspapers And Magazines',
    type: 'value',
    chart: true,
  },
  {
    id: 168,
    name: 'NonAlcoholicDrinks',
    label: 'Household Spend (total): Non Alcoholic Drinks',
    type: 'value',
    chart: true,
  },
  {
    id: 169,
    name: 'OTCPharmaceuticals',
    label: 'Household Spend (total): OTC Pharmaceuticals',
    type: 'value',
    chart: true,
  },
  {
    id: 170,
    name: 'OtherFoodStuffs',
    label: 'Household Spend (total): Other Food Stuffs',
    type: 'value',
    chart: true,
  },
  {
    id: 171,
    name: 'PetsAndPetfood',
    label: 'Household Spend (total): Pets And Petfood',
    type: 'value',
    chart: true,
  },
  {
    id: 172,
    name: 'Photography',
    label: 'Household Spend (total): Photography',
    type: 'value',
    chart: true,
  },
  {
    id: 173,
    name: 'Restaurantandcafemeals',
    label: 'Household Spend (total): Restaurant and Cafe meals',
    type: 'value',
    chart: true,
  },
  {
    id: 174,
    name: 'SoftFurnishingsLinens',
    label: 'Household Spend (total): Soft Furnishings Linens',
    type: 'value',
    chart: true,
  },
  {
    id: 175,
    name: 'SpectaclesAndLenses',
    label: 'Household Spend (total): Spectacles And Lenses',
    type: 'value',
    chart: true,
  },
  {
    id: 176,
    name: 'SportsAndCampingEquipment',
    label: 'Household Spend (total): Sports And Camping Equipment',
    type: 'value',
    chart: true,
  },
  {
    id: 177,
    name: 'Stationery',
    label: 'Household Spend (total): Stationery',
    type: 'value',
    chart: true,
  },
  {
    id: 178,
    name: 'Tobacco',
    label: 'Household Spend (total): Tobacco',
    type: 'value',
    chart: true,
  },
  {
    id: 179,
    name: 'Toys',
    label: 'Household Spend (total): Toys',
    type: 'value',
    chart: true,
  },
  {
    id: 180,
    name: 'TravelGoods',
    label: 'Household Spend (total): Travel Goods',
    type: 'value',
    chart: true,
  },
  {
    id: 181,
    name: 'Womenswear',
    label: 'Household Spend (total): Womenswear',
    type: 'value',
    chart: true,
  },
  {
    id: 182,
    name: 'ComparisonTotal_a1',
    label: 'Household Spend (By Lifepro): A1 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 183,
    name: 'ComparisonTotal_a2',
    label: 'Household Spend (By Lifepro): A2 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 184,
    name: 'ComparisonTotal_a3',
    label: 'Household Spend (By Lifepro): A3 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 185,
    name: 'ComparisonTotal_a4',
    label: 'Household Spend (By Lifepro): A4 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 186,
    name: 'ComparisonTotal_a5',
    label: 'Household Spend (By Lifepro): A5 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 187,
    name: 'ComparisonTotal_b1',
    label: 'Household Spend (By Lifepro): B1 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 188,
    name: 'ComparisonTotal_b2',
    label: 'Household Spend (By Lifepro): B2 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 189,
    name: 'ComparisonTotal_b3',
    label: 'Household Spend (By Lifepro): B3 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 190,
    name: 'ComparisonTotal_b4',
    label: 'Household Spend (By Lifepro): B4 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 191,
    name: 'ComparisonTotal_b5',
    label: 'Household Spend (By Lifepro): B5 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 192,
    name: 'ComparisonTotal_c1',
    label: 'Household Spend (By Lifepro): C1 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 193,
    name: 'ComparisonTotal_c2',
    label: 'Household Spend (By Lifepro): C2 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 194,
    name: 'ComparisonTotal_c3',
    label: 'Household Spend (By Lifepro): C3 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 195,
    name: 'ComparisonTotal_c4',
    label: 'Household Spend (By Lifepro): C4 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 196,
    name: 'ComparisonTotal_c5',
    label: 'Household Spend (By Lifepro): C5 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 197,
    name: 'ComparisonTotal_d1',
    label: 'Household Spend (By Lifepro): D1 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 198,
    name: 'ComparisonTotal_d2',
    label: 'Household Spend (By Lifepro): D2 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 199,
    name: 'ComparisonTotal_d3',
    label: 'Household Spend (By Lifepro): D3 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 200,
    name: 'ComparisonTotal_d4',
    label: 'Household Spend (By Lifepro): D4 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 201,
    name: 'ComparisonTotal_d5',
    label: 'Household Spend (By Lifepro): D5 Comparison Goods Total',
    type: 'value',
    chart: true,
  },
  {
    id: 202,
    name: 'FoodTotal_a1',
    label: 'Household Spend (By Lifepro): A1 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 203,
    name: 'FoodTotal_a2',
    label: 'Household Spend (By Lifepro): A2 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 204,
    name: 'FoodTotal_a3',
    label: 'Household Spend (By Lifepro): A3 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 205,
    name: 'FoodTotal_a4',
    label: 'Household Spend (By Lifepro): A4 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 206,
    name: 'FoodTotal_a5',
    label: 'Household Spend (By Lifepro): A5 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 207,
    name: 'FoodTotal_b1',
    label: 'Household Spend (By Lifepro): B1 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 208,
    name: 'FoodTotal_b2',
    label: 'Household Spend (By Lifepro): B2 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 209,
    name: 'FoodTotal_b3',
    label: 'Household Spend (By Lifepro): B3 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 210,
    name: 'FoodTotal_b4',
    label: 'Household Spend (By Lifepro): B4 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 211,
    name: 'FoodTotal_b5',
    label: 'Household Spend (By Lifepro): B5 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 212,
    name: 'FoodTotal_c1',
    label: 'Household Spend (By Lifepro): C1 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 213,
    name: 'FoodTotal_c2',
    label: 'Household Spend (By Lifepro): C2 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 214,
    name: 'FoodTotal_c3',
    label: 'Household Spend (By Lifepro): C3 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 215,
    name: 'FoodTotal_c4',
    label: 'Household Spend (By Lifepro): C4 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 216,
    name: 'FoodTotal_c5',
    label: 'Household Spend (By Lifepro): C5 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 217,
    name: 'FoodTotal_d1',
    label: 'Household Spend (By Lifepro): D1 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 218,
    name: 'FoodTotal_d2',
    label: 'Household Spend (By Lifepro): D2 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 219,
    name: 'FoodTotal_d3',
    label: 'Household Spend (By Lifepro): D3 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 220,
    name: 'FoodTotal_d4',
    label: 'Household Spend (By Lifepro): D4 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 221,
    name: 'FoodTotal_d5',
    label: 'Household Spend (By Lifepro): D5 Food Total',
    type: 'value',
    chart: true,
  },
  {
    id: 222,
    name: 'EatingOut_a1',
    label: 'Household Spend (By Lifepro): A1 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 223,
    name: 'EatingOut_a2',
    label: 'Household Spend (By Lifepro): A2 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 224,
    name: 'EatingOut_a3',
    label: 'Household Spend (By Lifepro): A3 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 225,
    name: 'EatingOut_a4',
    label: 'Household Spend (By Lifepro): A4 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 226,
    name: 'EatingOut_a5',
    label: 'Household Spend (By Lifepro): A5 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 227,
    name: 'EatingOut_b1',
    label: 'Household Spend (By Lifepro): B1 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 228,
    name: 'EatingOut_b2',
    label: 'Household Spend (By Lifepro): B2 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 229,
    name: 'EatingOut_b3',
    label: 'Household Spend (By Lifepro): B3 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 230,
    name: 'EatingOut_b4',
    label: 'Household Spend (By Lifepro): B4 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 231,
    name: 'EatingOut_b5',
    label: 'Household Spend (By Lifepro): B5 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 232,
    name: 'EatingOut_c1',
    label: 'Household Spend (By Lifepro): C1 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 233,
    name: 'EatingOut_c2',
    label: 'Household Spend (By Lifepro): C2 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 234,
    name: 'EatingOut_c3',
    label: 'Household Spend (By Lifepro): C3 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 235,
    name: 'EatingOut_c4',
    label: 'Household Spend (By Lifepro): C4 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 236,
    name: 'EatingOut_c5',
    label: 'Household Spend (By Lifepro): C5 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 237,
    name: 'EatingOut_d1',
    label: 'Household Spend (By Lifepro): D1 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 238,
    name: 'EatingOut_d2',
    label: 'Household Spend (By Lifepro): D2 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 239,
    name: 'EatingOut_d3',
    label: 'Household Spend (By Lifepro): D3 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 240,
    name: 'EatingOut_d4',
    label: 'Household Spend (By Lifepro): D4 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 241,
    name: 'EatingOut_d5',
    label: 'Household Spend (By Lifepro): D5 Eating Out',
    type: 'value',
    chart: true,
  },
  {
    id: 242,
    name: 'RestaurantsCafeAlcohol_a1',
    label: 'Household Spend (By Lifepro): A1 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 243,
    name: 'RestaurantsCafeAlcohol_a2',
    label: 'Household Spend (By Lifepro): A2 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 244,
    name: 'RestaurantsCafeAlcohol_a3',
    label: 'Household Spend (By Lifepro): A3 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 245,
    name: 'RestaurantsCafeAlcohol_a4',
    label: 'Household Spend (By Lifepro): A4 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 246,
    name: 'RestaurantsCafeAlcohol_a5',
    label: 'Household Spend (By Lifepro): A5 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 247,
    name: 'RestaurantsCafeAlcohol_b1',
    label: 'Household Spend (By Lifepro): B1 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 248,
    name: 'RestaurantsCafeAlcohol_b2',
    label: 'Household Spend (By Lifepro): B2 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 249,
    name: 'RestaurantsCafeAlcohol_b3',
    label: 'Household Spend (By Lifepro): B3 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 250,
    name: 'RestaurantsCafeAlcohol_b4',
    label: 'Household Spend (By Lifepro): B4 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 251,
    name: 'RestaurantsCafeAlcohol_b5',
    label: 'Household Spend (By Lifepro): B5 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 252,
    name: 'RestaurantsCafeAlcohol_c1',
    label: 'Household Spend (By Lifepro): C1 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 253,
    name: 'RestaurantsCafeAlcohol_c2',
    label: 'Household Spend (By Lifepro): C2 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 254,
    name: 'RestaurantsCafeAlcohol_c3',
    label: 'Household Spend (By Lifepro): C3 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 255,
    name: 'RestaurantsCafeAlcohol_c4',
    label: 'Household Spend (By Lifepro): C4 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 256,
    name: 'RestaurantsCafeAlcohol_c5',
    label: 'Household Spend (By Lifepro): C5 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 257,
    name: 'RestaurantsCafeAlcohol_d1',
    label: 'Household Spend (By Lifepro): D1 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 258,
    name: 'RestaurantsCafeAlcohol_d2',
    label: 'Household Spend (By Lifepro): D2 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 259,
    name: 'RestaurantsCafeAlcohol_d3',
    label: 'Household Spend (By Lifepro): D3 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 260,
    name: 'RestaurantsCafeAlcohol_d4',
    label: 'Household Spend (By Lifepro): D4 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 261,
    name: 'RestaurantsCafeAlcohol_d5',
    label: 'Household Spend (By Lifepro): D5 Restaurants, Cafe, Alcohol',
    type: 'value',
    chart: true,
  },
  {
    id: 262,
    name: 'Books_Cards_Stationery',
    label: 'Household Spend (Grouped): Books, Cards & Stationery',
    type: 'value',
    chart: true,
  },
  {
    id: 263,
    name: 'Fashion_Footwear_Jewellery',
    label: 'Household Spend (Grouped): Fashion, Footwear & Jewellery',
    type: 'value',
    chart: true,
  },
  {
    id: 264,
    name: 'Electrical_Goods_Mobile_Phones_Photography',
    label: 'Household Spend (Grouped): Electrical Goods, Mobile Phones & Photography',
    type: 'value',
    chart: true,
  },
  {
    id: 265,
    name: 'Home_Improvements_Gardening',
    label: 'Household Spend (Grouped): Home Improvements & Gardening',
    type: 'value',
    chart: true,
  },
  {
    id: 266,
    name: 'Floor_Coverings',
    label: 'Household Spend (Grouped): Floor Coverings (Total)',
    type: 'value',
    chart: true,
  },
  {
    id: 267,
    name: 'Home_Furnishings_Gifts',
    label: 'Household Spend (Grouped): Home Furnishings & Gifts',
    type: 'value',
    chart: true,
  },
  {
    id: 268,
    name: 'Soft_Furnishings_Linens',
    label: 'Household Spend (Grouped): Soft Furnishings & Linens',
    type: 'value',
    chart: true,
  },
  {
    id: 269,
    name: 'Health_Beauty_inc_OTC_Pharmacy',
    label: 'Household Spend (Grouped): Health & Beauty (inc OTC Pharmacy)',
    type: 'value',
    chart: true,
  },
  {
    id: 270,
    name: 'Music_DVDs_Games_Toys',
    label: 'Household Spend (Grouped): Music, DVDs, Games & Toys',
    type: 'value',
    chart: true,
  },
  {
    id: 271,
    name: 'Other_Spectacles_Lenses_Travel_Goods_Motor_Accessories',
    label: 'Household Spend (Grouped): Other (Spectacles & Lenses, Travel Goods, Motor Accessories)',
    type: 'value',
    chart: true,
  },
  {
    id: 272,
    name: 'Sports_And_Camping_Equipment',
    label: 'Household Spend (Grouped): Sports And Camping Equipment',
    type: 'value',
    chart: true,
  },
  {
    id: 273,
    name: 'Eating_Out_Restaurants_Cafes',
    label: 'Household Spend (Grouped): Eating Out, Restaurants & Cafes',
    type: 'value',
    chart: true,
  },
  {
    id: 274,
    name: 'Leisure_Centres_Attractions_live_Entertainment_etc',
    label: 'Household Spend (Grouped): Leisure Centres, Attractions & Live Entertainment etc',
    type: 'value',
    chart: true,
  },

  {
    id: 275,
    name: 'Students',
    label: 'Students',
    type: 'value',
    chart: true,
  },
]

// export const COLUMNS_BENCHMARK = {
//   168 : 'personicx_a1',
//   169 : 'personicx_a2',
//   170 : 'personicx_a3',
//   171 : 'personicx_a4',
//   172 : 'personicx_a5',
//   173 : 'personicx_b1',
//   174 : 'personicx_b2',
//   175 : 'personicx_b3',
//   176 : 'personicx_b4',
//   177 : 'personicx_b5',
//   178 : 'personicx_c1',
//   179 : 'personicx_c2',
//   180 : 'personicx_c3',
//   181 : 'personicx_c4',
//   182 : 'personicx_c5',
//   183 : 'personicx_d1',
//   184 : 'personicx_d2',
//   185 : 'personicx_d3',
//   186 : 'personicx_d4',
//   187 : 'personicx_d5',
//   226 : 'spend_eatingoutf',

//   238 : 'workforce_all_workfo',
//   251 : 'workforce_all_16_24',
//   252 : 'workforce_all_25_34',
// }

export const STATISTICS_WORKFORCE: ExtendedStatisticsKey[] = [
  {
    id: 0,
    name: 'all_workforce',
    label: 'Workforce Total',
    type: 'value',
    chart: true,
  },
  {
    id: 1,
    name: 'm_16_24',
    label: 'Male aged 16-24',
    type: 'value',
    chart: true,
  },
  {
    id: 2,
    name: 'm_25_34',
    label: 'Male aged 25-34',
    type: 'value',
    chart: true,
  },
  {
    id: 3,
    name: 'm_35_44',
    label: 'Male aged 35-44',
    type: 'value',
    chart: true,
  },
  {
    id: 4,
    name: 'm_45_54',
    label: 'Male aged 45-54',
    type: 'value',
    chart: true,
  },
  {
    id: 5,
    name: 'm_55_64',
    label: 'Male aged 55-65',
    type: 'value',
    chart: true,
  },
  {
    id: 6,
    name: 'm_65_74',
    label: 'Male aged 65-74',
    type: 'value',
    chart: true,
  },
  {
    id: 7,
    name: 'f_16_24',
    label: 'Female aged 16-24',
    type: 'value',
    chart: true,
  },
  {
    id: 8,
    name: 'f_25_34',
    label: 'Female aged 25-34',
    type: 'value',
    chart: true,
  },
  {
    id: 9,
    name: 'f_35_44',
    label: 'Female aged 35-44',
    type: 'value',
    chart: true,
  },
  {
    id: 10,
    name: 'f_45_54',
    label: 'Female aged 45-54',
    type: 'value',
    chart: true,
  },
  {
    id: 11,
    name: 'f_55_64',
    label: 'Female aged 55-65',
    type: 'value',
    chart: true,
  },
  {
    id: 12,
    name: 'f_65_74',
    label: 'Female aged 65-74',
    type: 'value',
    chart: true,
  },
  {
    id: 13,
    name: 'all_16_24',
    label: 'All aged 16-24',
    type: 'value',
    chart: true,
  },
  {
    id: 14,
    name: 'all_25_34',
    label: 'All aged 25-34',
    type: 'value',
    chart: true,
  },
  {
    id: 15,
    name: 'all_35_44',
    label: 'All aged 35-44',
    type: 'value',
    chart: true,
  },
  {
    id: 16,
    name: 'all_45_54',
    label: 'All aged 45-54',
    type: 'value',
    chart: true,
  },
  {
    id: 17,
    name: 'all_55_64',
    label: 'All aged 55-65',
    type: 'value',
    chart: true,
  },
  {
    id: 18,
    name: 'all_65_74',
    label: 'All aged 65-74',
    type: 'value',
    chart: true,
  },
  {
    id: 19,
    name: 'ab',
    label: 'AB',
    type: 'value',
    chart: true,
  },
  {
    id: 20,
    name: 'c1',
    label: 'C1',
    type: 'value',
    chart: true,
  },
  {
    id: 21,
    name: 'c2',
    label: 'C2',
    type: 'value',
    chart: true,
  },
  {
    id: 22,
    name: 'de',
    label: 'DE',
    type: 'value',
    chart: true,
  },
  {
    id: 23,
    name: 'ind_a',
    label: 'Agriculture, forestry and fishing',
    type: 'value',
    chart: true,
  },
  {
    id: 24,
    name: 'ind_b',
    label: 'Mining and quarrying',
    type: 'value',
    chart: true,
  },
  {
    id: 25,
    name: 'ind_c',
    label: 'Manufacturing',
    type: 'value',
    chart: true,
  },
  {
    id: 26,
    name: 'ind_d',
    label: 'Elec / Gas / Steam',
    type: 'value',
    chart: true,
  },
  {
    id: 27,
    name: 'ind_e',
    label: 'Water / Sewerage / Waste Mangt',
    type: 'value',
    chart: true,
  },
  {
    id: 28,
    name: 'ind_f',
    label: 'Construction',
    type: 'value',
    chart: true,
  },
  {
    id: 29,
    name: 'ind_g',
    label: 'Wholesale & Retail Trade, Repair of Motor Vehicles',
    type: 'value',
    chart: true,
  },
  {
    id: 30,
    name: 'ind_h',
    label: 'Transport and storage',
    type: 'value',
    chart: true,
  },
  {
    id: 31,
    name: 'ind_i',
    label: 'Accomodation & Food Services',
    type: 'value',
    chart: true,
  },
  {
    id: 32,
    name: 'ind_j',
    label: 'Information and communication',
    type: 'value',
    chart: true,
  },
  {
    id: 33,
    name: 'ind_k',
    label: 'Financial and insurance activities',
    type: 'value',
    chart: true,
  },
  {
    id: 34,
    name: 'ind_l',
    label: 'Real estate activities',
    type: 'value',
    chart: true,
  },
  {
    id: 35,
    name: 'ind_m',
    label: 'Professional, scientific and technical activities',
    type: 'value',
    chart: true,
  },
  {
    id: 36,
    name: 'ind_n',
    label: 'Administrative and support service activities',
    type: 'value',
    chart: true,
  },
  {
    id: 37,
    name: 'ind_o',
    label: 'Public administration and defence; compulsory social security',
    type: 'value',
    chart: true,
  },
  {
    id: 38,
    name: 'ind_p',
    label: 'Education',
    type: 'value',
    chart: true,
  },
  {
    id: 39,
    name: 'ind_q',
    label: 'Human health and social work activities',
    type: 'value',
    chart: true,
  },
  {
    id: 40,
    name: 'ind_rs',
    label: 'Arts, entertainment and recreation; other service activities',
    type: 'value',
    chart: true,
  },
  {
    id: 41,
    name: 'ind_t',
    label: 'Other 1',
    type: 'value',
    chart: true,
  },
  {
    id: 42,
    name: 'ind_u',
    label: 'Other 2',
    type: 'value',
    chart: true,
  },
  {
    id: 43,
    name: 'occ_1',
    label: 'Managers, directors and senior officials',
    type: 'value',
    chart: true,
  },
  {
    id: 44,
    name: 'occ_2',
    label: 'Professional',
    type: 'value',
    chart: true,
  },
  {
    id: 45,
    name: 'occ_3',
    label: 'Associate professional',
    type: 'value',
    chart: true,
  },
  {
    id: 46,
    name: 'occ_4',
    label: 'Admin and secretarial',
    type: 'value',
    chart: true,
  },
  {
    id: 47,
    name: 'occ_5',
    label: 'Skilled trades',
    type: 'value',
    chart: true,
  },
  {
    id: 48,
    name: 'occ_6',
    label: 'Caring, leisure and other service',
    type: 'value',
    chart: true,
  },
  {
    id: 49,
    name: 'occ_7',
    label: 'Sales and customer service',
    type: 'value',
    chart: true,
  },
  {
    id: 50,
    name: 'occ_8',
    label: 'Process, plant and machine operatives',
    type: 'value',
    chart: true,
  },
  {
    id: 51,
    name: 'occ_9',
    label: 'Elementary occupations',
    type: 'value',
    chart: true,
  },
]

const SELECTABLE_STATISTICS: any = {
  0: true,
  1: true,
}

export const STATISTICS_MAP = STATISTICS //.filter(statistic => SELECTABLE_STATISTICS[statistic.id])//.slice(0, 5)

const BENCHMARKS_CHILANGO_LIST: { [index: string]: boolean } = {
  A1_Households: true,
  A2_Households: true,
  A3_Households: true,
  A4_Households: true,
  A5_Households: true,
  B1_Households: true,
  B2_Households: true,
  B3_Households: true,
  B4_Households: true,
  B5_Households: true,
  C1_Households: true,
  C2_Households: true,
  C3_Households: true,
  C4_Households: true,
  C5_Households: true,
  D1_Households: true,
  D2_Households: true,
  D3_Households: true,
  D4_Households: true,
  D5_Households: true,

  EatingOut_a1: true,
  EatingOut_a2: true,
  EatingOut_a3: true,
  EatingOut_a5: true,
  EatingOut_c1: true,

  EatingOut: true,
  AGED162401: true,
  AGED254401: true,
  OCCU_All: true,

  Students: true,

  Population: true,
}

const BENCHMARKS_CHILANGO_WORKFORCE_LIST: { [index: string]: boolean } = {
  all_workforce: true,
  all_16_24: true,
  all_25_34: true,
  all_35_44: true,
  all_45_54: true,
  all_55_64: true,
  all_65_74: true,
}

const BENCHMARKS_COCODIMAMA_LIST: { [index: string]: boolean } = {
  A1_Households: true,
  A2_Households: true,
  A3_Households: true,
  A4_Households: true,
  A5_Households: true,
  B1_Households: true,
  B2_Households: true,
  B3_Households: true,
  B4_Households: true,
  B5_Households: true,
  C1_Households: true,
  C2_Households: true,
  C3_Households: true,
  C4_Households: true,
  C5_Households: true,
  D1_Households: true,
  D2_Households: true,
  D3_Households: true,
  D4_Households: true,
  D5_Households: true,

  AGED162401: true,
  AGED254401: true,
  OCCU_All: true,

  Population: true,

  AGE_16_to_17: true,
  AGE_18_to_19: true,
  AGE_20_to_24: true,
  AGE_25_to_29: true,
  AGE_30_to_44: true,

  Social_AB: true,
}

const BENCHMARKS_COCODIMAMA_WORKFORCE_LIST: { [index: string]: boolean } = {
  all_workforce: true,
  all_16_24: true,
  all_25_34: true,
  all_35_44: true,
  all_45_54: true,
  all_55_64: true,
  all_65_74: true,

  ab: true,
}

const BENCHMARKS_PLANET_ORGANIC_LIST: { [index: string]: boolean } = {
  A1_Households: true,
  B1_Households: true,
  C1_Households: true,

  Households: true,
  Population: true,
}

const BENCHMARKS_PLANET_ORGANIC_WORKFORCE_LIST: { [index: string]: boolean } = {
  all_workforce: true,
  all_25_34: true,
  all_35_44: true,
  ab: true,
}

export const STATISTICS_BENCHMARKS_CHILANGO = STATISTICS //.filter(item => BENCHMARKS_CHILANGO_LIST[item.name])
export const STATISTICS_BENCHMARKS_CHILANGO_WORKFORCE = STATISTICS_WORKFORCE //.filter(item => BENCHMARKS_CHILANGO_WORKFORCE_LIST[item.name])

export const STATISTICS_BENCHMARKS_COCODIMAMA = STATISTICS //.filter(item => BENCHMARKS_COCODIMAMA_LIST[item.name])
export const STATISTICS_BENCHMARKS_COCODIMAMA_WORKFORCE = STATISTICS_WORKFORCE //.filter(item => BENCHMARKS_COCODIMAMA_WORKFORCE_LIST[item.name])

export const STATISTICS_BENCHMARKS_PLANET_ORGANIC = STATISTICS //.filter(item => BENCHMARKS_PLANET_ORGANIC_LIST[item.name])
export const STATISTICS_BENCHMARKS_PLANET_ORGANIC_WORKFORCE = STATISTICS_WORKFORCE //.filter(item => BENCHMARKS_PLANET_ORGANIC_WORKFORCE_LIST[item.name])

export const STATISTICS_COMBINED_ID_TO_NAME = STATISTICS.concat(
  STATISTICS_FORECAST as any,
  STATISTICS_EXTENDED as any
).reduce((acc, cur) => {
  acc[cur.id] = cur.name
  return acc
}, {} as Record<number, string>)

export const STATISTICS_FORECASRT_AND_EXTENDED_ID_TO_NAME = []
  .concat(STATISTICS_FORECAST as any, STATISTICS_EXTENDED as any)
  .reduce((acc, cur) => {
    acc[cur.id] = cur.name
    return acc
  }, {} as Record<number, string>)

export const STATISTICS_ID_TO_NAME = STATISTICS.reduce((acc, cur) => {
  acc[cur.id] = cur.name
  return acc
}, {} as Record<number, string>)

export const STATISTICS_WITH_NONE = [
  {
    id: -1,
    name: 'none',
    label: 'None',
    chart: false,
    type: 'value',
  },
].concat(STATISTICS_MAP, STATISTICS_EXTENDED as any)

export const ALL_STATISTICS_NAME_MAP = []
  .concat(STATISTICS_MAP, STATISTICS_FORECAST, STATISTICS_EXTENDED)
  .reduce((acc, cur) => {
    acc[cur.name] = cur
    return acc
  }, {} as Record<string, ExtendedStatisticsKey>)

export const ALL_STATISTICS_WORKDOFCE_NAME_MAP = STATISTICS_WORKFORCE.reduce((acc, cur) => {
  acc[cur.name] = cur
  return acc
}, {} as Record<string, ExtendedStatisticsKey>)
