import { Injectable } from '@angular/core'
import {
  ReachableTile,
  LatLngProperties,
  StatisticsKey,
  LatLng,
  TravelTypeEdgeWeightOptions,
  ExtendedStatisticsKey,
  StatisticsSet,
  SRID,
  PolygonRequestOptions,
  BoundingBox,
} from '@targomo/core'
import { Indicators } from '@targomo/client'
import { ObservableList } from '@targomo/client'
import { DataSetLocation } from './index'
import { LocalMapModel } from './localMapModel'
import { PlacesModel, POLYGON_SOURCES_THRESHOLD } from './placesModel'
import {
  DataSetEndpoint,
  SettingsEndpoint,
  CategoryEndpoint,
  Category,
  PlanningApplicationDataSetEndpoint,
} from '../api/index'
import { Observable } from 'rxjs/Observable'
import { StatisticsModel } from './statisticsModel'
import { PlaceEndpoint, Place, canonicalPositions } from '../api/place'
import { StatisticsEndpoint } from '../api/statistics'
import { SettingsModel, DisplaySettings, TravelDisplayMode } from './settingsModel'
import { Subject } from 'rxjs/Subject'
import { ExtendedBehaviorSubject } from '../util/extendedBehaviorSubject'
import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/first'
import 'rxjs/add/operator/take'
import 'rxjs/add/operator/distinctUntilChanged'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/withLatestFrom'
import { MaxEdgeWeightOption } from '@targomo/client'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { FeatureModel } from './featureModel'
import { ZoneLayersModel } from './zoneLayersModel'
import { ZoneLayersEndpoint } from '../api/sectors'
import { DataLoadingModel } from './dataLoadingModel'
import { GeoRegionsEndpoint } from '../api/georegions'
import { UserEventLogEndpoint } from '../api/userEventLog'
import { Settings } from '../../common/models'
import { AbstractLocation } from './entities'
import { MaxiReportModel } from './maxi/maxiReportModel'
import { SourcesTitleModel } from './sourcesTitleModel'
import { MultigraphModel } from './multigraphModel'
import * as bbox from '@turf/bbox'
import { geometry } from '@targomo/core'
import { filter, take, timeout } from 'rxjs/operators'
import { CareHomeReportModel } from './careHome/careHomeModel'
import { combineLatest } from 'rxjs'
import { distinctUntilChanged } from 'rxjs-compat/operator/distinctUntilChanged'
import { CombinedSources } from './matchpoint/combinedSources'

export const SPEED_PENALTIES = {
  trafficJunctionPenalty: 4,
  trafficSignalPenalty: 7,
}

export const BIKE_WALK_SPEED = {
  bikeSpeed: SPEED_PENALTIES,
  walkSpeed: SPEED_PENALTIES,
}

export const BIKE_WALK_SPEED_PENALTIES = {
  bike: SPEED_PENALTIES,
  walk: SPEED_PENALTIES,
  transit: {},
  car: {},
}

@Injectable()
export class AppModel {
  public readonly mapModel: LocalMapModel
  public readonly endpointMetadata: Promise<any>
  public readonly places: PlacesModel
  public readonly statistics: StatisticsModel
  public readonly multigraph: MultigraphModel
  public readonly polygonLoaded: Subject<any> = new Subject() // Only used for request sequencing
  public readonly polygons: ExtendedBehaviorSubject<any> = new ExtendedBehaviorSubject(null) // Visible polygons
  public readonly polygonsBounds: ExtendedBehaviorSubject<BoundingBox> = new ExtendedBehaviorSubject(null) // Zoom on polygon

  public readonly displaySettings: ExtendedBehaviorSubject<DisplaySettings> // = new ExtendedBehaviorSubject<DisplaySettings>(new DisplaySettings())

  public readonly sessionLoaded = new Subject<any>()
  public readonly logoUpdated = new BehaviorSubject<number>(new Date().getTime())

  readonly maxiReportModel: MaxiReportModel
  readonly careHomeReportModel: CareHomeReportModel

  private _disableNextJump = false

  public readonly publicSettings: Promise<Settings>
  readonly titles: SourcesTitleModel

  private headbeatInitialized = false

  public readonly selectedItemsCount$: Observable<number>

  constructor(
    readonly settings: SettingsModel,
    readonly features: FeatureModel,
    private indicators: Indicators,
    private dataSetEndpoint: DataSetEndpoint,
    private placeEndpoint: PlaceEndpoint,
    readonly zoneLayersModel: ZoneLayersModel,
    readonly zoneLayersEndpoint: ZoneLayersEndpoint,
    readonly placesLoadingModel: DataLoadingModel,
    private statisticsEndpoint: StatisticsEndpoint,
    private geoRegionsEndpoint: GeoRegionsEndpoint,
    private userEventLogEndpoint: UserEventLogEndpoint,
    private settingsEndpoint: SettingsEndpoint,
    private planningEndpoint: PlanningApplicationDataSetEndpoint
  ) {
    this.displaySettings = this.settings.displaySettings
  }

  private initHearbeat() {
    if (this.headbeatInitialized) {
      return
    }

    this.headbeatInitialized = true
    let session: number = null
    const callback = async () => {
      let result = await this.userEventLogEndpoint.logHeartbeat(session)
      if (!session) {
        session = result
      }
      setTimeout(callback, 45000)
    }

    setTimeout(callback, 10000)
  }

  init() {
    this.settings.initialized.next(true)
    this.initHearbeat()
    ;(<any>this.publicSettings) = this.settingsEndpoint.getPublic()

    this.placesLoadingModel.init()
    this.placesLoadingModel.registerPostLoad((places) => {
      this.places.applyDeletedState(places)
    })

    const sources = new CombinedSources()

    const self: any = this
    self.mapModel = new LocalMapModel(this.indicators, sources)
    // this.displaySettings.getValue().statistic = STATISTICS[0]//{id: -1,  name: ''}
    // this.displaySettings.getValue().statistic = {id: -1,  name: ''}
    this.displaySettings.getValue().mapStyle = 'positron'
    // this.displaySettings.getValue().performanceMouseMoveOutsideAngular = true

    self.selectedItemsCount$ = combineLatest(this.zoneLayersModel.selectedZones, sources.observable)
      .map(([zones, sources]) => (zones ? zones.length : 0) + (sources ? sources.length : 0))
      .distinctUntilChanged()
      .shareReplay(1)

    self.places = new PlacesModel(
      this.placesLoadingModel,
      this.indicators,
      this.settings.travelOptionsUpdates,
      this.settings.intersectionModeUpdates,
      <any>this.polygonLoaded,
      this.dataSetEndpoint,
      this.placeEndpoint,
      this.planningEndpoint,
      sources,
      this.settings.showLabelsUpdates,
      this.settings.client,
      this.settings,
      this.zoneLayersModel,
      this.geoRegionsEndpoint,
      this.userEventLogEndpoint,
      self.selectedItemsCount$
    )

    self.statistics = new StatisticsModel(
      this.indicators,
      <any>this.places.sources.observable,
      this.settings.travelOptionsUpdates,
      this.settings.intersectionModeUpdates,
      this.statisticsEndpoint,
      this.settings,
      this,
      this.features,
      this.zoneLayersModel,
      this.zoneLayersEndpoint
    )
    self.maxiReportModel = new MaxiReportModel(
      this,
      this.zoneLayersModel,
      this.placeEndpoint,
      this.indicators,
      this.statisticsEndpoint
    )

    self.multigraph = new MultigraphModel(
      this.indicators,
      <any>this.places.sources.observable,
      this.settings.travelOptionsUpdates,
      this.settings.intersectionModeUpdates,
      this.statisticsEndpoint,
      this.settings,
      this,
      this.features,
      this.zoneLayersModel,
      this.zoneLayersEndpoint
    )
    self.careHomeReportModel = new CareHomeReportModel(
      this.indicators,
      this.settings,
      this.statistics,
      this.places,
      this.settingsEndpoint
    )

    // this.mapModel.travelOptions.getValue().requestTimeout = 90000

    self.endpointMetadata = this.settings.client.metadata()

    // this.displaySettings.subscribe(options => {
    //   const targetOptions = this.mapModel.options.getValue()

    //   for (let key in options) {
    //     (<any>targetOptions)[key] = (<any>options)[key]
    //   }

    //   const travelDisplayModeDetails = this.settings.getTravelDisplayModeDetails(options.travelDisplayMode)
    //   // if (!travelDisplayModeDetails.statistics) {
    //   //   targetOptions.statistic = {id: -1,  name: ''}
    //   // }

    //   this.mapModel.options.nextProperty('isochrones', travelDisplayModeDetails.polygons)
    //   this.mapModel.options.nextProperty('inverseTravel', travelDisplayModeDetails.inverted)
    //   // this.mapModel.options.nextProperty('statistic', travelDisplayModeDetails.statistics ? this.displaySettings.getValue().statistic : {id: -1,  name: ''})

    //   this.mapModel.options.next(targetOptions)
    // })

    // this.initPlaces()
    this.initPolygons()
    this.initMarkerStyle()
    // this.initSelected()
    this.initMarkerSize()

    self.titles = new SourcesTitleModel(this)
  }

  // initSettings() {
  //   return new SettingsModel(this.)
  // }

  initMarkerSize() {
    // Observable.combineLatest(this.displaySettings.pluck<any, string>('markerSizeProperty').distinctUntilChanged(), this.places.originalPlaces.value)
    // .subscribe(([property, places]) => {

    function propertySize(place: DataSetLocation, property: string) {
      const area = Math.min(50000, (<any>place)[property] || 0)

      let markerSize = 1
      if (area > 30000) {
        markerSize = 9
      } else if (area >= 20000) {
        markerSize = 4
      }

      return markerSize
    }

    Observable.combineLatest(this.places.originalPlaces.value).subscribe(([places]) => {
      places.forEach((place: DataSetLocation) => {
        place.properties['marker-size:netSalesArea'] = propertySize(place, 'netSalesArea')
        place.properties['marker-size:grossInternalArea'] = propertySize(place, 'grossInternalArea')
      })
    })

    Observable.combineLatest(this.places.customPlaces.observable).subscribe(([places]) => {
      places.forEach((place: DataSetLocation) => {
        place.properties['marker-size:netSalesArea'] = propertySize(place, 'netSalesArea')
        place.properties['marker-size:grossInternalArea'] = propertySize(place, 'grossInternalArea')
      })
    })

    this.settings.pointAndClickSourceUpdates.subscribe((place) => {
      if (place) {
        place.properties['marker-size:netSalesArea'] = propertySize(place, 'netSalesArea')
        place.properties['marker-size:grossInternalArea'] = propertySize(place, 'grossInternalArea')
      }
    })
  }

  initMarkerStyle() {
    // const style = Observable.combineLatest(
    //   this.places.uniqueColors,
    //   this.settings.markerStylePrintUpdates,
    // ).subscribe(([uniqueColors, forPrint]) => {
    //   if (uniqueColors.length) {
    //     const result = markersLayer(uniqueColors, forPrint)
    //     this.displaySettings.nextWithCurrent(options => {
    //       options.markerStyle = result.markerStyle
    //       options.sourceMarkerStyle = result.sourceMarkerStyle
    //       return options
    //     })
    //   }
    // })
  }

  initPolygons() {
    const callback = async (
      sources: Place[],
      travelOptions: TravelTypeEdgeWeightOptions & { transitFrameDate?: number; transitFrameTime?: number },
      edgeWeights: number[],
      displayMode: TravelDisplayMode,
      intersectionMode: string
    ) => {
      if (!sources || !sources.length) {
        return null
      }

      const options: any = {
        serializer: 'geojson',
        buffer: 0.0004,
        srid: SRID.SRID_4326,
        simplifyMeters: 2,
        intersectionMode,
      }

      const requestOptions: PolygonRequestOptions = {
        ...options,
        ...BIKE_WALK_SPEED,
        travelType: travelOptions.travelType,
        maxEdgeWeight: travelOptions.maxEdgeWeight,
        transitFrameDate: travelOptions.transitFrameDate,
        transitFrameTime: travelOptions.transitFrameTime,
        travelEdgeWeights:
          displayMode === TravelDisplayMode.ThematicPolygonsInverted
            ? [travelOptions.maxEdgeWeight]
            : edgeWeights.filter((value) => value <= travelOptions.maxEdgeWeight), //.map( col => col.value).filter(value => value <= travelOptions.maxEdgeWeight)
      }

      this.userEventLogEndpoint.travelTimesRun()

      // in this case get bounds from multigraph
      if (displayMode === TravelDisplayMode.ThematicNoPolygons && sources.length > POLYGON_SOURCES_THRESHOLD) {
        return null
      }

      // Over a certain size don't allow polygons (do multigraph instead)
      if (sources && sources.length > POLYGON_SOURCES_THRESHOLD) {
        return null
      }

      const result = await this.indicators.add(
        this.settings.client.polygons.fetch(canonicalPositions(sources), requestOptions)
      )
      return result
    }

    this.places.filteredPlaces.value
      .debounceTime(100)
      .withLatestFrom(
        this.places.sources.observable,
        this.settings.travelOptionsUpdates,
        this.settings.travelEdgeWeightsUpdates,
        this.features.travelDisplayModeUpdates,
        this.settings.intersectionModeUpdates
      )
      .subscribe(async ([places, sources, travelOptions, edgeWeights, displayMode, intersectionMode]) => {
        const result = await callback(sources, travelOptions, edgeWeights, displayMode, intersectionMode)
        this.polygonLoaded.next(result)
      })

    Observable.combineLatest(
      this.places.sources.observable,
      this.settings.travelOptionsUpdates,
      this.settings.intersectionModeUpdates
    )
      .debounceTime(20)
      .withLatestFrom(this.settings.travelEdgeWeightsUpdates, this.features.travelDisplayModeUpdates)
      .subscribe(async ([[sources, travelOptions, intersectionMode], edgeWeights, displayMode]) => {
        const noJump = this._disableNextJump
        this._disableNextJump = false

        // this.mapModel.polygons.next(null)
        const result = await callback(sources, travelOptions, edgeWeights, displayMode, intersectionMode)
        this.polygonLoaded.next(result)
        this.polygons.next(result)

        if (!noJump) {
          if (result) {
            const boundsRaw = bbox(<any>result)

            const bounds = {
              southWest: { lat: boundsRaw[1], lng: boundsRaw[0] },
              northEast: { lat: boundsRaw[3], lng: boundsRaw[2] },
            }
            this.polygonsBounds.next(bounds)
          } else if (sources && sources.length) {
            if (displayMode === TravelDisplayMode.ThematicNoPolygons) {
              const bounds = await this.multigraph.bounds$
                .pipe(
                  filter((b) => !!b),
                  timeout(60000),
                  take(1)
                )
                .toPromise()

              if (bounds) {
                this.polygonsBounds.next(bounds)
              } else {
                this.polygonsBounds.next(geometry.boundingBoxListWithinTravelOptions(sources, travelOptions))
              }
            }
          }
        }
      })

    this.features.travelDisplayModeUpdates
      .withLatestFrom(
        this.places.sources.observable,
        this.settings.travelOptionsUpdates,
        this.settings.travelEdgeWeightsUpdates,
        this.settings.intersectionModeUpdates
      )
      .subscribe(async ([displayMode, sources, travelOptions, edgeWeights, intersectionMode]) => {
        const result = await callback(sources, travelOptions, edgeWeights, displayMode, intersectionMode)
        this.polygonLoaded.next(result)
        this.polygons.next(result)
      })
  }

  disableNextJump() {
    this._disableNextJump = true
  }
}
