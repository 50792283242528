import { Component } from '@angular/core'
import { SubscriberComponent } from '@targomo/client'
import { runInThisContext } from 'vm'
import { LabelsConfig, LABELS_CONFIG_OPTIONS } from '../../../model/labelsConfig'
import { SettingsModel } from '../../../model/settingsModel'

@Component({
  selector: 'label-options-panel',
  templateUrl: './labelOptionsPanel.component.html',
  styleUrls: ['./labelOptionsPanel.component.less'],
})
export class LabelOptionsPanelComponent extends SubscriberComponent {
  readonly options = LABELS_CONFIG_OPTIONS
  value: Record<string, boolean> = {}
  example: string = ''

  constructor(readonly settings: SettingsModel) {
    super()

    this.watch(settings.labelsConfigUpdates, (value) => {
      this.value = (value || []).reduce((acc, cur) => {
        acc[cur] = true
        return acc
      }, {} as Record<string, boolean>)

      const config = new LabelsConfig(value)
      const fakePlace: any = {
        fascia: 'Aldi',
        sitename: 'Aldi',
        postcode: 'SK8 5JQ',
        grossInternalArea: 37213,
        netSalesArea: 27213,
        defaultName: 'Aldi',
      }

      config.updatePlaces([fakePlace])
      this.example = fakePlace.name
    })
  }

  toggle(key: string) {
    this.value[key] = !this.value[key]
    this.changeLabelsConfig(Object.keys(this.value).filter((key) => this.value[key]))
  }

  changeLabelsConfig(value: string[]) {
    this.settings.displaySettings.nextProperty('labelsConfig', value)
  }
}
