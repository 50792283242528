import { Place } from '../api/place'
import { DecimalPipe } from '@angular/common'

let NUMBER_PIPE = new DecimalPipe('en')

function formatNumber(value: any) {
  if (isNaN(value)) {
    return ''
  }

  return NUMBER_PIPE.transform(+value, '1.0-0')
}

function formatString(value: any) {
  return value
}

export const LABELS_CONFIG_OPTIONS = [
  { format: formatString, suffix: '', key: 'fascia', label: 'Fascia' },
  { format: formatString, suffix: '', key: 'sitename', label: 'Site Name' },
  { format: formatString, suffix: '', key: 'town', label: 'Town' },
  { format: formatString, suffix: '', key: 'postcode', label: 'Postcode' },
  { format: formatNumber, suffix: ' GIA', key: 'grossInternalArea', label: 'Gross Area' },
  { format: formatNumber, suffix: ' NSA', key: 'netSalesArea', label: 'Net Sales Area' },
  { format: formatString, suffix: '', key: 'defaultName', label: 'Default' },
]

const LABELS_CONFIG_OPTIONS_MAP = LABELS_CONFIG_OPTIONS.reduce((acc, cur) => {
  acc[cur.key] = cur
  return acc
}, {} as { [index: string]: { key: string; suffix: string; label: string; format: (value: any) => string } })

export class LabelsConfig {
  private valuesMap: { [key: string]: boolean }

  constructor(public readonly values: string[]) {
    this.valuesMap = {}

    if (values) {
      this.valuesMap = values.reduce((acc, cur) => {
        acc[cur] = true
        return acc
      }, this.valuesMap)
    } else {
      this.values = []
    }
  }

  contains(key: string) {
    return this.valuesMap[key]
  }

  updatePlaces(places: Place[]) {
    if (places) {
      // Give custom places a defaultName
      places.forEach((place) => {
        if (place.id < 0 && !place.defaultName) {
          place.defaultName = place.name || place.fascia
        }
      })

      const selectedValues = this.values && this.values.length ? this.values : ['defaultName']
      const values = LABELS_CONFIG_OPTIONS.filter((item) => selectedValues.indexOf(item.key) > -1).map(
        (item) => item.key
      )

      places.forEach((place: any) => {
        place.name = values
          .map((key) => {
            const item = LABELS_CONFIG_OPTIONS_MAP[key]
            if (place[item.key] != null) {
              return item.format(place[item.key]) + item.suffix
            } else {
              return null
            }
          })
          .filter((item) => !!item)
          .join(', ')
      })
    }
  }

  toJSON() {
    return this.values
  }
}
