import {
  CompositeLayer,
  DefaultMapLayer,
  DefaultMapLayerOptions,
  EMPTY_GEOJSON_DATA,
  FeatureInteractionLayer,
  FilterMapLayerOptions,
  GeojsonMapSource,
  getBasePath,
  MapCellEvent,
  MapLayerPosition,
  MarkerMapSource,
  TileMapSource,
} from '@targomo/client'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Observable } from 'rxjs/Observable'
import { FeatureModel } from '../../model/featureModel'
import { ZoneLayersModel } from '../../model/zoneLayersModel'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

class DefaultFilterLayer extends DefaultMapLayer {
  private filter: any[] = null

  setFilter(filter: any[]) {
    this.filter = filter
    this.update((map) => {
      map.setFilter(this.id, filter)
    })
  }

  get(): Partial<any> {
    const result = super.get()

    if (this.filter) {
      result.filter = this.filter
    }

    return result
  }
}

export class ZoneLayerComposite extends CompositeLayer {
  private hoverLayer: DefaultMapLayer
  private sectorsLayer: DefaultMapLayer
  private sectorsLayerHidden: DefaultMapLayer
  private availableLayer: DefaultMapLayer
  private availableLayerLines: DefaultMapLayer
  private selectedEditLayer: DefaultFilterLayer
  private selectedLayer: DefaultMapLayer
  private labelsLayer: DefaultMapLayer
  private labelsAvailableLayer: DefaultMapLayer
  private _visible = false
  private _visibleObservable = new BehaviorSubject<boolean>(false)

  private selectedState = new BehaviorSubject<{ [index: number]: any }>({})

  readonly events: {
    readonly click: Observable<MapCellEvent>
    readonly hover: Observable<MapCellEvent>
  }

  constructor(
    map: CustomMapboxComponent,
    private labelsVisible: Observable<boolean>,
    private selectedZoneLayer: Observable<number>,
    private sectorsModel: ZoneLayersModel,
    private activeField: string,
    private featureModel: FeatureModel,
    private tileLayersVisible: Observable<boolean>
  ) {
    super(map as any)

    this.init()
    this.initLabels()
    this.events = this.initEvents()
    this.initSelectDeselect()

    this.setVisible(this._visible)
  }

  private init() {
    // const sourceMapUrl = new BehaviorSubject<string>(getBasePath() + 'api/zoneLayers/tiles/{z}/{x}/{y}.mvt')
    const sourceMapUrl = this.selectedZoneLayer.map((id) =>
      id == null ? null : getBasePath() + 'api/zoneLayers/tiles/' + this.activeField + '/' + id + '/{z}/{x}/{y}.mvt'
    )
    const source = new TileMapSource(<any>sourceMapUrl)

    const observableStyle = <T>(input: T) => {
      return this.selectedZoneLayer.map((id) => {
        if (id == null) {
          const result: any = JSON.parse(JSON.stringify(input))
          delete result.sourceLayer
          return result
        } else {
          return input
        }
      })
    }

    const optionsAvailableLines: DefaultMapLayerOptions = {
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#000000',
        'line-width': 2,
        'line-opacity': 0.8,
      },
    }

    const options: Observable<DefaultMapLayerOptions> = observableStyle({
      sourceLayer: '0',
      ...optionsAvailableLines,
    })

    const optionsHidden: Observable<DefaultMapLayerOptions> = observableStyle({
      type: 'fill',
      sourceLayer: '0',
      paint: {
        'fill-color': 'rgba(0, 0, 0, 0)',
        'fill-opacity': 1,
      },
    })

    // const layerOptions  = new BehaviorSubject<DefaultMapLayerOptions>(options)
    // const layerOptionsHidden  = new BehaviorSubject<DefaultMapLayerOptions>(optionsHidden)
    this.sectorsLayer = new DefaultMapLayer(this.map, source, options).setPosition(MapLayerPosition.BELOW_MARKERS)
    this.sectorsLayerHidden = new DefaultMapLayer(this.map, source, optionsHidden).setPosition(
      MapLayerPosition.BELOW_MARKERS
    )

    const optionsAvailable: DefaultMapLayerOptions = {
      type: 'fill',
      paint: {
        'fill-color': {
          property: 'color',
          type: 'identity',
        },
        'fill-opacity': 0.4,
      },
    }

    const availableSource = new GeojsonMapSource(this.sectorsModel.layerGeometries)

    const availableLayerOptions = new BehaviorSubject<DefaultMapLayerOptions>(optionsAvailable)
    this.availableLayer = new DefaultMapLayer(
      this.map,
      availableSource,
      <any>availableLayerOptions.asObservable()
    ).setPosition(MapLayerPosition.BELOW_MARKERS)

    const availableLayerLinesOptions = new BehaviorSubject<DefaultMapLayerOptions>(optionsAvailableLines)
    this.availableLayerLines = new DefaultMapLayer(
      this.map,
      availableSource,
      <any>availableLayerLinesOptions.asObservable()
    ).setPosition(MapLayerPosition.BELOW_MARKERS)

    this.watch(
      Observable.combineLatest(this._visibleObservable, this.featureModel.zonesLayerVisibleUpdates),
      ([visibleLayer, zoneVisible]) => {
        this.availableLayer.setVisible(visibleLayer)
        this.availableLayerLines.setVisible(visibleLayer && !zoneVisible)
      }
    )

    const filterOptions: Observable<FilterMapLayerOptions> = observableStyle({
      sourceLayer: '0',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(255, 255, 0, 0.7)',
        'fill-opacity': 1,
      },
    })

    this.selectedEditLayer = new DefaultFilterLayer(this.map, source, filterOptions).setPosition(
      MapLayerPosition.BELOW_MARKERS
    )
    this.selectedEditLayer.setFilter(['in', 'id'])

    this.watch(this.sectorsModel.visibleLayerMapFeatures, (selected) => {
      if (!selected) {
        this.selectedEditLayer.setFilter(['in', 'id'])
        return
      }

      // const list: any[] = ['in', 'id'].concat(Object.keys(selected).map(id => <any>+id))
      const list: any[] = ['in', 'id'].concat(selected.map((id) => <any>+id))

      this.selectedEditLayer.setFilter(list)
    })

    const selectedOptions = {
      type: 'fill',
      paint: {
        'fill-color': 'rgba(255, 255, 0, 0.7)',
        'fill-opacity': 1,
      },
    }

    const selecedSource = new GeojsonMapSource(this.sectorsModel.selectedGeometries)
    this.selectedLayer = new DefaultMapLayer(this.map, selecedSource, selectedOptions).setPosition(
      MapLayerPosition.BELOW_MARKERS
    )

    this.watch(
      Observable.combineLatest(
        this._visibleObservable,
        this.featureModel.zonesLayerVisibleUpdates,
        this.tileLayersVisible
      ),
      ([visible, zoneVisible, tileLayersVisible]) => {
        const value = visible && zoneVisible && tileLayersVisible
        this.sectorsLayer.setVisible(value)
        this.sectorsLayerHidden.setVisible(value)
        this.selectedEditLayer.setVisible(visible)
      }
    )
  }

  private initLabels() {
    // const sourceMapUrl = new BehaviorSubject<string>(getBasePath() + 'api/zoneLayers/labels/{z}/{x}/{y}.mvt')
    const sourceMapUrl = this.selectedZoneLayer.map(
      (id) => getBasePath() + 'api/zoneLayers/labels/' + this.activeField + '/' + id + '/{z}/{x}/{y}.mvt'
    )
    const source = new TileMapSource(<any>sourceMapUrl)

    const optionsAvailable = {
      // 'source-layer': '0',
      type: 'symbol',
      minzoom: 5,
      maxzoom: 24,
      // filter: this.options.filter || ['all'],
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-allow-overlap': false,
        'symbol-avoid-edges': false,
        'text-size': 14,
        'text-anchor': 'center',
        'text-offset': [0, 0],
        'text-font': ['Open Sans Bold'],
        visibility: 'none',
      },
      paint: {
        'text-color': '#444444',
        'text-halo-color': '#cccccc',
        'text-halo-width': 0.85,
      },
    }

    const options = {
      'source-layer': '0',
      ...optionsAvailable,
    }

    const layerOptions = new BehaviorSubject<any>(options)
    const layer = (this.labelsLayer = new DefaultMapLayer(this.map, <any>source, layerOptions).setPosition(
      MapLayerPosition.MARKERS_GLOW
    ))

    const layerAvailableOptions = new BehaviorSubject<any>(optionsAvailable)
    this.labelsAvailableLayer = new DefaultMapLayer(
      this.map,
      new MarkerMapSource(this.sectorsModel.userZoneLabels),
      layerAvailableOptions
    ).setPosition(MapLayerPosition.MARKERS_GLOW)

    setTimeout(() => {
      layer.setVisible(false)
      this.labelsAvailableLayer.setVisible(false)

      this.watch(
        Observable.combineLatest(
          this._visibleObservable,
          this.labelsVisible,
          this.featureModel.zonesLayerVisibleUpdates,
          this.tileLayersVisible
        ),
        ([visibleLayer, visibleLabels, zoneVisible, tileLayersVisible]) => {
          layer.setVisible(visibleLabels && visibleLayer && zoneVisible && tileLayersVisible)
        }
      )

      this.watch(
        Observable.combineLatest(
          this._visibleObservable,
          this.labelsVisible,
          this.featureModel.zonesLayerVisibleUpdates
        ),
        ([visibleLayer, visibleLabels, zoneVisible]) => {
          this.labelsAvailableLayer.setVisible(visibleLabels && visibleLayer && !zoneVisible)
        }
      )
    })
  }

  initEvents() {
    let visible: boolean = false

    const interaction = new FeatureInteractionLayer(this.sectorsLayerHidden)
    const showCellHover = Observable.from([true])

    const hoverSource = new GeojsonMapSource(
      Observable.combineLatest(interaction.events.hover, showCellHover)
        .map(([event, showHover]) => {
          if (!showHover || !event.feature) {
            return EMPTY_GEOJSON_DATA
          } else {
            return event.feature
          }
        })
        .distinctUntilChanged()
        .debounceTime(10)
    )

    let hoverLayerConfig = {
      type: 'fill',
      paint: {
        'fill-opacity': 1,
        'fill-color': 'rgba(255, 255, 0, 0.4)',
        'fill-outline-color': 'rgba(255, 255, 0, 1)',
      },
    }

    const hoverLayer = (this.hoverLayer = new DefaultMapLayer(this.map, hoverSource, hoverLayerConfig))

    this.watch(
      Observable.combineLatest(this._visibleObservable, this.sectorsModel.editingUpdates),
      ([visible, editing]) => {
        this.hoverLayer.setVisible(visible && editing)
      }
    )

    return interaction.events
  }

  initSelectDeselect() {
    this.watch(this.events.click, (event) => {
      if (event.feature && event.feature.properties && event.feature.properties.id != null) {
        const id = event.feature.properties.id
        this.sectorsModel.toggleSectorFeature(id)
      }
    })

    // this.watch(this.events.click, event => {
    //   if (event.feature && event.feature.properties && event.feature.properties.id != null) {
    //     const id = event.feature.properties.id
    //     if (selected[id]) {
    //       delete selected[id]
    //     } else {
    //       selected[id] = event.feature
    //     }
    //   }

    //   this.selectedState.next(selected)
    // })
  }

  setVisible(value: boolean): this {
    this._visible = value
    // this.hoverLayer.setVisible(value)
    // this.sectorsLayer.setVisible(value)
    // this.sectorsLayerHidden.setVisible(value)
    // this.selectedLayer.setVisible(value)
    // this.labelsLayer.setVisible(value)

    this._visibleObservable.next(value)

    return this
  }
  isVisible(): boolean {
    return this._visible
  }
}
