import {ReportModel} from './exportPdfReport';
import {PDFHelper, tableOptions} from './helper';
import {Group} from '../maxi/competitionMaxiReport';

export function competitionPopulationRatios(reportModel: ReportModel, groups: Group[], helper: PDFHelper) {
  const rows: any[] = []

  reportModel.forEach((report, i) => {
    const row: any[] = []

    const population = report.statistics.census.population2011.population.total

    var nsa = 0
    groups.forEach(group => {
      if (group && group.minutes <= report.minutes && isFinite(group.totals.netSalesArea)) {
        nsa += group.totals.netSalesArea
      }
    })

    row.push(report.nameFullFromTo)
    // row.push('0-' + report.nameFull)
    row.push(helper.formatCell(population) || 0)
    row.push(helper.formatCell(nsa) || 0)
    row.push(helper.formatCell((nsa / population) || 0, 1) || 0)
    row.push(helper.formatCell((population / nsa) || 0, 1) || 0)

    rows.push(row)
  })

  const head = ['', 'Cumulative Population', 'Cumulative Competing NSA (sq ft)', 'Competing NSA / Capita *', 'Population / Competing NSA **'].map(text => {
    return {
      text: text,
      style: 'defaultCell',
      bold: true
    }
  })

  const options: any = tableOptions(helper, reportModel, 2)
  options.border = '#bfbfbf'
  options.layout.vLineWidth = () => 0

  return helper.table(head, rows, 'defaultTable', options).concat([{
    stack: [
      '* A low Competing NSA / Capita ratio is better than a high ratio',
      '** A high Population / Competing NSA ratio is better than a low ratio'
    ], style: 'footnote'}])
}

