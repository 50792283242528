import { AbstractSubscriber, Indicators, ObservableList, RANDOM_COLORS } from '@targomo/client'
import { object as objects } from '@targomo/common'
import {
  BoundingBox,
  geometry as geometries,
  geometry,
  LatLng,
  LatLngId,
  LatLngProperties,
  TargomoClient,
  TimeRequestOptions,
  TravelTypeEdgeWeightOptions,
} from '@targomo/core'
import turfDistance from '@turf/distance'
import turfInside from '@turf/inside'
import * as scales from 'd3-scale'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Observable } from 'rxjs/Observable'
import {
  combineLatest,
  debounceTime,
  delay,
  map,
  shareReplay,
  take,
  withLatestFrom,
  distinctUntilChanged,
} from 'rxjs/operators'
import { PlanningApplication } from '../../common/models'
import { Category, CUSTOM_CATEGORIES, nextCategoryId } from '../api/category'
import { DataSet, DataSetEndpoint } from '../api/dataSet'
import { GeoRegionsEndpoint } from '../api/georegions'
import { canonicalPositions, PlaceEndpoint, Place } from '../api/place'
import { PlanningApplicationDataSetEndpoint } from '../api/planningApplicationDataSet'
import { UserEventLogEndpoint } from '../api/userEventLog'
import { SearchResult } from '../main/components/geosearch/geosearch.component.type'
import { dateQuarters } from '../model/dateQuarter'
import { AsyncObservable } from '../util/asyncObservable'
import { ExtendedBehaviorSubject } from '../util/extendedBehaviorSubject'
import { Filter } from '../util/types'
import { CentrePointCategorize, DataLoadingModel } from './dataLoadingModel'
import { DataSetFilter } from './dataSetFilter'
import { AbstractLocation, LatLngIdTravelTimeDistance } from './entities'
import { FasciasSelectionModel } from './fasciasSelectionModel'
import { DataSetLocation, PlanningLocation } from './index'
import { LabelsConfig } from './labelsConfig'
import { LocationsPresentModel } from './locationsPresentModel'
import { OperatorsWithinModel } from './operatorsWithinModel'
import { matchPlaceWithSerialized, SerializedPlace, serializePlace } from './savedSessionModel'
import { LocalreachabilityClient, SettingsModel } from './settingsModel'
import { ZoneLayersModel } from './zoneLayersModel'
import { CombinedSources } from './matchpoint/combinedSources'
import { Subject } from 'rxjs'

export const COMPETITION_THRESHOLD_COALESCE_LOCATIONS = 100
export const COMPETITION_THRESHOLD_COALESCE_SOURCES = 50

// Above this limit no polygon requests, automatically use multigraph
export const POLYGON_SOURCES_THRESHOLD = 250
export const INTERSECTION_THRESHOLD = 250

// Above this limit no benchmak reports
export const BENCHMARK_SOURCES_THRESHOLD = 50
export const COMPARISON_SOURCES_THRESHOLD = 50

let PLANNING_CATEGORY: Category = objects.assign(new Category(), {
  id: 0,
  name: 'Planning Applications',
  color: '#3887BE',
  markerStyleCategory: '#3887BE',
  visible: true,
})

const CENTREPOINT_FILTER_CODES: Record<string, string> = {
  OTHER: 'OT',
  'OUT OF TOWN': 'OOT',
  'OUTLET CENTRE': 'OC',
  SERVICES: 'SE',
  'SHOPPING CENTRE': 'SC',
  'TOWN CENTRE SHOPPING CENTRE': 'SC',
  // SOLUS: 'SOL',
  'SUBURBAN CENTRE': 'Suburban',
  'TOWN CENTRE': 'TC',
  'TRANSPORT HUB': 'TH',

  SOLUS: 'OOT',
  'INDUSTRIAL / TRADE': 'OOT',
  'LEISURE PARK': 'OOT',
  'RETAIL / SHOPPING PARK': 'OOT',
}

// const CENTREPOINT_FILTER_CODES: Record<string, string> = {
//   'TOWN CENTRE': 'HS',
//   'SUBURBAN CENTRE': 'HS',

//   'SHOPPING CENTRE': 'SC',

//   SOLUS: 'OOT',
//   'INDUSTRIAL / TRADE': 'OOT',
//   'LEISURE PARK': 'OOT',
//   'RETAIL / SHOPPING PARK': 'OOT',
// }

class Counter {
  allVisible = true
  allHidden = true

  private visible: Record<string, boolean> = {}
  private hidden: Record<string, boolean> = {}

  add(id: string, visible: boolean) {
    if (visible) {
      this.allHidden = false
      this.visible[id] = true
    } else {
      this.allVisible = false
      this.hidden[id] = true
    }
  }

  isAll() {
    return this.allHidden || this.allVisible
  }

  toJSON() {
    if (this.allVisible) {
      return true
    } else if (this.allHidden) {
      return false
    } else {
      const visibleKeys = Object.keys(this.visible)
      const hiddenKeys = Object.keys(this.hidden)

      if (JSON.stringify(visibleKeys).length < JSON.stringify(hiddenKeys).length) {
        return { '+': visibleKeys }
      } else {
        return { '-': hiddenKeys }
      }
    }
  }
}

export type CategoryMap = { [id: string]: Category }

export type CompactLayersState = Record<
  string,
  | true
  | Record<
      string,
      | true
      | {
          '-'?: string[]
          '|'?: string[]
        }
    >
>

// let categoryIndex = -1
// let dataSetIndex = -1
// let placeIndex = -1

export class PlacesModel extends AbstractSubscriber {
  readonly generalPropertyUpdate$ = new Subject<void>()

  private dataSetFiltersUpdates = new BehaviorSubject<boolean>(null)

  public readonly categorySelectUpdated = new BehaviorSubject<any>(null)
  public readonly categoryPinned = new ObservableList<string>()

  public readonly categorySearchText = new BehaviorSubject<string>('')
  public readonly categorySearchUpdated: Observable<[string, boolean]>

  public readonly customProfiles = new ObservableList<DataSet>()
  public readonly dataSetsUnavailable = new ExtendedBehaviorSubject<DataSet[]>([])
  public readonly dataSets = new ExtendedBehaviorSubject<DataSet[]>([])
  public readonly uniqueColors = new BehaviorSubject<string[]>([])
  public readonly filters = new ObservableList<Filter<AbstractLocation>>()
  public readonly customPlaces = new ObservableList<AbstractLocation>()
  public readonly labelFascia = new ObservableList<string>()
  public readonly labelRoadLink = new ObservableList<number>()
  public readonly hoverPlace = new BehaviorSubject<AbstractLocation>(null)
  public readonly selectedPlace = new BehaviorSubject<AbstractLocation>(null)

  // Note: add some comment what this is
  public readonly selectedPlacePlanningRisk = new BehaviorSubject<AbstractLocation>(null)

  public readonly originalPlaces = new AsyncObservable<AbstractLocation[]>(this.initPlaces(), [])
  public readonly places = new AsyncObservable<AbstractLocation[]>(<any>this.initCombinedPlaces(), [])
  public readonly temporaryLocation = new BehaviorSubject<SearchResult>(null)

  public readonly hoverPlanningPlace = new BehaviorSubject<PlanningApplication>(null)
  public readonly selectedPlanningPlace = new BehaviorSubject<{
    type: string
    location: AbstractLocation | PlanningApplication
  }>(null) // this could have been better....
  public readonly planningApplicationsPlaces = new BehaviorSubject<LatLngProperties[]>(null)

  private unavailableDataSets: Observable<DataSet[]>
  // private categoriesMap = new AsyncObservable<CategoryMap>(this.initCategoriesMap(), {})

  public readonly filteredPlacesRaw = new AsyncObservable<AbstractLocation[]>(<any>this.initFilteredPlacesRaw(), [])
  public readonly filteredPlaces = new AsyncObservable<AbstractLocation[]>(<any>this.initFilteredPlaces(), [])

  public readonly filteredPlacesDeleted = new AsyncObservable<AbstractLocation[]>(
    <any>this.initFilteredPlacesDeleted(),
    []
  )

  public readonly reachablePlacesTimesDistance = new AsyncObservable<AbstractLocation[]>(
    <any>this.initReachablePlaces(),
    []
  )

  public readonly reachablePlaces = new AsyncObservable<AbstractLocation[]>(
    <any>this.reachablePlacesTimesDistance.value.pipe(
      map((list) => {
        return list.filter((location) => location.travelTime != null && location.travelTime > -1)
      })
    ),
    []
  )

  public readonly reachableFilteredPlacesAndPlanning = new AsyncObservable<AbstractLocation[]>(
    <any>this.initFilteredReachablePlacesAndPlanning(),
    []
  )

  public readonly reachableFilteredPlacesAndPlanningTimesDistance = new AsyncObservable<AbstractLocation[]>(
    <any>this.initFilteredReachablePlacesAndPlanningTimeDistance(),
    []
  )

  public readonly reachableFilteredPlaces = new AsyncObservable<AbstractLocation[]>(
    <any>this.initFilteredReachablePlaces(),
    []
  )

  public readonly inactiveSources: Observable<AbstractLocation[]>

  public readonly labelLocationsRaw = new BehaviorSubject<LatLngProperties[]>([])
  public readonly labelLocations = this.initLabelLocations()

  public readonly mapPlaces = Observable.combineLatest(
    this.settings.showStoresWithinTravelTimeUpdates,
    this.filteredPlaces.value,
    this.reachableFilteredPlaces.value,
    this.sources.observable
  ).pipe(
    map(([showTravel, filtered, reachable, sources]) => {
      return showTravel && sources.length > 0 ? reachable : filtered
    }),
    shareReplay(1)
  )
  // .map((items) => items.filter((item) => !item.isStar()))
  // .shareReplay(1)

  // public readonly filteredPlacesNormal = this.filteredPlaces.value.map(items => items.filter(item => item.id >= 0)).shareReplay(1)
  // public readonly filteredPlacesCustom = this.filteredPlaces.value.map(items => items.filter(item => item.id < 0)).shareReplay(1)
  public readonly filteredPlacesNormal = this.mapPlaces
    .map((items) => items.filter((item) => !item.isStar()))
    .shareReplay(1)

  public readonly filteredPlacesCustom = this.mapPlaces
    .map((items) => items.filter((item) => item.isStar()))
    .shareReplay(1)

  public readonly georegionGeometry: Promise<any>
  public readonly deletedLocations: SerializedPlace[] = []

  public readonly matchpointAvailable$ = this.initMatchpointAvailable()
  readonly operatorsWithin: OperatorsWithinModel
  readonly locationsPresent: LocationsPresentModel
  readonly fasciasSelectionModel: FasciasSelectionModel

  readonly visibleDatasets = Observable.combineLatest(
    this.dataSets,
    this.categorySelectUpdated.pipe(debounceTime(1))
  ).pipe(
    map(([dataSets]) => {
      return dataSets.filter((dataSet) => {
        return dataSet.categories.find((category) => {
          return category.visible
        })
      })
    }),
    shareReplay(1)
  )

  readonly visibleDatasetsFilters = this.visibleDatasets.pipe(
    map((dataSets) => {
      if (!this.settings.displaySettings.value.dataSetFilters) {
        this.settings.displaySettings.value.dataSetFilters = {}
      }

      return dataSets.map(
        (item) =>
          new DataSetFilter(item, this.settings.displaySettings.value.dataSetFilters, () =>
            this.dataSetFiltersUpdates.next(true)
          )
      )
    }),
    shareReplay(1)
  )

  readonly nonPassiveDataSets = this.initNonPassiveDataSets()

  public readonly reachableFilteredPlacesAndPlanningNonPassive = new AsyncObservable<AbstractLocation[]>(
    <any>this.initFilteredReachablePlacesAndPlanningNonPassive(),
    []
  )

  public readonly reachableFilteredPlacesNonPassive = new AsyncObservable<AbstractLocation[]>(
    <any>this.initFilteredReachablePlacesNonPassive(),
    []
  )

  public readonly reachableFilteredPlanning = new AsyncObservable<AbstractLocation[]>(
    <any>this.initFilteredReachablePlanning(),
    []
  )

  public readonly reachableFilteredPlanningNonPassive = new AsyncObservable<AbstractLocation[]>(
    <any>this.initFilteredReachablePlanningNonPassive(),
    []
  )

  public readonly placesNonPassive = new AsyncObservable<AbstractLocation[]>(
    <any>this.initCombinedPlacesNonPassive(),
    []
  )

  public readonly filteredNonPassivePlaces = this.initFilteredNonPassivePlaces()
  public readonly categorySelectionUpdated = new BehaviorSubject<Category>(null)

  constructor(
    private placesLoadingModel: DataLoadingModel,
    private indicators: Indicators,
    private travelOptions: Observable<TravelTypeEdgeWeightOptions>,
    private intersectionMode: Observable<string>,
    private polygons: Observable<any>,
    private dataSetEndpoint: DataSetEndpoint,
    private placeEndpoint: PlaceEndpoint,
    private planningEndpoint: PlanningApplicationDataSetEndpoint,
    public readonly sources: CombinedSources,
    private readonly showLabelsUpdates: Observable<boolean>,
    private client: TargomoClient,
    private settings: SettingsModel,
    private zoneLayersModel: ZoneLayersModel,
    private geoRegionsEndpoint: GeoRegionsEndpoint,
    private userEventLogEndpoint: UserEventLogEndpoint,
    private travelSourcesCount$: Observable<number>
  ) {
    super()
    // Reachable filtered
    this.initFilteredReachablePlaces()
    // this.initSourcesFilter()
    this.initMinMax()
    this.initLocationCenterType()
    this.initDateAdded()
    this.inactiveSources = this.initInactiveSources()
    this.categorySearchUpdated = this.initCategoryFilter()

    this.georegionGeometry = this.initGeoRegionGeometry()

    this.getUnavailableDataSets()

    this.operatorsWithin = new OperatorsWithinModel(this)
    this.locationsPresent = new LocationsPresentModel(this, settings.gapReportLocationsUpdates, travelSourcesCount$)
    this.fasciasSelectionModel = new FasciasSelectionModel(this)
    // this.initUpdateCategoryColors()
  }

  private initMatchpointAvailable() {
    return this.sources.observable
      .map((sources) => {
        return (
          sources && sources.length >= 1 && !sources.find((item) => item.isTemporaryCustom())
          // sources[0].dataSet &&
          // (sources[0].dataSet.name || '').trim().toUpperCase().indexOf('CENTREPOINT') > -1
        ) // FIXME

        // return (
        //   sources &&
        //   sources.length === 1 &&
        //   sources[0].dataSet &&
        //   (sources[0].dataSet.name || '').trim().toUpperCase().indexOf('CENTREPOINT') > -1
        // ) // FIXME
      })
      .distinctUntilChanged()
      .shareReplay(1)
  }

  getUnavailableDataSets() {
    if (!this.unavailableDataSets) {
      this.unavailableDataSets = this.placesLoadingModel.initUnavailableDataSets().shareReplay(1)

      // FIXME: doing this weirdness because time is running out and something was not working
      // check later when I have time
      this.unavailableDataSets.subscribe((dataSets) => {
        this.dataSetsUnavailable.next(dataSets)
      })
    }

    return this.unavailableDataSets
  }

  updateLabels() {
    return this.settings.labelsConfigUpdates.pipe(
      combineLatest(this.places.value),
      map(([configInput, places]) => {
        const config = new LabelsConfig(configInput)
        config.updatePlaces(places)
        return places
      })
    )

    // this.watch(observable, ([configInput, places]) => {
    //   const config = new LabelsConfig(configInput)
    //   config.updatePlaces(places)
    // })
  }

  async getLatLngAllowedFunction() {
    const geometry = await this.georegionGeometry

    return function (place: LatLng) {
      if (!geometry) {
        // No geometry for now means all world is allowed
        return true
      }
      const point = [place.lng, place.lat]
      return turfInside(point, geometry)
    }
  }

  async isLatLngAllowed(place: LatLng) {
    return (await this.getLatLngAllowedFunction())(place)
  }

  private async initGeoRegionGeometry() {
    const geometry = await this.geoRegionsEndpoint.getGeometries()
    if (geometry) {
      return {
        type: 'Feature',
        geometry,
        properties: {},
      }
    } else {
      return null
    }
  }

  private initCategoryFilter() {
    return this.initFilterFor()
  }

  private initFilterFor() {
    const clearSearch = (dataSets: DataSet[]) => {
      dataSets.forEach((set) => {
        set.filtered = null
        if (set.categories) {
          set.categories.forEach((category) => {
            category.filtered = null
          })
        }
      })
    }

    const updateSearch = (dataSets: DataSet[], search: string, onlyMapped: boolean) => {
      search = (search || '').toLowerCase()

      function contains(value: string, segment: string) {
        return (value || '').toLowerCase().indexOf(segment) > -1
      }

      dataSets.forEach((set) => {
        let setFiltered = false
        if (set.categories) {
          set.categories.forEach((category) => {
            category.filtered = !search || contains(category.name, search)

            if (onlyMapped) {
              category.filtered = category.filtered && category.visible
            }

            setFiltered = setFiltered || category.filtered
          })
        }
        set.filtered = setFiltered || (!onlyMapped && contains(set.name, search))
      })
    }

    const result = this.categorySearchText.pipe(
      combineLatest(this.settings.showOnlyMappedCategoriesUpdates),
      debounceTime(300),
      withLatestFrom(this.dataSets, this.dataSetsUnavailable),
      map(([[search, onlyMapped], dataSets, dataSetsUnavailable]) => {
        if (!search || search.length < 3) {
          search = ''
        }

        if (!search && !onlyMapped) {
          clearSearch(dataSets)
          clearSearch(dataSetsUnavailable)
          return ['', onlyMapped]
        } else {
          updateSearch(dataSets, search, onlyMapped)
          updateSearch(dataSetsUnavailable, search, onlyMapped)
          return [search, onlyMapped]
        }
      })
    ) as Observable<[string, boolean]>

    return result.shareReplay(1)
  }

  private initInactiveSources() {
    const EMPTY: AbstractLocation[] = []

    return Observable.combineLatest(
      this.settings.exclusiveTravelUpdates,
      this.sources.observable,
      this.settings.travelOptionsUpdates,
      // this.filteredPlaces.value
      this.filteredNonPassivePlaces
    )
      .debounceTime(10)
      .switchMap(async ([exclusiveTravel, sources, travelOptions, places]) => {
        if (!exclusiveTravel) {
          return EMPTY
        }
        return await this.getInactiveSources(exclusiveTravel, sources, travelOptions)
      })
      .distinctUntilChanged()
      .shareReplay(1)
  }

  async toggleCategoryPinned(category: Category) {
    category.pinned = !category.pinned

    if (category.pinned) {
      this.categoryPinned.add(category.getPersistentCode())
    } else {
      this.categoryPinned.remove(category.getPersistentCode())
    }
  }

  restorePinnedCategories(pinned: string[]) {
    this.placesLoadingModel.categoriesMap.take(1).subscribe((categoriesMap) => {
      objects.values(categoriesMap).forEach((category) => (category.pinned = false))

      pinned.forEach((code) => {
        const category = categoriesMap[code]
        if (category) {
          category.pinned = true
        }
      })

      this.categoryPinned.clear().addAll(pinned)
    })
  }

  async toggleCategory(category: Category, value: boolean) {
    await this.placesLoadingModel.loadDataSet(category.dataSet)

    const places = (await this.places.value.pipe(delay(10), take(1)).toPromise()).filter(
      (place) => place.category === category
    )

    if (value) {
      this.sources.addAll(places)
    } else {
      this.sources.removeAll(places)
    }
  }

  /**
   * when filter changes, also filter active sources
   */
  private initSourcesFilter() {
    this.filters.observable.subscribe((filters) => {
      var dirty = false
      const sources = this.sources.getValues().filter((source) => {
        const result = (filters || []).every((filter) => filter(source))
        if (!result) dirty = true
        return result
      })

      if (dirty) {
        this.sources.update(sources)
      }
    })
  }

  private initPlaces() {
    const categoryColors$ = this.placesLoadingModel.dataSets.map((dataSets) => {
      const colors = this.initCategoriesMap(dataSets)
      this.dataSets.next(dataSets)
      return { colors, dataSets }
    })

    Observable.combineLatest(
      categoryColors$,
      this.settings.customCategoryColorsPaletteUpdates,
      this.settings.customLocationsColorsPaletteUpdates
    ).subscribe(([{ colors, dataSets }, customColors, customColorsLocation]) => {
      const defaultColors = colors || {}
      objects.values(customColors || {}).forEach((color) => {
        defaultColors[color] = color
      })

      objects.values(customColorsLocation || {}).forEach((color) => {
        defaultColors[color] = color
      })

      this.uniqueColors.next(Object.keys(defaultColors))
    })

    const categoryAndLocationColors = Observable.combineLatest(
      this.settings.customCategoryColorsPaletteUpdates,
      this.settings.customLocationsColorsPaletteUpdates
    ).map(([categoryColors, locationColors]) => {
      return { categoryColors, locationColors }
    })

    const placesColors$: Observable<AbstractLocation[]> = Observable.combineLatest(
      categoryColors$,
      this.settings.showCustomCategoryColorsUpdates,
      categoryAndLocationColors,
      this.placesLoadingModel.allPlaces,
      this.customPlaces.observable,
      this.settings.locationIconModelUpdates
    ).map(([{ dataSets }, custom, { categoryColors, locationColors }, places, customPLaces, locationIconModel]) => {
      ;(dataSets || []).forEach((dataSet) => {
        ;(dataSet.categories || []).forEach((category) => {
          if (custom && categoryColors) {
            category.customColor = categoryColors[this.placesLoadingModel.categoryKey(category)] || category.customColor
          }
          category.color = (<any>category).markerStyleCategory = custom
            ? category.customColor || category.originalColor
            : category.originalColor

          const config = locationIconModel.getCategoryConfig(category as any)
          category.customIcon = config.icon || null
        })
      })

      if (places) {
        places.forEach((place) => {
          if (place.category) {
            place.properties['marker-type'] = place.category.markerStyleCategory
          }

          if (locationColors && locationColors[place.id]) {
            place.properties['marker-type'] = locationColors[place.id]
          }

          locationIconModel.decorateWithIcon(place)
        })
      }

      if (customPLaces) {
        customPLaces.forEach((place) => {
          if (place.category) {
            place.properties['marker-type'] = place.category.markerStyleCategory
          }

          if (locationColors && locationColors[place.id]) {
            place.properties['marker-type'] = locationColors[place.id]
          }

          locationIconModel.decorateWithIcon(place)
        })
      }

      return places
    })

    return placesColors$.map(async (v) => v)
  }

  async findReachablePlaces(geometry: any, planning: boolean) {
    let places = await this.filteredPlaces.value.take(1).toPromise()
    if (!geometry) {
      return []
    }

    places = places || []
    let result: AbstractLocation[] = null

    if (
      geometry.properties &&
      geometry.properties.isCircle &&
      geometry.properties.radiusInKm != null &&
      geometry.properties.center != null
    ) {
      const source = { lat: geometry.properties.center[1], lng: geometry.properties.center[0] }
      const maximumDistance = +geometry.properties.radiusInKm

      result = places.filter((place) => {
        // decided to use turf, just in case...
        const distance = turfDistance(geometry.properties.center, [place.lng, place.lat])
        // geometries.calculateDistance(source, place)
        return distance <= maximumDistance
      })
    } else {
      result = places.filter((place) => {
        const point = [place.lng, place.lat]
        return (
          !!place.planningLocation == planning &&
          geometries.contains(geometry.properties.bounds, place) &&
          turfInside(point, geometry)
        )
      })
    }

    return result
  }

  private initReachablePlaces() {
    const reachableZones = Observable.combineLatest(
      this.zoneLayersModel.visibleLayerFeaturesGeometry,
      this.filteredPlaces.value
    ).map(([geometry, places]) => {
      if (!geometry) {
        return []
      }

      const now = new Date().getTime()

      places = places || []
      let result: AbstractLocation[] = null

      if (
        geometry.properties &&
        geometry.properties.isCircle &&
        geometry.properties.radiusInKm != null &&
        geometry.properties.center != null
      ) {
        const source = { lat: geometry.properties.center[1], lng: geometry.properties.center[0] }
        const maximumDistance = +geometry.properties.radiusInKm

        result = places.filter((place) => {
          // decided to use turf, just in case...
          const distance = turfDistance(geometry.properties.center, [place.lng, place.lat])
          // geometries.calculateDistance(source, place)
          return distance <= maximumDistance
        })
      } else {
        result = places.filter((place) => {
          const point = [place.lng, place.lat]
          return geometries.contains(geometry.properties.bounds, place) && turfInside(point, geometry)
        })
      }

      return result
    })

    // reachableZones.subscribe(places => {
    //   console.log('INSIDE PLACES', places)
    // })

    const reachablePolygon = this.polygons
      .withLatestFrom(this.sources.observable, this.filteredPlaces.value, this.travelOptions, this.intersectionMode)
      .map(async ([polygons, sources, places, travelOptions, intersectionMode]) => {
        const boundingBox = geometry.boundingBoxListWithinTravelOptions(sources, travelOptions)

        const sourcesMap: any = {}
        sources.forEach((source) => {
          sourcesMap[source.id] = source
        })
        const isNotSource = (place: any) => !sourcesMap[place.id]

        const result = await this.loadTimes(sources, places, travelOptions, intersectionMode, boundingBox).then(
          (places) => places.filter(isNotSource)
        )

        sources.forEach((source) => {
          source.travelTime = 0
        })

        return result
      })

    return Observable.combineLatest(
      Observable.from([null]).merge(reachableZones),
      Observable.from([null]).merge(reachablePolygon),
      this.zoneLayersModel.selectionExistsUpdates
    ).map(([zonePlaces, polygonPlaces, showZones]) => {
      if (showZones) {
        return zonePlaces || []
      } else {
        return polygonPlaces || []
      }
    })
  }

  private initCategoriesMap(profiles: DataSet[]) {
    var i = 0
    // const result: {[index: string]: Category} = {}
    const colors: { [index: string]: string } = {}

    for (let key in CUSTOM_CATEGORIES) {
      colors[CUSTOM_CATEGORIES[key].color] = CUSTOM_CATEGORIES[key].color
    }

    RANDOM_COLORS.forEach((color) => {
      colors[color] = color
    })

    for (var profile of profiles) {
      for (var category of profile.categories) {
        i++

        if (!category.color) {
          category.color = RANDOM_COLORS[i % RANDOM_COLORS.length]
        }

        ;(<any>category).markerStyleCategory = category.color
        colors[category.color] = category.color

        // const simpleKey = profile.versionId + '@' + category.code
        // const key = profile.versionId + '@' + category.grouping + '@' + category.code
        // result[key] = <any>category
        // result[simpleKey] = <any>category // just in case
      }
    }

    colors['#3887BE'] = '#3887BE'

    return colors
    // return result
  }

  private initFilteredPlaces() {
    return this.filteredPlacesRaw.value.pipe(
      map((places) => {
        return places.filter((place) => !place.deleted)
      })
    )
  }

  async filterNonPassive(places: AbstractLocation[]) {
    return this.nonPassiveDataSets.pipe(take(1)).map((datasetFiltersMap) => {
      return places.filter((place) => {
        const filter = datasetFiltersMap[place.dataSet && place.dataSet.id]
        return !filter
      })
    })
  }

  private initNonPassiveDataSets() {
    return Observable.combineLatest(this.visibleDatasetsFilters, this.dataSetFiltersUpdates).pipe(
      map(([datasetFilters]) => {
        const datasetFiltersMap = datasetFilters.reduce((acc, cur) => {
          acc[cur.dataSet.id] = cur.isPassive()

          return acc
        }, {} as Record<number, boolean>)

        return datasetFiltersMap
      }),
      shareReplay(1)
    )
  }

  private initFilteredNonPassivePlaces() {
    return Observable.combineLatest(this.filteredPlaces.value, this.nonPassiveDataSets).pipe(
      map(([places, datasetFiltersMap]) => {
        return places.filter((place) => {
          const filter = datasetFiltersMap[place.dataSet && place.dataSet.id]
          return !filter
        })
      }),
      shareReplay(1)
    )
  }

  private initFilteredPlacesDeleted() {
    return this.filteredPlacesRaw.value.pipe(
      map((places) => {
        return places.filter((place) => !!place.deleted)
      })
    )
  }

  async updateLabelsWithCurrentConfig(places: Place[]) {
    const config = new LabelsConfig(await this.settings.labelsConfigUpdates.toPromise())
    config.updatePlaces(places)
    return places
  }

  private initFilteredPlacesRaw() {
    // return this.settings.labelsConfigUpdates.pipe(
    //   combineLatest(this.places.value),
    //   map(([configInput, places]) => {
    //     const config = new LabelsConfig(configInput)
    //     config.updatePlaces(places)
    //     return places
    //   })

    const labeledPlaces = Observable.combineLatest(this.places.value, this.settings.labelsConfigUpdates).map(
      ([places, labelsConfig]) => {
        const config = new LabelsConfig(labelsConfig)

        config.updatePlaces(places)
        return places
      }
    )

    const pinnedPlaces = Observable.combineLatest(labeledPlaces, this.categoryPinned.observable).pipe(
      map(([places]) => {
        const pinned = places.filter((place) => place.category.pinned)
        const unPinned = places.filter((place) => !place.category.pinned)

        return [...unPinned, ...pinned]
      })
    )

    return Observable.combineLatest(
      this.filters.observable,
      // this.places.value,
      pinnedPlaces,
      this.labelFascia.observable,
      this.showLabelsUpdates,
      this.settings.markerStyleReportUpdates
      // this.settings.labelsConfigUpdates,
    ).map(async ([filters, places, fasciaList, showLabels, markerStyleReport]) => {
      if (!filters || filters.length == 0) {
        // We explicitly want to never have all unfiltered locations, so no filters means no locations selected
        // Result of returning all locations is browser tab crash due to momery limit
        return []
      }

      // const filteredPlaces = places.filter(place => !place.deleted && (filters || []).every(filter => filter(place)))
      let filteredPlaces = places.filter((place) => (filters || []).every((filter) => filter(place)))

      if (markerStyleReport === 'competition') {
        filteredPlaces = filteredPlaces.filter((place) => !place.planningLocation)
      }

      const storeIds: any = {}
      const result: AbstractLocation[] = []

      // Filter places belonding to multiple data sets
      filteredPlaces.forEach((place) => {
        if (!storeIds[place.storeId] || !place.storeId) {
          storeIds[place.storeId] = true
          result.push(place)
        }
      })

      const fasciaMap: any = {}
      const labelLocations: AbstractLocation[] = []
      fasciaList.forEach((fascia) => (fasciaMap[fascia] = true))

      result.forEach((place: any) => {
        let found = place.properties['marker-label-visible'] //fasciaMap[place.fascia]

        if (showLabels) {
          found = !found
        } else if (found) {
          labelLocations.push(place)
        }

        if (found) {
          place.properties['name'] = place.name
        } else {
          place.properties['name'] = ''
        }
      })

      this.labelLocationsRaw.next(<any>(showLabels ? filteredPlaces : labelLocations))

      return result
    })
  }

  private initLabelLocations() {
    const showReachable$ = Observable.combineLatest(
      this.settings.showStoresWithinTravelTimeUpdates.pipe(distinctUntilChanged()),
      this.sources.observable.pipe(
        map((sources) => sources.length > 0),
        distinctUntilChanged()
      ),
      this.reachablePlaces.value
    ).pipe(
      map(([showStoresWithinTravelTimeUpdates, hasSources]) => {
        return showStoresWithinTravelTimeUpdates && hasSources
      }),
      debounceTime(1)
    )

    return Observable.combineLatest(this.labelLocationsRaw, showReachable$).pipe(
      map(([places, showReachable]) => {
        if (showReachable) {
          return places.filter((location: any) => location.travelTime != null && location.travelTime > -1)
        } else {
          return places
        }
      }),
      shareReplay(1)
    )
  }

  private initFilteredReachablePlacesAndPlanning() {
    return Observable.combineLatest(this.filters.observable, this.reachablePlaces.value).map(
      async ([filters, reachablePlaces]) =>
        reachablePlaces.filter((place) => (filters || []).every((filter) => filter(place)))
    )
  }

  private initFilteredReachablePlacesAndPlanningNonPassive() {
    return Observable.combineLatest(this.reachableFilteredPlacesAndPlanning.value, this.nonPassiveDataSets).pipe(
      map(([places, datasetFiltersMap]) => {
        return places.filter((place) => {
          const filter = datasetFiltersMap[place.dataSet && place.dataSet.id]
          return !filter
        })
      }),
      shareReplay(1)
    )
  }

  private initFilteredReachablePlacesAndPlanningTimeDistance() {
    return Observable.combineLatest(this.filters.observable, this.reachablePlacesTimesDistance.value).map(
      async ([filters, reachablePlaces]) =>
        reachablePlaces.filter((place) => (filters || []).every((filter) => filter(place)))
    )
  }

  private initFilteredReachablePlacesNonPassive() {
    return this.reachableFilteredPlacesAndPlanningNonPassive.value.map((places) => {
      return (places || []).filter((place) => !place.planningLocation)
    })
  }

  private initFilteredReachablePlaces() {
    return this.reachableFilteredPlacesAndPlanning.value.map((places) => {
      return (places || []).filter((place) => !place.planningLocation)
    })
  }

  private initFilteredReachablePlanning() {
    return this.reachableFilteredPlacesAndPlanning.value.map((places) => {
      return (places || []).filter((place) => !!place.planningLocation)
    })
  }

  private initFilteredReachablePlanningNonPassive() {
    return this.reachableFilteredPlacesAndPlanningNonPassive.value.map((places) => {
      return (places || []).filter((place) => !!place.planningLocation)
    })
  }

  private initCombinedPlaces() {
    return Observable.combineLatest(this.customPlaces.observable, this.originalPlaces.value).map(
      async ([customPlaces, places]) => [].concat(places, customPlaces)
    )
  }

  private initCombinedPlacesNonPassive() {
    return Observable.combineLatest(this.places.value, this.nonPassiveDataSets).pipe(
      map(([places, datasetFiltersMap]) => {
        return places.filter((place) => {
          const filter = datasetFiltersMap[place.dataSet && place.dataSet.id]
          return !filter
        })
      }),
      shareReplay(1)
    )
  }

  private async loadTimes(
    sources: AbstractLocation[],
    places: AbstractLocation[],
    options: TravelTypeEdgeWeightOptions,
    intersectionMode: string,
    boundingBox: BoundingBox
  ): Promise<AbstractLocation[]> {
    if (!sources.length) return []

    var boundingPlaces = places
    if (boundingBox) {
      boundingPlaces = places.filter((place) => geometry.contains(boundingBox, place))
    }

    if (!boundingPlaces.length) {
      return []
    }

    if (sources.length > INTERSECTION_THRESHOLD && intersectionMode !== 'union') {
      return []
    }

    // TODO: optimize, try to prefilter any unreachable items I can figure out
    // const requestOptions = travelOptions.forTimes({sources: sources, travelSpeed: options.travelSpeed, travelType: options.travelType, travelTime: options.travelTime})
    // requestOptions.setMaxRoutingTime(options.travelTime)

    const requestOptions = {
      ...options,
      intersectionMode,
    }

    return await this.indicators.add(
      this.rechabilityLocations(canonicalPositions(sources), boundingPlaces, requestOptions, true)
    )
  }

  private nextCustomLocationId() {
    let id = -1
    this.customPlaces.getValues().forEach((location) => {
      id = Math.min(location.id, id)
    })

    return id - 1
  }

  nextDataSetId() {
    let id = -1
    this.customProfiles.getValues().forEach((profile) => {
      id = Math.min(profile.id, id)
    })

    return id - 1
  }

  public addCustomPlace(location: DataSetLocation) {
    location.id = this.nextCustomLocationId()
    location.storeId = String(location.id)
    this.customPlaces.add(location)
  }

  // public generateCategoryId() {
  //   return categoryIndex--
  // }

  public undeleteLocation(location: DataSetLocation) {
    if (!location.deleted) {
      return
    }

    location.deleted = false

    if (location.category) {
      location.category.placesCount++
    }

    if (location.dataSet && location.dataSet.categories) {
      location.dataSet.placesCount++
      this.placesLoadingModel.dataSetLoadedOrUpdated.next(location.dataSet)
    }

    const serialized = serializePlace(location)
    ;(<any>this).deletedLocations = this.deletedLocations.filter((row) => {
      return !(
        row.storeId === serialized.storeId &&
        row.category === serialized.category &&
        row.dataSetName === serialized.dataSetName
      )
    })

    this.filters.touch()
  }

  public deleteLocation(location: DataSetLocation) {
    if (location.id < 0) {
      if (location.category) {
        location.category.placesCount--
      }
      this.customPlaces.remove(location)
      this.sources.remove(location)

      // NOTE: does it need to be more efficient, or is it ok for the expected use cases (single location deletes)
      const profiles = this.customProfiles.getValues() || []
      for (let profile of profiles) {
        if (profile.categories) {
          for (let category of profile.categories) {
            if (category === location.category) {
              profile.placesCount--
              this.placesLoadingModel.dataSetLoadedOrUpdated.next(profile)
              return // return on first
            }
          }
        }
      }
    } else {
      location.deleted = true
      this.sources.remove(location)

      if (location.category) {
        location.category.placesCount--
      }

      if (location.dataSet && location.dataSet.categories) {
        location.dataSet.placesCount--
        this.placesLoadingModel.dataSetLoadedOrUpdated.next(location.dataSet)
      }

      this.deletedLocations.push(serializePlace(location))
      this.filters.touch()
    }
  }

  public addCustomLayer(name: string, locations: DataSetLocation[]) {
    const categories: { [name: string]: Category } = {}
    const categoriesList: Category[] = []

    function normalizeProperties(object: any) {
      const result: any = {}

      for (let key in object) {
        result[key.toLowerCase()] = object[key]
      }

      return result
    }

    let categoryIndex = nextCategoryId()
    let dataSetIndex = this.nextDataSetId()

    if (locations) {
      let id = this.nextCustomLocationId()

      locations.forEach((location) => {
        const properties = normalizeProperties(location.properties || {})

        const locationAny = <any>location

        locationAny.fascia = properties.name || properties.fascia
        locationAny.name = locationAny.fascia
        locationAny.primaryCategory =
          properties.primarycategory || properties['primary_category'] || properties['primary-category']
        locationAny.secondaryCategory =
          properties.secondarycategory || properties['secondary_category'] || properties['secondary-category']
        locationAny.holdingCompany =
          properties.holdingcompany || properties['holding_company'] || properties['holding-company']

        let categoryName = location.fascia //location.fascia || location.sitename || properties.name || properties.fascia
        if (!categoryName) {
          categoryName = 'Other'
        }

        if (!categories[categoryName]) {
          const color = RANDOM_COLORS[Math.abs(categoryIndex) % RANDOM_COLORS.length]
          categories[categoryName] = Object.assign(new Category(), {
            id: categoryIndex--,
            code: categoryName,
            name: categoryName,
            placesCount: 0,
            color: color,
            originalColor: color,
            grouping: locationAny.secondaryCategory || locationAny.holdingCompany || 'Other',
            visible: true,
          })

          categories[categoryName].markerStyleCategory = categories[categoryName].color

          categoriesList.push(categories[categoryName])
        }

        ;(<any>location).category = categories[categoryName]
        categories[categoryName].placesCount++

        locationAny.sitename = properties.sitename
        locationAny.paon = properties.paon
        locationAny.saon = properties.saon
        locationAny.taon = properties.taon
        locationAny.street = properties.street
        locationAny.suburb = properties.suburb
        locationAny.town = properties.town
        locationAny.postcode = properties.postcode
        locationAny.district = properties.district
        locationAny.county = properties.county
        locationAny.region = properties.region
        locationAny.netSalesArea =
          properties.netsalesarea || properties['net_sales_area'] || properties['net-sales-area']
        locationAny.netSalesAreaSource =
          properties.netsalesareasource || properties['net_sales_area_source'] || properties['net-sales-area-source']
        locationAny.grossInternalArea =
          properties.grossinternalarea || properties['gross_internal_area'] || properties['gross-internal-area']
        locationAny.grossInternalAreaSource =
          properties.grossinternalareasource ||
          properties['gross_internal_area_source'] ||
          properties['gross-internal-area-source']

        if (locationAny.netSalesArea != null) locationAny.netSalesArea = +locationAny.netSalesArea

        if (locationAny.grossInternalArea != null) locationAny.grossInternalArea = +locationAny.grossInternalArea

        location.dataSetId = dataSetIndex
        location.id = id--
        location.storeId = properties.storeId || properties['store-id'] || properties['store_id'] || String(location.id)
        location.properties['marker-type'] = (<any>location).category.markerStyleCategory
        location.properties['marker-icon-size'] = 1
        location.properties['isGeoJSON'] = true
      })
    }

    const profile = {
      id: dataSetIndex,
      name: name,
      categories: categoriesList,
      placesCount: (locations && locations.length) || 0,
      visible: true,
    }

    this.customPlaces.addAll(locations)

    this.dataSets.nextWithCurrent((sets) => {
      sets.push(profile)
      return sets
    })

    this.customProfiles.add(profile)

    this.filters.touch()
    dataSetIndex--

    this.placesLoadingModel.dataSetLoadedOrUpdated.next(profile)
  }

  isLabelEnabled(location: DataSetLocation) {
    return !!location.properties['marker-label-visible']
    // return this.labelFascia.contains(location.fascia)
  }

  toggleLabel(location: DataSetLocation) {
    location.properties['marker-label-visible'] = !location.properties['marker-label-visible']
    // this.labelFascia.toggle(location.fascia)
    this.labelFascia.touch()
  }

  clearLabels() {
    this.places.getValue().forEach((location: any) => {
      location.properties['marker-label-visible'] = false
    })

    this.labelFascia.clear()
  }

  public sessionRestored() {
    this.dataSets.nextWithCurrent((sets) => {
      this.customProfiles.getValues().forEach((profile) => {
        sets.push(profile)
      })
      return sets
    })
  }

  public getVisibleCategories() {
    const visible: any = {}
    this.dataSets.getValue().forEach((set) => {
      set.categories.forEach((category: Category) => {
        if (category.visible) {
          visible[category.code + '@' + category.grouping + '@' + category.dataSetName] = true
        }
      })
    })

    return visible
  }

  public async expandCategoriesCompact(compact: CompactLayersState) {
    const visible: Record<string, boolean> = {}

    function hydrate(list: string[]) {
      return (list || []).reduce((acc, cur) => {
        acc[cur] = true

        return acc
      }, {} as Record<string, true>)
    }

    const datasets = await this.placesLoadingModel.dataSets.take(1).toPromise()

    datasets.forEach((set) => {
      const setConfig = compact[set.name]
      if (setConfig) {
        if (setConfig === true) {
          set.categories.forEach((category: Category) => {
            visible[category.code + '@' + category.grouping + '@' + category.dataSetName] = true
          })
        } else {
          const groupings = Object.keys(setConfig).reduce((acc, cur) => {
            const config = setConfig[cur]

            if (config === true) {
              acc[cur] = true
            } else {
              acc[cur] = {
                values: hydrate((config as any)['+'] || (config as any)['-']),
                excludeMode: !!(config as any)['-'],
              }
            }

            return acc
          }, {} as any)

          set.categories.forEach((category: Category) => {
            const currrentGrouping = groupings[category.grouping]
            if (
              currrentGrouping &&
              (currrentGrouping === true ||
                (currrentGrouping.excludeMode && !currrentGrouping.values[category.code]) ||
                (!currrentGrouping.excludeMode && currrentGrouping.values[category.code]))
            ) {
              visible[category.code + '@' + category.grouping + '@' + category.dataSetName] = true
            }
          })
        }
      }
    })

    return visible
  }

  public getVisibleCategoriesCompact() {
    const visible: CompactLayersState = {}

    this.dataSets.getValue().forEach((set) => {
      const allCounter = new Counter()
      const groupings = Object.keys(
        set.categories.reduce((acc, category: Category) => {
          acc[category.grouping] = true

          return acc
        }, {} as any)
      ).reduce((acc, cur) => {
        acc[cur] = new Counter()
        return acc
      }, {} as Record<string, Counter>)

      set.categories.forEach((category: Category) => {
        allCounter.add(category.code, category.visible)
        groupings[category.grouping].add(category.code, category.visible)
      })

      if (allCounter.isAll()) {
        if (!allCounter.allHidden) {
          visible[set.name] = allCounter.toJSON() as true
        }
      } else {
        visible[set.name] = Object.keys(groupings).reduce((acc, key) => {
          if (!groupings[key].allHidden) {
            acc[key] = groupings[key].toJSON()
          }
          return acc
        }, {} as any)
      }
    })

    return visible
  }

  public getVisibleDataSets() {
    const visible: CompactLayersState = {}

    this.dataSets.getValue().forEach((set) => {
      const allCounter = new Counter()
      const groupings = Object.keys(
        set.categories.reduce((acc, category: Category) => {
          acc[category.grouping] = true

          return acc
        }, {} as any)
      ).reduce((acc, cur) => {
        acc[cur] = new Counter()
        return acc
      }, {} as Record<string, Counter>)

      set.categories.forEach((category: Category) => {
        allCounter.add(category.code, category.visible)
        groupings[category.grouping].add(category.code, category.visible)
      })

      if (allCounter.isAll()) {
        if (!allCounter.allHidden) {
          visible[set.id] = true
        }
      } else {
        visible[set.name] = Object.keys(groupings).reduce((acc, key) => {
          if (!groupings[key].allHidden) {
            visible[set.id] = true
          }
          return acc
        }, {} as any)
      }
    })

    return Object.keys(visible).map((item) => +item)
  }

  public async restoreDeletedState() {
    const places = await this.originalPlaces.value.take(1).toPromise()

    places.forEach((place) => {
      place.deleted = false
    })

    this.dataSets.getValue().forEach((set) => {
      set.placesCount = set.placesCountOriginal
      set.categories.forEach((category: Category) => {
        category.placesCount = category.placesCountOriginal
      })
    })

    this.applyDeletedState(places)
  }

  public applyDeletedState(places: AbstractLocation[]) {
    const deletedMap = objects.mapFromArray(this.deletedLocations || [], 'storeId')
    places.forEach((place) => {
      const match = deletedMap[place.storeId]
      if (match && matchPlaceWithSerialized(place, match)) {
        if (!place.deleted) {
          if (place.category) {
            ;(<any>place.category).placesCount--
          }

          if (place.dataSet && place.dataSet.categories) {
            place.dataSet.placesCount--
          }
        }

        place.deleted = true
      }
    })
  }

  public setVisibleCategories(visible: any, fromCode: boolean) {
    const listAffected: Category[] = []
    this.dataSets.getValue().forEach((set) => {
      let found = false
      set.categories.forEach((category: Category) => {
        if (fromCode) {
          category.visible = !!visible[category.code + '@' + category.grouping + '@' + category.dataSetName]
        } else {
          category.visible = !!visible[category.id]
        }

        if (category.visible) {
          listAffected.push(category)
        }

        found = found || category.visible
      })

      if (found) {
        this.indicators.add(this.placesLoadingModel.loadDataSet(set))
      }
    })

    this.filters.touch()
    this.logLayersCreated(listAffected)
  }

  public getRadiusForZoomLevel(zoom: number, area: number) {
    const markerSize = Math.floor(area / 5000) + 1

    let radius = 8 + Math.ceil(Math.sqrt(markerSize / 3.14) * 12)
    zoom = Math.max(5, Math.min(25, zoom))

    return scales.scaleLinear().domain([1, 25]).range([1, radius])(zoom)
  }

  public getInactiveSourcesWithPlaces(
    enabled: boolean,
    sources: AbstractLocation[],
    travelOptions: TravelTypeEdgeWeightOptions,
    locations: AbstractLocation[]
  ) {
    if (!enabled) {
      return []
    }

    return geometry.locationsWithinTravelOptions(locations, sources || [], travelOptions)
  }

  public async getInactiveSources(
    enabled: boolean,
    sources: AbstractLocation[],
    travelOptions: TravelTypeEdgeWeightOptions
  ) {
    if (!enabled) {
      return []
    }

    const locations = await this.filteredNonPassivePlaces.take(1).toPromise()
    const result = geometry.locationsWithinTravelOptions(locations, sources || [], travelOptions)

    // return <Place[]>[]
    return result
  }

  private initLocationCenterType() {
    let currentDataSetFilter: any

    Observable.combineLatest(
      this.settings.secondaryLocationTypeFiltersUpdates,
      this.dataSetFiltersUpdates,
      this.placesLoadingModel.centrepointMap,
      this.placesLoadingModel.dataSets
    )
      .pipe(debounceTime(10))
      .subscribe(([datasetFilters, , centrepointMap, dataSets]) => {
        this.filters.remove(currentDataSetFilter)

        if (datasetFilters.ALL) {
          currentDataSetFilter = null
        } else {
          const categorise = new CentrePointCategorize(dataSets, centrepointMap, CENTREPOINT_FILTER_CODES, 'OT')

          currentDataSetFilter = (place: AbstractLocation): boolean => {
            const code = categorise.getCode(place)
            if (code) {
              return datasetFilters[code]
            }

            return place.isStar() // was false, but now star places always appear on map
          }

          this.filters.add(currentDataSetFilter)
        }
      })
  }

  private initMinMax() {
    // let currentZoomFilter: any

    // Observable.combineLatest(this.settings.markerSizePropertyUpdates, this.settings.markerSizeMinMaxUpdates).subscribe(
    //   ([property, minMax]) => {
    //     this.filters.remove(currentZoomFilter)

    //     if (!minMax) {
    //       return
    //     }

    //     currentZoomFilter = (place: AbstractLocation): boolean => {
    //       if (place.id >= 0 || (place.id < 0 && place[property] != null)) {
    //         return place[property] >= minMax.min && place[property] <= minMax.max
    //       } else {
    //         return true
    //       }
    //     }

    //     this.filters.add(currentZoomFilter)
    //   }
    // )

    let currentDataSetFilter: any

    Observable.combineLatest(
      this.visibleDatasetsFilters,
      this.settings.showStoresWithinNoAreaDataUpdates,
      this.dataSetFiltersUpdates.pipe(debounceTime(250))
    ).subscribe(([datasetFilters, showStoresWithinNoAreaData]) => {
      const datasetFiltersMap = datasetFilters.reduce((acc, cur) => {
        acc[cur.dataSet.id] = cur

        return acc
      }, {} as Record<number, DataSetFilter>)

      this.filters.remove(currentDataSetFilter)

      currentDataSetFilter = (place: AbstractLocation): boolean => {
        const filter = datasetFiltersMap[place.dataSet && place.dataSet.id]
        if (!filter) {
          return true
        }

        return filter.matchLocation(place, showStoresWithinNoAreaData)

        // const property = filter.getFilterProperty()
        // const types = filter.getSizeBy()
        // const lowerLimit = filter.getSliderLowerValue()
        // const upperLimit = filter.getSliderUpperValue()

        // if (filter.isAverage()) {
        //   const category = place.category

        //   if (category) {
        //     const averageSize = category.averageSizes.getSize(property, types.ALL ? ['ALL'] : Object.keys(types))

        //     if (averageSize == null) {
        //       return showStoresWithinNoAreaData
        //     }

        //     return (
        //       (lowerLimit == undefined || averageSize >= lowerLimit) &&
        //       (upperLimit == undefined || averageSize <= upperLimit)
        //     )
        //   }
        // } else {
        //   if (place[property] == null) {
        //     return showStoresWithinNoAreaData
        //   }

        //   if (place.id >= 0 || (place.id < 0 && place[property] != null)) {
        //     return (
        //       (lowerLimit == undefined || place[property] >= lowerLimit) &&
        //       (upperLimit == undefined || place[property] <= upperLimit)
        //     )
        //   } else {
        //     return true
        //   }
        // }

        // return true
      }

      this.filters.add(currentDataSetFilter)
    })
  }

  private initDateAdded() {
    const FILTER_PROPERTY = 'Filter_Date'
    let currentZoomFilter: any

    this.settings.dateAddedQuarterMinMaxUpdates.subscribe((minMax) => {
      this.filters.remove(currentZoomFilter)

      if (!minMax || minMax.min === null || minMax.max === null) {
        return
      }

      const minValue = dateQuarters.toBoundsDate(minMax.min, 'min')
      const maxValue = dateQuarters.toBoundsDate(minMax.max, 'max')

      // TODO: convert to number to avoid comparing strings
      currentZoomFilter = (place: AbstractLocation): boolean => {
        if (!place.other) {
          return !!minMax.showNull
        }

        let value = place.other[FILTER_PROPERTY]
        if (!value) {
          return !!minMax.showNull
        }

        value = value.trim()
        return value >= minValue && value < maxValue
      }

      this.filters.add(currentZoomFilter)
    })
  }

  async rechabilityLocations<T extends LatLngIdTravelTimeDistance>(
    sources: LatLngId[],
    targets: T[],
    options: TimeRequestOptions,
    includeShortestDistance = false
  ): Promise<T[]> {
    const map: any = {}
    targets.forEach((place) => (map[String(place.id)] = -1))

    const res = await (this.settings.client.reachability as LocalreachabilityClient).combinedTimeDistance(
      canonicalPositions(sources),
      canonicalPositions(targets),
      options,
      includeShortestDistance
    )

    return targets
      .filter((target) => res[target.id])
      .map((target) => {
        target.travelTime = res[target.id].travelTime
        target.fastestTravelDistance = res[target.id].fastestTravelDistance
        target.shortestTravelDistance = res[target.id].shortestTravelDistance

        return target
      })
  }

  async logLayersCreated(layers: Category[]) {
    layers = layers.filter((layer) => layer.id >= 0)

    if (layers.length > 0) {
      this.userEventLogEndpoint.logLayersCreated(layers.length)
    }
  }

  planningApplicationAsLocation(planning: PlanningApplication): DataSetLocation {
    const result: any = {
      ...planning,
      id: 'planning:' + planning.id,
      category: PLANNING_CATEGORY,
    }

    result.properties = {
      'marker-type': PLANNING_CATEGORY.color,
      'marker-size': 2,
      'marker-size:grossInternalArea': 2,
      'marker-size:netSalesArea': 2,
      'marker-icon-size': PLANNING_CATEGORY.customIconSize || 1,
    }

    const resultItem = new PlanningLocation(result)

    this.indicators.add(this.planningEndpoint.getApplication(planning)).then((data) => {
      objects.assign(resultItem, data, { id: result.id })
    })

    return resultItem
  }

  async serializeVisible() {
    const filteredPlaces = await this.filteredPlaces.value.pipe(take(1)).toPromise()

    function serializePositions(locations: AbstractLocation[]) {
      return locations.map((item) => {
        const { dataSet, category, ...rest } = item

        return rest
      })
    }

    const visiblePlaceIds = filteredPlaces.map((item) => item.id)
    const customPlaces = serializePositions(filteredPlaces.filter((place) => place.isStar()))
    const visibleDataSets = this.getVisibleDataSets()

    return {
      visiblePlaceIds,
      customPlaces,
      visibleDataSets,
    }
  }
}
