<mat-radio-group [ngModel]="selected">
  <div fxLayout="row wrap">
    <div class="option-box" (click)="select(option)" *ngFor="let option of options">
      <option-image [image]="option.image"></option-image>
      <div>
        <mat-radio-button [value]="option.key">{{ option.name }}</mat-radio-button>
      </div>
    </div>
  </div>
</mat-radio-group>
