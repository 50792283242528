import { Component } from '@angular/core';
import { Category, DataSet } from "../../../api/index";
import {Auth} from '@targomo/client';
import {DataSetEndpoint} from '../../../api/dataSet';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ZoneLayersEndpoint } from '../../../api/sectors';
import { ZoneLayer } from '../../../../common/models';

@Component({
  selector: 'admin-zone-layer-categories',
  templateUrl: './adminZoneLayer.html'
})
export class AdminZoneLayerComponent {
  availableColumns = [
    {key: 'name', label: 'Name'},
    {key: 'gid', label: 'Gid'},
  ]

  form: FormGroup
  // categories: Category[] = []
  currentEdit: number
  loading: Promise<Category[]>
  saving: Promise<{}>
  set: ZoneLayer = <any>{}
  id: number

  constructor(private formBuilder: FormBuilder, private Auth: Auth, private zoneLayersEndpoint: ZoneLayersEndpoint, private route: ActivatedRoute, private router: Router) {
    this.form = this.formBuilder.group({
      'name': [this.set.name, [Validators.required]],
      'matchColumn': ['', [Validators.required]],
    })
    this.refresh()
  }

  async refresh() {
    const params = this.route.snapshot.params
    const id = params.id

    this.id = id
    // this.categories = await (this.loading = this.dataSetEndpoint.getCategories({id: setId}))
    this.set = await this.zoneLayersEndpoint.get({id: id})

    this.form = this.formBuilder.group({
      'name': [this.set.name, [Validators.required]],
      'matchColumn': [this.set.matchColumn, [Validators.required]],
    })
  }

  back() {
    this.router.navigate(['/admin/zones'])
  }

  showMenu(event: any) {
    event.stopPropagation()
  }

  async save(event?: any) {
    if (this.form.valid) {
      this.set.name = this.form.value.name
      this.set.matchColumn = this.form.value.matchColumn
      this.saving = this.zoneLayersEndpoint.save(this.set)
      await this.saving
      this.back()
    }
  }

  async updatedVersion() {
    // this.categories = await (this.loading = this.dataSetEndpoint.getCategories({id: this.setId}))
  }
}
