<section style="width: 80vw; max-width: 350px">
  <div class="dialog-logo">
      <img  *ngIf="logo" [src]="logo"/>
  </div>

  <form [formGroup]="form" (ngSubmit)="save()">
    
    <div>
      <p>Welcome back to StorePointGeo.</p>
      <p>Do you want to apply a <b>Project Code</b> to this session?</p>
    </div>

    <div style="margin: 16px 0px">
      <mat-form-field style="width: 100%">
        <input matInput placeholder="Project Code" type="text" name="projectNumber" formControlName="projectNumber"/>
      </mat-form-field>
    </div>
    
    <div class="dialog-bottom">
      <button mat-button (click)="dialogRef.close()" mat-dialog-close><span translate="dialog.cancel">Continue without Project Code</span></button>
      <button mat-raised-button color="primary" mat-button><span translate="dialog.save">Save</span></button>
    </div>
  </form>
</section>