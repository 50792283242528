<back-button></back-button>
<section style="padding: 0px 16px">
  <h3>Data Exports</h3>

  <tgm-loadable [model]="loadingProjects">
    <div class="empty-message" *ngIf="projects?.length == 0">No exports</div>
    <mat-list>
      <mat-list-item class="region" *ngFor="let project of projects">
        <div class="list-item-full-center" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex>
            <div>{{ project.name }}</div>
            <div class="created-date">
              <span *ngIf="project.status === 'ready'">Ready</span>
              <span *ngIf="project.status === 'error'"><span style="color: #ff0000">Error</span></span>
              <span *ngIf="!(project.status === 'ready' || project.status === 'error')"
                >Progress: {{ project.progress / project.total | percent }}
              </span>
              <span>{{ project.created | date : 'yyyy-MM-dd hh:MM' }}</span>
              <span>{{ getTypeName(project.type) }}</span>
            </div>
          </div>

          <button
            [disabled]="!(project.status === 'ready')"
            (click)="loadExport(project)"
            title="Download"
            mat-icon-button
          >
            <mat-icon class="mat-24">file_download</mat-icon>
          </button>

          <button (click)="deleteExport(project)" title="Delete" mat-icon-button>
            <mat-icon class="mat-24">delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </tgm-loadable>
</section>
