import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { Indicators } from '@targomo/client'
import { SavedSessionEndpoint } from '../api/savedSession'
import { UserSettingsEndpoint } from '../api/userSettings'
import { DisplaySettings } from '../model/settingsModel'
import { SavedSessionModel } from '../model/savedSessionModel'

@Injectable()
export class ShareSessionResolver implements Resolve<any> {
  constructor(
    private savedSessionEndpoint: SavedSessionEndpoint,
    private savedSessionModel: SavedSessionModel,
    private indivators: Indicators,
    private userSettingsEndpoint: UserSettingsEndpoint
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      if (route.queryParams.session) {
        return await this.indivators.add(this.savedSessionEndpoint.accept(route.queryParams.session))
      } else {
        const defaultLoadSessionId = await this.userSettingsEndpoint.loadDefaultProject()
        if (defaultLoadSessionId != null) {
          const session = await this.indivators.add(this.savedSessionEndpoint.find({ id: defaultLoadSessionId }))

          if (session) {
            this.savedSessionModel.currentSession.next(session)
          }

          return session && session.data
        }
      }
    } catch (e) {
      // ignore whatever happens here and continue loading the app
      console.error(e)
    }
    return null
  }
}
