import { DecimalPipe } from '@angular/common/'
import { Injectable } from '@angular/core'
import { Indicators, Auth } from '@targomo/client'
import { object as objects } from '@targomo/common'
import 'rxjs/add/operator/take'
import { Observable } from 'rxjs/Observable'
import { DEFAULT_COPYRIGHT, Settings } from '../../../common/models/settings'
import { AllStatistics } from '../../../common/models/statistics/statistics'
import { RiskAssessmentEndpoint } from '../../api/riskAssessment'
import { ZoneLayersEndpoint } from '../../api/sectors'
import { SettingsEndpoint } from '../../api/settings'
import { UserEndpoint } from '../../api/user'
import { UserSettingsEndpoint } from '../../api/userSettings'
import { AppModel } from '../../model/appModel.service'
import { AbstractLocation } from '../../model/entities/place'
import { PdfReportSettings } from '../../model/settingsModel'
import { ZoneLayersModel } from '../../model/zoneLayersModel'
import { APP_BASE_PATH } from '../../util/basePath'
import { competitionReport, placesByMinutes } from './competition'
import { competitionPopulationRatios } from './competitionRatios'
import { competitionSummary } from './competitionSummary'
import demographicsReport from './demographics'
import { CustomImageData, reportFooter, reportHeader } from './headerFooter'
import { PDFHelper } from './helper'
import { homePage } from './homePage'
import { lifestyleSpendReport, lifestyleSpendReportComparisonGoods } from './lifestyleSpend'
import { mapReport } from './mapReport'
import { populationReport } from './population'
import * as reportModel from './reportModel'
import { spendReport } from './spend'
import { spendSummaryReport } from './spendSummary'
import { createStyle } from './styles'
import { travelLabel } from './travelLabel'
import { workforceReport } from './workforce'
import { ensureSuffix } from '../../util/fileNameUtil'
import { generations } from './generations'
import { PDF_REPORT_SPEND_COLLIERS } from './values'
import { postcodeAnalysisReport } from './postcodeAnalysis'
import { PostcodeAnalysisModel } from '../../model/postcodeAnalyssModel'
import { take } from 'rxjs/operators'

const pdfMake = require('pdfmake/build/pdfmake.js')
const pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

export type ExportMapImage = (mode: 'hidden' | 'competition') => Promise<{ imageData: string }>

export type ReportModel = {
  minutes: number
  name: string
  nameFull: string
  nameFullFromTo: string
  nameFullFromToExclusive: string
  statistics: AllStatistics
}[]

const TABLE_BACKGROUNDS = ['#e7f1f9', '#fbfdf9']
const TABLE_BORDERS = ['#548235', '#a9d08e', '#e2efda']

@Injectable()
export class ExportPDFReport {
  private helper: PDFHelper

  constructor(
    private userEndpoint: UserEndpoint,
    private auth: Auth,
    private settingsEndpoint: SettingsEndpoint,
    private appModel: AppModel,
    private pipe: DecimalPipe,
    private indicators: Indicators,
    private zoneLayersModel: ZoneLayersModel,
    private zoneLayersEndpoint: ZoneLayersEndpoint,
    private riskAssessmentEndpoint: RiskAssessmentEndpoint,
    private userSettingsEndpoint: UserSettingsEndpoint,
    private postcdeAnalysisMOdel: PostcodeAnalysisModel
  ) {
    this.helper = new PDFHelper(pipe)
  }

  async initIcons(helper: PDFHelper) {
    const user = await this.userEndpoint.me()
    const customLogoPath = APP_BASE_PATH + `api/users/${user.id}/companypicture/public`

    const [imageData, imageHome, imagePeople, customLogo, imageCar] = await Promise.all([
      helper.convertImgToDataURLviaCanvas(APP_BASE_PATH + 'api/settings/logo/' + user.id, 'image/png'),
      helper.convertImgToDataURLviaCanvas(APP_BASE_PATH + 'assets/images/icon_home.png', 'image/png'),
      helper.convertImgToDataURLviaCanvas(APP_BASE_PATH + 'assets/images/icon_people.png', 'image/png'),
      helper.convertImgToDataURLviaCanvas(customLogoPath, 'image/png'),
      helper.convertImgToDataURLviaCanvas(APP_BASE_PATH + 'assets/images/car-side-solid.png', 'image/png'),
    ])

    helper.setIcons({
      logo: imageData.data,
      home: imageHome.data,
      people: imagePeople.data,
      customLogo: customLogo.data,
      car: imageCar.data,
    })

    return [imageData, imageHome, imagePeople, customLogo]
  }

  async save(exportMapImage: ExportMapImage, options?: { title?: string; fileName?: string }) {
    const result = await reportModel.calculate(this.appModel, this.indicators)
    if (!result.length) {
      return
    }

    const places = this.appModel.places.reachableFilteredPlacesNonPassive.getValue()
    const planningPlaces = this.appModel.places.reachableFilteredPlanningNonPassive.getValue()

    const userSettings = await this.userSettingsEndpoint.loadReport()
    const mapPromise = exportMapImage(
      userSettings.reportTemplate && userSettings.reportTemplate.shortMapCompetition ? 'competition' : 'hidden'
    )

    const fileName = options && options.fileName ? ensureSuffix(options.fileName, '.pdf') : 'storepointgeo_report.pdf'
    return this.generateReport(
      result,
      fileName,
      places,
      planningPlaces,
      (await mapPromise).imageData,
      options && options.title
    )
  }

  private async generateReport(
    reportModel: ReportModel,
    fileName: string,
    places: AbstractLocation[],
    planningPlaces: AbstractLocation[],
    mapImageData: string,
    title: string = ''
  ) {
    const me = await this.auth.me()
    let permissions: any = {}
    if (me) {
      permissions = (<any>me).permissions
    }

    const userSettings = await this.userSettingsEndpoint.loadReport()
    const reportSettings: PdfReportSettings = (userSettings && userSettings.reportTemplate) || <any>{}

    // const user = await this.userEndpoint.me()
    // const customLogoPath = APP_BASE_PATH + `api/users/${user.id}/companypicture/public`
    const settings = await this.settingsEndpoint.getPublic()
    const [zonesVisible] = await Observable.combineLatest(this.zoneLayersModel.selectionExistsUpdates)
      .take(1)
      .toPromise()

    const sources = this.appModel.places.sources.getValues()

    const [imageData, imageHome, imagePeople, customLogo] = await this.initIcons(this.helper)

    return await this.generateReportRun(
      places,
      sources,
      reportModel,
      imageData.data,
      customLogo,
      settings,
      zonesVisible,
      fileName,
      mapImageData,
      planningPlaces,
      reportSettings,
      title,
      permissions
    )
  }

  async pdfGeneralSettings() {
    const userSettings = await this.userSettingsEndpoint.loadReport()
    const reportSettings: PdfReportSettings = (userSettings && userSettings.reportTemplate) || <any>{}

    // const user = await this.userEndpoint.me()
    // const customLogoPath = APP_BASE_PATH + `api/users/${user.id}/companypicture/public`
    const settings = await this.settingsEndpoint.getPublic()
    const [zonesVisible] = await Observable.combineLatest(this.zoneLayersModel.selectionExistsUpdates)
      .take(1)
      .toPromise()

    const [imageData, imageHome, imagePeople, customLogo] = await this.initIcons(this.helper)

    return {
      logo: imageData.data,
      customLogo,
      settings,
      zonesVisible,
      reportSettings,
    }
  }

  async saveBatch(exportMapImage: ExportMapImage) {
    const zones = this.zoneLayersModel.snapshot()

    const userSettings = await this.userSettingsEndpoint.loadReport()

    for (let zone of zones) {
      const mapPromise = exportMapImage(
        userSettings.reportTemplate && userSettings.reportTemplate.shortMapCompetition ? 'competition' : 'hidden'
      )
      // const mapPromise = exportMapImage()
      const result = await reportModel.calculateZone(zone, this.appModel, this.indicators, this.zoneLayersEndpoint)
      const geometry = await this.zoneLayersEndpoint.getGeometries(zone.layer, zone.features)
      const places = await this.appModel.places.findReachablePlaces(geometry, false)
      const planningPlaces = await this.appModel.places.findReachablePlaces(geometry, true)
      // this.appModel.places.reachableFilteredPlaces.getValue()

      await this.generateReport(
        result,
        'storepointgeo_report_' + zone.name + '.pdf',
        places,
        planningPlaces,
        (
          await mapPromise
        ).imageData
      )
    }
  }

  private async generateReportRun(
    places: AbstractLocation[],
    sources: AbstractLocation[],
    reportModel: ReportModel,
    imageData: string,
    customImage: CustomImageData,
    settings: Settings,
    zonesVisible: boolean,
    fileName: string,
    mapImageData: string,
    planningPlaces: AbstractLocation[],
    reportSettings: PdfReportSettings,
    title = '',
    permissions: any
  ) {
    this.helper.setBorderColor(reportSettings && reportSettings.borderColor)
    const edgeWeights = await this.appModel.settings.edgeWeightsUpdates.take(1).toPromise()
    const travelMode = travelLabel((await this.appModel.settings.travelOptionsUpdates.take(1).toPromise()).travelType)
    const intersectionExclusiveMode = await this.appModel.settings.intersectionExclusiveModeUpdates.take(1).toPromise()
    const maxEdgeWeight = (await this.appModel.settings.travelOptionsUpdates.take(1).toPromise()).maxEdgeWeight
    const minutesGroupsCount = 5
    const minutesGroup = Math.ceil(edgeWeights[0].value / minutesGroupsCount / 60) * 60
    const householdsOrCapita = await this.appModel.settings.reportPerCapitaHouseholdsUpdates.take(1).toPromise()
    const sourcesTitles = await this.appModel.titles.value$.take(1).toPromise()

    const groupsTotals = placesByMinutes(places, zonesVisible, minutesGroup, undefined, maxEdgeWeight / 60)
    const groups = groupsTotals.groups
    const totals = groupsTotals.totals

    const planningGroupsTotals = placesByMinutes(
      planningPlaces,
      zonesVisible,
      minutesGroup,
      undefined,
      maxEdgeWeight / 60
    )

    function hasSection(key: string) {
      return reportSettings && (reportSettings.sections || []).indexOf(key) > -1
    }

    const copyright = [(settings && settings.copyright) || DEFAULT_COPYRIGHT].join(', ')

    let planningReportSection: any[] = []
    const hasPlanning = planningPlaces.length > 0 && !zonesVisible
    if (hasPlanning) {
      const planningFooterItems: any[] = objects.values(
        (sources || []).reduce((acc, cur) => {
          if (cur.country === 'GB') {
            acc['GB'] = '* Source: Barbour ABI'
          } else if (cur.country === 'ROI') {
            acc['ROI'] = '* Source: CIS Ireland'
          }
          return acc
        }, {} as any)
      )

      const planningFooter = planningFooterItems.length ? [{ stack: planningFooterItems, style: 'footnote' }] : []

      planningReportSection = []
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'headerHuge',
            text: 'PLANNING APPLICATIONS',
            absolutePosition: { x: 30, y: 360 },
          },
        ])
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'pageHeader',
            text: zonesVisible ? 'Planning Applications in zone' : 'Planning Applications by ' + travelMode + 'Time',
          },
        ])
        .concat(
          <any[]>(
            competitionSummary(reportModel, planningPlaces, sources, groupsTotals.totals, this.helper, zonesVisible)
          )
        )
        .concat(planningFooter)
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'pageHeader',
            text: zonesVisible ? 'Planning Applications in zone' : 'Planning Applications by ' + travelMode + 'Time',
          },
        ])
        .concat(
          competitionReport(
            planningPlaces,
            planningGroupsTotals.groups,
            null,
            planningGroupsTotals.totals,
            this.helper,
            zonesVisible,
            reportSettings && reportSettings.competitionColumns,
            true,
            undefined,
            undefined,
            undefined,
            false
          )
        )
        .concat(JSON.parse(JSON.stringify(planningFooter))) // I have a feeling pdfmake doesn't like reusing objects
    }

    const hasWorkforce =
      reportModel[reportModel.length - 1].statistics.census.workerAges.total ||
      reportModel[reportModel.length - 1].statistics.census.socialClassWorkforce.total ||
      reportModel[reportModel.length - 1].statistics.census.occupations.total

    let content: any[] = []

    let homePageImage = imageData
    if (reportSettings.customLogo) {
      homePageImage = customImage.data || imageData
    }

    function formatHomePageTitle() {
      if (title) {
        return title
      }

      const zoneName = zonesVisible ? reportModel[0].nameFull + ' - ' : ''
      let homePageTitle = [
        zoneName ? null : travelMode + 'Time',
        'Competition',
        'Demographic',
        'Spend',
        hasWorkforce ? 'Workforce' : null,
        hasPlanning && hasSection('planningApplications') ? 'Planning Report' : null,
      ].filter((part) => !!part)

      if (hasSection('hideCompetition') && hasSection('colliersDemographics') && hasSection('colliersSpend')) {
        // TODO: can be better
        return 'Socio-Demographic Profile'
      }

      return (
        zoneName +
        homePageTitle.slice(0, homePageTitle.length - 1).join(', ') +
        ' & ' +
        homePageTitle[homePageTitle.length - 1] +
        '.'
      )
    }

    const travelTitle = zonesVisible ? null : travelMode + 'Time'

    content = content.concat(
      homePage(
        this.helper,
        sources,
        sourcesTitles,
        homePageImage,
        zonesVisible ? reportModel[0].nameFull : null,
        travelMode,
        formatHomePageTitle(),
        intersectionExclusiveMode
      )
    )

    if (hasSection('mapPage')) {
      let text = ''
      if (sources.length === 1) {
        const source = sources[0]
        text = ': ' + [source.defaultName, source.town, source.postcode].filter((item) => !!item).join(' | ')
      } else if (sources.length > 1) {
        text = ': Multiple Locations'
      }

      content = content
        // .concat(<any>[{pageBreak: 'before', pageOrientation: 'landscape', style: 'headerInverse', text: 'Map'}])
        .concat([{ pageBreak: 'before', pageOrientation: 'landscape', text: '' }])
        .concat([this.helper.fullWidthHeader('Map' + text)])
        .concat(
          <any[]>await mapReport(
            reportModel,
            this.helper,
            mapImageData,
            edgeWeights.filter((item) => item.value <= maxEdgeWeight),
            travelTitle
          )
        )
    }

    if (hasSection('showCompetitionSummary') && !zonesVisible) {
      content = content
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'pageHeader',
            text: zonesVisible ? 'Competition in zone' : 'Competition by ' + travelMode + 'Time',
          },
        ])
        .concat(<any[]>competitionSummary(reportModel, places, sources, totals, this.helper, zonesVisible))
    }

    if (!hasSection('hideCompetition')) {
      content = content
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'pageHeader',
            text: zonesVisible ? 'Competition in zone' : 'Competition by ' + travelMode + 'Time',
          },
        ])
        .concat(
          <any[]>(
            competitionReport(
              places,
              groups,
              sources,
              totals,
              this.helper,
              zonesVisible,
              reportSettings && reportSettings.competitionColumns,
              false,
              undefined,
              undefined,
              undefined,
              false
            )
          )
        )
    }

    if (hasSection('populationPages')) {
      content = content
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'headerHuge',
            text: 'POPULATION',
            absolutePosition: { x: 30, y: 360 },
          },
        ])
        .concat(<any[]>populationReport(reportModel, this.helper, travelTitle))
    }

    content = content
      .concat(<any>[
        {
          pageOrientation: hasSection('colliersDemographics') ? 'portrait' : 'landscape',
          pageBreak: 'before',
          style: 'pageHeader',
          text: 'Demographics',
        },
      ])
      .concat(demographicsReport(reportModel, this.helper, zonesVisible, reportSettings))

    if (hasSection('colliersSpend')) {
      content = content
        .concat(<any>[{ pageOrientation: 'portrait', pageBreak: 'before', style: 'pageHeader', text: 'Spend' }])
        .concat(
          demographicsReport(reportModel, this.helper, zonesVisible, reportSettings, PDF_REPORT_SPEND_COLLIERS as any)
        )
    }

    if (!hasSection('hideCompetitionRatios')) {
      content = content
        .concat(<any>[{ pageBreak: 'before', style: 'header2', text: 'Competition & Population Ratios' }])
        .concat(competitionPopulationRatios(reportModel, groups, this.helper))
    }

    content = content
      .concat(<any>[
        {
          pageBreak: 'before',
          pageOrientation: 'landscape',
          style: 'pageHeader',
          text:
            // TODO not super nice
            hasSection('hideCompetition') && hasSection('colliersDemographics') && hasSection('colliersSpend')
              ? 'LifePro Segmentation'
              : 'Lifestyle Spend & Data',
        },
      ])
      .concat(lifestyleSpendReport(reportModel, this.helper), {
        text: `LifePro is a proprietary household demographic classification created by Aspinall + Aspinall, using data from ONS. It is a matrix of households based on income
      and life stage. Income is in five groups from 1 (most affluent) to 5 (least affluent). They are the columns in the table. The life stages are in four groups, Pre-Family,
      Families, Empty Nesters, and Retired/Seniors, forming the rows in the table.`,
        style: 'hint',
      })

    if (!hasSection('hideComparisonGoodsSpend')) {
      content = content
        .concat(<any>[
          { pageBreak: 'before', pageOrientation: 'landscape', style: 'pageHeader', text: 'Comparison Goods Spend' },
        ])
        .concat(lifestyleSpendReportComparisonGoods(reportModel, this.helper))
    }

    if (!hasSection('hideSpendSummary')) {
      content = content
        .concat(<any>[
          { pageBreak: 'before', pageOrientation: 'landscape', style: 'pageHeader', text: 'Spend Summary' },
        ])
        .concat(spendSummaryReport(reportModel, this.helper, householdsOrCapita))
    }

    if (!hasSection('hideSpendDetail')) {
      content = content
        .concat(<any>[{ pageBreak: 'before', pageOrientation: 'portrait', style: 'pageHeader', text: 'Spend Detail' }])
        .concat(spendReport(reportModel, this.helper, householdsOrCapita))
    }

    if (hasSection('workforcePages') && hasWorkforce) {
      content = content
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'headerHuge',
            text: 'WORKFORCE',
            absolutePosition: { x: 30, y: 360 },
          },
        ])
        .concat(<any[]>workforceReport(reportModel, this.helper))
    }

    if (hasSection('planningApplications')) {
      content = content.concat(planningReportSection)
    }

    if (hasSection('generations')) {
      content = content
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'pageHeader',
            text: 'Generations',
          },
        ])
        .concat(<any[]>generations(reportModel, this.helper))
    }

    if (hasSection('postcodeAnalysis') && permissions.postcodesAnalysis) {
      content = content
        .concat(<any>[
          {
            pageBreak: 'before',
            pageOrientation: 'portrait',
            style: 'pageHeader',
            text: 'Existing Customer Analysis',
          },
        ])
        .concat(
          <any[]>postcodeAnalysisReport(await this.postcdeAnalysisMOdel.value$.pipe(take(1)).toPromise(), this.helper)
        )
    }

    const docDefinition: any = {
      footer: reportFooter(copyright, customImage, reportSettings.shortFooter),
      header: reportHeader(imageData, zonesVisible ? null : travelMode + 'Time Report'),
      content: content,
      styles: createStyle(reportSettings),
      pageMargins: [30, 80, 30, 80],
      pageSize: 'A4',
    }

    pdfMake.createPdf(docDefinition).download(fileName)
  }

  private getLogoImage() {}

  // private homePage(sources: Place[], imageData: string, zoneName: string): any[] {
  //   const sourcesRows = sources.map(source => {
  //     const text = [source.fascia, source.town, source.postcode].filter(item => !!item).join(' | ')
  //     return {style: "homeHeader1", text}
  //   })

  //   const title = (zoneName ? (zoneName + ' - ') : '') + 'Travel Time, Competition, Demographic, Spend, Workforce & Planning Report.'

  //   return [].concat([
  //     {image: imageData, width: 260, style: 'logoCenter'},
  //   ]).concat(sourcesRows)
  //   .concat([
  //     {style: "homeHeader2", text: title, margin: [40, 40, 40, 40]},
  //     {text: this.helper.formatDate(new Date()), style: 'frontPageDate'},
  //     {style: "copyright", pageBreak: 'after', text: ''}
  //   ])
  // }
}
