<section style="padding: 0px 16px">
  <h3>Matchpoint Selection Criteria</h3>

  <tgm-loadable [model]="loadingProjects">
    <div class="empty-message" *ngIf="projects?.length == 0">No selection criteria</div>
    <mat-list>
      <mat-list-item class="region" *ngFor="let project of projects">
        <div class="list-item-full-center" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex>
            <div>{{ project.name }}</div>
            <div class="created-date">
              <span>{{ project.created | date: 'yyyy-MM-dd' }}</span>
            </div>
          </div>

          <button (click)="loadExport(project)" title="Download" mat-icon-button>
            <mat-icon class="mat-24">file_download</mat-icon>
          </button>

          <button (click)="deleteExport(project)" title="Delete" mat-icon-button>
            <mat-icon class="mat-24">delete</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </tgm-loadable>

  <div class="buttons-panel" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
    <button matTooltip="Back" matTooltipPosition="below" mat-button type="button" (click)="clickBack()">
      <mat-icon class="arrow-icon" mat-font-set="material-icons">arrow_back</mat-icon> <span>Back</span>
    </button>
  </div>
</section>
