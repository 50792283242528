import { PdfReportSettings } from '../../model/settingsModel'

const TABLE_FONT_SIZE = 7
export const DEFAULT_CORPORATE_COLOR = '#548235'

export function createStyle(options: PdfReportSettings) {
  const CORPORATE_COLOR = (options && options.borderColor) || DEFAULT_CORPORATE_COLOR

  return {
    homeHeader1: {
      margin: [0, 20, 0, 20],
      alignment: 'center',
      fontSize: 22,
    },

    homeHeader4: {
      margin: [0, 2, 0, 2],
      alignment: 'center',
      fontSize: 22,
    },

    homeHeader2: {
      margin: [0, 50, 0, 70],
      alignment: 'center',
      fontSize: 22,
      color: '#444444',
    },

    homeHeader3: {
      margin: [0, 10, 0, 10],
      alignment: 'center',
      fontSize: 22,
      color: '#444444',
    },

    copyright: {},

    defaultTable: {},

    alignRight: {
      alignment: 'right',
    },

    alignCenter: {
      alignment: 'center',
    },

    defaultCell: {
      margin: [3, 3, 3, 3],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellBold: {
      margin: [3, 3, 3, 3],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE,
      bold: true,
    },

    defaultCell2: {
      margin: [3, 0, 3, 0],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE + 1,
    },

    defaultCellBiggerFont: {
      margin: [2, 2, 2, 2],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE + 2,
    },

    defaultCellMini: {
      margin: [2, 2, 2, 2],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellMicro: {
      margin: [0, 0, 0, 0],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE,
    },

    footnote: {
      margin: [3, 3, 3, 3],
      fontSize: TABLE_FONT_SIZE,
    },

    // CORPORATE_COLOR
    largeCellGroupFirstInverse: {
      margin: [3, 3, 3, 3],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE + 4,
      bold: true,
      color: '#ffffff',
    },

    largeCellGroupInverse: {
      margin: [3, 3, 3, 3],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE + 4,
      bold: true,
      color: '#ffffff',
    },

    largeCellGroupFirst: {
      margin: [3, 3, 3, 3],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE + 4,
      bold: true,
    },

    largeCellGroup: {
      margin: [3, 3, 3, 3],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE + 4,
      bold: true,
    },

    largeCellGroupUnderline: {
      margin: [3, 3, 3, 3],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE + 4,
      bold: true,
      decoration: 'underline',
    },

    defaultCellGroup: {
      margin: [3, 3, 3, 3],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE + 1,
      bold: true,
    },

    postcodesCellGroup: {
      margin: [3, 3, 3, 3],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE + 4,
      color: '#000000',
      bold: true,
    },

    postcodesHint: {
      margin: [3, 6, 3, 6],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE + 1,
    },

    defaultCellRight: {
      margin: [3, 3, 3, 3],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellRightBold: {
      margin: [3, 3, 3, 3],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE,
      bold: true,
    },

    defaultCellRightMini: {
      margin: [2, 2, 2, 2],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellColorBox: {
      margin: [0, 3, 0, 3],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellColorBoxCenter: {
      margin: [0, 3, 0, 3],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellColorBoxCenter2: {
      margin: [0, 2, 0, 2],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellLeftNoMargin: {
      margin: [0, 3, 0, 3],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellLeft: {
      margin: [3, 3, 3, 3],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellLeft2: {
      margin: [2, 2, 2, 2],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellLeftMini: {
      margin: [2, 2, 2, 2],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellCenter: {
      margin: [3, 3, 3, 3],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellCenterMini: {
      margin: [2, 2, 2, 2],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE,
    },

    headerIcon2: {
      margin: [3, 0, 3, 0],
      alignment: 'right',
    },

    graphCellRight: {
      margin: [3, 0, 3, 0],
      alignment: 'right',
      fontSize: TABLE_FONT_SIZE,
    },

    graphCellLeft: {
      margin: [3, 0, 3, 0],
      alignment: 'left',
      fontSize: TABLE_FONT_SIZE,
    },

    totalCell: {
      margin: [3, 3, 3, 3],
      alignment: 'right',
      bold: true,
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellFirst: {
      margin: [3, 3, 3, 3],
      fontSize: TABLE_FONT_SIZE,
    },

    defaultCellFirst2: {
      margin: [3, 0, 3, 0],
      fontSize: TABLE_FONT_SIZE + 1,
    },

    percentHeaderLeft: {
      margin: [3, 3, 3, 3],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE,
      color: '#888888',
    },

    percentHeaderRight: {
      margin: [3, 3, 3, 3],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE,
      color: '#888888',
    },

    percentHeaderCenter: {
      margin: [3, 3, 3, 3],
      alignment: 'center',
      fontSize: TABLE_FONT_SIZE,
      color: '#888888',
    },

    logoCenter: {
      margin: [0, 70, 0, 40],
      alignment: 'center',
    },

    logoLegend: {
      margin: [0, 0, 0, 20],
      alignment: 'left',
    },

    logoFooter: {
      margin: [2, 2, 2, 2],
      alignment: 'right',
    },

    header: {
      fontSize: 19,
      bold: true,
      margin: [0, 20, 0, 40],
      decoration: 'underline',
      decorationColor: '#fc7bea',
    },

    pageHeader: {
      fontSize: 19,
      bold: true,
      margin: [0, 0, 0, 40],
      decoration: 'underline',
      decorationColor: '#fc7bea',
    },

    headerHuge: {
      fontSize: 30,
      bold: true,
      color: CORPORATE_COLOR,
    },

    graphHeader: {
      fontSize: 16,
      bold: true,
      alignment: 'center',
    },

    headerInverse: {
      fontSize: 19,
      bold: true,
      margin: [0, 0, 0, 0],
      width: '100%',
      color: '#ffffff',
      background: CORPORATE_COLOR,
    },

    headerInverse2: {
      fontSize: 19,
      bold: true,
      margin: [0, 0, 0, 0],
      width: '100%',
      color: '#ffffff',
    },

    frontPageDate: {
      fontSize: 16,
      alignment: 'center',
      margin: [0, 20, 0, 20],
    },

    header2: {
      fontSize: 16,
      bold: true,
      margin: [0, 20, 0, 4],
    },

    subheader: {
      fontSize: 16,
      bold: true,
      margin: [0, 20, 0, 4],
    },

    pageNumber: {
      fontSize: 10,
      alignment: 'center',
    },

    contentFooter: {
      fontSize: 10,
      margin: [30, 40, 30, 20],
    },

    contentFooter2: {
      fontSize: 10,
      alignment: 'right',
      margin: [30, 0, 30, 0],
    },

    contentHeader: {
      fontSize: 13,
      margin: [30, 20, 30, 40],
    },

    headerMap: {
      fontSize: 14,
      bold: true,
      margin: [0, 0, 0, 4],
      // decoration: 'underline',
      // decorationColor: '#fc7bea',
    },

    legendSection: {
      margin: [0, 0, 0, 20],
    },

    legendStyle: {
      width: 160,
      fillColor: '#ffffff',
      margin: [3, 3, 3, 3],
    },

    mapLegendTableRow: {
      fillColor: '#ffffff',
      background: '#ffffff',
    },

    mapLegendMainLayout: {},

    carePageHeader: {
      fontSize: 15,
      bold: true,
      margin: [0, 0, 0, 10],
      decoration: 'underline',
      decorationColor: '#fc7bea',
    },

    careTableHeader: {
      fontSize: 12,
      bold: true,
      // margin: [0, 20, 0, 40],
      decoration: 'underline',
      decorationColor: '#fc7bea',
    },

    careTableSubHeader: {
      fontSize: 12,
      bold: true,
      // margin: [0, 20, 0, 40],
      decoration: 'underline',
      decorationColor: '#fc7bea',
    },

    careDescription: {
      fontSize: 11,
      color: '#444444',
      lineHeight: 1.15,
    },

    hint: {
      margin: [0, 10, 0, 0],
      fontSize: 9,
      color: '#444444',
      lineHeight: 1.15,
    },
  }
}
