import {Component, Input, OnInit, OnChanges, SimpleChange} from '@angular/core'
import {MAP_STYLES, SubscriberComponent} from '@targomo/client'
import {StatisticsKey} from '@targomo/core'
import {STATISTICS_WITH_NONE, STATISTICS, STATISTICS_MAP} from '../../model/constants';
import {AppModel} from '../../model/appModel.service';
import { Observable } from 'rxjs/Observable';
import { ZoneLayersEndpoint } from '../../api/sectors';

@Component({
  selector: 'zone-layer-switch',
  template: `
    <mat-list-item class="select-boundaries" title="Boundaries Layer">
      <div fxFlex *ngIf="label">Boundaries Layer</div>

      <mat-select fxFlex [ngModel]="selected" (ngModelChange)="changeStatistic($event)">
      <mat-option title="None" [value]="-1">None</mat-option>
      <mat-option title="{{option.name}}" *ngFor="let option of listOptions" [value]="option.id">{{option.name}}</mat-option>
      </mat-select>
    </mat-list-item>

    <mat-list-item>
      <div fxFlex>Show only user zones</div>

      <mat-slide-toggle [ngModel]="appModel.settings.showOnlyUserZonesUpdates | async" (ngModelChange)="changeShowOnlyUser($event)">
      </mat-slide-toggle>
    </mat-list-item>    
  `,
  styles: [`
    :host {
      display: block;
    }

    .mat-select-underline {
      background: none !important;
    }

    .mat-list-item-content {
      padding: 0px !important;
    }
  `]
})
export class ZoneLayerSwitchComponent extends SubscriberComponent {
  @Input() label: boolean

  listOptions: any[]
  selected:any  = null

  constructor(readonly appModel: AppModel, private zoneLayersEndpoint: ZoneLayersEndpoint) {
    super()
    this.initOptions()
  }

  async initOptions() {
    this.listOptions = await this.zoneLayersEndpoint.me()

    this.watch(this.appModel.displaySettings.getValue().zoneLayer, selected => {
      this.selected = selected == null ? -1 : selected
    })
  }

  changeStatistic(value: number) {
    this.appModel.displaySettings.nextProperty('zoneLayer', value < 0 ? null : value)
  }

  changeShowOnlyUser(value: boolean) {
    this.appModel.displaySettings.nextProperty('showOnlyUserZones', value)
  }
}