import './newLocationDialog.less'

import { Component, Input, OnInit, OnChanges, SimpleChange, Optional, Inject } from '@angular/core'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material'

export interface MapDialogOptions {
  title: string
  legend: boolean
}

@Component({
  selector: 'map-print-dialog',
  template: `
    <section style="min-width: 400px">
      <h3 mat-dialog-title translate="layers.addLocation">Optional Report Title</h3>

      <form (ngSubmit)="save()">
        <div fxLayout="column">
          <mat-form-field *ngIf="showTitle" fxFlex>
            <input matInput [(ngModel)]="model.title" name="title" placeholder="Optional Title" />
          </mat-form-field>

          <mat-form-field fxFlex>
            <input matInput [(ngModel)]="model.fileName" name="fileName" placeholder="Optional File Name" />
          </mat-form-field>
        </div>

        <div class="dialog-bottom">
          <button mat-button (click)="dialogRef.close()" mat-dialog-close>
            <span translate="dialog.cancel">Cancel</span>
          </button>
          <button mat-raised-button color="primary" mat-button><span translate="dialog.save">Download</span></button>
        </div>
      </form>
    </section>
  `,
})
export class MapPrintDialog {
  showTitle = true
  model: any = <any>{
    legend: true,
  }

  constructor(
    public dialogRef: MatDialogRef<MapPrintDialog>,
    @Optional() @Inject(MAT_DIALOG_DATA) readonly dialogData: any
  ) {
    dialogData = dialogData || {}
    this.model.title = dialogData.title || ''
    this.model.fileName = dialogData.fileName || ''
    this.showTitle = dialogData.showTitle == null ? true : !!dialogData.showTitle
  }

  save() {
    this.dialogRef.close(this.model)
  }
}
