import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core'
import { DataSetLike } from '../../../../../common/models/dataSet'
import { CategoryLike } from '../../../../../common/models'

@Component({
  selector: 'admin-select-fascias',
  templateUrl: './adminSelectFascias.component.html',
  styleUrls: ['./adminSelectFascias.component.less'],
})
export class AdminSelectFasciasComponent {
  @Output() selectionChange = new EventEmitter<DataSetLike[]>()

  @Input() dataSets: DataSetLike[]
  @Input() allowGroupings: boolean = true
  @Input() allowFilters: boolean = true

  visibleDataSet: any = {}
  loading: Promise<DataSetLike[]>
  filter: string

  showOnlySelected = false

  constructor() {}

  // async ngOnInit(): Promise<void> {
  //   this.dataSets = await this.indicators.add(this.loading = this.dataSetsEndpoint.findAllFull())
  // }

  toggleDataSet(profile: DataSetLike) {
    this.visibleDataSet[profile.id] = !this.visibleDataSet[profile.id]
  }

  selectionUpdated(category: CategoryLike) {
    this.selectionChange.next(this.dataSets)
  }

  filterChanged(filter: string) {
    filter = (filter || '').toLowerCase()

    function contains(value: string, segment: string) {
      return (value || '').toLowerCase().indexOf(segment) > -1
    }

    setTimeout(() => {
      this.dataSets.forEach((dataSet) => {
        if (!this.showOnlySelected) {
          dataSet.filtered = dataSet.filteredChildren !== false || (filter && contains(dataSet.name, filter))
        } else {
          dataSet.filtered = dataSet.filteredChildren !== false
        }
      })
    }, 100)
  }

  showSelected() {
    this.showOnlySelected = !this.showOnlySelected
    this.filterChanged(this.filter)
  }

  clearAll() {
    this.dataSets.forEach((dataSet) => {
      dataSet.categories.forEach((category) => {
        category.selected = false
      })

      dataSet.categories = [].concat(dataSet.categories)
    })

    this.dataSets = [].concat(this.dataSets)
  }

  clearSelection() {
    this.filter = ''
    this.filterChanged('')
  }
}
