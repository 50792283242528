import { Injectable } from '@angular/core'
import { combineLatest, Observable, of, from } from 'rxjs'
import { map, shareReplay, switchMap, mergeMap, filter, debounceTime } from 'rxjs/operators'
import { PostcodeAnalysisEndpoint, PostcodeAnalysisReportGroup } from '../api/postcodeAnalysis'
import { AppModel } from './appModel.service'
import { GraphValue, RANDOM_COLORS } from '@targomo/client'
import { serializePlace } from './savedSessionModel'
import { canonicalPositions } from '../api/place'

export class PostcodeAnalysisReport {
  readonly groups: PostcodeAnalysisReportGroup[]
  readonly graphModel: GraphValue[]

  constructor(data: Partial<PostcodeAnalysisReport>) {
    Object.assign(this, data)

    this.graphModel = this.initGraphModel()
  }

  private initGraphModel(): GraphValue[] {
    if (!this.groups) {
      return null
    }

    const v1 = {
      key: '',
      values:
        this.groups.length > 0
          ? this.groups[this.groups.length - 1].categories
              .filter((item) => item.percent >= 0.05) // Exclude < 5%
              .map((category, i) => {
                return {
                  label: category.name,
                  xValue: i,
                  value: category.percent,
                }
              })
          : [],
    }

    return [v1] as GraphValue[]
  }
}

@Injectable()
export class PostcodeAnalysisModel {
  readonly promise$: Observable<Promise<PostcodeAnalysisReport>>
  readonly value$: Observable<PostcodeAnalysisReport>
  readonly categories$: Observable<Record<string, string>>

  constructor(private appModel: AppModel, private postcodeAnalysisEndpoint: PostcodeAnalysisEndpoint) {
    this.promise$ = of(null).pipe(
      mergeMap(() => this.initReport()),
      shareReplay(1)
    )

    this.value$ = this.promise$.pipe(
      switchMap((x) => x),
      shareReplay(1)
    )

    this.categories$ = from(this.initCategories())
  }

  private async initCategories() {
    const categories = await this.postcodeAnalysisEndpoint.colors()
    return Object.keys(categories).reduce((acc, cur) => {
      const color = categories[cur]
      acc[cur.slice(0, 4)] = color

      return acc
    }, {} as Record<string, string>)
  }

  private initReport() {
    return combineLatest(
      this.appModel.places.sources.observable,
      this.appModel.settings.travelOptionsUpdates,
      this.appModel.settings.intersectionExclusiveModeUpdates
    ).pipe(
      debounceTime(1),
      map(async ([sources, options, intersectionMode]) => {
        if (!sources || sources.length === 0) {
          return null
        }

        const visiblePlaces = intersectionMode === 'exclusive' ? await this.appModel.places.serializeVisible() : null

        return new PostcodeAnalysisReport(
          await this.postcodeAnalysisEndpoint.report({
            sources: canonicalPositions(sources).map((place) => {
              return {
                id: place.id,
                lat: place.lat,
                lng: place.lng,
                latAlt: place.latAlt,
                lngAlt: place.lngAlt,
              }
            }),
            options,
            intersectionMode,
            visiblePlaces,
          })
        )
      })
    )
  }
}
