<div class="title">Travel Time Filters</div>

<div style="margin-bottom: 32px">
  <div style="margin: 0px 8px">
    <mat-checkbox (change)="toggleShowStoresWithinTravelTime($event)" [checked]="showStoresWithinTravelTime$ | async"
      >Show only stores within travel time:</mat-checkbox
    >
  </div>
</div>

<div class="title">Filter by location type</div>
<table>
  <tr *ngFor="let option of options">
    <td (click)="toggle(option.id)">
      <mat-checkbox style="pointer-events: none" [checked]="values[option.id]">{{ option.name }}</mat-checkbox>
    </td>
  </tr>
</table>

<div style="margin: 16px 0px">
  'Out of Town' means Retail / Shopping Parks, Leisure Parks, Industrial / Trade Parks and Solus Units
</div>

<ng-container *ngIf="permissions && permissions.dateAddedFilter">
  <div class="title">Filter by Date Added:</div>
  <filter-dates></filter-dates>
</ng-container>
