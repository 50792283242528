<div class="section">
  <p>
    These options allow you to thematically display demographic, workforce and spend statistics through our hex
    geography.
  </p>
</div>

<div class="section">
  <div>
    <b>Select Map Statistic to Display</b>
    <statistic-switch></statistic-switch>
  </div>
</div>

<!-- <mat-select [disabled]="disabledTravelDisplay" [disabled]="appModel.settings.exclusiveTravelUpdates | async" style="width: 110px" [ngModel]="(appModel.settings.displaySettings | async).travelDisplayMode" (ngModelChange)="changeTravelDisplayMode($event)"> -->

<div class="section">
  <mat-radio-group
    [disabled]="disabledTravelDisplay$ | async"
    [value]="(settings.displaySettings | async).travelDisplayMode"
    (change)="changeSelection($event)"
  >
    <div class="option-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
      <div fxFlex>
        <mat-radio-button [value]="options[0].value"
          ><b>{{ options[0].name }}</b></mat-radio-button
        >
        <p>Standard Travel-Time display, no thematic mapping.</p>
      </div>
      <option-image image="options_travel_isochrones.png"></option-image>
    </div>

    <div class="option-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
      <div fxFlex>
        <mat-radio-button [value]="options[1].value"
          ><b>{{ options[1].name }}</b></mat-radio-button
        >
        <p>
          This options thematically shades the selected map statistic (see above) using our hex geography to create a
          heat map.
        </p>
        <p>If a travel-time has been generated it inverts the area to display the thematic within the travel-time.</p>
        <p>If no travel-time has been generated then only the thematic map is displayed.</p>
      </div>
      <option-image image="options_travel_inverted.png"></option-image>
    </div>

    <div class="option-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
      <div fxFlex>
        <mat-radio-button [value]="options[2].value"
          ><b>{{ options[2].name }}</b></mat-radio-button
        >
        <p>
          This option retains the thematic mapping but shades up the hexes by travel-time colours for the travel-time
          area.
        </p>
      </div>
      <option-image image="options_travel_hex.png"></option-image>
    </div>
  </mat-radio-group>
</div>

<div class="section">
  <div class="option-line" *ngIf="parent.permissions && parent.permissions.thematicPedestrian">
    <mat-checkbox
      [ngModel]="settings.displaySettings.getValue().pedestrianLayer"
      (ngModelChange)="changePedestrianLayer($event)"
    >
      <b>Show pedestrian thematic layer</b>
    </mat-checkbox>
  </div>

  <div class="option-line" *ngIf="parent.permissions && parent.permissions.thematicWorkforce">
    <mat-checkbox
      [ngModel]="settings.displaySettings.getValue().workforceLayer"
      (ngModelChange)="changeWorkforceLayer($event)"
    >
      <b>Show workforce thematic layer</b>
    </mat-checkbox>
  </div>

  <div class="option-line" *ngIf="parent.permissions && parent.permissions.roadsVolume">
    <mat-checkbox
      [ngModel]="settings.displaySettings.getValue().roadsVolumeLayer"
      (ngModelChange)="changeRoadsVolumeLayer($event)"
    >
      <b>Show roads volume thematic layer</b>
    </mat-checkbox>
  </div>

  <div
    class="option-line"
    *ngIf="parent.permissions && parent.permissions.roadsVolume && settings.displaySettings.getValue().roadsVolumeLayer"
  >
    <mat-checkbox
      [ngModel]="settings.displaySettings.getValue().roadsVolumeLayerViewportBreaks"
      (ngModelChange)="changeRoadsVolumeLayerBreaks($event)"
    >
      <b>Colorize roads volume thematic colors based on viewport</b>
    </mat-checkbox>
  </div>

  <div class="option-line" *ngIf="parent.permissions && parent.permissions.postcodesAnalysis">
    <mat-checkbox
      [ngModel]="settings.displaySettings.getValue().postcodesAnalysisLayer"
      (ngModelChange)="changePostcodesAnalysisLayer($event)"
    >
      <b>Show existing customers layer</b>
    </mat-checkbox>
  </div>
</div>
