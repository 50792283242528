import { Component } from '@angular/core';
import { SubscriberComponent } from '@targomo/client';
import { switchMap } from 'rxjs/operators';
import { AppModel } from '../../../../../model/appModel.service';
import { CareHomeReportModel } from '../../../../../model/careHome/careHomeModel';
import { SettingsModel } from '../../../../../model/settingsModel';

@Component({
  selector: 'care-home-mini-report',
  templateUrl: './careHomeMiniReport.component.html',
  styleUrls: ['./careHomeMiniReport.component.less']
})
export class CareHomeMiniReportComponent extends SubscriberComponent {
  readonly model: CareHomeReportModel
  // private report: CareHomeReport

  constructor(
    appModel: AppModel,
    readonly settings: SettingsModel,
  ) {
    super()

    this.model = appModel.careHomeReportModel
  }

  onYearChange(year: number) {
    this.settings.displaySettings.nextProperty('careHomeForecastYear', year)
  }

  overUnderSupplyText(value: number) {
    if (value < 0) {
      return 'under supply'
    } else if (value > 0) {
      return 'over supply'
    } else {
      return ''
    }
  }
}
