<p>Choose how you want to analyse travel times from one of the following methods.</p>

<mat-radio-group [ngModel]="settings.intersectionExclusiveModeUpdates | async" (change)="changeSelection($event)">
  <div class="option-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
    <div fxFlex>
      <mat-radio-button [value]="options[0].value"
        ><b>{{ options[0].name }}</b></mat-radio-button
      >
      <div>
        <p>This is the traditional and default method for analysing travel times.</p>
        <p>
          Where more than one travel time is created StorePointGeo will combine and group the data for all travel-times.
        </p>
      </div>
    </div>
    <option-image image="options_travel_combined.png"></option-image>
  </div>

  <div class="option-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
    <div fxFlex>
      <mat-radio-button [value]="options[1].value"
        ><b>{{ options[1].name }}</b></mat-radio-button
      >
      <div>
        <p>
          Where two or more travel times overlap, selecting this option will display the area of overlap, rather than
          the combined (above) travel-time area.
        </p>
        <p>All statistics (demgraphics, competiiton spend etc) displayed are based only on the area of overlap.</p>
      </div>
    </div>
    <option-image image="options_travel_overlapping.png"></option-image>
  </div>

  <div class="option-item" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
    <div fxFlex>
      <mat-radio-button [value]="options[2].value"
        ><b>{{ options[2].name }}</b></mat-radio-button
      >
      <div>
        <p>
          The Exclusive travel-time tool returns the travel-time area only for the area where the subject location is
          the nearest location vs mapped competition, i.e. any area where it is more convenient (quicker) to reach a
          competing store is exlcuded from the travel-time area.
        </p>
        <p>This is very useful for understanding canibilisation and the impact of store openings and closures.</p>
        <p>
          Note: typically this would be used to compare sites across a single brand, or a small selection of key
          competitors as all mapped points are treated as equal competition.
        </p>
        <p>
          A truly powerful tool, if you would like more information please contact us and we can demonstrate this in
          more detail.
        </p>
      </div>
    </div>
    <option-image image="options_travel_exclusive.png"></option-image>
  </div>
</mat-radio-group>
