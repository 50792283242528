
import {Component} from '@angular/core'
import {Auth, SubscriberComponent} from '@targomo/client';
import {Router, ActivatedRoute, ChildActivationEnd, ActivationEnd} from '@angular/router';
import { ProjectNumberModel } from '../../model/projectNumberModel';

@Component({
  selector: 'admin-component',
  templateUrl: './admin.html',
  styleUrls: ['./admin.less']
})
export class AdminComponent extends SubscriberComponent {
  wide = false

  constructor(
    readonly auth: Auth, 
    private router: Router, 
    private route: ActivatedRoute,
    private projectNumberModel: ProjectNumberModel
  ) {
    super()

    this.watch(router.events, ev => {
      if (ev instanceof ActivationEnd && !(ev.snapshot.component === AdminComponent)) {
        this.wide = !!ev.snapshot.data.wide
      }
    })
  }

  logout() {
    this.auth.logout()
    this.projectNumberModel.clearProjectNumber()
    this.router.navigate(['/login'])
    window.location.reload()
  }
}