import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { OptionsDialogComponent } from './optionsDialog/optionsDialog.component'

@Injectable()
export class OptionsDialogService {
  constructor(private dialog: MatDialog) {}

  async open(panel?: string) {
    const dialogRef = this.dialog.open(OptionsDialogComponent, {
      disableClose: false,
      width: '90vw',
      height: '90vh',
      maxWidth: '1150px',
      panelClass: 'options-dialog-container-marker-class',
      data: {
        panel,
      },
    })

    const result = await dialogRef.afterClosed().toPromise()
    if (result) {
    }
  }
}
