import { Component, OnInit, Optional, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Auth } from '@targomo/client'

@Component({
  selector: 'app-options-dialog',
  templateUrl: './optionsDialog.component.html',
  styleUrls: ['./optionsDialog.component.less'],
})
export class OptionsDialogComponent {
  navbarSections = [
    {
      key: 'styling',
      name: 'Styling',
      clickKey: 'map_style',
      classes: { level1: true, inactive: true },
    },
    { key: 'map_style', parent: 'Styling', name: 'Map Style', classes: { level2: true } },
    {
      key: 'travel_time_colours',
      name: 'Travel-Time Colours',
      parent: 'Styling',
      classes: { level2: true },
    },
    { key: 'point_colours', parent: 'Styling', name: 'Point Colours', classes: { level2: true } },
    {
      key: 'map_filters_section',
      clickKey: 'map_filters_primary',
      name: 'Map Filters',
      classes: { level1: true, inactive: true },
    },
    { key: 'map_filters_primary', parent: 'Map Filters', name: 'Primary Filters', classes: { level2: true } },
    { key: 'map_filters_secondary', parent: 'Map Filters', name: 'Secondary Filters', classes: { level2: true } },
    {
      key: 'travel_time_options_thematics',
      name: 'Travel-Time Options & Thematics',
      classes: { level1: true, inactive: true },
      clickKey: 'travel_time_method',
    },
    {
      key: 'travel_time_method',
      name: 'Travel-Time Method',
      parent: 'Travel-Time Options & Thematics',
      classes: { level2: true },
      permission: 'intersectionMode',
    },
    {
      key: 'max_travel_time',
      name: 'Max Travel-Time',
      parent: 'Travel-Time Options & Thematics',
      classes: { level2: true },
      permission: 'extendedTravel',
    },
    { key: 'thematics', name: 'Thematics', parent: 'Travel-Time Options & Thematics', classes: { level2: true } },
    {
      key: 'custom_label_options',
      name: 'Custom Label Options',
      classes: { level1: true },
    },

    {
      key: 'locations_present',
      name: 'Locations Present/Not Present',
      classes: { level1: true },
      permission: 'locationsInView',
    },
  ]

  selected = this.navbarSections[1]
  permissions: any

  constructor(
    readonly dialogRef: MatDialogRef<OptionsDialogComponent>,
    private auth: Auth,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
    if (dialogData && dialogData.panel) {
      this.selected = this.navbarSections.find((item) => item.key === dialogData.panel) || this.navbarSections[1]
    }

    this.initPermisions()
  }

  private async initPermisions() {
    const me = await this.auth.me()
    if (me) {
      this.permissions = (<any>me).permissions
    }
  }

  clickNav(selected: any) {
    const key = selected.clickKey || selected.key
    this.selected = this.navbarSections.find((option) => option.key === key)
  }

  close() {
    this.dialogRef.close()
  }
}
