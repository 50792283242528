import {ReportModel} from './exportPdfReport';
import {tableOptions, PDFHelper} from './helper';
import { PERSONICS_GROUPS } from '../mini/lifestylePersonicxMiniReport.component';
import { StatisticsValue, PersonicxStatisticsSummary } from '../../../common/models/statistics/statistics';

const enum WhichReport {
  HOUSEHOLDS,
  COMPARISON_GOODS
}

export function lifestyleSpendReport(reportModel: ReportModel, helper: PDFHelper) {
  return lifestyleSpendTable(reportModel, helper, WhichReport.HOUSEHOLDS)//.concat(lifestyleSpendTable(reportModel, helper))
}

export function lifestyleSpendReportComparisonGoods(reportModel: ReportModel, helper: PDFHelper) {
  return lifestyleSpendTable(reportModel, helper, WhichReport.COMPARISON_GOODS)//.concat(lifestyleSpendTable(reportModel, helper))
}

function lifestyleSpendTable(reportModel: ReportModel, helper: PDFHelper, whichReport: WhichReport) {
  const currencySymbol = reportModel[0].statistics.currencySymbol()
  let rows: any[] = []

  const LABELS = [
    'Pre Family',
    'Families',
    'Empty Nesters',
    'Retired',
  ]

  let heads: any[] = [
    [{text:'', border: [false, false, false, false]}],
  ]
  // let heads: any[] = [
  //   [{text:'', border: [false, false, false, false]}],
  //   [{text: '', group: true}],
  //   ['Lifestage'],
  // ]

  const addReport = (property: string, label: string = '', first: boolean = false, indices: boolean = false, thousands: boolean = false) => {
    rows = [
      [{text: '', group: true, style: 'defaultCellGroup'}],
      [{text: label, style: 'defaultCellGroup', rowBackground: '#ffffff'}],
      [{text: 'Lifestage', style: 'defaultCellLeft', rowBackground: '#ffffff'}],
    ]

    reportModel.forEach((report, i) => {
      if (first)
        heads[0] = heads[0].concat([{text: report.nameFull, style: 'defaultCellCenter', colSpan: 6, rowBackground: '#ffffff'}, '', '', '', '', ''])

      rows[0] = rows[0].concat([{text: 'Household Income Group', style: 'defaultCellCenter', colSpan: 5}, '', '', '', '', ''])
      rows[1] = rows[1].concat([{text: 'High', style: 'defaultCellCenter'}, {text: 'Ave', style: 'defaultCellCenter', colSpan: 3}, '', '', {text: 'Low', style: 'defaultCellCenter'}, ''])
      rows[2] = rows[2].concat(['1', '2', '3', '4', '5', 'Total' + (thousands ? ' (' + currencySymbol + '000)' : '')].map(row => ({text: row, style: 'defaultCellCenter'})))
    })

    PERSONICS_GROUPS.forEach((group, i) => {
      const row = [LABELS[i]]
      // const row = [group.name]

      reportModel.forEach((report) => {
        const statistics = <PersonicxStatisticsSummary>(<any>report.statistics.lifestyle.social)[property]
        // const statistics = report.statistics.lifestyle.personicxSummary
        const statistic: StatisticsValue[] = statistics[group.id]

        statistic.forEach(value => {
          if (indices) {
            let percent = (value.total / statistics.total) || 0
            let indexVsValue = (percent / value.index * 100)
            row.push(helper.formatCell(indexVsValue))
          } else if (thousands) {
            row.push(helper.formatCell(value.total / 1000))
          } else {
            row.push(helper.formatCell(value.total))
          }
        })

        if (indices) {
          let percent = (statistics.vertical[i] / statistics.total) || 0
          let indexVsValue = (percent / statistics.verticalIndices[i] * 100)
          row.push(helper.formatCell(indexVsValue))
        } else if (thousands) {
          row.push(helper.formatCell(statistics.vertical[i] / 1000))
        } else {
          row.push(helper.formatCell(statistics.vertical[i]))
        }
      })

      rows.push(row.map((row, i) => i == 0 ? row : ({text: row, style: 'defaultCellCenter'})))
    })

    const calculateTotals = () => {
      const row: any[] = [{text: 'Total' + (thousands ? ' (' + currencySymbol + '000)' : ''), style: 'defaultCell', total: true, group: true}]

      reportModel.forEach((report, i) => {
        const statistics = <PersonicxStatisticsSummary>(<any>report.statistics.lifestyle.social)[property]
        statistics.horizontal.forEach((value, i) => {
          if (indices) {
            let percent = (statistics.horizontal[i] / statistics.total) || 0
            let indexVsValue = (percent / statistics.horizontalIndices[i] * 100)
            row.push(helper.formatCell(indexVsValue))
          } else if (thousands) {
            row.push(helper.formatCell(value / 1000))
          } else {
            row.push(helper.formatCell(value))
          }
        })

        if (indices) {
          row.push(helper.formatCell(100))
        } else if (thousands) {
          row.push(helper.formatCell(statistics.total / 1000))
        } else {
          row.push(helper.formatCell(statistics.total))
        }
      })

      return row.map((row, i) => i == 0 ? row : ({text: row, style: 'defaultCellCenter'}))
    }

    // if (!indices)
    rows.push(calculateTotals())

    heads = heads.concat(rows)
  }

  switch(whichReport) {
    case WhichReport.HOUSEHOLDS:
      addReport('households', 'Household Count', true, false, false)
      addReport('households', `Index / Bias vs ${reportModel[0].statistics.region} Avg`, false, true)
  
      break;
    
    case WhichReport.COMPARISON_GOODS:
      addReport('comparisonTotal', 'Comparison Goods Spend (' + currencySymbol + '000)', true, false, true)
    
      break;
  }

  const table: any = helper.table(null, heads, 'defaultTable', tableOptions(helper, reportModel, 3, 6))

  // table[0].table.body.forEach(row => {
  //   row.forEach(cell => {
  //     cell.fontSize = 5
  //     cell.style = cell.style && cell.style + 'Mini'
  //   })
  // })

  return table
}
