import { Injectable } from '@angular/core'
import { Indicators } from '@targomo/client'
import { Observable } from 'rxjs'
import { distinctUntilChanged, map, shareReplay, pluck } from 'rxjs/operators'
import { PlaceEndpoint } from '../../api'
import { StatisticsEndpoint } from '../../api/statistics'
import { exportData } from '../../main/maxi/exportMaxiReport'
import { AppModel } from '../appModel.service'
import { ZoneLayersModel } from '../zoneLayersModel'
import { AbstractBenchmarkReport } from './abstractBenchmarkReport'
import { BenchmarkChilangoModel } from './benchmarkChilangoModel'
import { BenchmarkCocodiMamaModel } from './benchmarkCocodiMamaModel'
import { BenchmarkPlanetOrganicModel } from './benchmarkPlanetOrganicModel'

export class MaxiReportModel {
  readonly customReportsEnabled$: Observable<{
    showChilangoBenchmark: boolean
    showCocodimamaBenchmark: boolean
    showPlanetOrganicBenchmark: boolean
  }>

  readonly benchmarkChilango: AbstractBenchmarkReport<any>
  readonly benchmarkCocodiMama: AbstractBenchmarkReport<any>
  readonly benchmarkPlanetOrganic: AbstractBenchmarkReport<any>

  readonly benchmarkChilangoEnabled$: Observable<boolean>
  readonly benchmarkCocoiMamaEnabled$: Observable<boolean>
  readonly benchmarkPlanetOrganicEnabled$: Observable<boolean>

  constructor(
    private appModel: AppModel,
    private zoneLayersModel: ZoneLayersModel,
    private places: PlaceEndpoint,
    private indicators: Indicators,
    private statisticsEndpoint: StatisticsEndpoint
  ) {
    this.customReportsEnabled$ = this.initCustomReportsEnabled()

    this.benchmarkChilangoEnabled$ = this.customReportsEnabled$.pipe(
      pluck<any, boolean>('showChilangoBenchmark'),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.benchmarkCocoiMamaEnabled$ = this.customReportsEnabled$.pipe(
      pluck<any, boolean>('showCocodimamaBenchmark'),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.benchmarkPlanetOrganicEnabled$ = this.customReportsEnabled$.pipe(
      pluck<any, boolean>('showPlanetOrganicBenchmark'),
      distinctUntilChanged(),
      shareReplay(1)
    )

    this.benchmarkChilango = new BenchmarkChilangoModel(this.appModel, this.indicators, this.statisticsEndpoint)
    this.benchmarkCocodiMama = new BenchmarkCocodiMamaModel(this.appModel, this.indicators, this.statisticsEndpoint)
    this.benchmarkPlanetOrganic = new BenchmarkPlanetOrganicModel(
      this.appModel,
      this.indicators,
      this.statisticsEndpoint
    )
  }

  private initCustomReportsEnabled() {
    return this.appModel.places.dataSets.pipe(
      map((dataSets) => {
        function findPlaces(name: string) {
          name = name.toUpperCase()
          return dataSets.filter((dataset) => dataset.name.toUpperCase() == name).length > 0
        }

        const showChilangoBenchmark = findPlaces('Chilango Direct Competitors') && findPlaces('Chilango Complementary')
        const showCocodimamaBenchmark = findPlaces('Coco di Mama Competitors')
        const showPlanetOrganicBenchmark = findPlaces('Planet Organic Competitors')

        return {
          showChilangoBenchmark,
          showCocodimamaBenchmark,
          showPlanetOrganicBenchmark,
        }
      })
    )
  }

  async export(completeData: boolean = false, fileName: string = '') {
    const reports: { locations: any[]; columns: any[]; fileName: string }[] = []
    const show = await this.customReportsEnabled$.take(1).toPromise()

    if (show.showChilangoBenchmark) {
      reports.push(await this.benchmarkChilango.export$.take(1).toPromise())
    }

    if (show.showCocodimamaBenchmark) {
      reports.push(await this.benchmarkCocodiMama.export$.take(1).toPromise())
    }

    if (show.showPlanetOrganicBenchmark) {
      reports.push(await this.benchmarkPlanetOrganic.export$.take(1).toPromise())
    }

    // workaround
    const zonesSelected = await Observable.combineLatest(this.zoneLayersModel.selectionExistsUpdates)
      .map(([value]) => value)
      .take(1)
      .toPromise()

    exportData(this.appModel, this.places, reports, completeData, zonesSelected, fileName)
  }
}
