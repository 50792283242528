import { Indicators } from '@targomo/client'
import { Observable, BehaviorSubject, of } from 'rxjs';
import { canonicalPositions, Place } from '../api/place';
import { TravelTypeEdgeWeightOptions, MultigraphClient, BoundingBox } from '@targomo/core';
import { FeatureModel } from './featureModel';
import { ZoneLayersModel } from './zoneLayersModel';
import { StatisticsEndpoint } from '../api/statistics';
import { AppModel } from './appModel.service';
import { ZoneLayersEndpoint } from '../api/sectors';
import { SettingsModel, TravelDisplayMode } from './settingsModel';
import { switchMap, shareReplay, map, retryWhen, delay, catchError } from 'rxjs/operators';
import { StatefulMultigraphClient, MultigraphOverview } from '../api/multigraph';
import { UrlUtil } from '../util/urlUtil';
import { POLYGON_SOURCES_THRESHOLD } from './placesModel';

export class MultigraphModel {
  readonly statisticsGroupZoom$ = new BehaviorSubject<number>(null)
  readonly hash$: Observable<string>
  readonly overview$: Observable<MultigraphOverview>
  readonly tilesUrl$: Observable<string>
  readonly multigraphClient: StatefulMultigraphClient
  readonly bounds$: Observable<BoundingBox>

  constructor(
    private indicators: Indicators,
    private sources: Observable<Place[]>,
    private travelOptions: Observable<TravelTypeEdgeWeightOptions>,
    private intersectionMode: Observable<string>,
    private statisticsEndpoint: StatisticsEndpoint,
    private settingsModel: SettingsModel,
    private appModel: AppModel,
    private featuresModel: FeatureModel,
    private zoneLayersModel: ZoneLayersModel,
    private zoneLayersEndpoint: ZoneLayersEndpoint,
  ) {
    this.multigraphClient = new StatefulMultigraphClient(settingsModel.client)

    const hashFetched$ = this.init().pipe(
      switchMap(value => value),
      shareReplay(1)
    )

    const overview$ = hashFetched$.pipe(
      switchMap(async hash => {
        if (!hash) {
          return null
        }

        const overview = await this.indicators.add(this.multigraphClient.overfiew(hash))

        if (overview.status === 'COMPLETED') {
          return {hash, overview}
        } else {
          throw new Error(overview.status)
        }
      }),
      retryWhen(err$ =>
        err$.pipe(
          map(err => {
            if (err.message === 'FAILED') {
              throw err
            }
          }),
          delay(1000)
        )
      ),
      catchError(er => of(null)),
      shareReplay(1)
    )

    this.hash$ = overview$.pipe(
      map(overview => {
        if (overview) {
          return overview.hash
        } else {
          return null
        }
      }),
      shareReplay(1)
    )

    this.overview$ = overview$.pipe(
      map(overview => {
        if (overview) {
          return overview.overview
        } else {
          return null
        }
      }),
      shareReplay(1)
    )

    this.tilesUrl$ = this.hash$.pipe(
      map(hash => {
        if (!hash) {
          return hash
        }

        const format = 'mvt'

        return new UrlUtil.TargomoUrl(this.settingsModel.client)
        .host(this.settingsModel.client.config.statisticsUrl)
        .part('multigraph/' + hash + '/{z}/{x}/{y}.' + format)
        .key()
        .params({ serviceUrl: this.settingsModel.client.serviceUrl })
        .toString()
      }),
      catchError(er => of(null)),
      shareReplay(1)
    )


    this.bounds$ = this.overview$.pipe(
      map(overview => {
        if (!overview || !overview.multiGraphInfo || !overview.multiGraphInfo.northEast || !overview.multiGraphInfo.southWest) {
          return null
        }

        return {
          northEast: {
            lat: overview.multiGraphInfo.northEast.y,
            lng: overview.multiGraphInfo.northEast.x,
          },
          southWest: {
            lat: overview.multiGraphInfo.southWest.y,
            lng: overview.multiGraphInfo.southWest.x,
          },
        } as BoundingBox
      }),
      shareReplay(1)
    )
  }

  private init() {
    return Observable.combineLatest(
      this.sources,
      this.travelOptions,
      this.intersectionMode,
      this.appModel.settings.exclusiveTravelUpdates,
      this.featuresModel.travelTimesVisibleUpdates,
      this.zoneLayersModel.selectionExistsUpdates,
      this.zoneLayersModel.visibleLayerFeaturesGeometry,
      this.appModel.places.inactiveSources,
      this.settingsModel.disableStatisticsUpdates,
      this.appModel.settings.statisticUpdates,
      this.statisticsGroupZoom$,
      this.settingsModel.travelDisplayModeUpdates,
    ).debounceTime(20).map(async ([sources, travel, intersectionMode, exclusiveMode, travelTimesVisible, zonesVisible, geometry, inactiveSources, disableStatistics, statisticUpdates, statisticsGroupZoom, travelDisplayMode,]) => {
      if (disableStatistics) {
        console.log('Statistics disabled')
        return null as string
      }

      if (travelDisplayMode === TravelDisplayMode.NoThematicPolygons) {
        return null
      }

      if (intersectionMode !== 'union') {
        return null
      }

      if (sources && sources.length <= POLYGON_SOURCES_THRESHOLD) {
        return null
      }

      if (zonesVisible) {
        if (!geometry) {
          return null as string
        }
      }

      return await this.indicators.add(this.multigraphClient.requestHash(canonicalPositions(sources), {
        travel,
        statisticsId: statisticUpdates.id,
        statisticsGroup: statisticsGroupZoom,
      }))
    })
  }
}