import { DecimalPipe } from '@angular/common/'
import { Injectable } from '@angular/core'
import { Indicators, Auth, TRAVEL_COLORS } from '@targomo/client'
import { BoundingBox } from '@targomo/core'
import 'rxjs/add/operator/take'
import { take } from 'rxjs/operators'
import { DEFAULT_COPYRIGHT, DEFAULT_MAP_ATTRIBUTION } from '../../../common/models/settings'
import { Place } from '../../api/index'
import { SettingsEndpoint } from '../../api/settings'
import { UserEndpoint } from '../../api/user'
import { UserSettingsEndpoint } from '../../api/userSettings'
import { AppModel } from '../../model/appModel.service'
import { GapReportLocations, PdfReportSettings, SettingsModel } from '../../model/settingsModel'
import { APP_BASE_PATH } from '../../util/basePath'
import { drawMapScale } from './exportPdfMap'
import { reportFooter, reportHeader } from './headerFooter'
import { colorSquareGeneral, PDFHelper } from './helper'
import { createStyle } from './styles'
import { LocationsPresentModel, LocationsPresentReport } from '../../model/locationsPresentModel'
import { UserPermissions } from '../../../common/models'
import { GapReportPermissions } from '../../../common/models/userPermissions'
import { travelTimesLegend } from './travelLegend'
import { OperatorPresent } from '../../model/operatorsWithinModel'
import { AVAILABLE_GAP_REPORT_COLUMNS } from '../../pages/admin/components/adminEditGapAnalysisReport/adminEditGapAnalysisReport.component'
import { AbstractLocation } from '../../model'
import { travelLabel } from './travelLabel'
const Color = require('color')

const pdfMake = require('pdfmake/build/pdfmake.js')
const pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs

const REPORT_TITLE = 'GAP ANALYSIS REPORT'

const CLASSIFICATION_TYPES: Record<string, string> = {
  'town centre': 'TC',
  'town centre shopping centre': 'TC-SC',
  'shopping centre': 'SC',
  'out of town': 'OOT',
  'suburban area': 'Suburban',
  'suburban centre': 'Suburban',
  'retail / shopping park': 'OOT',
  solus: 'Solus',
}

const TABLE_OPTIONS = {
  border: '#ffffff',
  background: ['#ffffff', null],
  widths: () => ['auto', '*'],
  layout: {
    hLineWidth: function (i: number, node: any) {
      return 0
    },
    vLineWidth: function (i: number, node: any) {
      return 0
    },
    hLineColor: function (i: number, node: any) {
      return '#ffffff'
    },
    vLineColor: function (i: number, node: any) {
      return '#ffffff'
    },

    fillColor: function (i: number, node: any) {
      return '#ffffff'
    },
  },
}

function cellTransform(text: any) {
  return { text: text == null ? '' : text, style: 'defaultCell' }
}

function cellTransformLeft(text: any) {
  return { text: text == null ? '' : text, style: 'defaultCellFirst' }
}

class ColumnModel {
  private helper = new PDFHelper(new DecimalPipe('en-US'))
  private columnsMap = AVAILABLE_GAP_REPORT_COLUMNS.reduce((acc, cur) => {
    acc[cur.key] = cur
    return acc
  }, {} as Record<string, { key: string; label: string }>)

  readonly columns: string[]
  constructor(columns: string[], windowMode: boolean) {
    const suppliedColumnsMap = columns
      .filter((column) => !(windowMode && column === 'travelTime'))
      .reduce((acc, cur) => {
        acc[cur] = true
        return acc
      }, {} as Record<string, boolean>)

    // sort based on available columns order
    this.columns = AVAILABLE_GAP_REPORT_COLUMNS.map((item) => item.key).filter((key) => suppliedColumnsMap[key])
  }

  getColumnLabels() {
    return this.columns.map((key) => (this.columnsMap[key] ? this.columnsMap[key].label : ''))
  }

  hasClassification() {
    return !!this.columnsMap['classification']
  }

  getColumnValuePresentOperator(operator: OperatorPresent) {
    return this.columns.map((key) => this.getColumnValuePresent(key, operator))
  }

  isRightAligned(index: number) {
    const key = this.columns[index]
    return key === 'grossInternalArea' || key === 'netSalesArea' || key === 'travelTime'
  }

  getColumnValuePresent(key: string, operator: OperatorPresent) {
    const transformString = (key: keyof AbstractLocation) => {
      return {
        stack: operator.locations.map((value) => value[key]).map(cellTransformLeft),
      }
    }

    const transformNumber = (key: keyof AbstractLocation) => {
      return {
        stack: operator.locations.map((value) => this.helper.formatNumber(value[key])).map(cellTransform),
      }
    }

    const transformTravelBand = (key: keyof AbstractLocation) => {
      return {
        stack: operator.locations
          .map((location) => {
            const min = +location.travelTime || 0
            const max = +location.travelTime || 0

            return `${Math.floor(min / 300) * 5}-${Math.ceil(max / 300) * 5}`
          })
          .map(cellTransform),
      }
    }

    const transformClassification = () => {
      return {
        stack: operator.locations
          .map((value) => {
            if (!value || !value.other) {
              return 'Suburban'
            }

            const key =
              Object.keys(value.other).find((key) => {
                return key.trim().toUpperCase() === 'CENTRE CLASSIFICATION'
              }) || 'CENTRE CLASSIFICATION'

            const v = String(value.other[key] || '')
              .trim()
              .toLowerCase()
            return CLASSIFICATION_TYPES[v] || value.other[key] || 'Suburban'
          })

          .map(cellTransformLeft),
      }
    }

    switch (key) {
      case 'name':
        return operator.category.name // transformString(key)
      case 'fascia':
        return transformString(key)
      case 'primaryCategory':
        return transformString(key)
      case 'town':
        return transformString(key)
      case 'street':
        return transformString(key)
      case 'postcode':
        return transformString(key)
      case 'grossInternalArea':
        return transformNumber(key)
      case 'netSalesArea':
        return transformNumber(key)
      case 'paon':
        return transformString(key as any)
      case 'taon':
        return transformString(key as any)
      case 'saon':
        return transformString(key as any)
      case 'travelTime':
        return transformTravelBand(key)
      case 'classification':
        return transformClassification()
    }

    return ''
  }
}

@Injectable()
export class ExportPDFGapReport {
  private helper: PDFHelper

  constructor(
    private appModel: AppModel,
    private pipe: DecimalPipe,
    private indicators: Indicators,
    private userEndpoint: UserEndpoint,
    private settingsEndpoint: SettingsEndpoint,
    private userSettingsEndpoint: UserSettingsEndpoint
  ) {
    this.helper = new PDFHelper(pipe)
  }

  async save(
    title: string,
    imageData: string,
    zoomLevel: number,
    bounds: BoundingBox,
    scale: { width: number; label: string },
    gapReportPermissions: GapReportPermissions
  ) {
    imageData = await drawMapScale(imageData, scale)

    const travelOptions = await this.appModel.settings.travelOptionsUpdates.pipe(take(1)).toPromise()

    const sourceMode = await this.appModel.settings.gapReportLocationsUpdates.pipe(take(1)).toPromise()
    const travelSourcesCount = await this.appModel.selectedItemsCount$.pipe(take(1)).toPromise()

    const locationsPresentModel = new LocationsPresentReport(
      sourceMode,
      travelSourcesCount,
      bounds,
      this.appModel.places.filteredPlaces.getValue(),
      [].concat(this.appModel.places.sources.getValues(), this.appModel.places.reachableFilteredPlaces.getValue())
    )

    await new Promise((resolve) => setTimeout(resolve, 100))

    const user = await this.userEndpoint.me()
    const customLogoPath = APP_BASE_PATH + `api/users/${user.id}/companypicture/public`
    const settings = await this.settingsEndpoint.getPublic()
    const userSettings = await this.userSettingsEndpoint.loadReport()

    const helper = this.helper // new PDFHelper(null)
    const customLogo = await helper.convertImgToDataURLviaCanvas(customLogoPath, 'image/png')
    const companyLogoPath = await this.helper.convertImgToDataURLviaCanvas(
      APP_BASE_PATH + 'api/settings/logo/' + user.id,
      'image/png'
    )
    const sources = this.appModel.places.sources.getValues()

    const reportSettings: PdfReportSettings = (userSettings && userSettings.reportTemplate) || <any>{}
    let homePageImage = companyLogoPath.data
    if (reportSettings.customLogo) {
      homePageImage = customLogo.data || companyLogoPath.data
    }

    const generateLegend = async () => {
      if (gapReportPermissions.showCompetition) {
        return [await this.categoriesLegend(locationsPresentModel)]
      } else {
        return locationsPresentModel.windowMode ? [] : [await travelTimesLegend(this.appModel.settings)]
      }
    }

    const copyright = [(settings && settings.copyright) || DEFAULT_COPYRIGHT, DEFAULT_MAP_ATTRIBUTION].join(', ')

    const columnModelPresent = new ColumnModel(gapReportPermissions.columns, locationsPresentModel.windowMode)

    const travelLabelText = `${travelOptions.maxEdgeWeight / 60} Minute ${travelLabel(travelOptions.travelType)}Time`

    const mapTitle = locationsPresentModel.windowMode ? 'Analysis Area' : travelLabelText

    const sourcesStack = sources.map((source) => {
      const text = [source.defaultName, source.town, source.postcode].filter((item) => !!item).join(' | ')
      return { text, fontSize: 8 }
    })

    const presentRows = await this.createTablePresent(locationsPresentModel, columnModelPresent)

    const docDefinition: any = {
      footer: reportFooter(copyright, customLogo, reportSettings.shortFooter),
      header: reportHeader(homePageImage, REPORT_TITLE),

      content: [
        this.homePage(sources, homePageImage, title),

        { pageBreak: 'before', pageOrientation: 'landscape', text: '' },

        [helper.fullWidthHeader(mapTitle, 'headerInverse2')],

        {
          columns: [
            { stack: await generateLegend(), width: 250, style: 'legendStyle' },
            {
              image: imageData,
              width: 480,
              alignment: 'right',
              absolutePosition: { x: 240, y: 100 },
            },
            { stack: sourcesStack, absolutePosition: { x: 340, y: 105 } },
          ],
          fontSize: 12,
        },

        {
          pageBreak: 'before',
          pageOrientation: 'landscape',
          style: 'header',
          text: 'Brands Present In User Defined Area',
        },

        this.asPdfTable(
          presentRows.rows,
          ['Category'].concat(columnModelPresent.getColumnLabels()),
          columnModelPresent,
          [null].concat(presentRows.backgrounds)
        ),

        columnModelPresent.hasClassification
          ? {
              margin: [0, 10, 0, 3],
              fontSize: 8,
              alignment: 'center',
              text: 'Classifcation Key: TC – Town Centre, TC-SC – Town Centre Shopping Centre, SC – Shopping Centre, OOT – Out of Town, Solus - Solus Store, Suburban – Suburban Area.',
            }
          : '',

        {
          pageBreak: 'before',
          pageOrientation: 'landscape',
          style: 'header',
          text: 'Brands Not Present In User Defined Area',
        },

        this.asPdfTable(await this.createTableNotPresent(locationsPresentModel), ['Category', 'Name']),
      ],
      styles: createStyle(reportSettings),
      pageMargins: [30, 60, 30, 80],
      pageSize: 'A4',
      pageOrientation: 'landscape',
    }

    return pdfMake.createPdf(docDefinition)
  }

  private asPdfTable(input: any[], columns: string[], columnModel: ColumnModel = null, background?: string[]) {
    if (!input || !input.length) {
      return ''
    }

    function cellTransformIndex(text: any, i: number) {
      if (typeof text === 'object' || text instanceof Array) {
        return text
      } else {
        if (columnModel) {
          return {
            text: text == null ? '' : text,
            style: !columnModel.isRightAligned(i - 1) ? 'defaultCellFirst' : 'defaultCell',
          }
        } else {
          return { text: text == null ? '' : text, style: i < 2 ? 'defaultCellFirst' : 'defaultCell' }
        }
      }
    }

    const options = {
      cellTransform: cellTransformIndex,
      widths: () => columns.map((item) => '*'),
      headerRows: 1,
      background,
    }

    return this.helper.table(columns, input, 'defaultTable', options)
  }

  private async createTablePresent(locationsPresent: LocationsPresentReport, columnModelPresent: ColumnModel) {
    const present = locationsPresent.operatorsPresent

    const rows = (present || []).map((item) => {
      const row: any[] = [item.category.grouping || ''].concat(
        columnModelPresent.getColumnValuePresentOperator(item) as any[]
      )

      const flatRows = item.locations.map((location, i) => {
        return row.map((cell) => {
          if (cell.stack instanceof Array) {
            return cell.stack[i]
          } else {
            if (i === 0) {
              return { ...cellTransformLeft(cell.text || cell), rowSpan: item.count }
            } else {
              return ''
            }
          }
        })
      })

      return flatRows
    })

    const backgrounds = (present || []).reduce((acc, cur, i) => {
      cur.locations.forEach(() => {
        acc.push(i % 2 === 0 ? null : '#fafafa')
      })
      return acc
    }, [] as string[])

    return { rows: Array.prototype.concat.apply([], rows), backgrounds }
  }

  private async createTableNotPresent(locationsPresent: LocationsPresentReport) {
    const notPresent = locationsPresent.operatorsNotPresent

    return (notPresent || []).map((item) => {
      return [item.category.grouping, item.category.name]
    })
  }

  private async categoriesLegend(locationsPresent: LocationsPresentReport) {
    const present = locationsPresent.operatorsPresent

    const CUTOFF = 22
    const rows: any[] = present.slice(0, CUTOFF).map((source) => {
      return [
        this.helper.styleColorColumn(colorSquareGeneral(source.category.color)),
        { text: source.category.name, style: 'defaultCellLeft2' },
      ]
    })

    if (!rows.length) {
      return ''
    }

    let note: any[] = []
    if (present.length > CUTOFF) {
      note = [{ text: `Note: Legend Limited to ${CUTOFF} Fascias`, style: 'defaultCellLeft2', margin: [0, 5, 0, 0] }]
    }

    return {
      style: 'legendSection',
      stack: [this.helper.table(null, rows, 'defaultTable', TABLE_OPTIONS)].concat(note),
    }
  }

  private homePage(sources: Place[], imageData: string, title: string): any[] {
    const headerStyle1 = 'homeHeader3'
    const headerStyle2 = 'homeHeader1'

    const sourcesRows = title
      ? [{ style: headerStyle1, text: title }]
      : sources.map((source) => {
          const text = [source.defaultName, source.town, source.postcode].filter((item) => !!item).join(' | ')
          return { style: headerStyle1, text, margin: [0, 5, 0, 5] }
        })

    return []
      .concat([{ image: imageData, width: 280, margin: [0, 45, 0, 40], style: 'logoCenter' }])
      .concat([
        { style: headerStyle2, text: REPORT_TITLE, margin: [20, 20, 20, 0] },
        { text: '', style: 'homeHeader1', margin: [0, 0, 0, 10] },
      ])
      .concat(sourcesRows)
      .concat([
        { text: '', style: 'homeHeader1' },
        { text: this.helper.formatDate(new Date()), style: 'frontPageDate' },
      ])
      .concat([{ style: 'copyright', text: '' }])
  }
}
