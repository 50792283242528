import { Component, Input, HostBinding } from '@angular/core'
import { SubscriberComponent } from '@targomo/client'
import { Router, NavigationEnd } from '@angular/router'
import { Location } from '@angular/common';

@Component({
  selector: 'back-button',
  templateUrl: './backButton.component.html',
  styleUrls: ['./backButton.component.less']
})
export class BackButtonComponent extends SubscriberComponent {
  constructor(router: Router, private location: Location) {
    super()
  }

  back() {
    this.location.back()
  }
}
