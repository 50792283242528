import { Component, OnInit } from '@angular/core';
import { AppModel } from '../../../model/appModel.service';
import { SubscriberComponent, Indicators } from '@targomo/client';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { AllStatistics, StatisticsValue } from '../../../../common/models/statistics/statistics';
import { Place } from '../../../api/place';
import { getCensusSegments, ReportSegment } from '../../mini/model/reportSegments';
import { ComparisonModel, ComparisonModelResult, COMPARISON_HIGHLIGHT, COMPARISON_INDEX, ComparisonIndex, ComparisonHighlight } from './comparisonModel';
import {files} from '@targomo/client'
import { ComparisonReportTemplateEndpoint } from '../../../api/comparisonReportTemplate';
import { map } from 'rxjs/operators';
import { AbstractLocation } from '../../../model';
import {csv} from '../../../util/csv'
import { ReportPerCapitaHousehold } from '../../../model/settingsModel';

export const COLUMNS = [
  {key: 'fascia', label: 'Fascia'},
  {key: 'street', label: 'Street'},
  {key: 'town', label: 'Town'},
  {key: 'postcode', label: 'Postcode'},
  {key: 'grossInternalArea', label: 'Gross area (sq ft)'},
  {key: 'netSalesArea', label: 'Net sales area (sq ft)'},
  // {key: 'netSalesArea', label: 'Net sales area (sq ft)'},
]

interface ValueRow {
  title: string
  value: number
  valueIndex: number
  dominant?: boolean
}

interface RowSegment {
  title: string
  children: ValueRow[]
}

class ColumnItem {
  location: Place
  statistics: AllStatistics

  demographics?: RowSegment[]
  spend?: RowSegment[]
  competition?: RowSegment[]
}


@Component({
  selector: 'maxi-comparison-report',
  templateUrl: './maxiComparisonReport.component.html',
  styleUrls: ['./maxiComparisonReport.component.less']
})
export class MaxiComparisonReportComponent extends SubscriberComponent implements OnInit {
  avalableComparisonHighlight = COMPARISON_HIGHLIGHT
  avalableComparisonIndex = COMPARISON_INDEX

  basicColumns = COLUMNS
  // segments: ReportSegment[]
  sources: AbstractLocation[]
  data: ColumnItem[] = []
  report: ComparisonModelResult
  
  availableTemplates: any[] = []
  tableWidth: number = null

  private comparisonModel: ComparisonModel

  comparisonHighlight$ = new BehaviorSubject<ComparisonHighlight>(COMPARISON_HIGHLIGHT[2].key)
  comparisonIndex$ = new BehaviorSubject<ComparisonIndex>(COMPARISON_INDEX[0].key)

  indexTitle = ''
  perHouseholdCapitaTitle = ''

  constructor(
    readonly appModel: AppModel,
    private indicators: Indicators,
    private templateEndpoint: ComparisonReportTemplateEndpoint,
  ) {
    super()

  }

  async ngOnInit() {
    this.availableTemplates = await this.templateEndpoint.me()
    const template$ = this.appModel.settings.comparisonReportTemplateIdUpdates.pipe(
      map((templateId) => {
        const template = this.availableTemplates.filter(item => item.id === templateId)[0] || this.availableTemplates[0]
        return template.data
      })
    )

    this.watch(this.appModel.settings.reportPerCapitaHouseholdsUpdates, value => {
      this.perHouseholdCapitaTitle = value === ReportPerCapitaHousehold.PER_CAPITA ? 'Per Capita' : 'Per Households'
    })

    this.comparisonModel = new ComparisonModel(this.appModel, this.indicators, template$, this.comparisonHighlight$, this.comparisonIndex$)

    this.watch(this.comparisonModel.statistics$, data => {
      if (data) {
        this.avalableComparisonIndex = data.indexOptions
        const currentIndex = this.comparisonIndex$.getValue()
        if (this.avalableComparisonIndex.filter(option => option.key === currentIndex).length === 0) {
          this.comparisonIndex$.next(this.avalableComparisonIndex[0].key)
        }
      }
    })

    this.watch(this.comparisonIndex$, indexType => {
      const currentIndexItem = this.avalableComparisonIndex.filter(option => option.key === indexType)[0]
      this.indexTitle = currentIndexItem ? currentIndexItem.columnLabel : ''
    })

    this.watch(this.comparisonModel.report$, report => {
      this.report = report
    })

    this.watch(combineLatest(
      this.appModel.places.sources.observable,
      this.appModel.statistics.census.value,
      template$,
    ), ([sources, statistics, template]) => {
      this.sources = sources || []
      const segments = getCensusSegments(statistics)
      this.tableWidth = this.sources.length * 300 + 300
    })
  }

  export() {
    const data = this.flattenReport()
    files.saveFile(csv(data),'text/csv', 'storepointgeo_comparison_report.csv')    
  }

  private flattenReport() {
    const results: any[] = this.report.sources.map(_ => ({}))
    this.report.segments.forEach(segment => {
      segment.children.forEach(group => {
        group.children.forEach(node => {
          node.values.forEach((value, index) => {
            const column = [segment.title, group.title, node.title].filter(item => !!item).join(' - ')

            if (segment.type === 'indexDominant') {
              results[index][column] = value.value
              results[index][column + ' (Index vs ' + this.indexTitle + ')'] = value.valueIndex
            } else if (segment.type === 'index') {
              results[index][column] = value.value
              results[index][column + ' (Index vs ' + this.indexTitle + ')'] = value.valueIndex
            } else {
              results[index][column] = value.value
            }
          })
        })
      })
    })

    return results
  }


  selectTemplate(template: any) {
    if (template) {
      this.appModel.settings.displaySettings.nextProperty('comparisonReportTemplateId', template)
    }
  }

  selectHighlight(template: any) {
    this.comparisonHighlight$.next(template)

  }
  
  selectIndex(template: any) {
    this.comparisonIndex$.next(template)
  }  
}
