<div class="admin-panel">
  <h2 class="mat-title">Comparison Report Template</h2>

  <form class="form" name="form" (submit)="save($event)" novalidate>
    <section>
      <mat-form-field style="width: 100%">
        <input placeholder="Name" matInput type="text" name="name" [(ngModel)]="model.name"/>
      </mat-form-field>

      <admin-comparison-report-options (modelChange)="updateReport($event)" [model]="model.data"></admin-comparison-report-options>

    </section>

    <div class="align-right" style="margin-top: 32px">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-flat-button color="primary" type="submit">Save</button>
    </div>
  </form>
</div>