<div class="admin-panel">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Edit Zone Layer</h2>
    <div class="secondary">
        <button mat-button class="mat-primary" routerLink="/admin/zone/upload/{{id}}"><mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> Upload New Zone Layer Version</button>
    </div>
  </div>

  <form [formGroup]="form" class="form" name="form" (submit)="save(form)"  novalidate style="margin-bottom: 30px">
    <section fxLayout="column" fxLayoutGap="2rem">
      <mat-form-field>
          <input placeholder="Name" matInput type="text" formControlName="name"/>

      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Match column" formControlName="matchColumn">
          <mat-option *ngFor="let option of availableColumns" [value]="option.key">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </section>

    <div class="align-right">
      <tgm-saveable [model]="saving"></tgm-saveable>
      <button mat-button type="button" (click)="back()">&larr; Back</button>
      <button mat-flat-button color="primary" type="submit">Save</button>
    </div>
  </form>

  <section style="margin-bottom: 20px">
    <h3 class="mat-title subtitle">Versions</h3>
    <admin-zone-layer-versions (updated)="updatedVersion()" [id]="id"></admin-zone-layer-versions>
  </section>
</div>