import {Entity} from './entity'
import { User } from './user';

export enum UserEventLogType {
  CHANGE_PASSWORD = 0,
  LOGIN = 1,
  DATA_LAYER_ACTIVATED = 2,
  TRAVEL_TIME_RUN = 3,
  MAP_CREATED = 4,
  MAXI_REPORTS_VIEWED = 5,
  PDF_REPORT_DOWNLOADED = 6,
  SESSION = 8,
  MAP_IMAGE_CREATED = 9,
  MESSAGE_DISMISSED = 10,
  MESSAGE_INFO_REQUESTED = 11,
  MATCHPOINT_DIALOG_OPENED = 12,
  GAP_REPORT_CREATED = 13,
  CARE_REPORT_PDF_DOWNLOADED = 14,

}

export interface UserEventLog extends Entity {
  username?: string
  name?: string
  userId?: number
  type: UserEventLogType
  projectNumber?: string
  created: Date
  data: any
}