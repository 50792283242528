import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tgm-wait-dialog',
  templateUrl: './waitDialog.component.html',
  styleUrls: ['./waitDialog.component.less']
})
export class WaitDialogComponent {
  model: any = null

  constructor(public dialogRef: MatDialogRef<WaitDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) readonly dialogData: any) {
    this.model = dialogData
  }
}
