import { Component, OnInit, ViewChild, forwardRef } from '@angular/core'
import { LABELS_CONFIG_OPTIONS, LabelsConfig } from '../../../model/labelsConfig'
import { MatSelectionList } from '@angular/material'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { SubscriberComponent } from '@targomo/client'

export const SELECT_PLACES_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectPlacesLabelComponent),
  multi: true,
}

@Component({
  selector: 'select-places-label',
  templateUrl: './selectPlacesLabel.component.html',
  styleUrls: ['./selectPlacesLabel.component.less'],
  providers: [SELECT_PLACES_ACCESSOR],
})
export class SelectPlacesLabelComponent extends SubscriberComponent implements OnInit, ControlValueAccessor {
  @ViewChild('list') list: MatSelectionList

  options = LABELS_CONFIG_OPTIONS
  value: LabelsConfig

  _onChange: (value: string[]) => void
  _onTouched: (value: string[]) => void

  constructor() {
    super()
  }

  ngOnInit() {}

  emit() {
    const values = this.list.selectedOptions.selected.map((item) => item.value)
    if (this._onChange) {
      this._onChange(values)
    }
  }

  writeValue(configValue: string[]): void {
    this.value = new LabelsConfig(configValue || [])
    this.list.selectedOptions.clear()
    if (configValue) {
      const options = this.list.options.filter((item) => this.value.contains(item.value))
      this.list.selectedOptions.select(...options)
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    // this.disabled = isDisabled
  }
}
