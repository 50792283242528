<button style="width: 1px; height: 1px; opacity: 0" mat-icon-button [mat-menu-trigger-for]="menu"></button>

<mat-menu #menu="matMenu">
  <ng-container *ngIf="!!currentRoadSection">
    <button (click)="toggleRoadLabel()" mat-menu-item>Toggle Road Link Label</button>
  </ng-container>

  <ng-container *ngIf="!isDeleted">
    <ng-container *ngIf="isTemporaryCustom">
      <button (click)="storeData()" mat-menu-item>Store Data</button>
      <button (click)="saveLocation()" mat-menu-item translate="menu.addLocation">Save location</button>
    </ng-container>

    <ng-container *ngIf="!isTemporaryCustom">
      <ng-container *ngIf="!isPointAndClick">
        <button
          *ngIf="!!current && isSelected"
          (click)="togglePolygons()"
          mat-menu-item
          translate="menu.deleteLocation"
        >
          Traveltime Off
        </button>
        <button
          *ngIf="!!current && !isSelected"
          (click)="togglePolygons()"
          mat-menu-item
          translate="menu.deleteLocation"
        >
          Traveltime On
        </button>
      </ng-container>

      <ng-container *ngIf="!!isPointAndClick">
        <button *ngIf="!!current && isTemporaryCustom" (click)="storeData()" mat-menu-item>Store Data</button>
        <button *ngIf="!!current && !isTemporaryCustom" (click)="viewStoreData()" mat-menu-item>View Store Data</button>
      </ng-container>

      <button *ngIf="!!current && isLabelSelected" (click)="toggleLabels()" mat-menu-item>Label Off</button>
      <button *ngIf="!!current && !isLabelSelected" (click)="toggleLabels()" mat-menu-item>Label On</button>
      <button *ngIf="!current" (click)="addLocation()" mat-menu-item translate="menu.addLocation">Add location</button>
      <button *ngIf="!!current && isColorSelectEnabled" (click)="colorLocation()" mat-menu-item>Select color</button>
      <button *ngIf="!!current && isIconSelectEnabled" (click)="colorLocation()" mat-menu-item>Select color</button>
      <button *ngIf="!!current && isDeletable" (click)="deleteLocation()" mat-menu-item translate="menu.deleteLocation">
        Delete location
      </button>
    </ng-container>
  </ng-container>

  <button *ngIf="!!current && isDeleted" (click)="undeleteLocation()" mat-menu-item>Undelete location</button>

  <button *ngIf="!!isPolygonExportAllowed" (click)="downloadPolygons()" mat-menu-item>
    Download Polygons as GeoJSON
  </button>
</mat-menu>
