import { tableOptions, PDFHelper, TABLE_BACKGROUNDS } from './helper'
import { ReportModel } from './exportPdfReport'
import { LIFESTYLE_SEGMENTS } from '../mini/model/reportSegments'
import { SPEND_REPORT } from './values'
import { ReportPerCapitaHousehold } from '../../model/settingsModel'

export function valueGraph(
  value: number,
  background: string,
  height = 10,
  total = 10,
  defaultCell = 'defaultCell',
  middleBorder = false
): any {
  if (!isFinite(value)) return ''

  const first = value < 1

  if (first) {
    value = Math.min(1, Math.abs(1 - value))
  } else {
    value = Math.min(1, value - 1)
  }

  if (value == 0) {
    return ''
  }

  const TOTAL = total
  const HEIGHT = height
  const width = Math.round(TOTAL * value)
  const offset = TOTAL - width

  return {
    style: defaultCell,
    canvas: [
      {
        type: 'rect',
        x: 0,
        y: 0,
        w: TOTAL * 2,
        h: HEIGHT,
        lineColor: background,
        lineOpacity: 0,
        opacity: 0,
        lineWidth: 0,
        fillOpacity: 0,
      },
      {
        type: 'rect',
        x: first ? offset : TOTAL,
        y: 0,
        w: width,
        h: HEIGHT,
        color: first ? '#c44444' : '#4472c4',
      },

      ...(middleBorder
        ? [
            {
              type: 'rect',
              x: TOTAL,
              y: -2,
              w: 0.5,
              h: HEIGHT + 4,
              color: '#000000',
            },
          ]
        : []),
    ],
  }
}

export function spendReport(reportModel: ReportModel, helper: PDFHelper, householdOrCapita: ReportPerCapitaHousehold) {
  const currencySymbol = reportModel[0].statistics.currencySymbol()

  // const segments: {id: string, name: string, group: string}[] = [].concat(
  //   LIFESTYLE_SEGMENTS[0].children.filter(item => !item.parent).map(item => ({id: item.id, name: item.name, group: 'spendSummary'}))
  // ).concat(
  //   LIFESTYLE_SEGMENTS[1].children.map(item => ({id: item.id, name: item.name, group: 'spend'}))
  // )

  // const se

  const rows: any[] = []

  const head = [''].concat(
    <any>reportModel.map((report) => {
      return {
        text: report.name,
        colSpan: 3,
      }
    })
  )

  const createHeads = () => {
    const head: any[] = [{ text: '', border: [false, false, false, false] }]
    const subhead: any[] = ['']

    const titleString = householdOrCapita === ReportPerCapitaHousehold.PER_CAPITA ? 'Per Capita' : 'Household'

    reportModel.forEach((report) => {
      head.push({ text: titleString + ' Spend (' + currencySymbol + ')', style: 'defaultCellLeft' })
      head.push('')
      head.push({ text: 'Index vs ' + report.statistics.regionLabel() + ' Avg', style: 'defaultCellRight' })

      subhead.push({ text: '-100', style: 'percentHeaderLeft' })
      subhead.push({ text: '0', style: 'percentHeaderCenter' })
      subhead.push({ text: '100', style: 'percentHeaderRight' })
    })

    rows.push(head)
    rows.push(subhead)
  }

  createHeads()

  const COLORS = ['#fbfdf9', '#e7f1f9']

  SPEND_REPORT.forEach((segment, i) => {
    const row: any[] = [segment.name]

    reportModel.forEach((report) => {
      const statistic =
        segment.items[0] == 'convenience' ? report.statistics.lifestyle.spendSummary : report.statistics.lifestyle.spend
      // const statistic = report.statistics.lifestyle[segment.group][segment.id]
      // const value = statistic.total / report.statistics.census.population2011.households.total

      const calculateValue = () => {
        var total = 0
        var percent = 0
        var index = 0

        segment.items.forEach((id) => {
          total += statistic[id].total || 0
          index += statistic[id].index || 0
          percent += statistic[id].percent || 0
        })

        return [total / report.statistics.populationOrHouseholds, total, percent, index]
      }

      const [value, total, percent, index] = calculateValue()

      row.push({
        text: helper.formatCell(value),
        style: 'defaultCell',
        border: [false, false, false, false],
      })

      const graphValue = (value && index && value / index) || 0
      row.push(valueGraph(graphValue, COLORS[i % 2]))
      row.push({
        text: helper.formatCell(graphValue * 100),
        bold: true,
        style: 'defaultCell',
      })
    })

    rows.push(row)
  })

  const head2: any[] = [{ text: '', border: [false, false, false, false] }]
  reportModel.forEach((report) => {
    head2.push('')
    head2.push('')
    head2.push('')
  })

  const options = {
    layout: {
      hLineWidth: function (i: number, node: any) {
        return i == 0 ? 2 : 0
      },
      vLineWidth: function (i: number, node: any) {
        const firstInSegment = ~~(i % 3) == 1
        if (firstInSegment) return 2
        else return 0
      },
      hLineColor: function (i: number, node: any) {
        return helper.tableBorder(0)
      },
      vLineColor: function (i: number, node: any) {
        const index = Math.floor((i - 1) / 3)
        if (index == 0 || index >= reportModel.length) return helper.tableBorder(0)
        else return helper.tableBorder(1)
        // return  TABLE_BORDERS[(~~(i - 1) / 3)]//(i === 0 || i === node.table.widths.length) ? 'black' : 'black'
      },

      fillColor: function (i: number, node: any) {
        if (i < 3) {
          return null
        }
        return TABLE_BACKGROUNDS[i % TABLE_BACKGROUNDS.length]
      },
    },
  }

  return helper.table(head2, rows, 'defaultTable', options) //new TableOptions(3, 3))
}
