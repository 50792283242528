<ng-container *ngIf="(model.value$ | async); let report; else noData">
  <p></p>
  <care-home-segment-table [statistics]="report.statistics" [model]="report.current"></care-home-segment-table>
  <p></p>

  <forecast-year-slider
    [year]="settings.careHomeForecastYearUpdates | async"
    (onYearChange)="onYearChange($event)"
    ></forecast-year-slider>
  <care-home-segment-table [statistics]="report.statistics" [model]="report.horizon"></care-home-segment-table>
  <p></p>

  <table>
    <tr>
      <th width="50%">Care Home Provision</th>
      <th>Homes</th>
      <th>Beds</th>
      <th>En Suite</th>
    </tr>

    <tr>
      <td width="50%">Current</td>
      <td>{{report.current.provisionHomes | number: '1.0-0'}}</td>
      <td>{{report.current.provisionBeds | number: '1.0-0'}}</td>
      <td>{{report.current.provisionEnSuite | number: '1.0-0'}}</td>
    </tr>
  </table>

  <p></p>

  <table>
    <tr>
      <th colspan="3">Over Under supply</th>
    </tr>

    <tr>
      <td width="50%">Current</td>
      <td [class.negativeNumber]="report.current.overUnderSupply < 0">{{report.current.overUnderSupply | maybeNegativeNumber: '1.0-0'}}</td>
      <td>{{overUnderSupplyText(report.current.overUnderSupply) }}</td>
    </tr>

    <tr>
      <td width="50%">Horizon ({{report.horizonYear}})</td>
      <td [class.negativeNumber]="report.horizon.overUnderSupply < 0">{{report.horizon.overUnderSupply | maybeNegativeNumber: '1.0-0'}}</td>
      <td>{{overUnderSupplyText(report.horizon.overUnderSupply) }}</td>
    </tr>    
  </table>  
</ng-container>

<ng-template #noData><span class="no-data">No Data</span></ng-template>