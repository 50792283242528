import {ComparisonReportTemplate, DataSet}  from '../../common/models'
import {Injectable} from '@angular/core';
import {RESTHelper} from '@targomo/client';

export type ComparisonReportTemplate = ComparisonReportTemplate

@Injectable()
export class ComparisonReportTemplateEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/comparisonReportTemplates')
  }

  get(profile: {id: number}) {
    return this.http.get<ComparisonReportTemplate>(`/${profile.id}`)
  }

  save(profile: ComparisonReportTemplate) {
    return this.http.put<ComparisonReportTemplate>(`/${profile.id}`, profile)
  }

  delete(profile: ComparisonReportTemplate) {
    return this.http.delete<ComparisonReportTemplate>(`/${profile.id}`)
  }

  create(profile: ComparisonReportTemplate) {
    return this.http.post<ComparisonReportTemplate>(`/`, profile)
  }

  findAll() {
    return this.http.get<ComparisonReportTemplate[]>('')
  }

  me() {
    return this.http.get<ComparisonReportTemplate[]>('/me')
  }  
}
