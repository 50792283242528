import { Component } from '@angular/core';
import { Indicators, SubscriberComponent } from '@targomo/client';
import { array as arrays } from '@targomo/common';
import { AppModel } from '../../model/appModel.service';

@Component({
  selector: 'benchmark-report',
  templateUrl: './benchmarkReport.html'
})
export class BenchmarkReportComponent extends SubscriberComponent {
  currentSorting: string = 'fascia'
  inverseSorting = false
  locations: any[]
  loading: Promise<any>

  columns = [
    {key: "fascia", label: "Name"},
    {key: "street", label: "Street"},
    {key: "town", label: "Town"},
    {key: "postcode", label: "Postcode"},
    {key: "value0", label: "All Pop"},
    {key: "value1", label: "All HH"},
    {key: "value2", label: "Target HH"},
    {key: "value3", label: "Students HH"},
    {key: "value4", label: "All"},
    {key: "value5", label: "Target"},
    {key: "value6", label: "All"},
    {key: "value7", label: "Target"},
    {key: "value8", label: "Complementary"},
    {key: "value9", label: "Competitor"},
  ]

  columnsExport = [
    {key: "fascia", label: "Name"},
    {key: "street", label: "Street"},
    {key: "town", label: "Town"},
    {key: "postcode", label: "Postcode"},
    {key: "value0", label: "All Pop"},
    {key: "value1", label: "All HH"},
    {key: "value2", label: "Target HH"},
    {key: "value3", label: "Students HH"},
    {key: "value4", label: "All (Workforce)"},
    {key: "value5", label: "Target (Workforce)"},
    {key: "value6", label: "All (Spend)"},
    {key: "value7", label: "Target (Spend)"},
    {key: "value8", label: "Complementary"},
    {key: "value9", label: "Competitor"},
  ]

  constructor(readonly appModel: AppModel, private indicators: Indicators) {
    super()

    this.watch(this.appModel.maxiReportModel.benchmarkChilango.loading$, loading => {
      this.loading = this.indicators.add(loading)
    })

    this.watch(this.appModel.maxiReportModel.benchmarkChilango.values$, locations => {
      if (locations) {
        this.locations = locations

        setTimeout(async () => {
          this.updateSort()
        })
      }
    })    
  }

  async export() {
    await this.loading

    return {locations: this.locations, columns: this.columnsExport, fileName: 'benchmark_chilango'}
  }

  sortByColumn(column: string) {
    if (this.currentSorting == column) {
      this.inverseSorting = !this.inverseSorting
    }

    this.currentSorting = column
    this.updateSort()
  }

  updateSort() {
    arrays.sortBy(this.locations, this.currentSorting, this.inverseSorting)
  }
}