import {Observable} from 'rxjs/Observable'
import {BehaviorSubject} from 'rxjs/BehaviorSubject'
import { Subject } from "rxjs/Subject";
import { Observer } from "rxjs/Observer";
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/first';

export class AsyncObservable<T> {
  public readonly value: Observable<T>
  public readonly promise: Observable<Promise<T>>

  constructor(source: Observable<Promise<T>>, initial?: T) {
    const valueSubject = new BehaviorSubject<T>(initial)
    const promiseSubject = new BehaviorSubject<Promise<T>>(Promise.resolve(initial))

    this.value = valueSubject
    this.promise = promiseSubject

    source.subscribe(promise => promiseSubject.next(promise))
    var latest: Promise<T>

    promiseSubject.subscribe(async promise => {
      latest = promise

      const value = await promise

      // only emit value if promise value has not been updated
      if (latest == promise) {
        valueSubject.next(value)
      }
    })
  }

  public getValue(): T {
    const valueSubject = <BehaviorSubject<T>>this.value
    return valueSubject.getValue()
  }

  public async waitValue(): Promise<T> {
    const value =  await this.value.filter(value => value !== undefined).first().toPromise()
    return value
  }
}