import { Component, ViewChild } from '@angular/core'
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/operator/map'

import 'rxjs/add/observable/from'
import { PolygonRequestOptions, TargomoClient, SRID, StatisticsSet } from '@targomo/core'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Router, NavigationEnd } from '@angular/router'
import { AppModel } from '../model/appModel.service'
import { MatIconRegistry } from '@angular/material'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'app'

  constructor(appModel: AppModel, router: Router, iconReg: MatIconRegistry, sanitizer: DomSanitizer) {
    for (let i = 0; i < 14; i++) {
      iconReg.addSvgIcon(
        'tgm_marker_' + i,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/images/marker_icons/marker-${i}.svg`)
      )
    }

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        appModel.settings.displaySettings.nextProperty('cellHover', (event.url || '').indexOf('/cellHover') > -1)
      }
    })
  }
}
