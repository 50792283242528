import { tableOptions, PDFHelper, TABLE_BACKGROUNDS } from './helper'
import { ReportModel } from './exportPdfReport'
import { LIFESTYLE_SEGMENTS, LIFESTYLE_SEGMENTS_VIEW } from '../mini/model/reportSegments'
import { SPEND_REPORT, PDF_REPORT_SPEND_SUMMARY } from './values'
import { ReportPerCapitaHousehold } from '../../model/settingsModel'

export function spendSummaryReport(
  reportModel: ReportModel,
  helper: PDFHelper,
  householdOrCapita: ReportPerCapitaHousehold
) {
  const currencySymbol = reportModel[0].statistics.currencySymbol()
  const rows: any[] = []
  let headsMinutes: any[] = [[{ text: '', border: [false, false, false, false] }]]

  const createHeads = () => {
    const head: any[] = []

    head.push({ text: 'Segment', style: 'defaultCellLeft', group: true })
    const titleString = householdOrCapita === ReportPerCapitaHousehold.PER_CAPITA ? 'Per Capita' : 'Per Household'

    reportModel.forEach((report) => {
      headsMinutes[0] = headsMinutes[0].concat([
        { text: report.nameFull, style: 'defaultCellCenter', colSpan: 4 },
        '',
        '',
        '',
      ])

      head.push({ text: titleString + ' Spend (' + currencySymbol + ')', style: 'defaultCell' })
      head.push({ text: 'Index vs ' + report.statistics.regionLabel() + ' Avg', style: 'defaultCell' })
      head.push({ text: '' + report.statistics.regionLabel() + ' Avg', style: 'defaultCell' })
      head.push({ text: 'Total (' + currencySymbol + '000)', style: 'defaultCell' })
    })

    rows.push(head)
    // rows.push(subhead)
  }

  createHeads()

  const COLORS = ['#fbfdf9', '#e7f1f9']

  PDF_REPORT_SPEND_SUMMARY.forEach((segment, i) => {
    const row: any[] = [segment.name]

    reportModel.forEach((report) => {
      const statistic = report.statistics.lifestyle.spendSummary

      const calculateValue = () => {
        var total = 0
        var percent = 0
        var index = 0

        segment.items.forEach((id) => {
          total += statistic[id].total || 0
          index += statistic[id].index || 0
          percent += statistic[id].percent || 0
        })

        return [total / report.statistics.populationOrHouseholds || 0, total, percent, index]
      }

      const [value, total, percent, index] = calculateValue()

      row.push({
        text: helper.formatCell(value),
        style: 'defaultCell',
      })

      const graphValue = (value && index && value / index) || 0
      // row.push(valueGraph(graphValue, COLORS[i % 2]))
      row.push({
        text: helper.formatCell(graphValue * 100),
        bold: true,
        style: 'defaultCell',
      })

      row.push({
        text: helper.formatCell(index),
        style: 'defaultCell',
      })

      row.push({
        text: helper.formatCell(total / 1000),
        style: 'defaultCell',
      })
    })

    rows.push(row)
  })

  return helper.table(null, headsMinutes.concat(rows), 'defaultTable', tableOptions(helper, reportModel, 2, 4))
}
