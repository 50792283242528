import { Component, EventEmitter, Output } from '@angular/core'
import { Indicators, SubscriberComponent, TgmQuickDialogs } from '@targomo/client'
import { MatchpointEndpoint } from '../../../api/matchpoint'
import { SavedSession } from '../../../api/savedSession'
import { SettingsModel } from '../../../model/settingsModel'
import { MatchpointConfigPanelComponent } from '../matchpointConfigPanel/matchpointConfigPanel.component'

@Component({
  selector: 'load-matchpoint-panel',
  styleUrls: ['./loadMatchpointPanel.component.less'],
  templateUrl: './loadMatchpointPanel.component.html',
})
export class LoadMatchpointPanelComponent extends SubscriberComponent {
  loadingProjects: Promise<SavedSession[]>
  projects: any[]

  @Output() back = new EventEmitter()

  constructor(
    private indicators: Indicators,
    private matchpointEndpoint: MatchpointEndpoint,
    private quickDialogs: TgmQuickDialogs,
    private parent: MatchpointConfigPanelComponent
  ) {
    super()

    this.init()
  }

  async init() {
    this.projects = await (this.loadingProjects = this.matchpointEndpoint.listUserSettings())
  }

  async loadExport(layer: any) {
    const loaded = await this.indicators.add(this.matchpointEndpoint.loadUserSettings(layer.id))
    this.parent.updateModel(loaded.data)

    this.quickDialogs.snack('Matchpoint selection criteria loaded')
    this.back.emit(true)
  }

  async deleteExport(layer: any) {
    if (
      await this.quickDialogs.confirm({
        title: 'Delete Matchpoint Selection Criteria',
        message: `Would you like to delete matchpoint selection criteria ${layer.name}?`,
        confirm: 'Delete',
        cancel: 'Cancel',
      })
    ) {
      await this.matchpointEndpoint.deleteUserSettings(layer.id)

      this.init()
    }
  }

  clickBack() {
    this.back.emit(true)
  }
}
