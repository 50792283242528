import {
  CompositeLayer,
  DefaultMapLayer,
  FeatureInteractionLayer,
  FilterMapLayer,
  FilterMapLayerOptions,
  getBasePath,
  MapLayerPosition,
  TileMapSource,
} from '@targomo/client'
import { Observable } from 'rxjs/Observable'
import { map, startWith } from 'rxjs/operators'
import { AppModel } from '../../model/appModel.service'
import { GREAT_BRITAIN_WORKFORCE } from '../../model/constants'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'
import { PostcodeAnalysisModel } from '../../model/postcodeAnalyssModel'

export class PostcodeAnalysisComposite extends CompositeLayer {
  private hoverLayer: FilterMapLayer
  private layerOptions: FilterMapLayerOptions
  private layer: DefaultMapLayer

  constructor(
    map: CustomMapboxComponent,
    private hoverMode: Observable<boolean>,
    private appModel: AppModel,
    private postcodesAnalysisModel: PostcodeAnalysisModel
  ) {
    super(map as any)

    this.init()
    this.initEvents()
  }

  private init() {
    const sourceMapUrl = getBasePath() + 'api/postcodeAnalysis/tiles/{z}/{x}/{y}.mvt'
    const source = new TileMapSource(<any>sourceMapUrl)

    const options = this.postcodesAnalysisModel.categories$.pipe(
      startWith({} as any),
      map((categoriesMap) => {
        const colorsMatch: any[] = []
        Object.keys(categoriesMap || {}).forEach((key) => {
          colorsMatch.push(key)
          colorsMatch.push(categoriesMap[key])
        })

        return (this.layerOptions = {
          type: 'circle',
          sourceLayer: '0',
          paint: {
            'circle-color': ['match', ['to-string', ['get', 'name']], ...colorsMatch, '#3288bd'],
            'circle-opacity': 0.4,
            'circle-stroke-width': 0,
            'circle-stroke-color': '#3288bd',
            'circle-radius': ['interpolate', ['linear'], ['zoom'], 1, 1, 25, 15],
          },
        })
      })
    )

    const layer = (this.layer = new DefaultMapLayer(this.map, source, options).setPosition(
      MapLayerPosition.BELOW_MARKERS
    ))

    layer.setVisible(false)

    this.watch(this.appModel.settings.postcodesAnalysisLayerUpdates, (state) => {
      layer.setVisible(state)
    })
  }

  //   const filterOptions: FilterMapLayerOptions = (this.filterOptions = {
  //     sourceLayer: '21',
  //     type: 'circle',
  //     paint: {
  //       'circle-color': 'rgba(255, 255, 0, 0.7)', //"#3288bd",
  //       'circle-opacity': 0.5,
  //       'circle-stroke-width': 1,
  //       'circle-stroke-color': 'rgba(255, 255, 0, 1)',
  //       'circle-radius': {
  //         property: '0',
  //         stops: [
  //           [0, 2],
  //           [54068, 30],
  //         ],
  //       },
  //     },
  //   })

  //   this.hoverLayer = new FilterMapLayer(this.map, source, filterOptions).setPosition(MapLayerPosition.BELOW_MARKERS)
  //   this.hoverLayer.setVisible(false)

  //   this.updateZoom()
  //   return layer
  // }

  async initEvents() {
    const interaction = new FeatureInteractionLayer(this.layer)
    this.watch(interaction.events.hover, (event) => {})
  }
  //   let visible: boolean = false

  //   this.remember(
  //     <any>this.mapModel.hoverWorkforce.subscribe((feature) => {
  //       if (!visible) {
  //         return
  //       }

  //       const list: (string | number)[] = ['in', 'id']

  //       if (feature) {
  //         list.push(feature.properties.id)
  //       }

  //       this.hoverLayer.setVisible(visible && list.length > 2)
  //       this.hoverLayer.setFilter(list)
  //     })
  //   )

  //   this.remember(
  //     <any>Observable.combineLatest(this.workforceLayerUpdates, this.hoverMode).subscribe(([state, hover]) => {
  //       visible = state && hover

  //       if (!visible) {
  //         this.hoverLayer.setVisible(false)
  //       }

  //       this.streetsLayer.setVisible(state)
  //     })
  //   )

  //   const interaction = new FeatureInteractionLayer(this.streetsLayer)
  //   this.watch(interaction.events.hover, (event) => {
  //     this.mapModel.hoverWorkforce.next(event.feature)
  //   })

  //   this.initZoom()
  // }

  setVisible(value: boolean): this {
    throw new Error('Method not implemented.')
  }
  isVisible(): boolean {
    throw new Error('Method not implemented.')
  }
}
