import { DecimalPipe } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { MatButtonToggleChange } from '@angular/material'
import { GraphValue, SubscriberComponent } from '@targomo/client'
import { AllStatistics, CensusStatistics, StatisticsValue } from '../../../../../common/models/statistics/statistics'
import { STATISTICS_ALTERNATE_LABELS } from '../../../../../common/models/statistics/statisticsAlternateLabels'
import { CustomBarGraphOptions } from '../../../../components/tgm-custom-bargraph/bargraph.component'
import { AppModel } from '../../../../model/appModel.service'
import { ReportPerCapitaHousehold } from '../../../../model/settingsModel'
import { graphLabelMeasure } from '../../../util/graphLabelMeasure'
import { TextMeasurer } from '../../../util/textMeasurer'
import { NodeTransformFunction } from '../../model/reportSegments'

export const MEASURER = new TextMeasurer('12px normal "Open Sans"')
const DECIMAL_PIPE = new DecimalPipe('en')

@Component({
  selector: 'histogram-table',
  templateUrl: './histogramTable.component.html',
  styleUrls: ['./histogramTable.component.less'],
})
export class HistogramTableComponent extends SubscriberComponent implements OnInit, OnChanges {
  @Input() node: {
    id: string
    name: string
    children: { id: string; name: string }[]
    transform?: NodeTransformFunction
  }
  @Input() statistics: CensusStatistics
  @Input() allStatistics: AllStatistics
  @Input() allColumns: boolean
  @Input() enableGraph: boolean
  @Input() view = 'graph'

  @Output() viewChange = new EventEmitter()
  @Output() clickRow = new EventEmitter()

  graphModel: GraphValue[]
  graphOptions: CustomBarGraphOptions

  currencySymbol = '£'
  perHouseholdCapitaTitle: string = ''

  statisticsValues: { [id: string]: StatisticsValue }
  children: { id: string; name: string; intensity?: boolean }[]

  constructor(private appModel: AppModel) {
    super()
  }

  ngOnInit() {
    this.updateModel()

    this.watch(this.appModel.statistics.census.value, (statistics) => {
      if (statistics) {
        this.currencySymbol = this.allStatistics.currencySymbol()
        this.perHouseholdCapitaTitle =
          statistics.householdsOrPopulation === ReportPerCapitaHousehold.PER_CAPITA ? 'Per Capita' : 'Per Household'
        this.updateModel()
      }
    })
  }

  ngOnChanges() {
    this.updateModel()
  }

  updateModel() {
    if (this.node.transform) {
      const { children, statisticsValues } = this.node.transform(
        this.statistics && this.statistics[this.node.id],
        this.node.children
      )
      this.statisticsValues = statisticsValues
      this.children = children
    } else {
      this.statisticsValues = this.statistics && this.statistics[this.node.id]
      this.children = this.node.children
    }

    this.updateGraphModel()
  }

  updateGraphModel() {
    const maximumText = Math.round(
      (this.node &&
        this.children &&
        this.children.reduce((acc, cur) => {
          const label = STATISTICS_ALTERNATE_LABELS[cur.name] || cur.name
          const width = MEASURER.width(label) * 1.2
          return Math.max(acc, width)
        }, 0)) ||
        0
    )

    this.graphOptions = new CustomBarGraphOptions({
      width: 420,
      height: 300 + maximumText,
      axisLabelDistance: 5,
      yAxisLabel: 'Reachable',
      margin: { left: 80, bottom: 50 + maximumText },
      xAxisLabelRotate: -90,

      color: (item: any, index: number) => {
        if (this.children[index] && this.children[index].intensity) {
          return '#007bcc' // ...more intensity...
        } else {
          return '#33aeff'
        }
      },

      tooltip: (item: any) => {
        return `
        <table>
          <tr>
            <td class="legend-color-guide"><div style="background-color: ${item.color}"></div></td>
            <td class="key">${item.data ? item.data.label : ''}</td>
          </tr>
          <tr>
            <td></td>
            <td class="value">${item.data ? DECIMAL_PIPE.transform(item.data.value, '1.0-0') : ''}</td>
          </tr>
        </table>
        `
      },
    })

    this.graphModel = this.node &&
      this.children && [
        {
          key: '',
          values: this.children.map((child) => {
            const label = STATISTICS_ALTERNATE_LABELS[child.name] || child.name
            return {
              label: label,
              value: this.statisticsValues && this.statisticsValues[child.id] && this.statisticsValues[child.id].total,
            }
          }),
        },
      ]

    // Calculate graph ticks, because prolbem
    const maxValue = Math.max.apply(
      null,
      (this.graphModel[0] && this.graphModel[0].values.map((item) => item.value)) || []
    )
    const tickPart = maxValue / 4
    const multiplierBase = Math.floor(tickPart / Math.pow(10, Math.floor(Math.log10(tickPart))))
    const multiplier = multiplierBase >= 5 ? 5 : multiplierBase >= 2 ? 2 : 1
    const tickBase = multiplier * Math.pow(10, Math.floor(Math.log10(tickPart)))

    if (tickBase) {
      const tickValues = []
      for (let v = 0; v < maxValue; v += tickBase) {
        tickValues.push(v)
      }

      this.graphOptions.yAxisTickValues = tickValues
    }

    this.graphOptions.margin.left = graphLabelMeasure(MEASURER, this.graphModel, this.graphOptions)
    this.graphOptions.axisLabelDistance = 20
  }

  viewChangeClick(event: MatButtonToggleChange) {
    this.viewChange.emit(event.value)
  }

  click(node: any) {
    this.clickRow.emit(node)
  }
}
