import { AppModel } from '../../../../model/appModel.service'
import { Place } from '../../../../api/index'
import { Component, NgZone, ChangeDetectorRef } from '@angular/core'
import { SubscriberComponent } from '@targomo/client'
import { Observable } from 'rxjs/Observable'
import { object as objects } from '@targomo/common'

@Component({
  selector: 'source-title-panel',
  templateUrl: './sourceTitlePanel.html',
  styleUrls: ['./sourceTitlePanel.less'],
})
export class SourceTitlePanelComponent extends SubscriberComponent {
  // The application wide model keeping track of selected and visible places
  sources: Place[]
  fascias: { name: string; count: number }[]

  // The list of places that are to be rendered in the view
  places: Place[]

  zoneSelection: boolean = false
  zoneSelectionTitle: string = ''

  constructor(private appModel: AppModel, private changeDetectorRef: ChangeDetectorRef) {
    super()
  }

  ngOnInit() {
    this.watch(this.appModel.titles.value$, (titles) => {
      this.sources = titles.sources
      this.fascias = titles.fascias
      this.zoneSelection = titles.zoneSelectionExists
      this.zoneSelectionTitle = titles.zoneSelectionTitle
      this.changeDetectorRef.detectChanges()
    })
  }
}
