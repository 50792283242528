export const SWITCHABLE_INDICES_COLUMNS: {[key: string]: boolean} = {
  'BakedGoods': true,
  'Confectionery': true,
  'OtherFoodStuffs': true,
  'FruitAndVegetables': true,
  'MeatFishAndPoultry': true,
  'AlcoholicDrinks': true,
  'CleaningAndPaperProducts': true,
  'NonAlcoholicDrinks': true,
  'PetsAndPetfood': true,
  'BabyEquipment': true,
  'Books': true,
  'Cards': true,
  'Stationery': true,
  'Childrenswear': true,
  'ElectricalGasAppliances': true,
  'ElectricalGasAccessories': true,
  'FashionAccessories': true,
  'FloorCoverings': true,
  'Footwear': true,
  'Furniture': true,
  'Haberdashery': true,
  'HealthBeauty': true,
  'HomeFurnishingsGifts': true,
  'HomeImprovementsAndGardening': true,
  'Jewellery': true,
  'Menswear': true,
  'MobilePhones': true,
  'MotorAccessories': true,
  'MusicAndVideo': true,
  'NewspapersAndMagazines': true,
  'OTCPharmaceuticals': true,
  'Photography': true,
  'SoftFurnishingsLinens': true,
  'SpectaclesAndLenses': true,
  'SportsAndCampingEquipment': true,
  'Tobacco': true,
  'Toys': true,
  'EatingOut': true,
  'TravelGoods': true,
  'Womenswear': true,
  'Cinemas': true,
  'LeisureCentres': true,
  'ClubsDancesdiscoBingo': true,
  'LiveEntertainment': true,
  'MuseumZooThemeParkHouse': true,
  'AlcoholOutOfHome': true,
  'Restaurantandcafemeals': true,
  'ComparisonTotal': true,
  'FoodTotal': true,
  'Books_Cards_Stationery': true,
  'Fashion_Footwear_Jewellery': true,
  'Electrical_Goods_Mobile_Phones_Photography': true,
  'Home_Improvements_Gardening': true,
  'Floor_Coverings': true,
  'Home_Furnishings_Gifts': true,
  'Soft_Furnishings_Linens': true,
  'Health_Beauty_inc_OTC_Pharmacy': true,
  'Music_DVDs_Games_Toys': true,
  'Other_Spectacles_Lenses_Travel_Goods_Motor_Accessories': true,
  'Sports_And_Camping_Equipment': true,
  'Eating_Out_Restaurants_Cafes': true,
  'Leisure_Centres_Attractions_live_Entertainment_etc': true,
}

Object.keys(SWITCHABLE_INDICES_COLUMNS).forEach(key => {
  SWITCHABLE_INDICES_COLUMNS[key.toUpperCase()] = true
})