import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn:'root'
})
export class OpenDialog {
  constructor(/** @hidden */ private dialog: MatDialog) {
  }

  async show<T, U>(dialogCompoment: new (... args: any[]) => T, options?: U) {
    const dialogRef = this.dialog.open(dialogCompoment, {
      disableClose: false,
      data: options
    })

    const result = await dialogRef.afterClosed().toPromise()
    if (result) {
      return result
    }    
  }
}
