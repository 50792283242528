import { Injectable } from '@angular/core'
import { AppModel } from './appModel.service'

import 'rxjs/add/operator/take'
import { HomeComponent } from '../main/home.component'
import { BoundingBox } from '@targomo/core'
import { getBasePath } from '@targomo/client'
import { Place, CategoryEndpoint } from '../api/index'
import { STATISTICS_WITH_NONE } from './constants'
import { DataSetLocation, CustomLocation } from './index'
import { object as objects } from '@targomo/common'
import { ZoneLayersModel } from './zoneLayersModel'
import { LabelsConfig } from './labelsConfig'
import { LayerStateStack } from './layerStateStack.service'
import { PlanningLocation, AbstractLocation } from './entities'
import { ExtendedBehaviorSubject } from '../util/extendedBehaviorSubject'
import { SavedSession } from '../api/savedSession'
import { DisplaySettings } from './settingsModel'
import { UrlInitialState } from '../resolvers/urlInitialStateResolver'
import { CustomMapboxComponent } from '../main/mapBox/mapbox.component'

function upper(value: string) {
  return (value || '').toUpperCase()
}

export function serializePlace(source: Place): SerializedPlace {
  return {
    id: source.id,
    storeId: source.storeId,
    category: source.category.code,
    dataSetName: (source.category as any).dataSetName,
    type: source.constructor.name,
    data: source instanceof PlanningLocation ? source : null,
  }
}

export function matchPlaceWithSerialized(place: Place, placeCandidate: SerializedPlace) {
  if (place.id === placeCandidate.id && +place.id < 0) {
    return true
  }

  if (
    place.storeId === placeCandidate.storeId &&
    place.category &&
    upper(place.category.code) === upper(placeCandidate.category)
  ) {
    if (placeCandidate.dataSetName) {
      if (upper(placeCandidate.dataSetName) == upper((place.category as any).dataSetName)) {
        return true
      }
    } else {
      return true
    }
  }

  return false
}

export class SerializedPlace {
  id: number
  storeId: string
  category: string
  dataSetName: string
  type: string
  data: Partial<AbstractLocation>
}

@Injectable()
export class SavedSessionModel {
  public readonly currentSession = new ExtendedBehaviorSubject<SavedSession>(null)

  constructor(
    private appModel: AppModel,
    private categoryEndpoint: CategoryEndpoint,
    private sectorsModel: ZoneLayersModel,
    private layerStateStack: LayerStateStack
  ) {}

  async snapshot(mapView: CustomMapboxComponent) {
    const map = await mapView.getMap()
    const { markerStyle, sourceMarkerStyle, ...settings } = await this.appModel.displaySettings
      .asObservable()
      .take(1)
      .toPromise()
    const labelLocations = this.appModel.places.places
      .getValue()
      .filter((place: any) => place.properties['marker-label-visible'])
      .map((place) => serializePlace(place))

    // (await this.appModel.places.labelLocations.asObservable().take(1).toPromise()).map((source: any) => source.id)
    const sources = await this.appModel.places.sources.getValues().map((source) => {
      return serializePlace(source)
    })

    const selectedPlacePlanningRisk = this.appModel.places.selectedPlacePlanningRisk.getValue()
    const planningApplicationsPlaces = this.appModel.places.planningApplicationsPlaces.getValue()

    const customPlaces = await this.appModel.places.customPlaces.getValues()
    const customProfiles = await this.appModel.places.customProfiles.getValues()
    const visibleCategories = this.appModel.places.getVisibleCategories()
    const layerStateState = await this.layerStateStack.snapshot()

    const sectors = await this.sectorsModel.snapshot()
    const roadLinkLabelsIds = await this.appModel.places.labelRoadLink.getValues()
    const pinnedCategories = await this.appModel.places.categoryPinned.getValues()

    return {
      version: 2,
      bounds: mapView.getBounds(),
      settings,
      labelLocations,
      sources,
      customPlaces,
      customProfiles,
      visibleCategories,
      pitch: map.getPitch(),
      bearing: map.getBearing(),
      sectors,
      deletedLocations: this.appModel.places.deletedLocations,
      layerStateState,
      planningApplicationsPlaces,
      selectedPlacePlanningRisk,
      roadLinkLabelsIds,
      pinnedCategories,
    }
  }

  private placesQuickMap(list: any[]) {
    const sourcesMap: any = {}

    if (!list) {
      return sourcesMap
    }

    list.forEach((id: any) => {
      if (id.storeId != null) {
        sourcesMap[id.storeId] = true
      } else if (id.id != null) {
        sourcesMap[id.id] = true
      } else {
        sourcesMap[id] = true
      }
    })

    return sourcesMap
  }

  private matchPlace(place: Place, list: any[], quickMap: any) {
    if (!list) {
      return false
    }

    if (quickMap[place.id] || quickMap[place.storeId]) {
      for (let placeCandidate of list) {
        if (typeof placeCandidate === 'number') {
          if (place.id === placeCandidate) {
            return true
          }
        } else {
          if (place.id === placeCandidate.id && +place.id < 0) {
            return true
          }

          if (
            place.storeId === placeCandidate.storeId &&
            place.category &&
            upper(place.category.code) === upper(placeCandidate.category)
          ) {
            if (placeCandidate.dataSetName) {
              if (upper(placeCandidate.dataSetName) == upper((place.category as any).dataSetName)) {
                return true
              }
            } else {
              return true
            }
          }
        }
      }
    }

    return false
  }

  private fixDuplicates(list: { id: number; storeId?: string }[]) {
    let minId = -1

    list.forEach((item) => {
      minId = Math.min(item.id, minId)
    })

    minId--

    const foundMap: any = {}

    list.forEach((item) => {
      if (foundMap[item.id]) {
        const original = item.id
        item.id = minId--

        if (item.storeId && +item.storeId === original) {
          item.storeId = '' + item.id
        }
      } else {
        foundMap[item.id] = true
      }
    })
  }

  async restore(session: any, mapView: CustomMapboxComponent) {
    this.appModel.disableNextJump()

    const currentSettings = await this.appModel.displaySettings.asObservable().take(1).toPromise()

    const settings: DisplaySettings = {
      ...session.settings,
      showOnlyMappedCategories: false, // don't save this
      sourceMarkerStyle: currentSettings.sourceMarkerStyle,
      markerStyle: currentSettings.markerStyle,
      cellHover: false,
      statistic:
        (session.settings &&
          session.settings.statistic &&
          STATISTICS_WITH_NONE.filter((statistic) => statistic.id === session.settings.statistic.id)[0]) ||
        STATISTICS_WITH_NONE[0],

      labelsConfig: session.settings && session.settings.labelsConfig,
      // pointAndClickSource: null,
    }

    if (
      !settings.travelColors ||
      !(settings.travelColors instanceof Array) ||
      settings.travelColors.filter((color) => !!color).length !== 6
    ) {
      settings.travelColors = currentSettings.travelColors
    }

    ;(<any>this.appModel.places).deletedLocations = session.deletedLocations || []
    this.appModel.displaySettings.next(settings)
    this.appModel.places.customProfiles.update(session.customProfiles)

    const customProfilesMap: any = {}
    session.customProfiles.forEach((profile: any) => {
      profile.categories.forEach((category: any) => {
        customProfilesMap['' + profile.id + '@' + category.id] = category
        customProfilesMap[category.id] = category
      })
    })

    const customPlaces = session.customPlaces.map((placeData: Place) => {
      const place: Place = <any>objects.assign(new CustomLocation(), placeData)
      place.category =
        customProfilesMap['' + place.dataSetId + '@' + place.category.id] ||
        customProfilesMap[place.category.id] ||
        this.categoryEndpoint.custom(placeData.fascia)
      return place
    })

    this.fixDuplicates(customPlaces)

    this.appModel.places.customPlaces.update(customPlaces)

    const sessonSources: any[] = session.sources || []
    const sourcesMap: any = this.placesQuickMap(sessonSources)

    await this.appModel.placesLoadingModel.loadDataSetsByName(
      sessonSources.map((row) => row.dataSetName).filter((name) => !!name)
    )

    let allPlaces = await this.appModel.places.originalPlaces.value.take(1).toPromise()

    const labelLocationsMap: any = this.placesQuickMap(session.labelLocations)

    allPlaces = allPlaces.concat(customPlaces)

    allPlaces.forEach((place: any) => {
      place.properties['marker-label-visible'] = this.matchPlace(place, session.labelLocations, labelLocationsMap)
      labelLocationsMap[place.id]
    })

    this.appModel.places.sources.update(
      allPlaces
        .filter((place) => this.matchPlace(place, sessonSources, sourcesMap))
        .concat(
          sessonSources
            .filter((data) => data.type === 'PlanningLocation')
            .map((item) => new PlanningLocation(item.data))
        )
    )

    this.appModel.places.sessionRestored()
    this.appModel.places.setVisibleCategories(session.visibleCategories || {}, session.version > 1)
    await this.appModel.places.restoreDeletedState()

    const map = await mapView.getMap()

    mapView.setBounds(session.bounds, <any>{ animate: false })
    this.appModel.sessionLoaded.next(session)

    if (session.sectors) {
      this.sectorsModel.restoreState(session.sectors)
    }

    if (session.layerStateState) {
      this.layerStateStack.restore(session.layerStateState)
    }

    if (session.selectedPlacePlanningRisk) {
      this.appModel.places.selectedPlacePlanningRisk.next(new DataSetLocation(session.selectedPlacePlanningRisk))
    }

    if (session.planningApplicationsPlaces) {
      this.appModel.places.planningApplicationsPlaces.next(session.planningApplicationsPlaces)
    }

    if (session.roadLinkLabelsIds) {
      this.appModel.places.labelRoadLink.update(session.roadLinkLabelsIds)
    }

    if (session.pinnedCategories) {
      this.appModel.places.restorePinnedCategories(session.pinnedCategories)
    }

    setTimeout(() => {
      if (session.pitch != null) {
        map.setPitch(session.pitch)
      }

      if (session.bearing != null) {
        map.setBearing(session.bearing)
      }
    })

    setTimeout(() => {
      mapView.zone.run(() => {})
    }, 500)
  }

  async restoreUrlSession(state: UrlInitialState, mapView: CustomMapboxComponent) {
    if (!isNaN(state.lat) && !isNaN(state.lng)) {
      mapView.setView({ lat: +state.lat, lng: +state.lng }, false)
    }

    if (!isNaN(state.zoom)) {
      mapView.setZoom(state.zoom)
    }

    if (state.layers) {
      try {
        const expanded = await this.appModel.places.expandCategoriesCompact(state.layers)
        this.appModel.places.setVisibleCategories(expanded || {}, true)
      } catch (e) {
        console.error(e)
      }
    }
  }

  async snapshotUrlSession(mapView: CustomMapboxComponent) {
    const map = await mapView.getMap()
    const { lat, lng } = map.getCenter()
    const zoom = map.getZoom()

    const layers = this.appModel.places.getVisibleCategoriesCompact()

    const payload = JSON.stringify({ zoom, lat, lng, layers })

    return getBasePath() + `#/?state=${encodeURIComponent(payload)}`
  }
}
