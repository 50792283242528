export const STATISTICS_FORECAST = [
// {id: 276, name: 'Total_HHs'},
// {id: 277, name: 'Pop_2018'},
  {id: 278, name: 'Age_0_4_2021'},
  {id: 279, name: 'Age_5_9_2021'},
  {id: 280, name: 'Age_10_14_2021'},
  {id: 281, name: 'Age_15_19_2021'},
  {id: 282, name: 'Age_20_24_2021'},
  {id: 283, name: 'Age_25_29_2021'},
  {id: 284, name: 'Age_30_34_2021'},
  {id: 285, name: 'Age_35_39_2021'},
  {id: 286, name: 'Age_40_44_2021'},
  {id: 287, name: 'Age_45_49_2021'},
  {id: 288, name: 'Age_50_54_2021'},
  {id: 289, name: 'Age_55_59_2021'},
  {id: 290, name: 'Age_60_64_2021'},
  {id: 291, name: 'Age_65_69_2021'},
  {id: 292, name: 'Age_70_74_2021'},
  {id: 293, name: 'Age_75_79_2021'},
  {id: 294, name: 'Age_80_84_2021'},
  {id: 295, name: 'Age_85_89_2021'},
  {id: 296, name: 'Age_90_Plus_2021'},
  {id: 297, name: 'Age_0_4_uplift_2022'},
  {id: 298, name: 'Age_5_9_uplift_2022'},
  {id: 299, name: 'Age_10_14_uplift_2022'},
  {id: 300, name: 'Age_15_19_uplift_2022'},
  {id: 301, name: 'Age_20_24_uplift_2022'},
  {id: 302, name: 'Age_25_29_uplift_2022'},
  {id: 303, name: 'Age_30_34_uplift_2022'},
  {id: 304, name: 'Age_35_39_uplift_2022'},
  {id: 305, name: 'Age_40_44_uplift_2022'},
  {id: 306, name: 'Age_45_49_uplift_2022'},
  {id: 307, name: 'Age_50_54_uplift_2022'},
  {id: 308, name: 'Age_55_59_uplift_2022'},
  {id: 309, name: 'Age_60_64_uplift_2022'},
  {id: 310, name: 'Age_65_69_uplift_2022'},
  {id: 311, name: 'Age_70_74_uplift_2022'},
  {id: 312, name: 'Age_75_79_uplift_2022'},
  {id: 313, name: 'Age_80_84_uplift_2022'},
  {id: 314, name: 'Age_85_89_uplift_2022'},
  {id: 315, name: 'Age_90_Plus_uplift_2022'},
  {id: 316, name: 'Age_0_4_uplift_2023'},
  {id: 317, name: 'Age_5_9_uplift_2023'},
  {id: 318, name: 'Age_10_14_uplift_2023'},
  {id: 319, name: 'Age_15_19_uplift_2023'},
  {id: 320, name: 'Age_20_24_uplift_2023'},
  {id: 321, name: 'Age_25_29_uplift_2023'},
  {id: 322, name: 'Age_30_34_uplift_2023'},
  {id: 323, name: 'Age_35_39_uplift_2023'},
  {id: 324, name: 'Age_40_44_uplift_2023'},
  {id: 325, name: 'Age_45_49_uplift_2023'},
  {id: 326, name: 'Age_50_54_uplift_2023'},
  {id: 327, name: 'Age_55_59_uplift_2023'},
  {id: 328, name: 'Age_60_64_uplift_2023'},
  {id: 329, name: 'Age_65_69_uplift_2023'},
  {id: 330, name: 'Age_70_74_uplift_2023'},
  {id: 331, name: 'Age_75_79_uplift_2023'},
  {id: 332, name: 'Age_80_84_uplift_2023'},
  {id: 333, name: 'Age_85_89_uplift_2023'},
  {id: 334, name: 'Age_90_Plus_uplift_2023'},
  {id: 335, name: 'Age_0_4_uplift_2024'},
  {id: 336, name: 'Age_5_9_uplift_2024'},
  {id: 337, name: 'Age_10_14_uplift_2024'},
  {id: 338, name: 'Age_15_19_uplift_2024'},
  {id: 339, name: 'Age_20_24_uplift_2024'},
  {id: 340, name: 'Age_25_29_uplift_2024'},
  {id: 341, name: 'Age_30_34_uplift_2024'},
  {id: 342, name: 'Age_35_39_uplift_2024'},
  {id: 343, name: 'Age_40_44_uplift_2024'},
  {id: 344, name: 'Age_45_49_uplift_2024'},
  {id: 345, name: 'Age_50_54_uplift_2024'},
  {id: 346, name: 'Age_55_59_uplift_2024'},
  {id: 347, name: 'Age_60_64_uplift_2024'},
  {id: 348, name: 'Age_65_69_uplift_2024'},
  {id: 349, name: 'Age_70_74_uplift_2024'},
  {id: 350, name: 'Age_75_79_uplift_2024'},
  {id: 351, name: 'Age_80_84_uplift_2024'},
  {id: 352, name: 'Age_85_89_uplift_2024'},
  {id: 353, name: 'Age_90_Plus_uplift_2024'},
  {id: 354, name: 'Age_0_4_uplift_2025'},
  {id: 355, name: 'Age_5_9_uplift_2025'},
  {id: 356, name: 'Age_10_14_uplift_2025'},
  {id: 357, name: 'Age_15_19_uplift_2025'},
  {id: 358, name: 'Age_20_24_uplift_2025'},
  {id: 359, name: 'Age_25_29_uplift_2025'},
  {id: 360, name: 'Age_30_34_uplift_2025'},
  {id: 361, name: 'Age_35_39_uplift_2025'},
  {id: 362, name: 'Age_40_44_uplift_2025'},
  {id: 363, name: 'Age_45_49_uplift_2025'},
  {id: 364, name: 'Age_50_54_uplift_2025'},
  {id: 365, name: 'Age_55_59_uplift_2025'},
  {id: 366, name: 'Age_60_64_uplift_2025'},
  {id: 367, name: 'Age_65_69_uplift_2025'},
  {id: 368, name: 'Age_70_74_uplift_2025'},
  {id: 369, name: 'Age_75_79_uplift_2025'},
  {id: 370, name: 'Age_80_84_uplift_2025'},
  {id: 371, name: 'Age_85_89_uplift_2025'},
  {id: 372, name: 'Age_90_Plus_uplift_2025'},
  {id: 373, name: 'Age_0_4_uplift_2026'},
  {id: 374, name: 'Age_5_9_uplift_2026'},
  {id: 375, name: 'Age_10_14_uplift_2026'},
  {id: 376, name: 'Age_15_19_uplift_2026'},
  {id: 377, name: 'Age_20_24_uplift_2026'},
  {id: 378, name: 'Age_25_29_uplift_2026'},
  {id: 379, name: 'Age_30_34_uplift_2026'},
  {id: 380, name: 'Age_35_39_uplift_2026'},
  {id: 381, name: 'Age_40_44_uplift_2026'},
  {id: 382, name: 'Age_45_49_uplift_2026'},
  {id: 383, name: 'Age_50_54_uplift_2026'},
  {id: 384, name: 'Age_55_59_uplift_2026'},
  {id: 385, name: 'Age_60_64_uplift_2026'},
  {id: 386, name: 'Age_65_69_uplift_2026'},
  {id: 387, name: 'Age_70_74_uplift_2026'},
  {id: 388, name: 'Age_75_79_uplift_2026'},
  {id: 389, name: 'Age_80_84_uplift_2026'},
  {id: 390, name: 'Age_85_89_uplift_2026'},
  {id: 391, name: 'Age_90_Plus_uplift_2026'},
  {id: 392, name: 'Age_0_4_uplift_2027'},
  {id: 393, name: 'Age_5_9_uplift_2027'},
  {id: 394, name: 'Age_10_14_uplift_2027'},
  {id: 395, name: 'Age_15_19_uplift_2027'},
  {id: 396, name: 'Age_20_24_uplift_2027'},
  {id: 397, name: 'Age_25_29_uplift_2027'},
  {id: 398, name: 'Age_30_34_uplift_2027'},
  {id: 399, name: 'Age_35_39_uplift_2027'},
  {id: 400, name: 'Age_40_44_uplift_2027'},
  {id: 401, name: 'Age_45_49_uplift_2027'},
  {id: 402, name: 'Age_50_54_uplift_2027'},
  {id: 403, name: 'Age_55_59_uplift_2027'},
  {id: 404, name: 'Age_60_64_uplift_2027'},
  {id: 405, name: 'Age_65_69_uplift_2027'},
  {id: 406, name: 'Age_70_74_uplift_2027'},
  {id: 407, name: 'Age_75_79_uplift_2027'},
  {id: 408, name: 'Age_80_84_uplift_2027'},
  {id: 409, name: 'Age_85_89_uplift_2027'},
  {id: 410, name: 'Age_90_Plus_uplift_2027'},
  {id: 411, name: 'Age_0_4_uplift_2028'},
  {id: 412, name: 'Age_5_9_uplift_2028'},
  {id: 413, name: 'Age_10_14_uplift_2028'},
  {id: 414, name: 'Age_15_19_uplift_2028'},
  {id: 415, name: 'Age_20_24_uplift_2028'},
  {id: 416, name: 'Age_25_29_uplift_2028'},
  {id: 417, name: 'Age_30_34_uplift_2028'},
  {id: 418, name: 'Age_35_39_uplift_2028'},
  {id: 419, name: 'Age_40_44_uplift_2028'},
  {id: 420, name: 'Age_45_49_uplift_2028'},
  {id: 421, name: 'Age_50_54_uplift_2028'},
  {id: 422, name: 'Age_55_59_uplift_2028'},
  {id: 423, name: 'Age_60_64_uplift_2028'},
  {id: 424, name: 'Age_65_69_uplift_2028'},
  {id: 425, name: 'Age_70_74_uplift_2028'},
  {id: 426, name: 'Age_75_79_uplift_2028'},
  {id: 427, name: 'Age_80_84_uplift_2028'},
  {id: 428, name: 'Age_85_89_uplift_2028'},
  {id: 429, name: 'Age_90_Plus_uplift_2028'},
  {id: 430, name: 'Age_0_4_uplift_2029'},
  {id: 431, name: 'Age_5_9_uplift_2029'},
  {id: 432, name: 'Age_10_14_uplift_2029'},
  {id: 433, name: 'Age_15_19_uplift_2029'},
  {id: 434, name: 'Age_20_24_uplift_2029'},
  {id: 435, name: 'Age_25_29_uplift_2029'},
  {id: 436, name: 'Age_30_34_uplift_2029'},
  {id: 437, name: 'Age_35_39_uplift_2029'},
  {id: 438, name: 'Age_40_44_uplift_2029'},
  {id: 439, name: 'Age_45_49_uplift_2029'},
  {id: 440, name: 'Age_50_54_uplift_2029'},
  {id: 441, name: 'Age_55_59_uplift_2029'},
  {id: 442, name: 'Age_60_64_uplift_2029'},
  {id: 443, name: 'Age_65_69_uplift_2029'},
  {id: 444, name: 'Age_70_74_uplift_2029'},
  {id: 445, name: 'Age_75_79_uplift_2029'},
  {id: 446, name: 'Age_80_84_uplift_2029'},
  {id: 447, name: 'Age_85_89_uplift_2029'},
  {id: 448, name: 'Age_90_Plus_uplift_2029'},
  {id: 449, name: 'Age_0_4_uplift_2030'},
  {id: 450, name: 'Age_5_9_uplift_2030'},
  {id: 451, name: 'Age_10_14_uplift_2030'},
  {id: 452, name: 'Age_15_19_uplift_2030'},
  {id: 453, name: 'Age_20_24_uplift_2030'},
  {id: 454, name: 'Age_25_29_uplift_2030'},
  {id: 455, name: 'Age_30_34_uplift_2030'},
  {id: 456, name: 'Age_35_39_uplift_2030'},
  {id: 457, name: 'Age_40_44_uplift_2030'},
  {id: 458, name: 'Age_45_49_uplift_2030'},
  {id: 459, name: 'Age_50_54_uplift_2030'},
  {id: 460, name: 'Age_55_59_uplift_2030'},
  {id: 461, name: 'Age_60_64_uplift_2030'},
  {id: 462, name: 'Age_65_69_uplift_2030'},
  {id: 463, name: 'Age_70_74_uplift_2030'},
  {id: 464, name: 'Age_75_79_uplift_2030'},
  {id: 465, name: 'Age_80_84_uplift_2030'},
  {id: 466, name: 'Age_85_89_uplift_2030'},
  {id: 467, name: 'Age_90_Plus_uplift_2030'},
  {id: 468, name: 'Age_0_4_uplift_2031'},
  {id: 469, name: 'Age_5_9_uplift_2031'},
  {id: 470, name: 'Age_10_14_uplift_2031'},
  {id: 471, name: 'Age_15_19_uplift_2031'},
  {id: 472, name: 'Age_20_24_uplift_2031'},
  {id: 473, name: 'Age_25_29_uplift_2031'},
  {id: 474, name: 'Age_30_34_uplift_2031'},
  {id: 475, name: 'Age_35_39_uplift_2031'},
  {id: 476, name: 'Age_40_44_uplift_2031'},
  {id: 477, name: 'Age_45_49_uplift_2031'},
  {id: 478, name: 'Age_50_54_uplift_2031'},
  {id: 479, name: 'Age_55_59_uplift_2031'},
  {id: 480, name: 'Age_60_64_uplift_2031'},
  {id: 481, name: 'Age_65_69_uplift_2031'},
  {id: 482, name: 'Age_70_74_uplift_2031'},
  {id: 483, name: 'Age_75_79_uplift_2031'},
  {id: 484, name: 'Age_80_84_uplift_2031'},
  {id: 485, name: 'Age_85_89_uplift_2031'},
  {id: 486, name: 'Age_90_Plus_uplift_2031'},
  {id: 487, name: 'Age_0_4_uplift_2032'},
  {id: 488, name: 'Age_5_9_uplift_2032'},
  {id: 489, name: 'Age_10_14_uplift_2032'},
  {id: 490, name: 'Age_15_19_uplift_2032'},
  {id: 491, name: 'Age_20_24_uplift_2032'},
  {id: 492, name: 'Age_25_29_uplift_2032'},
  {id: 493, name: 'Age_30_34_uplift_2032'},
  {id: 494, name: 'Age_35_39_uplift_2032'},
  {id: 495, name: 'Age_40_44_uplift_2032'},
  {id: 496, name: 'Age_45_49_uplift_2032'},
  {id: 497, name: 'Age_50_54_uplift_2032'},
  {id: 498, name: 'Age_55_59_uplift_2032'},
  {id: 499, name: 'Age_60_64_uplift_2032'},
  {id: 500, name: 'Age_65_69_uplift_2032'},
  {id: 501, name: 'Age_70_74_uplift_2032'},
  {id: 502, name: 'Age_75_79_uplift_2032'},
  {id: 503, name: 'Age_80_84_uplift_2032'},
  {id: 504, name: 'Age_85_89_uplift_2032'},
  {id: 505, name: 'Age_90_Plus_uplift_2032'},
  {id: 506, name: 'Age_0_4_uplift_2033'},
  {id: 507, name: 'Age_5_9_uplift_2033'},
  {id: 508, name: 'Age_10_14_uplift_2033'},
  {id: 509, name: 'Age_15_19_uplift_2033'},
  {id: 510, name: 'Age_20_24_uplift_2033'},
  {id: 511, name: 'Age_25_29_uplift_2033'},
  {id: 512, name: 'Age_30_34_uplift_2033'},
  {id: 513, name: 'Age_35_39_uplift_2033'},
  {id: 514, name: 'Age_40_44_uplift_2033'},
  {id: 515, name: 'Age_45_49_uplift_2033'},
  {id: 516, name: 'Age_50_54_uplift_2033'},
  {id: 517, name: 'Age_55_59_uplift_2033'},
  {id: 518, name: 'Age_60_64_uplift_2033'},
  {id: 519, name: 'Age_65_69_uplift_2033'},
  {id: 520, name: 'Age_70_74_uplift_2033'},
  {id: 521, name: 'Age_75_79_uplift_2033'},
  {id: 522, name: 'Age_80_84_uplift_2033'},
  {id: 523, name: 'Age_85_89_uplift_2033'},
  {id: 524, name: 'Age_90_Plus_uplift_2033'}
]
