import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core'
import { Category, DataSet } from '../../../api'
import { Auth } from '@targomo/client'
import { DataSetEndpoint } from '../../../api/dataSet'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { GeoRegionsEndpoint } from '../../../api/georegions'
import { UserSettingsEndpoint } from '../../../api/userSettings'
import { PdfReportSettings } from '../../../model/settingsModel'
import { MatDialog } from '@angular/material'
import { ColorSelectDialog } from '../../../main/dialogs/colorSelectDialog/colorSelectDialog.component'

const AVAILABLE_SECTIONS: { key: string; label: string; selected?: boolean; inverse?: boolean }[] = [
  { key: 'mapPage', label: 'Map Page' },
  { key: 'hideCompetition', label: 'Competition', inverse: true },
  { key: 'showCompetitionSummary', label: 'Competition Summary' },
  { key: 'hideCompetitionRatios', label: 'Competition and Population Ratios', inverse: true },
  { key: 'workforcePages', label: 'Workforce' },
  { key: 'populationPages', label: 'Population' },
  { key: 'planningApplications', label: 'Planning Applications' },
  { key: 'hideComparisonGoodsSpend', label: 'Comparison Goods Spend', inverse: true },
  { key: 'hideSpendSummary', label: 'Spend Summary', inverse: true },
  { key: 'hideSpendDetail', label: 'Spend Details', inverse: true },
  { key: 'colliersDemographics', label: 'Colliers Demographics Variant' },
  { key: 'colliersSpend', label: 'Colliers Spend Variant' },
  { key: 'generations', label: 'Generations' },
  { key: 'postcodeAnalysis', label: 'Postcode Analysis' },
]

export const AVAILABLE_COMPETITION_COLUMS: { key: string; label: string; selected?: boolean }[] = [
  { key: 'holdingCompany', label: 'Company' },
  { key: 'fascia', label: 'Fascia' },
  { key: 'primaryCategory', label: 'Primary Category' },
  { key: 'secondaryCategory', label: 'Secondary Category' },
  { key: 'town', label: 'Town' },
  { key: 'street', label: 'Street' },
  { key: 'postcode', label: 'Postcode' },
  { key: 'grossInternalArea', label: 'GIA (sq ft)' },
  { key: 'netSalesArea', label: 'NSA (sq ft)' },
  { key: 'travelTime', label: 'Time (mins)' },
]

@Component({
  selector: 'admin-report-options',
  templateUrl: './adminReportOptions.component.html',
  styleUrls: ['./adminReportOptions.component.less'],
})
export class AdminReportOptionsComponent implements OnInit, OnChanges {
  availableSections = AVAILABLE_SECTIONS
  availableCompetitionColumns = AVAILABLE_COMPETITION_COLUMS

  @Input() model: PdfReportSettings
  @Output() modelChange = new EventEmitter<PdfReportSettings>()

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.ngOnChanges()
  }

  ngOnChanges() {
    if (!this.model) {
      this.model = <any>{}
    }

    this.availableSections = this.mergeList(AVAILABLE_SECTIONS, this.model.sections)
    this.availableCompetitionColumns = this.mergeList(AVAILABLE_COMPETITION_COLUMS, this.model.competitionColumns)
  }

  private mergeList(avaliable: { key: string; label: string; selected?: boolean }[], selected: string[]) {
    const selectedMap: any = (selected || []).reduce((acc, cur) => {
      acc[cur] = true
      return acc
    }, {} as any)

    return avaliable.map((item) => {
      return {
        ...item,
        selected: selectedMap[item.key],
      }
    })
  }

  toggleCompetitionColumn(value: any) {
    value.selected = !value.selected
    this.fireUpdate()
  }

  toggleReportSection(value: any) {
    value.selected = !value.selected
    this.fireUpdate()
  }

  toggleGeneral(key: string) {
    ;(<any>this.model)[key] = !(<any>this.model)[key]
    this.fireUpdate()
  }

  async editColor($event: any) {
    $event.preventDefault()

    const dialogRef = this.dialog.open(ColorSelectDialog, {
      disableClose: false,
      data: { value: { color: this.model.borderColor } },
    })

    const result = await dialogRef.afterClosed().toPromise()
    this.model.borderColor = result ? result.color : this.model.borderColor
    this.fireUpdate()
  }

  clearColor() {
    this.model.borderColor = null
    this.fireUpdate()
  }

  private fireUpdate() {
    this.model.sections = this.availableSections.filter((item) => item.selected).map((item) => item.key)
    this.model.competitionColumns = this.availableCompetitionColumns
      .filter((item) => item.selected)
      .map((item) => item.key)

    this.modelChange.next(this.model)
  }
}
