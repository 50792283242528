import { Component, OnInit } from '@angular/core';
import { CompanyEndpoint } from '../../../api/company';
import { Indicators, TgmQuickDialogs } from '@targomo/client';
import { Company } from '../../../../common/models';

@Component({
  selector: 'app-admin-company-list',
  templateUrl: './adminCompanyList.component.html',
  styleUrls: ['./adminCompanyList.component.less']
})
export class AdminCompanyListComponent implements OnInit {
  companies: any[] = []

  constructor(
    private companyEndpoint: CompanyEndpoint,
    private indicators: Indicators,
    private quickDialogs: TgmQuickDialogs,
  ) { }

  async ngOnInit() {
    this.updateList()
  }
  
  private async updateList() {
    this.companies = await this.indicators.add(this.companyEndpoint.findAll())
  }

  showMenu(event: any) {
    event.stopPropagation()
  }  

  async removeCompany(company: Company) {
    if (await this.quickDialogs.confirm({
      title: 'Delete User',
      message: `Would you like to delete company (${company.name})?`,
      confirm: 'Delete',
      cancel: 'Cancel'
    })) {
      const result = await this.companyEndpoint.delete(company)
      this.updateList()
    }
  }
}
