<div>
  <source-title-panel style="text-align: center"></source-title-panel>

  <mat-list class="sections">
    <mat-list-item (click)="toggle('drivetime')">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>
          {{(appModel.zoneLayersModel.selectionExistsUpdates | async) ? 'Competition by Zone' : 'Competition by
          Traveltime'}}
        </div>
        <mat-icon
          [class.toggled]="visible.drivetime"
          class="mat-secondary animation-rotate"
          mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>

    <div class="content" *ngIf="visible.drivetime">
      <competition-mini-report></competition-mini-report>
    </div>

    <mat-list-item (click)="toggle('census')">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>Residents' Demographics</div>
        <mat-icon [class.toggled]="visible.census" class="mat-secondary animation-rotate" mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>

    <div class="content" *ngIf="visible.census">
      <tgm-loadable
        [model]="appModel.statistics.census.promise | async"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="!(appModel.zoneLayersModel.selectionExistsUpdates | async)"
      >
        <tgm-custom-bargraph [model]="graphPopulationModel" [options]="graphPopulationOptions"></tgm-custom-bargraph>
      </tgm-loadable>

      <census-mini-report></census-mini-report>
    </div>

    <mat-list-item (click)="toggle('lifestyle')">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>Residents' Lifestyle Data &amp; Spend</div>
        <mat-icon
          [class.toggled]="visible.lifestyle"
          class="mat-secondary animation-rotate"
          mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>
    <div class="content" *ngIf="visible.lifestyle">
      <lifestyle-mini-report></lifestyle-mini-report>
    </div>

    <mat-list-item (click)="toggle('houseprices')">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>House Prices &amp; Household Income</div>
        <mat-icon
          [class.toggled]="visible.houseprices"
          class="mat-secondary animation-rotate"
          mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>
    <div class="content" *ngIf="visible.houseprices">
      <house-prices-mini-report></house-prices-mini-report>
    </div>

    <mat-list-item (click)="toggle('workforce')" *ngIf="!statistics || statistics.hasWorkforce != false">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>Workforce Demographics</div>
        <mat-icon
          [class.toggled]="visible.workforce"
          class="mat-secondary animation-rotate"
          mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>
    <div class="content" *ngIf="visible.workforce" [hidden]="statistics && statistics.hasWorkforce == false">
      <tgm-loadable
        [model]="appModel.statistics.census.promise | async"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="!(appModel.zoneLayersModel.selectionExistsUpdates | async)"
      >
        <tgm-custom-bargraph [model]="graphWorkforceModel" [options]="graphWorkforceOptions"></tgm-custom-bargraph>
      </tgm-loadable>
      <workforce-mini-report></workforce-mini-report>
    </div>

    <mat-list-item (click)="toggle('fascias')" *ngIf="permissions && permissions.locationsInView">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>Locations Present</div>
        <mat-icon [class.toggled]="visible.fascias" class="mat-secondary animation-rotate" mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>
    <div class="content" *ngIf="visible.fascias">
      <locations-present></locations-present>
    </div>

    <mat-list-item (click)="toggle('postcodeAnalysis')" *ngIf="permissions && permissions.postcodesAnalysis">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>Existing Customer Analysis</div>
        <mat-icon
          [class.toggled]="visible.postcodeAnalysis"
          class="mat-secondary animation-rotate"
          mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>

    <div class="content" *ngIf="visible.postcodeAnalysis && permissions && permissions.postcodesAnalysis">
      <postcode-analysis></postcode-analysis>
    </div>

    <mat-list-item (click)="toggle('fasciasWithin')" *ngIf="permissions && permissions.withinTool">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>Within/Without Tool</div>
        <mat-icon
          [class.toggled]="visible.fasciasWithin"
          class="mat-secondary animation-rotate"
          mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>
    <div class="content" *ngIf="visible.fasciasWithin">
      <operators-present-travel></operators-present-travel>
    </div>

    <mat-list-item
      (click)="toggle('populationForecast')"
      *ngIf="permissions && permissions.populationForecast && (statistics?.hasForecast != false)"
    >
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>Population Forecast</div>
        <mat-icon
          [class.toggled]="visible.populationForecast"
          class="mat-secondary animation-rotate"
          mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>
    <div
      class="content"
      *ngIf="visible.populationForecast && permissions && permissions.populationForecast && (statistics?.hasForecast != false)"
    >
      <population-forecast></population-forecast>
    </div>

    <mat-list-item (click)="toggle('careHome')" *ngIf="permissions && permissions.careHome">
      <div class="location-item" fxFlex fxLayout="row">
        <div fxFlex>Care Home Supply & Demand</div>
        <mat-icon
          [class.toggled]="visible.careHome && permissions && permissions.careHome"
          class="mat-secondary animation-rotate"
          mat-font-set="material-icons"
          >keyboard_arrow_right</mat-icon
        >
      </div>
    </mat-list-item>
    <div class="content" *ngIf="visible.careHome">
      <care-home-mini-report></care-home-mini-report>
    </div>
  </mat-list>

  <div *ngIf="statistics">
    <mat-list style="text-align: center; margin: 10px 8px; background: #f0f0f0; padding: 0px">
      <indices-switch style="width: 100%" [statistics]="statistics"></indices-switch>
    </mat-list>

    <mat-list
      style="text-align: center; margin: 10px 8px; background: #f0f0f0; padding: 0px"
      *ngIf="permissions && permissions.householdsCapitaSwitch"
    >
      <per-capita-households-switch style="width: 100%"></per-capita-households-switch>
    </mat-list>
  </div>

  <!-- <div *ngIf="statistics" style="text-align: center; margin: 10px 8px;">
    <mat-list>
      <exclusive-switch style="width: 100%"></exclusive-switch>
    </mat-list>
  </div> -->
</div>
