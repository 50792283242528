<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <div>
    <button mat-flat-button color="primary" [matMenuTriggerFor]="menuCategories">Category Selection</button>

    <mat-menu class="dataset-menu" #menuCategories="matMenu">
      <button mat-button (click)="categorySelectionClearAll($event)">Clear All</button>
      <div class="dataset-menu-container" (click)="clickMenu($event)">
        <admin-select-fascias
          (selectionChange)="categorySelectionChange($event)"
          [allowGroupings]="true"
          [allowFilters]="false"
          [dataSets]="dataSets$ | async"
        ></admin-select-fascias>
      </div>
    </mat-menu>
  </div>

  <div>
    <span class="pointer" (click)="filter.setNSA()">
      <mat-checkbox style="pointer-events: none" [checked]="filter.isNSA()">NSA</mat-checkbox>
    </span>
  </div>

  <div>
    <span class="pointer" (click)="filter.setGIA()">
      <mat-checkbox style="pointer-events: none" [checked]="filter.isGIA()">GIA</mat-checkbox>
    </span>
  </div>

  <div>
    <button mat-button [matMenuTriggerFor]="menu">AVG UNIT SIZE BY: {{ filter.getSizeByLabel() }}</button>

    <mat-menu #menu="matMenu">
      <div *ngFor="let option of SIZE_OPTIONS">
        <mat-checkbox
          (click)="clickMenu($event)"
          (change)="filter.toggleSizeBy(option.key)"
          [checked]="filter.isSizeBy(option.key)"
          >{{ option.name }}</mat-checkbox
        >
      </div>
    </mat-menu>
  </div>

  <div class="min-max">
    <mat-form-field>
      <input
        matInput
        [ngModel]="filter.getSliderLowerValue()"
        (ngModelChange)="filter.setSliderLowerValue($event)"
        name="min"
        placeholder="Min"
      />
    </mat-form-field>
  </div>
  <div class="min-max">
    <mat-form-field>
      <input
        matInput
        [ngModel]="filter.getSliderUpperValue()"
        (ngModelChange)="filter.setSliderUpperValue($event)"
        name="max"
        placeholder="Max"
      />
    </mat-form-field>
  </div>
</div>

<!-- <td (click)="data.setAverage()">
  <mat-checkbox style="pointer-events: none" [checked]="data.isAverage()"></mat-checkbox>
</td>
 -->
<!-- <td>
</td>

<td>{{ data.getSliderLowerValue() | number: '1.0-0' }}</td>
<td>
  <tgm-rangeslider
    [margin]="0.001"
    [step]="0.01"
    [max]="data.getSliderMaxValue(maximums)"
    [minValue]="data.getSliderLowerValue()"
    [maxValue]="data.getSliderUpperValue()"
    (minValueChange)="data.setSliderLowerValue($event)"
    (maxValueChange)="data.setSliderUpperValue($event)"
  ></tgm-rangeslider>
</td>
<td>{{ data.getSliderUpperValue() | number: '1.0-0' }}</td>
</tr>
 -->
