import { ReportModel } from "./exportPdfReport";
import { PDFHelper } from "./helper";
import { CensusStatisticsGroup, AllStatistics, StatisticsValue } from "../../../common/models/statistics/statistics";

function getTableOptions(helper: PDFHelper) {
  return {
    border: '#ffffff',
    background: ['#ffffff', null],
    // widths: () => ['50', '50'],
    layout: {
      hLineWidth: function (i: number, node: any) {
        return 1
      },
      vLineWidth: function (i: number, node: any) {
        return 1
      },
      hLineColor: function (i: number, node: any) {
        return helper.tableBorder(); //'#b06977'//CORPORATE_COLOR
      },
      vLineColor: function (i: number, node: any) {
        return helper.tableBorder(); // '#b06977'//CORPORATE_COLOR
      },

      fillColor: function (i: number, node: any) {
        if (i === 0) {
          return helper.tableBorder();
        } else {
          return '#ffffff'
        }
      }
    }
  }
}

function getTableOptionsSimple(helper: PDFHelper) {
  return {
    border: '#ffffff',
    background: ['#ffffff', null],
    widths: () => ['50%', '50%'],
    layout: {
      hLineWidth: function (i: number, node: any) {
        return 1
      },
      vLineWidth: function (i: number, node: any) {
        return 1
      },
      hLineColor: function (i: number, node: any) {
        return helper.tableBorder(); //'#b06977'//CORPORATE_COLOR
      },
      vLineColor: function (i: number, node: any) {
        return helper.tableBorder(); // '#b06977'//CORPORATE_COLOR
      },

      fillColor: function (i: number, node: any) {
        if (i === 0) {
          return helper.tableBorder();
        } else {
          return '#ffffff'
        }
      }
    }
  }
}


export function forStatisticItem(group: CensusStatisticsGroup, callback: (key: string, value: any) => void) {
  for (let key in group) {
    if (group[key] && group[key].total !== undefined) {
      callback(key, group[key])
    }
  }
}

export function largeTableWithIndex(
  reportModel: ReportModel,
  helper: PDFHelper,
  labelHeader: string,
  whichStatistic: (input: {statistics: AllStatistics}) => CensusStatisticsGroup,
  individualLabels: {[index: string]: string},
) {
  const result: any[] = []

  result.push([
    {text: labelHeader, style: 'largeCellGroupFirstInverse', rowBackground: '#ffffff'},
    {text: "Value", style: 'largeCellGroupInverse', rowBackground: '#ffffff'},
    {text: "%", style: 'largeCellGroupInverse', rowBackground: '#ffffff'},
    {text: "Index vs Region Average", style: 'largeCellGroupInverse', rowBackground: '#ffffff'},
    {text: "Region Average", style: 'largeCellGroupInverse', rowBackground: '#ffffff'},
  ])

  const reportSegment = reportModel[reportModel.length - 1]
  // forStatisticItem(reportSegment.statistics.census.gender, (key, item) => {
  forStatisticItem(whichStatistic(reportSegment), (key, item) => {
    if (individualLabels[key]) {
      result.push([
        {text: individualLabels[key], style: 'largeCellGroupFirst', rowBackground: '#ffffff'},
        {text: helper.formatNumber(item.total), style: 'largeCellGroup', rowBackground: '#ffffff'},
        {text: helper.formatNumber(item.percent, 1, ''), style: 'largeCellGroup', rowBackground: '#ffffff'},
        {text: helper.formatNumber(item.percent / item.index * 100, 0, ''), style: 'largeCellGroup', rowBackground: '#ffffff'},
        {text: helper.formatNumber(item.index, 2, '%'), style: 'largeCellGroup', rowBackground: '#ffffff'},
      ])
    }
  })


  result.push([
    {text: 'Total', style: 'largeCellGroupFirst', rowBackground: '#ffffff'},
    {text: helper.formatNumber(whichStatistic(reportSegment).total), style: 'largeCellGroup', rowBackground: '#ffffff'},
    {text: '100%', style: 'largeCellGroup', rowBackground: '#ffffff'},
    {text: '', style: 'largeCellGroup', rowBackground: '#ffffff'},
    {text: '100%', style: 'largeCellGroup', rowBackground: '#ffffff'},
  ])

  // })

  return helper.table(null, result, 'defaultTable', getTableOptions(helper))
}

export function largeWorkforceTableWithIndex(
  reportModel: ReportModel,
  helper: PDFHelper,
  individualLabels: {[index: string]: string},
  whichStatistic: (input: {statistics: AllStatistics}) => CensusStatisticsGroup
) {
  const result: any[] = []

  result.push([
    {text: 'Segment', style: 'largeCellGroupFirstInverse', rowBackground: '#ffffff'},
    {text: "%", style: 'largeCellGroupInverse', rowBackground: '#ffffff'},
    {text: "Index vs Region Average", style: 'largeCellGroupInverse', rowBackground: '#ffffff'},
    {text: "Value", style: 'largeCellGroupInverse', rowBackground: '#ffffff'},
  ])

  const reportSegment = reportModel[reportModel.length - 1]
  // forStatisticItem(reportSegment.statistics.census.gender, (key, item) => {
  forStatisticItem(whichStatistic(reportSegment), (key, item) => {
    if (!item.index && !item.value) {
      return
    }

    result.push([
      {text: individualLabels[key], style: 'largeCellGroupFirst', rowBackground: '#ffffff'},
      {text: helper.formatNumber(item.percent, 1, ''), style: 'largeCellGroup', rowBackground: '#ffffff'},
      {text: helper.formatNumber(item.percent / item.index * 100, 0, ''), style: 'largeCellGroup', rowBackground: '#ffffff'},
      {text: helper.formatNumber(item.total), style: 'largeCellGroup', rowBackground: '#ffffff'},
      // {text: helper.formatNumber(item.index, 2, '%'), style: 'largeCellGroup', rowBackground: '#ffffff'},
    ])
  })
  // })

  result.push([
    {text: 'Total', style: 'largeCellGroupFirst', rowBackground: '#ffffff'},
    {text: '100%', style: 'largeCellGroup', rowBackground: '#ffffff'},
    {text: '', style: 'largeCellGroup', rowBackground: '#ffffff'},
    {text: helper.formatNumber(whichStatistic(reportSegment).total), style: 'largeCellGroup', rowBackground: '#ffffff'},
  ])


  return helper.table(null, result, 'defaultTable', getTableOptions(helper))
}

export function largeWorkforceTableTimes(
  reportModel: ReportModel,
  helper: PDFHelper,
  individualLabels: {[index: string]: string},
  whichStatistic: (input: {statistics: AllStatistics}) => CensusStatisticsGroup
) {
  const result: any[] = []

  const rowHeader: any[] = [{text: 'Segment', style: 'largeCellGroupFirstInverse', rowBackground: '#ffffff'}]
  result.push(rowHeader.concat(reportModel.map(reportItem => {
    return {text: reportItem.nameFull, style: 'largeCellGroupInverse', rowBackground: '#ffffff'}
  })))

  const reportSegment = reportModel[reportModel.length - 1]
  forStatisticItem(whichStatistic(reportSegment), (key, item) => {
    const row = (reportModel.map(reportItem => {
      return {text: helper.formatNumber(whichStatistic(reportItem)[key].total), style: 'largeCellGroup', rowBackground: '#ffffff'}
    }))

    const label: any = {text: individualLabels[key], style: 'largeCellGroupFirst', rowBackground: '#ffffff'}
    result.push([label].concat(row))
  })

  result.push([
    {text: 'Total', style: 'largeCellGroupFirst', rowBackground: '#ffffff'},
  ].concat((reportModel.map(reportItem => {
      return {text: helper.formatNumber(whichStatistic(reportItem).total), style: 'largeCellGroup', rowBackground: '#ffffff'}
    })))
  )

  return helper.table(null, result, 'defaultTable', getTableOptions(helper))
}


export function largeWorkforceTableSimple(
  reportModel: ReportModel,
  helper: PDFHelper,
  columnLabel: string,
  whichStatistic: (input: {statistics: AllStatistics}) => StatisticsValue,
  travelTitle: string
) {
  return largeWorkforceTableSimpleAlt(reportModel.map(item => {
    return {
      name: item.nameFull,
      total: whichStatistic(item).total,
    }
  }), helper, columnLabel, travelTitle)
}


export function largeWorkforceTableSimpleAlt(
  reportModel: {
    name: string,
    total: number,
  }[],
  helper: PDFHelper,
  columnLabel: string,
  travelTitle: string
) {
  const result: any[] = []

  result.push([
    {text: travelTitle || 'Travel-Time', style: 'largeCellGroupFirstInverse', fillColor: helper.tableBorder()},
    {text: columnLabel, style: 'largeCellGroupInverse', fillColor: helper.tableBorder()},
  ])

  reportModel.forEach(reportItem => {
    result.push([
      {text: reportItem.name, style: 'largeCellGroupFirst', rowBackground: '#ffffff'},
      {text: helper.formatNumber(reportItem.total), style: 'largeCellGroup', rowBackground: '#ffffff'},
    ])
  })

  return helper.table(null, result, 'defaultTable', getTableOptionsSimple(helper))
}