import { Injectable } from '@angular/core'
import { RESTHelper } from '@targomo/client'

export interface PostcodeAnalysisReportGroup {
  title: string
  travelTime: number
  categories: { name: string; percent: number }[]
}

@Injectable()
export class PostcodeAnalysisEndpoint {
  private http: RESTHelper

  constructor(RestHelper: RESTHelper) {
    this.http = RestHelper.prefix('/postcodeAnalysis')
  }

  report(payload: any) {
    return this.http.post<{ groups: PostcodeAnalysisReportGroup[] }>(`/report`, payload)
  }

  categories() {
    return this.http.get<string[]>(`/categories`)
  }

  colors() {
    return this.http.get<Record<string, string>>(`/colors`)
  }

  updateColor(category: string, color: string) {
    return this.http.put<Record<string, string>>(`/colors`, { category, color })
  }

  uploadMultipart(files: any) {
    const data = new FormData()
    data.append('files', files[0])
    return this.http.post<number>(`/upload/`, data)
  }
}
