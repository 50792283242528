<tgm-loadable [model]="model?.loading$ | async">
  <div class="options-header" fxLayout="row" fxLayoutAlign="start center" style="margin: 16px 0px">
    <div fxFlex></div>

    <mat-checkbox
      [disabled]="!(model.isTownCentre$ | async)"
      (change)="model.setIncludeTownCenters($event.checked)"
      [checked]="model.includeTownCenters$ | async"
      >Include Town Centre Shopping Centre Locations in Town Centres</mat-checkbox
    >
  </div>

  <table
    style="width: 100%"
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
    matSortActive="name"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="fascia">Fascia</th>
      <td mat-cell *matCellDef="let row">
        <span class="checkbox" [style.background]="row.category.color || '#ddd'"></span>
        {{ row.fascia }}
      </td>
    </ng-container>

    <ng-container matColumnDef="secondaryCategory">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="secondaryCategory">Category</th>
      <td mat-cell *matCellDef="let row">{{ row.secondaryCategory }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</tgm-loadable>
