import { Component } from '@angular/core';
import { DataSet } from "../../api/index";
import {DataProfile, DataProfileEndpoint} from '../../api/dataProfile';
import {DataSetEndpoint} from '../../api/dataSet';
import {Auth} from '@targomo/client';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'admin-dataprofile-details',
  templateUrl: './adminDataProfileDetails.html'
})
export class AdminDataProfileDetailsComponent {
  form: FormGroup
  available: DataSet[]
  profile: DataProfile = <any>{}
  currentEdit: string
  loading: Promise<any>
  saving: Promise<any>
  submitted = false

  constructor(private formBuilder: FormBuilder, private Auth: Auth, private DataSet: DataSetEndpoint, private DataProfile: DataProfileEndpoint, private route: ActivatedRoute, private router: Router) {
    this.form = this.formBuilder.group({
      'name': [this.profile.name, [Validators.required]],
    })

    this.refresh()
  }

  async refresh() {
    const params = this.route.snapshot.params
    const id = params.id

    await (this.loading = Promise.all([
      id === 'new' ? Promise.resolve(<DataProfile>{}) : this.DataProfile.get({id}).then(profile => this.profile = profile),
      this.DataSet.findAll().then(available => this.available = available)
    ]))

    this.available.forEach(set => {
      if (this.profile && this.profile.dataSets)
        (<any>set)['selected'] = this.profile.dataSets.filter(item => item.id == set.id).length > 0
      else
        (<any>set)['selected'] = false
    })

    this.form = this.formBuilder.group({
      'name': [this.profile.name, [Validators.required]],
    })
  }

  back() {
    this.router.navigate(['/admin/profiles'])
  }

  editDataSet(set: DataSet) {
          // this.router.navigate([`/admin/profile//`])
    // this.$state.go('admin.profileCategories', {id: this.profile.id, setId: set.id})
  }

  onCloseEdit(refresh: boolean) {
    this.currentEdit = null
    if (refresh) {
      this.refresh()
    }
  }

  toggle(set: any) {
    set.selected = !set.selected
  }

  showMenu(event: any) {
    event.stopPropagation()
  }

  async save(event?: any) {
    this.submitted = true
    if (this.form.valid) {
      this.profile.dataSets = this.available.filter(set => !!(<any>set)['selected'])
      this.profile.name = this.form.value.name
      this.saving = ((!this.profile.id) ? this.DataProfile.create(this.profile) : this.DataProfile.save(this.profile))///.then((result) => {
      await this.saving
      this.router.navigate(['/admin/profiles'])
    }
  }
}