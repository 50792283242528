import { Component, OnInit } from '@angular/core'
import { NEW_MAP_STYLES, SettingsModel } from '../../../model/settingsModel'

@Component({
  selector: 'map-style-options-panel',
  templateUrl: './mapStyleOptionsPanel.component.html',
  styleUrls: ['./mapStyleOptionsPanel.component.less'],
})
export class MapStyleOptionsPanelComponent {
  styleOptions: any[] = NEW_MAP_STYLES

  constructor(readonly settings: SettingsModel) {}

  changeMapStyle(value: string) {
    this.settings.displaySettings.nextProperty('mapStyle', value)
  }
}
