export function underlineCell(cell: any, color = '#000000', border = true) {
  if (typeof cell == 'string') {
    cell = {text: cell}
  }

  const result = {
    table: {
      widths: ['*'],
      body: [[{...cell, border: [false, false, false, border], borderColor: [color, color, color, color]}]],   
    }
  }

  return result
}