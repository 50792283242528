import { Place } from "../../../common/models";
import { PDFHelper } from "./helper";
import { SourcesTitle } from "../../model/sourcesTitleModel";
import { AbstractLocation } from "../../model";

export function homePageSources(sourcesTitles: SourcesTitle, makeTitles?: (location: AbstractLocation) => string[]) {
  let sourcesRows: any[] = []

  if (!makeTitles) {
    makeTitles = location => [location.defaultName, location.town, location.postcode]
  }

  if (sourcesTitles.fascias && sourcesTitles.fascias.length) {
    sourcesRows = sourcesTitles.fascias.map(source => {
      const text = `${source.name} (${source.count})`
      return {style: "homeHeader4", text}
    })
  } else if (sourcesTitles) {
    if (sourcesTitles.sources && sourcesTitles.sources.length) {
      sourcesRows = sourcesTitles.sources.map(source => {
        const text = makeTitles(source).filter(item => !!item).join(' | ')
        return {style: "homeHeader4", text}
      })
    }
  }  

  return sourcesRows
}

export function homePage(
  helper: PDFHelper, 
  sources: Place[], 
  sourcesTitles: SourcesTitle,
  imageData: string, 
  zoneName: string, 
  travelName: string, 
  title: string,
  intersectionExclusiveMode: string
): any[] {
  const sourcesRows: any[] = homePageSources(sourcesTitles)

  // if (sourcesTitles.fascias && sourcesTitles.fascias.length) {
  //   sourcesRows = sourcesTitles.fascias.map(source => {
  //     const text = `${source.name} (${source.count})`
  //     return {style: "homeHeader4", text}
  //   })
  // } else if (sourcesTitles) {
  //   if (sourcesTitles.sources && sourcesTitles.sources.length) {
  //     sourcesRows = sourcesTitles.sources.map(source => {
  //       const text = [source.fascia, source.town, source.postcode].filter(item => !!item).join(' | ')
  //       return {style: "homeHeader4", text}
  //     })
  //   }
  // }

  let combinationTypeText = ''
  if (sources.length > 1 && intersectionExclusiveMode) {
    switch (intersectionExclusiveMode) {
      case 'union':
        combinationTypeText = 'Combined Travel-Time Area Data'
        break

      case 'exclusive':
        combinationTypeText = 'Exclusive Travel-Time Area Data'
        break        

      case 'intersection':
        combinationTypeText = 'Overlapping Travel-Time Area Data'
    }
  }

  return [].concat([
    {image: imageData, width: 260, style: 'logoCenter'},
  ]).concat(sourcesRows)
  .concat([
    {style: "homeHeader2", text: title, margin: [40, 40, 40, 40]},
    {text: combinationTypeText, style: 'frontPageDate'},
    {text: helper.formatDate(new Date()), style: 'frontPageDate'},
    {style: "copyright", text: ''}
  ])
}