import { Component, Input, EventEmitter, Output } from '@angular/core'
import { UserEndpoint, User } from '../../api/user'
import { DataSetEndpoint } from '../../api/dataSet'
import { Indicators, SubscriberComponent } from '@targomo/client'
import { TgmQuickDialogs } from '@targomo/client'
import { MatCheckboxChange } from '@angular/material'
import { Router } from '@angular/router'
import { MatchpointEndpoint } from '../../api/matchpoint'
import { timer } from 'rxjs'
import { switchMap, catchError } from 'rxjs/operators'

@Component({
  selector: 'admin-data-set-versions',
  template: `
    <div>
      <tgm-loadable [model]="loading">
        <table class="versions" *ngIf="versions && versions.length > 0">
          <tbody>
            <tr>
              <th></th>
              <th>Version</th>
              <th></th>
              <th>Preview</th>
              <th>Active</th>
            </tr>

            <tr *ngFor="let version of versions">
              <td>
                <mat-icon
                  [hidden]="version.preview || version.active"
                  class="delete"
                  (click)="deleteVersion(version)"
                  mat-font-set="material-icons"
                  >delete</mat-icon
                >
              </td>
              <td>
                <span fxFlex>{{ version.createdAt | date: 'y-MM-dd HH:mm' }}</span>
              </td>
              <td>
                <i
                  class="material-icons general-hourclass-icon-animation"
                  *ngIf="version.jobStatusText && (version.preview || version.active)"
                  [title]="version.jobStatusText"
                  >hourglass_empty</i
                >
              </td>
              <td>
                <mat-checkbox [checked]="version.preview" (change)="togglePreview(version, $event)"></mat-checkbox>
              </td>
              <td><mat-checkbox [checked]="version.active" (change)="toggleActive(version, $event)"></mat-checkbox></td>
            </tr>
          </tbody>
        </table>

        <div class="no-versions" *ngIf="versions && versions.length == 0">No locations in this dataset</div>
      </tgm-loadable>

      <div *ngIf="versions && versions.length == 0">
        <div style="text-align: center; margin-top: 24px">
          <button mat-button (click)="deleteSet()">
            <i class="material-icons">delete_forever</i> <span>Delete this data set</span>
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./adminDataSetVersions.less'],
})
export class AdminDataSetVersionsComponent extends SubscriberComponent {
  @Input() private setId: number
  @Output() private updated = new EventEmitter()

  versions: any[] = []
  loading: Promise<any[]>

  hasMatchpointCalculations = false

  constructor(
    private dataSetEndpoint: DataSetEndpoint,
    private indicators: Indicators,
    private quickDialogs: TgmQuickDialogs,
    private router: Router,
    private matchpoint: MatchpointEndpoint
  ) {
    super()
  }

  async ngOnInit() {
    const dataSet = await this.dataSetEndpoint.get({ id: +this.setId })
    this.hasMatchpointCalculations = (dataSet.name || '').toUpperCase().indexOf('CENTREPOINT') > -1
    this.versions = await this.indicators.add((this.loading = this.dataSetEndpoint.getVersions({ id: +this.setId })))

    if (this.hasMatchpointCalculations) {
      this.watch(
        timer(0, 5000).pipe(
          switchMap(async () => {
            try {
              await this.decorateJobStatus()
            } catch (e) {
              console.error(e)
            }
          })
        ),
        () => {}
      )
    }
  }

  private async decorateJobStatus() {
    if (this.versions) {
      for (let version of this.versions) {
        const result = await this.matchpoint.jobStatus(version.id)
        if (result && result.total > 0 && +result.total !== +result.value) {
          version.jobStatusText = `Matchpoint recalculation: ${result.value} of ${result.total}`
        } else {
          version.jobStatusText = null
        }
      }
    }
  }

  async togglePreview(version: any, value: MatCheckboxChange) {
    await this.dataSetEndpoint.savePreview(version, value.checked)
    this.updated.emit(true)
    this.ngOnInit()
  }

  async toggleActive(version: any, value: MatCheckboxChange) {
    await this.dataSetEndpoint.saveActive(version, value.checked)
    this.ngOnInit()
  }

  async deleteVersion(version: any) {
    if (version.active || version.preview) {
      this.quickDialogs.snack("Active and Preview data set versions can't be deleted")
      return
    }

    const result = await this.quickDialogs.confirm({
      message: 'Permanently delete this data set version?',
      confirm: 'Delete',
      cancel: 'Cancel',
    })

    if (result) {
      const deleteResult = await this.dataSetEndpoint.deleteVersion(version)
      if (deleteResult) {
        this.quickDialogs.snack('Version deleted')
        this.ngOnInit()
      } else {
        this.quickDialogs.snack('Delete failed')
      }
    }
  }

  async deleteSet() {
    const set = await this.dataSetEndpoint.get({ id: this.setId })

    if (
      await this.quickDialogs.confirm({
        title: 'Delete Data Set',
        message: `Would you like to delete data set ${set.name}?`,
        confirm: 'Delete',
        cancel: 'Cancel',
      })
    ) {
      await this.dataSetEndpoint.delete({ id: this.setId })
      this.quickDialogs.snack(`Data set ${set.name} deleted`)
      this.router.navigate(['/admin/datasets'])
    }
  }
}
