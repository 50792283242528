import { Component, Input, OnInit, NgZone } from '@angular/core'
import { Observable, of } from 'rxjs'
import { DataSet } from '../../../api/dataSet'
import { DataLoadingModel } from '../../../model/dataLoadingModel'
import { DataSetFilter } from '../../../model/dataSetFilter'
import { MatchpointModel } from '../../../model/matchpoint/matchpointModel'
import { DataSetLike } from '../../../../common/models/dataSet'
import { Category } from '../../../api/category'
import { take } from 'rxjs/operators'

// TODO: use same category size oject
// Make syre dataset is loaded for each selected category
@Component({
  selector: 'matchpoint-category-filter',
  templateUrl: './matchpointCategoryFilter.component.html',
  styleUrls: ['./matchpointCategoryFilter.component.less'],
})
export class MatchpointCategoryFilterComponent implements OnInit {
  SIZE_OPTIONS = [
    { name: 'All', key: 'ALL' },
    { name: 'High Street', key: 'HS' },
    { name: 'Shopping Centre', key: 'SC' },
    { name: 'Out of Town', key: 'OOT' },
  ]

  @Input() readonly model: MatchpointModel

  filter: DataSetFilter = new DataSetFilter({ id: 0 } as any, {}, () => {})
  dataSets$: Observable<DataSetLike[]> = of([])

  constructor(readonly dataLoadingModel: DataLoadingModel, private ngZone: NgZone) {}

  ngOnInit() {
    this.filter = this.model.gapOpportunityFilter
    this.dataSets$ = this.model.gapOpportunityDataSetsForFilter$
  }

  clickMenu(event: any) {
    event.stopPropagation()
  }

  categorySelectionChange(event: DataSetLike[]) {
    const set = new Set<Category>()

    if (event) {
      event.forEach((dataSet) => {
        if (dataSet.categories) {
          dataSet.categories.forEach((category) => {
            if (category.selected) {
              set.add((category as any).original)
            }
          })
        }
      })
    }

    this.model.gapOpportunityFilterSelectedCategories$.next(set)
  }

  categorySelectionClearAll(event: any) {
    event.stopPropagation()

    this.dataSets$.pipe(take(1)).subscribe((dataSets) => {
      this.ngZone.run(() => {
        dataSets.forEach((dataSet) => {
          if (dataSet.categories) {
            dataSet.categories.forEach((category) => {
              category.selected = false
            })
          }
          dataSet.categories = [].concat(dataSet.categories)
        })
      })

      this.model.gapOpportunityFilterSelectedCategories$.next(new Set())
    })
  }
}
