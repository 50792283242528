import { Observable, of } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { Category } from '../api/category'
import { PlacesModel } from './placesModel'
import { object as objects} from '@targomo/common'
import { combineLatest } from 'rxjs'
import exportPlaces from '../util/exportPlaces'
import { AbstractLocation } from './entities'

export interface OperatorCategorized extends Record<string, any> {
  category: Category
  count: number
  locations: AbstractLocation[]
  netSalesArea?: number
  grossInternalArea?: number
}

export function categorizeLocations(locations: AbstractLocation[]): OperatorCategorized[] {
  return objects.values(locations.reduce((acc, place) => {
    if (acc[place.category.id]) {
      acc[place.category.id].count++

      acc[place.category.id].netSalesArea += place.netSalesArea || 0,
      acc[place.category.id].grossInternalArea += place.grossInternalArea || 0,
            
      acc[place.category.id].locations.push(place)
    } else {
      acc[place.category.id] = {
        netSalesArea: place.netSalesArea || 0,
        grossInternalArea: place.grossInternalArea || 0,
        category: place.category, 
        count: 1, 
        locations: [place]
      }
    }
    return acc
  }, {} as {[id: number]: OperatorCategorized}))
}

export interface OperatorPresent {
  category: Category
  count: number
  locations: AbstractLocation[]
}

export interface OperatorNotPresent {
  category: Category
  count: number
  locations: AbstractLocation[]
}

export class OperatorsWithinModel {
  readonly locationsWithin$: Observable<AbstractLocation[]> //Observable<Category[]>
  readonly locationsNotWithin$: Observable<AbstractLocation[]> //Observable<Category[]>

  readonly operatorsWithin$: Observable<OperatorPresent[]> //Observable<Category[]>
  readonly operatorsNotWithin$: Observable<OperatorNotPresent[]> //Observable<Category[]>
  readonly loading$: Observable<Promise<any>>

  constructor(
    private places: PlacesModel
  ) {
    const allLocations$ = this.places.filteredPlaces.value

    const sourcesMap$: Observable<{[id: number]: any}> = this.places.sources.observable.pipe(
      map(sources => objects.mapFromArray(sources || [], 'id')),
      shareReplay(1),
    )

    this.locationsWithin$ = combineLatest(
      this.places.reachablePlaces.value,
      sourcesMap$
    ).pipe(
      map(([locations, sourcesMap]) => {
        return locations && locations.filter(location => !sourcesMap[location.id])
      }),
      shareReplay(1)
    )


    this.locationsNotWithin$ = combineLatest(
      allLocations$,
      this.locationsWithin$,
      sourcesMap$
    ).pipe(
      map(([all, reachable, sourcesMap]) => {
        if (!all || !reachable) {
          return all
        }

        const withinMap = objects.mapFromArray(reachable, 'id')
        return all.filter(item => !withinMap[item.id] && !sourcesMap[item.id])
      }),
      shareReplay(1)
    )

    // const allOperatorsMap$ = allLocations$.pipe(
    //   map(locations => {
    //     if (!locations) {
    //       return {}
    //     } else {
    //       return locations.reduce((acc, place) => {
    //         if (acc[place.category.id]) {
    //           acc[place.category.id].count++
    //         } else {
    //           acc[place.category.id] = {category: place.category, count: 1}
    //         }
    //         return acc
    //       }, {} as {[id: number]: {
    //         category: Category,
    //         count: number
    //       }})
    //     }
    //   }),
    //   shareReplay(1)
    // )

    this.operatorsWithin$ = this.locationsWithin$.pipe(
      map(locations => {
        if (!locations) {
          return null
        } else {
          return objects.values(locations.reduce((acc, place) => {
            if (acc[place.category.id]) {
              acc[place.category.id].count++
              acc[place.category.id].locations.push(place)
            } else {
              acc[place.category.id] = {category: place.category, count: 1, locations: [place]}
            }
            return acc
          }, {} as {[id: number]: {
            category: Category,
            count: number,
            locations: AbstractLocation[]
          }}))
        }
      }),
      shareReplay(1)
    )

    this.operatorsNotWithin$ = this.locationsNotWithin$.pipe(
      map(locations => {
        if (!locations) {
          return null
        } else {
          return objects.values(locations.reduce((acc, place) => {
            if (acc[place.category.id]) {
              acc[place.category.id].count++
              acc[place.category.id].locations.push(place)
            } else {
              acc[place.category.id] = {category: place.category, count: 1, locations: [place]}
            }
            return acc
          }, {} as {[id: number]: {
            category: Category,
            count: number,
            locations: AbstractLocation[]
          }}))
        }
      }),
      shareReplay(1)
    )
  }

  async exportCsvNotWithin() {
    const locations = await this.locationsNotWithin$.take(1).toPromise()
    exportPlaces(locations, 'csv', 'storepointgeo_not_within.csv')
  }

  async exportCsvWithin() {
    const locations = await this.locationsWithin$.take(1).toPromise()
    exportPlaces(locations, 'csv', 'storepointgeo_within.csv')
  }
}
