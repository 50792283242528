import { Component, AfterViewInit, OnInit } from '@angular/core'
import { SubscriberComponent, Indicators } from '@targomo/client'
import { PlaceEndpoint, Place } from '../../../api'
import { AppModel } from '../../../model/appModel.service'
import { PieGraphValues } from '../../components/pie-chart/pie-chart.component'
import { DataLoadingModel } from '../../../model/dataLoadingModel'

@Component({
  selector: 'center-point-report-panel',
  templateUrl: './centerPointReport.component.html',
  styleUrls: ['./centerPointReport.component.less'],
})
export class CenterPointReportComponent extends SubscriberComponent implements OnInit {
  places: Place[] = []
  loading: Promise<Place[]>
  graphModel: PieGraphValues[]

  constructor(
    readonly appModel: AppModel,
    private placeEndpoint: PlaceEndpoint,
    private indicators: Indicators,
    private dataLoadingModel: DataLoadingModel
  ) {
    super()
  }

  ngOnInit() {
    this.watch(this.appModel.places.selectedPlace, async (place) => {
      this.places = []
      this.graphModel = null

      if (place && place.dataSet && (place.dataSet.name || '').toLowerCase().indexOf('centrepoint') > -1) {
        this.places = await (this.loading = this.indicators.add(
          this.dataLoadingModel.applyDefaultNameByVersionId(await this.placeEndpoint.findCenterPoint(place.id))
        ))
        this.graphModel = this.createGraphModel(this.places)
      }
    })
  }

  private createGraphModel(places: Place[]) {
    const model = places.reduce((acc, cur) => {
      acc[cur.primaryCategory] = acc[cur.primaryCategory] || 0
      acc[cur.primaryCategory] += cur.netSalesArea || 0

      return acc
    }, {} as any)

    return Object.keys(model).map((key) => {
      return { key, y: model[key] }
    })
  }

  hover(place: Place) {
    // do nothing yet...maybe later
  }
}
