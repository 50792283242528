<div *ngIf="allowFilters">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field fxFlex>
      <input matInput [(ngModel)]="filter" (ngModelChange)="filterChanged($event)" name="Filter" placeholder="Filter" />
    </mat-form-field>

    <button title="Clear search" [disabled]="!filter" (click)="clearSelection()" type="button" mat-icon-button>
      <mat-icon class="mat-20">clear</mat-icon>
    </button>
    <button title="Show only selected" (click)="showSelected()" type="button" mat-icon-button>
      <mat-icon [color]="showOnlySelected ? 'primary' : 'black'" class="mat-20">filter_list</mat-icon>
    </button>
    <button title="De-select all" (click)="clearAll()" type="button" mat-icon-button>
      <mat-icon class="mat-20">delete</mat-icon>
    </button>
  </div>
</div>

<div>
  <mat-list>
    <ng-container *ngFor="let profile of dataSets">
      <mat-list-item (click)="toggleDataSet(profile)" *ngIf="profile.filtered != false">
        <div class="location-item" fxFlex fxLayout="row">
          <div fxFlex>
            {{ profile.name }}
            <span [hidden]="profile.placesCount == null" class="child-count">({{ profile.placesCount | number }})</span>
          </div>
          <mat-icon
            [class.toggled]="visibleDataSet[profile.id]"
            class="mat-secondary animation-rotate"
            mat-font-set="material-icons"
            >keyboard_arrow_right</mat-icon
          >
        </div>
      </mat-list-item>

      <div [hidden]="!visibleDataSet[profile.id] || profile.filtered == false" class="child-list">
        <admin-select-fascias-category
          [allowGroupings]="allowGroupings"
          (selectionChange)="selectionUpdated($event)"
          [showOnlySelected]="showOnlySelected"
          [filter]="filter"
          [dataSet]="profile"
          [categories]="profile.categories"
        ></admin-select-fascias-category>
      </div>
    </ng-container>
  </mat-list>
</div>
