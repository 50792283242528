import { MapLayer, MapLayerPosition, MapSource } from '@targomo/client'
import { Layer } from 'mapbox-gl'
import { Observable } from 'rxjs'
import { MultigraphOverview } from '../../api/multigraph'
import { TravelDisplayMode } from '../../model/settingsModel'
import * as d3scales from '../util/d3scales'
import { CustomMapboxComponent } from '../mapBox/mapbox.component'

const DEFAULT_STATISTICS_COLORS = 'interpolateYlOrRd'

export class MultigraphLayer extends MapLayer<MapSource<{}>> {
  private overview: MultigraphOverview = null
  private colorStops: { color?: string; value: number }[] = null
  private interpolator: string = DEFAULT_STATISTICS_COLORS
  private travelOpacity = 0.5
  private statisticsOpacity = 0.85
  private travelDisplayMode: TravelDisplayMode
  private hasHash = false

  constructor(
    map: CustomMapboxComponent,
    source: MapSource<{}>,
    overview$: Observable<MultigraphOverview>,
    colorStops$: Observable<{ color?: string; value: number }[]>,
    interpolator$: Observable<string>,
    travelOpacity$: Observable<number>,
    statisticsOpacity$: Observable<number>,
    travelDisplayMode$: Observable<TravelDisplayMode>,
    hash$: Observable<string>
  ) {
    super(map as any, source)
    this.setPosition(MapLayerPosition.STATISTICS)

    this.watch(hash$, (hash) => {
      this.hasHash = !!hash
      this.update()
    })

    this.watch(travelDisplayMode$, (travelDisplayMode) => {
      this.travelDisplayMode = travelDisplayMode
      this.update()
    })

    // this.watch(travelOpacity$, travelOpacity => {
    //   this.travelOpacity = travelOpacity

    //   this.update()
    // })

    this.watch(statisticsOpacity$, (statisticsOpacity) => {
      this.statisticsOpacity = statisticsOpacity
      this.update()
    })

    this.watch(overview$, (overview) => {
      this.overview = overview
      this.update()
    })

    this.watch(colorStops$, (colorStops) => {
      this.colorStops = colorStops
      this.update()
    })

    this.watch(interpolator$, (interpolator) => {
      this.interpolator = interpolator || DEFAULT_STATISTICS_COLORS
      this.update()
    })
  }

  get(): Partial<Layer> {
    if (!this.hasHash) {
      return {
        type: 'fill',
      }
    }

    const result: any = {
      type: 'fill',
      'source-layer': 'aggregation',
      paint: {
        'fill-antialias': false,
        'fill-opacity': [
          'case',
          ['has', 'reachability'],
          this.travelOpacity,
          ['has', 'w'],
          this.travelOpacity,
          this.statisticsOpacity,
        ],
        ...this.calculatePaint(),
      },
    }

    return result
  }

  private calculatePaint(): any {
    if (this.colorStops && this.travelDisplayMode === TravelDisplayMode.ThematicNoPolygons) {
      return {
        'fill-color': [
          'case',
          ['has', 'reachability'],
          this.getTravelColorConfig('reachability'),
          ['has', 'w'],
          this.getTravelColorConfig('w'),
          this.calculateStatisticsColorStops(this.overview, this.interpolator),
        ],
      }
    } else {
      return {
        'fill-color': this.calculateStatisticsColorStops(this.overview, this.interpolator),
      }
    }
  }

  private getTravelColorConfig(attribute = 'reachability') {
    const colorSubexpression: any[] = ['case']
    let lastColor: string = null
    this.colorStops.forEach((pair) => {
      colorSubexpression.push(['<=', ['number', ['get', attribute]], pair.value])
      colorSubexpression.push((lastColor = pair.color))
    })

    if (lastColor) {
      colorSubexpression.push(lastColor)
    }

    return colorSubexpression
  }

  private calculateStatisticsColorStops(all: MultigraphOverview, interpolator: string, attribute = 'statistics'): any {
    if (!all || !all.multiGraphInfo || !all.multiGraphInfo.layersInfo || !all.multiGraphInfo.layersInfo.statistics) {
      return 'rgba(0, 0, 0, 0)'
    }

    const overview = all.multiGraphInfo.layersInfo.statistics
    const breakpoints: any[] = overview.kmeansValueBreaks ||
      overview.equalIntervalValueBreaks || [overview.minValue, overview.maxValue]
    const interpolatorFunction = (<any>d3scales)[interpolator]

    const result: any[] = ['interpolate', ['exponential', 1], ['number', ['get', '' + attribute]]]

    let last: any = null
    breakpoints.forEach((breakpoint: any, i: number) => {
      if (last !== breakpoint) {
        result.push(breakpoint, interpolatorFunction((i + 1) / breakpoints.length))
        last = breakpoint
      }
    })

    return result
  }
}
