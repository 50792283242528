<div class="admin-panel">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">Notification Messages</h2>
    <div class="secondary">
      <button mat-button class="mat-primary" routerLink="/admin/messages/new"><mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> New Message</button>
    </div>
  </div>    


  <mat-list>
    <!-- <mat-divider></mat-divider> -->
    <mat-list-item *ngFor="let set of messages" routerLink="/admin/messages/{{set.id}}">
      <div class="name-section" fxFlex fxLayout="column" >
        <span>{{set.title}}</span>
      </div>

      <button (click)="showMenu($event)" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="mat-24">more_vert</mat-icon></button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/admin/messages/{{set.id}}" aria-label="Edit">
          <mat-icon mat-font-set="material-icons">mode_edit</mat-icon>
          Edit Message
        </button>

        <button mat-menu-item (click)="removeMessage(set)" aria-label="Edit">
          <mat-icon mat-font-set="material-icons">delete</mat-icon>
          Remove Message
        </button>

      </mat-menu>
    </mat-list-item>
  </mat-list>
</div>