import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core'
import { AVAILABLE_COMPETITION_COLUMS } from '../../adminReportOptions/adminReportOptions.component'

export const AVAILABLE_GAP_REPORT_COLUMNS: { key: string; label: string; selected?: boolean }[] = [
  { key: 'name', label: 'Name' },
  { key: 'paon', label: 'Address 1' },
  { key: 'saon', label: 'Address 2' },
  { key: 'taon', label: 'Address 3' },
  { key: 'street', label: 'Street' },
  { key: 'classification', label: 'Classification' },
  { key: 'town', label: 'Town' },
  { key: 'postcode', label: 'Postcode' },
  { key: 'grossInternalArea', label: 'GIA (sq ft)' },
  { key: 'netSalesArea', label: 'NSA (sq ft)' },
  { key: 'travelTime', label: 'Travel Time' },
]

export const AVAILABLE_GAP_REPORT_COLUMNS_DEFAULT = ['name', 'town', 'postcode', 'grossInternalArea', 'netSalesArea']

export const FALLBACK_GAP_REPORT_SETTINGS = {
  // Default as before
  showCompetition: true,
  showCatchment: true,
  columns: AVAILABLE_GAP_REPORT_COLUMNS_DEFAULT,
}

@Component({
  selector: 'admin-edit-gap-analysis-report',
  templateUrl: './adminEditGapAnalysisReport.component.html',
  styleUrls: ['./adminEditGapAnalysisReport.component.less'],
})
export class AdminEditGapAnalysisReportComponent implements OnInit, OnChanges {
  @Output() modelChange = new EventEmitter<any>()

  availableCompetitionColumns: typeof AVAILABLE_GAP_REPORT_COLUMNS = JSON.parse(
    JSON.stringify(AVAILABLE_GAP_REPORT_COLUMNS)
  )

  @Input() model: any = JSON.parse(JSON.stringify(FALLBACK_GAP_REPORT_SETTINGS))

  constructor() {}

  toggleGeneral(property: string) {
    this.model = this.model || {}
    this.model[property] = !this.model[property]
    this.fireUpdate()
  }

  toggleCompetitionColumn(value: any) {
    value.selected = !value.selected
    this.fireUpdate()
  }

  ngOnInit() {
    this.ngOnChanges()
  }

  ngOnChanges() {
    if (!this.model) {
      this.model = JSON.parse(JSON.stringify(FALLBACK_GAP_REPORT_SETTINGS))
    }

    this.availableCompetitionColumns = this.mergeList(AVAILABLE_GAP_REPORT_COLUMNS, this.model.columns)
  }

  private mergeList(avaliable: { key: string; label: string; selected?: boolean }[], selected: string[]) {
    const selectedMap: any = (selected || []).reduce((acc, cur) => {
      acc[cur] = true
      return acc
    }, {} as any)

    return avaliable.map((item) => {
      return {
        ...item,
        selected: selectedMap[item.key],
      }
    })
  }

  private fireUpdate() {
    this.model = this.model || {}
    this.model.columns = this.availableCompetitionColumns.filter((item) => item.selected).map((item) => item.key)

    this.modelChange.next(this.model)
  }
}
