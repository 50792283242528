import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Indicators } from '@targomo/client'
import { SettingsCareHome } from '../../../../common/models/settings'
import { SettingsEndpoint } from '../../../api/settings'

@Component({
  selector: 'admin-care-home',
  templateUrl: './adminCareHome.component.html',
  styleUrls: ['./adminCareHome.component.less']
})
export class AdminCareHomeComponent implements OnInit {
  loading: Promise<SettingsCareHome> = null
  saving: Promise<any> = null
  form: FormGroup
  ready = true

  ageColumns = [
    {label: 'Ages Under 65', key: 'agesUnder65'},
    {label: 'Ages 65-69', key: 'ages65to69'},
    {label: 'Ages 70-74', key: 'ages70to74'},
    {label: 'Ages 75-79', key: 'ages75to79'},
    {label: 'Ages 80-84', key: 'ages80to84'},
    {label: 'Ages 85-89', key: 'ages85to89'},
    {label: 'Ages 90+', key: 'ages90plus'},
  ]

  constructor(
    private settingsEndpoint: SettingsEndpoint,
    private indicators: Indicators,
  ) {
    this.form = new FormGroup({
      agesUnder65: new FormControl(),
      ages65to69: new FormControl(),
      ages70to74: new FormControl(),
      ages75to79: new FormControl(),
      ages80to84: new FormControl(),
      ages85to89: new FormControl(),
      ages90plus: new FormControl(),
    })
  }

  async ngOnInit() {
    const reportSettings = await this.indicators.add(this.loading = this.settingsEndpoint.getCareHome())

    this.form = new FormGroup({
      agesUnder65: new FormControl(reportSettings.ratios && reportSettings.ratios.agesUnder65 || 0),
      ages65to69: new FormControl(reportSettings.ratios && reportSettings.ratios.ages65to69 || 0),
      ages70to74: new FormControl(reportSettings.ratios && reportSettings.ratios.ages70to74 || 0),
      ages75to79: new FormControl(reportSettings.ratios && reportSettings.ratios.ages75to79 || 0),
      ages80to84: new FormControl(reportSettings.ratios && reportSettings.ratios.ages80to84 || 0),
      ages85to89: new FormControl(reportSettings.ratios && reportSettings.ratios.ages85to89 || 0),
      ages90plus: new FormControl(reportSettings.ratios && reportSettings.ratios.ages90plus || 0),
    })
  }

  async submit() {
    this.saving = this.settingsEndpoint.saveCareHome({ratios: this.form.value})
  }
}
