

import { Component, Input, EventEmitter, Output } from '@angular/core';
import {UserEndpoint, User} from '../../../api/user';
import { DataSetEndpoint } from '../../../api/dataSet';
import { Indicators } from '@targomo/client';
import { TgmQuickDialogs } from '@targomo/client';
import { MatCheckboxChange } from "@angular/material";
import { Router } from '@angular/router';
import { ZoneLayersEndpoint } from '../../../api/sectors';

@Component({
  selector: 'admin-zone-layer-versions',
  template: `
    <div>
      <tgm-loadable [model]="loading">
        <table class="versions" *ngIf="versions && versions.length > 0">
        <tbody>
          <tr>
            <th></th>
            <th>Version</th>
            <th>Preview</th>
            <th>Active</th>
          </tr>

          <tr *ngFor="let version of versions">
            <td><mat-icon [hidden]="version.preview || version.active" class="delete" (click)="deleteVersion(version)" mat-font-set="material-icons">delete</mat-icon></td>
            <td><span fxFlex>{{version.createdAt | date: 'y-MM-dd HH:mm'}}</span></td>
            <td><mat-checkbox [checked]="version.preview" (change)="togglePreview(version, $event)"></mat-checkbox></td>
            <td><mat-checkbox [checked]="version.active" (change)="toggleActive(version, $event)"></mat-checkbox></td>
          </tr>
          </tbody>
        </table>

        <div class="no-versions" *ngIf="versions && versions.length == 0">
          No versions of this zone layer exist
        </div>
        
      </tgm-loadable>

      <div *ngIf="versions && versions.length == 0">
        <div style="text-align: center; margin-top: 24px">
          <button mat-button (click)="deleteSet()">
            <i class="material-icons">delete_forever</i> <span>Delete this zone layer</span>
          </button>
        </div>   
      </div>
    </div>

  `,
  styleUrls: ['./adminZoneLayerVersions.less']
})
export class AdminZoneLayerVersionsComponent {
  @Input() private id: number
  @Output() private updated = new EventEmitter()

  versions: any[] = []
  loading: Promise<any[]>

  constructor(private dataSetEndpoint: ZoneLayersEndpoint, private indicators: Indicators, private quickDialogs: TgmQuickDialogs, private router: Router) {
  }

  async ngOnInit() {
    this.versions = await this.indicators.add((this.loading = this.dataSetEndpoint.getVersions({id: +this.id})))
  }

  async togglePreview(version: any, value: MatCheckboxChange) {
    await this.dataSetEndpoint.savePreview(version, value.checked)
    this.updated.emit(true)
    this.ngOnInit()
  }

  async toggleActive(version: any, value: MatCheckboxChange) {
    await this.dataSetEndpoint.saveActive(version, value.checked)
    this.ngOnInit()
  }

  async deleteVersion(version: any) {
    if (version.active || version.preview) {
      this.quickDialogs.snack('Active and Preview zone versions can\'t be deleted')
      return
    }

    const result = await this.quickDialogs.confirm({
      message: 'Permanently delete this zone version?',
      confirm: 'Delete',
      cancel: 'Cancel'
    })

    if (result) {
      const deleteResult = await this.dataSetEndpoint.deleteVersion(version)
      if (deleteResult) {
        this.quickDialogs.snack('Version deleted')
        this.ngOnInit()
      } else {
        this.quickDialogs.snack('Delete failed')
      }
    }
  }

  async deleteSet() {
    const set = await this.dataSetEndpoint.get({id: this.id})

    if (await this.quickDialogs.confirm({
      title: 'Delete Zone',
      message: `Would you like to delete zone ${set.name}?`,
      confirm: 'Delete',
      cancel: 'Cancel'
    })) {
      await this.dataSetEndpoint.delete({id: this.id})
      this.quickDialogs.snack(`Zone ${set.name} deleted`)
      this.router.navigate(['/admin/zones'])
    }
  }
}