<div fxLayout="column">
  <div fxLayout="row">
    <h2 fxFlex class="mat-title">User Profiles</h2>

    <div class="secondary">
      <button mat-button class="primary" routerLink="/admin/user/new"><mat-icon class="arrow-icon" mat-font-set="material-icons">add</mat-icon> New Profile</button>
    </div>
  </div>
</div>

<tgm-loadable [model]="loading">
  <mat-list>
    <mat-list-item *ngFor="let user of users" routerLink="/admin/user/{{user.id}}">
      <img *ngIf="user.hasPicture" class="avatar" [src]="basePath + 'api/users/' + user.id + '/picture'"/>
      <img *ngIf="!user.hasPicture" class="avatar" [src]="basePath + 'assets/images/profile.png'">

      <div class="name-section" fxFlex fxLayout="column" >
        <div class="name">{{user.name}}</div>
        <div class="username">{{user.username}}</div>
      </div>

      <button (click)="showMenu($event)" mat-icon-button [matMenuTriggerFor]="menu"><mat-icon class="mat-24">more_vert</mat-icon></button>

      <mat-menu #menu="matMenu">
        <button routerLink="/admin/user/{{user.id}}" mat-menu-item>Edit Profile</button>
        <button *ngIf="user.username != 'admin'" (click)="deleteUser(user)" mat-menu-item>Delete Profile</button>
      </mat-menu>
    </mat-list-item>
  </mat-list>
</tgm-loadable>