<tgm-loadable loading="loading" *ngIf="places?.length">
  <h3>Occupier Data Summary</h3>
  <span class="no-data" *ngIf="!places.length">No Data</span>

  <tgm-pie-chart style="margin: 16px 0px" [data]="graphModel"></tgm-pie-chart>

  <table *ngIf="!!places.length">
    <tr>
      <th>Name</th>
      <th>Category</th>
      <th>GIA (sq ft)</th>
      <th>NSA (sq ft)</th>
    </tr>

    <tr
      (click)="click(item)"
      *ngFor="let item of places"
      (mouseleave)="hover(null)"
      (mouseenter)="hover(item)"
      [class.hover]="hoverPlace && hoverPlace.id == item.id"
    >
      <td>{{ item.defaultName || item.sitename || item.fascia }}</td>
      <td>{{ item.primaryCategory }}</td>
      <td>{{ item.grossInternalArea | number }}</td>
      <td>{{ item.netSalesArea | number }}</td>
    </tr>
  </table>
</tgm-loadable>
