import { Component } from '@angular/core';
import { DataSet } from "../../api/index";
import {Auth} from '@targomo/client';
import {DataSetEndpoint} from '../../api/dataSet';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'admin-dataprofiles-list',
  templateUrl: './adminDataSetsList.html'
})
export class AdminDataSetsListComponent {
  dataSets: DataSet[]

  constructor(private datasetEndpoint: DataSetEndpoint, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.updateList()
  }

  async updateList() {
    this.dataSets = await this.datasetEndpoint.findAll()
  }

  showMenu(event: any) {
    event.stopPropagation()
  }
  // go(set: DataSet) {
  //   this.$state.go('admin.editSet', {setId: set.id})
  // }
}
