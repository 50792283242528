import { Component, OnInit, OnDestroy } from '@angular/core'
import { DataSet, Category } from '../../../api/index'
import { Auth, Indicators } from '@targomo/client'
import { DataSetEndpoint } from '../../../api/dataSet'
import { Router, ActivatedRoute } from '@angular/router'
import { ZoneLayersEndpoint } from '../../../api/sectors'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { PlanningApplicationDataSetEndpoint } from '../../../api/planningApplicationDataSet'
import { SelectFasciasModel } from '../components/adminSelectFascias/selectFasciasModel'
import { PlanningApplicationDataSet } from '../../../../common/models/planningApplication'
import { URL_REGEX } from '../adminDataProfileCategories.component'
import { startWith } from 'rxjs/operators'
import { AutoUpdateLog } from '../../../../common/models'

@Component({
  selector: 'admin-planning-application-versions',
  templateUrl: './planningApplicationVersions.component.html',
  styleUrls: ['./planningApplicationVersions.component.less'],
})
export class AdminPlanningApplicationVersionsComponent implements OnInit, OnDestroy {
  form: FormGroup
  // categories: Category[] = []
  currentEdit: number
  loading: Promise<Category[]>
  saving: Promise<{}>
  set: PlanningApplicationDataSet = <any>{}
  id: number

  loadingDataSets: Promise<DataSet[]>
  dataSets: DataSet[]

  locationEnabledCount: number = null
  status: string
  exited = false
  autoUpdateLog: AutoUpdateLog

  constructor(
    private formBuilder: FormBuilder,
    private Auth: Auth,
    public dataSetsEndpoint: DataSetEndpoint,
    public planningEndpoint: PlanningApplicationDataSetEndpoint,
    private route: ActivatedRoute,
    private router: Router,
    private indicators: Indicators
  ) {
    this.form = this.formBuilder.group({
      name: [this.set.name, [Validators.required]],
      autoUpdate: [this.set.autoUpdate],
      autoUpdateURL: [this.set.autoUpdateURL, [Validators.pattern(URL_REGEX)]],
    })
  }

  async refresh() {
    const params = this.route.snapshot.params
    const id = params.id

    this.id = id
    this.set = await this.planningEndpoint.get({ id: id })

    this.form = this.formBuilder.group({
      name: [this.set.name, [Validators.required]],
      autoUpdate: [this.set.autoUpdate],
      autoUpdateURL: [this.set.autoUpdateURL, [Validators.pattern(URL_REGEX)]],
    })

    const callback = async () => {
      this.status = await this.planningEndpoint.getCalculatingStatus({ id: id })
      if (!this.exited) {
        setTimeout(callback, 30 * 1000)
      }
    }

    this.autoUpdateLog = id === 'new' ? ({} as any) : await this.planningEndpoint.getAutoUpdateLog({ id })

    callback()

    this.form
      .get('autoUpdate')
      .valueChanges.pipe(startWith(this.set.autoUpdate))
      .subscribe((value) => {
        if (value) {
          this.form.get('autoUpdateURL').setValidators([Validators.required, Validators.pattern(URL_REGEX)])
        } else {
          this.form.get('autoUpdateURL').setValidators([Validators.pattern(URL_REGEX)])
        }

        this.form.get('autoUpdateURL').updateValueAndValidity()
      })
  }

  ngOnDestroy() {
    this.exited = true
  }

  async ngOnInit() {
    await this.refresh()
    this.dataSets = await this.indicators.add((this.loadingDataSets = this.dataSetsEndpoint.findAllFull()))
    new SelectFasciasModel(this.dataSets).updateSelected(this.set.selectedFascias)
  }

  back() {
    this.router.navigate(['/admin/planningApplications'])
  }

  showMenu(event: any) {
    event.stopPropagation()
  }

  async save(event?: any) {
    if (this.form.valid) {
      this.set.name = this.form.value.name
      this.set.copyright = this.form.value.copyright
      this.set.autoUpdate = this.form.value.autoUpdate
      this.set.autoUpdateURL = this.form.value.autoUpdateURL
      this.set.selectedFascias = new SelectFasciasModel(this.dataSets).getSelectedFascias()
      this.saving = this.planningEndpoint.save(this.set)
      await this.saving
      this.back()
    }
  }

  async updatedVersion() {
    // this.categories = await (this.loading = this.dataSetEndpoint.getCategories({id: this.setId}))
  }

  updateFascias(dataSets: DataSet[]) {
    this.locationEnabledCount = 0
    this.dataSets.forEach((dataset) => {
      dataset.categories.forEach((category) => {
        if (category.selected) {
          this.locationEnabledCount += (category as any).placesCount || 0
        }
      })
    })
  }

  recalculate() {
    const selection = new SelectFasciasModel(this.dataSets).getSelectedFascias()
    console.log('SELECTION', selection)
  }
}
