<section style="width: 80vw; max-width: 350px">
  <form>
    <div>
      {{this.model.message}}
    </div>

    <div class="dialog-bottom">
      <button mat-raised-button  [mat-dialog-close]="true" color="primary" mat-button><span translate="dialog.save">{{this.model.confirm}}</span></button>
    </div>
  </form>
</section>