
import {AppModel} from '../../model/appModel.service'
import {Indicators, SubscriberComponent} from '@targomo/client'
import { Component, Input } from '@angular/core'
import { DataSet } from "../../api/index";
import {Subscription} from 'rxjs/Subscription';
import { DataLoadingModel } from '../../model/dataLoadingModel';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'layers-panel',
  templateUrl: './layersPanel.html',
  styleUrls: ['./layersPanel.less']
})
export class LayersPanelComponent extends SubscriberComponent {
  @Input() type: 'default' | 'alsoAvailable'

  visible: any = {}
  profiles: DataSet[]
  loading: Promise<any>

  constructor(private indicators: Indicators, readonly appModel: AppModel, private placesLoadingModel: DataLoadingModel) {
    super()
  }

  ngOnInit() {  
    this.watch(this.appModel.places.places.promise, loading => {
      this.loading = loading
    })

    if (this.type === 'alsoAvailable') {
      this.watch(this.appModel.places.getUnavailableDataSets(), profiles => {
        this.profiles = profiles
      })  
    } else {
      this.watch(this.appModel.places.dataSets, profiles => {
        this.profiles = profiles
      })  
    }


    // NOTE: consider this as an option
    // this.watch(this.appModel.places.categorySearchUpdated.pipe(debounceTime(1000)), () => {
    //   if (this.profiles) {
    //     this.profiles.forEach(profile => {
    //       if (profile.filtered) {
    //         this.visible[profile.id] = true
    //       }
    //     })
    //   }
    // })
  }


  toggle(layer: any) {
    this.visible[layer] = !this.visible[layer]

    if (this.type === 'alsoAvailable') {
      return
    }

    if (this.visible[layer] && this.profiles) {      
      const loadDataSet = this.profiles.filter(set => set.id === layer)[0]
      if (loadDataSet) {
        this.placesLoadingModel.loadDataSet(loadDataSet, true)
      }
    }
  }
}